import React, { Component } from "react";
import Slider from "react-slick";

import img from "../../../assets/Landing page/checkmark (1).svg";

import img3 from "../../../assets/Landing page/Macbook air Mockup.png";
import img4 from "../../../assets/Landing page/hireFreelancer.png";

export default class CenterMode extends Component {
  render() {
    const settings = {
      customPaging: function (i) {
        return (
          <a>
            {i + 1 === 1 ? (
              <div
                style={{
                  float: "left",
                  borderRadius: "25px",
                  position: "relative",
                  top: "12vw",
                }}
                className="taghomebenofit-noshadowx"
              >
                <span>Business Software </span>
              </div>
            ) : (
              <div
                style={{
                  float: "left",
                  borderRadius: "25px",
                  position: "relative",
                  top: "12vw",
                }}
                className="taghomebenofit-noshadowx"
              >
                <span>Hire Business Captain </span>
              </div>
            )}
          </a>
        );
      },
      dots: true,
      dotsClass: "slick-dots slick-thumb",
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    const settings1 = {
      customPaging: function (i) {
        return (
          <a>
            {i + 1 === 1 ? (
              <div
                style={{
                  float: "left",
                  borderRadius: "25px",
                  position: "relative",
                  bottom: "2vw",
                }}
                className="taghomebenofit-noshadowx"
              >
                <span>Business Software </span>
              </div>
            ) : (
              <div
                style={{
                  float: "left",
                  borderRadius: "25px",
                  position: "relative",
                  bottom: "2vw",
                }}
                className="taghomebenofit-noshadowx"
              >
                <span>Hire Business Captain </span>
              </div>
            )}
          </a>
        );
      },
      dots: true,
      dotsClass: "slick-dots slick-thumb",
      infinite: true,
      speed: 1000,
      adaptiveHeight: true,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <div>
        <Slider {...(this?.props?.width > 700 ? settings1 : settings)}>
          <div>
            <div
              style={{
                position: "relative",
                top: this?.props?.width > 700 ? "3vw" : "5vw",
              }}
              className="home-benifit-noshadow"
            >
              <div className="homenoshadawb-textpart">
                <div
                  style={{ marginLeft: "0", color: "#0052CC" }}
                  className={
                    this?.props?.width > 700
                      ? "homebenifittype"
                      : "homebenifittypex"
                  }
                ></div>
                <div
                  style={{
                    fontSize: this?.props?.width > 700 ? "2vw" : "5vw",
                    lineHeight: this?.props?.width > 700 ? "3vw" : "7vw",
                    color: "black",
                  }}
                  className="homebenefit-typedesc"
                >
                  One Software for All Business Needs
                </div>

                <div className="checkamarkbenofirhome">
                  <div style={{ display: "flex" }} className="chenkhomeine">
                    <span>
                      <img src={img} alt="" />
                    </span>
                    List your Product or Service
                  </div>
                  <div style={{ display: "flex" }} className="chenkhomeine">
                    <span>
                      <img src={img} alt="" />
                    </span>
                    Funding
                  </div>
                  <div style={{ display: "flex" }} className="chenkhomeine">
                    <span>
                      <img src={img} alt="" />
                    </span>
                    Hiring
                  </div>
                  <div style={{ display: "flex" }} className="chenkhomeine">
                    <span>
                      <img src={img} alt="" />
                    </span>
                    Get Business Solutions
                  </div>
                </div>
              </div>
              <div
                hidden={this?.props?.width > 700 ? false : true}
                className="homenoshadawb-imgpart"
              >
                <img src={img3} alt="" />
              </div>
            </div>
          </div>

          <div>
            <div
              style={{
                position: "relative",
                top: this?.props?.width > 700 ? "3vw" : "5vw",
              }}
              className="home-benifit-noshadow"
            >
              <div className="homenoshadawb-textpart">
                <div
                  style={{ marginLeft: "0", color: "#0052CC" }}
                  className={
                    this?.props?.width > 700
                      ? "homebenifittype"
                      : "homebenifittypex"
                  }
                ></div>
                <div
                  style={{
                    fontSize: this?.props?.width > 700 ? "2vw" : "5vw",
                    lineHeight: this?.props?.width > 700 ? "3vw" : "7vw",
                    color: "black",
                  }}
                  className="homebenefit-typedesc"
                >
                  Hire One Professional with Five Skills
                </div>

                <div className="checkamarkbenofirhome">
                  <div style={{ display: "flex" }} className="chenkhomeine">
                    <span>
                      <img src={img} alt="" />
                    </span>
                    Reduce Work Load
                  </div>
                  <div style={{ display: "flex" }} className="chenkhomeine">
                    <span>
                      <img src={img} alt="" />
                    </span>
                    Recommends Solutions.
                  </div>
                  <div style={{ display: "flex" }} className="chenkhomeine">
                    <span>
                      <img src={img} alt="" />
                    </span>
                    Save Cost from 50% to 70%.
                  </div>
                  <div style={{ display: "flex" }} className="chenkhomeine">
                    <span>
                      <img src={img} alt="" />
                    </span>
                    Advice Growth Strategies
                  </div>
                </div>
              </div>
              <div
                hidden={this?.props?.width > 700 ? false : true}
                className="homenoshadawb-imgpart"
              >
                <img src={img4} alt="" />
              </div>
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}
