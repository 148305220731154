import React, { useEffect, useState } from "react";
import "./DashBoard.css";
import img from "../../assets/NewLogo/without tag line.svg";
import img4 from "../../assets/My profile – 28/pexels-stefan-stefancik-91227.png";
import img5 from "../../assets/My profile – 28/Iconly-Light-outline-Notification.svg";
import img3 from "../../assets/My profile – 33/2 User.svg";
import { useNavigate } from "react-router";
import "../../components/Profilecomp/profile.css";
import searchicon from "../../assets/Dashboard/Iconly-Light-Search.svg";
import Popover from "@mui/material/Popover";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import Badge from "@mui/material/Badge";
import CloseIcon from "@mui/icons-material/Close";
import LogoutIcon from "../../assets/icons/log-out.svg";
import { userActions } from "../../store/userSlice";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import BrowseWorkSiadebar from "./BrowseWorkSiadebar";
import axios from "axios";
import API_HOST from "../../env";
import MenuIcon from "@mui/icons-material/Menu";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import img6 from "../../assets/icons/tutorial-icon-png-19.jpg";
import { Link } from "react-router-dom";
import img22 from "../../assets/walletimage/Activity.svg";
import img23 from "../../assets/Dashboard/Iconly-Light-Calendar.svg";
import img7 from "../../assets/Dashboard/Iconly-Light-outline-Paper.svg";
import img8 from "../../assets/Dashboard/Iconly-Light-outline-Setting.svg";
import img9 from "../../assets/Dashboard/Iconly-Light-outline-Work.svg";
import img2 from "../../assets/Dashboard/Iconly-Light-outline-Buy.svg";
import img24 from "../../assets/Dashboard/Iconly-Light-outline-Document.svg";
import img89 from "../../assets/Dashboard/Skill center – 2/Iconly-Light-outline-Profile.svg";
import walletimg from "../../assets/walletimage/Wallet.svg";
import img25 from "../../assets/Dashboard/Home.svg";
import img26 from "../../assets/Dashboard//Iconly-Light-Search.svg";
import img12 from "../../assets/Dashboard/website.svg";
import img14 from "../../assets/Dashboard/success.png";
import img15 from "../../assets/Dashboard/tip.svg";
import img16 from "../../assets/Dashboard/tax.png";
import img17 from "../../assets/Dashboard/expense.png";
import img18 from "../../assets/Dashboard/question (1).png";
import img20 from "../../assets/Dashboard/Referral.png";
import img21 from "../../assets/Dashboard/Ticket Star.svg";
import img30 from "../../assets/Dashboard/Iconly-Light-outline-Paper Plus.svg";
import chart from "../../assets/Dashboard/Iconly-Light-outline-Chart.svg";
import img32 from "../../assets/Finince/icons8-fund-48.png";
import img33 from "../../assets/Finince/icons8-calculator-64.png";
import img34 from "../../assets/Finince/icons8-bank-64.png";
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      cursor: "pointer",
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export default function DashbaordNav({
  width,
  notifications,
  setNotifications,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [mobile, setmobile] = useState(false);

  useEffect(() => {
    if (user) {
      if (user?.contactNo) {
        setmobile(false);
      } else {
        setmobile(user?.contactNo ? false : true);
      }
    }
  }, [user]);




  const [anchorEl1, setAnchorEl1] = React.useState(null);

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const open1 = Boolean(anchorEl1);
  const id1 = open1 ? "simple-popover" : undefined;

  const [anchorEl1x, setAnchorEl1x] = React.useState(null);

  const handleClick1x = (event) => {
    setAnchorEl1x(event.currentTarget);
  };

  const handleClose1x = () => {
    setAnchorEl1x(null);
  };

  const open1x = Boolean(anchorEl1x);
  const id1x = open1x ? "simple-popover" : undefined;

  function logoutUser() {
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("token");
    window.location.pathname = "/";
    dispatch(userActions.removeUser());
  }

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : "57vw" }}
      onKeyDown={toggleDrawer(anchor, false)}
      role="presentation"
    >
      <BrowseWorkSiadebar width={width} toggleDrawer={toggleDrawer} />
    </Box>
  );

  const updaten = () => {
    if (user?.userName) {
      axios
        .get(`${API_HOST}/notified/alertNotification`, {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((res) => {
          setNotifications(res?.data?.success?.data[0]);
        });
    }
  };
  const updatec = () => {
    if (user?.userName) {
      axios
        .get(`${API_HOST}/connection/clientsView?pageSize=10&pageNumber=1`, {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((res) => {
          setRequestconnect(res?.data?.success?.data);
        });
    }
  };
  const [requestconnect, setRequestconnect] = useState([]);

  useEffect(() => {
    if (user?.userName) {
      axios
        .get(`${API_HOST}/connection/clientsView?pageSize=10&pageNumber=1`, {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((res) => {
          setRequestconnect(res?.data?.success?.data);
        });
    }
  }, [user]);

  const handleaccept = (id) => {
    axios
      .post(
        `${API_HOST}/connection/acceptConnection`,
        { connectionId: id },
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((res) => {
        setRequestconnect(res?.data?.success?.data);
        updatec();
      });
  };
  const handlereject = (id) => {
    axios
      .post(
        `${API_HOST}/connection/rejectConnection`,
        { connectionId: id },
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((res) => {
        setRequestconnect(res?.data?.success?.data);
        updatec();
      });
  };

  const [blink, setBlink] = useState(false);

  useEffect(() => {
    setBlink(false);
    if (requestconnect?.length > 0) {
      requestconnect?.map((data) => {
        if (!data?._id?.accept && !data?._id?.reject && !data?._id?.withdrawl) {
          console.log(true);
          setBlink(true);
          return 0;
        }
      });
    }
  }, [requestconnect]);


  return (
    <div className="pnav-container">
      <div
        style={{
          display: width > 700 ? "" : "flex",
          width: width > 700 ? "" : "10vw",
        }}
        className="navlofogo"
      >
        {width <= 700 && (
          <span onClick={toggleDrawer("left", true)}>
            <MenuIcon
              style={{
                fontSize: "8vw",
                marginRight: "0.51vw",
                position: "relative",
                top: "0.2vw",
                right: "1vw",
              }}
            />
          </span>
        )}
        <SwipeableDrawer
          anchor={"left"}
          open={state["left"]}
          onClose={toggleDrawer("left", false)}
          onOpen={toggleDrawer("left", true)}
        >
          {list("left")}
        </SwipeableDrawer>
        {width > 700 && (
          <img onClick={() => navigate("/")} src={img} alt="error" />
        )}
      </div>

    
    
      <div className="pnav-container2">
        <div
          onClick={(e) => {
            navigate("/dashboard/wallet");
          }}
          className="settinsiconpnav"
        >
          <img
            src={walletimg}
            style={{
              fontSize: "2vw",
              width: width > 700 ? "2vw" : "5.5vw",
              objectFit: "contain",
              cursor: "pointer",
            }}
            alt=""
          />
        </div>

        <div
          onClick={(e) => {
            navigate("/dashboard/analytics");
          }}
          className="settinsiconpnav"
        >
          <img
            src={chart}
            style={{
              fontSize: "2vw",
              width: width > 700 ? "2vw" : "5.5vw",
              objectFit: "contain",
              cursor: "pointer",
            }}
            alt=""
          />
        </div>
        <div
          onClick={(e) => {
            navigate("/dashboard/tutorials");
          }}
          className="settinsiconpnav"
        >
          <img
            src={img6}
            style={{
              fontSize: "2vw",
              width: width > 700 ? "2vw" : "5.5vw",
              objectFit: "contain",
              cursor: "pointer",
            }}
            alt=""
          />
        </div>

        <div
          onClick={(e) => {
            updatec();
            handleClick1x(e);
          }}
          className="settinsiconpnav"
        >
          {blink ? (
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              variant="dot"
            >
              <img
                src={img3}
                style={{
                  fontSize: "2vw",
                  width: width > 700 ? "2vw" : "5.5vw",
                  objectFit: "contain",
                  cursor: "pointer",
                }}
                alt=""
              />{" "}
            </StyledBadge>
          ) : (
            <img
              src={img3}
              style={{
                fontSize: "2vw",
                width: width > 700 ? "2vw" : "5.5vw",
                objectFit: "contain",
                cursor: "pointer",
              }}
              alt=""
            />
          )}
        </div>

        <Popover
          id={id1x}
          open={open1x}
          anchorEl={anchorEl1x}
          onClose={handleClose1x}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <div className="notificationpopup1">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
              }}
              className="notificationpopuptitile"
            >
              <div className="notification-title">Connection Requests</div>{" "}
              <div>
                <CloseIcon
                  onClick={handleClose1x}
                  style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
                />
              </div>
            </div>
            {requestconnect.length > 0 &&
              requestconnect.slice(0, 6)?.map((data) => {
                return (
                  <div>
                    <div
                      style={{ borderBottom: "none", paddingBottom: "0vw" }}
                      className="notification-entriesbox"
                    >
                      <div style={{ width: width > 700 ? "66vw" : "70vw" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          className="notificationname"
                          onClick={() => {
                            navigate(
                              `/dashboard/profile/${data?._id?.client?.userName[0]}`
                            );
                            handleClose1x();
                          }}
                        >
                          <span>
                            <img
                              style={{
                                width: width > 700 ? "1.7vw" : "5.5vw",
                                height: width > 700 ? "1.7vw" : "5.5vw",
                                objectFit: "cover",
                                borderRadius: "50%",
                                marginRight: width > 700 ? "1vw" : "2vw",
                              }}
                              src={data?._id?.client?.media[0]}
                              alt=""
                            />
                          </span>{" "}
                          {data?._id?.client?.fullName[0]} wants to connect with
                          You
                        </div>
                      </div>

                      <div
                        className="notificationtime"
                        style={{ width: width > 700 ? "13vw" : "15vw" }}
                      >
                        {new Date(data?._id?.created_at).getHours()}:
                        {new Date(data?._id?.created_at).getMinutes()}
                        {" , "}
                        {new Date(data?._id?.created_at).getDate()}/
                        {new Date(data?._id?.created_at).getMonth() + 1}/
                        {new Date(data?._id?.created_at).getFullYear()}
                      </div>
                    </div>

                    <div
                      style={{
                        width: width > 700 ? "46vw" : "85vw",
                        marginLeft: "1vw",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          cursor: "pointer",
                          fontSize: width > 700 ? "0.9vw" : "2.5vw",
                          lineHeight: width > 700 ? "1.1vw" : "2.8vw",
                        }}
                        className="notificationname"
                      >
                        <span
                          style={{ fontWeight: "500", marginRight: "0.5vw" }}
                        >
                          Purpose:{" "}
                        </span>
                        {data?._id?.purpose}
                      </div>
                    </div>

                    <div
                      style={{ paddingTop: "0.2vw", paddingBottom: "0vw" }}
                      className="notification-entriesbox"
                    >
                      <div style={{ width: width > 700 ? "66vw" : "70vw" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            fontSize: width > 700 ? "0.9vw" : "2.5vw",
                          }}
                          className="notificationname"
                        >
                          {data?._id?.accept &&
                            "You accepted this connection request"}
                          {data?._id?.reject &&
                            "You rejected this connection request"}
                          {data?._id?.withdrawl &&
                            "Client has withdraw this connection request"}
                          {!data?._id?.accept &&
                            !data?._id?.reject &&
                            !data?._id?.withdrawl &&
                            "Do any action with this Connection Request"}
                        </div>
                      </div>

                      {!data?._id?.accept &&
                        !data?._id?.reject &&
                        !data?._id?.withdrawl && (
                          <div
                            className="notificationtime"
                            style={{
                              width: width > 700 ? "15vw" : "25vw",
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                              fontSize: width > 700 ? "0.9vw" : "2.5vw",
                              position: "relative",
                              bottom: "0.5vw",
                            }}
                          >
                            <button
                              style={{
                                height: width > 700 ? "2vw" : "4.5vw",
                                marginRight: "1vw",
                              }}
                              className="hb-buttonx"
                              onClick={() => {
                                handlereject(data?._id?.connectionId);
                              }}
                            >
                              Reject
                            </button>
                            <button
                              style={{ height: width > 700 ? "2vw" : "4.5vw" }}
                              className="hb-buttonx"
                              onClick={() => {
                                handleaccept(data?._id?.connectionId);
                              }}
                            >
                              {" "}
                              Accept
                            </button>
                          </div>
                        )}

                      {data?._id?.accept && (
                        <div
                          className="notificationtime"
                          style={{
                            width: width > 700 ? "15vw" : "20vw",
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            fontSize: width > 700 ? "0.9vw" : "2.5vw",
                            position: "relative",
                            bottom: "0.5vw",
                          }}
                        >
                          {" "}
                          <button
                            style={{ height: width > 700 ? "2vw" : "4.5vw" }}
                            className="hb-buttonx"
                            onClick={() => {
                              navigate(
                                `/dashboard/connection/${data?._id?.connectionId}`
                              );
                              handleClose1x();
                            }}
                          >
                            View
                          </button>
                        </div>
                      )}
                      {(data?._id?.reject || data?._id?.withdrawl) && (
                        <div
                          className="notificationtime"
                          style={{
                            width: width > 700 ? "12vw" : "15vw",
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            fontSize: width > 700 ? "0.9vw" : "2.5vw",
                            position: "relative",
                            bottom: "0.5vw",
                            color: "red",
                          }}
                        >
                          {data?._id?.Status}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleClose1x();
                navigate("/dashboard/connection_request");
              }}
              className="seeallnotification"
            >
              See all connections
            </div>
          </div>
        </Popover>
        <div
          onClick={(e) => {
            updaten();
            handleClick1(e);
          }}
          className="settinsiconpnav"
        >
          {notifications?.notification?.length > 0 ? (
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              variant="dot"
            >
              {" "}
              <img
                src={img5}
                style={{
                  fontSize: "2vw",
                  width: width > 700 ? "2vw" : "5.5vw",
                  objectFit: "contain",
                  cursor: "pointer",
                }}
                alt=""
              />{" "}
            </StyledBadge>
          ) : (
            <img
              src={img5}
              style={{
                fontSize: "2vw",
                width: width > 700 ? "2vw" : "5.5vw",
                objectFit: "contain",
                cursor: "pointer",
              }}
              alt=""
            />
          )}
        </div>
        <Popover
          id={id1}
          open={open1}
          anchorEl={anchorEl1}
          onClose={handleClose1}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <div className="notificationpopup1">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
              }}
              className="notificationpopuptitile"
            >
              <div className="notification-title">Notifications</div>{" "}
              <div>
                <CloseIcon
                  onClick={handleClose1}
                  style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
                />
              </div>
            </div>
            {notifications?.notification?.length > 0 &&
              notifications?.notification?.slice(0, 5)?.map((data) => {
                return (
                  <div className="notification-entriesbox">
                    <div style={{ width: width > 700 ? "66vw" : "70vw" }}>
                      <div className="notificationname">
                        {data?.notification}
                      </div>
                    </div>
                    <div
                      className="notificationtime"
                      style={{ width: width > 700 ? "12vw" : "15vw" }}
                    >
                      {new Date(data?.time).getHours()}:
                      {new Date(data?.time).getMinutes()}
                      {" , "}
                      {new Date(data?.time).getDate()}/
                      {new Date(data?.time).getMonth() + 1}/
                      {new Date(data?.time).getFullYear()}
                    </div>
                  </div>
                );
              })}
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleClose1();
                navigate("/dashboard/notification");
              }}
              className="seeallnotification"
            >
              See all Notification{" "}
            </div>
          </div>
        </Popover>

        <div
          style={{ position: "relative", top: "1vw" }}
          className="profileDropdown"
        >
          <Dropdown className="d-flex justify-content-center ">
            <Dropdown.Toggle id="dropdown-autoclose-true">
              {width > 700 ? (
                <div
                  style={{
                    position: "relative",
                    bottom: "1vw",
                    width: "fit-content",
                    padding: "0vw",
                  }}
                  className="settinsiconpnav pnavusername"
                >
                  <div className="pnavuserimage">
                    <img
                      style={{ border: "2.5px solid #0052CC" }}
                      src={user?.media ? user?.media : img4}
                      alt=""
                    />
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    width: "8vw",
                    height: "8vw",
                    marginLeft: "2vw",
                    position: "relative",
                    bottom: "1vw",
                    marginRight: "1vw",
                  }}
                  className="pnavuserimage"
                >
                  <img
                    style={{
                      width: "8vw",
                      height: "8vw",
                      borderRadius: "50%",
                      border: "2px solid #0052CC",
                    }}
                    src={user?.media ? user?.media : img4}
                    alt=""
                  />
                </div>
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu
              className={
                width > 700
                  ? "p-0  px-0 bottom2px"
                  : "p-0  px-0 bottom2px mx-1 my-2"
              }
            >
              <div
                className={width > 700 ? "py-2  px-2 " : "p-1  px-1 "}
                style={{
                  background: "#0052cc20",
                  borderRadius: "0.375rem",
                  border: "1px solid #0052cc50",
                  cursor: "auto",
                }}
              >
                <Dropdown.Item
                  className={
                    width > 700
                      ? " py-3 px-3 pe-1 d-flex align-items-start cusror-pointer "
                      : " py-2 px-2 pe-1 d-flex align-items-start cusror-pointer "
                  }
                  style={{
                    background: "white",
                    borderRadius: "0.375rem",
                    cursor: "pointer",
                    borderTopLeftRadius: "0.375rem",
                    borderTopRightRadius: "0.375rem",
                    borderBottomLeftRadius: "0vw",
                    borderBottomRightRadius: "0vw",
                    borderBottom: "2px solid #0052cc40",
                    paddingRight: "0rem!important",
                  }}
                >
                  <img
                    style={{
                      width: width > 700 ? "4vw" : "11vw",
                      height: width > 700 ? "4vw" : "11vw",
                      borderRadius: "50%",
                    }}
                    src={user?.media ? user?.media : img4}
                    alt=""
                  />
                  <div
                    style={{
                      marginLeft: width > 700 ? "0.5vw" : "1.5vw",
                      width: "100%",
                      overflow: "scroll",
                    }}
                    className="profileDropdownMenu "
                  >
                    <div>
                      {user?.fullName?.length > 25
                        ? user?.fullName?.slice(0, 25)
                        : user?.fullName}
                    </div>
                    <div
                      className="smallfont"
                      style={{ fontSize: "0.7vw !important" }}
                    >
                      {user?.emailId?.length ? user?.emailId : user?.emailId}
                    </div>
                    <div
                      onClick={() => {
                        navigate("/dashboard/profile");
                      }}
                      className="MAnageaccountbutton"
                    >
                      Manage your Tool44 Account
                    </div>
                  </div>
                </Dropdown.Item>

                <Dropdown.Item
                  className={
                    width > 700
                      ? "py-3 px-3 d-flex align-items-center cusror-pointer"
                      : "py-3 px-3 d-flex align-items-center cusror-pointer"
                  }
                  onClick={() => logoutUser()}
                  style={{
                    background: "white",
                    borderRadius: "0.375rem",
                    cursor: "pointer",
                    borderTopLeftRadius: "0vw",
                    borderTopRightRadius: "0vw",
                  }}
                >
                  <img src={LogoutIcon} alt="logout" />
                  <span className="profileDropdownMenu mx-2">Logout</span>
                </Dropdown.Item>
                <Dropdown.Item
                  className={
                    width > 700
                      ? " px-1 d-flex align-items-center cusror-pointer"
                      : " px-1 d-flex align-items-center cusror-pointer"
                  }
                  style={{
                    background: "#0052cc00",
                    borderRadius: "0.375rem",
                    paddingBottom: "0.25rem",
                    paddingTop: "0.65rem",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: width > 700 ? "0.75vw" : "2vw",
                      cursor: "pointer",
                    }}
                    className="profileDropdownMenu mx-1"
                    onClick={() => navigate("/privacypolicy")}
                  >
                    Privacy Policy
                  </div>
                  <span
                    style={{ position: "relative", bottom: "0.25rem" }}
                    className="profileDropdownMenu mx-1"
                  >
                    .
                  </span>
                  <span
                    onClick={() => navigate("/termsandconditions")}
                    style={{
                      fontSize: width > 700 ? "0.75vw" : "2vw",
                      cursor: "pointer",
                    }}
                    className="profileDropdownMenu mx-1"
                  >
                    Terms & Condition
                  </span>
                </Dropdown.Item>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      {mobile && (
        <div
          style={{ position: "fixed", bottom: "1vw" }}
          className="visibleafteronesecind"
        >
          <Stack sx={{ width: "100%", color: "red" }} spacing={1}>
            <Alert
              severity="error"
              onClose={() => {
                setmobile(false);
              }}
              style={{ color: "red" }}
            >
              <Link
                onClick={() => {
                  setmobile(false);
                }}
                to="/dashboard/profile/#mobileupdate"
              >
                <span style={{ color: "red" }}>
                  Please Update Your mobile Number
                </span>
              </Link>
            </Alert>
          </Stack>
        </div>
      )}
    </div>
  );
}
