import React from "react";
import "./Explore.css";
export default function ExploreBanner(
    {width}
) {
  return (
    <div className="ExploreBanner">
      <div
        style={{ marginTop: width>700? "0vw":"2vw", width: "fit-content" }}
        className="h-banner-accerlate"
      >
        Solution & Support!
      </div>
      <div
        style={{ fontSize: width>700? "4vw":"6.5vw", lineHeight:width>700? "5vw":"10vw" ,marginTop:"0.4vw" }}
        className="h-banner-carrierland"
      >
        Save Cost With Expertise
      </div>
      <div
        style={{ marginTop: "0vw", width: "fit-content" }}
        className="hbsmalltext"
      >
        Meet all your requirements. From finding work to getting things done !
      </div>
    </div>
  );
}
