import React, { useEffect, useState } from "react";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import API_HOST from "../../../env";
import Box from "@mui/material/Box";
import img21 from "../../../assets/My profile – 28/Component 85 – 16 (1).svg";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { DeleteOutlineOutlined } from "@mui/icons-material";
import { TextField } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import imageCompression from "browser-image-compression";
import img3 from "../../../assets/Web 1280 – 14/Group 9831.svg";
import img from "../../../assets/Web 1280 – 14/Icon.svg";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
};

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "48vw",
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 2,
};
const style3 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
};

const styl = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
};

const styl3 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "91vw",
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
};
const useStyles = makeStyles((theme) => ({
  input: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "0.91vw",
    color: "#263238",
    border: "yellow !important",
  },
}));

export default function ProfileCerticateDetails({
  certificate,
  width,
  setcertificated,
  user,
}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleeducationdelete = () => {
    const data = {
      certificateId: certificate?.certificateId,
    };
    axios
      .post(`${API_HOST}/users/removeCertificates`, data, {
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        axios
          .get(
            `${API_HOST}/users/viewCertificates?pageSize=50&pageNumber=1&userName=${user?.userName}`
          )
          .then((res) => {
            setcertificated(res?.data?.success?.data);
            handleClose();
          });
      });
  };
  const classes = useStyles();
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [openx, setOpenx] = React.useState(false);
  const handleOpenx = () => setOpenx(true);
  const handleClosex = () => setOpenx(false);
  const [iamge, setIamge] = useState();

  const [year1, setyear1] = useState(certificate?.issueDate);

  const [year2, setyear2] = useState(certificate?.expiredDate);

  const handleaddcertificate = () => {
    if (!callagename || !organisation) {
      setErrorp("fill all the field");
      return;
    }
    const formdata = new FormData();

    formdata.append("certificateName", callagename);
    formdata.append("certificateId", certificate?.certificateId);

    formdata.append("issueDate", year1);
    formdata.append("organization", organisation);
    formdata.append("doesNotExpire", checkmarkcertificate);

    formdata.append("expiredDate", year2);
    formdata.append("verifiedUrl", certiurl);
    formdata.append("creditalId", certificateid);
    if (iamge) {
      formdata.append("fileName", iamge);
    }

    axios
      .post(`${API_HOST}/users/editCertificates`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        if (user) {
          axios
            .get(
              `${API_HOST}/users/viewCertificates?pageSize=50&pageNumber=1&userName=${user?.userName}`
            )
            .then((res) => {
              setcertificated(res?.data?.success?.data);
              handleClose1();
            });
        }
      });
  };
  const [errorp, setErrorp] = useState("");
  const [callagename, setcallagename] = useState(certificate?.certificateName);
  const [organisation, setorganisation] = useState(certificate?.organization);
  const [certificateid, setcerticateid] = useState(certificate?.creditalId);
  const [certiurl, setcertiurl] = useState(certificate?.verifiedUrl);
  const [checkmarkcertificate, setCheckmarkcertificate] = useState(
    certificate?.doesNotExpire
  );

  useEffect(() => {
    setyear1(certificate?.issueDate);
    setyear2(certificate?.expiredDate);
    setcallagename(certificate?.certificateName);
    setorganisation(certificate?.organization);
    setcertiurl(certificate?.verifiedUrl); 
    setcerticateid(certificate?.creditalId);
    setCheckmarkcertificate(certificate?.doesNotExpire);
  }, [certificate]);

  async function handleImageUpload(event) {
    const imageFile = event.target.files[0];
    console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log(
        "compressedFile instanceof Blob",
        compressedFile instanceof Blob
      ); // true
      console.log(
        `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
      ); // smaller than maxSizeMB

      //   await uploadToServer(compressedFile); // write your own logic
      await setIamge(new File([compressedFile], `${compressedFile?.name}`));
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div>
          <div className="pusercertificatebox">
            <div onClick={handleOpenx} className="pusercertificateboximg">
              <img
                style={{ cursor: "pointer" }}
                src={certificate?.images}
                alt=""
              />
            </div>
            {certificate?.verifiedUrl ? (
              <a
                href={certificate?.verifiedUrl}
                target="_blank"
                rel="noreferrer"
              >
                <div className="pusercertificateboxtext">
                  <div className="pusercertificateboxtexthead">
                    {certificate?.certificateName}
                  </div>

                  <div className="pusercertificateboxtexthead1">
                    Provider: {certificate?.organization}
                  </div>
                  <div className="pusercertificateboxtexthead3">
                    Issued on {certificate?.issueDate} -{" "}
                    {certificate?.doesNotExpire
                      ? "present"
                      : certificate?.expiredDate}
                  </div>
                </div>{" "}
              </a>
            ) : (
              <div className="pusercertificateboxtext">
                <div className="pusercertificateboxtexthead">
                  {certificate?.certificateName}
                </div>

                <div className="pusercertificateboxtexthead1">
                  Provider: {certificate?.organization}
                </div>
                <div className="pusercertificateboxtexthead3">
                  Issued on {certificate?.issueDate} /
                  {certificate?.doesNotExpire
                    ? "present"
                    : certificate?.expiredDate}
                </div>
              </div>
            )}
          </div>
        </div>

        <Modal
          open={openx}
          onClose={handleClosex}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={width > 700 ? styl : styl3}>
            <div className="imgbocofcerti">
              <img src={certificate?.images} alt="" />
            </div>
          </Box>
        </Modal>

        <Modal
          open={open1}
          onClose={handleClose1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={width > 700 ? style2 : style3}>
            <div
              style={{
                maxHeight: "90vh",
                overflowY: "scroll",
                width: "100%",
                padding: "1vw",
              }}
            >
              <div className="profiletitleandmenunav">
                <div className="profiledetailstitle">Add Certification</div>
                <div className="profiledetailnavmanu">
                  <CloseIcon
                    onClick={handleClose1}
                    style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
                  />
                </div>
              </div>
              <hr style={{ color: "#000000" }} />
              <div
                style={{ left: "0vw", width: "100%" }}
                className="loginfield"
              >
                <TextField
                  id="outlined-basic"
                  label="Title"
                  variant="outlined"
                  value={callagename}
                  style={{ width: "100%" }}
                  InputLabelProps={{
                    style: {
                      fontSize: width > 700 ? "1vw" : "3vw",
                      fontFamily: "Poppins",
                      fontStyle: "500",
                      fontWeight: "500",
                      color: "black",
                    },
                  }}
                  inputProps={{ className: classes.input }}
                  onChange={(e) => setcallagename(e.target.value)}
                />
              </div>
              <div
                style={{ left: "0vw", width: "100%" }}
                className="loginfield"
              >
                <TextField
                  id="outlined-basic"
                  label="Organization"
                  variant="outlined"
                  value={organisation}
                  style={{ width: "100%" }}
                  InputLabelProps={{
                    style: {
                      fontSize: width > 700 ? "1vw" : "3vw",
                      fontFamily: "Poppins",
                      fontStyle: "500",
                      fontWeight: "500",
                      color: "black",
                    },
                  }}
                  inputProps={{ className: classes.input }}
                  onChange={(e) => setorganisation(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="checkbox">
                  <input
                    type="checkbox"
                    name=""
                    id="checkbox"
                    checked={checkmarkcertificate}
                    onChange={() =>
                      setCheckmarkcertificate(!checkmarkcertificate)
                    }
                  />
                  <div
                    style={{
                      fontSize: width > 700 ? "0.91vw" : "2.7vw",
                      fontFamily: "Poppins",
                      fontStyle: "500",
                      fontWeight: "500",
                      color: "#000000",
                      display: "inline",
                      margin: "0 1vw",
                      cursor: "pointer",
                    }}
                  >
                    Does not expire
                  </div>
                </label>
              </div>
              <div className="jobpodtedfieldtitile">Issue Date</div>
              <div style={{ display: "flex", width: "100%" }}>
                <div
                  style={{
                    left: "0vw",
                    width: "50%",
                    margin: "0.3vw 0",
                  }}
                  className="loginfield"
                  // onClick={handleClickx}
                >
                  <div className="" style={{ width: "100%" }}>
                    <div style={{ width: "100%" }}>
                      <div className="jobpostfieldinputbox">
                        <input
                          style={{ width: "100%" }}
                          type="date"
                          className="input-homejobformdate"
                          name=""
                          id=""
                          // min={disablePastDate()}
                          value={year1}
                          max={"2025-12-31"}
                          maxlength="4"
                          onChange={(e) => {
                            setyear1(e.target.value);
                          }}
                          // onChange={(e) => {
                          //   setDatestart(e.target.value);
                          // }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!checkmarkcertificate ? (
                <>
                  {" "}
                  <div className="jobpodtedfieldtitile">Expired Date</div>
                  <div style={{ display: "flex", width: "100%" }}>
                    <div
                      style={{
                        left: "0vw",
                        width: "50%",
                        margin: "0.3vw 0",
                      }}
                      className="loginfield"
                    >
                      <div className="" style={{ width: "100%" }}>
                        <div style={{ width: "100%" }}>
                          <div className="jobpostfieldinputbox">
                            <input
                              style={{ width: "100%" }}
                              type="date"
                              className="input-homejobformdate"
                              name=""
                              id=""
                              value={year2}
                              // min={disablePastDate()}
                              max={"2025-12-31"}
                              maxlength="4"
                              onChange={(e) => {
                                setyear2(e.target.value);
                              }}
                              // onChange={(e) => {
                              //   setDatestart(e.target.value);
                              // }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              {/* </div> */}
              <div
                style={{ left: "0vw", width: "100%" }}
                className="loginfield"
              >
                <TextField
                  id="outlined-basic"
                  label="Credital Id"
                  variant="outlined"
                  value={certificateid}
                  style={{ width: "100%" }}
                  InputLabelProps={{
                    style: {
                      fontSize: width > 700 ? "1vw" : "3vw",
                      fontFamily: "Poppins",
                      fontStyle: "500",
                      fontWeight: "500",
                      color: "black",
                    },
                  }}
                  inputProps={{ className: classes.input }}
                  onChange={(e) => setcerticateid(e.target.value)}
                />
              </div>
              <div
                style={{ left: "0vw", width: "100%" }}
                className="loginfield"
              >
                <TextField
                  id="outlined-basic"
                  label="Verified URL"
                  value={certiurl}
                  variant="outlined"
                  style={{ width: "100%" }}
                  InputLabelProps={{
                    style: {
                      fontSize: width > 700 ? "1vw" : "3vw",
                      fontFamily: "Poppins",
                      fontStyle: "500",
                      fontWeight: "500",
                      color: "black",
                    },
                  }}
                  inputProps={{ className: classes.input }}
                  onChange={(e) => setcertiurl(e.target.value)}
                />
              </div>
              <div
                style={{
                  width: width > 700 ? "99%" : "99%",
                  height: width > 700 ? "10vw" : "20vw",
                }}
                className="inputfilebox"
              >
                <div>
                  <label htmlFor="inputctaelogfile">
                    <div className="inputicon">
                      <img src={img} alt="" />
                    </div>
                    <div className="inputcateaddformfile">
                      Drag and Drop ,Browse to upload
                    </div>
                    <input
                      type="file"
                      id="inputctaelogfile"
                      onChange={(e) => {
                        handleImageUpload(e);
                      }}
                      hidden
                    />
                  </label>
                </div>
              </div>
              {iamge ? (
                <div className="inputfilesshowncatboxsingle">
                  <div className="inputfilesshowncatboxsingleimg">
                    <img
                      style={{ width: width > 700 ? "" : "5vw" }}
                      src={img3}
                      alt=""
                    />
                  </div>
                  <div className="fileselctednamecate">
                    {iamge?.name.slice(0, 50)}
                  </div>
                  <div className="inputfilesshowncatboxsingleimg">
                    <img
                      style={{
                        width: width > 700 ? "1.5vw" : "4vw",
                        marginLeft: "1vw",
                        cursor: "pointer",
                      }}
                      src={img21}
                      alt=""
                      onClick={() => {
                        setIamge();
                      }}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}

              <p
                style={{
                  color: "red",
                  fontSize: width > 700 ? "0.9vw" : "2.5vw",
                }}
              >
                {errorp}
              </p>

              <div
                style={{ marginTop: "0.31vw" }}
                className="handlemoreaboutskill"
              >
                <div
                  style={{
                    background: "white",
                    color: "black",
                    cursor: "pointer",
                  }}
                  className="handlecirclieaboutsave"
                  onClick={handleClose1}
                >
                  Cancel
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  className="handlecirclieaboutsave"
                  onClick={handleaddcertificate}
                >
                  SAVE
                </div>
              </div>
            </div>
          </Box>
        </Modal>

        <div style={{ display: "flex", width: "5vw" }}>
          <EditIcon
            onClick={handleOpen1}
            style={{
              fontSize: width > 700 ? "1.5vw" : "4vw",
              color: "gray",
              margin: "0 0.61vw",
              cursor: "pointer",
            }}
          />
          <DeleteOutlineOutlined
            onClick={handleOpen}
            style={{
              cursor: "pointer",
              fontSize: width > 700 ? "1.7vw" : "4vw",
              color: "gray",
              margin: "0 0.61vw",
            }}
          />
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={width > 700 ? style : style1}>
            <div className="profiletitleandmenunav">
              <div className="profiledetailstitle">Delete Certification</div>
              <div className="profiledetailnavmanu">
                <div>
                  <CloseIcon
                    onClick={handleClose}
                    style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
                  />
                </div>
              </div>
            </div>
            <hr style={{ color: "#000000" }} />
            <div
              style={{
                color: "gray",
                fontSize: width > 700 ? "1vw" : "2.7vw",
                fontWeight: "300",
              }}
              className="profiledetailstitle"
            >
              The action will delete " {certificate?.certificateName}" From all
              of your profiles.
            </div>
            <div
              style={{
                color: "gray",
                fontSize: width > 700 ? "1vw" : "2.5vw",
                fontWeight: "400",
                marginBottom: "5vw",
              }}
              className="profiledetailstitle"
            >
              Are you sure you want to delete this education?
            </div>
            <hr style={{ color: "#000000" }} />
            <div
              style={{ marginTop: "0.31vw" }}
              className="handlemoreaboutskill"
            >
              <div
                style={{
                  background: "white",
                  color: "black",
                  cursor: "pointer",
                }}
                className="handlecirclieaboutsave"
                onClick={handleClose}
              >
                Cancel
              </div>
              <div
                style={{ cursor: "pointer", background: "red" }}
                onClick={handleeducationdelete}
                className="handlecirclieaboutsave"
              >
                Delete
              </div>
            </div>
          </Box>
        </Modal>
      </div>
      <hr
        style={{
          margin: "1vw 0vw",
          marginBottom: "1vw",
          color: "#000000",
        }}
      />
    </>
  );
}
