import React, { useEffect, useState } from "react";
import "./Website.css";
import StarIcon from "@mui/icons-material/Star";
import img3 from "../../assets/Dashboard/share.png";
import Navbar2 from "../Navbar/Navbar2";
import Box from "@mui/material/Box";
import { ShareSocial } from "react-share-social";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
export default function WebsiteBanner({ width, websitedata }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const style1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90vw",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 1,
  };
  const [reversearray, setReversearray] = useState([]);
  useEffect(() => {
    setReversearray(websitedata?.happyCustomers?.reverse());
  }, [websitedata]);
  const style5 = {
    root: {
      background: "white",
      borderRadius: 3,
      border: 0,
      padding: "0vw",
      color: "black",
      width: "100%",
      maxWidth: "100%",
    },
    copyContainer: {
      border: "1px solid blue",
      background: "rgb(0,0,0,0.4)",
      padding: "1vw 1vw",
    },
    title: {
      color: "#0052cc !important",
      fontStyle: "italic",
      marginBottom: "0vw",
      marginTop: "1vw",
    },
  };
  const [openx, setOpenx] = React.useState(false);
  const handleOpenx = () => setOpenx(true);
  const handleClosex = () => setOpenx(false);
  return (
    <>
      <div style={{ overflow: "hidden", height: "0vw" }} className="kok">
        <Navbar2 width={width} />
      </div>
      <div className="kok">
        <div
          style={{ background: websitedata?.themeColor }}
          className="kok1"
        ></div>
      </div>
      <Modal
        open={openx}
        onClose={handleClosex}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={width > 700 ? style : style1}>
          <div className="profiletitleandmenunav">
            <div className="profiledetailstitle">Share</div>
            <div className="profiledetailnavmanu">
              <div>
                <CloseIcon
                  onClick={handleClosex}
                  style={{
                    fontSize: width > 700 ? "1.5vw" : "4vw",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          </div>
          <hr style={{ color: "#000000" }} />
          <ShareSocial
            style={style5}
            url={`${websitedata?.websiteName}  -Click here: ${
              "https://www.tool44.com/" +
              websitedata?.websiteName
                .replaceAll(" ", "-")
                .replaceAll("/", "_")
                .replaceAll("?", "_") +
              "/" +
              websitedata?.websiteId
            }. Enjoy!`}
            socialTypes={[
              "facebook",
              "whatsapp",
              "twitter",
              "linkedin",
              "telegram",
              "reddit",
              "line",
              "instapaper",
              "hatena",
              "email",
              "livejournal",
              "mailru",
            ]}
          />
        </Box>
      </Modal>

      {width > 700 ? (
        <div className="Website-banner">
          <div className="websitebannerdetail">
            <div className="navofwebsite">
              <img src={websitedata?.websiteLogo} alt="" />
              <div className="websitelogoname">
                {websitedata?.websiteName}
                <div
                  style={{
                    fontSize: "1vw",
                    lineHeight: "2.1vw",
                    fontWeight: "500",
                  }}
                >
                  {websitedata?.metaDescription}
                </div>
              </div>
            </div>
            <div
              style={{ color: websitedata?.themeColor }}
              className="webbannertitle"
            >
              {websitedata?.bannerTitle}
            </div>
            <div
              className="webbannertitle1"
              dangerouslySetInnerHTML={{ __html: websitedata?.headline }}
            ></div>
            <div
              className="webbannertitle2"
              dangerouslySetInnerHTML={{ __html: websitedata?.headline2 }}
            ></div>

            <div className="OurHappycustomer">
              Our Happy Customer{" "}
              <span>
                {" "}
                <img
                  onClick={() => handleOpenx()}
                  style={{
                    margin: "0.5vw 0.25vw",
                    width: width > 700 ? "1.5vw " : "4vw",
                    height: width > 700 ? "1.4vw" : "4vw",

                    cursor: "pointer",
                  }}
                  src={img3}
                  alt=""
                />
              </span>
            </div>

            <div style={{ display: "flex" }}>
              {reversearray?.slice(0, 7)?.map((data, index) => {
                return (
                  <div
                    className="personiconwebsite"
                    style={{ right: `${index * 0.61}vw` }}
                  >
                    <img src={data?.user_id?.media} alt="" />
                  </div>
                );
              })}
              {reversearray?.length === 0 && (
                <div className="personiconwebsite">
                  <img
                    src={
                      "https://44resourcesimages.s3.amazonaws.com/d2ea44e1-9a8b-484c-85a3-34c98c7adee3-FkjaBcxtfV%24man.png"
                    }
                    alt=""
                  />
                </div>
              )}
            </div>

            <div style={{ display: "flex" }}>
              <StarIcon
                style={{
                  width: "1.3vw",
                  margin: "0.2vw",
                  height: "1vw",
                  color: websitedata?.themeColor,
                }}
              />
              <StarIcon
                style={{
                  width: "1.3vw",
                  margin: "0.2vw",
                  height: "1vw",
                  color: websitedata?.themeColor,
                }}
              />
              <StarIcon
                style={{
                  width: "1.3vw",
                  margin: "0.2vw",
                  height: "1vw",
                  color: websitedata?.themeColor,
                }}
              />
              <StarIcon
                style={{
                  width: "1.3vw",
                  margin: "0.2vw",
                  height: "1vw",
                  color: websitedata?.themeColor,
                }}
              />
              <StarIcon
                style={{
                  width: "1.3vw",
                  margin: "0.2vw",
                  color: websitedata?.themeColor,
                  height: "1vw",
                }}
              />
            </div>
          </div>
          <div className="websitebannerimg">
            <img src={websitedata?.bannerImage} alt="" />
          </div>
        </div>
      ) : (
        <>
          <div className="Website-banner">
            <div className="websitebannerdetail">
              <div className="navofwebsite">
                <img src={websitedata?.websiteLogo} alt="" />
                <div className="websitelogoname">
                  {websitedata?.websiteName}{" "}
                  <div
                    style={{
                      fontSize: "2.6vw",
                      lineHeight: "3vw",
                      fontWeight: "500",
                    }}
                  >
                    {websitedata?.metaDescription}
                  </div>
                </div>
              </div>
              <div
                style={{ color: websitedata?.themeColor }}
                className="webbannertitle"
              >
                {websitedata?.bannerTitle}
              </div>
            </div>
            <div className="websitebannerimg">
              <img src={websitedata?.bannerImage} alt="" />
            </div>
          </div>
          <div
            className="webbannertitle1"
            dangerouslySetInnerHTML={{ __html: websitedata?.headline }}
          ></div>
          {websitedata?.headline2 && (
            <div
              className="webbannertitle2"
              dangerouslySetInnerHTML={{ __html: websitedata?.headline2 }}
            ></div>
          )}

          <div className="OurHappycustomer">
            Our Happy Customer{" "}
            <span>
              {" "}
              <img
                onClick={() => handleOpenx()}
                style={{
                  margin: "0.5vw 0.25vw",
                  width: width > 700 ? "1.5vw " : "4vw",
                  height: width > 700 ? "1.4vw" : "4vw",

                  cursor: "pointer",
                }}
                src={img3}
                alt=""
              />
            </span>
          </div>

          <div style={{ display: "flex" }}>
            {reversearray?.slice(0, 7)?.map((data, index) => {
              return (
                <div
                  className="personiconwebsite"
                  style={{ right: `${index * (width > 700 ? 0.61 : 3.3)}vw` }}
                >
                  <img src={data?.user_id?.media || "https://44resourcesimages.s3.amazonaws.com/d2ea44e1-9a8b-484c-85a3-34c98c7adee3-FkjaBcxtfV%24man.png"} alt="" />
                </div>
              );
            })}
            {reversearray?.length === 0 && (
              <div className="personiconwebsite">
                <img
                  src={
                    "https://44resourcesimages.s3.amazonaws.com/d2ea44e1-9a8b-484c-85a3-34c98c7adee3-FkjaBcxtfV%24man.png"
                  }
                  alt=""
                />
              </div>
            )}
          </div>

          <div style={{ display: "flex", paddingLeft: "2vw" }}>
            <StarIcon
              style={{
                width: "3vw",
                margin: "0.51vw",
                height: "3vw",
                color: websitedata?.themeColor,
              }}
            />
            <StarIcon
              style={{
                width: "3vw",
                margin: "0.51vw",
                height: "3vw",
                color: websitedata?.themeColor,
              }}
            />
            <StarIcon
              style={{
                width: "3vw",
                margin: "0.51vw",
                height: "3vw",
                color: websitedata?.themeColor,
              }}
            />
            <StarIcon
              style={{
                width: "3vw",
                margin: "0.51vw",
                height: "3vw",
                color: websitedata?.themeColor,
              }}
            />
            <StarIcon
              style={{
                width: "3vw",
                margin: "0.51vw",
                height: "3vw",
                color: websitedata?.themeColor,
              }}
            />
          </div>
        </>
      )}
    </>
  );
}
