import React from "react";

export default function Howitworkready() {
  return (
    <div
      style={{
        margin: "3vw 0vw",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        height: "30vw",
      }}
      className="homebluebox"
    >
      <div className="homeblueboxtitle">Ready to Get Started ?</div>
      <div className="homeblueboxsubtitle">
        Join our community of 300+ Resources of all sizes who use Tool44 Latest
        Technology and Products make with our experts candidate and easy to
        delightful Customer and Employees
      </div>

      <button style={{ border: "none" }} className="homeworktop-button">
        Join us
      </button>
    </div>
  );
}
