import React, { useEffect, useState } from "react";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate, useParams } from "react-router";
import { Metadata } from "../../Metadata/Metadata";
import axios from "axios";
import API_HOST from "../../../env";
import ReactImageMagnify from "react-image-magnify";
import StarIcon from "@mui/icons-material/Star";
import Slider from "react-slick";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
function SamplePrevArrow(props) {
  return (
    <div
      style={{
        width: props?.width > 700 ? "3vw" : "5vw",
        height: props?.width > 700 ? "3vw" : "5vw",

        borderRadius: "50%",
        background: "#FFF",

        margin: props?.width > 700 ? "1vw" : "3vw",
      }}
      className="d-flex justify-content-center align-items-center"
      onClick={props.onClick}
    >
      <ArrowUpwardIcon alt="prev" />
    </div>
  );
}

function SampleNextArrow(props) {
  return (
    <div
      style={{
        width: props?.width > 700 ? "3vw" : "5vw",
        height: props?.width > 700 ? "3vw" : "5vw",
        borderRadius: "50%",
        background: "#FFF",

        margin: props?.width > 700 ? "1vw" : "3vw",
      }}
      className="d-flex justify-content-center align-items-center"
      onClick={props.onClick}
    >
      <ArrowDownwardIcon alt="next" />
    </div>
  );
}

export default function BookProductDetailpage({ width }) {
  const navigate = useNavigate();
  const { id } = useParams();

  const [data, setData] = useState();

  useEffect(() => {
    axios.get(`${API_HOST}/webmodel/byId?webId=${id}`).then((res) => {
      setData(res?.data?.success?.data);
    });
  }, [id]);

  let settings = {
    infinite: false,
    speed: 250,
    arrows: true,
    slidesToShow: 3,
    initialSlide: 0,
    slidesToScroll: 2,
    vertical: true,
    swipeToSlide: true,
    prevArrow: <SamplePrevArrow width={width} />,
    nextArrow: <SampleNextArrow width={width} />,
  };
  const [displayImage, setDisplayImage] = useState(0);
  const sliderClick = (value) => {
    setDisplayImage(value);
  };
  const [contentNameRoute, setcontentNameRoute] = useState();

  useEffect(() => {
    const myStr = data?.website_id?.websiteName;
    setcontentNameRoute(
      myStr?.replaceAll(" ", "-")?.replaceAll("/", "_")?.replaceAll("?", "_")
    );
  }, [data]);

  return (
    <div
      style={{
        height: width > 700 ? "calc(100vh - 4vw )" : "calc(100vh - 14vw )",
        background: "white",
      }}
      className="ScrollTable"
    >
      <Metadata
        title={`${data?.product_id?.productName}`}
        description={data?.product_id?.description}
        keyword={`${data?.product_id?.productName} ,${data?.product_id?.description} ,${data?.product_id?.longDescription}`}
      ></Metadata>
      <div
        style={{
          width: "100%",
          background: "white",
          padding: width > 700 ? "1vw 1vw" : "2vw",
          paddingBottom: "0vw",
        }}
      >
        <button
          style={{
            cursor: "pointer",
            zIndex: "100",
            padding: "0.5vw 0.7vw",
            backgroundColor: "white",
            color: "#000",
            fontSize: "1.2vw",
            borderRadius: "0.3vw",
            border: "1px solid #d7d7d7",
            marginBottom: width > 700 ? "1vw" : "3vw",
            marginTop: "0vw",
          }}
          onClick={() => navigate(-1)}
        >
          <ArrowBackIosNewIcon />
        </button>
      </div>

      <div
        style={{
          width: "100%",
          background: "white",
          display: "flex",
          padding: width > 700 ? "1vw 1vw" : "2vw",
          paddingTop: "0vw",
          flexDirection: width > 700 ? "" : "column",
        }}
      >
        <div className="product-wrapper">
          <div className="productpage">
            <ul
              style={{
                marginTop:
                  data?.product_id?.files?.length < 4
                    ? (4 - data?.product_id?.files?.length) * 4 + "vw"
                    : "1vw",

                height: width > 700 ? "" : "20vw",
                position: width > 700 ? "" : "relative",
                top: width > 700 ? "" : "2vw",
                left:
                  width > 700
                    ? ""
                    : data?.product_id?.files?.length === 1
                    ? "1vw"
                    : data?.product_id?.files?.length === 2
                    ? "6vw"
                    : data?.product_id?.files?.length === 3
                    ? "12vw"
                    : "25vw",
              }}
              className="preview-thumbnail nav nav-tabs"
            >
              {data?.product_id?.files.length && (
                <Slider {...settings}>
                  {data?.product_id?.files.map((img, index) => (
                    <li
                      key={index}
                      className={displayImage === index ? "imageActive" : ""}
                    >
                      <span
                        data-target={`#pic-${index}`}
                        data-toggle="tab"
                        onMouseOver={() => sliderClick(index)}
                      >
                        <img
                          src={img?.file}
                          style={{ width: "100%" }}
                          alt="iiiii"
                        />
                      </span>
                    </li>
                  ))}
                </Slider>
              )}
            </ul>

            <div className="productImg">
              <div style={{ width: "100%", height: "100%" }}>
                <ReactImageMagnify
                  style={{ width: "100%", height: "100%" }}
                  imageClassName="smallImage"
                  {...{
                    smallImage: {
                      alt: "Wristwatch by Ted Baker London",
                      isFluidWidth: true,
                      // width: 400,
                      // height: 400,
                      src: data?.product_id?.files[displayImage]?.file,
                      sizes: "(max-width: 1200px) 30vw, 360px",
                    },
                    largeImage: {
                      src: data?.product_id?.files[displayImage]?.file,
                      width: 1400,
                      height: 1500,
                    },
                    shouldUsePositiveSpaceLens: true,
                    lensStyle: {
                      background: "hsla(0, 0%, 100%, .3)",
                      border: "1px solid #ccc",
                    },
                    enlargedImageContainerDimensions: {
                      width: "120%",
                      height: "100%",
                    },
                    enlargedImageContainerStyle: {
                      marginTop: "-1vw",
                      zIndex: 10,
                      background: "#fff",
                    },
                    enlargedImageStyle: {
                      objectFit: "contain",
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            background: "white",
          }}
          className="product-wrapper1"
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginLeft: "0vw" }} className="tagblue">
              {data?.product_id?.category?.category}
            </div>
            <span
              style={{
                color: "#0052cc",
                fontSize: width > 700 ? "0.9vw" : "2.7vw",
                marginLeft: "1vw",
              }}
            >
              {data?.product_id?.type}
            </span>
          </div>
          <div className="Nameofproduct">{data?.product_id?.productName}</div>
          <div className="headingofproduct">Description :</div>
          <div className="descriptionofproduct">
            {data?.product_id?.description}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="headingofproduct">Price :</div>
              <div
                style={{ margin: "0 0.5vw" }}
                className="descriptionofproduct"
              >
                <span
                  style={{
                    fontSize: width > 700 ? "1.3vw" : "4vw",
                    color: "#0052cc",
                  }}
                >
                  Rs {data?.product_id?.costPrice}
                </span>{" "}
                {data?.product_id?.sellingPrice && (
                  <del> Rs {data?.product_id?.sellingPrice}</del>
                )}
                {data?.product_id?.sellingPrice && (
                  <span style={{ color: "#0052cc", margin: "0 1vw" }}>
                    {" "}
                    {parseFloat(
                      ((data?.product_id?.sellingPrice -
                        data?.product_id?.costPrice) *
                        100) /
                        data?.product_id?.sellingPrice
                    ).toFixed(2)}
                    % off{" "}
                  </span>
                )}
              </div>
            </div>

            <div
              style={{
                color: "#0052cc",
                display: "flex",
                alignItems: "center",
                marginRight: "2vw",
              }}
            >
              {data?.product_id?.rating?.$numberDecimal
                ? data?.product_id?.rating?.$numberDecimal?.slice(0, 1)
                : 0}
              <StarIcon
                style={{ width: width > 700 ? "1.2vw" : "4vw", margin: "0vw" }}
              />{" "}
              (
              {data?.product_id?.completedOrder
                ? data?.product_id?.completedOrder
                : 0}
              )
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="headingofproduct">
                {" "}
                {data?.product_id?.type === "Service" ? "Type" : "Stock"} :{" "}
              </div>{" "}
              <div
                style={{
                  color: data?.quantity > 0 ? "" : "red",
                  fontSize: width > 700 ? "1.1vw" : "3vw",
                  margin: "0 1vw",
                }}
                className="stockmenuweb"
              >
                {data?.product_id?.type === "Service" ? (
                  data?.product_id.serviceType
                ) : (
                  <span>
                    {" " + data?.quantity > 0
                      ? " " + data?.quantity + " Items Left"
                      : "Out of Stock"}
                  </span>
                )}
              </div>
            </div>
            <div style={{ width: width > 700 ? "15vw" : "fit-content" }}>
              <div
                style={{ width: width > 700 ? "13vw" : "fit-content" }}
                onClick={() =>
                  data?.website_id?.websiteName
                    ? navigate(
                        `/${contentNameRoute}/${data?.website_id?.websiteId}`
                      )
                    : navigate(`/-/${data?.website_id?.websiteId}`)
                }
                className="Visitwebsitebutton"
              >
                Visit Website
              </div>
            </div>
          </div>

          {data?.product_id?.addOn?.length > 0 && (
            <div className="headingofproduct">Add On :</div>
          )}

          {data?.product_id?.addOn?.map((data) => {
            return (
              <div className="addonbox">
                <div className="addondata">
                  <div className="addonimg">
                    <img src={data.image} alt="" />
                  </div>
                  <div style={{ width: "70%" }} className="addonname">
                    {data.name}
                  </div>
                  <div style={{ width: "20%" }} className="addonname">
                    Rs {data.amount}
                  </div>
                </div>
                <div className="addondesc">{data?.description}</div>
              </div>
            );
          })}
          <div className="headingofproduct">Details :</div>
          <div
            className=""
            dangerouslySetInnerHTML={{
              __html: data?.product_id?.longDescription,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
