import React, { useEffect, useState } from "react";

import Profileuserdetaillanguage from "./Profilepopup/Profileuserdetaillanguage";

import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import InstagramIcon from "@mui/icons-material/Instagram";


export default function Profileuserdetails({ width, user }) {

  const [instaLink, setInstaLink] = useState("");
  const [lLink, setllink] = useState("");
  const [fLink, setflink] = useState("");
  const [gLink, setglink] = useState("");

  useEffect(() => {
    if (user?.userName) {
      setllink(user?.lLink);
      setglink(user?.gLink);
      setInstaLink(user?.gitLink);
      setflink(user?.fLink);
    }
  }, [user]);

  return (
    <div className='Profileuserdetails-container'>
      <div className='profileuserdetailsone'>
        <div className='profiletitleandmenunav'>
          <div className='profiledetailstitle'>User profile</div>
          <div className='profiledetailnavmanu'></div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '0.5vw',
          }}
          className='profileuserfirstonetitle'
        >
          Social Link <div style={{ width: '13%', textAlign: 'center' }}></div>
        </div>

        <div
          style={{ flexWrap: 'wrap', justifyContent: 'flex-start' }}
          className='profileuserdfirst'
        >
          {fLink && (
            <div style={{ width: '25%', textAlign: 'center' }}>
              <a
                href={fLink}
                target='_blank'
              >
                <FacebookIcon
                  style={{
                    width: width > 700 ? '2vw' : '5vw',
                    cursor: 'pointer',
                    color: '#0052cc',
                  }}
                />
              </a>
            </div>
          )}
          {gLink && (
            <div style={{ width: '25%', textAlign: 'center' }}>
              {' '}
              <a
                href={gLink}
                target='_blank'
              >
                <GoogleIcon
                  style={{
                    width: width > 700 ? '2vw' : '5vw',
                    cursor: 'pointer',
                    color: '#0052cc',
                  }}
                />
              </a>
            </div>
          )}
          {lLink && (
            <div style={{ width: '25%', textAlign: 'center' }}>
              {' '}
              <a
                href={lLink}
                target='_blank'
              >
                <LinkedInIcon
                  style={{
                    width: width > 700 ? '2vw' : '5vw',
                    cursor: 'pointer',
                    color: '#0052cc',
                  }}
                />
              </a>
            </div>
          )}

          {instaLink && (
            <div style={{ width: "25%", textAlign: "center" }}>
              {" "}
              <a href={instaLink} target="_blank">
                <InstagramIcon
                  style={{
                    width: width > 700 ? '2vw' : '5vw',
                    cursor: 'pointer',
                    color: '#0052cc',
                  }}
                />
              </a>
            </div>
          )}
        </div>

        <div className='profileuserdfirst'>
          <div className='profileuserfirstone'>
            <div
              style={{
                display: 'flex',

                alignItems: 'center',
              }}
              className='profileuserfirstonetitle'
            >
              <div>Language</div>
            </div>

            {user?.languages?.map((language) => {
              return (
                <Profileuserdetaillanguage
                  language={language}
                  width={width}
                />
              );
            })}
          </div>
        </div>

        <div
          style={{ marginBottom: '0.3vw' }}
          className='profileuserfirstonetitle'
        >
          DOB -
          {user?.dob && (
            <span>
              {new Date(user?.dob).getDate()}/
              {new Date(user?.dob).getMonth() + 1}/
              {new Date(user?.dob).getFullYear()}
            </span>
          )}
        </div>

        <div className='profileuserfirstonetitle'>
          Address - {user?.address}{' '}
        </div>
      </div>
      <div className='profileuserdetailstwo'></div>
      <div
        style={{ marginTop: '1vw' }}
        className='profileuserdetailsthree'
      >
        <div
          style={{}}
          className='profiletitleandmenunav'
        >
          <div className='profiledetailstitle'>Work title</div>
          <div className='profiledetailnavmanu'></div>
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              fontSize: width > 700 ? '1.1vw' : '3.1vw',
              margin: '0.5vw 1vw',
            }}
            className='profiledetailstitle'
          >
            {user?.title ? user?.title : 'Update Your Title'}
          </div>
        </div>

        <div className='profiledetailstitle'>About Me </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div
            style={{
              fontSize: width > 700 ? '1.1vw' : '3.1vw',
              margin: '0.5vw 1vw',
            }}
            className='profiledetailstitle'
          >
            <div
              className='aboutprofileuser'
              dangerouslySetInnerHTML={{
                __html: user?.fullDescription
                  ? user?.fullDescription
                  : 'Enter Your description',
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}
