import React, { useState, useEffect } from "react";
import DoneIcon from "@mui/icons-material/Done";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core";
import API_HOST from "../../env";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { userActions } from "../../store/userSlice";
import "./Emailverify.css";
import CloseIcon from "@mui/icons-material/Close";
import { authentication } from "./firebase-config";
import { RecaptchaVerifier } from "firebase/auth";
import { signInWithPhoneNumber } from "firebase/auth";
const useStyles = makeStyles((theme) => ({
  input: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "0.91vw",
    width: "100%",
    color: "#263238",
  },
  input1: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "2.7vw",
    width: "100%",
    color: "#263238",
  },
}));

export default function PhoneVerify({
  toggleDrawer,
  closedrawer,
  width,
  handleContactModalclose,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [Emailuser, setEmailuser] = useState("");
  useEffect(() => {
    setEmailuser(user?.contactNo);
    setEmail(user?.contactNo);
  }, [user]);

  const [OTPShow, setOTPShow] = useState(false);
  const [emailShow, setEmailShow] = useState(true);

  const [labelOTP, setLabelOTP] = useState("");
  const [bacgroundcolor, setBacgroundcolor] = useState("#0052cc");

  const [showdonetick, setShowdonetick] = useState(false);

  const [bordercolor, setBordercolor] = useState("#E8E8E8");
  const [labelcolor, setlabelcolor] = useState("#6B6B6B");

  const [bordercolor1, setBordercolor1] = useState("#E8E8E8");
  const [labelcolor1, setlabelcolor1] = useState("#6B6B6B");

  const [email, setEmail] = useState(user?.emailId ? user?.emailId : "");
  const [otp, setOtp] = useState("");

  const generateCaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha_container",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          console.log("Recaptca verified");
        },
      },
      authentication
    );
  };

  const onSignInSubmit = () => {
    // e.preventDefault();
    generateCaptcha();
    const phoneNumber = "+91" + email;
    console.log(phoneNumber);
    let appVerifier = window.recaptchaVerifier;

    signInWithPhoneNumber(authentication, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        console.log("OTP has been sent");
        // handleOpen();
        setOTPShow(true);
        // ...
      })
      .catch((error) => {
        console.log(error, "SMS not sent");
      });
  };

  const savemobile = () => {
    const formdata = new FormData();

    formdata.append("userId", user?.userId);
    formdata.append("contactNo", email);
    axios
      .post(`${API_HOST}/users/editEmail`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        setOTPShow(false);
        setLoginerror("");
        handleClose();
        setShowsave(false);
        window.localStorage.setItem(
          "user",
          JSON.stringify({ ...res.data.success.data })
        );

        dispatch(
          userActions.setUser({
            user: { ...res.data.success.data },
          })
        );
        setSuccessbox(
          "Mobile Successfully  Updated ! , Click Close Icon to Side Tab"
        );
      })
      .catch((err) => {
        setLoginerror(err.response?.data?.fails?.message);
        handleClose();
      });
  };

  const [showsave, setShowsave] = useState(false);

  const verifyOTP = (e) => {
    // let otp = e.target.value;
    handleToggle();
    setOtp(otp);

    if (otp.length === 6) {
      console.log(otp);
      let confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(otp)
        .then((result) => {
          // User signed in successfully.
          const userFirebase = result.user;
          if (userFirebase) {
            handleClose();
            savemobile();
            setShowsave(true);
            setOTPShow(false);
            setEmailShow(false);
          }
          handleClose();
        })
        .catch((error) => {
          console.log(error.message);
          if (error.message === "'undefined' is not valid JSON") {
            console.log();
            handleClose();
            savemobile();
            setShowsave(true);
            setOTPShow(false);
            setEmailShow(false);
          } else {
            handleClose();
          }
          handleClose();
        });
    }
  };

  const [loginerror, setLoginerror] = useState("");
  const [successbox, setSuccessbox] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    console.log(false);
  };
  
  const handleToggle = () => {
    setOpen(true);
    console.log(true);
  };

  return (

    <div className=''>
      <div className=''>
        <div style={{ position: 'relative' }}>
          <div
            className='login-box'
            style={{ marginTop: '0.5vmax' }}
          >

            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              className="loginheading"
            >

              <div className='profiledetailstitle'>
                Verify Mobile And Update
              </div>
              <div className='profiledetailnavmanu'>
                <div>
                  <CloseIcon
                    onClick={handleContactModalclose}
                    style={{
                      fontSize: width > 700 ? '1.5vw' : '4vw',
                      cursor: 'pointer',
                    }}
                  />
                </div>
              </div>
            </div>
            {emailShow ? (
              <>
                {" "}
                <div
                  style={{
                    border:
                      bordercolor === "#FF0303"
                        ? "2px solid #FF0303"
                        : "1px solid #E8E8E8",
                  }}
                  className="email-box"
                >
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": { width: "25ch" },
                      "& .MuiTextField-root": {
                        mt: "0.75vw",
                        pl: 2,
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      type="number"
                      InputProps={{ disableUnderline: true }}
                      id="standard-basic"
                      label={"Mobile"}
                      value={email}
                      disabled={OTPShow ? true : false}
                      placeholder="number"
                      inputProps={{
                        className: width > 700 ? classes.input : classes.input1,
                      }}
                      InputLabelProps={{
                        style: {
                          color: `${labelcolor}`,
                          fontSize: width > 700 ? "1vw" : "3vw",
                          marginLeft: "15px",
                          fontStyle: "normal",
                          fontWeight: "500",
                        },
                      }}
                      onChange={(e) => {
                        setEmail(e.target.value);

                        setOTPShow(false);
                        setLabelOTP("");

                        setBacgroundcolor("#0052cc");
                      }}
                      variant="standard"
                    />
                  </Box>
                </div>
              </>
            ) : (
              <>
                <p></p>
              </>
            )}

            {OTPShow && (
              <>
                <div
                  style={{
                    border:
                      bordercolor1 === "#FF0303"
                        ? "2px solid #FF0303"
                        : "1px solid #E8E8E8",
                  }}
                  className="email-box"
                >
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": { width: "25ch" },
                      "& .MuiTextField-root": {
                        mt: "0.75vw",
                        pl: 2,
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      type="password"
                      InputProps={{ disableUnderline: true }}
                      id="standard-basic"
                      label={"OTP"}
                      value={otp}
                      //   inputProps={{
                      //     className: width > 700 ? classes.input : classes.input1,
                      //   }}
                      placeholder="OTP"
                      InputLabelProps={{
                        style: {
                          fontSize: width > 700 ? "1vw" : "3vw",

                          marginLeft: "15px",

                          fontStyle: "normal",
                          fontWeight: "500",
                          color: `${labelcolor1}`,
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setOtp(e.target.value);
                        setBordercolor1("#E8E8E8");
                        setlabelcolor1("#6B6B6B");
                        if (e.target.value) {
                          setLabelOTP("OTP");
                        } else {
                          setLabelOTP("");
                        }
                      }}
                    />
                  </Box>
                </div>
              </>
            )}

            {Emailuser !== email && !OTPShow && !showsave && (
              <button
                style={{ backgroundColor: `${bacgroundcolor}` }}
                onClick={() => {
                  onSignInSubmit();
                }}

                className='contact_loginButton'

              >
                <span>
                  {" "}
                  {showdonetick ? (
                    <>
                      <DoneIcon
                        style={{ fontSize: "19px", marginBottom: "2px" }}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  Get OTP
                </span>
                <div id="recaptcha_container"></div>
              </button>
            )}

            {Emailuser !== email && OTPShow && (
              <button
                style={{ backgroundColor: `${bacgroundcolor}` }}
                onClick={verifyOTP}

                className='contact_loginButton'

              >
                <span>
                  {" "}
                  {showdonetick ? (
                    <>
                      <DoneIcon
                        style={{ fontSize: "19px", marginBottom: "2px" }}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  Verify
                </span>
              </button>
            )}
            {showsave && (
              <button
                style={{ backgroundColor: `${bacgroundcolor}` }}
                onClick={verifyOTP}

                className='contact_loginButton'

              >
                <span>
                  {" "}
                  {showdonetick ? (
                    <>
                      <DoneIcon
                        style={{ fontSize: "19px", marginBottom: "2px" }}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  Save
                </span>
              </button>
            )}
          </div>
        </div>
        {loginerror && (
          <div
            style={{
              width: width > 700 ? "25vw" : "80vw",
              marginTop: width > 700 ? "2vw" : "6vw",
            }}
            className="Errorbox"
          >
            {loginerror}
          </div>
        )}
        {successbox && (
          <div
            style={{
              border: "1px solid #0085ff",
              background: "#0052cc10",
              color: "#0052cc",

              width: width > 700 ? "25vw" : "80vw",
              marginTop: width > 700 ? "1vw" : "3vw",
              paddingLeft: width > 700 ? "0.51vw" : "1vw",
            }}
            className="Errorbox"
          >
            {successbox}
          </div>
        )}
      </div>
    </div>
  );
}
