import React from "react";

import ProfileCatelogdetails1 from "../DashbaordSidebarComponents/BookCatalogues/ProfileCatelogDetails";

export default function CategorySearchItem({
  searchedData,
  CatalogueTtile,
  setpage,
  page,
  width,
}) {
  return (
    <div
      style={{
        width: "100vw",
        padding: width > 700 ? "2vw 5vw" : "2vw",
        paddingBottom: "0vw",
      }}
    >
      <div className="headingpopularcate">{CatalogueTtile}</div>
      <div
        style={{ margin: width > 700 ? "2vw" : "0vw" }}
        className={width > 700 ? "pcatalofboxes" : "catalogcontainerdashbaord"}
      >
        {searchedData?.length > 0 ? (
          searchedData?.map((catalogue, index) => {
            return <ProfileCatelogdetails1 data={catalogue} width={width} />;
          })
        ) : (
          <div
            style={{
              textAlign: "center",
              width: "100%",
              lineHeight: width > 700 ? "1vw" : "5vw",
              fontSize: width > 700 ? "1.5vw" : "4vw",
              fontWeight: "500",
            }}
          >
            There are no Prducts related to This category or subCategory!
          </div>
        )}
      </div>
      {searchedData?.length > 5 && (
        <div className="ViewMorebutton" onClick={() => setpage(page + 1)}>
          View More
        </div>
      )}
    </div>
  );
}
