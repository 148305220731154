import React from "react";
import img1 from "../../assets/About/istockphoto-1346000818-170667a.png";
import img3 from "../../assets/Landing page/checkmark (1).svg";
import img2 from "../../assets/About/procreator-ux-design-studio-VzJjPuk53sk-unsplash.png";
export default function AboutVision({ width }) {
  return (
    <div style={{ marginTop: "3vw" }} className="Aboutabout-container">
      <div className="abouttitileheading"> Our Vision</div>
      <div
        style={{
          marginTop: "5vw",
          display: width > 700 ? "flex" : "block",
          alignItems: "center",
        }}
      >
        <div style={{ width: width > 700 ? "55%" : "100%" }}>
        

          <div   style={{marginRight:"5vw" ,marginBottom:"1vw" ,alignItems:width<=700 && "flex-start" }} className="tickmarkworkdoservicebox">
            <img style={{ width: width>700? "2.3vw":"5vw" }} src={img3} alt="" />
            <div style={{ fontSize: width>700? "1.4vw":"4vw" }}>
              Providing a seamless and user-friendly interface for businesses
              and freelancers to connect, collaborate and transact.{" "}
            </div>
          </div>
          <div  style={{marginRight:"5vw" ,marginBottom:"1vw" ,alignItems:width<=700 && "flex-start" }} className="tickmarkworkdoservicebox">
            <img style={{ width: width>700? "2.3vw":"5vw" }} src={img3} alt="" />
            <div style={{ fontSize: width>700? "1.4vw":"4vw" }}>
              Offering a diverse range of project categories to cater to the
              needs of different businesses and freelancers.
            </div>
          </div>
          <div  style={{marginRight:"5vw" ,marginBottom:"1vw" ,alignItems:width<=700 && "flex-start" }} className="tickmarkworkdoservicebox">
            <img style={{ width: width>700? "2.3vw":"5vw" }} src={img3} alt="" />
            <div style={{ fontSize: width>700? "1.4vw":"4vw" }}>
              Ensuring a secure and transparent payment system to protect the
              interests of both parties.{" "}
            </div>
          </div>
          <div  style={{marginRight:"5vw" ,marginBottom:"1vw" ,alignItems:width<=700 && "flex-start" }} className="tickmarkworkdoservicebox">
            <img style={{ width: width>700? "2.3vw":"5vw" }} src={img3} alt="" />
            <div style={{ fontSize: width>700? "1.4vw":"4vw" }}>
              Building a strong and engaged community of freelancers and
              businesses, where they can network, share knowledge, and grow
              together.{" "}
            </div>
          </div>
          <div  style={{marginRight:"5vw" ,marginBottom:"1vw" ,alignItems:width<=700 && "flex-start" }} className="tickmarkworkdoservicebox">
            <img style={{ width: width>700? "2.3vw":"5vw" }} src={img3} alt="" />
            <div style={{ fontSize: width>700? "1.4vw":"4vw" }}>
              Providing tools and resources to help freelancers enhance their
              skills and market themselves effectively.{" "}
            </div>
          </div>
          <div  style={{marginRight:"5vw" ,marginBottom:"1vw" ,alignItems:width<=700 && "flex-start" }} className="tickmarkworkdoservicebox">
            <img style={{ width: width>700? "2.3vw":"5vw" }} src={img3} alt="" />
            <div style={{ fontSize: width>700? "1.4vw":"4vw" }}>
              Continuously innovating and improving the platform to provide the
              best possible experience for users.
            </div>
          </div>
          <div  style={{marginRight:"5vw" ,marginBottom:"1vw" ,alignItems:width<=700 && "flex-start" }} className="tickmarkworkdoservicebox">
            <img style={{ width: width>700? "2.3vw":"5vw" }} src={img3} alt="" />
            <div style={{ fontSize: width>700? "1.4vw":"4vw" }}>
              Committing to ethical and sustainable business practices,
              promoting diversity and inclusiveness, and playing a positive role
              in the gig economy.
            </div>
          </div>
        </div>
        <div className="aboutaboutimg">
          <img style={{ borderRadius: "0.4vw" }} src={img1} alt="" />
        { width>700 &&  <div style={{ marginTop: "5vw" }}>
            <img style={{ borderRadius: "0.4vw" }} src={img2} alt="" />
          </div>}
        </div>
      </div>
    </div>
  );
}
