import React from "react";
import imageCompression from "browser-image-compression";
import img from "../../../assets/Web 1280 – 14/Icon.svg";
import img1 from "../../../assets/Web 1280 – 14/Group 9831.svg";
import img2 from "../../../assets/Web 1280 – 14/Iconly-Light-outline-Close Square.svg";
import axios from "axios";
import API_HOST from "../../../env";
import { makeStyles } from "@material-ui/core";

import { TextField } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  select: {
    height: "2.5vw",
    width: "100%",
    marginTop: "0.2vw",
    padding: "1vw",
    marginLeft: "0vw",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "1vw",
    lineHeight: "120%",
    color: "#FFFFFF",
  },
  select2: {
    height: "1vw",
    width: "100%",
    marginTop: "0.1vw",
    padding: "0.9vw 0.5vw",
    marginLeft: "0vw",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "0.51vw",
    lineHeight: "100%",
    color: "#FFFFFF",
  },
  select3: {
    height: "1vw",
    width: "100%",
    marginTop: "0.1vw",
    padding: "0vw 0vw",
    marginLeft: "0vw",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "0.51vw",
    lineHeight: "100%",
    color: "#FFFFFF",
    position: "relative",
    top: "0.81vw",
    left: "0.2vw",
  },
  icon: {
    fill: "white",
  },
}));
export default function Offers({
  data,
  width,
  index,
  arrayofservices,
  setArrayofservices,
}) {
  const classes = useStyles();

  const upadteimage = (img) => {
    const formdata = new FormData();
    formdata.append("fileName", img);
    formdata.append("folderName", "productObjects")
    axios
      ?.post(`${API_HOST}/contentManagement/tableContent`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        setArrayofservices([
          ...arrayofservices.slice(0, index),
          {
            name: data?.name,
            description: data?.description,
            image: res.data?.success?.data?.file,
            amount: data?.amount,
          },
          ...arrayofservices.slice(index + 1, arrayofservices?.length),
        ]);
      });
  };

  async function handleImageUpload(event) {
    const imageFile = event.target.files[0];

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log("k");
      await upadteimage(new File([compressedFile], `${compressedFile?.name}`));
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div
        style={{ marginTop: "1vw", flexWrap: "wrap", height: "fit-content" }}
        className="servicesmenudiv"
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: width > 700 ? "97%" : "100%",
            flexDirection: width > 700 ? "row" : "column",
          }}
        >
          <div style={{ width: width > 700 ? "50%" : "100%" }}>
            <div
              style={{
                left: "0vw",
                width: width > 700 ? "94%" : "100% ",
                marginLeft: "0%",
              }}
              className="loginfield"
            >
              <TextField
                id="outlined-basic"
                label="Add-On  Name"
                variant="outlined"
                value={data?.name}
                style={{ width: "100%" }}
                InputLabelProps={{
                  style: {
                    fontSize: width > 700 ? "1vw" : "3vw",
                    fontFamily: "Poppins",
                    fontStyle: "500",
                    fontWeight: "500",
                    color: "black",
                  },
                }}
                inputProps={{ className: classes.input }}
                onChange={(e) => {
                  setArrayofservices([
                    ...arrayofservices.slice(0, index),
                    {
                      name: e.target.value,
                      description: data?.description,
                      image: data?.image,
                      amount: data?.amount,
                    },
                    ...arrayofservices.slice(
                      index + 1,
                      arrayofservices?.length
                    ),
                  ]);
                }}
              />
            </div>
          </div>

          <div style={{ width: width > 700 ? "50%" : "100%" }}>
            <div
              style={{
                left: "0vw",
                width: width > 700 ? "94%" : "100% ",
                marginLeft: "0%",
              }}
              className="loginfield"
            >
              <TextField
                id="outlined-basic"
                label="Add-On Price (Rs)"
                variant="outlined"
                type="number"
                value={data?.amount}
                style={{ width: "100%" }}
                InputLabelProps={{
                  style: {
                    fontSize: width > 700 ? "1vw" : "3vw",
                    fontFamily: "Poppins",
                    fontStyle: "500",
                    fontWeight: "500",
                    color: "black",
                  },
                }}
                inputProps={{ className: classes.input }}
                onChange={(e) => {
                  setArrayofservices([
                    ...arrayofservices.slice(0, index),
                    {
                      name: data?.name,
                      description: data?.description,
                      image: data?.image,
                      amount: e.target.value,
                    },
                    ...arrayofservices.slice(
                      index + 1,
                      arrayofservices?.length
                    ),
                  ]);
                }}
              />
            </div>
          </div>
        </div>

        <div
          style={{
            width: width > 700 ? "94%" : "100%",
            marginTop: "1vw",
          }}
          className="servicesmenuname2box"
        >
          <input
            type="text"
            placeholder={`Add-On-${index + 1} Description`}
            value={data?.description}
            onChange={(e) => {
              setArrayofservices([
                ...arrayofservices.slice(0, index),
                {
                  name: data?.name,
                  description: e.target.value,
                  image: data?.image,
                  amount: data?.amount,
                },
                ...arrayofservices.slice(index + 1, arrayofservices?.length),
              ]);
            }}
          />
        </div>

        <div
          style={{
            width: width > 700 ? "95%" : "100%",
            marginTop: width > 700 ? "1vw" : "3vw",
            height: width > 700 ? "7vw" : "fit-content",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{ height: width > 700 ? "7vw" : "20vw" }}
            className="inputfilebox"
          >
            <div>
              <label htmlFor={`inputctaelogfile${index + 1}`}>
                <div className="inputicon">
                  <img src={img} alt="" />
                </div>
                <div className="inputcateaddformfile">
                  Drag and Drop ,Browse to upload Your Offer {index + 1} Image
                </div>
                <input
                  type="file"
                  id={`inputctaelogfile${index + 1}`}
                  onChange={(e) => {
                    handleImageUpload(e);
                  }}
                  hidden
                />
              </label>
            </div>
          </div>

       </div>
      
            {data?.image && (
                 <div style={{height:"fit-content" ,minHeight:"1.1vw"}} className="inputfilesshowncatebox">
              <div   className="inputfilesshowncatboxsingle">
                <div className="inputfilesshowncatboxsingleimg">
                  <img
                    src={img1}
                    style={{ width: width > 700 ? "" : "4.5vw" }}
                    alt=""
                  />
                </div>
                <div className="fileselctednamecate">{data?.image}</div>
                <div className="inputfilesshowncatboxsingleimg">
                  <img
                    style={{
                      width: width > 700 ? "1.5vw" : "4.5vw",
                      marginLeft: "1vw",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setArrayofservices([
                        ...arrayofservices.slice(0, index),
                        {
                          name: data?.name,
                          heading: data?.heading,
                          image: "",
                          amount: data?.amount,
                        },
                        ...arrayofservices.slice(
                          index + 1,
                          arrayofservices?.length
                        ),
                      ]);
                    }}
                    src={img2}
                    alt=""
                  />
                </div>
              </div>
          </div>
            )}
       
      </div>
     
    </>
  );
}
