import React from "react";
import "./About.css";
import img from "../../assets/About/lycs-architecture-U2BI3GMnSSE-unsplash.png";
import img1 from "../../assets/About/jason-goodman-Oalh2MojUuk-unsplash.png";
import img2 from "../../assets/About/linkedin-sales-solutions-YDVdprpgHv4-unsplash.png";
import img3 from "../../assets/About/damir-kopezhanov-nC6CyrVBtkU-unsplash.png";
import img4 from "../../assets/About/campaign-creators-e6n7uoEnYbA-unsplash.png";
export default function AboutMission({width}) {
  return (
    <div className="Aboutabout-container">
      <div className="abouttitileheading"> Our Mission</div>
      <div style={{width:'92vw'}} className="ourmissionmaintitile">
      By providing easy-to-use and effective technological solutions, we aim to simplify the daily operations of businesses and help them achieve their goals.
      </div>
      <div className="aboutmissionfleximages">
        <div className="img1aboutmision">
          <img src={img} alt="" />
        </div>
        <div className="img2aboutmision">
          <div className="img21aboutmision">
            <img src={img1} alt="" />
          </div>
          <div className="img21aboutmision">
            <img src={img2} alt="" />
          </div>
        </div>
        <div hidden={width > 700 ?false:true} className="img3aboutmision">
          <img src={img3} alt="" />
        </div>
        <div className="img1aboutmision">
          <img src={img4} alt="" />
        </div>
      </div>
   </div>
  );
}
