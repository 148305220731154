import React from "react";

import { useState } from "react";
import axios from "axios";
import API_HOST from "../../env";
import { useEffect } from "react";
import PopularCategorycards from "./PopularCategorycards";

export default function PopularcateAndSubcate({
  page,
  setpage,
  setCategoryid,
  setCatalogueTtile,

  setSearchedData,
  width,
}) {
  const [categoryCards, setCategoryCards] = useState();

  useEffect(() => {
    axios
      .get(
        `${API_HOST}/theCategory/viewCategory?pageSize=2000&pageNumber=1`
      )
      .then((res) => {
        setCategoryCards(res?.data?.success?.data);
       
      })
      .catch((err) => {});
  }, []);

  return (
    <div style={{ width: "100vw", padding: "5vw", paddingTop: "1vw" }}>
   
      <PopularCategorycards
        width={width}
        setSearchedData={setSearchedData}
        setpage={setpage}
        setCatalogueTtile={setCatalogueTtile}
        setCategoryid={setCategoryid}
        categoryCards={categoryCards}
      />
    </div>
  );
}
