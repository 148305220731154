import React, { useEffect, useState } from "react";
import "./DashBoard.css";
import imgx from "../../assets/NewLogo/without tag line.svg";
import img from "../../assets/walletimage/Activity.svg";
import img1 from "../../assets/Dashboard/Iconly-Light-outline-Bookmark.svg";
import img3 from "../../assets/Dashboard/Iconly-Light-Calendar.svg";
import img7 from "../../assets/Dashboard/Iconly-Light-outline-Paper.svg";
import img8 from "../../assets/Dashboard/Iconly-Light-outline-Setting.svg";
import img9 from "../../assets/Dashboard/Iconly-Light-outline-Work.svg";
import img2 from "../../assets/Dashboard/Iconly-Light-outline-Buy.svg";
import img4 from "../../assets/Dashboard/Iconly-Light-outline-Document.svg";
import img89 from "../../assets/Dashboard/Skill center – 2/Iconly-Light-outline-Profile.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { List } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import img5 from "../../assets/Dashboard/Home.svg";
import img6 from "../../assets/Dashboard//Iconly-Light-Search.svg";
import img10 from "../../assets/Dashboard/file-invoice-dollar-solid.svg";
import img12 from "../../assets/Dashboard/website.svg";
import img13 from "../../assets/Dashboard/rocket.svg";
import img14 from "../../assets/Dashboard/success.png";
import img15 from "../../assets/Dashboard/tip.svg";
import img16 from "../../assets/Dashboard/tax.png";
import img17 from "../../assets/Dashboard/expense.png";
import img18 from "../../assets/Dashboard/question (1).png";
import img19 from "../../assets/Dashboard/3 User.svg";
import img20 from "../../assets/Dashboard/Referral.png";
import img21 from "../../assets/Dashboard/Ticket Star.svg";
import img22 from "../../assets/Finince/icons8-fund-48.png";
import img23 from "../../assets/Finince/icons8-calculator-64.png";
import img24 from "../../assets/Finince/icons8-bank-64.png";
import img25 from "../../assets/Dashboard/law.svg";
import img26 from "../../assets/Dashboard/contract.png";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";

export default function BrowseWorkSiadebar({ toggleDrawer, width }) {
  const navigate = useNavigate();

  const location = useLocation();

  const [colorsidebar, setColorsidebar] = useState(1);

  const [open101, setOpen101] = React.useState(false);

  const handleClick101 = () => {
    setOpen101(!open101);
  };
  const [open102, setOpen102] = React.useState(false);

  const handleClick102 = () => {
    setOpen102(!open102);
  };
  const [open103, setOpen103] = React.useState(false);

  const handleClick103 = () => {
    setOpen103(!open103);
  };

  const [open104, setOpen104] = React.useState(false);

  const handleClick104 = () => {
    setOpen104(!open104);
  };

  const [open105, setOpen105] = React.useState(false);

  const handleClick105 = () => {
    setOpen105(!open105);
  };
  const [open106, setOpen106] = React.useState(false);

  const handleClick106 = () => {
    setOpen106(!open106);
  };

  const [open107, setOpen107] = React.useState(false);

  const handleClick107 = () => {
    setOpen107(!open107);
  };
  const [open108, setOpen108] = React.useState(false);

  const handleClick108 = () => {
    setOpen108(!open108);
  };

  const [open109, setOpen109] = React.useState(false);

  const handleClick109 = () => {
    setOpen109(!open109);
  };

  useEffect(() => {
    if (location.pathname === "/dashboard/Buying-List") {
      setOpen101(true);
    }
    if (location.pathname === "/dashboard/Service-List") {
      setOpen101(true);
    }
    if (location.pathname === "/dashboard/createWork") {
      setOpen101(true);
    }

    if (location.pathname === "/dashboard/bookcatalogue") {
      setOpen101(true);
    }
    if (location.pathname === "/dashboard/addcreatework") {
      setOpen101(true);
    }

    if (location.pathname === "/dashboard/addApplyWork") {
      setOpen102(true);
    }
    if (location.pathname === "/dashboard/applyWork") {
      setOpen102(true);
    }
    if (location.pathname === "/dashboard/orderList") {
      setOpen102(true);
    }
    if (location.pathname === "/dashboard/bookingList") {
      setOpen102(true);
    }
    if (location.pathname === "/dashboard/orderCalendar") {
      setOpen102(true);
    }
    if (location.pathname === "/dashboard/findtalent") {
      setOpen104(true);
    }
    if (location.pathname === "/dashboard/connect") {
      setOpen104(true);
    }
    if (location.pathname === "/dashboard/connection") {
      setOpen104(true);
    }

    if (location.pathname === "/dashboard/invoice") {
      setOpen105(true);
    }
    if (location.pathname === "/dashboard/need_fund") {
      setOpen105(true);
    }
    if (location.pathname === "/dashboard/emi_calculator") {
      setOpen105(true);
    }
    if (location.pathname === "/dashboard/lend_finance") {
      setOpen105(true);
    }
    if (location.pathname === "/dashboard/add_lend_finance") {
      setOpen105(true);
    }
    if (location.pathname === "/dashboard/addinvoice") {
      setOpen105(true);
    }
    if (location.pathname.includes("/dashboard/editinvoice")) {
      setOpen105(true);
    }

    if (location.pathname === "/dashboard/expenditure") {
      setOpen105(true);
    }
    if (location.pathname === "/dashboard/addpurchase") {
      setOpen105(true);
    }
    if (location.pathname === "/dashboard/addexpense") {
      setOpen105(true);
    }
    if (location.pathname === "/dashboard/editpurchase") {
      setOpen105(true);
    }
    if (location.pathname === "/dashboard/editexpense") {
      setOpen105(true);
    }
    if (location.pathname === "/dashboard/taxation") {
      setOpen105(true);
    }

    if (location.pathname === "/dashboard/addcatalogue") {
      setOpen103(true);
    }
    if (location.pathname === "/dashboard/catalogue") {
      setOpen103(true);
    }
    if (location.pathname === "/dashboard/postCreator") {
      setOpen106(true);
    }
    if (location.pathname === "/dashboard/addproduct") {
      setOpen102(true);
    }
    if (location.pathname === "/dashboard/products") {
      setOpen102(true);
    }
    if (location.pathname === "/dashboard/catalogue") {
      setOpen103(true);
    }

    if (location.pathname === "/dashboard/addBlog") {
      setOpen106(true);
    }
    if (location.pathname === "/dashboard/letterhead") {
      setOpen106(true);
    }
    if (location.pathname === "/dashboard/blogs") {
      setOpen106(true);
    }
    if (location.pathname === "/dashboard/startup/business_solution") {
      setOpen107(true);
    }

    if (location.pathname === "/dashboard/startup/business_plan") {
      setOpen107(true);
    }
    if (location.pathname === "/dashboard/startup/business_idea") {
      setOpen107(true);
    }
    if (location.pathname === "/dashboard/startup/business_task") {
      setOpen107(true);
    }

    if (location.pathname === "/dashboard/addcontract") {
      setOpen108(true);
    }
    if (location.pathname.includes("/dashboard/editcontract")) {
      setOpen108(true);
    }
    if (location.pathname === "/dashboard/contract") {
      setOpen108(true);
    }
  }, [location]);
  return (
    <div
      style={{
        position: "sticky",
        width: width > 700 ? "" : "100%",
        height: "calc( 100vh - 4vw)",
        paddingTop: width > 700 && "0vw",
      }}
      className="ScrollTable sidebardashbord-container"
    >
      {width <= 700 && (
        <div style={{ width: "50vw" }} className="navlofogo">
          <img
            style={{
              width: "40vw",
            }}
            onClick={() => navigate("/")}
            src={imgx}
            alt="error"
          />
        </div>
      )}

      <List
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <Link to="/dashboard">
          <div
            className={
              location.pathname === "/dashboard"
                ? "firstsidebarmenu1"
                : "firstsidebarmenu"
            }
            onClick={width <= 700 && toggleDrawer("left", false)}
            style={{
              borderTop: "none",
            }}
          >
            <img
              style={{
                width: width > 700 ? "1.8vw" : "5.5vw",
                margin: width > 700 ? "" : "0vw 2.3vw",
              }}
              src={img}
              alt=""
            />
            Dashboard
          </div>
        </Link>

        <div
          onClick={handleClick101}
          className={
            colorsidebar === 2 ? "firstsidebarmenu1" : "firstsidebarmenu"
          }
          style={{ background: "white", color: "black" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div>
              <img
                src={img5}
                alt=""
                style={{
                  width: width > 700 ? "1.8vw" : "5.5vw",
                  margin: width > 700 ? "1vw" : "0vw 2.3vw",
                  filter: "none",
                }}
              />
              Buy
            </div>
            <div>
              {" "}
              {open101 ? (
                <ExpandLess
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw" : "0vw 2.3vw",
                  }}
                />
              ) : (
                <ExpandMore
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw" : "0vw 2.3vw",
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <Collapse
          style={{ background: "#00000009" }}
          in={open101}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <Link to="/dashboard/bookcatalogue">
              <div
                className={
                  location.pathname === "/dashboard/bookcatalogue"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "0 1vw" : "0vw 2.3vw",
                  }}
                  src={img6}
                  alt=""
                />
                Search
              </div>
            </Link>
          </List>
          <List component="div" disablePadding>
            <Link to="/dashboard/Buying-List">
              <div
                className={
                  location.pathname === "/dashboard/Buying-List"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img2}
                  alt=""
                />
                Products Ordered
              </div>
            </Link>
          </List>
          <List component="div" disablePadding>
            <Link to="/dashboard/Service-List">
              <div
                className={
                  location.pathname === "/dashboard/Service-List"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img2}
                  alt=""
                />
                Services Booked
              </div>
            </Link>
          </List>

          <List component="div" disablePadding>
            <Link to="/dashboard/createWork">
              <div
                className={
                  location.pathname === "/dashboard/createWork" ||
                  location.pathname === "/dashboard/addcreatework"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img7}
                  alt=""
                />
                Issue Tender
              </div>
            </Link>
          </List>
        </Collapse>

        <div
          onClick={handleClick102}
          className={
            colorsidebar === 2 ? "firstsidebarmenu1" : "firstsidebarmenu"
          }
          style={{ background: "white", color: "black" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div>
              <img
                src={img1}
                alt=""
                style={{
                  width: width > 700 ? "1.8vw" : "5.5vw",
                  margin: width > 700 ? "" : "0vw 2.3vw",
                  filter: "none",
                }}
              />
              Sell
            </div>
            <div>
              {" "}
              {open102 ? (
                <ExpandLess
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw" : "0vw 2.3vw",
                  }}
                />
              ) : (
                <ExpandMore
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw" : "0vw 2.3vw",
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <Collapse
          style={{ background: "#00000009" }}
          in={open102}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <Link to="/dashboard/products">
              <div
                className={
                  location.pathname === "/dashboard/products" ||
                  location.pathname === "/dashboard/addproduct"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img7}
                  alt=""
                />
                Add Product/Service List
              </div>
            </Link>
          </List>

          <List component="div" disablePadding>
            <Link to="/dashboard/orderList">
              <div
                className={
                  location.pathname === "/dashboard/orderList"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "0 1vw" : "0vw 2.3vw",
                  }}
                  src={img7}
                  alt=""
                />
                Products Order List
              </div>
            </Link>
          </List>
          <List component="div" disablePadding>
            <Link to="/dashboard/bookingList">
              <div
                className={
                  location.pathname === "/dashboard/bookingList"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "0 1vw" : "0vw 2.3vw",
                  }}
                  src={img7}
                  alt=""
                />
                Service Booked List
              </div>
            </Link>
          </List>

          <List component="div" disablePadding>
            <Link to="/dashboard/addApplyWork">
              <div
                className={
                  location.pathname === "/dashboard/addApplyWork" ||
                  location.pathname === "/dashboard/addapplieddeatil"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img6}
                  alt=""
                />
                Apply Tender
              </div>
            </Link>
          </List>

          <List component="div" disablePadding>
            <Link to="/dashboard/applyWork">
              <div
                className={
                  location.pathname === "/dashboard/applyWork"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "0 1vw" : "0vw 2.3vw",
                  }}
                  src={img7}
                  alt=""
                />
                Applied Tender List
              </div>
            </Link>
          </List>

          <List component="div" disablePadding>
            <Link to="/dashboard/orderCalendar">
              <div
                className={
                  location.pathname === "/dashboard/orderCalendar"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img3}
                  alt=""
                />
                Order Calendar
              </div>
            </Link>
          </List>
        </Collapse>

        <div
          onClick={handleClick103}
          className={
            colorsidebar === 2 ? "firstsidebarmenu1" : "firstsidebarmenu"
          }
          style={{ background: "white", color: "black" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div>
              <img
                src={img2}
                alt=""
                style={{
                  width: width > 700 ? "1.8vw" : "5.5vw",
                  margin: width > 700 ? "" : "0vw 2.3vw",
                  filter: "none",
                }}
              />
       Web Page
            </div>
            <div>
              {" "}
              {open103 ? (
                <ExpandLess
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw 0vw 0vw" : "0vw 2.3vw",
                  }}
                />
              ) : (
                <ExpandMore
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw 0vw 0vw" : "0vw 2.3vw",
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <Collapse
          style={{ background: "#00000009" }}
          in={open103}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <Link to="/dashboard/catalogue">
              <div
                className={
                  location.pathname === "/dashboard/catalogue" ||
                  location.pathname === "/dashboard/addcatalogue"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img12}
                  alt=""
                />
                List of Websites
              </div>
            </Link>
          </List>
        </Collapse>

        <div
          onClick={handleClick104}
          className={
            colorsidebar === 2 ? "firstsidebarmenu1" : "firstsidebarmenu"
          }
          style={{ background: "white", color: "black" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div>
              <img
                src={img19}
                alt=""
                style={{
                  width: width > 700 ? "1.8vw" : "5.5vw",
                  margin: width > 700 ? "" : "0vw 2.3vw",
                  filter: "none",
                }}
              />
              Team
            </div>
            <div>
              {" "}
              {open104 ? (
                <ExpandLess
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw 0vw 0vw" : "0vw 2.3vw",
                  }}
                />
              ) : (
                <ExpandMore
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw 0vw 0vw" : "0vw 2.3vw",
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <Collapse
          style={{ background: "#00000009" }}
          in={open104}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <Link to="/dashboard/findtalent">
              <div
                className={
                  location.pathname === "/dashboard/findtalent"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "0 1vw" : "0vw 2.3vw",
                  }}
                  src={img6}
                  alt=""
                />
                Find Team
              </div>
            </Link>
          </List>
          <List component="div" disablePadding>
            <Link to="/dashboard/connect">
              <div
                className={
                  location.pathname === "/dashboard/connect"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img7}
                  alt=""
                />
                Connections
              </div>
            </Link>
          </List>
        </Collapse>

        <div
          onClick={handleClick105}
          className={
            colorsidebar === 2 ? "firstsidebarmenu1" : "firstsidebarmenu"
          }
          style={{ background: "white", color: "black" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div>
              <img
                src={img10}
                alt=""
                style={{
                  width: width > 700 ? "1.8vw" : "5.5vw",
                  margin: width > 700 ? "" : "0vw 2.3vw",
                  filter: "none",
                }}
              />
              Finance
            </div>
            <div>
              {" "}
              {open105 ? (
                <ExpandLess
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw 0vw 0vw" : "0vw 2.3vw",
                  }}
                />
              ) : (
                <ExpandMore
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw 0vw 0vw" : "0vw 2.3vw",
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <Collapse
          style={{ background: "#00000009" }}
          in={open105}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <Link to="/dashboard/invoice">
              <div
                className={
                  location.pathname === "/dashboard/invoice" ||
                  location.pathname.includes("/dashboard/editinvoice") ||
                  location.pathname === "/dashboard/addinvoice"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img7}
                  alt=""
                />
                Invoices
              </div>
            </Link>
          </List>

          <List component="div" disablePadding>
            <Link to="/dashboard/taxation">
              <div
                className={
                  location.pathname === "/dashboard/taxation"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img16}
                  alt=""
                />
                Tax Reports
              </div>
            </Link>
          </List>

          <List component="div" disablePadding>
            <Link to="/dashboard/expenditure">
              <div
                className={
                  location.pathname === "/dashboard/expenditure" ||
                  location.pathname === "/dashboard/addpurchase" ||
                  location.pathname === "/dashboard/addexpense"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img17}
                  alt=""
                />
                Accounting
              </div>
            </Link>
          </List>

          <List component="div" disablePadding>
            <Link to="/dashboard/need_fund">
              <div
                className={
                  location.pathname === "/dashboard/need_fund"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img22}
                  alt=""
                />
                Need a Fund
              </div>
            </Link>
          </List>

          <List component="div" disablePadding>
            <Link to="/dashboard/emi_calculator">
              <div
                className={
                  location.pathname === "/dashboard/emi_calculator"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img23}
                  alt=""
                />
                EMI Calculator
              </div>
            </Link>
          </List>

          <List component="div" disablePadding>
            <Link to="/dashboard/lend_finance">
              <div
                className={
                  location.pathname === "/dashboard/lend_finance" ||
                  location.pathname === "/dashboard/add_lend_finance"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img24}
                  alt=""
                />
                Lend Finance
              </div>
            </Link>
          </List>
        </Collapse>

        <div
          onClick={handleClick108}
          className={
            colorsidebar === 2 ? "firstsidebarmenu1" : "firstsidebarmenu"
          }
          style={{ background: "white", color: "black" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div>
              <img
                src={img25}
                alt=""
                style={{
                  width: width > 700 ? "1.8vw" : "5.5vw",
                  margin: width > 700 ? "" : "0vw 2.3vw",
                  filter: "none",
                }}
              />
              Legal
            </div>
            <div>
              {" "}
              {open108 ? (
                <ExpandLess
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw 0vw 0vw" : "0vw 2.3vw",
                  }}
                />
              ) : (
                <ExpandMore
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw 0vw 0vw" : "0vw 2.3vw",
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <Collapse
          style={{ background: "#00000009" }}
          in={open108}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <Link to="/dashboard/contract">
              <div
                className={
                  location.pathname === "/dashboard/contract" ||
                  location.pathname.includes("/dashboard/editcontract") ||
                  location.pathname === "/dashboard/addcontract"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img26}
                  alt=""
                />
                Draft an Agreement
              </div>
            </Link>
          </List>
        </Collapse>

        <div
          onClick={handleClick106}
          className={
            colorsidebar === 2 ? "firstsidebarmenu1" : "firstsidebarmenu"
          }
          style={{ background: "white", color: "black" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div>
              <img
                src={img4}
                alt=""
                style={{
                  width: width > 700 ? "1.8vw" : "5.5vw",
                  margin: width > 700 ? "" : "0vw 2.3vw",
                  filter: "none",
                }}
              />
              Marketing
            </div>
            <div>
              {" "}
              {open106 ? (
                <ExpandLess
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw 0vw 0vw" : "0vw 2.3vw",
                  }}
                />
              ) : (
                <ExpandMore
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw 0vw 0vw" : "0vw 2.3vw",
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <Collapse
          style={{ background: "#00000009" }}
          in={open106}
          timeout="auto"
          unmountOnExit
        >
          {" "}
          <List component="div" disablePadding>
            <Link to="/dashboard/businessdirectory">
              <div
                className={
                  location.pathname === "/dashboard/businessdirectory" ||
                  location.pathname === "/dashboard/businessdirectory"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img7}
                  alt=""
                />
                Business Directory
              </div>
            </Link>
          </List>
          <List component="div" disablePadding>
            <Link to="/dashboard/postCreator">
              <div
                className={
                  location.pathname === "/dashboard/postCreator"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img3}
                  alt=""
                />
                Social Media Post
              </div>
            </Link>
          </List>
          <List component="div" disablePadding>
            <Link to="/dashboard/letterhead">
              <div
                className={
                  location.pathname === "/dashboard/letterhead"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "0 1vw" : "0vw 2.3vw",
                  }}
                  src={img4}
                  alt=""
                />
                Marketing Documents
              </div>
            </Link>
          </List>
          <List component="div" disablePadding>
            <Link to="/dashboard/blogs">
              <div
                className={
                  location.pathname === "/dashboard/blogs" ||
                  location.pathname === "/dashboard/addBlog"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img7}
                  alt=""
                />
                Blogs
              </div>
            </Link>
          </List>
        </Collapse>

        <div
          onClick={handleClick107}
          className={
            colorsidebar === 2 ? "firstsidebarmenu1" : "firstsidebarmenu"
          }
          style={{ background: "white", color: "black" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div>
              <img
                src={img13}
                alt=""
                style={{
                  width: width > 700 ? "1.8vw" : "5.5vw",
                  margin: width > 700 ? "" : "0vw 2.3vw",
                  filter: "none",
                }}
              />
              Startup
            </div>
            <div>
              {" "}
              {open106 ? (
                <ExpandLess
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw 0vw 0vw" : "0vw 2.3vw",
                  }}
                />
              ) : (
                <ExpandMore
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw 0vw 0vw" : "0vw 2.3vw",
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <Collapse
          style={{ background: "#00000009" }}
          in={open107}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <Link to="/dashboard/stories">
              <div
                className={
                  location.pathname === "/dashboard/stories"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  src={img14}
                  alt=""
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                />
                Business Stories
              </div>
            </Link>
            <Link to="/dashboard/startup/business_idea">
              <div
                className={
                  location.pathname === "/dashboard/startup/business_idea"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "0 1vw" : "0vw 2.3vw",
                  }}
                  src={img15}
                  alt=""
                />
                Find Business Idea
              </div>
            </Link>
          </List>
          <List component="div" disablePadding>
            <Link to="/dashboard/startup/business_plan">
              <div
                className={
                  location.pathname === "/dashboard/startup/business_plan"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img7}
                  alt=""
                />
                Find Business Plan
              </div>
            </Link>
          </List>
          <List component="div" disablePadding>
            <Link to="/dashboard/startup/business_solution">
              <div
                className={
                  location.pathname === "/dashboard/startup/business_solution"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img9}
                  alt=""
                />
                Find Solutions
              </div>
            </Link>
          </List>
          <List component="div" disablePadding>
            <Link to="/dashboard/startup/business_task">
              <div
                className={
                  location.pathname === "/dashboard/startup/business_task"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img21}
                  alt=""
                />
                Business Task Tutorials
              </div>
            </Link>
          </List>
        </Collapse>

        <Link to="/dashboard/faq">
          <div
            className={
              location.pathname === "/dashboard/faq"
                ? "firstsidebarmenu1"
                : "firstsidebarmenu"
            }
            onClick={width <= 700 && toggleDrawer("left", false)}
          >
            <img
              src={img18}
              alt=""
              style={{
                width: width > 700 ? "1.8vw" : "5.5vw",
                margin: width > 700 ? "" : "0vw 2.3vw",
              }}
            />
            FAQ
          </div>
        </Link>

        <Link to="/dashboard/referral">
          <div
            className={
              location.pathname === "/dashboard/referral"
                ? "firstsidebarmenu1"
                : "firstsidebarmenu"
            }
            onClick={width <= 700 && toggleDrawer("left", false)}
          >
            <img src={img20} alt="" />
            Referral
          </div>
        </Link>

        <div
          onClick={handleClick109}
          className={
            colorsidebar === 2 ? "firstsidebarmenu1" : "firstsidebarmenu"
          }
          style={{ background: "white", color: "black" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div>
              <img
                src={img8}
                alt=""
                style={{
                  width: width > 700 ? "1.8vw" : "5.5vw",
                  margin: width > 700 ? "" : "0vw 2.3vw",
                  filter: "none",
                }}
              />
              Settings
            </div>
            <div>
              {" "}
              {open109 ? (
                <ExpandLess
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw 0vw 0vw" : "0vw 2.3vw",
                  }}
                />
              ) : (
                <ExpandMore
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw 0vw 0vw" : "0vw 2.3vw",
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <Collapse
          style={{ background: "#00000009" }}
          in={open109}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <Link to="/dashboard/companies">
              <div
                className={
                  location.pathname === "/dashboard/companies"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <StoreOutlinedIcon
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "0 1vw" : "0vw 2.3vw",
                  }}
                />
                Company
              </div>
            </Link>
            <Link to="/dashboard/documents">
              <div
                className={
                  location.pathname === "/dashboard/documents"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "0 1vw" : "0vw 2.3vw",
                  }}
                  src={img4}
                  alt=""
                />
                Documents
              </div>
            </Link>

            <Link to="/dashboard/security">
              <div
                className={
                  location.pathname === "/dashboard/security"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <HttpsOutlinedIcon
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "0 1vw" : "0vw 2.3vw",
                  }}
                />
                Security
              </div>
            </Link>
          </List>
        </Collapse>

        <Link to="/dashboard/profile">
          <div
            className={
              location.pathname === "/dashboard/profile"
                ? "firstsidebarmenu1"
                : "firstsidebarmenu"
            }
            onClick={width <= 700 && toggleDrawer("left", false)}
          >
            <img src={img89} alt="" />
            Profile
          </div>
        </Link>
      </List>
    </div>
  );
}
