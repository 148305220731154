import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Aboutccaresel from "./Aboutcrausel";
import { userActions } from "../../store/userSlice";
import { useNavigate } from "react-router";
import axios from "axios";
import API_HOST from "../../env";
export default function Aboutteam({ width }) {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const handleStart = () => {
    if (!user?.userName) {
      dispatch(userActions.openloginForm());
    } else {
      navigate("/dashboard");
    }
  };
  const [team, setTeam] = useState([]);
  useEffect(() => {
    axios
      .get(`${API_HOST}/team/viewTeam`)
      .then((res) => {
          setTeam(res.data.success.data)
      })
      .catch(() => {});
  }, []);

  return (
    <div>
      <div className="abouttitileheading"> Meet our Team</div>
      <div style={{ marginBottom: "3vw" }} className="abouttitileheadingsub">
        Our team of experts consists of creative and technically proficient
        individuals who possess strong analytical skills and a penchant for
        innovation.
      </div>

      <Aboutccaresel width={width}  team={team}/>

      <div className="readyabout">
        Ready to Get Started?
        <span>
          <button onClick={() => handleStart()}>Join us</button>
        </span>
      </div>
    </div>
  );
}
