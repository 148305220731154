import React, { Component } from "react";
import Slider from "react-slick";

import "./Homecategories.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import ProfileCatelogdetails from "./ProfileCatelogDetails";
import ProfileCatelogdetails1 from "../../DashbaordSidebarComponents/BookCatalogues/ProfileCatelogDetails";

function SampleNextArrow1(props) {
  const { onClick } = props;
  return (
    <div
      //   className={className}
      style={{
        // ...style,
        display: "block",
        background: "#0052CC",
        width: "7vw",
        position: "relative",
        zIndex: "50",
        cursor: "pointer",
        bottom: "35vw",
        left: "91vw",

        height: "7vw",
        boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.1)",
        borderRadius: "50%",
      }}
      onClick={onClick}
    >
      <div
        style={{
          color: "white",
          display: "flex",
          margin: "auto",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          height: "5vw",
        }}
      >
        <ArrowForwardIcon
          style={{
            fontSize: "5vw",
            marginTop: "1.9vw",
          }}
        />
      </div>
    </div>
  );
}

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      //   className={className}
      style={{
        // ...style,
        display: "block",
        background: "#0052CC",
        width: "3vw",
        position: "relative",
        zIndex: "50",
        cursor: "pointer",
        bottom: "15vw",
        left: "90.5vw",
        height: "3vw",
        boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.1)",
        borderRadius: "50%",
      }}
      onClick={onClick}
    >
      <div
        style={{
          color: "white",
          display: "flex",
          margin: "auto",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          height: "1vw",
        }}
      >
        <ArrowForwardIcon
          style={{
            fontSize: "2vw",
            marginTop: "1.9vw",
          }}
        />
      </div>
    </div>
  );
}
export default class Homeccaresel extends Component {
  render() {
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: this.props?.width <= 700 ? 2 : 4.7,
      slidesToScroll: 1,
      nextArrow:
        this.props?.width <= 700 ? <SampleNextArrow1 /> : <SampleNextArrow />,
    };

    return (
      <div style={{ width: "100vw", overflow: "hidden" }}>
        <div style={{ width: "100vw", overflow: "hidden" }}>
          <div
            style={{
              width: this.props?.width <= 700 ? "96vw" : "92vw ",
              margin: "auto",
            }}
          >
            {this?.props?.poplularCatelogue?.length <(this.props?.width > 700 ?5:2) ? (
              <div style={{ display: "flex" }}>
                {this?.props?.poplularCatelogue?.map((catalogue, index) => {
                  return (
                    <ProfileCatelogdetails1
                      data={catalogue}
                      width={this.props?.width}
                    />
                  );
                })}
              </div>
            ) : (
              <Slider {...settings}>
                {this?.props?.poplularCatelogue?.map((catalogue, index) => {
                  return (
                    <ProfileCatelogdetails
                      data={catalogue}
                      width={this.props?.width}
                    />
                  );
                })}
                {this?.props?.poplularCatelogue?.map((catalogue, index) => {
                  return (
                    <ProfileCatelogdetails
                      data={catalogue}
                      width={this.props?.width}
                    />
                  );
                })}
              </Slider>
            )}
          </div>
          {/* </div> */}
        </div>
      </div>
    );
  }
}
