import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  maxHeight: "90vh",
  height: "fit-content",
  overflow: "scroll",
  padding: "1vw",
};

const style2 = {
  position: "absolute",
  maxHieght: "90vh",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "94vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  maxHeight: "90vh",
  height: "fit-content",
  overflow: "scroll",

  padding: "2vw",
};
export default function BusinessDetails({ width, data }) {
  const [openx, setOpenx] = React.useState(false);
  const handleOpenx = () => setOpenx(true);
  const handleClosex = () => setOpenx(false);

  return (
    <div
      style={{
        alignItems: "center",
        padding: "0.75vw 1vw",
        margin: width > 700 ? "1vw 0vw" : "2vw 0vw",
        width: width > 700 ? "82vw" : "224vw",
      }}
      className="navoftableblogsdata"
    >
      <div style={{ width: width > 700 ? "14vw" : "40vw" }}>
        {data?.company?.companyName}
      </div>
      <div style={{ width: width > 700 ? "20vw" : "40vw" }}>
        {data?.company?.companyTitle}
      </div>
      <div style={{ width: width > 700 ? "15vw" : "40vw" }}>
        {data?.company?.emailId}
      </div>
      <div style={{ width: width > 700 ? "10vw" : "32vw" }}>
        {data?.company?.mobile}
      </div>
      <div style={{ width: width > 700 ? "9vw" : "27vw" }}>
        {data?.company?.state}
      </div>

      <button
        style={{ height: width > 700 ? "2vw" : "4.5vw" }}
        className="hb-buttonx"
        onClick={() => {
          handleOpenx();
        }}
      >
        View Detail
      </button>

      <Modal
        open={openx}
        onClose={handleClosex}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={width > 700 ? style : style2}>
          <div style={{ padding: "1vw" }} className="profiletitleandmenunav">
            <div className="profiledetailstitle"> Directory Detail </div>
            <div className="profiledetailnavmanu">
              <div>
                <CloseIcon
                  onClick={handleClosex}
                  style={{
                    fontSize: width > 700 ? "1.5vw" : "4vw",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          </div>
          <hr style={{ color: "#000000" }} />
          <div
            style={{ width: "100%", paddingTop: "0vw" }}
            className="flexofbankdetails"
          >
            <div style={{ width: "100%" }} className="flexofbankdetails">
              <div style={{ width: "50%" }} className="titleboxflexofbank">
                Company Name :
              </div>
              <div
                style={{ fontWeight: "400", width: "100%" }}
                className="titleboxflexofbank"
              >
                {data?.company?.companyName}
              </div>
            </div>
          </div>
          <div
            style={{ width: "100%", paddingTop: "0vw" }}
            className="flexofbankdetails"
          >
            <div style={{ width: "100%" }} className="flexofbankdetails">
              <div style={{ width: "50%" }} className="titleboxflexofbank">
                Company Title :
              </div>
              <div
                style={{ fontWeight: "400", width: "100%" }}
                className="titleboxflexofbank"
              >
                {data?.company?.companyTitle}
              </div>
            </div>
          </div>
          <div
            style={{ width: "100%", paddingTop: "0vw" }}
            className="flexofbankdetails"
          >
            <div style={{ width: "100%" }} className="flexofbankdetails">
              <div style={{ width: "50%" }} className="titleboxflexofbank">
                Email:
              </div>
              <div
                style={{ fontWeight: "400", width: "100%" }}
                className="titleboxflexofbank"
              >
                {data?.company?.emailId}
              </div>
            </div>
          </div>
          <div
            style={{ width: "100%", paddingTop: "0vw" }}
            className="flexofbankdetails"
          >
            <div style={{ width: "100%" }} className="flexofbankdetails">
              <div style={{ width: "50%" }} className="titleboxflexofbank">
                Mobile:
              </div>
              <div
                style={{ fontWeight: "400", width: "100%" }}
                className="titleboxflexofbank"
              >
                {data?.company?.mobile}
              </div>
            </div>
          </div>
          <div
            style={{ width: "100%", paddingTop: "0vw" }}
            className="flexofbankdetails"
          >
            <div style={{ width: "100%" }} className="flexofbankdetails">
              <div style={{ width: "50%" }} className="titleboxflexofbank">
                Address :
              </div>
              <div
                style={{ fontWeight: "400", width: "100%" }}
                className="titleboxflexofbank"
              >
                {data?.company?.adress}
              </div>
            </div>
          </div>
          <div
            style={{ width: "100%", paddingTop: "0vw" }}
            className="flexofbankdetails"
          >
            <div style={{ width: "100%" }} className="flexofbankdetails">
              <div style={{ width: "50%" }} className="titleboxflexofbank">
                State :
              </div>
              <div
                style={{ fontWeight: "400", width: "100%" }}
                className="titleboxflexofbank"
              >
                {data?.company?.state}
              </div>
            </div>
          </div>

          <div
            style={{ width: "100%", paddingTop: "0vw" }}
            className="flexofbankdetails"
          >
            <div style={{ width: "100%" }} className="flexofbankdetails">
              <div style={{ width: "50%" }} className="titleboxflexofbank">
                Pincode :
              </div>
              <div
                style={{
                  fontWeight: "400",
                  width: "100%",
                  // color: "#41B541"
                }}
                className="titleboxflexofbank"
              >
                {data?.company?.pinCode}
              </div>
            </div>
          </div>
          <hr style={{ color: "#000000" }} />

          <div
            style={{
              float: "right",
              marginBottom: "1vw",
              marginTop: width > 700 ? "0.3vw" : "1vw",
              width: width > 700 ? "20vw" : "70vw",
              justifyContent: "right",
            }}
            className="homejobbuttons"
          >
            <button
              onClick={() => {
                handleClosex();
              }}
              style={{ background: "white", marginRight: "2vw" }}
            >
              Close
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
