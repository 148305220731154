import React from "react";
import img from "../../../assets/Success stories/zan-BKyvWG7D6QA-unsplash.png";

import { useNavigate } from "react-router";

export default function BlogCards({ item, width }) {
  const navigate = useNavigate();
  const myStr = item?.contentName;
  const contentNameRoute = myStr
    .replaceAll(" ", "-")
    .replaceAll("/", "-")
    .replaceAll("?", "-");
  return (
    <div
      style={{ cursor: "pointer" ,margin: width>700? '1vw 0.7vw':"3vw 0.75vw" ,width: width>700? "25.5vw":"92vw" }}
      onClick={() => navigate(`/blogs/${contentNameRoute}/${item?.contentId}`)}
      className="boxoffilterbox"
    >
      <div className="imgboxfilteredblog" style={{width:"100%"}}>
        <img src={item?.icon?.length > 0 ? item?.icon[0] : img} alt="" style={{width:"100%"}}/>
      </div>
      
        <div
          style={{
            height: width > 700 ? "6vw" : "18vw",
            // display: "flex",
            // flexDirection: "column",
            // justifyContent: "space-between",
          }}
        >
          <div className="mt-3 " 
          style={{
            whiteSpace:"nowrap",
            height:"fit-content", 
            fontSize:"12px",
            background:"#ddd",
            width: "fit-content",
            padding: "0.3rem 0.5rem",
            borderRadius: "5px",
            marginBottom:"5px",
            fontSize:"14px"
            }}>{item?.category}</div>
          <div className="tagblogblue multiline-ellipsis mt-3" >
            <span className="tagblogblack ">
              {item?.contentName}
            </span>
          </div>
          
        </div>
        <hr />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="tagblogblack" style={{fontSize:"14px"}}>{item?.author}</div>
          <div className="tagblogblue">
            {/* {
          item?.created_at?.slice(0, 10)
          } */}
            {new Date(item?.created_at).getDate()}/
            {new Date(item?.created_at).getMonth() + 1}/
            {new Date(item?.created_at).getFullYear()}
          </div>
        </div>
    </div>
  );
}
