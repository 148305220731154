import React, { useEffect, useState } from "react";
import img1 from "../../assets/My profile – 28/Component 70 – 6.svg";
import "./profile.css";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { TextField } from "@mui/material";

import API_HOST from "../../env";
import axios from "axios";
import Profilerefrencedetail from "./Profilepopup/profilerefrencedetail";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "48vw",
  bgcolor: "background.paper",
  maxHeight: "95vh",
  overflow: "scroll",
  boxShadow: 24,
  p: 4,
};

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95vw",
  bgcolor: "background.paper",
  maxHeight: "95vh",
  overflow: "scroll",
  boxShadow: 24,
  p: 1,
};

const useStyles = makeStyles((theme) => ({
  input: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "0.91vw",

    color: "#263238",
    border: "yellow !important",
  },
}));

export default function Profilerefrence({ user, width,setRefrencesall }) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [callagename, setCallagename] = useState("");

  const [degreeset, setDegreeset] = useState("");
  const [studyset, setstudyset] = useState("");
  const [descriptioncallageadd, setdescriptioncallageadd] = useState("");

  const handleaddcallage = () => {
    const formdata = {
      emailId: descriptioncallageadd,
      name: callagename,
      title: degreeset,
      phone: studyset,
    };
    axios
      .post(`${API_HOST}/users/addRefrence`, formdata, {
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        if (user?.userName) {
          axios
            .get(
              `${API_HOST}/users/viewRefrence?pageSize=50&pageNumber=1&userName=${user?.userName}`
            )
            .then((res) => {
              setcertificated(res?.data?.success?.data);
             
          setRefrencesall(res?.data?.success?.data);
            });
        }
        handleClose();
        setCallagename("");
        setstudyset("");
        setDegreeset();

        setdescriptioncallageadd("");
      });
  };

  const [certificated, setcertificated] = useState([]);
  useEffect(() => {
    if (user?.userName) {
      axios
        .get(
          `${API_HOST}/users/viewRefrence?pageSize=50&pageNumber=1&userName=${user?.userName}`
        )
        .then((res) => {
          setcertificated(res?.data?.success?.data);
          setRefrencesall(res?.data?.success?.data);
        });
    }
  }, [user]);
  return (
    <div style={{ height: "fit-content" }} className="profilebadgecontainer">
      <div
        style={{ padding: "1.5vw", margin: "0 0vw" }}
        className="profiletitleandmenunav"
      >
        <div className="profiledetailstitle">Refrence</div>
        <div className="profiledetailnavmanu">
          <img
            onClick={handleOpen}
            src={img1}
            style={{ width: width > 700 ? "2.5vw" : "6vw" }}
            alt=""
          />
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={width > 700 ? style : style1}>
            <div className="profiletitleandmenunav">
              <div className="profiledetailstitle">Add Refrence</div>
              <div className="profiledetailnavmanu">
                <div>
                  <CloseIcon
                    onClick={handleClose}
                    style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
                  />
                </div>
              </div>
            </div>
            <hr style={{ color: "#000000" }} />
            <div style={{ left: "0vw", width: "100%" }} className="loginfield">
              <TextField
                id="outlined-basic"
                label="Full Name"
                variant="outlined"
                multiline
                style={{ width: "100%" }}
                InputLabelProps={{
                  style: {
                    fontSize: width > 700 ? "1vw" : "3vw",
                    fontFamily: "Poppins",
                    fontStyle: "500",
                    fontWeight: "500",
                    color: "black",
                  },
                }}
                inputProps={{ className: classes.input }}
                onChange={(e) => {
                  setCallagename(e.target.value);
                }}
              />
            </div>

            <div style={{ left: "0vw", width: "100%" }} className="loginfield">
              <TextField
                id="outlined-basic"
                label="Title"
                variant="outlined"
                style={{ width: "100%" }}
                InputLabelProps={{
                  style: {
                    fontSize: width > 700 ? "1vw" : "3vw",

                    fontFamily: "Poppins",
                    fontStyle: "500",
                    fontWeight: "500",
                    color: "black",
                  },
                }}
                inputProps={{ className: classes.input }}
                onChange={(e) => {
                  setDegreeset(e.target.value);
                }}
              />
            </div>
            <div style={{ left: "0vw", width: "100%" }} className="loginfield">
              <TextField
                id="outlined-basic"
                label="Phone number"
                variant="outlined"
                type="number"
                style={{ width: "100%" }}
                InputLabelProps={{
                  style: {
                    fontSize: width > 700 ? "1vw" : "3vw",
                    fontFamily: "Poppins",
                    fontStyle: "500",
                    fontWeight: "500",
                    color: "black",
                  },
                }}
                inputProps={{ className: classes.input }}
                onChange={(e) => {
                  setstudyset(e.target.value);
                }}
              />
            </div>

            <div style={{ left: "0vw", width: "100%" }} className="loginfield">
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                value={descriptioncallageadd}
                style={{ width: "100%" }}
                InputLabelProps={{
                  style: {
                    fontSize: width > 700 ? "1vw" : "3vw",
                    fontFamily: "Poppins",
                    fontStyle: "500",
                    fontWeight: "500",
                    color: "black",
                  },
                }}
                inputProps={{ className: classes.input }}
                onChange={(e) => {
                  setdescriptioncallageadd(e.target.value);
                }}
              />
            </div>
            <hr style={{ color: "#000000" }} />
            <div
              style={{ marginTop: "0.31vw" }}
              className="handlemoreaboutskill"
            >
              <div
                style={{
                  background: "white",
                  color: "black",
                  cursor: "pointer",
                }}
                className="handlecirclieaboutsave"
                onClick={handleClose}
              >
                Cancel
              </div>
              <div
                style={{ cursor: "pointer" }}
                className="handlecirclieaboutsave"
                onClick={handleaddcallage}
              >
                SAVE
              </div>
            </div>
          </Box>
        </Modal>
      </div>
      {certificated?.length > 0 &&
        certificated?.map((education) => {
          return (
            <Profilerefrencedetail
              education={education}
              setRefrencesall={setRefrencesall}
              width={width}
              setcertificated={setcertificated}
              user={user}
            />
          );
        })}
    </div>
  );
}
