import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import imgdot from "../../../assets/walletimage/Ellipse 1400.svg";
import imgdot1 from "../../../assets/walletimage/Ellipse 1402.svg";
export default function Dashbaordmainsidebar({ width, steps }) {
  const navigate = useNavigate();
  return (
    <div
      style={{
        position: "sticky",
        width: width > 700 ? "15vw" : "100%",
        height: "calc( 100vh - 4vw)",
        paddingTop: width > 700 && "0vw",
      }}
      className="ScrollTable sidebardashbord-container"
    >
      <div
        style={{ marginRight: "0.1vw", padding: "0.25vw" }}
        className="Redeamlistboxes1"
      >
        <div
          style={{ fontSize: "1.35vw", padding: "0.35vw", fontWeight: "400" }}
          className=""
        >
          Complete Steps
        </div>
        <div className="flexofredeamname">
          <span>
            <img
              src={steps?.createAccount ? imgdot : imgdot1}
              style={{
                width: "0.7vw",
                objectFit: "contain",
                margin: "1vw",
              }}
              alt=""
            />
          </span>
          Create account
        </div>
        <div
          onClick={() => {
            navigate("/dashboard/profile");
          }}
          className="flexofredeamname"
        >
          <span>
            <img
              src={steps?.updateProfile ? imgdot : imgdot1}
              style={{
                width: "0.7vw",
                objectFit: "contain",
                margin: "1vw",
              }}
              alt=""
            />
          </span>
          Update your profile
        </div>
        <div
          onClick={() => {
            navigate("/dashboard/profile");
          }}
          className="flexofredeamname"
        >
          <span>
            <img
              src={steps?.openTowork ? imgdot : imgdot1}
              style={{
                width: "0.7vw",
                objectFit: "contain",
                margin: "1vw",
              }}
              alt=""
            />
          </span>
          Open to work
        </div>
        <div
          onClick={() => {
            navigate("/dashboard/setting");
          }}
          className="flexofredeamname"
        >
          <span>
            <img
              src={steps?.companyDetails ? imgdot : imgdot1}
              style={{
                width: "0.7vw",
                objectFit: "contain",
                margin: "1vw",
              }}
              alt=""
            />
          </span>
          Add Company details
        </div>
        <div
          onClick={() => {
            navigate("/dashboard/addproduct");
          }}
          className="flexofredeamname"
        >
          <span>
            <img
              src={steps?.productService ? imgdot : imgdot1}
              style={{
                width: "0.7vw",
                objectFit: "contain",
                margin: "1vw",
              }}
              alt=""
            />
          </span>
          Add Product /Service
        </div>
        <div
          onClick={() => {
            navigate("/dashboard/addcatalogue");
          }}
          className="flexofredeamname"
        >
          <span>
            <img
              src={steps?.website ? imgdot : imgdot1}
              style={{
                width: "0.7vw",
                objectFit: "contain",
                margin: "1vw",
              }}
              alt=""
            />
          </span>
          Build your website
        </div>
        <div
          onClick={() => {
            navigate("/dashboard/products");
          }}
          className="flexofredeamname"
        >
          <span>
            <img
              src={steps?.listProductService ? imgdot : imgdot1}
              style={{
                width: "0.7vw",
                objectFit: "contain",
                margin: "1vw",
              }}
              alt=""
            />
          </span>
          List product/service in website
        </div>
        <div
          onClick={() => {
            navigate("/dashboard/addcreatework");
          }}
          className="flexofredeamname"
        >
          <span>
            <img
              src={steps?.createProposal ? imgdot : imgdot1}
              style={{
                width: "0.7vw",
                objectFit: "contain",
                margin: "1vw",
              }}
              alt=""
            />
          </span>
          Create a Proposal
        </div>
        <div
          onClick={() => {
            navigate("/dashboard/addApplyWork");
          }}
          className="flexofredeamname"
        >
          <span>
            <img
              src={steps?.bidProposal ? imgdot : imgdot1}
              style={{
                width: "0.7vw",
                objectFit: "contain",
                margin: "1vw",
              }}
              alt=""
            />
          </span>
          Bid a Proposal
        </div>
        <div
          onClick={() => {
            navigate("/dashboard/addinvoice");
          }}
          className="flexofredeamname"
        >
          <span>
            <img
              src={steps?.invoice ? imgdot : imgdot1}
              style={{
                width: "0.7vw",
                objectFit: "contain",
                margin: "1vw",
              }}
              alt=""
            />
          </span>
          Create a Invoice
        </div>
        <div
          onClick={() => {
            navigate("/dashboard/postCreator");
          }}
          className="flexofredeamname"
        >
          <span>
            <img
              src={steps?.createPost ? imgdot : imgdot1}
              style={{
                width: "0.7vw",
                objectFit: "contain",
                margin: "1vw",
              }}
              alt=""
            />
          </span>
          Create Post
        </div>
        <div
          onClick={() => {
            navigate("/dashboard/addBlog");
          }}
          className="flexofredeamname"
        >
          <span>
            <img
              src={steps?.createBlog ? imgdot : imgdot1}
              style={{
                width: "0.7vw",
                objectFit: "contain",
                margin: "1vw",
              }}
              alt=""
            />
          </span>
          Create a Blog
        </div>
        <div
          onClick={() => {
            navigate("/dashboard/Addletter");
          }}
          className="flexofredeamname"
        >
          <span>
            <img
              src={steps?.letterHead ? imgdot : imgdot1}
              style={{
                width: "0.7vw",
                objectFit: "contain",
                margin: "1vw",
              }}
              alt=""
            />
          </span>
          Create a LetterHead
        </div>
      </div>
    </div>
  );
}
