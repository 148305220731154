import React, { useEffect, useState } from "react";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import { Backdrop, CircularProgress, TextField } from "@mui/material";
import Popover from "@mui/material/Popover";
import AddIcon from "@mui/icons-material/Add";
import { makeStyles } from "@material-ui/core";
import img from "../../../assets/Web 1280 – 14/Icon.svg";
import img1 from "../../../assets/Web 1280 – 14/Group 9831.svg";
import img21 from "../../../assets/My profile – 28/Component 85 – 16 (1).svg";
import DoneIcon from "@mui/icons-material/Done";
import axios from "axios";
import API_HOST from "../../../env";
import Typography from "@mui/material/Typography";
import imageCompression from "browser-image-compression";
import { useNavigate } from "react-router";

import ToggleButton from "react-toggle-button";
import Texteditor from "./Texteditor";
import Offers from "./offers";

const useStyles = makeStyles((theme) => ({
  select: {
    height: "2.5vw",
    width: "100%",
    marginTop: "0.2vw",
    padding: "1vw",
    marginLeft: "0vw",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "1vw",
    lineHeight: "120%",
    color: "#FFFFFF",
  },
  select2: {
    height: "1vw",
    width: "100%",
    marginTop: "0.1vw",
    padding: "0.9vw 0.5vw",
    marginLeft: "0vw",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "0.51vw",
    lineHeight: "100%",
    color: "#FFFFFF",
  },
  select3: {
    height: "1vw",
    width: "100%",
    marginTop: "0.1vw",
    padding: "0vw 0vw",
    marginLeft: "0vw",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "0.51vw",
    lineHeight: "100%",
    color: "#FFFFFF",
    position: "relative",
    top: "0.81vw",
    left: "0.2vw",
  },
  icon: {
    fill: "white",
  },
}));

export default function Addmanually({ width }) {
  const classes = useStyles();
  const [checkone, setCheckone] = useState(false);
  const [checkone1, setCheckone1] = useState(false);

  const [arrayofservices, setArrayofservices] = useState([
    { name: "", description: "", image: "", amount: "" },
  ]);
  const [open121, setOpen121] = React.useState(false);
  const handleClose121 = () => {
    setOpen121(false);
  };
  const handleToggle121 = () => {
    setOpen121(!open121);
  };
  const [Categoryerror, setCategoryerror] = useState("");
  const [Category, setCategory] = useState("");

  const [datestart, setDatestart] = useState();
  const [dateend, setDateend] = useState();

  const [servicechargetype, setServicechargetype] = useState("Hourly");
  const [producttype, setProducttype] = useState("Product");

  const [ErrorCheck, setErrorCheck] = useState(false);
  const [ErrorCheck2, setErrorCheck2] = useState(false);

  const [ProductName, setProductName] = useState();
  const [ProductCode, setproductCode] = useState("");
  const [hsnCode, sethsncode] = useState("");
  const [gstrate, setgstrate] = useState("0");
  const [sellingprice, setsellingprice] = useState();
  const [costprice, setcostprice] = useState();
  const [desciption, setdesciption] = useState();
  const [longDescription, setlongdescritpion] = useState();

  const [discount, setDiscount] = useState(false);
  const [Private, setPrivate] = useState(true);

  const handlePostJOb = () => {
    if (!(checkone && checkone1)) {
      if (!checkone) {
        setErrorCheck(true);
      }
      if (!checkone1) {
        setErrorCheck2(true);
      }
      return false;
    }

    handleToggle121();
    const formdata = new FormData();

    arrayoffiles?.map((data) => {
      formdata.append("fileName", data);
    });

    formdata.append("private", Private);
    formdata.append("category", categogryid);
    formdata.append("type", producttype);
    formdata.append("serviceType", servicechargetype);
    formdata.append("productName", ProductName);
    formdata.append("productCode", ProductCode);
    formdata.append("hsnCode", hsnCode);
    formdata.append("gstRate", gstrate);
    formdata.append("sellingPrice", sellingprice);
    formdata.append("costPrice", costprice);
    formdata.append("description", desciption);
    formdata.append("longDescription", longDescription ? longDescription : "");
    formdata.append("addDiscount", discount);
    datestart && formdata.append("liveFrom", datestart);
    dateend && formdata.append("expiryDate", dateend);
    formdata.append("addOns", JSON?.stringify(arrayofservices));

    axios
      .post(`${API_HOST}/userProducts/newestProduct`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        navigate("/dashboard/products");
        handleClose121();
      })
      .catch((err) => {
        console.log(err?.response?.data?.fails?.message);
        setErroeshow(err?.response?.data?.fails?.message);

        handleClose121();
      });
  };
  const [erroeshow, setErroeshow] = useState(false);

  const navigate = useNavigate();

  const [arrayoffiles, setArrayoffiles] = useState([]);

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  const [arrayofgst, setarrayofgst] = useState([0, 5, 12, 18, 28]);

  const [arrayoflongdegree, setArrayoflongdegree] = useState();
  const [searchCategorysearch, setSearchCategorysearch] = useState("");
  useEffect(() => {
    axios
      .get(
        `${API_HOST}/theCategory/viewCategory?pageSize=50&pageNumber=1&category=${searchCategorysearch}`
      )
      .then((res) => {
        setArrayoflongdegree(res?.data?.success?.data);
      });
  }, [searchCategorysearch]);

  const [anchorElx2, setAnchorElx2] = React.useState(null);
  const handleClickx2 = (event) => {
    setAnchorElx2(event.currentTarget);
  };

  const handleClosex2 = () => {
    setAnchorElx2(null);
  };

  const openx2 = Boolean(anchorElx2);
  const idx2 = openx2 ? "simple-popover" : undefined;

  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const open1 = Boolean(anchorEl1);
  const id1 = open1 ? "simple-popover" : undefined;

  const [anchorEl1x, setAnchorEl1x] = React.useState(null);
  const handleClick1x = (event) => {
    setAnchorEl1x(event.currentTarget);
  };

  const handleClose1x = () => {
    setAnchorEl1x(null);
  };

  const open1x = Boolean(anchorEl1x);
  const id1x = open1x ? "simple-popover" : undefined;

  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const open2 = Boolean(anchorEl2);
  const id2 = open2 ? "simple-popover" : undefined;

  const [categogryid, setCategogryid] = useState();
  const [Categoryinfo, setCategoryinfo] = useState("");

  async function handleImageUpload(event) {
    const imageFile = event.target.files[0];
    console.log(imageFile?.type?.includes("image"));

    if (imageFile?.type?.includes("image")) {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(imageFile, options);
        console.log(
          "compressedFile instanceof Blob",
          compressedFile instanceof Blob
        ); // true
        console.log(
          `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
        ); // smaller than maxSizeMB

        //   await uploadToServer(compressedFile); // write your own logic
        await setArrayoffiles([
          ...arrayoffiles,
          new File([compressedFile], `${compressedFile?.name}`),
        ]);
      } catch (error) {
        console.log(error);
      }
    } else {
      if (imageFile.size < 1024 * 1024) {
        setArrayoffiles([...arrayoffiles, imageFile]);
      }
    }
  }

  return (
    <div
      style={{
        height: "fit-content",
        justifyContent: "center",
        width: width > 700 ? "80vw" : "100vw",
        paddingLeft: width > 700 ? "3vw" : "0vw",
      }}
      className="home-postjob-container"
    >
      <div
        style={{
          overflowX: "hidden",
          paddingBottom: "3vw",
          width: width > 700 ? "80vw" : "94vw",
          margin: "2vw",
          paddingTop: width > 700 ? "1vw" : "3vw",

          height: "fit-content",
        }}
        className="homepostjob-right "
      >
        <div className="jobpostedformheading">
          Add Product /Service Manually
        </div>

        <div>
          <div className="jobpodtedfieldtitile"> Product /Service Name</div>
          <div className="jobpostfieldinputbox">
            <input
              type="text"
              name="title"
              value={ProductName}
              onChange={(e) => {
                setProductName(e.target.value);
              }}
            />
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: width > 700 ? "97%" : "100%",
              flexDirection: width > 700 ? "row" : "column",
            }}
          >
            <div style={{ width: width > 700 ? "50%" : "100%" }}>
              <div
                style={{
                  left: "0vw",
                  width: width > 700 ? "94%" : "100% ",
                  marginLeft: "0%",
                }}
                className="loginfield"
                onClick={handleClick1x}
              >
                <TextField
                  id="outlined-basic"
                  label="Select Product / Service "
                  variant="outlined"
                  disabled
                  value={producttype}
                  style={{ width: "100%" }}
                  InputLabelProps={{
                    style: {
                      fontSize: width > 700 ? "1vw" : "3vw",
                      fontFamily: "Poppins",
                      fontStyle: "500",
                      fontWeight: "500",
                      color: "black",
                    },
                  }}
                  inputProps={{ className: classes.input }}
                  onChange={(e) => {
                    console.log(e.target.value);
                  }}
                />
                <span style={{ width: "0.1vw" }}>
                  <KeyboardArrowDownOutlined
                    style={{
                      fontSize: width > 700 ? "1.5vw" : "5vw",
                      position: "relative",
                      right: width > 700 ? "2vw" : "6vw",
                      top: width > 700 ? "1vw" : "3vw",
                    }}
                  />
                </span>
              </div>
              <Popover
                id={id1x}
                open={open1x}
                anchorEl={anchorEl1x}
                onClose={handleClose1x}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <div
                  style={{
                    maxHeight: width > 700 ? "18vw" : "40vw",
                    overflow: "scroll",
                    width: width > 700 ? "31vw" : "94vw",
                  }}
                >
                  <Typography
                    sx={{
                      p: 0.51,
                      pl: 1,
                      ml: 1,
                      width: "100%",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setProducttype("Product");
                      handleClose1x();
                    }}
                  >
                    Product
                  </Typography>

                  <Typography
                    sx={{
                      p: 0.51,
                      pl: 1,
                      ml: 1,
                      width: "100%",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setProducttype("Service");
                      handleClose1x();
                    }}
                  >
                    Service
                  </Typography>
                </div>
              </Popover>
            </div>

            <div style={{ width: width > 700 ? "50%" : "100%" }}>
              <div
                style={{
                  left: "0vw",
                  width: width > 700 ? "94%" : "100% ",
                  marginLeft: "0%",
                  opacity: producttype !== "Service" && "0.4",
                }}
                className="loginfield"
                onClick={producttype === "Service" && handleClick2}
              >
                <TextField
                  id="outlined-basic"
                  label="Select Service Charges"
                  variant="outlined"
                  disabled
                  value={servicechargetype}
                  style={{ width: "100%" }}
                  InputLabelProps={{
                    style: {
                      fontSize: width > 700 ? "1vw" : "3vw",
                      fontFamily: "Poppins",
                      fontStyle: "500",
                      fontWeight: "500",
                      color: "black",
                    },
                  }}
                  inputProps={{ className: classes.input }}
                  onChange={(e) => {
                    console.log(e.target.value);
                  }}
                />
                <span style={{ width: "0.1vw" }}>
                  <KeyboardArrowDownOutlined
                    style={{
                      fontSize: width > 700 ? "1.5vw" : "5vw",
                      position: "relative",
                      right: width > 700 ? "2vw" : "6vw",
                      top: width > 700 ? "1vw" : "3vw",
                    }}
                  />
                </span>
              </div>
              <Popover
                id={id2}
                open={open2}
                anchorEl={anchorEl2}
                onClose={handleClose2}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <div
                  style={{
                    maxHeight: width > 700 ? "18vw" : "40vw",
                    overflow: "scroll",
                    width: width > 700 ? "31vw" : "94vw",
                  }}
                >
                  <Typography
                    sx={{
                      p: 0.51,
                      pl: 1,
                      ml: 1,
                      width: "100%",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setServicechargetype("Hourly");
                      handleClose2();
                    }}
                  >
                    Hourly
                  </Typography>

                  <Typography
                    sx={{
                      p: 0.51,
                      pl: 1,
                      ml: 1,
                      width: "100%",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setServicechargetype("Full-day");
                      handleClose2();
                    }}
                  >
                    Full-day
                  </Typography>
                </div>
              </Popover>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: width > 700 ? "97%" : "100%",
              flexDirection: width > 700 ? "row" : "column",
            }}
          >
            <div style={{ width: width > 700 ? "50%" : "100%" }}>
              <div
                style={{
                  left: "0vw",
                  width: width > 700 ? "94%" : "100% ",
                  marginLeft: "0%",
                }}
                className="loginfield"
                onClick={handleClickx2}
              >
                <TextField
                  id="outlined-basic"
                  label="Select  Category *"
                  variant="outlined"
                  disabled
                  value={Category}
                  style={{ width: "100%" }}
                  InputLabelProps={{
                    style: {
                      fontSize: width > 700 ? "1vw" : "3vw",
                      fontFamily: "Poppins",
                      fontStyle: "500",
                      fontWeight: "500",
                      color: "black",
                    },
                  }}
                  inputProps={{ className: classes.input }}
                  onChange={(e) => {
                    console.log(e.target.value);
                  }}
                />
                <span style={{ width: "0.1vw" }}>
                  <KeyboardArrowDownOutlined
                    style={{
                      fontSize: width > 700 ? "1.5vw" : "5vw",
                      position: "relative",
                      right: width > 700 ? "2vw" : "6vw",
                      top: width > 700 ? "1vw" : "3vw",
                    }}
                  />
                </span>
              </div>
              <Popover
                id={idx2}
                open={openx2}
                anchorEl={anchorElx2}
                onClose={handleClosex2}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <div
                  style={{
                    maxHeight: width > 700 ? "18vw" : "40vw",
                    overflow: "scroll",
                    width: width > 700 ? "31vw" : "94vw",
                  }}
                >
                  <Typography
                    sx={{
                      p: 1,
                      pl: width > 700 ? 1 : 0,
                      ml: 1,
                      pr: 0,
                      width: width > 700 ? "30vw" : "89vw",
                      position: "fixed",
                      background: "white",
                      zIndex: "10",
                    }}
                  >
                    <input
                      placeholder="search here .."
                      onChange={(e) => {
                        setSearchCategorysearch(e.target.value);
                      }}
                      style={{
                        width: "96%",
                        border: "1.5px solid #00000050",
                        outline: "none",
                        height: "2.5",
                        borderRadius: "0.21vw",
                      }}
                    />
                  </Typography>
                  <Typography
                    sx={{
                      p: 2.5,
                      pl: 1,
                      ml: 1,
                      width: "100%",
                      cursor: "pointer",
                    }}
                  ></Typography>

                  {arrayoflongdegree?.length > 0 &&
                    arrayoflongdegree.map((data, index) => {
                      return (
                        <Typography
                          sx={{
                            p: 0.51,
                            pl: 1,
                            ml: 1,
                            width: "100%",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setCategory(data?.category);
                            handleClosex2();
                            setCategoryerror();
                            setCategogryid(data?._id);
                            setCategoryinfo(data?.description);
                          }}
                        >
                          {data?.category}
                        </Typography>
                      );
                    })}
                </div>
              </Popover>
              <p
                style={{
                  color: "red",
                  fontSize: width > 700 ? "0.9vw" : "2.7vw",
                }}
              >
                {Categoryerror}
              </p>{" "}
            </div>

            <div style={{ width: width > 700 ? "50%" : "100%" }}>
              <div
                style={{
                  left: "0vw",
                  width: width > 700 ? "94%" : "100% ",
                  marginLeft: "0%",
                }}
                className="loginfield"
                onClick={handleClick1}
              >
                <TextField
                  id="outlined-basic"
                  label="Select GST Rate "
                  variant="outlined"
                  disabled
                  value={gstrate + " %"}
                  style={{ width: "100%" }}
                  InputLabelProps={{
                    style: {
                      fontSize: width > 700 ? "1vw" : "3vw",
                      fontFamily: "Poppins",
                      fontStyle: "500",
                      fontWeight: "500",
                      color: "black",
                    },
                  }}
                  inputProps={{ className: classes.input }}
                  onChange={(e) => {
                    console.log(e.target.value);
                  }}
                />
                <span style={{ width: "0.1vw" }}>
                  <KeyboardArrowDownOutlined
                    style={{
                      fontSize: width > 700 ? "1.5vw" : "5vw",
                      position: "relative",
                      right: width > 700 ? "2vw" : "6vw",
                      top: width > 700 ? "1vw" : "3vw",
                    }}
                  />
                </span>
              </div>
              <Popover
                id={id1}
                open={open1}
                anchorEl={anchorEl1}
                onClose={handleClose1}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <div
                  style={{
                    maxHeight: width > 700 ? "18vw" : "40vw",
                    overflow: "scroll",
                    width: width > 700 ? "31vw" : "94vw",
                  }}
                >
                  {arrayofgst?.length > 0 &&
                    arrayofgst.map((data, index) => {
                      return (
                        <Typography
                          sx={{
                            p: 0.51,
                            pl: 1,
                            ml: 1,
                            width: "100%",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setgstrate(data);
                            handleClose1();
                          }}
                        >
                          {data} %
                        </Typography>
                      );
                    })}
                </div>
              </Popover>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: width > 700 ? "97%" : "100%",
              flexDirection: width > 700 ? "row" : "column",
            }}
          >
            <div style={{ width: width > 700 ? "50%" : "100%" }}>
              <div
                style={{
                  left: "0vw",
                  width: width > 700 ? "94%" : "100% ",
                  marginLeft: "0%",
                }}
                className="loginfield"
              >
                <TextField
                  id="outlined-basic"
                  label="Enter Product Code"
                  variant="outlined"
                  value={ProductCode}
                  style={{ width: "100%" }}
                  InputLabelProps={{
                    style: {
                      fontSize: width > 700 ? "1vw" : "3vw",
                      fontFamily: "Poppins",
                      fontStyle: "500",
                      fontWeight: "500",
                      color: "black",
                    },
                  }}
                  inputProps={{ className: classes.input }}
                  onChange={(e) => {
                    setproductCode(e.target.value);
                  }}
                />
              </div>
            </div>

            <div style={{ width: width > 700 ? "50%" : "100%" }}>
              <div
                style={{
                  left: "0vw",
                  width: width > 700 ? "94%" : "100% ",
                  marginLeft: "0%",
                }}
                className="loginfield"
              >
                <TextField
                  id="outlined-basic"
                  label="Enter HSN Code (optional)"
                  variant="outlined"
                  type="number"
                  value={hsnCode}
                  style={{ width: "100%" }}
                  InputLabelProps={{
                    style: {
                      fontSize: width > 700 ? "1vw" : "3vw",
                      fontFamily: "Poppins",
                      fontStyle: "500",
                      fontWeight: "500",
                      color: "black",
                    },
                  }}
                  inputProps={{ className: classes.input }}
                  onChange={(e) => {
                    sethsncode(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: width > 700 ? "97%" : "100%",
              flexDirection: width > 700 ? "row" : "column",
            }}
          >
            <div style={{ width: width > 700 ? "50%" : "100%" }}>
              <div
                style={{
                  left: "0vw",
                  width: width > 700 ? "94%" : "100% ",
                  marginLeft: "0%",
                }}
                className="loginfield"
              >
                <TextField
                  id="outlined-basic"
                  label="Selling Price (Rs) - MRP *"
                  variant="outlined"
                  type="number"
                  value={sellingprice}
                  style={{ width: "100%" }}
                  InputLabelProps={{
                    style: {
                      fontSize: width > 700 ? "1vw" : "3vw",
                      fontFamily: "Poppins",
                      fontStyle: "500",
                      fontWeight: "500",
                      color: "black",
                    },
                  }}
                  inputProps={{ className: classes.input }}
                  onChange={(e) => {
                    setsellingprice(e.target.value);
                  }}
                />
              </div>
            </div>

            <div style={{ width: width > 700 ? "50%" : "100%" }}>
              <div
                style={{
                  left: "0vw",
                  width: width > 700 ? "94%" : "100% ",
                  marginLeft: "0%",
                }}
                className="loginfield"
              >
                <TextField
                  id="outlined-basic"
                  label="Offer Price (Rs) *"
                  variant="outlined"
                  type="number"
                  value={costprice}
                  style={{ width: "100%" }}
                  InputLabelProps={{
                    style: {
                      fontSize: width > 700 ? "1vw" : "3vw",
                      fontFamily: "Poppins",
                      fontStyle: "500",
                      fontWeight: "500",
                      color: "black",
                    },
                  }}
                  inputProps={{ className: classes.input }}
                  onChange={(e) => {
                    setcostprice(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="jobpodtedfieldtitile"> Short Description</div>
          <div className="jobpostfieldinputbox">
            <textarea
              type="text"
              name="desc"
              value={desciption}
              onChange={(e) => {
                setdesciption(e.target.value);
              }}
            />
          </div>

          <div className="jobpodtedfieldtitile">Long Description</div>
          <div
            style={{
              margin: "0vw 0vw 1vw 0vw",
              width: width > 700 ? "67vw" : "94vw",
            }}
          >
            <Texteditor
              setShortdesc={setlongdescritpion}
              shortdesc={longDescription}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: width > 700 ? "94%" : "100% ",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "40%",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "2vw",
                marginBottom: "1vw",
              }}
              className="jobpodtedfieldtitile"
            >
              Add Discount
              <span>
                {" "}
                <ToggleButton
                  value={discount}
                  onToggle={(value) => {
                    setDiscount(!discount);
                  }}
                />
              </span>
            </div>

            <div
              style={{
                display: "flex",
                width: "48%",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "2vw",
                marginBottom: "1vw",
              }}
              className="jobpodtedfieldtitile"
            >
              Private
              <span>
                {" "}
                <ToggleButton
                  value={Private}
                  onToggle={(value) => {
                    setPrivate(!Private);
                  }}
                />
              </span>
            </div>
          </div>

          <div style={{ display: "flex", alignItems: "center", width: "97%" }}>
            <div style={{ width: "50%" }}>
              <div className="jobpodtedfieldtitile">
                Live Product Start Date *
              </div>
              <div className="jobpostfieldinputbox">
                <input
                  style={{ width: "100%" }}
                  type="date"
                  className="input-homejobformdate"
                  name=""
                  id=""
                  min={disablePastDate()}
                  max={"2025-12-31"}
                  maxlength="4"
                  onChange={(e) => {
                    setDatestart(e.target.value);
                  }}
                />
              </div>
            </div>

            <div style={{ width: "50%" }}>
              <div className="jobpodtedfieldtitile">
                Live Product End Date *
              </div>

              <div className="jobpostfieldinputbox">
                <input
                  style={{ width: "100%" }}
                  type="date"
                  className="input-homejobformdate"
                  name=""
                  id=""
                  min={disablePastDate()}
                  max={"2025-12-31"}
                  maxlength="4"
                  onChange={(e) => {
                    setDateend(e.target.value);
                  }}
                />
              </div>

              <p style={{ color: "red" }} className="redp"></p>
            </div>
          </div>

          <div className="jobpodtedfieldtitile">Image/Documents </div>
          <div
            style={{
              background: "white",
              padding: "0.51vw",
              marginTop: "0vw",
              paddingRight: "3vw",
            }}
          >
            <div className="inputfilebox">
              <div>
                <label htmlFor="inputctaelogfile">
                  <div className="inputicon">
                    <img src={img} alt="" />
                  </div>
                  <div className="inputcateaddformfile">
                    Drag and Drop ,Browse to upload
                  </div>
                  <input
                    type="file"
                    id="inputctaelogfile"
                    onChange={(e) => {
                      handleImageUpload(e);
                    }}
                    hidden
                  />
                </label>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              fontSize: width > 700 ? "0.8vw" : "2.7vw",
              paddingRight: "4.5vw",
              textAlign: "right",
            }}
          >
            Please upload file having extensions .jpeg/ .jpg/ .png only.
            <br />
            Image should be less then 512 kb.
          </div>
          <div
            className={arrayoffiles?.length > 0 ? "inputfilesshowncatebox" : ""}
          >
            {arrayoffiles?.length > 0 &&
              arrayoffiles?.map((file, index) => {
                return (
                  <div className="inputfilesshowncatboxsingle">
                    <div className="inputfilesshowncatboxsingleimg">
                      <img src={img1} alt="" />
                    </div>
                    <div className="fileselctednamecate">{file?.name}</div>
                    <div className="inputfilesshowncatboxsingleimg">
                      <img
                        style={{
                          width: width > 700 ? "1.5vw" : "4.5vw",
                          marginLeft: "1vw",
                          cursor: "pointer",
                        }}
                        src={img21}
                        alt=""
                        onClick={() => {
                          setArrayoffiles([
                            ...arrayoffiles.slice(0, index),
                            ...arrayoffiles.slice(
                              index + 1,
                              arrayoffiles.length
                            ),
                          ]);
                        }}
                      />
                    </div>
                  </div>
                );
              })}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "98%",
              marginTop: "2vw",
              alignItems: "center",
            }}
          >
            <div
              style={{ marginBottom: "0.5vw" }}
              className="jobpodtedfieldtitile"
            >
              Add On
            </div>
            <div
              style={{
                width: width > 700 ? "fit-content" : "fit-content",
                fontSize: width > 700 ? "1.1vw" : "3vw",
              }}
              className="servicesmenuname4box"
              onClick={() => {
                setArrayofservices([
                  ...arrayofservices?.slice(0, arrayofservices?.length - 1),
                ]);
              }}
            >
              <span style={{ color: "red", cursor: "pointer" }}>Remove</span>
            </div>
          </div>

          {arrayofservices?.map((data, index) => {
            return (
              <>
                <Offers
                  data={data}
                  width={width}
                  index={index}
                  setArrayofservices={setArrayofservices}
                  arrayofservices={arrayofservices}
                />
              </>
            );
          })}

          <div
            style={{ marginTop: "1.5vw", marginBottom: "0.5vw" }}
            className="addmoreservicecatalog"
            onClick={() => {
              setArrayofservices([
                ...arrayofservices,
                { name: "", heading: "", image: "" },
              ]);
            }}
          >
            <span>
              <AddIcon style={{ fontSize: width > 700 ? "1.3vw" : "4vw" }} />
            </span>{" "}
            Add More Add-on
          </div>

          <div className="checktemandc">
            <div
              className="checkbox"
              onClick={() => {
                setCheckone(!checkone);

                setErrorCheck(false);
              }}
              required="required"
            >
              {checkone ? (
                <DoneIcon
                  style={{
                    fontSize: width > 700 ? "0.81vw" : "3.7vw",
                    color: "blueviolet",
                    fontWeight: "600",
                  }}
                />
              ) : (
                ""
              )}{" "}
            </div>
            <div className="checktermandconditiontext">
              I agree the{" "}
              <span
                style={{ cursor: "pointer" }}
                onClick={() => window.open("/termsandconditions", "_blank")}
              >
                Terms and Condition *
              </span>
            </div>
          </div>
          <span
            style={{ color: "red", fontSize: width > 700 ? "0.9vw" : "2.7vw" }}
          >
            {ErrorCheck ? <span>Please Click on Terms and Condition</span> : ""}
          </span>

          <div className="checktemandc">
            <div
              className="checkbox"
              onClick={() => {
                setCheckone1(!checkone1);

                setErrorCheck2(false);
              }}
            >
              {checkone1 ? (
                <DoneIcon
                  style={{
                    fontSize: width > 700 ? "0.81vw" : "3.7vw",
                    color: "blueviolet",
                    fontWeight: "600",
                  }}
                />
              ) : (
                ""
              )}{" "}
            </div>
            <div className="checktermandconditiontext">
              I agree the{" "}
              <span
                onClick={() => window.open("/privacypolicy", "_blank")}
                style={{ cursor: "pointer" }}
              >
                Privacy Policy *
              </span>
            </div>
          </div>

          <span style={{ color: "red", fontSize: width > 700 ? "1vw" : "3vw" }}>
            {ErrorCheck2 ? <span>Please Click on Privacy Policy *</span> : ""}
          </span>

          <span style={{ color: "red", fontSize: width > 700 ? "1vw" : "3vw" }}>
            {erroeshow ? (
              <>
                <span>Please Fill all the necessary fields *</span>
                <div>({erroeshow})</div>
              </>
            ) : (
              ""
            )}
          </span>

          <div className="homejobbuttons">
            <button
              style={{ background: "white" }}
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </button>

            <button
              // disabled={checkone && checkone1 ? false : true}
              style={{ color: "white" }}
              onClick={handlePostJOb}
            >
              Submit
            </button>
          </div>
        </div>

        {Categoryinfo && (
          <div className="Categoryinfo">
            <div dangerouslySetInnerHTML={{ __html: Categoryinfo }}></div>
          </div>
        )}
      </div>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open121}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
