import React from "react";
import "./Service.css";
import img from "../../assets/Landing page/checkmark (1).svg";
import img1 from "../../assets/services/Expenditure.svg";
import img2 from "../../assets/services/Tax.svg";
import img3 from "../../assets/services/Website.svg";
import img4 from "../../assets/services/Invoice Genertor.svg";
import img5 from "../../assets/services/Marketplace.svg";
import img6 from "../../assets/services/Growth strategies.svg";
import img7 from "../../assets/services/Write Blogs.svg";
import img8 from "../../assets/services/undraw_team_page_re_cffb.svg";
import img9 from "../../assets/services/undraw_blog_post_re_fy5x.svg";
import img10 from "../../assets/services/undraw_booking_re_gw4j.svg";
import img11 from "../../assets/services/undraw_placeholders_re_pvr4.svg";
import img12 from "../../assets/services/undraw_digital_currency_qpak.svg";
import img13 from "../../assets/services/undraw_gifts_0ceh.svg";
import img14 from "../../assets/services/undraw_experts_re_i40h.svg";
import img15 from "../../assets/services/undraw_lightbulb_moment_re_ulyo.svg";
import img16 from "../../assets/services/undraw_news_re_6uub.svg";
import img17 from "../../assets/services/undraw_searching_re_3ra9.svg";
import img18 from "../../assets/services/undraw_questions_re_1fy7.svg";
import img19 from "../../assets/services/undraw_virtual_assistant_jjo2.svg";
import img20 from "../../assets/services/undraw_ideas_re_7twj.svg";

export default function Serviceswhatdo({ width }) {
  return (
    <div className="whatdoservicescontainer">
      <div className="abouttitileheading"> Tools</div>
      <div
        style={{ marginBottom: "1vw", padding: "0 7vw" }}
        className="abouttitileheadingsub"
      >
        Hello there! Are you looking to streamline and grow your business
        operations? Look no further! Tool44.com is the comprehensive business
        platform you need. With a wide range of features and services designed
        to help businesses like yours manage and thrive, Tool44.com is here to
        support your journey to success.
      </div>

      <div style={{ flexWrap: "wrap" }} className="whatdoboxescontainer">
        <div style={{ height: "fit-content" }} className="whatdoservcesbox">
          <img
            style={{
              width: "100%",
              maxHeight: width > 700 ? "14vw" : "45vw",
              objectFit: "contain",
            }}
            src={img5}
            alt=""
          />

          <div className="whatboxstitle"> Marketplace</div>

          <div
            style={{ width: "100%", textAlign: "center" }}
            className="tickmarkworkdoservicebox"
          >
            <div>
              Launch or sell your products/services with zero marketing costs.
              Reach a wider audience and boost your sales effortlessly.
            </div>
          </div>
        </div>

        <div style={{ height: "fit-content" }} className="whatdoservcesbox">
          <img
            style={{
              width: "100%",
              maxHeight: width > 700 ? "14vw" : "45vw",
              objectFit: "contain",
            }}
            src={img3}
            alt=""
          />

          <div className="whatboxstitle">Website Builder </div>

          <div
            style={{ width: "100%", textAlign: "center" }}
            className="tickmarkworkdoservicebox"
          >
            <div>
              Create a professional website in minutes, with no coding or design
              experience required. Establish a strong online presence and
              attract more customers.
            </div>
          </div>
        </div>

        <div style={{ height: "fit-content" }} className="whatdoservcesbox">
          <img
            style={{
              width: "100%",
              maxHeight: width > 700 ? "14vw" : "45vw",
              objectFit: "contain",
            }}
            src={img19}
            alt=""
          />

          <div className="whatboxstitle"> Business Virtual Assistant </div>

          <div
            style={{ width: "100%", textAlign: "center" }}
            className="tickmarkworkdoservicebox"
          >
            <div>
              Get personalized assistance, timely updates, and growth training
              for your business. Stay informed and ahead of the competition.
            </div>
          </div>
        </div>
        <div style={{ height: "fit-content" }} className="whatdoservcesbox">
          <img
            style={{
              width: "100%",
              maxHeight: width > 700 ? "14vw" : "45vw",
              objectFit: "contain",
            }}
            src={img17}
            alt=""
          />

          <div className="whatboxstitle"> Find and Connect </div>

          <div
            style={{ width: "100%", textAlign: "center" }}
            className="tickmarkworkdoservicebox"
          >
            <div>
              Discover and connect with the right suppliers and service
              providers for your business needs. Build valuable partnerships
              that drive growth.
            </div>
          </div>
        </div>

        <div style={{ height: "fit-content" }} className="whatdoservcesbox">
          <img
            style={{
              width: "100%",
              maxHeight: width > 700 ? "14vw" : "45vw",
              objectFit: "contain",
            }}
            src={img4}
            alt=""
          />

          <div className="whatboxstitle">Invoice Generator </div>

          <div
            style={{ width: "100%", textAlign: "center" }}
            className="tickmarkworkdoservicebox"
          >
            <div>
              Generate accurate invoices with a professional appearance. Impress
              your clients and streamline your billing process.
            </div>
          </div>
        </div>

        <div style={{ height: "fit-content" }} className="whatdoservcesbox">
          <img
            style={{
              width: "100%",
              maxHeight: width > 700 ? "14vw" : "45vw",
              objectFit: "contain",
            }}
            src={img1}
            alt=""
          />

          <div className="whatboxstitle">Manage Expenditure</div>

          <div
            style={{ width: "100%", textAlign: "center" }}
            className="tickmarkworkdoservicebox"
          >
            <div>
              Easily keep track of all your expenses on a user-friendly
              platform. Take control of your finances and optimize your
              spending.
            </div>
          </div>
        </div>

        <div style={{ height: "fit-content" }} className="whatdoservcesbox">
          <img
            style={{
              width: "100%",
              maxHeight: width > 700 ? "14vw" : "45vw",
              objectFit: "contain",
            }}
            src={img2}
            alt=""
          />

          <div className="whatboxstitle">Taxation Tools</div>

          <div
            style={{ width: "100%", textAlign: "center" }}
            className="tickmarkworkdoservicebox"
          >
            <div>
              Access taxation reports for easy tax compliance. Stay organized
              and avoid last-minute headaches during tax season.
            </div>
          </div>
        </div>

        <div style={{ height: "fit-content" }} className="whatdoservcesbox">
          <img
            style={{
              width: "100%",
              maxHeight: width > 700 ? "14vw" : "45vw",
              objectFit: "contain",
            }}
            src={img6}
            alt=""
          />

          <div className="whatboxstitle">Growth strategies</div>

          <div
            style={{ width: "100%", textAlign: "center" }}
            className="tickmarkworkdoservicebox"
          >
            <div>
              Join our weekly webinars on various business topics for
              performance improvement. Learn from industry experts and implement
              proven strategies.
            </div>
          </div>
        </div>

        <div style={{ height: "fit-content" }} className="whatdoservcesbox">
          <img
            style={{
              width: "100%",
              maxHeight: width > 700 ? "14vw" : "45vw",
              objectFit: "contain",
            }}
            src={img13}
            alt=""
          />

          <div className="whatboxstitle">Reward & Content</div>

          <div
            style={{ width: "100%", textAlign: "center" }}
            className="tickmarkworkdoservicebox"
          >
            <div>
              Enjoy exciting rewards and recognition for your business
              achievements. Stay motivated and inspired throughout your journey.
            </div>
          </div>
        </div>
        <div style={{ height: "fit-content" }} className="whatdoservcesbox">
          <img
            style={{
              width: "100%",
              maxHeight: width > 700 ? "14vw" : "45vw",
              objectFit: "contain",
            }}
            src={img12}
            alt=""
          />

          <div className="whatboxstitle">Save Cost</div>

          <div
            style={{ width: "100%", textAlign: "center" }}
            className="tickmarkworkdoservicebox"
          >
            <div>
              Compare options and choose affordable solutions to save costs.
              Maximize your budget without compromising on quality.
            </div>
          </div>
        </div>

        <div style={{ height: "fit-content" }} className="whatdoservcesbox">
          <img
            style={{
              width: "100%",
              maxHeight: width > 700 ? "14vw" : "45vw",
              objectFit: "contain",
            }}
            src={img7}
            alt=""
          />

          <div className="whatboxstitle">Write Blogs</div>

          <div
            style={{ width: "100%", textAlign: "center" }}
            className="tickmarkworkdoservicebox"
          >
            <div>
              Write and publish marketing-focused blog posts to enhance your
              online presence and reach a wider audience. Boost visibility and
              attract customers.
            </div>
          </div>
        </div>

        <div style={{ height: "fit-content" }} className="whatdoservcesbox">
          <img
            style={{
              width: "100%",
              maxHeight: width > 700 ? "14vw" : "45vw",
              objectFit: "contain",
            }}
            src={img8}
            alt=""
          />

          <div className="whatboxstitle"> Find Talent </div>

          <div
            style={{ width: "100%", textAlign: "center" }}
            className="tickmarkworkdoservicebox"
          >
            <div>
              Hire freelancers or employees for your business needs. Find the
              perfect fit and expand your team effortlessly.
            </div>
          </div>
        </div>

        <div style={{ height: "fit-content" }} className="whatdoservcesbox">
          <img
            style={{
              width: "100%",
              maxHeight: width > 700 ? "14vw" : "45vw",
              objectFit: "contain",
            }}
            src={img14}
            alt=""
          />

          <div className="whatboxstitle"> Connect with Experts</div>

          <div
            style={{ width: "100%", textAlign: "center" }}
            className="tickmarkworkdoservicebox"
          >
            <div>
              Access a diverse pool of talented professionals for consulting,
              design, marketing & more. Get the expert help you need to take
              your business to new heights.
            </div>
          </div>
        </div>

        <div style={{ height: "fit-content" }} className="whatdoservcesbox">
          <img
            style={{
              width: "100%",
              maxHeight: width > 700 ? "14vw" : "45vw",
              objectFit: "contain",
            }}
            src={img11}
            alt=""
          />

          <div className="whatboxstitle">Post Creator</div>

          <div
            style={{ width: "100%", textAlign: "center" }}
            className="tickmarkworkdoservicebox"
          >
            <div>
              Automate your social media posts for seamless scheduling and
              content sharing. Save time and maintain an active online presence.
            </div>
          </div>
        </div>
        <div style={{ height: "fit-content" }} className="whatdoservcesbox">
          <img
            style={{
              width: "100%",
              maxHeight: width > 700 ? "14vw" : "45vw",
              objectFit: "contain",
            }}
            src={img10}
            alt=""
          />

          <div className="whatboxstitle">Order Calendar</div>

          <div
            style={{ width: "100%", textAlign: "center" }}
            className="tickmarkworkdoservicebox"
          >
            <div>
              Effortlessly manage your services with an intuitive order and
              booking calendar. Simplify your scheduling and enhance customer
              satisfaction.
            </div>
          </div>
        </div>
        <div style={{ height: "fit-content" }} className="whatdoservcesbox">
          <img
            style={{
              width: "100%",
              maxHeight: width > 700 ? "14vw" : "45vw",
              objectFit: "contain",
            }}
            src={img9}
            alt=""
          />

          <div className="whatboxstitle">Letter head</div>

          <div
            style={{ width: "100%", textAlign: "center" }}
            className="tickmarkworkdoservicebox"
          >
            <div>
              Automatically create branded letterheads for professional and
              consistent communication. Leave a lasting impression on your
              clients.
            </div>
          </div>
        </div>
        <div style={{ height: "fit-content" }} className="whatdoservcesbox">
          <img
            style={{
              width: "100%",
              maxHeight: width > 700 ? "14vw" : "45vw",
              objectFit: "contain",
            }}
            src={img16}
            alt=""
          />

          <div className="whatboxstitle"> Business Stories</div>

          <div
            style={{ width: "100%", textAlign: "center" }}
            className="tickmarkworkdoservicebox"
          >
            <div>
              Explore our vast resources & community for business ideas,
              fostering innovation & growth opportunities. Learn from success
              and unlock your potential.
            </div>
          </div>
        </div>
        <div style={{ height: "fit-content" }} className="whatdoservcesbox">
          <img
            style={{
              width: "100%",
              maxHeight: width > 700 ? "14vw" : "45vw",
              objectFit: "contain",
            }}
            src={img15}
            alt=""
          />

          <div className="whatboxstitle">Business Ideas</div>

          <div
            style={{ width: "100%", textAlign: "center" }}
            className="tickmarkworkdoservicebox"
          >
            <div>
              Business plans shared by successful entrepreneurs on Tool44.com.
              Draw inspiration from their strategies & develop your own path to
              success.
            </div>
          </div>
        </div>
        <div style={{ height: "fit-content" }} className="whatdoservcesbox">
          <img
            style={{
              width: "100%",
              maxHeight: width > 700 ? "14vw" : "45vw",
              objectFit: "contain",
            }}
            src={img20}
            alt=""
          />

          <div className="whatboxstitle">Access to Business Plans</div>

          <div
            style={{ width: "100%", textAlign: "center" }}
            className="tickmarkworkdoservicebox"
          >
            <div>
              Business plan access: Unlock detailed and comprehensive business
              plans for strategic decision-making and growth.
            </div>
          </div>
        </div>
        <div style={{ height: "fit-content" }} className="whatdoservcesbox">
          <img
            style={{
              width: "100%",
              maxHeight: width > 700 ? "14vw" : "45vw",
              objectFit: "contain",
            }}
            src={img18}
            alt=""
          />

          <div className="whatboxstitle">Problem Solving</div>

          <div
            style={{ width: "100%", textAlign: "center" }}
            className="tickmarkworkdoservicebox"
          >
            <div>
              Find solutions to business problems through case studies & tap
              into the expertise of our thriving community. Grow & overcome
              challenges together.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
