import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import React from "react";
import Popover from "@mui/material/Popover";

import Typography from "@mui/material/Typography";
export default function Productdata({
  width,
  data,
  index,
  setArrayofservices,
  arrayofservices,
  products,
  setSelectedCategory,
  setsetSelectedCategory,
}) {
  const [anchorElx2, setAnchorElx2] = React.useState(null);
  const handleClickx2 = (event) => {
    setAnchorElx2(event.currentTarget);
  };

  const handleClosex2 = () => {
    setAnchorElx2(null);
  };

  const openx2 = Boolean(anchorElx2);
  const idx2 = openx2 ? "simple-popover" : undefined;

  return (
    <>
      <div
        style={{
          marginTop: "1.5vw",
          flexWrap: "wrap",
          width: width > 700 ? "94%" : "98%",
        }}
        className="servicesmenudiv"
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            onClick={handleClickx2}
            className="servicesmenuname1boxWInc mb-2"
          >
            <input
              value={data?.productName}
              type="text"
              placeholder="Requirement Description"
              onChange={(e) => {
                setArrayofservices([
                  ...arrayofservices?.slice(0, index),
                  { ...data, productName: e.target?.value },
                  ...arrayofservices?.slice(index + 1, arrayofservices?.length),
                ]);
              }}
            />
            <span style={{ width: "0.1vw" }}>
              <KeyboardArrowDownOutlined
                style={{
                  fontSize: width > 700 ? "1.5vw" : "5vw",
                  cursor: "pointer",
                  position: "relative",
                  right: width > 700 ? "2vw" : "6vw",
                  top: width > 700 ? "0vw" : "0vw",
                }}
              />
            </span>
          </div>
          <Popover
            id={idx2}
            open={openx2}
            anchorEl={anchorElx2}
            onClose={handleClosex2}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div
              style={{
                maxHeight: width > 700 ? "18vw" : "40vw",
                overflow: "scroll",
                width: width > 700 ? "67.5vw" : "90vw",
              }}
            >
              <Typography
                sx={{
                  p: 1,
                  pl: width > 700 ? 1 : 0,
                  ml: 1,
                  pr: 0,
                  width: width > 700 ? "65vw" : "84vw",
                  position: "fixed",
                  background: "white",
                  zIndex: "10",
                }}
              >
                <input
                  placeholder="search here .."
                  onChange={(e) => {
                    setsetSelectedCategory(e.target.value);
                  }}
                  style={{
                    width: "96%",
                    border: "1.5px solid #00000050",
                    outline: "none",
                    height: "2.5",
                    borderRadius: "0.21vw",
                  }}
                />
              </Typography>
              <Typography
                sx={{
                  p: 2.5,
                  pl: 1,
                  ml: 1,
                  width: "100%",
                  cursor: "pointer",
                }}
              ></Typography>

              {products?.length > 0 &&
                products.map((data1) => {
                  return (
                    <Typography
                      sx={{
                        p: 0.51,
                        pl: 1,
                        ml: 1,
                        width: "100%",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        setArrayofservices([
                          ...arrayofservices?.slice(0, index),
                          {
                            productName: data1?.productName,
                            productPrice: data1?.costPrice,
                            qty: data?.qty,
                            totalPrice:
                              data1?.costPrice * (data?.qty ? data?.qty : 0),
                          },
                          ...arrayofservices?.slice(
                            index + 1,
                            arrayofservices?.length
                          ),
                        ]);
                        handleClosex2();
                      }}
                    >
                      {data1?.productName}
                    </Typography>
                  );
                })}
            </div>
          </Popover>
        </div>

        <div style={{ width: "32%" }} className="servicesmenuname2box">
          Rs.
          <input
            value={data?.productPrice}
            type="number"
            placeholder="Price / Rate"
            onChange={(e) => {
              setArrayofservices([
                ...arrayofservices?.slice(0, index),
                {
                  ...data,
                  productPrice: e.target?.value,
                  totalPrice: e.target?.value * (data?.qty ? data?.qty : 0),
                },
                ...arrayofservices?.slice(index + 1, arrayofservices?.length),
              ]);
            }}
          />
        </div>
        <div style={{ width: "32%" }} className="servicesmenuname2box">
          <input
            value={data?.qty}
            type="number"
            placeholder="Numbers"
            onChange={(e) => {
              setArrayofservices([
                ...arrayofservices?.slice(0, index),
                {
                  ...data,
                  qty: e.target?.value,
                  totalPrice:
                    e.target?.value *
                    (data?.productPrice ? data?.productPrice : 0),
                },
                ...arrayofservices?.slice(index + 1, arrayofservices?.length),
              ]);
            }}
          />
        </div>

        <div style={{ width: "32%" }} className="servicesmenuname2box">
          Rs.
          <input
            value={data?.totalPrice}
            type="number"
            placeholder="Total Value"
            disabled
            onChange={(e) => {
              setArrayofservices([
                ...arrayofservices?.slice(0, index),
                { ...data, value: e.target?.value },
                ...arrayofservices?.slice(index + 1, arrayofservices?.length),
              ]);
            }}
          />
        </div>
      </div>
      <div className="servicesmenudiv">
        <div className="servicesmenuname1 "></div>
      </div>
    </>
  );
}
