import React from "react";
import { useLocation, useNavigate } from "react-router";

export default function PopularCategorycards({
  categoryCards,
  setCategoryid,
  setCatalogueTtile,
  width,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const url = new URLSearchParams(location.search);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        alignItems: "flex-start",
      }}
    >
      {categoryCards?.map((data, index) => {
        return (
          <div
            onClick={() => {
              url.set("category", data?._id);
              navigate(location.pathname + "?" + url.toString());
            }}
          >
            <button
              style={{
                width: width > 700 ? "22vw" : "45vw",
                textAlign: "left",
                margin: "0.5vw 0vw",
                lineHeight: width > 700 ? "1.5vw" : "3.5vw",
              }}
              className={"allcatemenuname"}
            >
              {data?.category}
            </button>
          </div>
        );
      })}
    </div>
  );
}
