import React from "react";
import { useNavigate } from "react-router";
import img1 from "../../../assets/Jobs/Iconly-Light-Delete.svg";
import axios from "axios";
import API_HOST from "../../../env";
import Box from "@mui/material/Box";
import img2 from "../../../assets/Dashboard/Skill center – 2/Iconly-Light-outline-Edit.svg";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  maxHeight: "95vh",
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function Skillpopup1({
  width,
  data,
  index,
  page,
  setAllusers,
  settotalpages,
  setSelectedCategory,
  arrayoffilterselected,
}) {
  const navigate = useNavigate();
  const [open3, setOpen3] = React.useState(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);
  const handledeleteBlog = () => {
    axios
      .post(
        `${API_HOST}/contentManagement/removecontent`,
        {
          contentId: data?.contentId,
        },
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then(() => {
        axios
          .get(
            `${API_HOST}/contentManagement/userContent?search=${setSelectedCategory}&pageNumber=${page}&pageSize=10&category=${JSON.stringify(
              arrayoffilterselected
            )}`,
            {
              headers: {
                Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem("token")),
              },
            }
          )
          .then((res) => {
            setAllusers(res?.data?.success?.data);
            handleClose3();
          });
        handleClose3();
      });
  };

  const myStr = data?.contentName;
  const contentNameRoute = myStr
    .replaceAll(" ", "-")
    .replaceAll("/", "-")
    .replaceAll("?", "-");

  return (
    <div>
      <div
        style={{
          alignItems: "center",
          padding: "0.5vw 1vw",
          margin: width > 700 ? "1vw 0vw" : "2vw 0vw",
          width: width > 700 ? "82vw" : "194vw",
        }}
        className="navoftableblogsdata"
      >
        <div
          onClick={() => {
            navigate(`/blogs/${contentNameRoute}/${data?.contentId}`);
          }}
          style={{ width: width > 700 ? "4vw" : "10vw", cursor: "pointer" }}
        >
          #{(page - 1) * 10 + (index + 1)}
        </div>

        <div
          onClick={() => {
            navigate(`/blogs/${contentNameRoute}/${data?.contentId}`);
          }}
          style={{ width: width > 700 ? "30vw" : "60vw", cursor: "pointer" }}
        >
          {data?.contentName?.slice(0, 65)}
        </div>

        <div
          onClick={() => {
            navigate(`/blogs/${contentNameRoute}/${data?.contentId}`);
          }}
          style={{ width: width > 700 ? "12vw" : "25vw", cursor: "pointer" }}
        >
          {data?.category}
        </div>

        <div
          style={{ width: width > 700 ? "14vw" : "30vw", fontWeight: "400" }}
        >
          {data?.author}
        </div>

        <div
          onClick={() => {
            navigate(`/blogs/${contentNameRoute}/${data?.contentId}`);
          }}
          style={{
            width: width > 700 ? "8vw" : "20vw",
            color:
              data?.status === "unpublish"
                ? "#F39600"
                : data?.status === "publish"
                ? "#2AC96A"
                : "red",
            fontWeight: "500",
          }}
        >
          {data?.status}
        </div>
        <div
          onClick={() => {
            navigate(`/blogs/${contentNameRoute}/${data?.contentId}`);
          }}
          style={{
            width: width > 700 ? "8vw" : "20vw",

            fontWeight: "500",
          }}
        >
          {data?.created_at && (
            <span>
              {new Date(data?.created_at).getDate()}/
              {new Date(data?.created_at).getMonth() + 1}/
              {new Date(data?.created_at).getFullYear()}
            </span>
          )}
        </div>

        <div
          onClick={() => {
            data?.type1
              ? navigate(`/blogs/${contentNameRoute}/${data?.contentId}`)
              : navigate(`/blogs/${contentNameRoute}/${data?.contentId}`);
          }}
          style={{ width: width > 700 ? "4vw" : "15vw", cursor: "pointer" }}
        >
          {data?.views}{" "}
        </div>

        <div style={{ width: width > 700 ? "6vw" : "15vw", display: "flex" }}>
          <img
            onClick={() => {
              navigate(`/dashboard/editBlog/${data?.contentId}`);
            }}
            style={{
              margin: "0 0.5vw",
              width: width > 700 ? "2vw " : "5vw",
              height: width > 700 ? "2vw " : "5vw",
              borderRadius: "50%",
              cursor: "pointer",
              objectFit: "cover",
            }}
            src={img2}
            alt=""
          />
          <img
            onClick={() => {
              handleOpen3();
            }}
            style={{
              margin: "0 0.5vw",
              width: width > 700 ? "2vw " : "5vw",
              height: width > 700 ? "2vw " : "5vw",
              borderRadius: "50%",
              cursor: "pointer",
              objectFit: "cover",
            }}
            src={img1}
            alt=""
          />
        </div>
        <Modal
          open={open3}
          onClose={handleClose3}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="profiletitleandmenunav">
              <div className="profiledetailstitle">Delete Blog </div>
              <div className="profiledetailnavmanu">
                <div>
                  <CloseIcon
                    onClick={handleClose3}
                    style={{ fontSize: "1.5vw", cursor: "pointer" }}
                  />
                </div>
              </div>
            </div>
            <hr style={{ color: "#00000090" }} />

            <div style={{ left: "0vw", width: "100%" }} className="loginfield">
              Are you really want to delete '{data?.contentName}'
            </div>

            <hr style={{ color: "#00000090" }} />
            <div
              style={{ marginTop: "0.31vw" }}
              className="handlemoreaboutskill"
            >
              <div
                style={{
                  background: "white",
                  color: "black",
                  cursor: "pointer",
                }}
                className="handlecirclieaboutsave"
                onClick={handleClose3}
              >
                Cancel
              </div>
              <div
                onClick={() => handledeleteBlog()}
                style={{ cursor: "pointer", background: "red" }}
                className="handlecirclieaboutsave"
              >
                Delete
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
}
