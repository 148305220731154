import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { DeleteOutlineOutlined } from "@mui/icons-material";
import { TextField } from "@mui/material";
import axios from "axios";
import API_HOST from "../../../env";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  maxHeight: "40rem",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  overflowY: "scroll",
  overflowX: "hidden",
  pl: 4,
  pt: 2,
  pr: 4,
  pb: 2,
};
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  maxHeight: "40rem",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  overflowY: "scroll",
  overflowX: "hidden",
  p: 1,
};
const useStyles = makeStyles((theme) => ({
  input: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "0.91vw",
    color: "#263238",
    border: "yellow !important",
  },
}));
export default function Profileexperincedetail({
  company,
  width,
  setcertificated,
  user,
  setWorkexp,
}) {
  const [year1, setyear1] = useState(company?.startDate);
  const classes = useStyles();

  const [city, setcity] = useState(company?.companyCity);
  const [country, setcountry] = useState(company?.companyCountry);

  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [year2, setyear2] = useState(company?.endDate);

  const [degreeset, setDegreeset] = useState(company?.jobTitle);

  const [companyName, setCompanyName] = useState(company?.companyName);

  const [descriptioncallageadd, setdescriptioncallageadd] = useState(
    company?.description
  );

  const [checkedtocompany, setCheckedtocompany] = useState(
    company?.workingCurrently
  );

  const handleeducationdelete = () => {
    const data = {
      companyId: company?.companyId,
    };
    axios
      .post(`${API_HOST}/users/removeCompanyDetails`, data, {
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((response) => {
        if (user) {
          axios
            .get(
              `${API_HOST}/users/viewCompany?pageSize=50&pageNumber=1&user_id=${user?._id}`
            )
            .then((res) => {
              setcertificated(res?.data?.success?.data);
              setWorkexp(res?.data?.success?.data);
            });
        }
        handleClose();
      });
  };

  const handleeditcallage = () => {
    if (checkedtocompany) {
      const formdata = {
        userId: user?.userId,
        companyName: companyName,
        companyCity: city,
        companyCountry: country,
        jobTitle: degreeset,

        startDate: year1,
        workingCurrently: checkedtocompany,
        description: descriptioncallageadd,
        companyId: company?.companyId,
      };
      axios
        .post(`${API_HOST}/users/editCompanyDetails`, formdata, {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((res) => {
          if (user) {
            axios
              .get(
                `${API_HOST}/users/viewCompany?pageSize=50&pageNumber=1&user_id=${user?._id}`
              )
              .then((res) => {
                setcertificated(res?.data?.success?.data);
                setWorkexp(res?.data?.success?.data);
              });
          }
          handleClose1();
        });
    } else {
      const formdata = {
        userId: user?.userId,
        companyName: companyName,
        companyCity: city,
        companyCountry: country,
        jobTitle: degreeset,

        startDate: year1,
        workingCurrently: checkedtocompany,
        description: descriptioncallageadd,

        endDate: year2,
        companyId: company?.companyId,
      };
      axios
        .post(`${API_HOST}/users/editCompanyDetails`, formdata, {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((res) => {
          if (user) {
            axios
              .get(
                `${API_HOST}/users/viewCompany?pageSize=50&pageNumber=1&user_id=${user?._id}`
              )
              .then((res) => {
                setcertificated(res?.data?.success?.data);
                setWorkexp(res?.data?.success?.data);
              });
          }
          handleClose1();
        });
    }
  };

  useEffect(() => {
    setyear1(company?.startDate);
    setyear2(company?.endDate);
    setCompanyName(company?.companyName);
    setcity(company?.companyCity);
    setcountry(company?.companyCountry);
    setdescriptioncallageadd(company?.description);
    setCheckedtocompany(company?.workingCurrently);
    setDegreeset(company?.jobTitle);
  }, [company]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "1vw 2vw",
        }}
      >
        <div>
          <div className="pworkexperincebox">
            <div className="pworkexperinceboxtext">
              <div className="pworkexperincebox-name">
                {company?.jobTitle} | {company?.companyName}
              </div>
              <div
                style={{
                  color: "black",
                  opacity: "0.7",
                  margin: "0",
                  padding: "0",
                }}
                className="pworkexperincebox-name1"
              >
                {company?.startDate} /
                {company?.workingCurrently ? "present" : company?.endDate}
              </div>
              <div
                style={{
                  fontSize: width > 700 ? "0.851vw" : "2.2vw",
                  color: "black",
                  opacity: "0.7",
                  margin: "0",
                  padding: "0",
                }}
                className="profileuserfirstonedata"
              >
                {company?.description}
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", width: width > 700 ? "5vw" : "10vw" }}>
          <EditIcon
            onClick={handleOpen1}
            style={{
              fontSize: width > 700 ? "1.5vw" : "4vw",
              color: "gray",
              margin: "0 0.61vw",
              cursor: "pointer",
            }}
          />
          <DeleteOutlineOutlined
            onClick={handleOpen}
            style={{
              cursor: "pointer",
              fontSize: width > 700 ? "1.5vw" : "4vw",
              color: "gray",
              margin: "0 0.61vw",
            }}
          />
        </div>
        <Modal
          open={open1}
          onClose={handleClose1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={width > 700 ? style : style1}>
            <div className="profiletitleandmenunav">
              <div className="profiledetailstitle">Edit Employment</div>
              <div className="profiledetailnavmanu">
                <div>
                  <CloseIcon
                    onClick={handleClose1}
                    style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
                  />
                </div>
              </div>
            </div>
            <div style={{ left: "0vw", width: "100%" }} className="loginfield">
              <TextField
                id="outlined-basic"
                label="Company"
                variant="outlined"
                value={companyName}
                style={{ width: "100%" }}
                InputLabelProps={{
                  style: {
                    fontSize: width > 700 ? "1vw" : "3vw",
                    fontFamily: "Poppins",
                    fontStyle: "500",
                    fontWeight: "500",
                    color: "black",
                  },
                }}
                inputProps={{ className: classes.input }}
                onChange={(e) => {
                  setCompanyName(e.target.value);
                }}
              />
            </div>
            <div className="jobpodtedfieldtitile">Location</div>
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  left: "0vw",
                  width: "50%",
                  margin: "0.3vw 0",
                }}
                className="loginfield"
              >
                <TextField
                  id="outlined-basic"
                  label="City"
                  variant="outlined"
                  value={city}
                  style={{ width: "95%" }}
                  InputLabelProps={{
                    style: {
                      fontSize: width > 700 ? "1vw" : "3vw",
                      fontFamily: "Poppins",
                      fontStyle: "500",
                      fontWeight: "500",
                      color: "black",
                    },
                  }}
                  onChange={(e) => setcity(e.target.value)}
                  inputProps={{ className: classes.input }}
                />
              </div>
              <div
                style={{
                  left: "1vw",
                  width: "50%",
                  margin: "0.3vw 0",
                  position: "relative",
                }}
                className="loginfield"
              >
                <TextField
                  id="outlined-basic"
                  label="Country"
                  variant="outlined"
                  value={country}
                  style={{
                    width: "95%",
                  }}
                  InputLabelProps={{
                    style: {
                      fontSize: width > 700 ? "1vw" : "3vw",
                      fontFamily: "Poppins",
                      fontStyle: "500",
                      fontWeight: "500",
                      color: "black",
                    },
                  }}
                  onChange={(e) => setcountry(e.target.value)}
                  inputProps={{ className: classes.input }}
                />
              </div>
            </div>
            <div style={{ left: "0vw", width: "100%" }} className="loginfield">
              <TextField
                id="outlined-basic"
                label="Title"
                variant="outlined"
                value={degreeset}
                style={{ width: "100%" }}
                InputLabelProps={{
                  style: {
                    fontSize: width > 700 ? "1vw" : "3vw",
                    fontFamily: "Poppins",
                    fontStyle: "500",
                    fontWeight: "500",
                    color: "black",
                  },
                }}
                inputProps={{ className: classes.input }}
                onChange={(e) => {
                  setDegreeset(e.target.value);
                }}
              />
            </div>
            <label htmlFor="checkbox">
              <input
                type="checkbox"
                onChange={() => {
                  setCheckedtocompany(!checkedtocompany);
                }}
                name=""
                id="checkbox"
                checked={checkedtocompany ? true : false}
              />

              <div
                className=""
                style={{
                  fontSize: width > 700 ? "1vw" : "3vw",
                  fontFamily: "Poppins",
                  fontStyle: "500",
                  fontWeight: "500",
                  color: "#000000",
                  display: "inline",
                  margin: "0 1vw",
                }}
              >
                Currently working here
              </div>
            </label>
            <div className="jobpodtedfieldtitile">From</div>
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  left: "0vw",
                  width: "50%",
                  margin: "0.3vw 0",
                }}
                className="loginfield"
                // onClick={handleClickx}
              >
                <div className="" style={{ width: "100%" }}>
                  <div style={{ width: "100%" }}>
                    <div className="jobpostfieldinputbox">
                      <input
                        style={{ width: "100%" }}
                        type="date"
                        className="input-homejobformdate"
                        name=""
                        id=""
                        value={year1}
                        // min={disablePastDate()}
                        max={"2025-12-31"}
                        maxlength="4"
                        onChange={(e) => {
                          setyear1(e.target.value);
                        }}
                        // onChange={(e) => {
                        //   setDatestart(e.target.value);
                        // }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!checkedtocompany ? (
              <>
                {" "}
                <div className="jobpodtedfieldtitile">To</div>
                <div style={{ display: "flex", width: "100%" }}>
                  <div
                    style={{
                      left: "0vw",
                      width: "50%",
                      margin: "0.3vw 0",
                    }}
                    className="loginfield"
                    // onClick={handleClickx2}
                  >
                    <div className="" style={{ width: "100%" }}>
                      <div style={{ width: "100%" }}>
                        <div className="jobpostfieldinputbox">
                          <input
                            style={{ width: "100%" }}
                            type="date"
                            className="input-homejobformdate"
                            name=""
                            id=""
                            value={year2}
                            // min={disablePastDate()}
                            max={"2025-12-31"}
                            maxlength="4"
                            onChange={(e) => {
                              setyear2(e.target.value);
                            }}
                            // onChange={(e) => {
                            //   setDatestart(e.target.value);
                            // }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            <div style={{ left: "0vw", width: "100%" }} className="loginfield">
              <TextField
                id="outlined-basic"
                label="Description (Optional)"
                variant="outlined"
                value={descriptioncallageadd}
                multiline
                rows="4"
                style={{ width: "100%" }}
                InputLabelProps={{
                  style: {
                    fontSize: width > 700 ? "1vw" : "3vw",
                    fontFamily: "Poppins",
                    fontStyle: "500",
                    fontWeight: "500",
                    color: "black",
                  },
                }}
                inputProps={{ className: classes.input }}
                onChange={(e) => {
                  console.log(e.target.value);
                  setdescriptioncallageadd(e.target.value);
                }}
              />
            </div>
            {/* <hr style={{ color: "#000000" }} /> */}
            <div
              style={{ marginTop: "0.31vw" }}
              className="handlemoreaboutskill"
            >
              <div
                style={{
                  background: "white",
                  color: "black",
                  cursor: "pointer",
                }}
                className="handlecirclieaboutsave"
                onClick={handleClose1}
              >
                Cancel
              </div>
              <div
                style={{ cursor: "pointer" }}
                className="handlecirclieaboutsave"
                onClick={handleeditcallage}
              >
                SAVE
              </div>
            </div>
          </Box>
        </Modal>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={width > 700 ? style : style1}>
            <div className="profiletitleandmenunav">
              <div className="profiledetailstitle"> Delete employment</div>
              <div className="profiledetailnavmanu">
                <div>
                  <CloseIcon
                    onClick={handleClose}
                    style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
                  />
                </div>
              </div>
            </div>
            <hr style={{ color: "#000000" }} />
            <div
              style={{
                color: "gray",
                fontSize: width > 700 ? "1vw" : "2.7vw",
                fontWeight: "300",
              }}
              className="profiledetailstitle"
            >
              The action will delete " {company?.companyName}" From all of your
              profiles.{" "}
            </div>
            <div
              style={{
                color: "gray",
                fontSize: width > 700 ? "0.91vw" : "2.7vw",
                fontWeight: "400",
                marginBottom: "5vw",
              }}
              className="profiledetailstitle"
            >
              Are you sure you want to delete this employment?
            </div>
            <hr style={{ color: "#000000" }} />
            <div
              style={{ marginTop: "0.31vw" }}
              className="handlemoreaboutskill"
            >
              <div
                style={{
                  background: "white",
                  color: "black",
                  cursor: "pointer",
                }}
                className="handlecirclieaboutsave"
                onClick={handleClose}
              >
                Cancel
              </div>
              <div
                onClick={handleeducationdelete}
                style={{ cursor: "pointer", background: "red" }}
                className="handlecirclieaboutsave"
              >
                Delete
              </div>
            </div>
          </Box>
        </Modal>
      </div>
      <hr
        style={{
          width: "90%",
          margin: "auto",
          marginBottom: "0.5vw",
          color: "#000000",
        }}
      />
    </>
  );
}
