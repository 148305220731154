import React, { useEffect, useState } from "react";

import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useNavigate } from "react-router";
import axios from "axios";
import API_HOST from "../../env";

import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import imgfilter from "../../assets/walletimage/Iconly-Light-Color-Filter.svg";
import Modal from "@mui/material/Modal";
import Listofjobbox from "./Listofjobbox";
import { TextField } from "@mui/material";
import { makeStyles } from "@material-ui/core";

import { Metadata } from "../Metadata/Metadata";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  input: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "0.91vw",

    color: "#263238",
    border: "yellow !important",
  },
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
  p: 4,
};
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
  p: 1,
};

export default function ExploreProposals({ width }) {
  const [setSelectedCategory, setSetSelectedCategory] = useState("All");
  const [page, setPage] = useState(1);
  const [SearchBlogchat, setSearchBlogchat] = useState("");

  const [arrayoflongdegree, setArrayoflongdegree] = useState();

  useEffect(() => {
    axios
      .get(`${API_HOST}/theCategory/viewCategory?pageSize=50&pageNumber=1`)
      .then((res) => {
        setArrayoflongdegree(res?.data?.success?.data);
      });
  }, []);

  const [totalpages, settotalpages] = useState(1);

  const classes = useStyles();

  const [Cateid, setCateid] = useState("");

  const [studyset, setstudyset] = useState("");
  const [studyset1, setstudyset1] = useState("");

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [allusers, setAllusers] = useState([]);
  const [prevsort, setPrevsort] = useState(false);
  const [sort, setSort] = useState(false);
  const [recall, setRecall] = useState(false);
  const { user } = useSelector((state) => state.user);
  useEffect(() => {
    axios
      .get(
        `${API_HOST}/purposals/viewForbiding?pageSize=9&pageNumber=${page}&search=${SearchBlogchat}&${
          studyset ? "location=" : ""
        }${studyset}&${Cateid ? "category=" : ""}${Cateid}${
          sort ? "&costPrice=true" : ""
        }&userName=${user?.userName}
        `
      )
      .then((res) => {
        setAllusers(res.data?.success?.data);
        if (res.data?.success?.data?.length === 9) {
          settotalpages(page + 1);
        } else {
          settotalpages(page);
        }
      });
  }, [page, setSelectedCategory, recall, user,SearchBlogchat]);
  return (
    <div style={{ paddingTop: "0vw" }} className="blogbannercontainer">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "fit-content",
          alignItems: "center",
          marginLeft: width > 700 ? "15vw" : "0vw",
        }}
      >
        <div className="searchblog">
          <div>
            <SearchOutlinedIcon
              style={{
                fontSize: width > 700 ? "2vw" : "5vw",
                margin: "0vw 1vw",
              }}
            />
          </div>
          <div style={{ width: width > 700 ? "100%" : "70vw" }}>
            <input
              type="text"
              placeholder="Search Proposals here ...."
              value={SearchBlogchat}
              onChange={(e) => {
                setSearchBlogchat(e.target.value);
              }}
            />
          </div>
        </div>
        <div>
          <div
            style={{
              flexWrap: "wrap",
              marginTop: width > 700 ? "0vw" : "0.5vw",
              marginBottom: "0vw",
              paddingBottom: "0vw",
              paddingTop: "0vw",
            }}
            className="filterboxflex"
          >
            <div
              onClick={() => {
                handleOpen();
              }}
              style={{
                height: width > 700 ? "4vw" : "8.5vw",
                marginTop: "0vw",
              }}
              className="filtericonbox"
            >
              <img src={imgfilter} alt="" /> {width > 700 && "Filter"}
            </div>

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={width > 700 ? style : style1}>
                <div
                  style={{
                    maxHeight: "80vh",
                    overflow: "scroll",
                    overflowX: "hidden",
                  }}
                >
                  <div className="profiletitleandmenunav">
                    <div className="profiledetailstitle">Add Filters</div>
                    <div className="profiledetailnavmanu">
                      <CloseIcon
                        onClick={handleClose}
                        style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
                      />
                    </div>
                  </div>
                  <hr style={{ color: "#000000" }} />

                  <div className="jobpodtedfieldtitile"> Enter Location </div>

                  <div>
                    <div
                      style={{ left: "0vw", width: "98%", marginLeft: "0%" }}
                      className="loginfield"
                    >
                      <TextField
                        id="outlined-basic"
                        label="Location"
                        variant="outlined"
                        value={studyset1}
                        style={{ width: "100%" }}
                        InputLabelProps={{
                          style: {
                            fontSize: width > 700 ? "1vw" : "3vw",
                            fontFamily: "Poppins",
                            fontStyle: "500",
                            fontWeight: "500",
                            color: "black",
                          },
                        }}
                        inputProps={{ className: classes.input }}
                        onChange={(e) => {
                          setstudyset1(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="jobpodtedfieldtitile">Sort by</div>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      onClick={() => setPrevsort(true)}
                      type="checkbox"
                      name=""
                      id=""
                      style={{ margin: "0 1vw" }}
                      checked={!prevsort ? false : true}
                    />
                    <span className="jobpodtedfieldtitile">Budget</span>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      onClick={() => setPrevsort(false)}
                      type="checkbox"
                      name=""
                      id=""
                      style={{ margin: "0 1vw" }}
                      checked={prevsort ? false : true}
                    />
                    <span className="jobpodtedfieldtitile">
                      Date ( Recent )
                    </span>
                  </div>

                  <div
                    style={{ marginTop: "0.31vw" }}
                    className="handlemoreaboutskill"
                  >
                    <div
                      style={{
                        background: "white",
                        color: "black",
                        cursor: "pointer",
                      }}
                      className="handlecirclieaboutsave"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Cancel
                    </div>

                    <div
                      style={{
                        background: "white",
                        color: "black",
                        cursor: "pointer",
                      }}
                      className="handlecirclieaboutsave"
                      onClick={() => {
                        setstudyset("");

                        setstudyset1("");

                        setPrevsort(false);
                        setSort(false);
                      }}
                    >
                      Reset
                    </div>

                    <div
                      style={{ cursor: "pointer" }}
                      className="handlecirclieaboutsave"
                      onClick={() => {
                        setstudyset(studyset1);

                        setSort(prevsort);
                        handleClose();
                        setRecall(!recall);
                      }}
                    >
                      Submit
                    </div>
                  </div>
                </div>
              </Box>
            </Modal>
          </div>
        </div>
      </div>

      <div style={{ margin: "1vw 0vw" }} className="filterlineblog">
        <div className="tagfiltertitile">Categories :</div>
        <div style={{ overflowX: "scroll", width:width>700?  "85vw":"70vw" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "flex-start",
              width: width > 700 ? "600vw" : "1400vw",
            }}
          >
            <div
              style={{
                background: setSelectedCategory === "All" ? "#0052CC" : "",
                color: setSelectedCategory === "All" ? "white" : "",
              }}
              className="tagblogfilter"
              onClick={() => {
                setSetSelectedCategory("All");

                setCateid();
              }}
            >
              All
            </div>
            {arrayoflongdegree?.length > 0 &&
              arrayoflongdegree?.map((data) => {
                return (
                  <div
                    className="tagblogfilter"
                    style={{
                      background:
                        setSelectedCategory === data?.category ? "#0052CC" : "",
                      color:
                        setSelectedCategory === data?.category ? "white" : "",

                      height: width > 700 ? "2.6vw" : "7vw",
                      textAlign: "center",
                      whiteSpace:"nowrap"
                    }}
                    onClick={() => {
                      setSetSelectedCategory(data?.category);
                      setSearchBlogchat("");
                      setCateid(data?._id);
                    }}
                  >
                    {data?.category}
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      <div className="flexoffilterboxes">
        {allusers?.length > 0 &&
          allusers?.map((item, index) => {
            return <Listofjobbox width={width} data={item} />;
          })}
      </div>
      {totalpages !== 1 ? (
        <div
          style={{ width: width > 700 ? "25vw" : "90vw" }}
          className="paginationbox"
        >
          <div>
            <ArrowBackIosIcon
              style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
              onClick={() => {
                if (page - 1 > 0) {
                  setPage(page - 1);
                }
              }}
            />
          </div>

          <div
            hidden={page - 4 > 0 ? false : true}
            onClick={() => setPage(page - 4)}
          >
            {page - 4}
          </div>
          <div
            hidden={page - 3 > 0 ? false : true}
            onClick={() => setPage(page - 3)}
          >
            {page - 3}
          </div>
          <div
            hidden={page - 2 > 0 ? false : true}
            onClick={() => setPage(page - 2)}
          >
            {page - 2}
          </div>
          <div
            hidden={page - 1 > 0 ? false : true}
            onClick={() => setPage(page - 1)}
          >
            {page - 1}
          </div>
          <div style={{ color: "#2A6599" }}>{page}</div>
          <div
            hidden={page + 1 > totalpages ? true : false}
            onClick={() => setPage(page + 1)}
          >
            {page + 1}
          </div>

          <div>
            <ArrowForwardIosIcon
              style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
              onClick={() => {
                if (page + 1 <= totalpages) {
                  setPage(page + 1);
                }
              }}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
