import React, { useEffect } from "react";
import DashbaordNav from "../../components/DashBaord/DashbaordNav";
import { Outlet } from "react-router";
import BrowseWorkSiadebar from "../../components/DashBaord/BrowseWorkSiadebar";

export default function Dashbaord({ width, notifications, setNotifications }) {
  useEffect(() => {
    window.scrollTo(0, 0, { behavior: "smooth" });
  }, []);

  return (
    <div style={{ height: "100vh" }}>
      <DashbaordNav
        width={width}
        notifications={notifications}
        setNotifications={setNotifications}
      />
      <div>
        <div
          style={{
            display: "flex",
            height: width > 700 ? "calc(100vh - 4vw)" : "calc(100vh - 14vw)",
          }}
        >
          {width > 700 && <BrowseWorkSiadebar width={width} />}
          <div
            style={{
              background: "rgb(251, 251, 251)",
              width: width > 700 ? "84vw" : "100vw",
              height: width > 700 ? "calc(100vh - 4vw)" : "calc(100vh - 14vw)",
              overflow: "hidden",
              overflowX: "hidden",
            }}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
