import React from "react";

export default function CommuinityBanner2() {
  return (
    <div className="blogBanner">
    <div className="bluebackgroundblog">
      <div style={{lineHeight:"3vw"}} className="homeblueboxtitle">Join a community with us <br /> to help you grow as a Team</div>
      <div style={{ fontSize: "1vw" }} className="homeblueboxsubtitle">
      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever.
      </div>

      <button style={{ border: "none" ,width:"15vw"}} className="homeworktop-button">
        Join The Community
      </button>
    </div>
  </div>
  );
}
