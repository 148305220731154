import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyC8eZYMok2IAL8P6OuvKFYXnGQxK-5GZ9w",
    authDomain: "tool44-prod.firebaseapp.com",
    projectId: "tool44-prod",
    storageBucket: "tool44-prod.appspot.com",
    messagingSenderId: "934837924126",
    appId: "1:934837924126:web:08c7a75a4d9d9240e2990f",
    measurementId: "G-MM2QJP25TZ"
  };

const app = initializeApp(firebaseConfig);
export const authentication = getAuth(app);
