import axios from "axios";
import React, { useEffect, useState } from "react";

import API_HOST from "../../../env";
import img from "../../../assets/Dashboard/Skill center – 2/Iconly-Light-outline-Edit.svg";
import img1 from "../../../assets/Dashboard/Skill center – 2/Iconly-Light-outline-Delete.svg";

import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};
export default function Companydetails({
  width,
  data,
  showcompanydetails,
  setShowcompanydetails,
  setRecall,
  setShoweditcompany,
  recall,
}) {
  const [Create, setCreate] = useState(false);
  const [showadd, setShowadd] = useState(false);
  const [showedit, setShowedit] = useState(false);

  const [companydetail, setCompanydetail] = useState();

  console.log('showcompanydetails',showcompanydetails);

  useEffect(() => {
    axios
      .get(
        `${API_HOST}/companyDetails/viewExistedbyid?companyId=${showcompanydetails?._id}`,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((res) => {
        setCompanydetail(res?.data?.success?.data);
        if (res?.data?.success?.data) {
          setCreate(true);
        }
      });
  }, []);

  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const handleDelete = () => {
    axios
      .post(
        `${API_HOST}/companyDetails/removeExisted`,
        { companyId: showcompanydetails?.companyId },
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((res) => {
        setCompanydetail();
        handleClose1();
        setCreate(false);
        setRecall(!recall);
        setShowcompanydetails(false);
      });
  };

  return (
    <div>
      {!showadd && !showedit && (
        <>
          <div
            style={{
              color: "black",
              display: "flex",
              justifyContent: "space-between",
              width: width>700? "96%":"90%",
              margin: "auto",
            }}
            className="profileworkhistruytoggleervalue"
          >
            <div>Company Details</div>
            {Create ? (
              <div style={{ display: "flex" }}>
                <div
                  onClick={() => {
                      console.log('ok');
                    setShoweditcompany(showcompanydetails)
                    setShowcompanydetails(false);
                  }}
                  style={{ cursor: "pointer", color: "#0052CC" }}
                >
                  <span>
                    <img
                      style={{
                        width: width > 700 ? "1.82vw" : "4vw",
                        objectFit: "contain",
                        margin: "0 1vw",
                        cursor: "pointer",
                      }}
                      src={img}
                      alt=""
                    />
                  </span>
                </div>
                <span
                  onClick={() => {
                    handleOpen1(true);
                  }}
                >
                  <img
                    style={{
                      width: width > 700 ? "1.82vw" : "4vw",
                      objectFit: "contain",
                      margin: "0 1vw",
                      cursor: "pointer",
                    }}
                    src={img1}
                    alt=""
                  />
                </span>
                <span
                  onClick={() => {
                    setShowcompanydetails(false);
                  }}
                >
                  <CloseIcon
                    style={{
                      fontSize: width > 700 ? "1.8vw" : "4vw",
                      marginLeft: "0.61vw",
                      cursor: "pointer",
                    }}
                  />
                </span>
              </div>
            ) : (
              <div
                onClick={() => {
                  setShowedit(false);
                  setShowadd(true);
                }}
                style={{ cursor: "pointer", color: "#0052CC" }}
              >
                Add Company Details
              </div>
            )}
          </div>

          <div
            style={{
              width: width > 700 ? "80.5vw" : "90vw",
              margin: width > 700 ? "1vw 1vw" : "4vw",
              height: "fit-content",
            }}
            className="bankcardContainer"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                flexWrap: width < 700 && "wrap",
              }}
            >
              <div
                style={{ justifyContent: "flex-start", width: "58vw" }}
                className="flexofbankdetails"
              >
                {companydetail?.logo && (
                  <div
                    style={{ fontWeight: "400", width: "9vw" }}
                    className="titleboxflexofbank"
                  >
                    <img
                      style={{
                        width: "8vw",
                        height: "8vw",
                        objectFit: "contain",
                      }}
                      src={companydetail?.logo}
                      alt=""
                    />
                  </div>
                )}
                <div style={{ width: "fit-content" }}>
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: width > 700 ? "1.6vw" : "3.5vw",
                      lineHeight: width > 700 ? "2vw" : "4vw",
                    }}
                    className="titleboxflexofbank"
                  >
                    {companydetail?.companyName}
                  </div>
                  <div
                    style={{ fontWeight: "400" }}
                    className="titleboxflexofbank"
                  >
                    {companydetail?.companyTitle}
                  </div>
                  <div
                    style={{ fontWeight: "400" }}
                    className="titleboxflexofbank"
                  >
                    {companydetail?.companyWebsiteLink}
                  </div>
                </div>
              </div>
              <div style={{ width: width > 700 ? "20" : "90vw" }}>
                <div
                  style={{ fontWeight: "400", display: "block" }}
                  className="titleboxflexofbank"
                >
                  <b>Mobile: </b> {companydetail?.mobile}
                </div>
                <div
                  style={{ fontWeight: "400", display: "block" }}
                  className="titleboxflexofbank"
                >
                  <b>Email: </b> {companydetail?.emailId}
                </div>
                <div
                  style={{ fontWeight: "400", display: "block" }}
                  className="titleboxflexofbank"
                >
                  <b>GSTIN: </b>
                  {companydetail?.gstNo}
                </div>
                <div
                  style={{ fontWeight: "400", display: "block" }}
                  className="titleboxflexofbank"
                >
                  <b>Address: </b> {companydetail?.adress}
                </div>
              </div>
            </div>
            <div style={{ width: "100%" }} className="flexofbankdetails">
              <div style={{ width: "50%" }} className="flexofbankdetails">
                <div className="titleboxflexofbank">State</div>
                <div
                  style={{ fontWeight: "400" }}
                  className="titleboxflexofbank"
                >
                  {companydetail?.state}
                </div>
              </div>
              <div style={{ width: "50%" }} className="flexofbankdetails">
                <div className="titleboxflexofbank">Pincode</div>
                <div
                  style={{ fontWeight: "400" }}
                  className="titleboxflexofbank"
                >
                  {companydetail?.pinCode}
                </div>
              </div>
            </div>
            <div style={{ width: "100%" }} className="flexofbankdetails">
              <div style={{ width: "50%" }} className="flexofbankdetails">
                <div className="titleboxflexofbank">Invoice Serial Name </div>
                <div
                  style={{ fontWeight: "400" }}
                  className="titleboxflexofbank"
                >
                  {companydetail?.invoicePrefix}
                </div>
              </div>
              <div style={{ width: "50%" }} className="flexofbankdetails">
                <div className="titleboxflexofbank">Invoicing Year </div>
                <div
                  style={{ fontWeight: "400" }}
                  className="titleboxflexofbank"
                >
                  {companydetail?.invoiceTimePeriod}
                </div>
              </div>
            </div>
            <div style={{ width: "100%" }} className="flexofbankdetails">
              <div style={{ width: "50%" }} className="flexofbankdetails">
                <div className="titleboxflexofbank">Invoice Start No</div>
                <div
                  style={{ fontWeight: "400" }}
                  className="titleboxflexofbank"
                >
                  {companydetail?.invoiceSeries}
                </div>
              </div>
              <div style={{ width: "50%" }} className="flexofbankdetails">
                <div className="titleboxflexofbank">Invoice Genrate no</div>
                <div
                  style={{ fontWeight: "400" }}
                  className="titleboxflexofbank"
                >
                  {companydetail?.nextInvoice}
                </div>
              </div>
            </div>
            <div style={{ width: "100%" }} className="flexofbankdetails">
              <div style={{ width: "50%" }} className="flexofbankdetails">
                <div className="titleboxflexofbank">Bank Name</div>
                <div
                  style={{ fontWeight: "400" }}
                  className="titleboxflexofbank"
                >
                  {companydetail?.bankName}
                </div>
              </div>
              <div style={{ width: "50%" }} className="flexofbankdetails">
                <div className="titleboxflexofbank">Account Holder Name</div>
                <div
                  style={{ fontWeight: "400" }}
                  className="titleboxflexofbank"
                >
                  {companydetail?.accountHolderName}
                </div>
              </div>
            </div>
            <div style={{ width: "100%" }} className="flexofbankdetails">
              <div style={{ width: "50%" }} className="flexofbankdetails">
                <div className="titleboxflexofbank">Invoice Account No</div>
                <div
                  style={{ fontWeight: "400" }}
                  className="titleboxflexofbank"
                >
                  {companydetail?.accNo}
                </div>
              </div>
              <div style={{ width: "50%" }} className="flexofbankdetails">
                <div className="titleboxflexofbank">IFSC Code</div>
                <div
                  style={{ fontWeight: "400" }}
                  className="titleboxflexofbank"
                >
                  {companydetail?.ifscCode}
                </div>
              </div>
            </div>
            <div style={{ width: "100%" }} className="flexofbankdetails">
              <div style={{ width: "50%" }} className="flexofbankdetails">
                <div className="titleboxflexofbank">Branch</div>
                <div
                  style={{ fontWeight: "400" }}
                  className="titleboxflexofbank"
                >
                  {companydetail?.branch}
                </div>
              </div>
              <div style={{ width: "50%" }} className="flexofbankdetails">
                <div className="titleboxflexofbank">Payment Link / UPI</div>
                <div
                  style={{ fontWeight: "400" }}
                  className="titleboxflexofbank"
                >
                  {companydetail?.paymentLink}
                </div>
              </div>
            </div>

            <div style={{ width: "100%" }} className="flexofbankdetails">
              <div style={{ width: "50%" }} className="flexofbankdetails">
                <div className="titleboxflexofbank">Company QR</div>
                <div
                  style={{ fontWeight: "400" }}
                  className="titleboxflexofbank"
                >
                  {companydetail?.qr && (
                    <img
                      src={companydetail?.qr}
                      style={{ width: "9vw", objectFit: "contain" }}
                      alt=""
                    />
                  )}
                </div>
              </div>
              <div style={{ width: "50%" }} className="flexofbankdetails">
                <div className="titleboxflexofbank">Signature / Stamp</div>
                <div
                  style={{ fontWeight: "400" }}
                  className="titleboxflexofbank"
                >
                  {companydetail?.signAndStamp && (
                    <img
                      src={companydetail?.signAndStamp}
                      style={{ width: "9vw", objectFit: "contain" }}
                      alt=""
                    />
                  )}
                </div>
              </div>
            </div>

            <div style={{ width: "100%" }} className="flexofbankdetails">
              <div style={{ width: "50%" }} className="flexofbankdetails">
                <div className="titleboxflexofbank">Payment QR</div>
                <div
                  style={{ fontWeight: "400" }}
                  className="titleboxflexofbank"
                >
                  {companydetail?.payment && (
                    <img
                      src={companydetail?.payment}
                      style={{ width: "9vw", objectFit: "contain" }}
                      alt=""
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    

      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={width > 700 ? style : style1}>
          <div className="profiletitleandmenunav">
            <div className="profiledetailstitle"> Delete Company Detail</div>
            <div className="profiledetailnavmanu">
              <div>
                <CloseIcon
                  onClick={handleClose1}
                  style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
                />
              </div>
            </div>
          </div>
          <hr style={{ color: "#000000" }} />
          <div
            style={{
              color: "gray",
              fontSize: width > 700 ? "1vw" : "3vw",
              fontWeight: "300",
            }}
            className="profiledetailstitle"
          >
            The action will delete "{data?.companyName} " From all of your
            Company data.{" "}
          </div>
          <div
            style={{
              color: "gray",
              fontSize: width > 700 ? "1vw" : "3vw",
              fontWeight: "400",
              marginBottom: "5vw",
            }}
            className="profiledetailstitle"
          >
            Are you sure you want to delete this Company data?
          </div>
          <hr style={{ color: "#000000" }} />
          <div style={{ marginTop: "0.31vw" }} className="handlemoreaboutskill">
            <div
              style={{
                background: "white",
                color: "black",
                cursor: "pointer",
              }}
              className="handlecirclieaboutsave"
              onClick={handleClose1}
            >
              Cancel
            </div>
            <div
              style={{ cursor: "pointer", background: "red" }}
              className="handlecirclieaboutsave"
              onClick={() => {
                handleDelete();
              }}
            >
              Delete
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
