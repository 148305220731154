import React, { useEffect, useState } from "react";
import "./Wallet.css";
import img1 from "../../../assets/walletimage/atm.png";
import img4 from "../../../assets/walletimage/upload.png";
import img5 from "../../../assets/walletimage/wallet copy.png";
import img11 from "../../../assets/walletimage/Amazon_Pay-Logo.wine.svg";
import img12 from "../../../assets/walletimage/Flipkart-Logo.wine.svg";
import img13 from "../../../assets/walletimage/Google_Pay-Logo.wine.svg";
import img14 from "../../../assets/walletimage/PayPal-Logo.wine.svg";
import img15 from "../../../assets/walletimage/Paytm-Logo.wine.svg";
import coinSymbol from '../../../assets/walletimage/coin_symbol.png'
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import imgdot from "../../../assets/walletimage/Ellipse 1400.svg";
import imgdot1 from "../../../assets/walletimage/Ellipse 1402.svg";
import { Metadata } from "../../Metadata/Metadata";
import Catalogfaqquestion from "../../Pricing/Catalogfaqquestion";
import { Backdrop, CircularProgress, TextField } from "@mui/material";
import axios from "axios";
import API_HOST from "../../../env";
import Skillpopupcopy from "./Skillpopupcopy";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  select: {
    height: "2.5vw",
    width: "100%",
    marginTop: "0.2vw",
    padding: "1vw",
    marginLeft: "0vw",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "1vw",
    lineHeight: "120%",
    color: "#FFFFFF",
  },
  select2: {
    height: "1vw",
    width: "100%",
    marginTop: "0.1vw",
    padding: "0.9vw 0.5vw",
    marginLeft: "0vw",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "0.51vw",
    lineHeight: "100%",
    color: "#FFFFFF",
  },
  select3: {
    height: "1vw",
    width: "100%",
    marginTop: "0.1vw",
    padding: "0vw 0vw",
    marginLeft: "0vw",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "0.51vw",
    lineHeight: "100%",
    color: "#FFFFFF",
    position: "relative",
    top: "0.81vw",
    left: "0.2vw",
  },
  icon: {
    fill: "white",
  },
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  height: "fit-content",
  overflow: "scroll",
  padding: "1vw",
};
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  height: "fit-content",
  overflow: "scroll",
  padding: "1vw",
};

const style2 = {
  position: "absolute",
  maxHieght: "90vh",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "94vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  height: "fit-content",
  padding: "2vw",
};
export default function Wallet({ width }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const classes = useStyles();
  const [open121, setOpen121] = React.useState(false);
  const handleClose121 = () => {
    setOpen121(false);
  };
  const handleToggle121 = () => {
    setOpen121(!open121);
  };

  const handlecreateonelink = () => {
    if (addmoney < 100) {
      setMoneyerror("Amount  should be more than Rs 100");
      return 0;
    }
    handleToggle121();
    axios
      ?.post(
        `${API_HOST}/wallet/walletPaymentLink`,
        {amount:addmoney},
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((res) => {
        console.log(res);
        window.location.replace(res?.data?.success?.data);
        handleClose121(); 
      })
      .catch(() => {
        handleClose121();
      });
  };
  const [page, setPage] = useState(1);

  const [checkwallet, setCheckwallet] = useState("");
  const [walletdata, setWalletdata] = useState();

  useEffect(() => {
    handleToggle121();
    axios
      .post(
        `${API_HOST}/wallet/viewWallet`,
        {},
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((res) => {
        setWalletdata(res?.data?.success?.data);
        handleClose121();
        setChecktransaction(!checktransaction);
      })
      .catch(() => {
        handleClose121();
      });
  }, [checkwallet]);

  const [checktransaction, setChecktransaction] = useState(false);
  const [transactiondata, setTransactiondata] = useState();

  useEffect(() => {
    handleToggle121();
    axios
      .post(
        `${API_HOST}/wallet/viewTransactions`,
        { pageSize: 10, pageNumber: page },
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((res) => {
        setTransactiondata(res?.data?.success?.data);
        handleClose121();
      })
      .catch(() => {
        handleClose121();
      });
  }, [checktransaction, page]);

  const [withdrawmoney, setWithdrawmoney] = useState(100);
  const [addmoney, setaddmoney] = useState(100);

  const [upiid, setUpiid] = useState("");
  const [moneyerror, setMoneyerror] = useState("");
  const [upiiderror, setUpiiderror] = useState("");

  const handleAddwithdraw = () => {
    if (withdrawmoney < 100) {
      setMoneyerror("Withdraw Money should be more than Rs 100");
      return 0;
    } else if (walletdata?.balance?.$numberDecimal < 100) {
      setMoneyerror("Wallet Amount  should be more than Rs 100");
      return 0;
    } else if (!upiid) {
      setUpiiderror("Please enter correct upi id");
      return 0;
    }
    axios
      .post(
        `${API_HOST}/withdrawl/genrateRequest`,
        {
          upi: upiid,
          checkOut_balance: withdrawmoney,
        },
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((res) => {
        handleClose();
        setMoneyerror("");
        setUpiiderror("");
        setWithdrawmoney(100);
        setUpiid("");
        setChecktransaction(!checktransaction);
      })
      .catch((err) => {
        setUpiiderror(err?.response?.data?.fails?.message);
      });
  };

  return (
    <div
      style={{
        height: width > 700 ? 'calc( 100vh - 4vw)' : 'calc( 100vh - 14vw)',
        paddingTop: '0vw',
        overflowX: 'hidden',
      }}
      className='ScrollTable  BrowseWorkMain-cntainer'
    >
      <Metadata
        title={`Wallet | Manage and Grow Your Business While Saving Costs`}
        description={
          'Connecting Businesses and Freelancers with Essential Business Tools in One Location'
        }
        keyword={
          'www.tool44.com,tool44,44tool,tool.com,Freelance jobs, blog ,aboutustool44, Freelancers,tool 44, 44tool ,44 tool,Freelance services,Remote work,Online jobs,Work from home,Freelance marketplace,Freelance platform,Freelance opportunities,Freelance gigs,Hire freelancers,Find freelancers,Freelance projects,Freelance work,Freelance community,Freelance professionals,Freelance talent,Freelance collaboration,Freelance job search,Freelance career'
        }
      ></Metadata>

      <div
        style={{ marginTop: '1vw', padding: '0.5vw' }}
        className='profiledetailstitle'
      >
        Digital Wallet
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          padding: width > 700 ? '0 20vw' : '1vw',
          paddingLeft: '2vw',
        }}
      >
        <div className='cardofexamstype'>
          <div className='titileofcardofexamstype'>Wallet Amount</div>
          <div
            style={{ background: '#0052cc20' }}
            className='imgsamllexamtype'
          >
            <img
              src={img5}
              alt=''
            />
          </div>
          <div
            className='valueofcardofexamstype'
            style={{ display: 'flex', alignItems: 'center', gap: '0.3rem' }}
          >
            <div style={{ width: '18px' }}>
              <img
                src={coinSymbol}
                style={{ width: '100%', height: '100%' }}
                alt='coin_symbol'
              />
            </div>
            <span style={{ marginTop: '0.1rem' }}>
              {walletdata?.balance?.$numberDecimal}
            </span>
          </div>
        </div>

        <div
          onClick={() => {
            handleOpen1();
          }}
          className='cardofexamstype'
          style={{ pointerEvents: 'none', opacity: '0.5' }}
        >
          <div className='titileofcardofexamstype'>Add Fund</div>
          <div
            style={{ background: '#0052cc20' }}
            className='imgsamllexamtype'
          >
            <img
              src={img4}
              alt=''
            />
          </div>
          <div className='valueofcardofexamstype'>
            <div
              className='valueofcardofexamstype'
              style={{ display: 'flex', alignItems: 'center', gap: '0.3rem' }}
            >
              <span> Min </span>
              <div style={{ width: '18px',  }}>
                <img
                  src={coinSymbol}
                  style={{ width: '100%', height: '100%' }}
                  alt='coin_symbol'
                />
              </div>
              <span style={{ marginTop: '0.1rem' }}>100</span>
            </div>{' '}
          </div>
        </div>
        <div
          onClick={() => {
            handleOpen();
          }}
          className='cardofexamstype'
          style={{ pointerEvents: 'none', opacity: '0.5' }}
        >
          <div className='titileofcardofexamstype'>Withdraw Money</div>
          <div
            style={{ background: '#0052cc20' }}
            className='imgsamllexamtype'
          >
            <img
              src={img1}
              alt=''
            />
          </div>
          <div className='valueofcardofexamstype'>
            <div
              className='valueofcardofexamstype'
              style={{ display: 'flex', alignItems: 'center', gap: '0.3rem' }}
            >
              <span> Min </span>
              <div style={{ width: '18px',  }}>
                <img
                  src={coinSymbol}
                  style={{ width: '100%', height: '100%' }}
                  alt='coin_symbol'
                />
              </div>
              <span style={{ marginTop: '0.1rem' }}>100</span>
            </div>{' '}
          </div>
        </div>
      </div>

      <div
        hidden
        style={{ marginTop: '1vw' }}
        className='profiledetailstitle'
      >
        Redeem Cash
      </div>
      <div
        hidden
        style={{
          display: 'flex',

          alignItems: 'center',
          padding: '0 1vw',
        }}
      >
        <div className='redeamCardoftranctionwallet'>
          <img
            src={img15}
            alt=''
          />
        </div>
        <div className='redeamCardoftranctionwallet'>
          <img
            src={img11}
            alt=''
          />
        </div>
        <div className='redeamCardoftranctionwallet'>
          <img
            src={img14}
            alt=''
          />
        </div>
        <div className='redeamCardoftranctionwallet'>
          <img
            src={img13}
            alt=''
          />
        </div>
      </div>
      <div
        hidden
        style={{ marginTop: '1vw' }}
        className='profiledetailstitle'
      >
        Redeem Through Shopping
      </div>
      <div
        hidden
        style={{
          display: 'flex',

          alignItems: 'center',
          padding: '0 1vw',
        }}
      >
        <div className='redeamCardoftranctionwallet'>
          <img
            src={img11}
            alt=''
          />
        </div>
        <div className='redeamCardoftranctionwallet'>
          <img
            src={img12}
            alt=''
          />
        </div>
        <div className='redeamCardoftranctionwallet'>
          <img
            src={img15}
            alt=''
          />
        </div>
        <div className='redeamCardoftranctionwallet'>
          <img
            src={img11}
            alt=''
          />
        </div>
      </div>
      <div
        hidden
        style={{ marginTop: '1vw', display: 'flex', alignItems: 'center' }}
        className='profiledetailstitle'
      >
        <div style={{ width: '75%' }}>Redeem List</div>
        <div style={{ width: '25%' }}>Redeem</div>
      </div>
      <div
        hidden
        style={{ marginTop: '0.51vw', display: 'flex' }}
      >
        <div style={{ width: '75%' }}>
          <div className='Redeamlistboxes'>
            <div>
              Login your account and Get your Reward of Rs 500 will Credit your
              Wallet
            </div>
            <div>Claim Now</div>
          </div>
          <div className='Redeamlistboxes'>
            <div>
              Login your account and Get your Reward of Rs 500 will Credit your
              Wallet
            </div>
            <div>Claim Now</div>
          </div>
          <div className='Redeamlistboxes'>
            <div>
              Login your account and Get your Reward of Rs 500 will Credit your
              Wallet
            </div>
            <div>Claim Now</div>
          </div>
          <div className='Redeamlistboxes'>
            <div>
              Login your account and Get your Reward of Rs 500 will Credit your
              Wallet
            </div>
            <div>Claim Now</div>
          </div>
          <div className='Redeamlistboxes'>
            <div>
              Login your account and Get your Reward of Rs 500 will Credit your
              Wallet
            </div>
            <div>Claim Now</div>
          </div>
          <div className='Redeamlistboxes'>
            <div>
              Login your account and Get your Reward of Rs 500 will Credit your
              Wallet
            </div>
            <div>Claim Now</div>
          </div>
        </div>
        <div style={{ width: '25%' }}>
          <div className='Redeamlistboxes1'>
            <div className='flexofredeamname'>
              <span>
                <img
                  src={imgdot}
                  style={{
                    width: '0.7vw',
                    objectFit: 'contain',
                    margin: '1vw',
                  }}
                  alt=''
                />
              </span>{' '}
              Login Succesfully
            </div>
            <div className='flexofredeamname'>
              <span>
                <img
                  src={imgdot}
                  style={{
                    width: '0.7vw',
                    objectFit: 'contain',
                    margin: '1vw',
                  }}
                  alt=''
                />
              </span>{' '}
              Create a 1st Post
            </div>
            <div className='flexofredeamname'>
              <span>
                <img
                  src={imgdot}
                  style={{
                    width: '0.7vw',
                    objectFit: 'contain',
                    margin: '1vw',
                  }}
                  alt=''
                />
              </span>{' '}
              Bid a 1st Job
            </div>
            <div className='flexofredeamname'>
              <span>
                <img
                  src={imgdot1}
                  style={{
                    width: '0.7vw',
                    objectFit: 'contain',
                    margin: '1vw',
                  }}
                  alt=''
                />
              </span>{' '}
              Create a 25th Job
            </div>
            <div className='flexofredeamname'>
              <span>
                <img
                  src={imgdot1}
                  style={{
                    width: '0.7vw',
                    objectFit: 'contain',
                    margin: '1vw',
                  }}
                  alt=''
                />
              </span>{' '}
              Bid a 25th Job
            </div>
            <div className='flexofredeamname'>
              <span>
                <img
                  src={imgdot1}
                  style={{
                    width: '0.7vw',
                    objectFit: 'contain',
                    margin: '1vw',
                  }}
                  alt=''
                />
              </span>{' '}
              Refer your Friend
            </div>
            <div className='flexofredeamtag flexofredeamname'>
              <span>
                <img
                  style={{
                    width: '0.7vw',
                    objectFit: 'contain',
                    margin: '0.51vw',
                  }}
                  src={imgdot}
                  alt=''
                />
              </span>{' '}
              complete
            </div>
            <div className='flexofredeamtag flexofredeamname'>
              <span>
                <img
                  style={{
                    width: '0.7vw',
                    objectFit: 'contain',
                    margin: '0.51vw',
                  }}
                  src={imgdot1}
                  alt=''
                />
              </span>{' '}
              In complete
            </div>
          </div>
        </div>
      </div>

      <div
        style={{ marginTop: '1vw', padding: '0.5vw' }}
        className='profiledetailstitle'
      >
        Transaction List
      </div>
      <div
        style={{
          width: width > 700 ? '82vw' : '90vw',

          overflowX: 'scroll',
        }}
      >
        <div
          style={{
            alignItems: 'center',
            padding: '0.75vw 0.5vw',
            margin: width > 700 ? '1vw 0vw' : '2vw 0vw',
            width: width > 700 ? '82vw' : '204vw',
            fontSize: width > 700 ? '1.1vw' : '3vw',
            overflowX: 'scroll',
          }}
          className='navoftableblogs'
        >
          <div style={{ width: width > 700 ? '14vw' : '40vw' }}>
            Transaction{' '}
          </div>
          <div style={{ width: width > 700 ? '23.5vw' : '65vw' }}>
            Transaction id
          </div>
          <div style={{ width: width > 700 ? '7vw' : '25vw' }}>Type</div>
          <div style={{ width: width > 700 ? '9.5vw' : '30vw' }}>Date </div>
          <div style={{ width: width > 700 ? '9vw' : '30vw' }}>Amount</div>
          <div style={{ width: width > 700 ? '10vw' : '35vw' }}>Status</div>
          <div></div>
        </div>
        {transactiondata?.map((data) => {
          return (
            <Skillpopupcopy
              data={data}
              width={width}
              checktransaction={checktransaction}
              setChecktransaction={setChecktransaction}
            />
          );
        })}
      </div>

      <div
        style={{ width: width > 700 ? '25vw' : '90vw' }}
        className='paginationbox'
      >
        <div>
          <ArrowBackIosIcon
            style={{ fontSize: width > 700 ? '1.5vw' : '4vw' }}
            onClick={() => {
              if (page - 1 > 0) {
                setPage(page - 1);
              }
            }}
          />
        </div>

        <div
          hidden={page - 4 > 0 ? false : true}
          onClick={() => setPage(page - 4)}
        >
          {page - 4}
        </div>
        <div
          hidden={page - 3 > 0 ? false : true}
          onClick={() => setPage(page - 3)}
        >
          {page - 3}
        </div>
        <div
          hidden={page - 2 > 0 ? false : true}
          onClick={() => setPage(page - 2)}
        >
          {page - 2}
        </div>
        <div
          hidden={page - 1 > 0 ? false : true}
          onClick={() => setPage(page - 1)}
        >
          {page - 1}
        </div>
        <div style={{ color: '#2A6599' }}>{page}</div>
        <div onClick={() => setPage(page + 1)}>{page + 1}</div>
        <div onClick={() => setPage(page + 2)}>{page + 2}</div>
        <div onClick={() => setPage(page + 3)}>{page + 3}</div>

        <div>
          <ArrowForwardIosIcon
            style={{ fontSize: width > 700 ? '1.5vw' : '4vw' }}
            onClick={() => {
              setPage(page + 1);
            }}
          />
        </div>
      </div>

      <div>
        <div
          style={{ margin: 'auto', marginBottom: '5vw', marginTop: '3vw' }}
          className='cafaqcontainer'
        >
          <div className='catefaqheadname'>Frequently asked questions</div>
          <div className='catefaqheadhaveq'>
            Have questioners? we're here to help
          </div>

          <Catalogfaqquestion
            width={width}
            data={{
              question:
                "What is the pricing structure for Tool44's annual domestic and foreign trips feature?",
              answer:
                'The annual domestic and foreign trip feature is available after the 12-month subscription plan.',
            }}
          />
          <Catalogfaqquestion
            width={width}
            data={{
              question:
                'Where can I find notifications for rewards and contests on the Tool44 platform?',
              answer:
                'Notifications for rewards and contests will be displayed on the dashboard banner',
            }}
          />
          <Catalogfaqquestion
            width={width}
            data={{
              question: ' What is the refund policy for Tool44 membership?',
              answer:
                'The refund policy for Tool44 membership allows for refunds within 7 days of the initial purchase. If you are not satisfied with your membership and wish to request a refund, you must submit your refund request within this timeframe. <br /> Please note that the 7-day refund policy is subject to certain conditions. These may include providing proof of purchase, adhering to any additional requirements outlined in the refund policy, and returning any physical products (if applicable) in their original condition. To initiate a refund for your Tool44 membership, please contact our customer support team. They will guide you through the refund process and assist you with any necessary steps or documentation required. <br/>  We recommend reviewing the full refund policy, available on our website or in the terms and conditions, for further details and specific eligibility criteria. If you have any additional questions or need further assistance, our customer support team will be happy to assist you.',
            }}
          />
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={width > 700 ? style1 : style2}>
          <div className='profiletitleandmenunav'>
            <div className='profiledetailstitle'>Withdraw Money</div>
            <div className='profiledetailnavmanu'>
              <div>
                <CloseIcon
                  onClick={handleClose}
                  style={{
                    fontSize: width > 700 ? '1.5vw' : '4vw',
                    cursor: 'pointer',
                  }}
                />
              </div>
            </div>
          </div>
          <hr style={{ color: '#000000' }} />
          <div
            style={{
              left: '0vw',
              width: width > 700 ? '92%' : '100% ',
              marginLeft: '1vw',
            }}
            className='loginfield'
          >
            <TextField
              id='outlined-basic'
              label='Amount'
              variant='outlined'
              type='number'
              value={withdrawmoney}
              style={{ width: '100%' }}
              InputLabelProps={{
                style: {
                  fontSize: width > 700 ? '1vw' : '3vw',
                  fontFamily: 'Poppins',
                  fontStyle: '500',
                  fontWeight: '500',
                  color: 'black',
                },
              }}
              inputProps={{ className: classes.input }}
              onChange={(e) => {
                setWithdrawmoney(e.target.value);
              }}
            />
          </div>

          <p style={{ color: 'red', fontSize: '0.91vw', marginLeft: '1vw' }}>
            {moneyerror}
          </p>
          <div
            style={{
              left: '0vw',
              width: width > 700 ? '92%' : '100% ',
              marginLeft: '1vw',
            }}
            className='loginfield'
          >
            <TextField
              id='outlined-basic'
              label='Enter Your upi id'
              variant='outlined'
              value={upiid}
              style={{ width: '100%' }}
              InputLabelProps={{
                style: {
                  fontSize: width > 700 ? '1vw' : '3vw',
                  fontFamily: 'Poppins',
                  fontStyle: '500',
                  fontWeight: '500',
                  color: 'black',
                },
              }}
              inputProps={{ className: classes.input }}
              onChange={(e) => {
                setUpiid(e.target.value);
              }}
            />
          </div>

          <p style={{ color: 'red', fontSize: '0.91vw', marginLeft: '1vw' }}>
            {upiiderror}
          </p>

          {
            <div
              style={{ marginTop: '0.31vw' }}
              className='handlemoreaboutskill'
            >
              <div
                style={{
                  cursor: 'pointer',
                  width: width > 700 ? '10vw' : '30vw',
                }}
                className='handlecirclieaboutsave'
                onClick={handleAddwithdraw}
              >
                Raise Request
              </div>
            </div>
          }
        </Box>
      </Modal>
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={width > 700 ? style1 : style2}>
          <div className='profiletitleandmenunav'>
            <div className='profiledetailstitle'>Add Amount</div>
            <div className='profiledetailnavmanu'>
              <div>
                <CloseIcon
                  onClick={handleClose1}
                  style={{
                    fontSize: width > 700 ? '1.5vw' : '4vw',
                    cursor: 'pointer',
                  }}
                />
              </div>
            </div>
          </div>
          <hr style={{ color: '#000000' }} />
          <div
            style={{
              left: '0vw',
              width: width > 700 ? '92%' : '100% ',
              marginLeft: '1vw',
            }}
            className='loginfield'
          >
            <TextField
              id='outlined-basic'
              label='Amount'
              variant='outlined'
              type='number'
              value={addmoney}
              style={{ width: '100%' }}
              InputLabelProps={{
                style: {
                  fontSize: width > 700 ? '1vw' : '3vw',
                  fontFamily: 'Poppins',
                  fontStyle: '500',
                  fontWeight: '500',
                  color: 'black',
                },
              }}
              inputProps={{ className: classes.input }}
              onChange={(e) => {
                setaddmoney(e.target.value);
              }}
            />
          </div>

          <p style={{ color: 'red', fontSize: '0.91vw', marginLeft: '1vw' }}>
            {moneyerror}
          </p>

          {
            <div
              style={{ marginTop: '0.31vw' }}
              className='handlemoreaboutskill'
            >
              <div
                style={{
                  cursor: 'pointer',
                  width: width > 700 ? '10vw' : '30vw',
                }}
                className='handlecirclieaboutsave'
                onClick={handlecreateonelink}
              >
                Pay Now
              </div>
            </div>
          }
        </Box>
      </Modal>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open121}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </div>
  );
}
