import React, { useEffect, useState } from "react";
import "./Homeblog.css";
import img from "../../../assets/Success stories/zan-BKyvWG7D6QA-unsplash.png";

import API_HOST from "../../../env";
import axios from "axios";
import { useNavigate } from "react-router";
export default function Homeblog({ width }) {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    axios
      .get(
        `${API_HOST}/contentManagement/trendingContent?pageNumber=1&pageSize=6`
      )
      .then((res) => {
        setBlogs(res?.data?.success?.data);
      });
  }, []);

  const navigate = useNavigate();

  return (
    <div className="homeblogcontainer">
      <div className="home-catetitle">
        Blogs <span>(Learn)</span>
      </div>
      <div className="homesubtryst">Our Popular Blogs</div>
      <div
        style={{ marginLeft: "0vw", marginRight: "0vw", flexWrap: "wrap" }}
        className="hometriustbox"
      >
        {blogs?.length > 0 &&
          blogs?.map((item) => {
            const myStr = item?.contentName;
            const contentNameRoute = myStr
              .replaceAll(" ", "-")
              .replaceAll("/", "-")
              .replaceAll("?", "-");
            return (
              <div
                style={{ cursor: "pointer", background: "white", width: width > 700 ? "27.5vw" : "100%" }}
                onClick={() =>
                  navigate(`/blogs/${contentNameRoute}/${item?.contentId}`)
                }
                className="boxoffilterbox"
              >
                <div className="imgboxfilteredblog" style={{width:"100%"}}>
                  <img
                    src={item?.icon?.length > 0 ? item?.icon[0] : img}
                    alt=""
                    className="w-100"
                  />
                </div>
                <div className="mt-3">
      <div className="d-flex justify-content-between mb-2">
        <div className="tagblogblue" style={{width:"55%"}}>
          <span className="tagblogblack ellipsis" style={{display:"block", width:"100%"}}>
            {item?.contentName}
          </span>
        </div>
        <div className="tagblue " style={{whiteSpace:"nowrap"}}>  {item?.category} </div>
      </div>
      
      <div className="toc mt-2">
        <div>Table of Content</div>
        <ul className="mt-2" style={{height:width > 700 ? "6vw" :"19.5vw", overflow:"hidden"}}>
          {item?.toC?.length > 0 && item?.toC?.map((tocItem) => {
            return <li>{tocItem?.heading}</li>
          })}  
        </ul>
      </div>
      <hr style={{ padding: "0vw", margin: "0.3vw" }}></hr>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
            style={{
              fontSize: width > 700 ? "0.85vw" : "2.5vw",
              display: "flex",
              marginTop: "0.1vw",
              alignItems: "center",
              fontWeight:"600"
            }}
          >
            {item?.author}
          </div>
        <div className="view">views - {item?.views}</div>
      </div>
    </div>
                
              </div>
            );
          })}
      </div>
    </div>
  );
}
