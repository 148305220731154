import React from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import img2 from "../../../assets/Dashboard/Skill center – 2/Iconly-Light-outline-Edit.svg";

export default function Listofjobbox({ data, data1, width }) {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  return (
    <div>
      <div
        style={{
          overflow: "hidden",
          display: "block",
          width: width > 700 ? "" : "90vw",
          marginLeft: width > 700 ? "0.5vw" : "2vw",
        }}
        className="activejobpostbox"
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "0.2vw",
            justifyContent: "space-between",
          }}
        >
          <div style={{ marginLeft: "0vw" }} className="tagblue">
            {data1?.category[0]?.category}
          </div>

          <div>
            {" "}
            <div
              style={{
                marginLeft: "1vw",
                fontSize: width > 700 ? "0.8vw" : "2.3vw",
              }}
            >
              <span>
                <FiberManualRecordIcon
                  style={{
                    fontSize: width > 700 ? "1.5vw" : "4vw",
                    color:
                      data?.status === "Assigned"
                        ? "yellow"
                        : data?.status === "processing"
                        ? "red"
                        : "Green",
                  }}
                />
              </span>
            </div>
          </div>

          {(data1?.status === "pending" ||
            data1?.status === "Withdraw" ||
            data1?.status === "Rejected") &&
            data?.status === "processing" && (
              <div
                style={{ width: width > 700 ? "3vw" : "6vw", display: "flex" }}
              >
                <img
                  onClick={() =>
                    navigate(
                      `/dashboard/editapplieddeatil/${data?.purposalId}/${data1?.bidderId}`
                    )
                  }
                  style={{
                    width: width > 700 ? "1.4vw " : "4vw",
                    height: width > 700 ? "1.4vw" : "4vw",
                    borderRadius: "50%",
                    cursor: "pointer",
                    objectFit: "cover",
                  }}
                  src={img2}
                  alt=""
                />
              </div>
            )}
        </div>
        <div style={{ height: "1.1vw" }} className="activejobpostname">
          {data?.title?.length > 30
            ? data?.title?.slice(0, 32) + ".."
            : data?.title}{" "}
        </div>
        <div
          style={{ lineHeight: "1.2vw !important", color: "#0052cc" }}
          className="activejobpistbudgetbox"
        >
          <div>
            Bidding <br />{" "}
            <span
              style={{
                fontSize: width > 700 ? "0.9vw" : "2.7vw",
                position: "relative",
                bottom: "0.3vw",
                color: "#000000",
              }}
            >
              Rs. {data1?.budget}
            </span>
          </div>
          <div style={{ marginRight: "1vw" }}>
            Location <br />{" "}
            <span
              style={{
                fontSize: width > 700 ? "0.9vw" : "2.7vw",
                position: "relative",
                bottom: "0.3vw",
                color: "#000000",
              }}
            >
              {data?.remote
                ? "remote"
                : data?.location?.length > 20
                ? data?.location?.slice(0, 18) + ".."
                : data?.location}
            </span>
          </div>
          <div style={{ marginRight: "1vw" }}>
            Expired on <br />{" "}
            <span
              style={{
                fontSize: width > 700 ? "0.9vw" : "2.7vw",
                position: "relative",
                bottom: "0.3vw",
                color: "#000000",
              }}
            >
              {data?.dueDate && (
                <span>
                  {new Date(data?.dueDate).getDate()}/
                  {new Date(data?.dueDate).getMonth() + 1}/
                  {new Date(data?.dueDate).getFullYear()}
                </span>
              )}
            </span>
          </div>
        </div>
        <div
          style={{ height: width > 700 ? "6.5vw" : "16vw", margin: "0vw" }}
          className="descriptionactibeobbox"
        >
          <div
            style={{
              height: width > 700 ? "6.6vw" : "15.5vw",
              color: "black",
              fontWeight: "400",
              fontSize: width > 700 ? "0.9vw" : "2.5vw",
              overflow: "hidden",
            }}
          >
            <div
              className=""
              style={{ color: "black" }}
              dangerouslySetInnerHTML={{
                __html: data?.description.slice(0, 250),
              }}
            ></div>
          </div>
        </div>

        <hr />
        <div style={{ paddingLeft: "0vw" }} className="flexlastactiveb">
          <div>
            Status -
            <span
              style={{
                color:
                  data1?.status === "Completed"
                    ? "green"
                    : data1?.status === "Accepted"
                    ? "#0052cc"
                    : "red",
              }}
            >
              {data1?.status ? data1?.status : "pending"}
            </span>
          </div>

          <div
            onClick={() => {
              navigate(
                `/dashboard/applieddetail/${
                  data1?.bidderId ? data1?.bidderId : 2
                }`
              );
            }}
            style={{ color: "#000000", cursor: "pointer" }}
          >
            {" "}
            See More
          </div>
        </div>
      </div>
    </div>
  );
}
