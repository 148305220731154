import React, { useEffect, useState } from "react";

import imgxx from "../../assets/Success stories Definition/checkmark (1).svg";
import { useNavigate } from "react-router";
import img4 from "../../assets/My profile – 28/pexels-stefan-stefancik-91227.png";
export default function Listofjobbox({ data, width }) {
  const [checkonex, setCheckonex] = useState(false);
  useEffect(() => {
    setCheckonex(false);
  }, [data]);
  const navigate = useNavigate();
  return (
    <div>
      <div
        style={{
          overflow: "hidden",
          display: "block",
          width: width > 700 ? "28.5vw" : "90vw",
          marginLeft: width > 700 ? "1vw" : "0.8vw",
          marginRight: "1vw",
          padding: width > 700 ? "0.7vw" : "2vw",
        }}
        className="activejobpostbox"
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "0.5vw",
          }}
        >
          <div style={{ marginLeft: "0vw" }} className="tagblue">
            {data?.category?.category}
          </div>
        </div>
        <div style={{ height: "1vw" }} className="activejobpostname">
          {data?.title?.length > 40
            ? data?.title?.slice(0, 40) + ".."
            : data?.title}{" "}
        </div>

        <div
          style={{
            lineHeight: width > 700 ? "1.1vw !important" : "3vw !important",
            color: "#0053cc",
          }}
          className="activejobpistbudgetbox"
        >
          <div>
            Budget <br />{" "}
            <span
              style={{
                fontSize: width > 700 ? "0.9vw" : "2.7vw",
                position: "relative",
                bottom: "0.3vw",
                color: "#000000",
              }}
            >
              {data?.budget}
            </span>
          </div>
          <div style={{ marginRight: "1vw" }}>
            Location <br />{" "}
            <span
              style={{
                fontSize: width > 700 ? "0.9vw" : "2.7vw",
                position: "relative",
                bottom: "0.3vw",
                color: "#000000",
              }}
            >
              {data?.remote
                ? "Remote"
                : data?.location?.length > 20
                ? data?.location?.slice(0, 18) + ".."
                : data?.location}
            </span>
          </div>
          <div style={{ marginRight: "1vw" }}>
            Expired on <br />{" "}
            <span
              style={{
                fontSize: width > 700 ? "0.9vw" : "2.7vw",
                position: "relative",
                bottom: "0.3vw",
                color: "#000000",
              }}
            >
              {data?.dueDate && (
                <span>
                  {new Date(data?.dueDate).getDate()}/
                  {new Date(data?.dueDate).getMonth() + 1}/
                  {new Date(data?.dueDate).getFullYear()}
                </span>
              )}
            </span>
          </div>
        </div>

        <div
          style={{
            height: width > 700 ? "4.75vw" : "12vw",
            margin: "0vw",
            overflow: "hidden",
          }}
          className="descriptionactibeobbox"
        >
          <div
            className=""
            style={{
              color: "black",
              fontSize: width > 700 ? "0.91vw" : "2.7vw",
            }}
            dangerouslySetInnerHTML={{
              __html: data?.description.slice(0, 250),
            }}
          ></div>
        </div>

        <hr style={{ margin: width > 700 ? "1vw" : "2vw" }} />
        <div
          style={{
            paddingLeft: "0vw",
            alignItems: "center",
            cursor: "pointer",
          }}
          className="flexlastactiveb"
        >
          <div
            onClick={() => {
              navigate(`/dashboard/profile/${data?.user_id?.userName}`);
            }}
          >
            <img
              style={{
                width: width > 700 ? "1.5vw" : "4vw",
                margin: "0 0.1vw",
                borderRadius: "50%",
              }}
              src={data?.user_id?.media || img4}
              alt=""
            />{" "}
            {data?.user_id?.fullName?.slice(0, 15)}
            {data?.user_id?.verifiedByAdmin && (
              <span
                style={{
                  color: "#0052CC",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1vw" : "3vw",
                    margin: "0 0.3vw",
                  }}
                  src={imgxx}
                  alt=""
                />
                {"Verified"}
              </span>
            )}
          </div>

          <div
            onClick={() => {
              navigate(`/proposal/${data?.purposalId ? data?.purposalId : 2}`);
            }}
            style={{ color: "#000000", cursor: "pointer" }}
          >
            {" "}
            See More
          </div>
        </div>
      </div>
    </div>
  );
}
