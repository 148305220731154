import { Done } from "@mui/icons-material";
import axios from "axios";
import { Backdrop, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import API_HOST from "../../env";
import { userActions } from "../../store/userSlice";
import "./Pricing.css";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import img33 from "../../assets/Landing page/Group 1179.svg";
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
};
const style3 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
};
export default function PricingBanner({ width }) {
  const [openx3, setOpenx3] = React.useState(false);
  const handleOpenx3 = () => setOpenx3(true);
  const handleClosex3 = () => {
    setOpenx3(false);
    navigate("/pricing");
  };
  const [bluebackgroundbox, setBluebackgroundbox] = useState(2);
  const navigate = useNavigate();
  const { user, loggedInStatus, subscriptiondetail } = useSelector(
    (state) => state.user
  );

  const location = useLocation();
  const url = new URLSearchParams(location.search);

  let paymentLink = url.get("paymentLink");

  const [open121, setOpen121] = React.useState(false);
  const handleClose121 = () => {
    setOpen121(false);
  };
  const handleToggle121 = () => {
    setOpen121(!open121);
  };

  const dispatch = useDispatch();
  const handleStart = () => {
    if (!user?.userName) {
      dispatch(userActions.openloginForm());
    } else {
      navigate("/dashboard");
    }
  };

  const handlecreateonelink = () => {
    handleToggle121();
    axios
      ?.post(`${API_HOST}/stripe/paymentLink`, {})
      .then((res) => {
        window.location.replace(res?.data?.success?.link);
        handleClose121();
      })
      .catch(() => {
        handleClose121();
      });
  };

  const [susseccVirtualassistant, setsusseccVirtualassistant] = useState(false);

  useEffect(() => {
    if (paymentLink) {
      handleToggle121();
      axios
        .post(
          `${API_HOST}/stripe/getPayment`,
          { checkoutSessionId: paymentLink },
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then((res) => {
          setsusseccVirtualassistant(true);
          handleOpenx3();
          handleClose121();
        })
        .catch(() => {
          handleClose121();
        });
    }
  }, [paymentLink]);

  return (
    <div style={{ paddingTop: width > 700 ? "2vw" : "4vw" }}>
      <div className="abouttitileheading">
        Best <span style={{ color: "#0052cc" }}>Pricing</span> For Business
      </div>
      <div
        style={{ marginBottom: width > 700 ? "3vw" : "6vw" }}
        className="abouttitileheadingsub"
      >
        We offer flexible pricing options to cater to your specific needs.
        <br /> Choose the plan that suits you best and take advantage of our
        exceptional features and benefits.
      </div>
      <div style={{ padding: "0 12vw" }} className="pricingboxescontainer">
        {/* <div
          style={{ background: bluebackgroundbox === 1 ? "#0052CC" : "" }}
          className="pricingbox"
          onMouseEnter={() => setBluebackgroundbox(1)}
          onMouseLeave={() => setBluebackgroundbox(0)}
        >
          <div
            style={{ color: bluebackgroundbox === 1 ? "white" : "" }}
            className="pricingboxtitile"
          >
            Trial
          </div>
          <div
            style={{
              fontSize: width > 700 ? "0.8vw" : "2.5vw",
              color: bluebackgroundbox === 1 ? "white" : "",
            }}
          >
            (Ideal for Startup )
          </div>
          <div
            style={{ color: bluebackgroundbox === 1 ? "white" : "" }}
            className="pricingboxtiprice"
          >
            Free
            <span style={{ fontSize: width > 700 ? "0.9vw" : "2.4vw" }}>
              (For 1 Month)
            </span>
          </div>
          <div style={{ height: width > 700 ? "10vw" : "28vw" }}>
            <div
              style={{ color: bluebackgroundbox === 1 ? "white" : "" }}
              className="pricingticks"
            >
              <Done
                style={{
                  fontSize: width > 700 ? "1.4vw" : "4vw",
                  marginRight: "0.7vw",
                }}
              />
              Free Software
            </div>
            <div
              style={{ color: bluebackgroundbox === 1 ? "white" : "" }}
              className="pricingticks"
            >
              <Done
                style={{
                  fontSize: width > 700 ? "1.4vw" : "4vw",
                  marginRight: "0.7vw",
                }}
              />
              Virtual Assistant Services
            </div>
          </div>

          <button
            onClick={() => {
              handleStart();
            }}
            className={
              bluebackgroundbox === 1 ? "pricingButton1" : "pricingButton"
            }
          >
            Get Started
          </button>
        </div>
        */}
        <div
          style={{ background: bluebackgroundbox === 2 ? "#0052CC" : "" }}
          onMouseEnter={() => setBluebackgroundbox(2)}
          onMouseLeave={() => setBluebackgroundbox(0)}
          className="pricingbox"
        >
          <div
            style={{ color: bluebackgroundbox === 2 ? "white" : "" }}
            className="pricingboxtitile"
          >
            Basic Wallet
          </div>
          <div
            style={{
              fontSize: width > 700 ? "0.8vw" : "2.5vw",
              color: bluebackgroundbox === 2 ? "white" : "",
            }}
          >
            (Ideal for Small & Medium Enterprises)
          </div>
          <div
            style={{ color: bluebackgroundbox === 2 ? "white" : "" }}
            className="pricingboxtiprice"
          >
            <span style={{ fontSize: width > 700 ? "0.9vw" : "2.4vw" }}>
              {" "}
              (Starting at )
            </span>{" "}
            Rs. 1
            <span style={{ fontSize: width > 700 ? "0.9vw" : "2.4vw" }}>
              {" "}
              (per service )
            </span>
          </div>
          <div style={{ height: width > 700 ? "10vw" : "28vw" }}>
            <div
              style={{ color: bluebackgroundbox === 2 ? "white" : "" }}
              className="pricingticks"
            >
              <Done
                style={{
                  fontSize: width > 700 ? "1.4vw" : "4vw",
                  marginRight: "0.7vw",
                }}
              />
              Free Software
            </div>
            <div
              style={{ color: bluebackgroundbox === 2 ? "white" : "" }}
              className="pricingticks"
            >
              <Done
                style={{
                  fontSize: width > 700 ? "1.4vw" : "4vw",
                  marginRight: "0.7vw",
                }}
              />
              Virtual Assistant Services
            </div>
            <div
              style={{ color: bluebackgroundbox === 2 ? "white" : "" }}
              className="pricingticks"
            >
              <Done
                style={{
                  fontSize: width > 700 ? "1.4vw" : "4vw",
                  marginRight: "0.7vw",
                }}
              />
              Reward and Recognition*
            </div>
          </div>

          <button
            onClick={() => {
              loggedInStatus ? navigate("/dashboard/wallet") : handleStart();
            }}
            className={
              bluebackgroundbox === 2 ? "pricingButton1" : "pricingButton"
            }
          >
            {subscriptiondetail?.customer_id ? "Billing Portal" : "Get Started"}
          </button>
        </div>
        <div
          style={{ background: bluebackgroundbox === 3 ? "#0052CC" : "" }}
          className="pricingbox"
          onMouseEnter={() => setBluebackgroundbox(3)}
          onMouseLeave={() => setBluebackgroundbox(0)}
        >
          <div
            style={{ color: bluebackgroundbox === 3 ? "white" : "" }}
            className="pricingboxtitile"
          >
            Custom Plan
          </div>
          <div
            style={{
              fontSize: width > 700 ? "0.8vw" : "2.5vw",
              color: bluebackgroundbox === 3 ? "white" : "",
            }}
          >
            (Ideal for larger organizations )
          </div>
          <div
            style={{ color: bluebackgroundbox === 3 ? "white" : "" }}
            className="pricingboxtiprice"
          >
            Custom
          </div>
          <div style={{ height: width > 700 ? "10vw" : "28vw" }}>
            <div
              style={{ color: bluebackgroundbox === 3 ? "white" : "" }}
              className="pricingticks"
            >
              <Done
                style={{
                  fontSize: width > 700 ? "1.4vw" : "4vw",
                  marginRight: "0.7vw",
                }}
              />
              Free Software
            </div>
            <div
              style={{ color: bluebackgroundbox === 3 ? "white" : "" }}
              className="pricingticks"
            >
              <Done
                style={{
                  fontSize: width > 700 ? "1.4vw" : "4vw",
                  marginRight: "0.7vw",
                }}
              />
              Virtual Assistant Services
            </div>
            <div
              style={{ color: bluebackgroundbox === 3 ? "white" : "" }}
              className="pricingticks"
            >
              <Done
                style={{
                  fontSize: width > 700 ? "1.4vw" : "4vw",
                  marginRight: "0.7vw",
                }}
              />
              Reward and Recognition*
            </div>
          </div>

          <button
            className={
              bluebackgroundbox === 3 ? "pricingButton1" : "pricingButton"
            }
          >
            Get Started
          </button>
        </div>
        <div
          style={{ background: bluebackgroundbox === 4 ? "#0052CC" : "" }}
          className="pricingbox"
          onMouseEnter={() => setBluebackgroundbox(4)}
          onMouseLeave={() => setBluebackgroundbox(0)}
        >
          <div
            style={{ color: bluebackgroundbox === 4 ? "white" : "" }}
            className="pricingboxtitile"
          >
            Virtual Assistant Essentials
          </div>
          <div
            style={{
              fontSize: width > 700 ? "0.8vw" : "2.5vw",
              color: bluebackgroundbox === 4 ? "white" : "",
            }}
          >
            (Ideal for verified Member)
          </div>
          <div
            style={{ color: bluebackgroundbox === 4 ? "white" : "" }}
            className="pricingboxtiprice"
          >
            Rs.2500{" "}
            <span style={{ fontSize: width > 700 ? "0.9vw" : "2.4vw" }}>
              (per request)
            </span>
          </div>
          <div style={{ height: width > 700 ? "10vw" : "28vw" }}>
            <div
              style={{ color: bluebackgroundbox === 4 ? "white" : "" }}
              className="pricingticks"
            >
              <Done
                style={{
                  fontSize: width > 700 ? "1.4vw" : "4vw",
                  marginRight: "0.7vw",
                }}
              />
              100 Visiting Cards
            </div>
            <div
              style={{ color: bluebackgroundbox === 4 ? "white" : "" }}
              className="pricingticks"
            >
              <Done
                style={{
                  fontSize: width > 700 ? "1.4vw" : "4vw",
                  marginRight: "0.7vw",
                }}
              />
              1 Membership ID Card
            </div>
            <div
              style={{ color: bluebackgroundbox === 4 ? "white" : "" }}
              className="pricingticks"
            >
              <Done
                style={{
                  fontSize: width > 700 ? "1.4vw" : "4vw",
                  marginRight: "0.7vw",
                }}
              />
              Company Logo T-shirts
            </div>
          </div>

          <button
            onClick={() => {
              handlecreateonelink();
            }}
            className={
              bluebackgroundbox === 4 ? "pricingButton1" : "pricingButton"
            }
          >
            Get Started
          </button>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open121}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Modal
        open={openx3 && susseccVirtualassistant}
        onClose={handleClosex3}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={width > 700 ? style1 : style3}>
          <div style={{ padding: "1vw" }} className="profiletitleandmenunav">
            <div
              style={{ color: "#0052cc", textAlign: "center" }}
              className="profiledetailstitle"
            >
              Virtual Assistant Essentials
            </div>
            <div className="profiledetailnavmanu">
              <div>
                <CloseIcon
                  onClick={handleClosex3}
                  style={{
                    fontSize: width > 700 ? "1.5vw" : "4vw",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          </div>

          <div className="imgboxofrewardpostlogo">
            <img src={img33} alt="" />
          </div>
          <div
            style={{ textAlign: "center", color: "#0052cc" }}
            className="rewardtiitle500"
          >
            Congratulations! Application successful for Virtual Assistant
            Essentials. Expect confirmation email from Tool44. For queries,
            reach out to us. Excited to collaborate! Thank you for choosing us.
          </div>

          <div style={{ height: "2vw" }}></div>
        </Box>
      </Modal>
    </div>
  );
}
