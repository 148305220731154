import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Backdrop, CircularProgress, TextField } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import API_HOST from "../../../env";
import axios from "axios";
import { Metadata } from "../../Metadata/Metadata";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Texteditor from "../AddProduct/Texteditor";

const useStyles = makeStyles((theme) => ({
  input: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "0.91vw",
    color: "#263238",
    border: "yellow !important",
  },
}));

export default function EditContract({ width }) {
  useEffect(() => {
    window.scrollTo(0, 0, { behavior: "smooth" });
  }, []);
  const classes = useStyles();
  const [isloading, setIsloading] = useState(false);
  const navigate = useNavigate();
  const [open121, setOpen121] = React.useState(false);
  const handleClose121 = () => {
    setOpen121(false);
  };
  const handleToggle121 = () => {
    setOpen121(!open121);
  };
  const { id } = useParams();
  const [showerror, setShowerror] = useState("");
  const [ContractName, setContractName] = useState("");
  const [desc, setDesc] = useState("");
  const [firstCompanyContractor, setFirstCompanyContractor] = useState("");
  const [firstCompanyName, setFirstCompanyName] = useState("");
  const [firstCompanymobile, setFirstCompanyMobile] = useState("");
  const [firstCompanyemail, setFirstCompanyemail] = useState("");
  const [firstCompanyAddress, setfirstCompanyAddress] = useState("");
  const [secondCompanyContractor, setsecondCompanyContractor] = useState("");
  const [secondCompanyName, setsecondCompanyName] = useState("");
  const [secondCompanymobile, setsecondCompanymobile] = useState("");
  const [secondCompanyemail, setsecondCompanyemail] = useState("");
  const [secondCompanyAddress, setsecondCompanyAddress] = useState("");
  const [date, setDate] = useState("");

  const handlesavedaddcatalogue = () => {
    if (!ContractName && contractNumber) {
      setShowerror("Please fill all the details");
      return;
    }
    setIsloading(true);
    handleToggle121();

    const formdata = new FormData();

    formdata.append("contractId", id);
    companyid && formdata.append("companyId", companyid?._id);
    companyid && formdata.append("logo", companyid?.logo);
    formdata.append("companyName", firstCompanyName);
    formdata.append("contractorName", firstCompanyContractor);
    formdata.append("emailId", firstCompanyemail);
    formdata.append("mobile", firstCompanymobile);
    formdata.append("address", firstCompanyAddress);
    formdata.append("contractName", ContractName);
    formdata.append("contractNo", contractNumber);
    formdata.append("secondcompanyName", secondCompanyName);
    formdata.append("secondcontractorName", secondCompanyContractor);
    formdata.append("secondemailId", secondCompanyemail);
    formdata.append("secondmobile", secondCompanymobile);
    formdata.append("secondaddress", secondCompanyAddress);
    formdata.append("detail", desc);

    date && formdata.append("date", date.slice(0, 10));
    date && formdata.append("time", date.slice(11));

    axios
      .post(`${API_HOST}/contract/editContract`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        navigate("/dashboard/contract");
        handleClose121();
        setIsloading(false);
      })
      .catch((err) => {
        handleClose121();
        setIsloading(false);
      });
  };

  const [companyid, setcompanyid] = useState();
  const [companyall, setCompanyall] = useState([]);
  const [contractNumber, setContractNumber] = useState("");

  useEffect(() => {
    axios
      .get(`${API_HOST}/companyDetails/viewExisted`, {
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        setCompanyall(res?.data?.success?.data);
      });

    axios
      .get(`${API_HOST}/contract/viewContract?contractId=${id}`, {
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        setContractName(res.data.success.data[0].contractName);
        setContractNumber(res.data.success.data[0].contractNo);
        console.log(
          res.data.success.data[0].date.slice(0, 10) +
            "T" +
            res.data.success.data[0].time
        );
        setDate(
          res.data.success.data[0].date.slice(0, 10) +
            "T" +
            res.data.success.data[0].time
        );
        setfirstCompanyAddress(res.data.success.data[0].address);
        setFirstCompanyContractor(res.data.success.data[0].contractorName);
        setFirstCompanyMobile(res.data.success.data[0].mobile);
        setFirstCompanyemail(res.data.success.data[0].emailId);
        setFirstCompanyName(res.data.success.data[0].companyName);
        setsecondCompanyAddress(res.data.success.data[0].secondaddress);
        setsecondCompanyContractor(
          res.data.success.data[0].secondcontractorName
        );
        setsecondCompanymobile(res.data.success.data[0].secondmobile);
        setsecondCompanyemail(res.data.success.data[0].secondemailId);
        setsecondCompanyName(res.data.success.data[0].secondcompanyName);
        setDesc(res.data.success.data[0].detail);
      });
  }, []);

  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const open1 = Boolean(anchorEl1);
  const id1 = open1 ? "simple-popover" : undefined;

  useEffect(() => {
    if (companyid) {
      setfirstCompanyAddress(
        companyid?.adress + ", " + companyid?.state + "-" + companyid?.pinCode
      );
      setFirstCompanyMobile(companyid?.mobile || "");
      setFirstCompanyName(companyid?.companyName);
      setFirstCompanyemail(companyid?.emailId);
    }
  }, [companyid]);

  return (
    <div
      className="ScrollTable"
      style={{
        background: "#F9F9F9",
        width: "100vw",
        padding: "0vw 1vw",
        height: width > 700 ? "calc(100vh - 4vw)" : "calc(100vh - 14vw)",
      }}
    >
      <Metadata
        title={`Add Contract | Manage and Grow Your Business While Saving Costs`}
        description={
          "Connecting Businesses and Freelancers with Essential Business Tools in One Location"
        }
        keyword={
          "www.tool44.com,tool44,44tool,tool.com,Freelance jobs, blog ,aboutustool44, Freelancers,tool 44, 44tool ,44 tool,Freelance services,Remote work,Online jobs,Work from home,Freelance marketplace,Freelance platform,Freelance opportunities,Freelance gigs,Hire freelancers,Find freelancers,Freelance projects,Freelance work,Freelance community,Freelance professionals,Freelance talent,Freelance collaboration,Freelance job search,Freelance career"
        }
      ></Metadata>
      <div className="addCatalogcontainer ">
        <div className="profiletitleandmenunav">
          <div
            style={{
              fontSize: width > 700 ? "1.7vw" : "4vw",
              fontWeight: "400",
            }}
            className="profiledetailstitle"
          >
            Edit Contract
          </div>
          <div className="profiledetailnavmanu">
            <div></div>
          </div>
        </div>
        <hr style={{ color: "#000000", margin: width > 700 ? "1vw" : "2vw" }} />
        <div
          style={{
            display: "flex",
            margin: width > 700 ? "0vw" : "1vw 0",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              left: "0vw",
              width: width > 700 ? "49%" : "100% ",
              marginLeft: "0%",
              cursor: "pointer",
            }}
            className="loginfield"
            onClick={handleClick1}
          >
            <TextField
              id="outlined-basic"
              label="Select Your Company "
              variant="outlined"
              disabled
              value={companyid?.companyName || ""}
              style={{ width: "100%" }}
              InputLabelProps={{
                style: {
                  fontSize: width > 700 ? "1vw" : "3vw",
                  fontFamily: "Poppins",
                  fontStyle: "500",
                  fontWeight: "500",
                  color: "black",
                },
              }}
              inputProps={{ className: classes.input }}
              onChange={(e) => {
                console.log(e.target.value);
              }}
            />
            <span style={{ width: "0.1vw" }}>
              <KeyboardArrowDownOutlined
                style={{
                  fontSize: width > 700 ? "1.5vw" : "5vw",
                  cursor: "pointer",
                  position: "relative",
                  right: width > 700 ? "2vw" : "6vw",
                  top: width > 700 ? "1vw" : "3vw",
                }}
              />
            </span>
          </div>
          <Popover
            id={id1}
            open={open1}
            anchorEl={anchorEl1}
            onClose={handleClose1}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div
              style={{
                maxHeight: width > 700 ? "18vw" : "40vw",
                overflow: "scroll",
                width: width > 700 ? "33vw" : "94vw",
              }}
            >
              {companyall?.map((data) => {
                return (
                  <Typography
                    sx={{
                      p: 0.51,
                      pl: 1,
                      ml: 1,
                      width: "100%",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setcompanyid(data);
                      handleClose1();
                    }}
                  >
                    {data?.companyName}
                  </Typography>
                );
              })}
            </div>
          </Popover>

          <div
            style={{
              left: "0vw",
              width: width > 700 ? "49%" : "100% ",
              marginLeft: "0vw",
            }}
            className="loginfield"
          >
            <TextField
              id="outlined-basic"
              label="Contract No"
              value={contractNumber}
              variant="outlined"
              style={{ width: "100%" }}
              InputLabelProps={{
                shrink: true,
                style: {
                  fontSize: width > 700 ? "1vw" : "3vw",
                  fontFamily: "Poppins",
                  fontStyle: "500",
                  fontWeight: "500",
                  color: "black",
                },
              }}
              inputProps={{ className: classes.input }}
              onChange={(e) => {
                setContractNumber(e.target.value);
              }}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            margin: width > 700 ? "0vw" : "1vw 0",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              left: "0vw",
              width: width > 700 ? "49%" : "100% ",
              marginLeft: "0vw",
            }}
            className="loginfield"
          >
            <TextField
              id="outlined-basic"
              label="Contract Name"
              value={ContractName}
              variant="outlined"
              style={{ width: "100%" }}
              InputLabelProps={{
                style: {
                  fontSize: width > 700 ? "1vw" : "3vw",
                  fontFamily: "Poppins",
                  fontStyle: "500",
                  fontWeight: "500",
                  color: "black",
                },
              }}
              inputProps={{ className: classes.input }}
              onChange={(e) => {
                setContractName(e.target.value);
              }}
            />
          </div>

          <div
            style={{
              left: "0vw",
              width: width > 700 ? "49%" : "100% ",
              margin: width > 700 ? "1vw 0.5vw 2vw 0vw" : "4vw 0",
            }}
            className="loginfield"
          >
            <TextField
              id="outlined-basic"
              label="Date"
              type="datetime-local"
              value={date}
              variant="outlined"
              style={{ width: "100%" }}
              InputLabelProps={{
                shrink: true,
                style: {
                  fontSize: width > 700 ? "1vw" : "3vw",
                  fontFamily: "Poppins",
                  fontStyle: "500",
                  fontWeight: "500",
                  color: "black",
                },
              }}
              inputProps={{ className: classes.input }}
              onChange={(e) => {
                console.log(e.target.value);
                setDate(e.target.value);
              }}
            />
          </div>
        </div>

        <div
          className="cateaddlastcheck"
          style={{ marginTop: "0vw", marginBottom: "0.5vw" }}
        >
          First Party Details (this information will autofill upon selecting
          your company ,you can change this detail)
        </div>

        <div
          style={{
            display: "flex",
            margin: width > 700 ? "0vw" : "1vw 0",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              left: "0vw",
              width: width > 700 ? "49%" : "100% ",
              marginLeft: "0vw",
            }}
            className="loginfield"
          >
            <TextField
              id="outlined-basic"
              label="Name of contracting party"
              value={firstCompanyContractor}
              variant="outlined"
              style={{ width: "100%" }}
              InputLabelProps={{
                style: {
                  fontSize: width > 700 ? "1vw" : "3vw",
                  fontFamily: "Poppins",
                  fontStyle: "500",
                  fontWeight: "500",
                  color: "black",
                },
              }}
              inputProps={{ className: classes.input }}
              onChange={(e) => {
                setFirstCompanyContractor(e.target.value);
              }}
            />
          </div>

          <div
            style={{
              left: "0vw",
              width: width > 700 ? "49%" : "100% ",
              margin: width > 700 ? "1vw 0.5vw 1vw 0vw" : "4vw 0",
            }}
            className="loginfield"
          >
            <TextField
              id="outlined-basic"
              label="Company name"
              type="text"
              value={firstCompanyName}
              variant="outlined"
              style={{ width: "100%" }}
              InputLabelProps={{
                style: {
                  fontSize: width > 700 ? "1vw" : "3vw",
                  fontFamily: "Poppins",
                  fontStyle: "500",
                  fontWeight: "500",
                  color: "black",
                },
              }}
              inputProps={{ className: classes.input }}
              onChange={(e) => setFirstCompanyName(e.target.value)}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            margin: width > 700 ? "0vw" : "1vw 0",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              left: "0vw",
              width: width > 700 ? "49%" : "100% ",
              marginLeft: "0vw",
            }}
            className="loginfield"
          >
            <TextField
              id="outlined-basic"
              label="Email"
              value={firstCompanyemail}
              variant="outlined"
              style={{ width: "100%" }}
              InputLabelProps={{
                style: {
                  fontSize: width > 700 ? "1vw" : "3vw",
                  fontFamily: "Poppins",
                  fontStyle: "500",
                  fontWeight: "500",
                  color: "black",
                },
              }}
              inputProps={{ className: classes.input }}
              onChange={(e) => {
                setFirstCompanyemail(e.target.value);
              }}
            />
          </div>

          <div
            style={{
              left: "0vw",
              width: width > 700 ? "49%" : "100% ",
              margin: width > 700 ? "1vw 0.5vw 2vw 0vw" : "4vw 0",
            }}
            className="loginfield"
          >
            <TextField
              id="outlined-basic"
              label="Mobile number"
              type="number"
              value={firstCompanymobile}
              variant="outlined"
              style={{ width: "100%" }}
              InputLabelProps={{
                style: {
                  fontSize: width > 700 ? "1vw" : "3vw",
                  fontFamily: "Poppins",
                  fontStyle: "500",
                  fontWeight: "500",
                  color: "black",
                },
              }}
              inputProps={{ className: classes.input }}
              onChange={(e) => setFirstCompanyMobile(e.target.value)}
            />
          </div>
        </div>

        <div
          style={{
            left: "0vw",
            width: "99%",
            margin: width > 700 ? "0vw 0.5vw 2vw 0vw" : "4vw 0",
          }}
          className="loginfield"
        >
          <TextField
            id="outlined-basic"
            label="Address"
            value={firstCompanyAddress}
            variant="outlined"
            style={{ width: "100%" }}
            InputLabelProps={{
              style: {
                fontSize: width > 700 ? "1vw" : "3vw",
                fontFamily: "Poppins",
                fontStyle: "500",
                fontWeight: "500",
                color: "black",
              },
            }}
            inputProps={{ className: classes.input }}
            onChange={(e) => {
              setfirstCompanyAddress(e.target.value);
            }}
          />
        </div>
        <div
          className="cateaddlastcheck"
          style={{ marginTop: "0vw", marginBottom: "0.5vw" }}
        >
          Second Party Details
        </div>

        <div
          style={{
            display: "flex",
            margin: width > 700 ? "0vw" : "1vw 0",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              left: "0vw",
              width: width > 700 ? "49%" : "100% ",
              marginLeft: "0vw",
            }}
            className="loginfield"
          >
            <TextField
              id="outlined-basic"
              label="Contract Name"
              value={secondCompanyContractor}
              variant="outlined"
              style={{ width: "100%" }}
              InputLabelProps={{
                style: {
                  fontSize: width > 700 ? "1vw" : "3vw",
                  fontFamily: "Poppins",
                  fontStyle: "500",
                  fontWeight: "500",
                  color: "black",
                },
              }}
              inputProps={{ className: classes.input }}
              onChange={(e) => {
                setsecondCompanyContractor(e.target.value);
              }}
            />
          </div>

          <div
            style={{
              left: "0vw",
              width: width > 700 ? "49%" : "100% ",
              margin: width > 700 ? "1vw 0.5vw 1vw 0vw" : "4vw 0",
            }}
            className="loginfield"
          >
            <TextField
              id="outlined-basic"
              label="Company name"
              type="text"
              value={secondCompanyName}
              variant="outlined"
              style={{ width: "100%" }}
              InputLabelProps={{
                shrink: true,
                style: {
                  fontSize: width > 700 ? "1vw" : "3vw",
                  fontFamily: "Poppins",
                  fontStyle: "500",
                  fontWeight: "500",
                  color: "black",
                },
              }}
              inputProps={{ className: classes.input }}
              onChange={(e) => setsecondCompanyName(e.target.value)}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            margin: width > 700 ? "0vw" : "1vw 0",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              left: "0vw",
              width: width > 700 ? "49%" : "100% ",
              marginLeft: "0vw",
            }}
            className="loginfield"
          >
            <TextField
              id="outlined-basic"
              label="Email"
              value={secondCompanyemail}
              variant="outlined"
              style={{ width: "100%" }}
              InputLabelProps={{
                style: {
                  fontSize: width > 700 ? "1vw" : "3vw",
                  fontFamily: "Poppins",
                  fontStyle: "500",
                  fontWeight: "500",
                  color: "black",
                },
              }}
              inputProps={{ className: classes.input }}
              onChange={(e) => {
                setsecondCompanyemail(e.target.value);
              }}
            />
          </div>

          <div
            style={{
              left: "0vw",
              width: width > 700 ? "49%" : "100% ",
              margin: width > 700 ? "1vw 0.5vw 2vw 0vw" : "4vw 0",
            }}
            className="loginfield"
          >
            <TextField
              id="outlined-basic"
              label="Mobile number"
              type="number"
              value={secondCompanymobile}
              variant="outlined"
              style={{ width: "100%" }}
              InputLabelProps={{
                style: {
                  fontSize: width > 700 ? "1vw" : "3vw",
                  fontFamily: "Poppins",
                  fontStyle: "500",
                  fontWeight: "500",
                  color: "black",
                },
              }}
              inputProps={{ className: classes.input }}
              onChange={(e) => setsecondCompanymobile(e.target.value)}
            />
          </div>
        </div>

        <div
          style={{
            left: "0vw",
            width: "99%",
            margin: width > 700 ? "0vw 0.5vw 2vw 0vw" : "4vw 0",
          }}
          className="loginfield"
        >
          <TextField
            id="outlined-basic"
            label="Address"
            value={secondCompanyAddress}
            variant="outlined"
            style={{ width: "100%" }}
            InputLabelProps={{
              style: {
                fontSize: width > 700 ? "1vw" : "3vw",
                fontFamily: "Poppins",
                fontStyle: "500",
                fontWeight: "500",
                color: "black",
              },
            }}
            inputProps={{ className: classes.input }}
            onChange={(e) => {
              setsecondCompanyAddress(e.target.value);
            }}
          />
        </div>

        <div className="jobpodtedfieldtitile">
          Information / Letter Content *
        </div>
        <div className="jobpostfieldinputbox">
          <div
            style={{
              margin: "0vw 0vw 1vw 0vw",
              width: width > 700 ? "67vw" : "94vw",
            }}
          >
            <Texteditor setShortdesc={setDesc} shortdesc={desc} />
          </div>
        </div>

        <div className="cateaddlastcheck"></div>
        <span
          style={{
            color: "red",
            fontSize: width > 700 ? "0.9vw" : "2.7vw",
          }}
        >
          {showerror}
        </span>
        <div style={{ marginTop: "0.31vw" }} className="handlemoreaboutskill">
          <div
            style={{
              background: "white",
              color: "black",
              cursor: "pointer",
            }}
            className="handlecirclieaboutsave"
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </div>

          <div
            style={{ cursor: "pointer" }}
            className="handlecirclieaboutsave"
            onClick={() => {
              if (!isloading) {
                handlesavedaddcatalogue();
              }
            }}
          >
            Save
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open121}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
