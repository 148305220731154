import React from "react";
import { useNavigate } from "react-router-dom";
export default function Homeexplorelist({
  viewAllCategories,
  setCategoryid,
  categoryid,
  width,
}) {
  const navigate = useNavigate();
  return (
    <div className="homecate-menu">
      <button
        className={!categoryid ? "allcatebox" : "allcatemenuname"}
        onClick={() => setCategoryid()}
      >
        All Categories
      </button>
      {width > 700
        ? viewAllCategories?.map((data, index) => {
            return (
              <div onClick={() => setCategoryid(data?._id)}>
                <button
                  style={{ width: "fit-content" }}
                  className={
                    categoryid === data?._id ? "allcatebox" : "allcatemenuname"
                  }
                >
                  {data?.category}
                </button>
              </div>
            );
          })
        : viewAllCategories?.slice(0,1)?.map((data, index) => {
            return (
              <div onClick={() => setCategoryid(data?._id)}>
                <button
                  style={{ width: "fit-content" }}
                  className={
                    categoryid === data?._id ? "allcatebox" : "allcatemenuname"
                  }
                >
                  {data?.category}
                </button>
              </div>
            );
          })}
      <div onClick={() => navigate("/categories")} className="allcatemenuname">
        More
      </div>
    </div>
  );
}
