import React, { useEffect, useState } from "react";

import "react-tippy/dist/tippy.css";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CloseIcon from "@mui/icons-material/Close";

import { TextField } from "@mui/material";
import img2 from "../../../assets/Dashboard/Skill center – 2/Iconly-Light-outline-Edit.svg";
import img3 from "../../../assets/Dashboard/Skill center – 2/Iconly-Light-outline-Document.svg";
import img4 from "../../../assets/Dashboard/Skill center – 2/Iconly-Light-outline-Delete.svg";

import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
import { makeStyles } from "@material-ui/core";
import axios from "axios";
import API_HOST from "../../../env";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  bgcolor: "background.paper",
  maxHeight: "90vh",
  overflow: "scroll",
  boxShadow: 24,
  p: 3,
};

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  maxHeight: "90vh",
  overflow: "scroll",
  p: 1,
};

const useStyles = makeStyles((theme) => ({
  input: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "0.91vw",
    color: "#263238",
    border: "yellow !important",
  },
}));

export default function Upcomingevent({ data, width, setRecall, recall }) {
  const { user } = useSelector((state) => state.user);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const [anchorElx2, setAnchorElx2] = React.useState(null);
  const handleClickx2 = (event) => {
    setAnchorElx2(event.currentTarget);
  };

  const handleClosex2 = () => {
    setAnchorElx2(null);
  };
  const openx2 = Boolean(anchorElx2);
  const idx2 = openx2 ? "simple-popover" : undefined;
  const [year1, setyear1] = useState("");
  const [year2, setyear2] = useState("");
  const [wrongcallageaddname, setWrongcallageaddname] = useState(false);
  const [descriptioncallageadd, setdescriptioncallageadd] = useState("");
  const [callagename, setCallagename] = useState("");
  const [themecolr, setthemecolr] = useState("#000000");
  const [checkedtocompany, setCheckedtocompany] = useState(true);
  const [types, setTypes] = useState();
  const [startdateerr, setStartdateerr] = useState(false);

  const [openx, setOpenx] = React.useState(false);
  const handleOpenx = () => setOpenx(true);

  const handleClosex = () => setOpenx(false);

  const handleCreate = () => {
    if (!callagename) {
      setWrongcallageaddname(true);
      return;
    }
    if (!year1) {
      setStartdateerr(true);
      return;
    }

    const formdata = new FormData();
    formdata.append("title", callagename);
    formdata.append("description", descriptioncallageadd);
    formdata.append("startDate", year1);
    year2 && formdata.append("endDate", year2);
    formdata.append("colour", themecolr);
    formdata.append("types", types);

    formdata.append("eventId", data?.eventId);
    formdata.append("allDay", checkedtocompany);

    axios
      .post(`${API_HOST}/eventCalender/editEvent`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        setRecall(!recall);
        handleClose();
      });
  };

  const handledelete = () => {
    const formdata = new FormData();

    formdata.append("eventId", data?.eventId ? data?.eventId : data?.actionId);

    axios
      .post(`${API_HOST}/eventCalender/removeEvent`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        setRecall(!recall);
        handleClose2();
      });
  };

  useEffect(() => {
    setCallagename(data?.title);
    setyear2(data?.endDate?.slice(0, 16));
    setyear1(data?.startDate?.slice(0, 16));
    setCheckedtocompany(data?.allDay);
    setthemecolr(data?.colour);
    setdescriptioncallageadd(data?.description);
    setTypes(data?.types);
  }, [data]);

  const navigate = useNavigate();

  return (
    <div
      style={{ maxWidth: width > 700 ? "40vw" : "96vw" }}
      className="eventboxbottom"
    >
      <div className="eventboxdate">
        <div className="eventboxdatedate">
          {" "}
          <span>
            <FiberManualRecordIcon
              style={{
                fontSize: width > 700 ? "0.81vw" : "2.4vw",
                marginRight: width > 700 ? "0.5vw" : "1vw",
                color: "#0052cc",
              }}
            />
          </span>
          {data?.startDate && (
            <span>
              {new Date(data?.startDate).getDate()}/
              {new Date(data?.startDate).getMonth() + 1}/
              {new Date(data?.startDate).getFullYear()}
            </span>
          )}
          {data?.endDate && (
            <span>
              - {new Date(data?.endDate).getDate()}/
              {new Date(data?.endDate).getMonth() + 1}/
              {new Date(data?.endDate).getFullYear()}
            </span>
          )}
          {data?.types && (
            <span
              style={{
                padding: "0.25vw 0.51vw",
                marginLeft: "0.5vw",
                background: data?.colour + "40",
                color: data?.colour,
              }}
              className="tagblue"
            >
              {" "}
              {data?.types}
            </span>
          )}
        </div>
        <div className="eventboxdatedescription">
          {data?.allDay
            ? "Full day Post / Event "
            : data?.startDate && (
                <span>
                  {"From " +
                    data?.startDate?.slice(11, 16).toString().padStart(2, "0")}
                  {data?.endDate &&
                    " To " +
                      data?.endDate?.slice(11, 16).toString().padStart(2, "0")}
                </span>
              )}
        </div>
      </div>
      <div className="eventboxtitle">
        <div className="eventboxtitlename">{data?.title}</div>
        <div
          style={{
            width: width > 700 ? "8vw" : "18vw",
            display: "flex",
            justifyContent: "space-around",
          }}
          className="eventboxpreiew-button"
        >
          {user?.userName === data?.userName ? (
            <RemoveRedEyeOutlinedIcon
              onClick={() => {
                if (types === "Order") {
                  navigate(`/dashboard/direct-sell-details/${data?.actionId}`);
                }
                if (types === "Booking") {
                  navigate(`/dashboard/BookingDetail/${data?.actionId}`);
                }
                if (types === "Proposal") {
                  navigate(`/dashboard/applieddetail/${data?.actionId}`);
                }
              }}
              style={{
                width: width > 700 ? "1.5vw" : "4vw",
                cursor: "pointer",
                marginRight: "0.75vw",
              }}
              src={img2}
              alt=""
            />
          ) : (
            <div
              style={{
                width: width > 700 ? "1.5vw" : "4vw",
                cursor: "pointer",
                marginRight: "0.75vw",
              }}
            ></div>
          )}
          {user?.userName === data?.userName ? (
            <img
              onClick={() => {
                handleOpen();
              }}
              style={{
                width: width > 700 ? "1.5vw" : "4vw",
                cursor: "pointer",
                marginRight: "0.75vw",
              }}
              src={img2}
              alt=""
            />
          ) : (
            <div
              style={{
                width: width > 700 ? "1.5vw" : "4vw",
                cursor: "pointer",
                marginRight: "0.75vw",
              }}
            ></div>
          )}

          <img
            style={{
              width: width > 700 ? "1.5vw" : "4vw",
              cursor: "pointer",
              marginRight: "0.75vw",
              filter: "brightness(0)",
            }}
            onClick={() => {
              handleOpenx();
            }}
            src={img3}
            alt=""
          />

          {user?.userName === data?.userName ? (
            <img
              onClick={handleOpen2}
              style={{
                width: width > 700 ? "1.5vw" : "4vw",
                cursor: "pointer",
              }}
              src={img4}
              alt=""
            />
          ) : (
            <div
              style={{
                width: width > 700 ? "1.5vw" : "4vw",
                cursor: "pointer",
              }}
            ></div>
          )}
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={width > 700 ? style : style1}>
          <div className="profiletitleandmenunav">
            <div className="profiledetailstitle">Edit Post</div>
            <div className="profiledetailnavmanu">
              <div>
                <CloseIcon
                  onClick={handleClose}
                  style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
                />
              </div>
            </div>
          </div>
          <hr style={{ color: "#000000" }} />

          <div style={{ left: "0vw", width: "100%" }} className="loginfield">
            <TextField
              error={!wrongcallageaddname ? false : true}
              value={callagename}
              id="outlined-basic"
              label="Post Title"
              variant="outlined"
              style={{ width: "100%" }}
              InputLabelProps={{
                style: {
                  fontSize: width > 700 ? "1vw" : "3vw",
                  fontFamily: "Poppins",
                  fontStyle: "500",
                  fontWeight: "500",
                  color: !wrongcallageaddname ? "black" : "#dc3545",
                },
              }}
              onChange={(e) => {
                setWrongcallageaddname(false);
                setCallagename(e.target.value);
              }}
              inputProps={{ className: classes.input }}
            />
          </div>
          <div
            style={{
              left: "0vw",
              width: width > 700 ? "100%" : "99%",
              marginLeft: "0%",
            }}
            className="loginfield"
          >
            <TextField
              id="outlined-basic"
              label="Post Type"
              variant="outlined"
              value={types}
              style={{ width: "100%" }}
              InputLabelProps={{
                style: {
                  fontSize: width > 700 ? "1vw" : "3vw",
                  fontFamily: "Poppins",
                  fontStyle: "500",
                  fontWeight: "500",
                  color: "black",
                  paddingRight: "2vw",
                },
              }}
              inputProps={{ className: classes.input }}
              onChange={(e) => {
                setTypes(e.target.value);
              }}
            />
          </div>

          <div style={{ display: "flex", width: "100%" }}>
            <div style={{ width: "50%" }}>
              <div className="jobpodtedfieldtitile">Start Date</div>
              <div style={{ display: "flex", width: "100%" }}>
                <div
                  style={{
                    left: "0vw",
                    width: "100%",
                    margin: "0.3vw 0",
                  }}
                  className="loginfield"
                  // onClick={handleClickx}
                >
                  <div className="" style={{ width: "100%" }}>
                    <div style={{ width: "100%" }}>
                      <div className="jobpostfieldinputbox">
                        <input
                          style={{ width: "100%" }}
                          type="datetime-local"
                          className="input-homejobformdate"
                          name=""
                          id=""
                          value={year1}
                          maxlength="4"
                          onChange={(e) => {
                            setyear1(e.target.value);
                            setStartdateerr(false);
                            console.log(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ width: "50%" }}>
              <div className="jobpodtedfieldtitile">End Date (Excluded)</div>
              <div style={{ display: "flex", width: "100%" }}>
                <div
                  style={{
                    left: "0vw",
                    width: "100%",
                    margin: "0.3vw 0",
                  }}
                  className="loginfield"
                  // onClick={handleClickx2}
                >
                  <div className="" style={{ width: "100%" }}>
                    <div style={{ width: "100%" }}>
                      <div className="jobpostfieldinputbox">
                        <input
                          style={{ width: "100%" }}
                          type="datetime-local"
                          className="input-homejobformdate"
                          name=""
                          id=""
                          value={year2}
                          maxlength="4"
                          onChange={(e) => {
                            setyear2(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </div>{" "}
            </div>
          </div>
          <div style={{ color: "red" }} className="redp">
            {startdateerr && "Please select start date"}
          </div>
          <label htmlFor="checkbox">
            <input
              type="checkbox"
              onChange={() => {
                setCheckedtocompany(!checkedtocompany);
              }}
              checked={checkedtocompany}
              name=""
              id="checkbox"
            />

            <div
              className=""
              style={{
                fontSize: width > 700 ? "1vw" : "3vw",
                fontFamily: "Poppins",
                fontStyle: "500",
                fontWeight: "500",
                color: "#000000",
                display: "inline",
                margin: "0 1vw",
              }}
            >
              set Post / Event for Full Day
            </div>
          </label>
          <div style={{ left: "0vw", width: "100%" }} className="loginfield">
            <TextField
              id="outlined-basic"
              label="Description (Optional)"
              variant="outlined"
              value={descriptioncallageadd}
              multiline
              rows="1"
              style={{ width: "100%" }}
              InputLabelProps={{
                style: {
                  fontSize: width > 700 ? "1vw" : "3vw",
                  fontFamily: "Poppins",
                  fontStyle: "500",
                  fontWeight: "500",
                  color: "black",
                },
              }}
              inputProps={{ className: classes.input }}
              onChange={(e) => {
                setdescriptioncallageadd(e.target.value);
              }}
            />
          </div>
          <div
            style={{
              width: width > 700 ? "100%" : "100%",
              border: "1px solid black",
              borderRadius: "5px",
              height: width > 700 ? "3vw" : "9vw",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: width > 700 ? "0.5vw" : "1.5vw",
              marginLeft: "0",
              left: "0",
              marginTop: "1.5vw",
            }}
            className="loginfield"
          >
            <div className="jobpodtedfieldtitile">
              {themecolr ? themecolr : "Choose a Theme color"}
            </div>
            <input
              id="outlined-basic"
              label="Choose a Theme color "
              variant="outlined"
              type="color"
              value={themecolr}
              style={{
                width: width > 700 ? "2vw" : "7vw",
                height: width > 700 ? "2vw" : "7vw",
              }}
              onChange={(e) => {
                console.log(e.target.value);
                setthemecolr(e.target.value);
              }}
            />
          </div>

          <div style={{ marginTop: "0.31vw" }} className="handlemoreaboutskill">
            <div
              style={{
                background: "white",
                color: "black",
                cursor: "pointer",
              }}
              className="handlecirclieaboutsave"
              onClick={handleClose}
            >
              Cancel
            </div>
            <div
              style={{ cursor: "pointer" }}
              className="handlecirclieaboutsave"
              onClick={handleCreate}
            >
              Update
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={width > 700 ? style : style1}>
          <div className="profiletitleandmenunav">
            <div className="profiledetailstitle">Delete Your Event</div>
            <div className="profiledetailnavmanu">
              <div>
                <CloseIcon
                  onClick={handleClose2}
                  style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
                />
              </div>
            </div>
          </div>
          <hr style={{ color: "#000000" }} />

          <div
            style={{
              marginBottom: "0.5vw",
              marginTop: "1.3vw",
              fontSize: width > 700 ? "1.2vw" : "3.2vw",
            }}
            className="jobpodtedfieldtitile"
          >
            Are you sure want to delete this event
          </div>

          <div style={{ marginTop: "0.31vw" }} className="handlemoreaboutskill">
            <div
              style={{
                background: "white",
                color: "black",
                cursor: "pointer",
              }}
              className="handlecirclieaboutsave"
              onClick={handleClose2}
            >
              Cancel
            </div>
            <div
              style={{ cursor: "pointer", background: "red" }}
              className="handlecirclieaboutsave"
              onClick={handledelete}
            >
              Delete
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openx}
        onClose={handleClosex}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={width > 700 ? style : style1}>
          <div
            style={{
              padding: width > 700 ? "0vw" : "1vw",
              width: "100%",
              height: "100%",
              maxHeight: "90vh",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              className="appliedjobformtitleflex"
            >
              <div>Post Detail</div>
              <div>
                <CloseIcon
                  onClick={handleClosex}
                  style={{
                    fontSize: width > 700 ? "1.5vw" : "4vw",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
            <hr style={{ height: "0.1vw" }} />
            <div
              style={{ margin: "1vw", fontSize: width > 700 ? "" : "2.5vw" }}
              className="activejobpistbudgetbox"
            >
              <div>
                Title <br />{" "}
                <span style={{ cursor: "pointer" }}>{data?.title}</span>
              </div>
              <div>
                Type/ Theme Colour <br />{" "}
                <span style={{ cursor: "pointer", color: data?.colour }}>
                  {data?.types && (
                    <span
                      style={{
                        padding: "0.25vw 0.51vw",
                        marginRight: "0.5vw",
                        background: data?.colour + "40",
                        color: data?.colour,
                      }}
                      className="tagblue"
                    >
                      {" "}
                      {data?.types}
                    </span>
                  )}
                  {data?.colour}
                </span>
              </div>
              <div style={{ marginRight: "1vw" }}>
                Start On <br />{" "}
                {data?.startDate && (
                  <span>
                    {new Date(data?.startDate).getDate()}/
                    {new Date(data?.startDate).getMonth() + 1}/
                    {new Date(data?.startDate).getFullYear()}
                    {!data?.allDay &&
                      ", " +
                        data?.startDate
                          ?.slice(11, 16)
                          .toString()
                          .padStart(2, "0")}
                  </span>
                )}
              </div>
              <div style={{ marginRight: "1vw" }}>
                End On <br />{" "}
                {data?.endDate && (
                  <span>
                    {new Date(data?.endDate).getDate()}/
                    {new Date(data?.endDate).getMonth() + 1}/
                    {new Date(data?.endDate).getFullYear()}
                    {!data?.allDay &&
                      ", " +
                        data?.endDate
                          ?.slice(11, 16)
                          .toString()
                          .padStart(2, "0")}
                  </span>
                )}
              </div>
            </div>

            {data?.description && (
              <div
                style={{ color: "#0052CC", margin: "0.5vw", fontWeight: "500" }}
              >
                Description
              </div>
            )}
            <div
              style={{
                width: "100%",
                margin: "0.5vw 1vw",
                fontSize: width > 700 ? "0.85vw" : "2.5vw",
                marginBottom: "0.0vw",
                marginRight: "2vw",
                color: "black",
              }}
              className="dashboardtitilemainparabid"
            >
              <div
                className=""
                style={{
                  color: "black",
                  fontSize: width > 700 ? "0.85vw" : "2.5vw",
                }}
                dangerouslySetInnerHTML={{
                  __html: data?.description,
                }}
              ></div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
