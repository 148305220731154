import React from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import imgxx from "../../../assets/Success stories Definition/checkmark (1).svg";
import { Link } from "react-router-dom";
import img4 from "../../../assets/My profile – 28/pexels-stefan-stefancik-91227.png";
import { useNavigate } from "react-router";
import img46 from "../../../assets/My profile – 28/Landing page – 19.png";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

export default function ProfileCatelogdetails({ width, catalogue }) {
  const navigate = useNavigate();

  // console.log("user", user);
  const myStr = catalogue?.websiteName;
  const contentNameRoute = myStr
    .replaceAll(" ", "-")
    .replaceAll("/", "_")
    .replaceAll("?", "_");

  return (
    <div>
      <div
        style={{ margin: width > 700 ? "1.5vw 0.5vw" : "2.5vw" }}
        className="pcatelogbox"
      >
        <div
          style={{
            background: `url('${
              catalogue?.bannerImage
                ? catalogue?.bannerImage?.trimStart()?.replaceAll(" ", "+")
                : img46
            }') center center / cover no-repeat`,
          }}
          className="pcatelogimg"
        >
          <div className="pcatelogimg2">
            <div className="porfolioprofilemenu">
              <RemoveRedEyeIcon
                onClick={() =>
                  catalogue?.websiteName
                    ? navigate(`/${contentNameRoute}/${catalogue?.websiteId}`)
                    : navigate(`/-/${catalogue?.websiteId}`)
                }
                style={{
                  margin: "0 0.2vw",
                  width: width > 700 ? "3vw " : "9vw",
                  height: width > 700 ? "3vw " : "9vw",
                  borderRadius: "50%",
                  cursor: "pointer",
                  objectFit: "cover",
                  backgroundColor: "white",
                  padding: "3px",
                }}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              margin: "0.5vw",

              width: "fit-content",
            }}
            className="pcatelogperson"
          >
            <div className="pcatelogpersonimg">
              <img src={catalogue?.websiteLogo || img4} alt="" />
            </div>
            <div
              style={{ width: "fit-content" }}
              className="pcatelogpersonname"
            >
              <div className="pcatelogpersonname1">
                <span
                  style={{
                    display: "flex",
                    marginTop: "0.1vw",
                  }}
                >
                  {catalogue?.websiteName?.slice(0, 18)}
                </span>

                <div
                  style={{
                    fontSize: width > 700 ? "0.85vw" : "2.5vw",
                    display: "flex",
                    marginTop: "0.1vw",
                  }}
                >
                  {catalogue?.userId?.verifiedByAdmin === true && (
                    <img
                      style={{
                        width: width > 700 ? "1.1vw" : "3.1vw",
                        marginRight: "0.3vw",
                      }}
                      src={imgxx}
                      alt=""
                    />
                  )}
                  {catalogue?.userId?.fullName}
                  {catalogue?.userId?.certifiedByAdmin === true && (
                    <span style={{ display: "flex" }}>
                      (
                      <WorkspacePremiumIcon
                        style={{
                          width: width > 700 ? "1.1vw" : "3.1vw",
                          height: width > 700 ? "1.1vw" : "3.1vw",
                          margin: "0vw",
                          color: "#0052cc",
                        }}
                        alt=""
                      />
                      )
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div style={{ width: "fit-content" }}>
            <div
              style={{
                margin: width > 700 ? "0.4vw 0.5vw" : "1vw 2vw",
                marginLeft: "0vw",
                maxWidth: width > 700 ? "10vw" : "30vw",
                marginBottom: "0.2vw",
                width: "fit-content",
                padding: width > 700 ? "0.3vw 0.6vw" : "1vw 1.5vw",
              }}
              className="tagblue"
            >
              {catalogue?.category?.category}
            </div>
            <div></div>
          </div>
        </div>

        <div
          style={{
            color: "black",
            margin: "0 1vw",
            height: width > 700 ? "5vw" : "12vw",
            lineHeight: width > 700 ? "1.3vw" : "3.8vw",
            width: "90%",
          }}
          className="pcatelog-title"
        >
          {catalogue?.bannerTitle?.slice(0, 90)}
        </div>
        <hr style={{ padding: "0vw", margin: "0.3vw" }} />
        <div style={{ margin: "0.51vw 0.2vw" }} className="pcatelogdate">
          <div>
            <span>
              <LocationOnIcon
                style={{
                  fontSize: width > 700 ? "1.2vw" : "3.5vw",
                  color: "#0052cc",
                }}
              />
            </span>
            {catalogue?.companyAddress
              ? catalogue?.companyAddress?.slice(0, 30)
              : "Remote"}
          </div>
          <div>
            <span>
              <Link
                to={`/dashboard/my-website/${contentNameRoute}/${catalogue?.websiteId}`}
              >
                <span
                  className="tagblue"
                  style={{
                    margin: "0.0vw 0.2vw",

                    width: "fit-content",
                    padding: "0.2vw 0.6vw",
                    color: "white",
                  }}
                >
                  Rs. {catalogue?.amount ? catalogue?.amount : "---"}
                </span>
              </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
