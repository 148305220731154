import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { DeleteOutlineOutlined } from "@mui/icons-material";
import { TextField } from "@mui/material";
import axios from "axios";

import API_HOST from "../../../env";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
};
const useStyles = makeStyles((theme) => ({
  input: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "0.91vw",
    color: "#263238",
    border: "yellow !important",
  },
}));
export default function ProfileotherDetails({
  other,
  width,
  setcertificated,
  user,
}) {
  const [year1, setyear1] = useState(other?.fromDate);
  const classes = useStyles();

  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [year2, setyear2] = useState(other?.toDate);

  const handleeducationdelete = () => {
    const data = {
      otherExperinceId: other?.otherExperinceId,
    };
    axios
      .post(`${API_HOST}/users/removeOthers`, data, {
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((response) => {
        if (user) {
          axios
            .get(
              `${API_HOST}/users/viewOthers?pageSize=50&pageNumber=1&user_id=${user?._id}`
            )
            .then((res) => {
              setcertificated(res?.data?.success?.data);
            });
        }

        handleClose();
      });
  };
  const [descriptioncallageadd, setdescriptioncallageadd] = useState(
    other?.description
  );
  const [callagename, setCallagename] = useState(other?.title);

  const handleeditcallage = () => {
    const formdata = {
      description: descriptioncallageadd,
      title: callagename,
      fromDate: year1,
      toDate: year2,
      otherExperinceId: other?.otherExperinceId,
    };
    axios
      .post(`${API_HOST}/users/editOthers`, formdata, {
        headers: {
          // "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        if (user) {
          axios
            .get(
              `${API_HOST}/users/viewOthers?pageSize=50&pageNumber=1&user_id=${user?._id}`
            )
            .then((res) => {
              setcertificated(res?.data?.success?.data);
            });
        }
        handleClose1();
      });
  };

  const [wrongcallageaddname, setWrongcallageaddname] = useState(false);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "1vw 2vw",
        }}
      >
        <div>
          <div className="pworkexperincebox">
            {/* <div className="pworkexperinceboximg">
              <img src={img22} alt="" />
            </div> */}
            <div className="pworkexperinceboxtext">
              <div className="pworkexperincebox-name">{other?.title}</div>
              <div
                style={{ margin: "0.1vw" }}
                className="pworkexperincebox-name1"
              >
                {other?.fromDate} /
                {other?.workingCurrently ? "present" : other?.toDate}
              </div>
              <div
                style={{
                  fontSize: width > 700 ? "0.851vw" : "2.3vw",
                  color: "black",
                  opacity: "0.7",
                  margin: "0",
                  padding: "0",
                }}
                className="profileuserfirstonedata"
              >
                {other?.description}
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", width: width > 700 ? "5vw" : "10vw" }}>
          <EditIcon
            onClick={handleOpen1}
            style={{
              fontSize: width > 700 ? "1.5vw" : "4vw",
              color: "gray",
              margin: "0 0.61vw",
              cursor: "pointer",
            }}
          />
          <DeleteOutlineOutlined
            onClick={handleOpen}
            style={{
              cursor: "pointer",
              fontSize: width > 700 ? "1.5vw" : "4vw",
              color: "gray",
              margin: "0 0.61vw",
            }}
          />
        </div>
        <Modal
          open={open1}
          onClose={handleClose1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={width > 700 ? style : style1}>
            <div className="profiletitleandmenunav">
              <div className="profiledetailstitle">Edit Others</div>
              <div className="profiledetailnavmanu">
                <div>
                  <CloseIcon
                    onClick={handleClose1}
                    style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
                  />
                </div>
              </div>
            </div>
            <div style={{ left: "0vw", width: "100%" }} className="loginfield">
              <TextField
                error={!wrongcallageaddname ? false : true}
                value={callagename}
                id="outlined-basic"
                label="Title"
                variant="outlined"
                style={{ width: "100%" }}
                InputLabelProps={{
                  style: {
                    fontSize: width > 700 ? "1vw" : "3vw",
                    fontFamily: "Poppins",
                    fontStyle: "500",
                    fontWeight: "500",
                    color: !wrongcallageaddname ? "black" : "#dc3545",
                  },
                }}
                onChange={(e) => {
                  setWrongcallageaddname(false);
                  setCallagename(e.target.value);
                }}
                inputProps={{ className: classes.input }}
              />
            </div>
            <div className="jobpodtedfieldtitile">From</div>
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  left: "0vw",
                  width: "50%",
                  margin: "0.3vw 0",
                }}
                className="loginfield"
                // onClick={handleClickx}
              >
                <div className="" style={{ width: "100%" }}>
                  <div style={{ width: "100%" }}>
                    <div className="jobpostfieldinputbox">
                      <input
                        style={{ width: "100%" }}
                        type="date"
                        className="input-homejobformdate"
                        name=""
                        id=""
                        value={year1}
                        // min={disablePastDate()}
                        max={"2025-12-31"}
                        maxlength="4"
                        onChange={(e) => {
                          setyear1(e.target.value);
                        }}
                        // onChange={(e) => {
                        //   setDatestart(e.target.value);
                        // }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="jobpodtedfieldtitile">To</div>
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  left: "0vw",
                  width: "50%",
                  margin: "0.3vw 0",
                }}
                className="loginfield"
              >
                <div className="" style={{ width: "100%" }}>
                  <div style={{ width: "100%" }}>
                    <div className="jobpostfieldinputbox">
                      <input
                        style={{ width: "100%" }}
                        type="date"
                        className="input-homejobformdate"
                        name=""
                        id=""
                        value={year2}
                        // min={disablePastDate()}
                        max={"2025-12-31"}
                        maxlength="4"
                        onChange={(e) => {
                          setyear2(e.target.value);
                        }}
                        // onChange={(e) => {
                        //   setDatestart(e.target.value);
                        // }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div style={{ left: "0vw", width: "100%" }} className="loginfield">
              <TextField
                id="outlined-basic"
                label="Description (Optional)"
                variant="outlined"
                value={descriptioncallageadd}
                multiline
                rows="6"
                style={{ width: "100%" }}
                InputLabelProps={{
                  style: {
                    fontSize: width > 700 ? "1vw" : "3vw",
                    fontFamily: "Poppins",
                    fontStyle: "500",
                    fontWeight: "500",
                    color: "black",
                  },
                }}
                inputProps={{ className: classes.input }}
                onChange={(e) => {
                  setdescriptioncallageadd(e.target.value);
                }}
              />
            </div>
            {/* <hr style={{ color: "#000000" }} /> */}
            <div
              style={{ marginTop: "0.31vw" }}
              className="handlemoreaboutskill"
            >
              <div
                style={{
                  background: "white",
                  color: "black",
                  cursor: "pointer",
                }}
                className="handlecirclieaboutsave"
                onClick={handleClose1}
              >
                Cancel
              </div>
              <div
                style={{ cursor: "pointer" }}
                className="handlecirclieaboutsave"
                onClick={handleeditcallage}
              >
                SAVE
              </div>
            </div>
          </Box>
        </Modal>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={width > 700 ? style : style1}>
            <div className="profiletitleandmenunav">
              <div className="profiledetailstitle"> Delete Others</div>
              <div className="profiledetailnavmanu">
                <div>
                  <CloseIcon
                    onClick={handleClose}
                    style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
                  />
                </div>
              </div>
            </div>
            <hr style={{ color: "#000000" }} />
            <div
              style={{
                color: "gray",
                fontSize: width > 700 ? "1vw" : "3vw",
                fontWeight: "300",
              }}
              className="profiledetailstitle"
            >
              The action will delete '{other?.title }' From all of your profiles.
            </div>
            <div
              style={{
                color: "gray",
                fontSize: width > 700 ? "1vw" : "3vw",
                fontWeight: "400",
                marginBottom: "5vw",
              }}
              className="profiledetailstitle"
            >
              Are you sure you want to delete this ?
            </div>
            <hr style={{ color: "#000000" }} />
            <div
              style={{ marginTop: "0.31vw" }}
              className="handlemoreaboutskill"
            >
              <div
                style={{
                  background: "white",
                  color: "black",
                  cursor: "pointer",
                }}
                className="handlecirclieaboutsave"
                onClick={handleClose}
              >
                Cancel
              </div>
              <div
                style={{ cursor: "pointer" ,background:"red" }}
                className="handlecirclieaboutsave"
                onClick={handleeducationdelete}
              >
                Delete
              </div>
            </div>
          </Box>
        </Modal>
      </div>
      <hr
        style={{
          width: "70vw",
          margin: "0 4vw",
          marginBottom: "1vw",
          color: "#000000",
        }}
      />
    </>
  );
}
