import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

export default function CustomPagination({count, currentPage, onChange}) {
  return (
    <Stack spacing={10}>
      <Pagination 
        count={count} 
        page={currentPage}
        onChange={onChange} 
        sx={{
            '& .MuiPaginationItem-root': {
              fontSize: '1.15rem',
              margin: '0 10px', // Adjust the margin as needed
            },
            '& .MuiPaginationItem-previousNext svg': {
            fontSize: '2.2rem',
          },
          }}
      />
    </Stack>
  );
}
