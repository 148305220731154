import React from "react";
import img from "../../../assets/Success stories/zan-BKyvWG7D6QA-unsplash.png";
import imgxx from "../../../assets/Success stories Definition/checkmark (1).svg";

import { useNavigate } from "react-router";

export default function BlogCards({ item, width, dragging }) {
  const navigate = useNavigate();
  const myStr = item?.contentName;
  const contentNameRoute = myStr
    .replaceAll(" ", "-")
    .replaceAll("/", "-")
    .replaceAll("?", "-");
  return (
    <a
      onClick={(e) => dragging && e.preventDefault()}
      href={`/blogs/${contentNameRoute}/${item?.contentId}`}
      //   target={`/blogs/${contentNameRoute}/${item?.contentId}`}
      rel="noreferrer"
      draggable={false}
      style={{
        color: "inherit !important",
        outline: "none !important",
        outlineColor: "none !important",
        border: "none !important",
        textDecoration: "none !important",
      }}
    >
       <div
          style={{ cursor: "pointer" ,margin: width>700? '1vw 0.7vw':"3vw 0.75vw" ,width: width>700? "25.5vw":"92vw" }}
          onClick={() => navigate(`/blogs/${contentNameRoute}/${item?.contentId}`)}
          className="boxoffilterbox"
        >
        <div className="imgboxfilteredblog w-100">
          <img src={item?.icon?.length > 0 ? item?.icon[0] : img} alt="" className="w-100" />
        </div>
        <div className="mt-3">
          <div className="d-flex justify-content-between mb-2">
            <div className="tagblogblue " style={{width:"55%"}}>
              <span className="tagblogblack ellipsis" style={{display:"block", width:"100%"}}>
                {item?.contentName}
              </span>
            </div>
            <div className="tagblue " style={{textAlign:"right", whiteSpace:"nowrap"}}>  {item?.category} </div>
          </div>
          
          <div className="toc mt-2">
            <div>Table of Content</div>
            <ul className="mt-2" style={{height:width > 700 ? "6vw" :"19.5vw", overflow:"hidden"}}>
              {item?.toC?.length > 0 && item?.toC?.map((tocItem) => {
                return <li>{tocItem?.heading}</li>
              })}  
            </ul>
          </div>
          <hr style={{ padding: "0vw", margin: "0.3vw" }}></hr>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
                style={{
                  fontSize: width > 700 ? "0.85vw" : "2.5vw",
                  display: "flex",
                  marginTop: "0.1vw",
                  alignItems: "center",
                  fontWeight:"600"
                }}
              >
                {item?.author}
              </div>
            <div className="view">views - {item?.views}</div>
          </div>
        </div>
      </div>
    </a>
  );
}
