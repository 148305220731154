import React, { useEffect, useState } from "react";

import { TextField } from "@mui/material";

import { makeStyles } from "@material-ui/core";
import img from "../../../assets/Web 1280 – 14/Icon.svg";
import img1 from "../../../assets/Web 1280 – 14/Group 9831.svg";
import img21 from "../../../assets/My profile – 28/Component 85 – 16 (1).svg";
import DoneIcon from "@mui/icons-material/Done";
import axios from "axios";
import API_HOST from "../../../env";

import imageCompression from "browser-image-compression";
import { useNavigate, useParams } from "react-router";

const useStyles = makeStyles((theme) => ({
  select: {
    height: "2.5vw",
    width: "100%",
    marginTop: "0.2vw",
    padding: "1vw",
    marginLeft: "0vw",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "1vw",
    lineHeight: "120%",
    color: "#FFFFFF",
  },
  select2: {
    height: "1vw",
    width: "100%",
    marginTop: "0.1vw",
    padding: "0.9vw 0.5vw",
    marginLeft: "0vw",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "0.51vw",
    lineHeight: "100%",
    color: "#FFFFFF",
  },
  select3: {
    height: "1vw",
    width: "100%",
    marginTop: "0.1vw",
    padding: "0vw 0vw",
    marginLeft: "0vw",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "0.51vw",
    lineHeight: "100%",
    color: "#FFFFFF",
    position: "relative",
    top: "0.81vw",
    left: "0.2vw",
  },
  icon: {
    fill: "white",
  },
}));

export default function EditPurchase({ width }) {
  const { id } = useParams();
  const classes = useStyles();
  const [checkone, setCheckone] = useState(false);
  const [checkone1, setCheckone1] = useState(false);
  const [arrayoffiles, setArrayoffiles] = useState([]);
  const [cgst, setCgst] = useState("");
  const [sgst, setsgst] = useState("");
  const [igst, setIgst] = useState("");
  const [ItemName, setItemName] = useState("");
  const [Date, setDate] = useState();
  const [totalAmount, setTotalAmount] = useState("");

  const upadteimage = (img) => {
    const formdata = new FormData();
    formdata.append("fileName", img);
    formdata.append("folderName", "expenditiureObjects");
    axios
      ?.post(`${API_HOST}/contentManagement/tableContent`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        setArrayoffiles([
          ...arrayoffiles,
          { file: res.data?.success?.data?.file },
        ]);
      });
  };

  async function handleImageUpload(event) {
    const imageFile = event.target.files[0];
    console.log(imageFile?.type?.includes("image"));

    if (imageFile?.type?.includes("image")) {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(imageFile, options);
        console.log(
          "compressedFile instanceof Blob",
          compressedFile instanceof Blob
        ); // true
        console.log(
          `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
        ); // smaller than maxSizeMB

        //   await uploadToServer(compressedFile); // write your own logic
        await upadteimage(
          new File([compressedFile], `${compressedFile?.name}`)
        );
      } catch (error) {
        console.log(error);
      }
    } else {
      if (imageFile.size < 1024 * 1024) {
        upadteimage(imageFile);
      }
    }
  }

  const [ErrorCheck, setErrorCheck] = useState(false);
  const [ErrorCheck2, setErrorCheck2] = useState(false);

  const handlePostJOb = () => {
    if (!(checkone && checkone1)) {
      if (!checkone) {
        setErrorCheck(true);
      }
      if (!checkone1) {
        setErrorCheck2(true);
      }
      return false;
    }

    const formdata = new FormData();

    formdata.append("files", JSON.stringify(arrayoffiles));
    formdata.append("expenditiureId", id);

    formdata.append("date", Date);
    formdata.append("name", ItemName);
    formdata.append("cgst", cgst ? cgst : 0);
    formdata.append("sgst", sgst ? sgst : 0);
    formdata.append("igst", igst ? igst : 0);
    formdata.append("totalAmount", totalAmount);

    axios
      .post(`${API_HOST}/expenditure/editPurchase`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        navigate("/dashboard/expenditure");
      })
      .catch((err) => {
        setErroeshow(true);
      });
  };
  const [erroeshow, setErroeshow] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${API_HOST}/expenditure/viewById?expenditiureId=${id}`, {
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        setDate(res.data.success.data?.date.slice(0, 10));
        setItemName(res.data.success.data?.name);
        setsgst(res.data.success.data?.sgst);
        setCgst(res.data.success.data?.cgst);
        setIgst(res.data.success.data?.igst);
        setTotalAmount(res.data.success.data?.totalAmount);
        setArrayoffiles(res?.data?.success?.data?.files);
      });
  }, [id]);

  return (
    <div
      style={{
        justifyContent: "center",
        width: width > 700 ? "80vw" : "100vw",
        paddingLeft: width > 700 ? "3vw" : "0vw",
        height: width > 700 ? "calc(100vh - 4vw)" : "calc(100vh - 14vw)",
      }}
      className="home-postjob-container ScrollTable"
    >
      <div
        style={{
          overflowX: "hidden",
          paddingBottom: "3vw",
          width: width > 700 ? "80vw" : "94vw",
          margin: "2vw",
          paddingTop: width > 700 ? "1vw" : "3vw",

          height: "fit-content",
        }}
        className="homepostjob-right "
      >
        <div className="jobpostedformheading">Edit Purchase</div>
        <div style={{ width: "100%" }}>
          <div
            style={{
              left: "0vw",
              width: width > 700 ? "94%" : "100% ",
              marginLeft: "0%",
            }}
            className="loginfield"
          >
            <TextField
              id="outlined-basic"
              label="Enter Item Name"
              variant="outlined"
              value={ItemName}
              style={{ width: "100%" }}
              InputLabelProps={{
                style: {
                  fontSize: width > 700 ? "1vw" : "3vw",
                  fontFamily: "Poppins",
                  fontStyle: "500",
                  fontWeight: "500",
                  color: "black",
                },
              }}
              inputProps={{ className: classes.input }}
              onChange={(e) => {
                setItemName(e.target.value);
              }}
            />
          </div>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: width > 700 ? "97%" : "100%",
              flexDirection: width > 700 ? "row" : "column",
            }}
          >
            <div style={{ width: width > 700 ? "50%" : "100%" }}>
              <div
                style={{
                  left: "0vw",
                  width: width > 700 ? "94%" : "100% ",
                  marginLeft: "0%",
                }}
                className="loginfield"
              >
                <TextField
                  id="outlined-basic"
                  label="CGST (Rs)"
                  variant="outlined"
                  type="number"
                  value={cgst}
                  style={{ width: "100%" }}
                  InputLabelProps={{
                    style: {
                      fontSize: width > 700 ? "1vw" : "3vw",
                      fontFamily: "Poppins",
                      fontStyle: "500",
                      fontWeight: "500",
                      color: "black",
                    },
                  }}
                  inputProps={{ className: classes.input }}
                  onChange={(e) => {
                    setCgst(e.target.value);
                  }}
                />
              </div>
            </div>

            <div style={{ width: width > 700 ? "50%" : "100%" }}>
              <div
                style={{
                  left: "0vw",
                  width: width > 700 ? "94%" : "100% ",
                  marginLeft: "0%",
                }}
                className="loginfield"
              >
                <TextField
                  id="outlined-basic"
                  label="SGST (Rs)"
                  variant="outlined"
                  type="number"
                  value={sgst}
                  style={{ width: "100%" }}
                  InputLabelProps={{
                    style: {
                      fontSize: width > 700 ? "1vw" : "3vw",
                      fontFamily: "Poppins",
                      fontStyle: "500",
                      fontWeight: "500",
                      color: "black",
                    },
                  }}
                  inputProps={{ className: classes.input }}
                  onChange={(e) => {
                    setsgst(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>{" "}
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: width > 700 ? "97%" : "100%",
              flexDirection: width > 700 ? "row" : "column",
            }}
          >
            <div style={{ width: width > 700 ? "50%" : "100%" }}>
              <div
                style={{
                  left: "0vw",
                  width: width > 700 ? "94%" : "100% ",
                  marginLeft: "0%",
                }}
                className="loginfield"
              >
                <TextField
                  id="outlined-basic"
                  label="IGST (Rs)"
                  variant="outlined"
                  type="number"
                  value={igst}
                  style={{ width: "100%" }}
                  InputLabelProps={{
                    style: {
                      fontSize: width > 700 ? "1vw" : "3vw",
                      fontFamily: "Poppins",
                      fontStyle: "500",
                      fontWeight: "500",
                      color: "black",
                    },
                  }}
                  inputProps={{ className: classes.input }}
                  onChange={(e) => {
                    setIgst(e.target.value);
                  }}
                />
              </div>
            </div>

            <div style={{ width: width > 700 ? "50%" : "100%" }}>
              <div
                style={{
                  left: "0vw",
                  width: width > 700 ? "94%" : "100% ",
                  marginLeft: "0%",
                }}
                className="loginfield"
              >
                <TextField
                  id="outlined-basic"
                  label="Total Amount (Rs)"
                  variant="outlined"
                  type="number"
                  value={totalAmount}
                  style={{ width: "100%" }}
                  InputLabelProps={{
                    style: {
                      fontSize: width > 700 ? "1vw" : "3vw",
                      fontFamily: "Poppins",
                      fontStyle: "500",
                      fontWeight: "500",
                      color: "black",
                    },
                  }}
                  inputProps={{ className: classes.input }}
                  onChange={(e) => {
                    setTotalAmount(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>{" "}
        <div style={{ display: "flex", alignItems: "center", width: "97%" }}>
          <div style={{ width: "50%" }}>
            <div className="jobpodtedfieldtitile">Date</div>
            <div className="jobpostfieldinputbox">
              <input
                style={{ width: "100%" }}
                type="date"
                className="input-homejobformdate"
                name=""
                id=""
                value={Date}
                maxlength="4"
                onChange={(e) => {
                  setDate(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="jobpodtedfieldtitile">Upload Bill / Invoice </div>
        <div
          style={{
            background: "white",
            padding: "0.51vw",
            marginTop: "0vw",
            paddingRight: "3vw",
          }}
        >
          <div className="inputfilebox">
            <div>
              <label htmlFor="inputctaelogfile">
                <div className="inputicon">
                  <img src={img} alt="" />
                </div>
                <div className="inputcateaddformfile">
                  Drag and Drop ,Browse to upload
                </div>
                <input
                  type="file"
                  id="inputctaelogfile"
                  onChange={(e) => {
                    handleImageUpload(e);
                  }}
                  hidden
                />
              </label>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            fontSize: width > 700 ? "0.8vw" : "2.7vw",
            paddingRight: "4.5vw",
            textAlign: "right",
          }}
        >
          Please upload file having extensions .jpeg/ .jpg/ .png only.
          <br />
          Image should be less then 512 kb.
        </div>
        <div
          className={arrayoffiles?.length > 0 ? "inputfilesshowncatebox" : ""}
        >
          {arrayoffiles?.length > 0 &&
            arrayoffiles?.map((file, index) => {
              return (
                <div className="inputfilesshowncatboxsingle">
                  <div className="inputfilesshowncatboxsingleimg">
                    <img src={img1} alt="" />
                  </div>
                  <div className="fileselctednamecate">
                    {file?.file?.split(".com/")[1]}
                  </div>
                  <div className="inputfilesshowncatboxsingleimg">
                    <img
                      style={{
                        width: width > 700 ? "1.5vw" : "4.5vw",
                        marginLeft: "1vw",
                        cursor: "pointer",
                      }}
                      src={img21}
                      alt=""
                      onClick={() => {
                        setArrayoffiles([
                          ...arrayoffiles.slice(0, index),
                          ...arrayoffiles.slice(index + 1, arrayoffiles.length),
                        ]);
                      }}
                    />
                  </div>
                </div>
              );
            })}
        </div>
        <div className="checktemandc">
          <div
            className="checkbox"
            onClick={() => {
              setCheckone(!checkone);

              setErrorCheck(false);
            }}
            required="required"
          >
            {checkone ? (
              <DoneIcon
                style={{
                  fontSize: width > 700 ? "0.81vw" : "3.7vw",
                  color: "blueviolet",
                  fontWeight: "600",
                }}
              />
            ) : (
              ""
            )}{" "}
          </div>
          <div className="checktermandconditiontext">
            I agree the{" "}
            <span
              style={{ cursor: "pointer" }}
              onClick={() => window.open("/termsandconditions", "_blank")}
            >
              Terms and Condition *
            </span>
          </div>
        </div>
        <span
          style={{ color: "red", fontSize: width > 700 ? "0.9vw" : "2.7vw" }}
        >
          {ErrorCheck ? <span>Please Click on Terms and Condition</span> : ""}
        </span>
        <div className="checktemandc">
          <div
            className="checkbox"
            onClick={() => {
              setCheckone1(!checkone1);

              setErrorCheck2(false);
            }}
          >
            {checkone1 ? (
              <DoneIcon
                style={{
                  fontSize: width > 700 ? "0.81vw" : "3.7vw",
                  color: "blueviolet",
                  fontWeight: "600",
                }}
              />
            ) : (
              ""
            )}{" "}
          </div>
          <div className="checktermandconditiontext">
            I agree the{" "}
            <span
              onClick={() => window.open("/privacypolicy", "_blank")}
              style={{ cursor: "pointer" }}
            >
              Privacy Policy *
            </span>
          </div>
        </div>
        <span style={{ color: "red", fontSize: width > 700 ? "1vw" : "3vw" }}>
          {ErrorCheck2 ? <span>Please Click on Privacy Policy *</span> : ""}
        </span>
        <span style={{ color: "red", fontSize: width > 700 ? "1vw" : "3vw" }}>
          {erroeshow ? <span>Please Fill all the necessary fields *</span> : ""}
        </span>
        <div className="homejobbuttons">
          <button
            style={{ background: "white" }}
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </button>

          <button
            // disabled={checkone && checkone1 ? false : true}
            style={{ color: "white" }}
            onClick={handlePostJOb}
          >
            Submit
          </button>
        </div>
      </div>{" "}
    </div>
  );
}
