import React, { useState } from "react";
import "./Homebanner.css";
import "../../DashbaordSidebarComponents/ADDJobpage/addpost.css";
import CloseIcon from "@mui/icons-material/Close";

import img51 from "../../../assets/Web 1280 – 14/Group 10219.svg";
import { TextField } from "@mui/material";
import img4 from "../../../assets/Web 1280 – 14/Ellipse 1375.svg";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { makeStyles } from "@material-ui/core";
import Box from "@mui/material/Box";
import imgxxx from "../../../assets/jobhome/Group 8797.svg";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import img2 from "../../../assets/file.svg";
import img3 from "../../../assets/close.svg";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import Homejobconfirm from "./Homejobconfirm";
import cuntrycide from "../../../helper/c";
import axios from "axios";
import API_HOST from "../../../env";
import imageCompression from "browser-image-compression";
const useStyles = makeStyles((theme) => ({
  select: {
    height: "2.5vw",
    width: "100%",
    marginTop: "0.2vw",
    padding: "1vw",
    marginLeft: "0vw",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "1vw",
    lineHeight: "120%",

    color: "#FFFFFF",
  },
  select2: {
    height: "1vw",
    width: "11vw",
    marginTop: "0.1vw",
    padding: "0.9vw 0.5vw",
    marginLeft: "0vw",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "0.51vw",
    lineHeight: "100%",
    color: "#FFFFFF",
  },
  select3: {
    height: "1vw",
    width: "9vw",
    marginTop: "0.1vw",
    padding: "0vw 0vw",
    marginLeft: "0vw",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "0.51vw",
    lineHeight: "100%",
    color: "#FFFFFF",
    position: "relative",
    top: "0.81vw",
    left: "0.2vw",
  },
  select4: {
    height: "8.1vw",
    width: "100%",
    marginTop: "0.3vw",
    padding: "1vw",
    marginLeft: "0vw",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "1vw",
    lineHeight: "120%",

    color: "#FFFFFF",
  },
  select5: {
    height: "8vw",
    width: "30vw",
    marginTop: "0.1vw",
    padding: "0.9vw 0.5vw",
    marginLeft: "0vw",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "0.51vw",
    lineHeight: "100%",
    color: "#FFFFFF",
  },
  select6: {
    height: "7vw",
    width: "25vw",
    marginTop: "0.1vw",
    padding: "0vw 0vw",
    marginLeft: "0vw",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "0.51vw",
    lineHeight: "100%",
    color: "#FFFFFF",
    position: "relative",
    top: "0.81vw",
    left: "0.2vw",
  },
  icon: {
    fill: "white",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  height: "50vw",
};

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  textAlign: "center",
  p: 1,
};

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1.5,
  pr: 1,
};

export default function HomePostjob({
  width,
  handleClose,
  setSelectedCategory,
  setSelectedCategoryid,
  categoryinfo,
}) {
  const [open121, setOpen121] = React.useState(false);
  const handleClose121 = () => {
    setOpen121(false);
  };
  const handleToggle121 = () => {
    setOpen121(!open121);
  };
  const classes = useStyles();

  const [age2, setAge2] = React.useState(98);

  const handleChange2x = (event) => {
    setAge2(event.target.value);
  };

  const [countryside, setCountryside] = useState(cuntrycide);
  const [mobilecuntry, setMobilecuntry] = useState(1);

  const [arrayoffiles, setArrayoffiles] = useState([]);

  const [openx, setOpenx] = React.useState(false);
  const handleOpenx = () => setOpenx(true);
  const handleClosex = () => setOpenx(false);

  const [cateaddcheckbox1, setCateaddcheckbox1] = useState(true);
  /* VALIDATION FUNCTIONALITY */
  const initialValues = {
    name: "",
    title: "",
    desc: "",
    mobile: "",
    email: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});

  const handleChangeFormVal = (e) => {
    setFormErrors("");
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    // const phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    const phoneno = /^[0-9]{10}$/;
    if (!values.name) {
      errors.name = "Username is required!";
    }
    if (!values.title) {
      errors.title = "Title is required!";
    }
    if (!values.desc) {
      errors.desc = "Description is required!";
    }
    if (!phoneno.test(values.mobile)) {
      errors.mobile =
        "Required 10 digits mobile number, match requested format!!";
    }
    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }
    return errors;
  };

  const [minBudegt, setMinBudegt] = useState();

  const [datestart, setDatestart] = useState();
  const [dateend, setDateend] = useState();

  const [countrycode, setCountrycode] = useState("+91");
  const [validateMobile, setValidateMobile] = useState("");
  const [validateEmail, setValidateEmail] = useState("");
  const [checkedtocompany, setCheckedtocompany] = useState(false);
  const [checkedtocompany_2, setCheckedtocompany_2] = useState(false);
  const [checkboxValidaData, setcheckboxValidaData] = useState("");
  const [checkboxValidaData_2, setcheckboxValidaData_2] = useState("");
  const [locationerror, setLocationerror] = useState("");

  const handlePostJOb = () => {
    if (checkedtocompany === false) {
      setcheckboxValidaData("Please Select the Terms & Condition");
      return false;
    }
    if (checkedtocompany_2 === false) {
      setcheckboxValidaData_2("Please Select the Privacy Policy");
      return false;
    }

    setValidateMobile("");
    setValidateEmail("");
    if (Object.getOwnPropertyNames(validate(formValues)).length !== 0) {
      setFormErrors(validate(formValues));
      return false;
    }
    if (Object.getOwnPropertyNames(validate(formValues)).length === 0) {
      setFormErrors(validate(formValues));
    }
    if (!studyset && cateaddcheckbox1) {
      setLocationerror("Select Location");
      return false;
    }

    const formdata = new FormData();

    formdata.append("fullName", formValues.name);
    formdata.append("workTitle", formValues.title);
    arrayoffiles?.map((data) => {
      formdata.append("fileName", data);
    });
    formdata.append("shortDescription", formValues.desc);
    formdata.append("jobPostingDate", datestart);
    formdata.append("terminationDate", dateend);
    formdata.append("category", setSelectedCategoryid);

    formdata.append("maximuBudget", minBudegt);

    formdata.append("contactNo", countrycode + formValues.mobile);
    formdata.append("emailId", formValues.email);

    formdata.append("remote", !cateaddcheckbox1);

    if (cateaddcheckbox1) {
      formdata.append("onSite", studyset);
    }

    skillset?.length > 0 &&
      skillset?.map((data, index) => {
        formdata.append(`skill${index + 1}`, data);
      });

    handleToggle121();
    axios
      .post(`${API_HOST}/jobPost/newUserJobPost`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setTrackingid(res.data.success?.data?.trackingId);
        setWorktitile(res.data.success?.data?.workTitle);
        setPassword(res.data.success?.userDetails?.password);
        setUsername(res.data.success?.userDetails?.userName);
        handleClose121();
        handleOpenx();
      })
      .catch((err) => {
        handleClose121();
        if (
          err.response.data.fails.message.code ===
          "auth/phone-number-already-exists"
        ) {
          setValidateMobile(err.response.data.fails.message.message);
        }

        if (
          err.response.data.fails.message.code === "auth/email-already-exists"
        ) {
          setValidateEmail(err.response.data.fails.message.message);
        }
        setErroeshow(true);
      });
  };
  const [erroeshow, setErroeshow] = useState(false);

  const [worktitile, setWorktitile] = useState("");

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [trackingid, setTrackingid] = useState("");

  const [studyset, setstudyset] = useState("");

  const [skillset, setskillset] = useState([]);

  const [setSelectedCategory1, setSetSelectedCategory1] = useState("");
  const [istdropdown, setIstdropdown] = useState(false);

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  async function handleImageUpload(event) {
    const imageFile = event.target.files[0];
    console.log(imageFile?.type?.includes("image"));

    if (imageFile?.type?.includes("image")) {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(imageFile, options);
        console.log(
          "compressedFile instanceof Blob",
          compressedFile instanceof Blob
        ); // true
        console.log(
          `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
        ); // smaller than maxSizeMB

        //   await uploadToServer(compressedFile); // write your own logic
        await setArrayoffiles([
          ...arrayoffiles,
          new File([compressedFile], `${compressedFile?.name}`),
        ]);
      } catch (error) {
        console.log(error);
      }
    } else {
      if (imageFile.size < 1024 * 1024) {
        setArrayoffiles([...arrayoffiles, imageFile]);
      }
    }
  }

  return (
    <div style={{ height: "none" }} className="home-postjob-container">
      {width > 700 && (
        <div className="homepostjob-left">
          <div className="homejobpostimgsec">
            <img
              src={imgxxx}
              style={{ marginBottom: "2vw", marginTop: "2vw" }}
              alt=""
            />
          </div>
          <div className="homepastjobtrustbox">
            <div className="homepostjobtrust">Trusted by Tool44</div>
          </div>
        </div>
      )}
      <div
        style={{ overflowX: "hidden", padding: "1vw", paddingLeft: "3vw" }}
        className="homepostjob-right"
      >
        <div className="jobpostedformheading">Job Posted Form Posted</div>

        <div>
          <div className="jobpodtedfieldtitile">Full Name *</div>
          <div className="jobpostfieldinputbox">
            <input
              type="text"
              name="name"
              value={formValues.name}
              onChange={handleChangeFormVal}
            />
          </div>
          <p style={{ color: "red" }}>{formErrors.name}</p>
          <div className="jobpodtedfieldtitile">Job Category</div>
          <div className="jobpostfieldinputbox">
            <input type="text" value={setSelectedCategory} disabled />
          </div>

          <div className="jobpodtedfieldtitile">Job Title *</div>
          <div className="jobpostfieldinputbox">
            <input
              type="text"
              name="title"
              value={formValues.title}
              onChange={handleChangeFormVal}
            />
          </div>
          <p style={{ color: "red" }}>{formErrors.title}</p>
          <div className="jobpodtedfieldtitile">Budget (Rs.) *</div>
          <div className="jobpostfieldinputbox">
            <input
              type="number"
              name="budget"
              value={minBudegt}
              onChange={(e) => {
                setMinBudegt(e.target.value);
              }}
            />
          </div>
          <div className="jobpodtedfieldtitile">Description</div>
          <div className="jobpostfieldinputbox">
            <textarea
              type="text"
              name="desc"
              value={formValues.desc}
              onChange={handleChangeFormVal}
            />
          </div>
          <p style={{ color: "red" }}>{formErrors.desc}</p>
          <div
            style={{
              marginBottom: "0.5vw",
              marginTop: "1.3vw",
              fontSize: width > 700 ? "1.2vw" : "3vw",
            }}
            className="jobpodtedfieldtitile"
          >
            Skills Required
          </div>
          <div
            style={{
              marginRight: width > 700 ? "2vw" : "0vw",
              height: "fit-content",
              padding: "0.3vw",
            }}
            className="boxofskillsp"
            onClick={() => {
              setIstdropdown(false);
            }}
          >
            <div className="savedskillpopupcardp">
              {skillset?.slice(0, 10)?.map((skill, index) => {
                return (
                  <div
                    style={{
                      borderRadius: "1vw",
                      padding: width > 700 ? "0.5vw 1vw" : "2vw 1.5vw",
                      margin: width > 700 ? "0.31vw 0.5vw" : "1.5vw 1.5vw",
                      height: width > 700 ? "2.1vw" : "6vw",
                      fontSize: width > 700 ? "0.9vw" : "3vw",
                    }}
                    className="savedskillwrapsinglep"
                  >
                    {skill}
                    <div
                      onClick={() => {
                        setskillset([
                          ...skillset.slice(0, index),
                          ...skillset.slice(index + 1, skillset.length),
                        ]);
                      }}
                    >
                      <CloseIcon
                        style={{
                          fontSize: width > 700 ? "1.4vw" : "5vw",
                          marginLeft: width > 700 ? "0.61vw" : "1.5vw",
                          cursor: "pointer",
                        }}
                      />
                    </div>{" "}
                  </div>
                );
              })}
              <div style={{ height: width > 700 ? "3vw" : "9vw" }}>
                {skillset?.length < 10 ? (
                  <input
                    style={{
                      border: "none",
                      outline: "none",
                      background: "white",
                      height: width > 700 ? "3vw" : "9vw",
                      padding: width > 700 ? "0.5vw" : "1vw",
                      width: "fit-content",
                      minWidth: "5vw",
                      fontSize: width > 700 ? "1.1vw" : "3.3vw",
                    }}
                    type="text"
                    placeholder="type here...."
                    value={setSelectedCategory1}
                    onChange={(e) => {
                      setSetSelectedCategory1(e.target.value);
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        if (skillset.indexOf(setSelectedCategory1) < 0) {
                          setskillset(
                            [...skillset, setSelectedCategory1].slice(0, 10)
                          );
                        }
                        setSetSelectedCategory1("");
                      }
                    }}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div className="jobpodtedfieldtitile">Date *</div>
          <div className="homjobpost-popbudegt">
            <div className="min-maxhomejob">Start</div>
            <div className="">
              <Box
                sx={{
                  background: "white",
                  border: "1px solid #7070705b",
                  height: width > 700 ? "2.5vw" : "8vw",
                  width: width > 700 ? "11vw" : "30vw",
                  borderRadius: "5px",
                  margin: "0.5vw 1.5vw",
                  padding: "0 0.2vw",
                }}
                className="setting-toggler"
              >
                <input
                  style={{
                    lineHeight: width > 700 ? "2vw" : "5vw",
                    width: "100%",
                  }}
                  type="date"
                  placeholder="dd/mm/yyyy"
                  className="input-homejobformdate"
                  name=""
                  id=""
                  min={disablePastDate()}
                  max={"2025-12-31"}
                  maxlength="4"
                  onChange={(e) => {
                    setDatestart(e.target.value);
                  }}
                />
              </Box>
            </div>
            <div style={{ marginLeft: "1vw" }} className="min-maxhomejob">
              End
            </div>
            <div className="">
              <Box
                sx={{
                  background: "white",
                  border: "1px solid #7070705b",
                  height: width > 700 ? "2.5vw" : "8vw",
                  width: width > 700 ? "11vw" : "30vw",
                  borderRadius: "5px",
                  margin: "0.5vw 1.5vw",
                  padding: " 0 0.2vw",
                }}
                className="setting-toggler"
              >
                <input
                  style={{
                    lineHeight: width > 700 ? "2vw" : "5vw",
                    width: "100%",
                  }}
                  type="date"
                  placeholder="dd/mm/yyyy"
                  className="input-homejobformdate"
                  name=""
                  id=""
                  min={disablePastDate()}
                  max={"2025-12-31"}
                  maxlength="4"
                  onChange={(e) => {
                    setDateend(e.target.value);
                  }}
                />
              </Box>
            </div>
          </div>
          <div className="jobpodtedfieldtitile">Image/Documents </div>
          <div className="fileinputbox1">
            <label htmlFor="fileupload">
              <div>
                <FileUploadIcon
                  style={{
                    fontSize: width > 700 ? "3vw" : "9vw",
                    color: "#8f92a173",
                    cursor: "pointer",
                  }}
                />
              </div>
              <input
                type="file"
                name="fileupload"
                id="fileupload"
                hidden
                onChange={(e) => {
                  handleImageUpload(e);
                }}
              />
              <div
                className="min-maxhomejob"
                style={{ textAlign: "center", width: "100%" }}
              >
                Drag and Drop or Browse to upload
              </div>
            </label>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              fontSize: width > 700 ? "0.8vw" : "2.8vw",
              paddingRight: "2vw",
              textAlign: "right",
            }}
          >
            Please upload file having extensions .jpeg/ .jpg/ .png only.
            <br />
            Image should be less then 512 kb.
          </div>
          <div
            className={arrayoffiles?.length > 0 ? "inputfilesshowncatebox" : ""}
          >
            {arrayoffiles?.length > 0 &&
              arrayoffiles?.map((file, index) => {
                return (
                  <div className="inputfilesshowncatboxsingle">
                    <div className="inputfilesshowncatboxsingleimg">
                      <img src={img2} alt="" />
                    </div>
                    <div className="fileselctednamecate">{file?.name}</div>
                    <div className="inputfilesshowncatboxsingleimg">
                      <img
                        style={{
                          width: "1.5vw",
                          marginLeft: "1vw",
                          cursor: "pointer",
                        }}
                        src={img3}
                        alt=""
                        onClick={() => {
                          setArrayoffiles([
                            ...arrayoffiles.slice(0, index),
                            ...arrayoffiles.slice(
                              index + 1,
                              arrayoffiles.length
                            ),
                          ]);
                        }}
                      />
                    </div>
                  </div>
                );
              })}
          </div>

          <div className="jobpodtedfieldtitile mt-2">Mobile *</div>
          <div className="jobpostfieldinputbox">
            <div style={{ width: "0.1vw", zIndex: "3" }}>
              <div className="okmobilejobform" onClick={handleOpenx}>
                {mobilecuntry}
              </div>
              <Box
                sx={{
                  background: "white",
                  border: "1px solid #7070705b",
                  height: width > 700 ? "3vw" : "9vw",
                  width: width > 700 ? "10vw" : "26vw",
                  borderRadius: "5px",
                  margin: "0vw 0vw",
                  position: "relative",
                  bottom: width > 700 ? "0.71vw" : "0.2vw",
                }}
                className="setting-toggler"
              >
                <FormControl variant="standard" fullWidth>
                  <Select
                    className={width > 700 ? classes.select3 : classes.select6}
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={age2}
                    defaultValue={age2}
                    disableUnderline
                    inputProps={{
                      classes: {
                        icon: classes.icon,
                      },
                    }}
                    onChange={handleChange2x}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          bgcolor: "white",
                          width: "9vw",
                          maxHeight: width > 700 ? "18vw" : "50vw",
                          "& .MuiMenuItem-root": {
                            padding: "0.1vw 0.1vw",
                            width: "100%",
                            height: "2vw",
                            fontFamily: "Poppins",
                            fontStyle: "normal",
                            fontWeight: "500",
                            fontSize: width > 700 ? "0.85vw" : "2.8vw",
                            lineHeight: width > 700 ? "1.2vw" : "3.5vw",
                            minHeight: width > 700 ? "1.5vw" : "5vw",
                            color: "#6b6b6b",
                          },
                        },
                      },
                    }}
                  >
                    <div style={{ width: width > 700 ? "9vw" : "25vw" }}>
                      <input
                        type="number"
                        style={{ width: width > 700 ? "9vw" : "25vw" }}
                        onChange={(e) => {
                          setCountryside(
                            cuntrycide.filter((x) =>
                              x?.dial_code.includes(e.target.value)
                            )
                          );
                          console.log(
                            cuntrycide.filter((x) =>
                              x?.dial_code.includes(e.target.value)
                            )
                          );
                        }}
                      />
                    </div>

                    {countryside &&
                      countryside?.map((code, index) => {
                        return (
                          <MenuItem
                            onClick={() => {
                              setCountrycode(code?.dial_code);
                            }}
                            value={index + 1}
                          >
                            <div
                              style={{
                                textAlign: "center",
                                marginTop: "0.5vw",
                              }}
                            >
                              {code?.dial_code} {code?.code}
                            </div>
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <input
              type="number"
              style={{ paddingLeft: width > 700 ? "11vw" : "27vw" }}
              name="mobile"
              value={formValues.mobile}
              onChange={handleChangeFormVal}
            />
          </div>
          <p style={{ color: "red", fontSize: "1vw" }}>
            {validateMobile ? validateMobile : ""}
          </p>
          <p style={{ color: "red", fontSize: "1vw" }}>{formErrors.mobile}</p>
          <div className="jobpodtedfieldtitile">Email *</div>
          <div className="jobpostfieldinputbox">
            <input
              type="text"
              style={{ width: "29.2vw" }}
              name="email"
              value={formValues.email}
              onChange={handleChangeFormVal}
            />
          </div>
          <p style={{ color: "red", fontSize: "1vw" }}>
            {validateEmail ? validateEmail : ""}
          </p>
          <p style={{ color: "red" }}>{formErrors.email}</p>
          <div className="jobpodtedfieldtitile">Location *</div>
          <div
            style={{ display: "flex", alignItems: "center", fontWeight: "500" }}
          >
            <div
              onClick={() => setCateaddcheckbox1(true)}
              className="checkboxfromcate"
              style={{ marginRight: "2vw" }}
            >
              <div>
                {cateaddcheckbox1 ? <img src={img51} /> : <img src={img4} />}{" "}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "500",
                }}
              >
                On Site
              </div>
            </div>
            <div
              onClick={() => {
                setCateaddcheckbox1(false);
              }}
              className="checkboxfromcate"
            >
              <div>
                {cateaddcheckbox1 ? <img src={img4} /> : <img src={img51} />}{" "}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "500",
                }}
              >
                Remote
              </div>
            </div>
          </div>
          {cateaddcheckbox1 ? (
            <div>
              <div
                style={{
                  left: "0vw",
                  padding: "0vw",
                  width: width > 700 ? "95%" : "100%",
                  marginLeft: "0",
                  marginTop: "2vw",
                }}
                className="loginfield"
              >
                <TextField
                  id="outlined-basic"
                  label="Location"
                  variant="outlined"
                  value={studyset}
                  style={{ width: "100%", padding: "0vw 1omportant" }}
                  InputLabelProps={{
                    style: {
                      fontSize: width > 700 ? "1vw" : "3vw",
                      fontFamily: "Poppins",
                      fontStyle: "500",
                      fontWeight: "500",
                      color: "black",
                    },
                  }}
                  inputProps={{ className: classes.input }}
                  onChange={(e) => {
                    setstudyset(e.target.value);
                  }}
                />
              </div>
              <p style={{ color: "red" }}>{locationerror}</p>
            </div>
          ) : (
            ""
          )}
          <div className="checktemandc">
            <input
              type="checkbox"
              onChange={() => {
                setCheckedtocompany(!checkedtocompany);
                setcheckboxValidaData("");
              }}
              name=""
              id="checkbox"
            />
            <div className="checktermandconditiontext">
              I agree the{" "}
              <span
                style={{ cursor: "pointer" }}
                onClick={() => window.open("/termsandconditions", "_blank")}
              >
                Terms and Condition *
              </span>
            </div>
          </div>
          <span
            style={{ color: "red", fontSize: width > 700 ? "0.9vw" : "2.7vw" }}
          >
            {/* {ErrorCheck ? <span>Please Click on Terms and Condition</span> : ""} */}
            {checkboxValidaData}
          </span>
          <div className="checktemandc">
            <input
              type="checkbox"
              onChange={() => {
                setCheckedtocompany_2(!checkedtocompany_2);
                setcheckboxValidaData_2("");
              }}
              name=""
              id="checkbox"
            />
            <div className="checktermandconditiontext">
              I agree the{" "}
              <span
                style={{ cursor: "pointer" }}
                onClick={() => window.open("/privacypolicy", "_blank")}
              >
                Privacy Policy *
              </span>
            </div>
          </div>
          <span
            style={{ color: "red", fontSize: width > 700 ? "0.9vw" : "2.7vw" }}
          >
            {/* {ErrorCheck2 ? <span>Please Click on Privacy Policy *</span> : ""} */}
            {checkboxValidaData_2}
          </span>
          <div className="homejobbuttons">
            <button style={{ background: "white" }} onClick={handleClose}>
              Cancel
            </button>

            <button
              // disabled={checkone && checkone1 ? false : true}
              style={{ color: "white" }}
              onClick={handlePostJOb}
            >
              Submit
            </button>
            <Modal
              //   hideBackdrop
              open={openx}
              onClose={handleClosex}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={width > 700 ? style : style2}>
                <Homejobconfirm
                  width={width}
                  handleClose={handleClose}
                  handleClosex={handleClosex}
                  workTitle={worktitile}
                  trackingId={trackingid}
                  password={password}
                  username={username}
                  email={formValues.email}
                />
              </Box>
            </Modal>
          </div>
          {categoryinfo && (
            <div className="Categoryinfo">
              <div dangerouslySetInnerHTML={{ __html: categoryinfo }}></div>
            </div>
          )}
        </div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open121}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </div>
  );
}
