import React from "react";
import { useCallback,  useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import img1 from "../../../assets/Web 1280 – 14/Group 9831.svg";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";
import API_HOST from "../../../env";
import { toPng } from "html-to-image";
import { useDispatch } from "react-redux";
import { userActions } from "../../../store/userSlice";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import { TextField } from "@mui/material";
import { makeStyles } from "@material-ui/core";
const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "530px",
  bgcolor: "background.paper",
  maxHeight: "90vh",
  overflow: "scroll",
  boxShadow: 24,
  p: "15px",
};

const style3 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "96vw",
  maxHeight: "90vh",
  overflow: "scroll",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
};
const useStyles = makeStyles((theme) => ({
  input: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "0.91vw",
    color: "#263238",
    border: "yellow !important",
    // padding: "1vw",
  },
}));
export default function ProfileCatelogdetails({
  width,
  catalogue,
  companydata,
}) {
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const classes = useStyles();
  const [indexofimage, setIndexofimage] = useState(0);
  const [Postcolor, setPostcolor] = useState(catalogue?.colour);
  const ref = useRef();

  const dispatch = useDispatch();

  const onButtonClick = useCallback(
    (name) => {
      if (ref.current === null) {
        return;
      }
      toPng(ref.current, { cacheBust: true })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = `${name}.png`;
          link.href = dataUrl;
          link.click();
          handleClose1();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [ref]
  );

  const exportPDFWithComponent = useCallback(
    (name) => {
      axios
        .get(`${API_HOST}/posts/downloadPost?postId=${catalogue?.postId}`, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        })
        .then(() => {
          onButtonClick(name);
        })
        .catch((err) => {
          if (err?.response?.data?.fails?.message === "Insufficient balance") {
            console.log(err?.response?.data?.fails?.message);
            dispatch(userActions.openwalletForm());
          }
        });
    },
    [ref]
  );
  const [companyid, setcompanyid] = useState();
  const [anchorEl1x, setAnchorEl1x] = React.useState(null);
  const handleClick1x = (event) => {
    setAnchorEl1x(event.currentTarget);
  };

  const handleClose1x = () => {
    setAnchorEl1x(null);
  };

  const open1x = Boolean(anchorEl1x);
  const id1x = open1x ? "simple-popover" : undefined;
  return (
    <div>
      <div
        style={{ margin: width > 700 ? "1.2vw 0.5vw" : "2.5vw" }}
        className="pcatelogbox"
      >
        <div
          style={{
            background: `url('${
              catalogue?.files[0]
                ? catalogue?.files[0]?.trimStart()?.replaceAll(" ", "+")
                : img1
            }') center center / cover no-repeat`,
            height: width > 700 ? "19vw" : "62.5vw",
          }}
          className="pcatelogimg"
        ></div>
        <div
          style={{
            display: "flex",
            height: width > 700 ? "3.5vw" : "11vw",
            overflow: "visible",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <div
            style={{
              margin: width > 700 ? "0.5vw" : "1vw",

              width: "fit-content",
            }}
            className="pcatelogperson"
          >
            <div
              style={{ width: "fit-content", maxWidth: "50vw" }}
              className="pcatelogpersonname"
            >
              <div className="pcatelogpersonname1">
                <span
                  style={{
                    display: "flex",
                    marginTop: "0.2vw",
                  }}
                >
                  {catalogue?.title?.slice(0, 50)}
                </span>
              </div>
            </div>
          </div>
          <div style={{ width: "fit-content" }}>
            <div
              style={{
                margin: width > 700 ? "0.4vw 0.5vw" : "1vw 2vw",
                marginLeft: "0vw",
                maxWidth: width > 700 ? "10vw" : "30vw",
                marginBottom: "0.2vw",
                width: "fit-content",
                padding: width > 700 ? "0.3vw 0.6vw" : "1vw 1.5vw",
              }}
              className="tagblue"
            >
              {catalogue?.typeOfPost}
            </div>
          </div>
        </div>

        <hr style={{ padding: "0vw", margin: "0.3vw" }} />
        <div
          style={{ margin: width > 700 ? "0.51vw 0.2vw" : "2.1vw 0.5vw" }}
          className="pcatelogdate"
        >
          <div>
            <span>
              Date :
              <span
                className="tagblue"
                style={{
                  margin: "0.0vw 0.2vw",

                  width: "fit-content",
                  padding: "0.2vw 0.6vw",
                  color: "white",
                }}
              >
                {catalogue?.startDate && (
                  <span>
                    {new Date(catalogue?.startDate).getDate()}/
                    {new Date(catalogue?.startDate).getMonth() + 1}/
                    {new Date(catalogue?.startDate).getFullYear()}
                  </span>
                )}
              </span>
            </span>
          </div>

          <div>
            <span>
              <span
                onClick={() => {
                  handleOpen1();
                }}
                className="tagblue"
                style={{
                  margin: width > 700 ? "0.0vw 0.2vw" : "1vw",
                  width: "fit-content",
                  padding: width > 700 ? "0.2vw 0.6vw" : "1vw 1.5vw",
                  color: "white",
                }}
              >
                Create Post
              </span>
            </span>
          </div>
        </div>
      </div>

      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={width > 700 ? style2 : style3}>
          <div className="profiletitleandmenunav">
            <div className="profiledetailstitle">Preview & Download Post</div>
            <div className="profiledetailnavmanu">
              <div>
                <CloseIcon
                  onClick={handleClose1}
                  style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
                />
              </div>
            </div>
          </div>
          <hr style={{ color: "#000000" }} />
          <div
            style={{
              height: width > 700 ? "500px" : "250px",
              width: width > 700 ? "500px" : "250px",
              fontFamily: "Arial, Helvetica, sans-serif",
              maxWidth: "92vw",
              margin: "auto",
            }}
          >
            <div
              style={{
                height: width > 700 ? "500px" : "250px",
                width: width > 700 ? "500px" : "250px",
                fontFamily: "Arial, Helvetica, sans-serif",
                maxWidth: "92vw",
                overflowX: "visible",
              }}
              ref={ref}
            >
              <img
                style={{
                  height: width > 700 ? "420px" : "210px",
                  width: width > 700 ? "500px" : "250px",
                  objectFit: "cover",
                  borderRadius: "1px",
                }}
                src={catalogue?.files?.[indexofimage]}
                alt=""
              />
              <div
                style={{
                  background: Postcolor,
                  color: "white",
                  fontWeight: "500",
                  height: width > 700 ? "80px" : "40px",
                  padding: width > 700 ? "5px" : "2.5px",
                  fontFamily: "Arial !important",
                  fontSize: width > 700 ? "25px" : "12.5px",
                  textAlign: "center",
                  width: width > 700 ? "500px" : "250px",
                }}
                className="textimage"
              >
                <div
                  className="textimage"
                  style={{
                    fontSize: width > 700 ? "20px" : "10px",
                    textAlign: "center",
                    lineHeight:
                      width > 700 ? "24px" : width > 700 ? "1vw" : "3vw",
                  }}
                >
                  {companyid?.companyName}
                </div>
                <div
                  className="textimage"
                  style={{
                    fontSize: width > 700 ? "20px" : "10px",
                    textAlign: "center",
                    lineHeight:
                      width > 700 ? "25px" : width > 700 ? "1vw" : "3vw",
                  }}
                >
                  {companyid?.companyTitle}
                </div>
                <div
                  style={{
                    fontSize:
                      width > 700 ? (width > 700 ? "1vw" : "3vw") : "6px",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className="textimage"
                >
                  {companyid && "Contact us -"}
                  {companyid?.mobile} {companyid && ","}
                  {companyid?.emailId}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              left: "0vw",
              width: "100% ",
              marginLeft: "0%",
              marginBottom: width > 700 && "0vw",
            }}
            className="loginfield"
            onClick={handleClick1x}
          >
            <TextField
              id="outlined-basic"
              label="Select Your Company "
              variant="outlined"
              disabled
              value={companyid?.companyName || ""}
              style={{ width: "100%" }}
              InputLabelProps={{
                style: {
                  fontSize: width > 700 ? "1vw" : "3vw",
                  fontFamily: "Poppins",
                  fontStyle: "500",
                  fontWeight: "500",
                  color: "black",
                },
              }}
              inputProps={{ className: classes.input }}
              onChange={(e) => {
                console.log(e.target.value);
              }}
            />
            <span style={{ width: "0.1vw" }}>
              <KeyboardArrowDownOutlined
                style={{
                  fontSize: width > 700 ? "1.5vw" : "5vw",
                  cursor: "pointer",
                  position: "relative",
                  right: width > 700 ? "2vw" : "6vw",
                  top: width > 700 ? "1vw" : "3vw",
                }}
              />
            </span>
          </div>
          <Popover
            id={id1x}
            open={open1x}
            anchorEl={anchorEl1x}
            onClose={handleClose1x}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div
              style={{
                maxHeight: width > 700 ? "18vw" : "40vw",
                overflow: "scroll",
                width: width > 700 ? "33vw" : "94vw",
              }}
            >
              {companydata?.length>0 && companydata?.map((data) => {
                return (
                  <Typography
                    sx={{
                      p: 0.51,
                      pl: 1,
                      ml: 1,
                      width: "100%",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setcompanyid(data);
                      handleClose1x();
                    }}
                  >
                    {data?.companyName}
                  </Typography>
                );
              })}
            </div>
          </Popover>

          <div
            style={{ marginTop: "0.1vw", justifyContent: "space-evenly" }}
            className="handlemoreaboutskill"
          >
            {indexofimage > 0 && (
              <div
                style={{
                  background: "white",
                  color: "#0052cc",
                  border: "1px solid #0052cc",
                  cursor: "pointer",
                }}
                className="handlecirclieaboutsave"
                onClick={() => {
                  setIndexofimage(indexofimage - 1);
                }}
              >
                Prev
              </div>
            )}

            {indexofimage < catalogue?.files?.length - 1 && (
              <div
                style={{
                  background: "white",
                  color: "#0052cc",
                  border: "1px solid #0052cc",
                  cursor: "pointer",
                }}
                className="handlecirclieaboutsave"
                onClick={() => {
                  setIndexofimage(indexofimage + 1);
                }}
              >
                Next
              </div>
            )}

            <label>
              <div
                style={{
                  cursor: "pointer",
                  width: "fit-content",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
                className="handlecirclieaboutsave"
                onClick={() => {}}
              >
                <span>Change colour </span>
              </div>
              <div style={{ width: "0vw", height: "0vw", overflow: "hidden" }}>
                <input
                  type="color"
                  style={{ width: "0vw", height: "0vw" }}
                  value={{ Postcolor }}
                  onChange={(e) => {
                    setPostcolor(e?.target?.value);
                  }}
                />
              </div>
            </label>

            <div
              style={{ cursor: "pointer" }}
              className="handlecirclieaboutsave"
              onClick={() => {
                exportPDFWithComponent(catalogue?.title);
              }}
            >
              Download
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
