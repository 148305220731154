import React from "react";
import "./footer.css";
export default function Footer2({ width }) {
  return (
    <div className="home-footer2">
      {" "}
      © 2023, Autrob Technologies Private Limited . All Rights Reserved
    </div>
  );
}
