import img2 from "../../assets/My profile – 28/Component 71 – 6.svg";
import "./profile.css";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import API_HOST from "../../env";
import axios from "axios";
import { useDispatch } from "react-redux";
import { userActions } from "../../store/userSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "55vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
};

export default function ProfileSkills({ user, width }) {
  const dispatch = useDispatch();
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  const [skillset, setskillset] = useState([]);
  const [skillset1, setskillset1] = useState([]);

  const [skillsettemp, setskillsettemp] = useState([]);


  const [istdropdown, setIstdropdown] = useState(false);
  const [setSelectedCategory, setSetSelectedCategory] = useState("");

  
  useEffect(() => {
    let arr = [];

    user?.noOfSkillSet?.map((skill) => {
      arr = [...arr, skill?.skill];
     
    });
    setskillset(arr);
 
  }, [user]);



 

  const handlePostJOb = () => {
    const data = new FormData();
    data.append("noOfSkillSet", JSON.stringify(skillset));
    data.append("userId", user?.userId);
    axios
      .post(`${API_HOST}/users/updateSkills`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        window.localStorage.setItem(
          "user",
          JSON.stringify({ ...res.data.success.data })
        );

        dispatch(
          userActions.setUser({
            user: { ...res.data.success.data },
          })
        );
        handleClose2();
      })
      .catch((error) => {});
  };

  return (
    <div style={{ height: "fit-content" }} className="profilebadgecontainer">
      <div
        style={{ padding: "2vw", margin: "0" }}
        className="profiletitleandmenunav"
      >
        <div className="profiledetailstitle">Specialized In (Optional)</div>
        <div className="profiledetailnavmanu">
          <img
            onClick={() => {
              setskillsettemp(skillset);
              handleOpen2();
            }}
            style={{ width: width > 700 ? "2.5vw" : "6vw", marginLeft: "0vw" }}
            src={img2}
            alt=""
          />
        </div>

        <Modal
          open={open2}
          onClose={handleClose2}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={width > 700 ? style : style1}>
            <div className="profiletitleandmenunav">
              <div className="profiledetailstitle">Edit Skills</div>
              <div className="profiledetailnavmanu">
                <div>
                  <CloseIcon
                    onClick={handleClose2}
                    style={{
                      fontSize: width > 700 ? "1.5vw" : "4.5vw",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
            </div>
            <hr style={{ color: "#000000" }} />

            <div className="editsillpopupskilltext">
              Keeping your skills up to date helps you get the jobs you want
            </div>

            <div
              style={{
                marginRight: "1vw",
                height: "fit-content",
                minHeight: width > 700 ? "" : "10vw",
                padding: "0.3vw",
              }}
              className="boxofskillsp"
              onClick={() => {
                setIstdropdown(false);
              }}
            >
              <div className="savedskillpopupcardp">
                {skillset?.slice(0, 10)?.map((skill, index) => {
                  return (
                    <div
                      style={{
                        borderRadius: "1vw",
                        padding: width > 700 ? "0.5vw 1vw" : "1vw 2vw",
                        margin: width > 700 ? "0.31vw 0.5vw" : "1vw",
                        height: width > 700 ? "2.1vw" : "7vw",
                        fontSize: width > 700 ? "0.9vw" : "2.7vw",
                      }}
                      className="savedskillwrapsinglep"
                    >
                      {skill}
                      <div
                        onClick={() => {
                          setskillset([
                            ...skillset.slice(0, index),
                            ...skillset.slice(index + 1, skillset.length),
                          ]);
                        }}
                      >
                        <CloseIcon
                          style={{
                            fontSize: width > 700 ? "1.4vw" : "4vw",
                            marginLeft: width > 700 ? "0.61vw" : "1vw",
                            cursor: "pointer",
                          }}
                        />
                      </div>{" "}
                    </div>
                  );
                })}
                <div style={{ height: "3vw" }}>
                  {skillset?.length < 10 ? (
                    <input
                      style={{
                        border: "none",
                        outline: "none",
                        background: "white",
                        height: width > 700 ? "3vw" : "8vw",
                        padding: width > 700 ? "0.5vw" : "1vw",
                        width: "fit-content",
                        minWidth: "5vw",
                        fontSize: width > 700 ? "1.1vw" : "3vw",
                      }}
                      type="text"
                      placeholder="type here...."
                      value={setSelectedCategory}
                      onChange={(e) => {
                        setSetSelectedCategory(e.target.value);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          if (skillset.indexOf(setSelectedCategory) < 0) {
                            setskillset(
                              [...skillset, setSelectedCategory].slice(0, 10)
                            );
                            setSetSelectedCategory("");
                          }
                        }
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <div className="editsillpopupskilltext">Maximum 10 skills </div>
            <div
              style={{ marginTop: "0.31vw" }}
              className="handlemoreaboutskill"
            >
              <div
                style={{
                  background: "white",
                  color: "black",
                  cursor: "pointer",
                }}
                className="handlecirclieaboutsave"
                onClick={handleClose2}
              >
                Cancel
              </div>
              <div
                style={{ cursor: "pointer" }}
                className="handlecirclieaboutsave"
                onClick={() => handlePostJOb()}
              >
                SAVE
              </div>
            </div>
          </Box>
        </Modal>
      </div>

      <div className="pallskillboxes">
        {user?.noOfSkillSet?.map((skill) => {
          return <div className="pskillbox">{skill?.skill}</div>;
        })}
      </div>
    </div>
  );
}
