import React from "react";
import { Link, useNavigate } from "react-router-dom";

import "./404.css";
import Helmet from "react-helmet";
export default function Pagenotfound() {
  const navigate = useNavigate();
  return (
    <section class="page_404">
      <Helmet>
        <title>Page not found</title>
        <meta name="errorpage" content="true" />
        <meta name="errortype" content="404 - Not Found" />
        <meta name="prerender-status-code" content="404" />
      </Helmet>

      <div class="container">
        <div class="row">
          <div class="col-sm-12 ">
            <div class="col-sm-offset-1  text-center">
              <div class="four_zero_four_bg">
                <h1 class="text-center ">404</h1>
              </div>

              <div class="contant_box_404">
                <h3 class="h2">Look like you're lost</h3>

                <p>the page you are looking for not available !</p>

                <p onClick={() => navigate(-1)} class="link_404">
                  Go to Previous Page
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
