import React from "react";
import "./About.css";
import img1 from "../../assets/About/procreator-ux-design-studio-VzJjPuk53sk-unsplash.png";
export default function Aboutabout({ width }) {
  return (
    <div style={{ marginTop: "2vw" }} className="Aboutabout-container">
      <div className="abouttitileheading">About us</div>
      <div
        style={{
          display: "flex",
          alignItems: width > 700 ? "center" : "flex-start",
          flexDirection: width > 700 ? "" : "column",
        }}
      >
        <div style={{ width: width > 700 ? "55%" : "100%" }}>
        <div className="bluetitilesubabout">Who we are</div>
          <div className="blackaboutaboutdata">
            We are full-time freelancers, passionate people working tirelessly
            to make a difference in the lives of people.
          </div>
          <div className="bluetitilesubabout">Our Journey</div>
          <div className="blackaboutaboutdata">
            
Starting and running a business is a challenge for everyone, especially with a lack of knowledge and high costs associated with implementing technological solutions. This realization inspired us to build a platform that offers affordable technological solutions to businesses of all sizes, enabling them to overcome their business challenges. Our goal is to provide business owners with the tools they need to succeed and thrive in today's competitive market.

          </div>
        </div>
        <div className="aboutaboutimg">
          <img src={img1} alt="" />
        </div>
      </div>
    </div>
  );
}
