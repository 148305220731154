import React from "react";
// import BlogBannercarosel from "./BlogBannerCaousel";
import "./Stories.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import BlogBannercarosel from "../../BlogDetails/BlogBannerCaousel";
export default function StoryDetailBanner({ data, width }) {
  const navigate = useNavigate();

  return (
    <div className="Blogdbannercontainer" style={{padding:"2vw 1vw"}}>
      <Helmet>
        <title>{`${data?.metaTitle}`}</title>
        <meta name="description" content={data?.metaDescription} />
        <meta
          property="og:url"
          content={`https://www.44resources.com/blogs/${data?.contentName}`}
        ></meta>
    
        <meta property="og:description" content={data?.metaDescription} />
        <meta property="og:title" content={data?.metaTitle} />
        <meta
          property="og:keywords"
          content={`${data?.metaTitle},${data?.contentName},${data?.slug}`}
        />
        <meta
          property="keywords"
          content={`${data?.metaTitle},${data?.contentName},${data?.slug}`}
        />
      </Helmet>
      <button
        style={{
        //   marginTop: "1vw",
          cursor: "pointer",
          zIndex: "100",
          padding: "0.5vw 0.7vw",
          backgroundColor: "white",
          color: "#000",
          borderRadius: width > 700 ? "0.3vw" : "1vw",
          border: "1px solid #d7d7d7",
        }}
        onClick={() => navigate(-1)}
      >
        <ArrowBackIosNewIcon />
      </button>
      <div style={{ width: "70vw" }} className="blogdbtitile">
        {data?.heading}
      </div>{" "}
      <div>
        <div
          style={{ position: "relative", top: width <= 700 && "3vw" }}
          className="ctatlogcarouseltitle"
        >
          <div className="textofcontainercatalgue">{data?.iconTitle}</div>
        </div>
        <div className="bannerblogimagescaurpsel">
          {data && (
            <div className="catalogimages" style={{width:"100%", height:"30vw"}}>
                <img src={data?.image} className="" style={{height:"30vw"}} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
