import React, { Component } from "react";

import img1 from "../../assets/Untitled design (1)/1.png";
import img3 from "../../assets/Untitled design (1)/3.png";
import img7 from "../../assets/Untitled design (1)/7.png";

export default class Aboutccaresel extends Component {
  render() {
    return (
      <div style={{ width: "100vw", overflow: "hidden" }}>
        {/* <div className="home-catecarousel"> */}
        <div
          style={{
            width: this.props?.width > 700 ? "96vw " : "88vw",
            margin: "auto",
            marginBottom: "2vw",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {this.props.team?.map((team) => {
            return (
              <div>
                <div
                  style={{
                    height: this.props?.width > 700 ? "25vw" : "103vw",
                    width: this.props?.width > 700 ? "17vw" : "89vw",
                    marginRight: this.props?.width > 700 ? "1vw" : "2vw",
                    marginLeft: this.props?.width > 700 ? "1vw" : "0vw",
                  }}
                  className="carouselbox"
                >
                  <div
                    style={{
                      height: this.props?.width > 700 ? "15vw" : "80vw",
                    }}
                    className="hc-cboximg"
                  >
                    <img
                      style={{
                        objectFit: "cover",
                        height: this.props?.width > 700 ? "15vw" : "80vw",
                      }}
                      src={team?.image}
                      alt=""
                    />
                  </div>
                  <div
                    style={{
                      fontSize: this.props?.width > 700 ? "1.3vw" : "4vw",
                      width: "88%",
                    }}
                    className="hc-cboxname"
                  >
                    {team.fullName}
                  </div>
                  <div
                    style={{
                      fontSize: this.props?.width > 700 ? "1.1vw" : "3.5vw",
                      height: "fit-content",
                      width: "90%",
                    }}
                    className="hc-cboxdesc"
                  >
                    {team?.role}
                  </div>
                  <div
                    style={{
                      fontSize: this.props?.width > 700 ? "0.9vw" : "2.7vw",
                      width: "90%",
                    }}
                    className="hc-cboxdesc"
                  >
                    Experience - {team?.experience}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
