import React, { useEffect, useState } from "react";
import img from "../../assets/Success stories/zan-BKyvWG7D6QA-unsplash.png";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useNavigate } from "react-router";
import axios from "axios";
import API_HOST from "../../env";
import img1 from "../../assets/Blog/business (1).svg";
import img2 from "../../assets/Blog/idea (3).svg";
import img3 from "../../assets/Blog/research.svg";
import img4 from "../../assets/Blog/workflow.svg";
import imgxx from "../../assets/Success stories Definition/checkmark (1).svg";

export default function FiterdBlog({ width }) {
  const navigate = useNavigate();
  const [allcategory, setAllcategory] = useState([]);
  const [setSelectedCategory, setSetSelectedCategory] = useState("All");
  const [page, setPage] = useState(1);
  const [SearchBlogchat, setSearchBlogchat] = useState("");
  const [totalcount, setTotalcount] = useState();
  useEffect(() => {
    setPage(1);

    if (setSelectedCategory === "All") {
      axios
        .get(
          `${API_HOST}/contentManagement/viewcontent?pageSize=9&pageNumber=${page}&search=${SearchBlogchat}`
        )
        .then((res) => {
          setAllcategory(res?.data?.success?.data);
          setTotalcount(res?.data?.success?.data?.length);
        });
    } else {
      axios
        .get(
          `${API_HOST}/contentManagement/viewcontent?category=${setSelectedCategory}&pageSize=9&pageNumber=${page}&search=${SearchBlogchat}`
        )
        .then((res) => {
          setAllcategory(res?.data?.success?.data);
          setTotalcount(res?.data?.success?.data?.length);
        });
    }
  }, [setSelectedCategory, SearchBlogchat]);

  useEffect(() => {
    if (setSelectedCategory === "All") {
      axios
        .get(
          `${API_HOST}/contentManagement/viewcontent?pageSize=9&pageNumber=${page}&search=${SearchBlogchat}`
        )
        .then((res) => {
          setAllcategory([...allcategory, ...res?.data?.success?.data]);
          setTotalcount(res?.data?.success?.data?.length);
        });
    } else {
      axios
        .get(
          `${API_HOST}/contentManagement/viewcontent?category=${setSelectedCategory}&pageSize=9&pageNumber=${page}&search=${SearchBlogchat}`
        )
        .then((res) => {
          setAllcategory([...allcategory, ...res?.data?.success?.data]);
          setTotalcount(res?.data?.success?.data?.length);
        });
    }
  }, [page]);

  // ALL CONTENT API CALL

  return (
    <div className="blogbannercontainer">
      <div className="flexofblobluecred">
        <div
          className="bludeblogtag"
          onClick={() => {
            setSetSelectedCategory("Business Ideas");
            setSearchBlogchat("");
          }}
        >
          <div className="blueblogcardimg">
            <img
              src={img2}
              alt=""
              style={{
                width: width > 700 ? "6vw" : "18vw",
                height: width > 700 ? "5vw" : "15vw",
                objectFit: "contain",
                marginTop: "1vw",
              }}
            />
          </div>
          <div className="datatagnameblog">Business Ideas</div>
        </div>
        <div
          className="bludeblogtag"
          onClick={() => {
            setSearchBlogchat("");
            setSetSelectedCategory("Business Plans");
          }}
        >
          <div className="blueblogcardimg">
            <img
              src={img4}
              alt=""
              style={{
                width: width > 700 ? "6vw" : "18vw",
                height: width > 700 ? "5vw" : "15vw",
                objectFit: "contain",
                marginTop: "1vw",
              }}
            />
          </div>
          <div className="datatagnameblog">Business Plans</div>
        </div>
        <div
          className="bludeblogtag"
          onClick={() => {
            setSearchBlogchat("");
            setSetSelectedCategory("Business Problems");
          }}
        >
          <div className="blueblogcardimg">
            <img
              src={img3}
              alt=""
              style={{
                width: width > 700 ? "6vw" : "18vw",
                height: width > 700 ? "5vw" : "15vw",
                objectFit: "contain",
                marginTop: "1vw",
              }}
            />
          </div>
          <div className="datatagnameblog">Business Problems</div>
        </div>
        <div
          className="bludeblogtag"
          onClick={() => {
            setSearchBlogchat("");
            setSetSelectedCategory("Business Task Tutorials");
          }}
        >
          <div className="blueblogcardimg">
            <img
              src={img4}
              alt=""
              style={{
                width: width > 700 ? "6vw" : "18vw",
                height: width > 700 ? "5vw" : "15vw",
                objectFit: "contain",
                marginTop: "1vw",
              }}
            />
          </div>
          <div className="datatagnameblog">Business Task Tutorials</div>
        </div>
        <div
          className="bludeblogtag"
          onClick={() => {
            setSearchBlogchat("");
            setSetSelectedCategory("Platform Tutorials");
          }}
        >
          <div className="blueblogcardimg">
            <img
              src={img2}
              alt=""
              style={{
                width: width > 700 ? "6vw" : "18vw",
                height: width > 700 ? "5vw" : "15vw",
                objectFit: "contain",
                marginTop: "1vw",
              }}
            />
          </div>
          <div className="datatagnameblog">Platform Tutorials</div>
        </div>
        <div
          className="bludeblogtag"
          onClick={() => {
            setSearchBlogchat("");
            setSetSelectedCategory("Others");
          }}
        >
          <div className="blueblogcardimg">
            <img
              src={img1}
              alt=""
              style={{
                width: width > 700 ? "6vw" : "18vw",
                height: width > 700 ? "5vw" : "15vw",
                objectFit: "contain",
                marginTop: "1vw",
              }}
            />
          </div>
          <div className="datatagnameblog">Others</div>
        </div>
      </div>
      <div className="searchblog">
        <div>
          <SearchOutlinedIcon
            style={{ fontSize: width > 700 ? "2vw" : "5vw", margin: "0vw 1vw" }}
          />
        </div>
        <div style={{ width: "100%" }}>
          <input
            type="text"
            placeholder="Search Blogs here ...."
            value={SearchBlogchat}
            onChange={(e) => {
              setSearchBlogchat(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="filterlineblog">
        <div className="tagfiltertitile">Filter by :</div>
        <div
          style={{
            background: setSelectedCategory === "All" ? "#0052CC" : "",
            color: setSelectedCategory === "All" ? "white" : "",
          }}
          className="tagblogfilter"
          onClick={() => {
            setSetSelectedCategory("All");
            setSearchBlogchat("");
          }}
        >
          All
        </div>
        <div
          className="tagblogfilter"
          style={{
            background:
              setSelectedCategory === "Business Ideas" ? "#0052CC" : "",
            color: setSelectedCategory === "Business Ideas" ? "white" : "",
          }}
          onClick={() => {
            setSetSelectedCategory("Business Ideas");
            setSearchBlogchat("");
          }}
        >
          Business Ideas
        </div>
        <div
          className="tagblogfilter"
          style={{
            background:
              setSelectedCategory === "Business Plans" ? "#0052CC" : "",
            color: setSelectedCategory === "Business Plans" ? "white" : "",
          }}
          onClick={() => {
            setSearchBlogchat("");
            setSetSelectedCategory("Business Plans");
          }}
        >
          Business Plans
        </div>
        <div
          className="tagblogfilter"
          style={{
            background:
              setSelectedCategory === "Business Problems" ? "#0052CC" : "",
            color: setSelectedCategory === "Business Problems" ? "white" : "",
          }}
          onClick={() => {
            setSearchBlogchat("");
            setSetSelectedCategory("Business Problems");
          }}
        >
          Business Problems
        </div>
        <div
          className="tagblogfilter"
          style={{
            background:
              setSelectedCategory === "Business Task Tutorials" ? "#0052CC" : "",
            color: setSelectedCategory === "Business Task Tutorials" ? "white" : "",
          }}
          onClick={() => {
            setSearchBlogchat("");
            setSetSelectedCategory("Business Task Tutorials");
          }}
        >
        Business Task Tutorials
        </div>
        <div
          className="tagblogfilter"
          style={{
            background:
              setSelectedCategory === "Platform Tutorials" ? "#0052CC" : "",
            color: setSelectedCategory === "Platform Tutorials" ? "white" : "",
          }}
          onClick={() => {
            setSearchBlogchat("");
            setSetSelectedCategory("Platform Tutorials");
          }}
        >
          Platform Tutorials
        </div>

        <div
          className="tagblogfilter"
          style={{
            background: setSelectedCategory === "Others" ? "#0052CC" : "",
            color: setSelectedCategory === "Others" ? "white" : "",
          }}
          onClick={() => {
            setSearchBlogchat("");
            setSetSelectedCategory("Others");
          }}
        >
          Others
        </div>
      </div>

      <div className="flexoffilterboxes">
        {allcategory?.length > 0 &&
          allcategory?.map((item) => {
            const myStr = item?.contentName;
            const contentNameRoute = myStr
              .replaceAll(" ", "-")
              .replaceAll("/", "-")
              .replaceAll("?", "-");
            return (
              <div
                  style={{ cursor: "pointer" ,margin: width>700? '1.5vw':"3vw 0.75vw" }}
                  onClick={() => navigate(`/blogs/${contentNameRoute}/${item?.contentId}`)} 
                  className="boxoffilterbox"
                >
                <div className="imgboxfilteredblog w-100">
                  <img src={item?.icon?.length > 0 ? item?.icon[0] : img} alt="" className="w-100" />
                </div>
                <div className="mt-3">
                  <div className="d-flex justify-content-between mb-2">
                    <div className="tagblogblue " style={{width:"55%"}}>
                      <span className="tagblogblack ellipsis" style={{display:"block", width:"100%"}}>
                        {item?.contentName}
                      </span>
                    </div>
                    <div className="tagblue " style={{whiteSpace:"nowrap"}}>  {item?.category} </div>
                  </div>
                  
                  <div className="toc mt-2">
                    <div>Table of Content</div>
                    <ul className="mt-2" style={{height:width > 700 ? "6vw" :"19.5vw", overflow:"hidden"}}>
                      {item?.toC?.length > 0 && item?.toC?.map((tocItem) => {
                        return <li>{tocItem?.heading}</li>
                      })}  
                    </ul>
                  </div>
                  <hr style={{ padding: "0vw", margin: "0.3vw" }}></hr>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                        style={{
                          fontSize: width > 700 ? "0.85vw" : "2.5vw",
                          display: "flex",
                          marginTop: "0.1vw",
                          alignItems: "center",
                          fontWeight:"600"
                        }}
                      >
                        {item?.author}
                      </div>
                    <div className="view">views - {item?.views}</div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      {totalcount > 8 && (
        <div className="ViewMorebutton" onClick={() => setPage(page + 1)}>
          View More
        </div>
      )}
    </div>
  );
}
