import React, { Component } from "react";
// import Slider from "react-slick";
// import img1 from "../../../assets/Landing page itworl/Sign Up.svg";
// import img2 from "../../../assets/Landing page itworl/Website Landing PAge.svg";
// import img3 from "../../../assets/Landing page itworl/Connect.svg";
// import img4 from "../../../assets/Landing page itworl/Rating and Review.svg";
// import img5 from "../../../assets/Landing page itworl/Utilize.svg";
import CountUp from 'react-countup';

import "./Homeabout.css";
// import img from "../../../assets/Landing page/tick.svg";
// export default class Homecarouselwork extends Component {
//   constructor(state) {
//     super(state);
//     this.state = { button: true };
//   }
//   render() {
//     const settings = {
//       infinite: true,
//       speed: 1000,
//       slidesToShow: 1,
//       slidesToScroll: 1,
//       autoplay: true,
//       autoplaySpeed: 4000,
//       pauseOnHover: false,
//     };
//     return (
//       <div style={{ width: "100vw", overflow: "hidden" }}>
//         {/* <div className="home-catecarousel"> */}
//         <div
//           style={{
//             width: "100vw ",
//             margin: "auto",
//             // marginBottom: "2vw"
//           }}
//         >
//           {this?.props?.width >= 700 && (
//             <>
//               <Slider {...settings}>
//                 <div className="homebluebox">
//                   <div style={{ display: "flex" }}>
//                     <div className="homeworkimg2">
//                       <div className="homeworkimgdiv1">01</div>
//                       <div className="homeworkimgdiv2">Sign up</div>
//                       <div className="homeworkimgdiv3">
//                         <span style={{ marginRight: "0.5vw" }}>
//                           <img src={img} alt="" style={{ fontSize: "1.3vw" }} />
//                         </span>{" "}
//                         Create an account
//                       </div>
//                       <div className="homeworkimgdiv4">
//                         <span style={{ marginRight: "0.5vw" }}>
//                           <img src={img} alt="" style={{ fontSize: "1.3vw" }} />
//                         </span>
//                         <div>Access all of our features.</div>
//                       </div>
//                     </div>
//                     <div className="homeworkimg3">
//                       <img src={img1} alt=""         style={{ position: "relative", bottom: "6vw" }}/>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="homebluebox">
//                   <div style={{ display: "flex" }}>
//                     <div className="homeworkimg2">
//                       <div className="homeworkimgdiv1">02</div>
//                       <div className="homeworkimgdiv2">Build your website </div>
//                       <div className="homeworkimgdiv3">
//                         <span style={{ marginRight: "0.5vw" }}>
//                           <img src={img} alt="" style={{ fontSize: "1.3vw" }} />
//                         </span>
//                         User-friendly website builder
//                       </div>
//                       <div className="homeworkimgdiv4">
//                         <span style={{ marginRight: "0.5vw" }}>
//                           <img src={img} alt="" style={{ fontSize: "1.3vw" }} />
//                         </span>
//                         <div>Customizable templates.</div>
//                       </div>
//                     </div>
                   
//                     <div className="homeworkimg3">
//                       <img
//                         style={{ position: "relative", bottom: "6vw" }}
//                         src={img2}
//                         alt=""
//                       />
//                     </div>
//                   </div>
//                 </div>
//                 <div className="homebluebox">
//                   <div style={{ display: "flex" }}>
//                     <div className="homeworkimg2">
//                       <div className="homeworkimgdiv1">03</div>
//                       <div className="homeworkimgdiv2">Connect</div>
//                       <div className="homeworkimgdiv3">
//                         <span style={{ marginRight: "0.5vw" }}>
//                           <img src={img} alt="" style={{ fontSize: "1.3vw" }} />
//                         </span>
//                         Target audience
//                       </div>
//                       <div className="homeworkimgdiv4">
//                         <span style={{ marginRight: "0.5vw" }}>
//                           <img src={img} alt="" style={{ fontSize: "1.3vw" }} />
//                         </span>
//                         <div>Showcase your offerings</div>
//                       </div>
//                     </div>
//                     <div className="homeworkimg3">
//                       <img
//                         style={{ position: "relative", bottom: "7vw" }}
//                         src={img3}
//                         alt=""
//                       />
//                     </div>
//                   </div>
//                 </div>
//                 <div className="homebluebox">
//                   <div style={{ display: "flex" }}>
//                     <div className="homeworkimg2">
//                       <div className="homeworkimgdiv1">04</div>
//                       <div className="homeworkimgdiv2">
//                         {" "}
//                         Ratings and reviews{" "}
//                       </div>
//                       <div className="homeworkimgdiv3">
//                         <span style={{ marginRight: "0.5vw" }}>
//                           <img src={img} alt="" style={{ fontSize: "1.3vw" }} />
//                         </span>
//                         Satisfied customers to help build your reputation
//                       </div>
//                       <div className="homeworkimgdiv4">
//                         <span style={{ marginRight: "0.5vw" }}>
//                           <img src={img} alt="" style={{ fontSize: "1.3vw" }} />
//                         </span>
//                         <div>Attract new clients.</div>
//                       </div>
//                     </div>
//                     <div className="homeworkimg3">
//                       <img
//                         style={{ position: "relative", bottom: "3vw" }}
//                         src={img4}
//                         alt=""
//                       />
//                     </div>
//                   </div>
//                 </div>
//                 <div className="homebluebox">
//                   <div style={{ display: "flex" }}>
//                     <div className="homeworkimg2">
//                       <div className="homeworkimgdiv1">05</div>
//                       <div className="homeworkimgdiv2">Utilize</div>
//                       <div className="homeworkimgdiv3">
//                         <span style={{ marginRight: "0.5vw" }}>
//                           <img src={img} alt="" style={{ fontSize: "1.3vw" }} />
//                         </span>
//                         Our powerful tools
//                       </div>
//                       <div className="homeworkimgdiv4">
//                         <span style={{ marginRight: "0.5vw" }}>
//                           <img src={img} alt="" style={{ fontSize: "1.3vw" }} />
//                         </span>
//                         <div>Maximize the effectiveness.</div>
//                       </div>
//                     </div>

//                     <div className="homeworkimg3">
//                       <img
//                         style={{ position: "relative", bottom: "4vw" }}
//                         src={img5}
//                         alt=""
//                       />
//                     </div>
//                   </div>
//                 </div>
//               </Slider>
//             </>
//           )}{" "}
//           {this?.props?.width < 700 && (
//             <Slider {...settings}>
//               <div className="homebluebox">
//                 <div style={{ display: "flex" }}>
//                   <div className="homeworkimg2">
//                     <div className="homeworkimgdiv1">01</div>
//                     <div className="homeworkimgdiv2">Sign up</div>
//                     <div className="homeworkimgdiv3">
//                       <span style={{ marginRight: "0.5vw" }}>
//                         <img src={img} alt="" style={{ fontSize: "1.3vw" }} />
//                       </span>
//                       Create an account
//                     </div>
//                     <div className="homeworkimgdiv4">
//                       <span style={{ marginRight: "0.5vw" }}>
//                         <img src={img} alt="" style={{ fontSize: "1.3vw" }} />
//                       </span>
//                       <div>
//                       Access all of our features.
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="homebluebox">
//                 <div style={{ display: "flex" }}>
//                 <div className="homeworkimg2">
//                       <div className="homeworkimgdiv1">02</div>
//                       <div className="homeworkimgdiv2">Build your website </div>
//                       <div className="homeworkimgdiv3">
//                         <span style={{ marginRight: "0.5vw" }}>
//                           <img src={img} alt="" style={{ fontSize: "1.3vw" }} />
//                         </span>
//                         User-friendly website builder
//                       </div>
//                       <div className="homeworkimgdiv4">
//                         <span style={{ marginRight: "0.5vw" }}>
//                           <img src={img} alt="" style={{ fontSize: "1.3vw" }} />
//                         </span>
//                         <div>Customizable templates.</div>
//                       </div>
//                     </div>
//                       </div>
//               </div>
//               <div className="homebluebox">
//                 <div style={{ display: "flex" }}>
//                 <div className="homeworkimg2">
//                       <div className="homeworkimgdiv1">03</div>
//                       <div className="homeworkimgdiv2">Connect</div>
//                       <div className="homeworkimgdiv3">
//                         <span style={{ marginRight: "0.5vw" }}>
//                           <img src={img} alt="" style={{ fontSize: "1.3vw" }} />
//                         </span>
//                         Target audience
//                       </div>
//                       <div className="homeworkimgdiv4">
//                         <span style={{ marginRight: "0.5vw" }}>
//                           <img src={img} alt="" style={{ fontSize: "1.3vw" }} />
//                         </span>
//                         <div>Showcase your offerings</div>
//                       </div>
//                     </div>
                 
//                 </div>
//               </div>
//               <div className="homebluebox">
//                 <div style={{ display: "flex" }}>
//                 <div className="homeworkimg2">
//                       <div className="homeworkimgdiv1">04</div>
//                       <div className="homeworkimgdiv2">
//                         {" "}
//                         Ratings and reviews{" "}
//                       </div>
//                       <div className="homeworkimgdiv3">
//                         <span style={{ marginRight: "0.5vw" }}>
//                           <img src={img} alt="" style={{ fontSize: "1.3vw" }} />
//                         </span>
//                         Satisfied customers to help build your reputation
//                       </div>
//                       <div className="homeworkimgdiv4">
//                         <span style={{ marginRight: "0.5vw" }}>
//                           <img src={img} alt="" style={{ fontSize: "1.3vw" }} />
//                         </span>
//                         <div>Attract new clients.</div>
//                       </div>
//                     </div>
                 
//                 </div>
//               </div>
//               <div className="homebluebox">
//                 <div style={{ display: "flex" }}>
//                 <div className="homeworkimg2">
//                       <div className="homeworkimgdiv1">05</div>
//                       <div className="homeworkimgdiv2">Utilize</div>
//                       <div className="homeworkimgdiv3">
//                         <span style={{ marginRight: "0.5vw" }}>
//                           <img src={img} alt="" style={{ fontSize: "1.3vw" }} />
//                         </span>
//                         Our powerful tools
//                       </div>
//                       <div className="homeworkimgdiv4">
//                         <span style={{ marginRight: "0.5vw" }}>
//                           <img src={img} alt="" style={{ fontSize: "1.3vw" }} />
//                         </span>
//                         <div>Maximize the effectiveness.</div>
//                       </div>
//                     </div>

//                   <div className="homeworkimg3"></div>
//                 </div>
//               </div>
//             </Slider>
//           )}
//         </div>
//       </div>
//     );
//   }
// }

const HomeCarouselWorkit = ({ allCompany,allStories}) => {
  return (
    <>
      <div>
        <h1 className='new_work_first_heading'>
          Some of our numbers since our launch
        </h1>
        <div className='new_work_container'>
          <div className='new_work_col_1'>
            <span className='new-work_col_1_number'>
              <CountUp
                end={allCompany}
                start={0}
                duration={3}
              />{' '}
              +
            </span>
            <span className='new-work_col_1_des'>Company</span>
          </div>
          <div className='new_work_col_2'>
            <span className='new-work_col_1_number'>
              {' '}
              <CountUp
                end={allStories}
                start={0}
                duration={3}
              />{' '}
              +
            </span>
            <span className='new-work_col_1_des'>Business Ideas</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeCarouselWorkit;

