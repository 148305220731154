import React from "react";

export default function EventDetailsabout() {
  return (
    <div className="EventCardContainer">
      <div className="titleviewdivevent">
        <div className="titletagviewtitleevnt">Similar Events</div>
        <div className="viewallbutton">View All</div>
      </div>
      <div className="flecofcardsevents">
        <div className="eventcardbox">
          <div className="blueevnetcardtitle">New Talent Webinar</div>
          <div className="blackevnetcardtitle">
            Talent Webinar: Interact With Experts
          </div>
          <div className="blackevnetcardpara">
            Getting started on Upwork can take a lot of work and focus. To help
            make this process a little easier, we’ve created this post where you
            can request feedback on your profile. The community is full of
            experienced Upwork freelancers who are passionate about helping
            others. These users will review your profile and provide feedback
            based on their experience.
          </div>
          <div className="blueevnetcardtitle1">Host & Speaker</div>
          <div className="blackevnetcardtitle1">Sunder Pitchai</div>
          <div className="blackevnetcardpara1">CEO of Google</div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              color: "black",
              margin: "1.2vw 0vw",
            }}
            className="blackevnetcardpara1"
          >
            <div>Dec 12, 2022(8:00 AM)</div>
            <div style={{ cursor: "pointer" }}>View event</div>
          </div>
        </div>
        <div className="eventcardbox">
          <div className="blueevnetcardtitle">New Talent Webinar</div>
          <div className="blackevnetcardtitle">
            Talent Webinar: Interact With Experts
          </div>
          <div className="blackevnetcardpara">
            Getting started on Upwork can take a lot of work and focus. To help
            make this process a little easier, we’ve created this post where you
            can request feedback on your profile. The community is full of
            experienced Upwork freelancers who are passionate about helping
            others. These users will review your profile and provide feedback
            based on their experience.
          </div>
          <div className="blueevnetcardtitle1">Host & Speaker</div>
          <div className="blackevnetcardtitle1">Sunder Pitchai</div>
          <div className="blackevnetcardpara1">CEO of Google</div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              color: "black",
              margin: "1.2vw 0vw",
            }}
            className="blackevnetcardpara1"
          >
            <div>Dec 12, 2022(8:00 AM)</div>
            <div>View event</div>
          </div>
        </div>
        <div className="eventcardbox">
          <div className="blueevnetcardtitle">New Talent Webinar</div>
          <div className="blackevnetcardtitle">
            Talent Webinar: Interact With Experts
          </div>
          <div className="blackevnetcardpara">
            Getting started on Upwork can take a lot of work and focus. To help
            make this process a little easier, we’ve created this post where you
            can request feedback on your profile. The community is full of
            experienced Upwork freelancers who are passionate about helping
            others. These users will review your profile and provide feedback
            based on their experience.
          </div>
          <div className="blueevnetcardtitle1">Host & Speaker</div>
          <div className="blackevnetcardtitle1">Sunder Pitchai</div>
          <div className="blackevnetcardpara1">CEO of Google</div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              color: "black",
              margin: "1.2vw 0vw",
            }}
            className="blackevnetcardpara1"
          >
            <div>Dec 12, 2022(8:00 AM)</div>
            <div>View event</div>
          </div>
        </div>
      </div>
    </div>
  );
}
