import React from "react";
import imageCompression from "browser-image-compression";
import img from "../../../assets/Web 1280 – 14/Icon.svg";
import img1 from "../../../assets/Web 1280 – 14/Group 9831.svg";
import img2 from "../../../assets/Web 1280 – 14/Iconly-Light-outline-Close Square.svg";
import axios from "axios";

import API_HOST from "../../../env";

export default function Offers({
  data,
  width,
  index,
  arrayofservices,
  setArrayofservices,
}) {
  const upadteimage = (img) => {
    const formdata = new FormData();
    formdata.append("fileName", img);
    formdata.append("folderName", "websitesObjects")
    axios
      ?.post(`${API_HOST}/contentManagement/tableContent`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        setArrayofservices([
          ...arrayofservices.slice(0, index),
          {
            name: data?.name,
            heading: data?.heading,
            image: res.data?.success?.data?.file,
          },
          ...arrayofservices.slice(index + 1, arrayofservices?.length),
        ]);
      });
  };

  async function handleImageUpload(event) {
    const imageFile = event.target.files[0];

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log('k');
      await upadteimage(new File([compressedFile], `${compressedFile?.name}`));
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div
        style={{ marginTop: "1vw", flexWrap: "wrap", height: "fit-content" }}
        className="servicesmenudiv"
      >
        <div
          style={{
            width: "99%",
          }}
          className="servicesmenuname1box"
        >
          <input
            type="text"
            placeholder={`Offer-${index + 1} Name`}
            value={data?.name}
            onChange={(e) => {
              setArrayofservices([
                ...arrayofservices.slice(0, index),
                {
                  name: e.target.value,
                  heading: data?.heading,
                  image: data?.image,
                },
                ...arrayofservices.slice(index + 1, arrayofservices?.length),
              ]);
            }}
          />
        </div>
        <div
          style={{
            width: "99%",
            marginTop: "1vw",
          }}
          className="servicesmenuname2box"
        >
          <input
            type="text"
            placeholder={`Offer-${index + 1} Heading`}
            value={data?.heading}
            onChange={(e) => {
              setArrayofservices([
                ...arrayofservices.slice(0, index),
                {
                  name: data?.name,
                  heading: e.target.value,
                  image: data?.image,
                },
                ...arrayofservices.slice(index + 1, arrayofservices?.length),
              ]);
            }}
          />
        </div>

        <div
          style={{
            width: "100%",
            marginTop: "1vw",
            height: width > 700 ? "7vw":"fit-content",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ height: width > 700 ? "7vw":"19vw" }} className="inputfilebox">
            <div>
              <label htmlFor={`inputctaelogfile${index+1}`}>
                <div className="inputicon">
                  <img src={img} alt="" />
                </div>
                <div className="inputcateaddformfile">
                  Drag and Drop ,Browse to upload Your Offer {index + 1} Image
                </div>
                <input
                  type="file"
                  id={`inputctaelogfile${index+1}`}
                  onChange={(e) => {
                    handleImageUpload(e);
                  }}
                  hidden
                />
              </label>
            </div>
          </div>

          <div className="inputfilesshowncatebox">
            {data?.image && (
              <div className="inputfilesshowncatboxsingle">
                <div className="inputfilesshowncatboxsingleimg">
                  <img
                    src={img1}
                    style={{ width: width > 700 ? "" : "4.5vw" }}
                    alt=""
                  />
                </div>
                <div className="fileselctednamecate">{data?.image?.split('amazonaws.com/')[1]}</div>
                <div className="inputfilesshowncatboxsingleimg">
                  <img
                    style={{
                      width: width > 700 ? "1.5vw" : "4.5vw",
                      marginLeft: "1vw",
                      cursor:"pointer"
                    }}
                    onClick={()=>{
                        setArrayofservices([
                            ...arrayofservices.slice(0, index),
                            {
                              name: data?.name,
                              heading: data?.heading,
                              image: "",
                            },
                            ...arrayofservices.slice(index + 1, arrayofservices?.length),
                          ]);
                    }}
                    src={img2}
                    alt=""
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div style={{height:width>700 ?"":"fit-content"}} className="servicesmenudiv">
        <div className="servicesmenuname1 mt-1"></div>
      </div>{" "}
    </>
  );
}
