import React, { useEffect, useState } from "react";
import img1 from "../../assets/My profile – 28/Component 70 – 6.svg";
import "./profile.css";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";

import { TextField } from "@mui/material";
import Profileexperincedetail from "./Profilepopup/Profileexperincedetail";

import API_HOST from "../../env";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  height: "40rem",
  bgcolor: "background.paper",
  boxShadow: 24,
  overflowY: "scroll",
  overflowX: "hidden",
  p: 3,
};
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  height: "40rem",
  bgcolor: "background.paper",
  boxShadow: 24,
  overflowY: "scroll",
  overflowX: "hidden",
  p: 1,
};
const useStyles = makeStyles((theme) => ({
  input: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "0.91vw",
    color: "#263238",
    border: "yellow !important",
  },
}));
export default function ProfileWorkexperince({ user, width,setWorkexp }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [year1, setyear1] = useState("");

  const [year2, setyear2] = useState("");

  const [city, setcity] = useState("");
  const [country, setcountry] = useState("");

  const [degreeset, setDegreeset] = useState("");

  const [companyName, setCompanyName] = useState("");

  const [descriptioncallageadd, setdescriptioncallageadd] = useState("");
  const [checkedtocompany, setCheckedtocompany] = useState(false);
  
  const handleaddcallage = () => {
    if (checkedtocompany) {
      const formdata = {
        companyName: companyName,
        companyCity: city,
        companyCountry: country,
        jobTitle: degreeset,
        startDate: year1,
        workingCurrently: checkedtocompany,
        description: descriptioncallageadd,
      };
      axios
        .post(`${API_HOST}/users/companyDetails`, formdata, {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((res) => {
          if (user) {
            axios
              .get(
                `${API_HOST}/users/viewCompany?pageSize=50&pageNumber=1&user_id=${user?._id}`
              )
              .then((res) => {
                setcertificated(res?.data?.success?.data);
                setWorkexp(res?.data?.success?.data);
              });
          }
          handleClose();
          setCompanyName("");
          setcity("");
          setcountry("");
          setDegreeset();
          setyear1("");
          setyear2("");
          setdescriptioncallageadd("");
          setCheckedtocompany(false)
        });
    } else {
      const formdata = {
        companyName: companyName,
        companyCity: city,
        companyCountry: country,
        jobTitle: degreeset,
        startDate: year1,
        workingCurrently: checkedtocompany,
        description: descriptioncallageadd,
        endDate: year2,
      };
      axios
        .post(`${API_HOST}/users/companyDetails`, formdata, {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((res) => {
          if (user) {
            axios
              .get(
                `${API_HOST}/users/viewCompany?pageSize=50&pageNumber=1&user_id=${user?._id}`
              )
              .then((res) => {
                setcertificated(res?.data?.success?.data);
                setWorkexp(res?.data?.success?.data);

              });
          }
          handleClose();
          setCompanyName("");
          setcity("");
          setcountry("");
          setDegreeset();
          setyear1("");
          setyear2("");
          setdescriptioncallageadd("");
          setCheckedtocompany(false)
        });
    }
  };

  const [certificated, setcertificated] = useState([]);

  useEffect(() => {
    if (user?.userName) {
      axios
        .get(
          `${API_HOST}/users/viewCompany?pageSize=50&pageNumber=1&user_id=${user?._id}`
        )
        .then((res) => {
          setcertificated(res?.data?.success?.data);
          setWorkexp(res?.data?.success?.data);
        });
    }
  }, [user]);
  
  return (
    <div>
      <div style={{ height: "fit-content" }} className="profilebadgecontainer">
        <div
          style={{ padding: "1.5vw", margin: "0 0vw" }}
          className="profiletitleandmenunav"
        >
          <div className="profiledetailstitle">Work Experience</div>
          <div className="profiledetailnavmanu">
            <img
              onClick={handleOpen}
              src={img1}
              style={{ width: width > 700 ? "2.5vw" : "6vw" }}
              alt=""
            />
          </div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={width > 700 ? style : style1}>
              <div>
                <div className="profiletitleandmenunav">
                  <div className="profiledetailstitle">Add Employment</div>
                  <div className="profiledetailnavmanu">
                    <div>
                      <CloseIcon
                        onClick={handleClose}
                        style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{ left: "0vw", width: "100%" }}
                  className="loginfield"
                >
                  <TextField
                    id="outlined-basic"
                    label="Company"
                    variant="outlined"
                    value={companyName}
                    style={{ width: "100%" }}
                    InputLabelProps={{
                      style: {
                        fontSize: width > 700 ? "1vw" : "3vw",
                        fontFamily: "Poppins",
                        fontStyle: "500",
                        fontWeight: "500",
                        color: "black",
                      },
                    }}
                    inputProps={{ className: classes.input }}
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                    }}
                  />
                </div>
                <div className="jobpodtedfieldtitile">Location</div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    marginTop: width > 700 ? "" : "3vw",
                  }}
                >
                  <div
                    style={{
                      left: "0vw",
                      width: "50%",
                      margin: "0.3vw 0",
                    }}
                    className="loginfield"
                  >
                    <TextField
                      id="outlined-basic"
                      label="City"
                      variant="outlined"
                      value={city}
                      style={{ width: "95%" }}
                      InputLabelProps={{
                        style: {
                          fontSize: width > 700 ? "1vw" : "3vw",
                          fontFamily: "Poppins",
                          fontStyle: "500",
                          fontWeight: "500",
                          color: "black",
                        },
                      }}
                      onChange={(e) => {
                        setcity(e.target.value);
                      }}
                      inputProps={{ className: classes.input }}
                    />
                  </div>

                  <div
                    style={{
                      left: "1vw",
                      width: "50%",
                      margin: "0.3vw 0",
                      position: "relative",
                    }}
                    className="loginfield"
                  >
                    <TextField
                      id="outlined-basic"
                      label="Country"
                      variant="outlined"
                      value={country}
                      style={{
                        width: "95%",
                      }}
                      InputLabelProps={{
                        style: {
                          fontSize: width > 700 ? "1vw" : "3vw",
                          fontFamily: "Poppins",
                          fontStyle: "500",
                          fontWeight: "500",
                          color: "black",
                        },
                      }}
                      onChange={(e) => {
                        setcountry(e.target.value);
                      }}
                      inputProps={{ className: classes.input }}
                    />
                  </div>
                </div>
                <div
                  style={{ left: "0vw", width: "100%" }}
                  className="loginfield"
                >
                  <TextField
                    id="outlined-basic"
                    label="Title"
                    variant="outlined"
                    value={degreeset}
                    style={{ width: "100%" }}
                    InputLabelProps={{
                      style: {
                        fontSize: width > 700 ? "1vw" : "3vw",
                        fontFamily: "Poppins",
                        fontStyle: "500",
                        fontWeight: "500",
                        color: "black",
                      },
                    }}
                    inputProps={{ className: classes.input }}
                    onChange={(e) => {
                      setDegreeset(e.target.value);
                    }}
                  />
                </div>
                <label htmlFor="checkbox">
                  <input
                    type="checkbox"
                    onChange={() => {
                      setCheckedtocompany(!checkedtocompany);
                    }}
                    name=""
                    id="checkbox"
                  />

                  <div
                    className=""
                    style={{
                      fontSize: width > 700 ? "1vw" : "3vw",
                      fontFamily: "Poppins",
                      fontStyle: "500",
                      fontWeight: "500",
                      color: "#000000",
                      display: "inline",
                      margin: "0 1vw",
                    }}
                  >
                    Currently working here
                  </div>
                </label>
                <div className="jobpodtedfieldtitile">From</div>
                <div style={{ display: "flex", width: "100%" }}>
                  <div
                    style={{
                      left: "0vw",
                      width: "50%",
                      margin: "0.3vw 0",
                    }}
                    className="loginfield"
                    // onClick={handleClickx}
                  >
                    <div className="" style={{ width: "100%" }}>
                      <div style={{ width: "100%" }}>
                        <div className="jobpostfieldinputbox">
                          <input
                            style={{ width: "100%" }}
                            type="date"
                            className="input-homejobformdate"
                            name=""
                            id=""
                            // min={disablePastDate()}
                            max={"2025-12-31"}
                            maxlength="4"
                            onChange={(e) => {
                              setyear1(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {!checkedtocompany ? (
                  <>
                    {" "}
                    <div className="jobpodtedfieldtitile">To</div>
                    <div style={{ display: "flex", width: "100%" }}>
                      <div
                        style={{
                          left: "0vw",
                          width: "50%",
                          margin: "0.3vw 0",
                        }}
                        className="loginfield"
                        // onClick={handleClickx2}
                      >
                        <div className="" style={{ width: "100%" }}>
                          <div style={{ width: "100%" }}>
                            <div className="jobpostfieldinputbox">
                              <input
                                style={{ width: "100%" }}
                                type="date"
                                className="input-homejobformdate"
                                name=""
                                id=""
                                // min={disablePastDate()}
                                max={"2025-12-31"}
                                maxlength="4"
                                onChange={(e) => {
                                  setyear2(e.target.value);
                                }}
                                // onChange={(e) => {
                                //   setDatestart(e.target.value);
                                // }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div
                  style={{ left: "0vw", width: "100%" }}
                  className="loginfield"
                >
                  <TextField
                    id="outlined-basic"
                    label="Description (Optional)"
                    variant="outlined"
                    value={descriptioncallageadd}
                    multiline
                    rows="4"
                    style={{ width: "100%" }}
                    InputLabelProps={{
                      style: {
                        fontSize: width > 700 ? "1vw" : "3vw",
                        fontFamily: "Poppins",
                        fontStyle: "500",
                        fontWeight: "500",
                        color: "black",
                      },
                    }}
                    inputProps={{ className: classes.input }}
                    onChange={(e) => {
                      console.log(e.target.value);
                      setdescriptioncallageadd(e.target.value);
                    }}
                  />
                </div>
                {/* <hr style={{ color: "#000000" }} /> */}
                <div
                  style={{ marginTop: "0.31vw" }}
                  className="handlemoreaboutskill"
                >
                  <div
                    style={{
                      background: "white",
                      color: "black",
                      cursor: "pointer",
                    }}
                    className="handlecirclieaboutsave"
                    onClick={handleClose}
                  >
                    Cancel
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    className="handlecirclieaboutsave"
                    onClick={handleaddcallage}
                  >
                    SAVE
                  </div>
                </div>
              </div>
            </Box>
          </Modal>
        </div>
        {certificated?.map((company) => {
          return (
            <Profileexperincedetail
              company={company}
              width={width}
              setcertificated={setcertificated}
              user={user}
              setWorkexp={setWorkexp}
            />
          );
        })}
      </div>
    </div>
  );
}
