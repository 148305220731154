import React, { useEffect, useState } from "react";
import axios from "axios";
import API_HOST from "../../../env";
import BusinessDetails from "./businessDetail";
import { useNavigate } from "react-router";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CustomPagination from "../../Pagination";

export default function BusinessList({ width }) {
  const [directoryList, setDirectoryList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    axios
      .get(`${API_HOST}/companyDetails/getconnectedCompany?page=${page}`, {
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        setDirectoryList(res.data?.success?.data);
        setTotalPages(res.data?.success?.pagination?.totalPages)
      });
  }, [page]);

  const navigate = useNavigate();

  return (
    <div style={{ padding: "1rem", overflow: "scroll" }} className="h-100">
      <div
        style={{ padding: "0.5vw" }}
        className="profiledetailstitle"
      >
        <button
          style={{
            cursor: "pointer",
            zIndex: "100",
            padding: "0.5vw 0.7vw",
            backgroundColor: "white",
            color: "#000",
            borderRadius: width > 700 ? "0.3vw" : "1vw",
            border: "1px solid #d7d7d7",
          }}
          className="me-2"
          onClick={() => navigate(-1)}
        >
          <ArrowBackIosNewIcon />
        </button>
        Company List
      </div>
      <div
        style={{
          width: width > 700 ? "82vw" : "90vw",
          overflow: "scroll",
        }}
      >
        <div
          style={{
            alignItems: "center",
            padding: "0.75vw 0.5vw",
            margin: width > 700 ? "1vw 0vw" : "2vw 0vw",
            width: width > 700 ? "82vw" : "204vw",
            fontSize: width > 700 ? "1.1vw" : "3vw",
            overflowX: "scroll",
          }}
          className="navoftableblogs"
        >
          <div style={{ width: width > 700 ? "14vw" : "40vw" }}>
            Company name{" "}
          </div>
          <div style={{ width: width > 700 ? "21vw" : "40vw" }}>
            Company Title
          </div>
          <div style={{ width: width > 700 ? "14.5vw" : "40vw" }}>Email</div>
          <div style={{ width: width > 700 ? "9.5vw" : "30vw" }}>Mobile </div>
          <div style={{ width: width > 700 ? "9vw" : "30vw" }}>State</div>
          <div></div>
        </div>
        {directoryList?.map((data) => {
          return <BusinessDetails data={data} width={width} />;
        })}
      </div>

      {(totalPages > 1) && <div className="mt-2  mb-3 d-flex align-items-center justify-content-center" >
        <CustomPagination
          count={totalPages}
          currentPage={page}
          onChange={ (event, newPage) => {setPage(newPage);}}
        />
      </div>}
    </div>
  );
}
