import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Backdrop, CircularProgress, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import API_HOST from "../../../env";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Metadata } from "../../Metadata/Metadata";
import Productdata from "./Productdata";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { userActions } from "../../../store/userSlice";

const useStyles = makeStyles((theme) => ({
  input: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "0.91vw",
    color: "#263238",
    border: "yellow !important",
    // padding: "1vw",
  },
}));

export default function AddInvoice({ width }) {
  useEffect(() => {
    window.scrollTo(0, 0, { behavior: "smooth" });
  }, []);
  const classes = useStyles();
  const { user } = useSelector((state) => state.user);
  const [arrayofservices, setArrayofservices] = useState([
    {
      productName: "",
      extraDescription: "",
      productGst: "0",
      productGstValue: "",
      hsnCode: "",
      rate: "",
      qty: 0,
      value: "",
      totalValue: "",
    },
  ]);

  const navigate = useNavigate();
  const [open121, setOpen121] = React.useState(false);
  const handleClose121 = () => {
    setOpen121(false);
  };
  const handleToggle121 = () => {
    setOpen121(!open121);
  };
  const [showerror, setShowerror] = useState("");
  const [BuyerName, setBuyerName] = useState("");
  const [Buyermobile, setBuyerMobile] = useState("");
  const [Buyeremail, setBuyeremail] = useState("");
  const [buyerAddress, setBuyerAddress] = useState("");
  const [buyerState, setBuyerState] = useState("");
  const [buyerpincode, setBuyerpincode] = useState("");
  const [buyergst, setBuyergst] = useState("");

  const [consigerName, setconsigerName] = useState("");
  const [consigermobile, setconsigermobile] = useState("");
  const [consigeremail, setconsigeremail] = useState("");
  const [consigerAddress, setconsigerAddress] = useState("");
  const [consigerState, setconsigerState] = useState("");
  const [consigerpincode, setconsigerpincode] = useState("");
  const [consigergst, setconsigergst] = useState("");

  const [totalprice, settotalprice] = useState("");
  const [Challanno, setchallanno] = useState("");
  const [date, setDate] = useState("");

  const [texable, setTexable] = useState("");

  const [grandtotal, setgrandtotal] = useState("");

  const [modeoftransport, setModeoftransport] = useState("");
  const [vehicalno, setvehicalno] = useState("");
  const [grno, setGrno] = useState("");
  const [pono, setPono] = useState("");
  const [ewaybill, setEwaybill] = useState("");
  const [ewaydate, setEwaydate] = useState("");

  var a = [
    "",
    "one ",
    "two ",
    "three ",
    "four ",
    "five ",
    "six ",
    "seven ",
    "eight ",
    "nine ",
    "ten ",
    "eleven ",
    "twelve ",
    "thirteen ",
    "fourteen ",
    "fifteen ",
    "sixteen ",
    "seventeen ",
    "eighteen ",
    "nineteen ",
  ];
  var b = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  const dispatch = useDispatch();

  const handlesavedaddcatalogue = () => {
    if (!companyid) {
      setShowerror("Please select company for making invoice");
      return;
    }

    handleToggle121();

    const formdata = new FormData();

    formdata.append("invoiceType", type);
    formdata.append("companyId", companyid?._id);
    formdata.append("buyerName", BuyerName);
    formdata.append("buyerEmailId", Buyeremail);
    formdata.append("buyerMobile", Buyermobile);
    formdata.append("buyerAddress", buyerAddress);
    formdata.append("buyerState", buyerState);
    formdata.append("buyerPinCode", buyerpincode);
    formdata.append("buyerGSTNo", buyergst);
    formdata.append("consignerName", consigerName);
    formdata.append("consignerMobile", consigermobile);
    formdata.append("consignerEmailId", consigeremail);
    formdata.append("consignerAddress", consigerAddress);
    formdata.append("consignerState", consigerState);
    formdata.append("consignerPinCode", consigerpincode);
    formdata.append("consignerGSTNo", consigergst);
    formdata.append("products", JSON.stringify(arrayofservices));

    formdata.append("taxableValue", texable ? texable : 0);
    formdata.append("extraCharge", extracharges ? extracharges : 0);

    formdata.append("totalValue", totalprice ? totalprice : 0);
    formdata.append("challanNo", Challanno);
    date && formdata.append("date", date);
    formdata.append("modeOfTransport", modeoftransport);
    formdata.append("vehicalNo", vehicalno);
    formdata.append("GRNO", grno);
    formdata.append("PONO", pono);
    formdata.append("eWAyBillNo", ewaybill);
    formdata.append("eWayDate", ewaydate);
    formdata.append("grandTotal", grandtotal ? grandtotal : 0);

    axios
      .post(`${API_HOST}/invoice/createInvoice`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        console.log(res.data.success.data);
        navigate("/dashboard/invoice");
        handleClose121();
      })
      .catch((err) => {
        handleClose121();
        if (err?.response?.data?.fails?.message === "Insufficient balance") {
          console.log(err?.response?.data?.fails?.message);
          dispatch(userActions.openwalletForm());
        }
      });
  };

  const [anchorElx2, setAnchorElx2] = useState(null);
  const openx2 = Boolean(anchorElx2);
  const idx2 = openx2 ? "simple-popover" : undefined;

  const handleClickx2 = (event) => {
    setAnchorElx2(event.currentTarget);
  };

  const handleClosex2 = () => {
    setAnchorElx2(null);
  };
  const [type, setType] = useState("Product");

  const [companyid, setcompanyid] = useState();
  const [companyall, setCompanyall] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_HOST}/companyDetails/viewExisted`, {
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        setCompanyall(res?.data?.success?.data);
      });
  }, []);

  const [anchorElx1, setAnchorElx1] = useState(null);
  const openx1 = Boolean(anchorElx1);
  const idx1 = openx1 ? "simple-popover" : undefined;
  const handleClickx1 = (event) => {
    setAnchorElx1(event.currentTarget);
  };
  const handleClosex1 = () => {
    setAnchorElx1(null);
  };
  const [anchorEl1x, setAnchorEl1x] = React.useState(null);
  const handleClick1x = (event) => {
    setAnchorEl1x(event.currentTarget);
  };

  const handleClose1x = () => {
    setAnchorEl1x(null);
  };

  const open1x = Boolean(anchorEl1x);
  const id1x = open1x ? "simple-popover" : undefined;

  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const open1 = Boolean(anchorEl1);
  const id1 = open1 ? "simple-popover" : undefined;

  const [extracharges, setExtracharges] = useState(0);

  const [arrayoflongdegree, setArrayoflongdegree] = useState([
    "JAMMU AND KASHMIR (1)",
    "HIMACHAL PRADESH (2)",
    "PUNJAB (3)",
    "CHANDIGARH	(4)",
    "UTTARAKHAND (5)",
    "HARYANA (6)",
    "DELHI (7)",
    "RAJASTHAN (8)",
    "UTTAR PRADESH (9)",
    "BIHAR (10)",
    "SIKKIM	(11)",
    "ARUNACHAL PRADESH (12)",
    "NAGALAND (13)",
    "MANIPUR (14)",
    "MIZORAM (15)",
    "TRIPURA (16)",
    "MEGHALAYA (17)",
    "ASSAM	(18)",
    "WEST BENGAL (19)",
    "JHARKHAND	(20)",
    "ODISHA	(21)",
    "CHATTISGARH (22)",
    "MADHYA PRADESH	(23)",
    "GUJARAT (24)",
    "DADRA AND NAGAR HAVELI AND DAMAN AND DIU (NEWLY MERGED UT) (26*)",
    "MAHARASHTRA (27)",
    "ANDHRA PRADESH(BEFORE DIVISION) (28)",
    "KARNATAKA (29)",
    "GOA (30)",
    "LAKSHADWEEP (31)",
    "KERALA	(32)",
    "TAMIL NADU	(33)",
    "PUDUCHERRY	(34)",
    "ANDAMAN AND NICOBAR ISLANDS (35)",
    "TELANGANA (36)",
    "ANDHRA PRADESH (NEWLY ADDED) (37)",
    "LADAKH (NEWLY ADDED) (38)",
  ]);

  useEffect(() => {
    let total = 0;
    let totalgst = 0;
    arrayofservices?.map((data) => {
      total = total + data?.value;
      totalgst = totalgst + data?.productGstValue;
    });

    settotalprice(parseFloat(total).toFixed(1));
    setTexable(parseFloat(totalgst).toFixed(1));
  }, [arrayofservices]);

  const [taxsamestate, setTaxsamestate] = useState(false);

  useEffect(() => {
    if (buyerState === companyid?.state) {
      setTaxsamestate(true);
    } else {
      setTaxsamestate(false);
    }
  }, [buyerState]);

  useEffect(() => {
    setgrandtotal(
      parseFloat(
        parseFloat(totalprice || 0) +
          parseFloat(texable || 0) +
          parseFloat(extracharges || 0)
      ).toFixed(1)
    );
  }, [totalprice, extracharges, texable]);

  const [Sameasabove, setSameasabove] = useState(false);

  useEffect(() => {
    if (Sameasabove) {
      setconsigerName(BuyerName);
      setconsigerAddress(buyerAddress);
      setconsigerState(buyerState);
      setconsigergst(buyergst);
      setconsigerpincode(buyerpincode);
      setconsigeremail(Buyeremail);
      setconsigermobile(Buyermobile);
    } else {
      setconsigerName("");
      setconsigerAddress("");
      setconsigerState("");
      setconsigergst("");
      setconsigerpincode("");
      setconsigeremail("");
      setconsigermobile("");
    }
  }, [Sameasabove]);

  const [products, setProducts] = useState("");
  const [setSelectedCategory, setsetSelectedCategory] = useState("");

  useEffect(() => {
    if (user?.userName) {
      axios
        .get(
          `${API_HOST}/userProducts/userAllProduct?pageSize=50&pageNumber=1${
            setSelectedCategory ? `&search=${setSelectedCategory}` : ""
          }
        `,
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then((res) => {
          setProducts(res.data?.success?.data);
        });
    }
  }, [setSelectedCategory, user]);

  useEffect(() => {
    setModeoftransport("");
    setvehicalno("");
    setGrno("");
    setEwaybill("");
    setEwaydate("");
  }, [type]);

  return (
    <div
      className="ScrollTable"
      style={{
        background: "#F9F9F9",
        width: "100vw",
        padding: "0vw 1vw",
        height: width > 700 ? "calc(100vh - 4vw)" : "calc(100vh - 14vw)",
      }}
    >
      <Metadata
        title={`Add Invoice | Manage and Grow Your Business While Saving Costs`}
        description={
          "Connecting Businesses and Freelancers with Essential Business Tools in One Location"
        }
        keyword={
          "www.tool44.com,tool44,44tool,tool.com,Freelance jobs, blog ,aboutustool44, Freelancers,tool 44, 44tool ,44 tool,Freelance services,Remote work,Online jobs,Work from home,Freelance marketplace,Freelance platform,Freelance opportunities,Freelance gigs,Hire freelancers,Find freelancers,Freelance projects,Freelance work,Freelance community,Freelance professionals,Freelance talent,Freelance collaboration,Freelance job search,Freelance career"
        }
      ></Metadata>
      <div className="addCatalogcontainer ">
        <div className="profiletitleandmenunav">
          <div
            style={{
              fontSize: width > 700 ? "1.7vw" : "4vw",
              fontWeight: "400",
            }}
            className="profiledetailstitle"
          >
            Create Invoice
          </div>
          <div className="profiledetailnavmanu">
            <div></div>
          </div>
        </div>
        <hr style={{ color: "#000000", margin: width > 700 ? "1vw" : "2vw" }} />
        <div
          style={{
            display: "flex",
            margin: width > 700 ? "0vw" : "1vw 0",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              left: "0vw",
              width: width > 700 ? "49%" : "100% ",
              marginLeft: "0%",
            }}
            className="loginfield"
            onClick={handleClick1}
          >
            <TextField
              id="outlined-basic"
              label="Search Company"
              variant="outlined"
              disabled
              value={companyid?.companyName || ""}
              style={{ width: "100%" }}
              InputLabelProps={{
                style: {
                  fontSize: width > 700 ? "1vw" : "3vw",
                  fontFamily: "Poppins",
                  fontStyle: "500",
                  fontWeight: "500",
                  color: "black",
                },
              }}
              inputProps={{ className: classes.input }}
              onChange={(e) => {
                console.log(e.target.value);
              }}
            />
            <span style={{ width: "0.1vw" }}>
              <KeyboardArrowDownOutlined
                style={{
                  fontSize: width > 700 ? "1.5vw" : "5vw",
                  cursor: "pointer",
                  position: "relative",
                  right: width > 700 ? "2vw" : "6vw",
                  top: width > 700 ? "1vw" : "3vw",
                }}
              />
            </span>
          </div>
          <Popover
            id={id1}
            open={open1}
            anchorEl={anchorEl1}
            onClose={handleClose1}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div
              style={{
                maxHeight: width > 700 ? "18vw" : "40vw",
                overflow: "scroll",
                width: width > 700 ? "33vw" : "94vw",
              }}
            >
              {companyall?.map((data) => {
                return (
                  <Typography
                    sx={{
                      p: 0.51,
                      pl: 1,
                      ml: 1,
                      width: "100%",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setcompanyid(data);
                      handleClose1();
                    }}
                  >
                    {data?.companyName}
                  </Typography>
                );
              })}
            </div>
          </Popover>

          <div
            style={{
              left: "0vw",
              width: width > 700 ? "49%" : "100% ",
              marginLeft: "0vw",
            }}
            className="loginfield"
          >
            <TextField
              id="outlined-basic"
              label="Invoice No"
              value={companyid?.nextInvoice}
              disabled
              variant="outlined"
              style={{ width: "100%" }}
              InputLabelProps={{
                shrink: true,
                style: {
                  fontSize: width > 700 ? "1vw" : "3vw",
                  fontFamily: "Poppins",
                  fontStyle: "500",
                  fontWeight: "500",
                  color: "black",
                },
              }}
              inputProps={{ className: classes.input }}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            margin: width > 700 ? "0vw" : "1vw 0",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              left: "0vw",
              width: width > 700 ? "49%" : "100% ",
              marginLeft: "0%",
            }}
            className="loginfield"
            onClick={handleClick1x}
          >
            <TextField
              id="outlined-basic"
              label="Select Invoice Type "
              variant="outlined"
              disabled
              value={type}
              style={{ width: "100%" }}
              InputLabelProps={{
                style: {
                  fontSize: width > 700 ? "1vw" : "3vw",
                  fontFamily: "Poppins",
                  fontStyle: "500",
                  fontWeight: "500",
                  color: "black",
                },
              }}
              inputProps={{ className: classes.input }}
              onChange={(e) => {
                console.log(e.target.value);
              }}
            />
            <span style={{ width: "0.1vw" }}>
              <KeyboardArrowDownOutlined
                style={{
                  fontSize: width > 700 ? "1.5vw" : "5vw",
                  cursor: "pointer",
                  position: "relative",
                  right: width > 700 ? "2vw" : "6vw",
                  top: width > 700 ? "1vw" : "3vw",
                }}
              />
            </span>
          </div>
          <Popover
            id={id1x}
            open={open1x}
            anchorEl={anchorEl1x}
            onClose={handleClose1x}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div
              style={{
                maxHeight: width > 700 ? "18vw" : "40vw",
                overflow: "scroll",
                width: width > 700 ? "33vw" : "94vw",
              }}
            >
              <Typography
                sx={{
                  p: 0.51,
                  pl: 1,
                  ml: 1,
                  width: "100%",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setType("Product");
                  handleClose1x();
                }}
              >
                Product
              </Typography>

              <Typography
                sx={{
                  p: 0.51,
                  pl: 1,
                  ml: 1,
                  width: "100%",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setType("Service");
                  handleClose1x();
                }}
              >
                Service
              </Typography>
            </div>
          </Popover>

          <div
            style={{
              left: "0vw",
              width: width > 700 ? "49%" : "100% ",
              margin: width > 700 ? "1vw 0.5vw 2vw 0vw" : "4vw 0",
            }}
            className="loginfield"
          >
            <TextField
              id="outlined-basic"
              label="Date"
              type="date"
              value={date}
              variant="outlined"
              style={{ width: "100%" }}
              InputLabelProps={{
                shrink: true,
                style: {
                  fontSize: width > 700 ? "1vw" : "3vw",
                  fontFamily: "Poppins",
                  fontStyle: "500",
                  fontWeight: "500",
                  color: "black",
                },
              }}
              inputProps={{ className: classes.input }}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>
        </div>
        <div
          style={{
            left: "0vw",
            width: "99%",
            margin: width > 700 ? "0vw 0.5vw 2vw 0vw" : "2vw 0",
          }}
          className="loginfield"
        >
          <TextField
            id="outlined-basic"
            label="PO No"
            value={pono}
            variant="outlined"
            style={{ width: "100%" }}
            InputLabelProps={{
              style: {
                fontSize: width > 700 ? "1vw" : "3vw",
                fontFamily: "Poppins",
                fontStyle: "500",
                fontWeight: "500",
                color: "black",
              },
            }}
            inputProps={{ className: classes.input }}
            onChange={(e) => setPono(e.target.value)}
          />
        </div>

        <div
          style={{ left: "0vw", width: "99%", margin: "2vw 0.5vw 2vw 0vw" }}
          className="loginfield"
        >
          <TextField
            id="outlined-basic"
            label="Buyer Name"
            value={BuyerName}
            variant="outlined"
            style={{ width: "100%" }}
            InputLabelProps={{
              style: {
                fontSize: width > 700 ? "1vw" : "3vw",
                fontFamily: "Poppins",
                fontStyle: "500",
                fontWeight: "500",
                color: "black",
              },
            }}
            inputProps={{ className: classes.input }}
            onChange={(e) => {
              setBuyerName(e.target.value);
              if (Sameasabove) {
                setconsigerName(e.target.value);
              }
            }}
          />
        </div>

        <div
          style={{ left: "0vw", width: "99%", margin: "2vw 0.5vw 2vw 0vw" }}
          className="loginfield"
        >
          <TextField
            id="outlined-basic"
            label="Buyer Mobile No"
            value={Buyermobile}
            variant="outlined"
            type="number"
            style={{ width: "100%" }}
            InputLabelProps={{
              style: {
                fontSize: width > 700 ? "1vw" : "3vw",
                fontFamily: "Poppins",
                fontStyle: "500",
                fontWeight: "500",
                color: "black",
              },
            }}
            inputProps={{ className: classes.input }}
            onChange={(e) => {
              setBuyerMobile(e.target.value);
              if (Sameasabove) {
                setconsigermobile(e.target.value);
              }
            }}
          />
        </div>

        <div
          style={{ left: "0vw", width: "99%", margin: "2vw 0.5vw 2vw 0vw" }}
          className="loginfield"
        >
          <TextField
            id="outlined-basic"
            label="Buyer Email Id"
            value={Buyeremail}
            variant="outlined"
            style={{ width: "100%" }}
            InputLabelProps={{
              style: {
                fontSize: width > 700 ? "1vw" : "3vw",
                fontFamily: "Poppins",
                fontStyle: "500",
                fontWeight: "500",
                color: "black",
              },
            }}
            inputProps={{ className: classes.input }}
            onChange={(e) => {
              setBuyeremail(e.target.value);
              if (Sameasabove) {
                setconsigeremail(e.target.value);
              }
            }}
          />
        </div>

        <div
          style={{
            left: "0vw",
            width: "99%",
            margin: width > 700 ? "0vw 0.5vw 2vw 0vw" : "4vw 0",
          }}
          className="loginfield"
        >
          <TextField
            id="outlined-basic"
            label="Buyer Address"
            value={buyerAddress}
            variant="outlined"
            style={{ width: "100%" }}
            InputLabelProps={{
              style: {
                fontSize: width > 700 ? "1vw" : "3vw",
                fontFamily: "Poppins",
                fontStyle: "500",
                fontWeight: "500",
                color: "black",
              },
            }}
            inputProps={{ className: classes.input }}
            onChange={(e) => {
              setBuyerAddress(e.target.value);
              if (Sameasabove) {
                setconsigerAddress(e.target.value);
              }
            }}
          />
        </div>

        <div
          style={{
            left: "0vw",
            width: width > 700 ? "99%" : "99%",
            marginLeft: "0%",
          }}
          className="loginfield"
          onClick={handleClickx2}
        >
          <TextField
            id="outlined-basic"
            label="Buyer State *"
            variant="outlined"
            disabled
            value={buyerState}
            style={{ width: "100%" }}
            InputLabelProps={{
              style: {
                fontSize: width > 700 ? "1vw" : "3vw",
                fontFamily: "Poppins",
                fontStyle: "500",
                fontWeight: "500",
                color: "black",
              },
            }}
            inputProps={{ className: classes.input }}
            onChange={(e) => {
              console.log(e.target.value);
            }}
          />
          {width > 700 && (
            <span style={{ width: "0.1vw" }}>
              <KeyboardArrowDownOutlined
                style={{
                  fontSize: "1.5vw",
                  position: "relative",
                  right: "2vw",
                  top: "1vw",
                }}
              />
            </span>
          )}
        </div>

        <Popover
          id={idx2}
          open={openx2}
          anchorEl={anchorElx2}
          onClose={handleClosex2}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div
            style={{
              maxHeight: width > 700 ? "18vw" : "40vw",
              overflow: "scroll",
              width: width > 700 ? "66.5vw" : "90vw",
            }}
            className="ScrollBarexist"
          >
            {arrayoflongdegree?.length > 0 &&
              arrayoflongdegree.map((data, index) => {
                return (
                  <Typography
                    sx={{
                      p: 0.51,
                      pl: 1,
                      ml: 0,
                      width: "100%",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setBuyerState(data);
                      handleClosex2();
                      if (Sameasabove) {
                        setconsigerState(data);
                      }
                    }}
                  >
                    {data}
                  </Typography>
                );
              })}
          </div>
        </Popover>

        <div
          style={{
            left: "0vw",
            width: "99%",
            margin: width > 700 ? "0vw 0.5vw 2vw 0vw" : "4vw 0",
          }}
          className="loginfield"
        >
          <TextField
            id="outlined-basic"
            type="number"
            label="Buyer Pincode"
            value={buyerpincode}
            variant="outlined"
            style={{ width: "100%" }}
            InputLabelProps={{
              style: {
                fontSize: width > 700 ? "1vw" : "3vw",
                fontFamily: "Poppins",
                fontStyle: "500",
                fontWeight: "500",
                color: "black",
              },
            }}
            inputProps={{ className: classes.input }}
            onChange={(e) => {
              setBuyerpincode(e.target.value);
              if (Sameasabove) {
                setconsigerpincode(e.target.value);
              }
            }}
          />
        </div>
        <div
          style={{
            left: "0vw",
            width: "99%",
            margin: width > 700 ? "0vw 0.5vw 2vw 0vw" : "4vw 0",
          }}
          className="loginfield"
        >
          <TextField
            id="outlined-basic"
            label="Buyer GST No"
            value={buyergst}
            variant="outlined"
            style={{ width: "100%" }}
            InputLabelProps={{
              style: {
                fontSize: width > 700 ? "1vw" : "3vw",
                fontFamily: "Poppins",
                fontStyle: "500",
                fontWeight: "500",
                color: "black",
              },
            }}
            inputProps={{ className: classes.input }}
            onChange={(e) => {
              setBuyergst(e.target.value);
              if (Sameasabove) {
                setconsigergst(e.target.value);
              }
            }}
          />
        </div>

        <label htmlFor="checkbox">
          <input
            type="checkbox"
            onChange={() => {
              setSameasabove(!Sameasabove);
            }}
            name=""
            checked={Sameasabove}
            id="checkbox"
          />

          <div
            className=""
            style={{
              fontSize: width > 700 ? "1vw" : "3vw",
              fontFamily: "Poppins",
              fontStyle: "500",
              fontWeight: "500",
              color: "#000000",
              display: "inline",
              margin: "0 1vw",
            }}
          >
            Shipping details same as above
          </div>
        </label>

        <div
          style={{ left: "0vw", width: "99%", margin: "2vw 0.5vw 2vw 0vw" }}
          className="loginfield"
        >
          <TextField
            id="outlined-basic"
            label="Name"
            value={consigerName}
            variant="outlined"
            style={{ width: "100%" }}
            InputLabelProps={{
              style: {
                fontSize: width > 700 ? "1vw" : "3vw",
                fontFamily: "Poppins",
                fontStyle: "500",
                fontWeight: "500",
                color: "black",
              },
            }}
            inputProps={{ className: classes.input }}
            onChange={(e) => setconsigerName(e.target.value)}
          />
        </div>

        <div
          style={{ left: "0vw", width: "99%", margin: "2vw 0.5vw 2vw 0vw" }}
          className="loginfield"
        >
          <TextField
            id="outlined-basic"
            label="Mobile No"
            value={consigermobile}
            type="number"
            variant="outlined"
            style={{ width: "100%" }}
            InputLabelProps={{
              style: {
                fontSize: width > 700 ? "1vw" : "3vw",
                fontFamily: "Poppins",
                fontStyle: "500",
                fontWeight: "500",
                color: "black",
              },
            }}
            inputProps={{ className: classes.input }}
            onChange={(e) => setconsigermobile(e.target.value)}
          />
        </div>
        <div
          style={{ left: "0vw", width: "99%", margin: "2vw 0.5vw 2vw 0vw" }}
          className="loginfield"
        >
          <TextField
            id="outlined-basic"
            label="Email Id"
            value={consigeremail}
            variant="outlined"
            style={{ width: "100%" }}
            InputLabelProps={{
              style: {
                fontSize: width > 700 ? "1vw" : "3vw",
                fontFamily: "Poppins",
                fontStyle: "500",
                fontWeight: "500",
                color: "black",
              },
            }}
            inputProps={{ className: classes.input }}
            onChange={(e) => setconsigeremail(e.target.value)}
          />
        </div>

        <div
          style={{
            left: "0vw",
            width: "99%",
            margin: width > 700 ? "0vw 0.5vw 2vw 0vw" : "4vw 0",
          }}
          className="loginfield"
        >
          <TextField
            id="outlined-basic"
            label="Address"
            value={consigerAddress}
            variant="outlined"
            style={{ width: "100%" }}
            InputLabelProps={{
              style: {
                fontSize: width > 700 ? "1vw" : "3vw",
                fontFamily: "Poppins",
                fontStyle: "500",
                fontWeight: "500",
                color: "black",
              },
            }}
            inputProps={{ className: classes.input }}
            onChange={(e) => setconsigerAddress(e.target.value)}
          />
        </div>

        <div
          style={{
            left: "0vw",
            width: width > 700 ? "99%" : "99%",
            marginLeft: "0%",
          }}
          className="loginfield"
          onClick={handleClickx1}
        >
          <TextField
            id="outlined-basic"
            label="State "
            variant="outlined"
            disabled
            value={consigerState}
            style={{ width: "100%" }}
            InputLabelProps={{
              style: {
                fontSize: width > 700 ? "1vw" : "3vw",
                fontFamily: "Poppins",
                fontStyle: "500",
                fontWeight: "500",
                color: "black",
              },
            }}
            inputProps={{ className: classes.input }}
            onChange={(e) => {
              console.log(e.target.value);
            }}
          />
          {width > 700 && (
            <span style={{ width: "0.1vw" }}>
              <KeyboardArrowDownOutlined
                style={{
                  fontSize: "1.5vw",
                  position: "relative",
                  right: "2vw",
                  top: "1vw",
                }}
              />
            </span>
          )}
        </div>
        <Popover
          id={idx1}
          open={openx1}
          anchorEl={anchorElx1}
          onClose={handleClosex1}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div
            style={{
              maxHeight: width > 700 ? "18vw" : "40vw",
              height: width > 700 ? "18vw" : "40vw",
              overflow: "scroll",
              width: width > 700 ? "66.5vw" : "90vw",
            }}
            className="ScrollBarexist"
          >
            {arrayoflongdegree?.length > 0 &&
              arrayoflongdegree.map((data, index) => {
                return (
                  <Typography
                    sx={{
                      p: 0.51,
                      pl: 1,
                      ml: 0,
                      width: "100%",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setconsigerState(data);
                      handleClosex1();
                    }}
                  >
                    {data}
                  </Typography>
                );
              })}
          </div>
        </Popover>

        <div
          style={{
            left: "0vw",
            width: "99%",
            margin: width > 700 ? "0vw 0.5vw 2vw 0vw" : "4vw 0",
          }}
          className="loginfield"
        >
          <TextField
            id="outlined-basic"
            label="Pincode"
            value={consigerpincode}
            variant="outlined"
            style={{ width: "100%" }}
            InputLabelProps={{
              style: {
                fontSize: width > 700 ? "1vw" : "3vw",
                fontFamily: "Poppins",
                fontStyle: "500",
                fontWeight: "500",
                color: "black",
              },
            }}
            inputProps={{ className: classes.input }}
            onChange={(e) => setconsigerpincode(e.target.value)}
          />
        </div>
        <div
          style={{
            left: "0vw",
            width: "99%",
            margin: width > 700 ? "0vw 0.5vw 2vw 0vw" : "4vw 0",
          }}
          className="loginfield"
        >
          <TextField
            id="outlined-basic"
            label="GST No"
            value={consigergst}
            variant="outlined"
            style={{ width: "100%" }}
            InputLabelProps={{
              style: {
                fontSize: width > 700 ? "1vw" : "3vw",
                fontFamily: "Poppins",
                fontStyle: "500",
                fontWeight: "500",
                color: "black",
              },
            }}
            inputProps={{ className: classes.input }}
            onChange={(e) => setconsigergst(e.target.value)}
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "98%",
            marginTop: "2vw",
            alignItems: "center",
          }}
        >
          <div
            style={{ marginBottom: "0.5vw" }}
            className="jobpodtedfieldtitile"
          >
            Products / Services
          </div>
          <div
            style={{
              width: width > 700 ? "fit-content" : "fit-content",
              fontSize: width > 700 ? "1.1vw" : "3vw",
            }}
            className="servicesmenuname4box"
          >
            <span
              style={{ color: "red", cursor: "pointer" }}
              onClick={() => {
                const index = arrayofservices.length - 1;

                setArrayofservices([
                  ...arrayofservices.slice(0, arrayofservices.length - 1),
                ]);
              }}
            >
              Remove
            </span>
          </div>
        </div>

        {arrayofservices?.map((data, index) => {
          return (
            <Productdata
              width={width}
              data={data}
              index={index}
              setArrayofservices={setArrayofservices}
              arrayofservices={arrayofservices}
              products={products}
              setSelectedCategory={setSelectedCategory}
              setsetSelectedCategory={setsetSelectedCategory}
            />
          );
        })}

        <div
          style={{
            marginTop: "2vw",
            marginBottom: "1.5vw",
            width: "fit-content",
          }}
          className="addmoreservicecatalog"
          onClick={() => {
            setArrayofservices([
              ...arrayofservices,
              {
                productName: "",
                extraDescription: "",
                productGst: "0",
                productGstValue: "",
                hsnCode: "",
                rate: "",
                qty: 0,
                value: "",
                totalValue: "",
              },
            ]);
          }}
        >
          <span>
            <AddIcon style={{ fontSize: width > 700 ? "1.3vw" : "4vw" }} />
          </span>{" "}
          Add More Products/services
        </div>

        <div
          style={{
            left: "0vw",
            width: "99%",
            margin: width > 700 ? "1vw 0.5vw 2vw 0vw" : "4vw 0",
          }}
          className="loginfield"
        >
          <TextField
            id="outlined-basic"
            label="Total Taxable Price (Rs.)"
            value={totalprice}
            type="number"
            disabled
            variant="outlined"
            style={{ width: "100%" }}
            InputLabelProps={{
              style: {
                fontSize: width > 700 ? "1vw" : "3vw",
                fontFamily: "Poppins",
                fontStyle: "500",
                fontWeight: "500",
                color: "black",
              },
            }}
            inputProps={{ className: classes.input }}
            onChange={(e) => settotalprice(e.target.value)}
          />
        </div>
        <div
          style={{
            left: "0vw",
            width: "99%",
            margin: width > 700 ? "1vw 0.5vw 2vw 0vw" : "4vw 0",
          }}
          className="loginfield"
        >
          <TextField
            id="outlined-basic"
            label="Total Tax (Rs.)"
            value={texable}
            type="number"
            disabled
            variant="outlined"
            style={{ width: "100%" }}
            InputLabelProps={{
              style: {
                fontSize: width > 700 ? "1vw" : "3vw",
                fontFamily: "Poppins",
                fontStyle: "500",
                fontWeight: "500",
                color: "black",
              },
            }}
            inputProps={{ className: classes.input }}
            onChange={(e) => settotalprice(e.target.value)}
          />
        </div>

        <div
          style={{
            left: "0vw",
            width: "99%",
            margin: width > 700 ? "0vw 0.5vw 2vw 0vw" : "4vw 0",
          }}
          className="loginfield"
        >
          <TextField
            id="outlined-basic"
            label="Extra charges (Rs.)"
            value={extracharges}
            type="number"
            variant="outlined"
            style={{ width: "100%" }}
            InputLabelProps={{
              shrink: true,
              style: {
                fontSize: width > 700 ? "1vw" : "3vw",
                fontFamily: "Poppins",
                fontStyle: "500",
                fontWeight: "500",
                color: "black",
              },
            }}
            inputProps={{ className: classes.input }}
            onChange={(e) => setExtracharges(e.target.value)}
          />
        </div>

        <div
          style={{
            left: "0vw",
            width: "99%",
            margin: width > 700 ? "0vw 0.5vw 2vw 0vw" : "4vw 0",
          }}
          className="loginfield"
        >
          <TextField
            id="outlined-basic"
            label="Total Invoice value (Rs.)"
            value={grandtotal}
            type="number"
            disabled
            variant="outlined"
            style={{ width: "100%" }}
            InputLabelProps={{
              style: {
                fontSize: width > 700 ? "1vw" : "3vw",
                fontFamily: "Poppins",
                fontStyle: "500",
                fontWeight: "500",
                color: "black",
              },
            }}
            inputProps={{ className: classes.input }}
            onChange={(e) => setgrandtotal(e.target.value)}
          />
        </div>

        {type === "Product" && (
          <div>
            <div
              style={{
                left: "0vw",
                width: "99%",
                margin: width > 700 ? "1vw 0.5vw 2vw 0vw" : "4vw 0",
              }}
              className="loginfield"
            >
              <TextField
                id="outlined-basic"
                label="Mode of transport"
                value={modeoftransport}
                variant="outlined"
                style={{ width: "100%" }}
                InputLabelProps={{
                  style: {
                    fontSize: width > 700 ? "1vw" : "3vw",
                    fontFamily: "Poppins",
                    fontStyle: "500",
                    fontWeight: "500",
                    color: "black",
                  },
                }}
                inputProps={{ className: classes.input }}
                onChange={(e) => setModeoftransport(e.target.value)}
              />
            </div>
            <div
              style={{
                left: "0vw",
                width: "99%",
                margin: width > 700 ? "0vw 0.5vw 2vw 0vw" : "4vw 0",
              }}
              className="loginfield"
            >
              <TextField
                id="outlined-basic"
                label="Vehicle No"
                value={vehicalno}
                variant="outlined"
                style={{ width: "100%" }}
                InputLabelProps={{
                  style: {
                    fontSize: width > 700 ? "1vw" : "3vw",
                    fontFamily: "Poppins",
                    fontStyle: "500",
                    fontWeight: "500",
                    color: "black",
                  },
                }}
                inputProps={{ className: classes.input }}
                onChange={(e) => setvehicalno(e.target.value)}
              />
            </div>
            <div
              style={{
                left: "0vw",
                width: "99%",
                margin: width > 700 ? "0vw 0.5vw 2vw 0vw" : "4vw 0",
              }}
              className="loginfield"
            >
              <TextField
                id="outlined-basic"
                label="GR No"
                value={grno}
                variant="outlined"
                style={{ width: "100%" }}
                InputLabelProps={{
                  style: {
                    fontSize: width > 700 ? "1vw" : "3vw",
                    fontFamily: "Poppins",
                    fontStyle: "500",
                    fontWeight: "500",
                    color: "black",
                  },
                }}
                inputProps={{ className: classes.input }}
                onChange={(e) => setGrno(e.target.value)}
              />
            </div>
            <div
              style={{
                left: "0vw",
                width: "99%",
                margin: width > 700 ? "0vw 0.5vw 2vw 0vw" : "4vw 0",
              }}
              className="loginfield"
            >
              <TextField
                id="outlined-basic"
                label="E-way Bill no"
                value={ewaybill}
                variant="outlined"
                style={{ width: "100%" }}
                InputLabelProps={{
                  style: {
                    fontSize: width > 700 ? "1vw" : "3vw",
                    fontFamily: "Poppins",
                    fontStyle: "500",
                    fontWeight: "500",
                    color: "black",
                  },
                }}
                inputProps={{ className: classes.input }}
                onChange={(e) => setEwaybill(e.target.value)}
              />
            </div>
            <div
              style={{
                left: "0vw",
                width: "99%",
                margin: width > 700 ? "0vw 0.5vw 2vw 0vw" : "4vw 0",
              }}
              className="loginfield"
            >
              <TextField
                id="outlined-basic"
                label="E-way Date"
                type="date"
                value={ewaydate}
                variant="outlined"
                style={{ width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    fontSize: width > 700 ? "1vw" : "3vw",
                    fontFamily: "Poppins",
                    fontStyle: "500",
                    fontWeight: "500",
                    color: "black",
                  },
                }}
                inputProps={{ className: classes.input }}
                onChange={(e) => setEwaydate(e.target.value)}
              />
            </div>
          </div>
        )}
        <hr style={{ color: "#000000" }} />
        <div style={{ color: "black" }} className="cateaddlastcheck">
          <div>
            * Your Invoice No and Your Company details will automatically
            genrate , you can add , edit your edit details from settings
          </div>
        </div>

        <div className="cateaddlastcheck">
          <div className="redp" style={{ color: "red" }}>
            {!companyid &&
              "Please fill your company details in setting for genrating Invoice"}
          </div>
        </div>
        <span
          style={{
            color: "red",
            fontSize: width > 700 ? "0.9vw" : "2.7vw",
          }}
        >
          {showerror}
        </span>
        <div style={{ marginTop: "0.31vw" }} className="handlemoreaboutskill">
          <div
            style={{
              background: "white",
              color: "black",
              cursor: "pointer",
            }}
            className="handlecirclieaboutsave"
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </div>

          <div
            style={{ cursor: "pointer" }}
            className="handlecirclieaboutsave"
            onClick={() => {
              companyid && handlesavedaddcatalogue();
            }}
          >
            Create
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open121}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
