import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import "./Addprojrctcomp.css";
import { makeStyles } from "@material-ui/core";
import { TextField } from "@mui/material";
import imageCompression from "browser-image-compression";
import API_HOST from "../../../env";
import img1 from "../../../assets/Web 1280 – 14/Group 9831.svg";
import img from "../../../assets/Web 1280 – 14/Icon.svg";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { useSelector } from "react-redux";
import img21 from "../../../assets/My profile – 28/Component 85 – 16 (1).svg";
const useStyles = makeStyles((theme) => ({
  input: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "0.91vw",
    color: "#263238",
    border: "yellow !important",
  },
}));
export default function EditPortfolio({ width }) {
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0, { behavior: "smooth" });
  }, []);
  const [arrayoffiles, setArrayoffiles] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [date, setdate] = useState();
  const [datex, setdatex] = useState();
  const [caption, setCaption] = useState("");

  const [skillset, setskillset] = useState([]);
  const [projecturl, setProjecturl] = useState("");
  const [projectdescription, setProjectdescription] = useState("");

  const [setSelectedCategory, setSetSelectedCategory] = useState("");
  const [checkedtocompany, setCheckedtocompany] = useState(false);
  const { user, loggedInStatus } = useSelector((state) => state.user);

  const handlepublish = () => {
    const formdata = new FormData();

    formdata.append("userId", user?.userId);
    formdata.append("projectDescription", projectdescription);
    formdata.append("projectTitle", title);
    formdata.append("projectUrl", projecturl);
    formdata.append("completionDate", date);
    formdata.append("startingDate", datex);

    formdata.append("caption", caption);
    formdata.append("skills", JSON.stringify(skillset));
    formdata.append("projectPortfolioId", id);

    axios
      .post(`${API_HOST}/portfolio/editProjectPortfolio`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        navigate("/dashboard/profile");
      })
      .catch((err) => {});
  };

  useEffect(() => {
    window.scrollTo(0, 0, { behavior: "smooth" });

    axios
      ?.get(
        `${API_HOST}/portfolio/viewProjectPortfolio?pageSize=8&pageNumber=1&projectPortfolioId=${id}`
      )
      .then((res) => {
        setCaption(res?.data?.success?.data[0]?.caption);
        setProjectdescription(res?.data?.success?.data[0]?.projectDescription);
        setProjecturl(res?.data?.success?.data[0]?.projectUrl);
        setTitle(res?.data?.success?.data[0]?.projectTitle);
        setskillset(res?.data?.success?.data[0]?.skills);
        setdatex(res?.data?.success?.data[0]?.startingDate);
        setdate(res?.data?.success?.data[0]?.completionDate);
        setCheckedtocompany(res?.data?.success?.data[0]?.currentlyWorking);
        setArrayoffiles(res?.data?.success?.data[0]?.icons);
      });
  }, [id]);

  const updateFile = (data) => {
    const formdata = new FormData();
    formdata.append("projectPortfolioId", id);
    formdata.append("fileName", data);
    axios
      .post(`${API_HOST}/portfolio/updateFiles`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        setArrayoffiles(res?.data?.success?.data?.icons);
      });
  };

  const removefile = (data, index) => {
    const formdata = new FormData();
    formdata.append("projectPortfolioId", id);
    formdata.append("_id", data);
    axios
      .post(`${API_HOST}/portfolio/removeFiles`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        setArrayoffiles([
          ...arrayoffiles.slice(0, index),
          ...arrayoffiles.slice(index + 1, arrayoffiles.length),
        ]);
      });
  };

  async function handleImageUpload(event) {
    const imageFile = event.target.files[0];
    console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log(
        "compressedFile instanceof Blob",
        compressedFile instanceof Blob
      ); // true
      console.log(
        `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
      ); // smaller than maxSizeMB

      //   await uploadToServer(compressedFile); // write your own logic
      await updateFile(new File([compressedFile], `${compressedFile?.name}`));
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div
      className="addpf-container ScrollTable"
      style={{ height: "calc( 100vh - 4vw )" }}
    >
      <div className="addpfcontaineradd">
        <button
          style={{
            cursor: "pointer",
            zIndex: "100",
            padding: width > 700 ? "0.5vw 0.7vw" : "1vw",
            backgroundColor: "white",
            color: "#000",
            borderRadius: "0.3vw",
            border: "1px solid #d7d7d7",
            marginLeft: width > 700 ? "0vw" : "1vw",
            marginTop: width <= 700 && "1vw",
            marginBottom: "1vw",
          }}
          onClick={() => navigate(-1)}
        >
          <ArrowBackIosNewIcon />
        </button>
        <div className="profiletitleandmenunav">
          <div
            style={{
              fontSize: width > 700 ? "1.7vw" : "4vw",
              fontWeight: "400",
            }}
            className="profiledetailstitle"
          >
            Edit Portfolio Project
          </div>
          <div className="profiledetailnavmanu">
            <div></div>
          </div>
        </div>
        <hr style={{ color: "#00000070" }} />
        <div
          style={{ left: "0vw", width: "99%", margin: "1.5vw 0.5vw 0vw 0vw" }}
          className="loginfield"
        >
          <TextField
            id="outlined-basic"
            label="Title"
            variant="outlined"
            value={title}
            style={{ width: "100%" }}
            InputLabelProps={{
              style: {
                fontSize: width > 700 ? "1vw" : "3vw",
                fontFamily: "Poppins",
                fontStyle: "500",
                fontWeight: "500",
                color: "black",
              },
            }}
            inputProps={{ className: classes.input }}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <label style={{ marginTop: "1.3vw" }} htmlFor="checkbox">
          <input
            type="checkbox"
            onChange={() => {
              setCheckedtocompany(!checkedtocompany);
            }}
            name=""
            checked={checkedtocompany}
            id="checkbox"
          />

          <div
            className=""
            style={{
              fontSize: width > 700 ? "1vw" : "3vw",
              fontFamily: "Poppins",
              fontStyle: "500",
              fontWeight: "500",
              color: "#000000",
              display: "inline",
              margin: "0 1vw",
            }}
          >
            Currently working here
          </div>
        </label>
        <div style={{ display: "flex", width: "70vw" }}>
          <div style={{ width: "35vw" }}>
            <div
              style={{ marginBottom: "0.5vw", marginTop: "1.3vw" }}
              className="jobpodtedfieldtitile"
            >
              Starting Date (optional)
            </div>
            <div
              style={{
                left: "0vw",
                width: width > 700 ? "90%" : "100%",
                margin: width > 700 ? "0.5vw 0.5vw 2vw 0vw" : "1vw 0",
              }}
              className="loginfield"
            >
              <input
                type="date"
                className="pfadddate"
                value={datex}
                onChange={(e) => {
                  setdatex(e.target.value);
                }}
              />
            </div>
          </div>
          {!checkedtocompany && (
            <div style={{ width: "35vw" }}>
              <div
                style={{ marginBottom: "0.5vw", marginTop: "1.3vw" }}
                className="jobpodtedfieldtitile"
              >
                Completion Date (optional)
              </div>
              <div
                style={{
                  left: "0vw",
                  width: width > 700 ? "90%" : "100%",
                  margin: width > 700 ? "0.5vw 0.5vw 2vw 0vw" : "1vw 0",
                }}
                className="loginfield"
              >
                <input
                  type="date"
                  className="pfadddate"
                  value={date}
                  onChange={(e) => {
                    setdate(e.target.value);
                  }}
                />
              </div>
            </div>
          )}
        </div>

        <div
          style={{
            background: "white",
            padding: "0.51vw",
            marginTop: "0vw",
            paddingRight: "3vw",
          }}
        >
          <div className="inputfilebox">
            <div>
              <label htmlFor="inputctaelogfile">
                <div className="inputicon">
                  <img src={img} alt="" />
                </div>
                <div className="inputcateaddformfile">
                  Drag and Drop ,Browse to upload
                </div>
                <input
                  type="file"
                  id="inputctaelogfile"
                  onChange={(e) => {
                    handleImageUpload(e);
                  }}
                  hidden
                />
              </label>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            fontSize: width > 700 ? "0.8vw" : "2.7vw",
            paddingRight: "4.5vw",
            textAlign: "right",
          }}
        >
          Please upload file having extensions .jpeg/ .jpg/ .png only.
          <br />
          Image should be less then 512 kb.
        </div>
        <div
          className={arrayoffiles?.length > 0 ? "inputfilesshowncatebox" : ""}
        >
          {arrayoffiles?.length > 0 &&
            arrayoffiles?.map((file, index) => {
              return (
                <div className="inputfilesshowncatboxsingle">
                  <div className="inputfilesshowncatboxsingleimg">
                    <img src={img1} alt="" />
                  </div>
                  <div className="fileselctednamecate">
                    {file?.icon?.split("$")[1]}
                  </div>
                  <div className="inputfilesshowncatboxsingleimg">
                    <img
                      style={{
                        width: width > 700 ? "1.5vw" : "4.5vw",
                        marginLeft: "1vw",
                        cursor: "pointer",
                      }}
                      src={img21}
                      alt=""
                      onClick={() => {
                        removefile(file?._id, index);
                      }}
                    />
                  </div>
                </div>
              );
            })}
        </div>

        <div
          hidden
          style={{ left: "0vw", width: "99%", margin: "2vw 0.5vw 2vw 0vw" }}
          className="loginfield"
        >
          <TextField
            id="outlined-basic"
            label="Caption"
            value={caption}
            variant="outlined"
            style={{ width: "100%" }}
            InputLabelProps={{
              style: {
                fontSize: width > 700 ? "1vw" : "3vw",
                fontFamily: "Poppins",
                fontStyle: "500",
                fontWeight: "500",
                color: "black",
              },
            }}
            inputProps={{ className: classes.input }}
            onChange={(e) => setCaption(e.target.value)}
          />
        </div>

        <div
          style={{
            marginBottom: "0.5vw",
            marginTop: "1.3vw",
            fontSize: width > 700 ? "1.2vw" : "3.5vw",
          }}
          className="jobpodtedfieldtitile"
        >
          Skills
        </div>
        <div
          style={{ marginRight: "0.5vw", height: "fit-content" }}
          className="boxofskillsp"
        >
          <div className="savedskillpopupcardp">
            {skillset?.slice(0, 10)?.map((skill, index) => {
              return (
                <div
                  style={{
                    borderRadius: "2vw",
                    padding: width > 700 ? " 0.5vw 1vw" : "1vw 2vw",
                    margin: width > 700 ? "0.51vw 0.5vw" : "1vw",
                  }}
                  className="savedskillwrapsinglep"
                >
                  {skill}
                  <div
                    onClick={() => {
                      setskillset([
                        ...skillset.slice(0, index),
                        ...skillset.slice(index + 1, skillset.length),
                      ]);
                    }}
                  >
                    <div>
                      <CloseIcon
                        style={{
                          fontSize: width > 700 ? "1.4vw" : "4vw",
                          marginLeft: "0.61vw",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </div>{" "}
                </div>
              );
            })}
            <div style={{ height: width > 700 ? "3vw" : "8vw" }}>
              {skillset?.length < 10 ? (
                <input
                  style={{
                    border: "none",
                    outline: "none",
                    background: "white",
                    height: width > 700 ? "3vw" : "8vw",
                    padding: "0.5vw",
                    width: "fit-content",
                    minWidth: "5vw",
                    fontSize: width > 700 ? "1.1vw" : "3.3vw",
                  }}
                  type="text"
                  placeholder="type here...."
                  value={setSelectedCategory}
                  onChange={(e) => {
                    setSetSelectedCategory(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      if (skillset.indexOf(setSelectedCategory) < 0) {
                        setskillset(
                          [...skillset, setSelectedCategory].slice(0, 10)
                        );
                        setSetSelectedCategory("");
                      }
                    }
                  }}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <div className="editsillpopupskilltext">Maximum 10 skills </div>

        <div
          style={{ left: "0vw", width: "99%", margin: "2vw 0.5vw 2vw 0vw" }}
          className="loginfield"
        >
          <TextField
            id="outlined-basic"
            label="Project URL (optional)"
            variant="outlined"
            value={projecturl}
            style={{ width: "100%" }}
            InputLabelProps={{
              style: {
                fontSize: width > 700 ? "1vw" : "3vw",
                fontFamily: "Poppins",
                fontStyle: "500",
                fontWeight: "500",
                color: "black",
              },
            }}
            inputProps={{ className: classes.input }}
            onChange={(e) => {
              setProjecturl(e.target.value);
            }}
          />
        </div>

        <div
          style={{
            left: "0vw",
            width: "99%",
            margin: width > 700 ? "3vw 0.5vw 2vw 0vw" : "5vw 0",
          }}
          className="loginfield"
        >
          <TextField
            id="outlined-basic"
            label="Project Description"
            variant="outlined"
            value={projectdescription}
            multiline
            rows="8"
            style={{ width: "100%" }}
            InputLabelProps={{
              style: {
                fontSize: width > 700 ? "1vw" : "3vw",
                fontFamily: "Poppins",
                fontStyle: "500",
                fontWeight: "500",
                color: "black",
              },
            }}
            inputProps={{ className: classes.input }}
            onChange={(e) => {
              setProjectdescription(e.target.value);
            }}
          />
        </div>
        <div
          style={{
            marginBottom: "0.3vw",
            fontWeight: "400",
            color: "#000000",
            fontSize: width > 700 ? "0.91vw" : "2.7vw",
            width: "100%",
            lineHeight: width > 700 ? "1.3vw" : "4vw",
          }}
          className="jobpodtedfieldtitile"
        >
          Make sure you have approval from your clients to display the work
          you've done for them publicly.
        </div>
        <div
          style={{
            marginTop: "0.31vw",
            marginBottom: width < 700 && "5vw",
            width: "98%",
          }}
          className="handlemoreaboutskill"
        >
          <div
            onClick={() => navigate(-1)}
            style={{
              background: "white",
              color: "black",
              cursor: "pointer",
            }}
            className="handlecirclieaboutsave"
          >
            Cancel
          </div>

          <div
            style={{
              cursor: "pointer",
              width: width > 700 ? "10vw" : "30vw",
              borderRadius: width > 700 ? "0.5vw" : "1vw",
            }}
            className="handlecirclieaboutsave"
            onClick={handlepublish}
          >
            Publish
          </div>
        </div>
      </div>
    </div>
  );
}
