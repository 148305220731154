import React, { useEffect, useState } from "react";
import img1 from "../../assets/My profile – 28/Component 70 – 6.svg";
import "./profile.css";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";

import { TextField } from "@mui/material";
import ProfileotherDetails from "./Profilepopup/ProfileOtherdetails";
import API_HOST from "../../env";
import axios from "axios";
import { useDispatch } from "react-redux";
import { userActions } from "../../store/userSlice";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 3,
};

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
};

const useStyles = makeStyles((theme) => ({
  input: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "0.91vw",
    color: "#263238",
    border: "yellow !important",
  },
}));
export default function ProfileWorkexperince({ user, width }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();

  const [year1, setyear1] = useState("");

  const [year2, setyear2] = useState("");

  const [descriptioncallageadd, setdescriptioncallageadd] = useState("");
  const [callagename, setCallagename] = useState("");

  const handleaddcallage = () => {
    if (callagename !== "") {
      const formdata = {
        description: descriptioncallageadd,
        title: callagename,

        fromDate: year1,
        toDate: year2,
      };
      axios
        .post(`${API_HOST}/users/updateOthers`, formdata, {
          headers: {
            // "Content-Type": "multipart/form-data",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((res) => {
          if (user) {
            axios
              .get(
                `${API_HOST}/users/viewOthers?pageSize=50&pageNumber=1&user_id=${user?._id}`
              )
              .then((res) => {
                setcertificated(res?.data?.success?.data);
              });
          }
          handleClose();
          setCallagename('')
          setyear1("");
          setyear2("");
          setdescriptioncallageadd("");
         
        });
    } else {
      setWrongcallageaddname(true);
    }
  };
  const [certificated, setcertificated] = useState([]);
  useEffect(() => {
    if (user?.userName) {
      axios
        .get(
          `${API_HOST}/users/viewOthers?pageSize=50&pageNumber=1&user_id=${user?._id}`
        )
        .then((res) => {
          setcertificated(res?.data?.success?.data);
        });
    }
  }, [user]);
  const [wrongcallageaddname, setWrongcallageaddname] = useState(false);

  return (
    <div>
      <div style={{ height: "fit-content" }} className="profilebadgecontainer">
        <div
          style={{ padding: "1.5vw", margin: "0 0vw" }}
          className="profiletitleandmenunav"
        >
          <div className="profiledetailstitle">Others</div>
          <div className="profiledetailnavmanu">
            <img
              onClick={handleOpen}
              style={{ width: width > 700 ? "2.5vw" : "6vw" }}
              src={img1}
              alt=""
            />
          </div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={width > 700 ? style : style1}>
              <div className="profiletitleandmenunav">
                <div className="profiledetailstitle">Add others</div>
                <div className="profiledetailnavmanu">
                  <div>
                    <CloseIcon
                      onClick={handleClose}
                      style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{ left: "0vw", width: "100%" }}
                className="loginfield"
              >
                <TextField
                  error={!wrongcallageaddname ? false : true}
                  value={callagename}
                  id="outlined-basic"
                  label="Title"
                  variant="outlined"
                  style={{ width: "100%" }}
                  InputLabelProps={{
                    style: {
                      fontSize: width > 700 ? "1vw" : "3vw",
                      fontFamily: "Poppins",
                      fontStyle: "500",
                      fontWeight: "500",
                      color: !wrongcallageaddname ? "black" : "#dc3545",
                    },
                  }}
                  onChange={(e) => {
                    setWrongcallageaddname(false);
                    setCallagename(e.target.value);
                  }}
                  inputProps={{ className: classes.input }}
                />
              </div>
              <div className="jobpodtedfieldtitile">From</div>
              <div style={{ display: "flex", width: "100%" }}>
                <div
                  style={{
                    left: "0vw",
                    width: "50%",
                    margin: "0.3vw 0",
                  }}
                  className="loginfield"
                  // onClick={handleClickx}
                >
                  <div className="" style={{ width: "100%" }}>
                    <div style={{ width: "100%" }}>
                      <div className="jobpostfieldinputbox">
                        <input
                          style={{ width: "100%" }}
                          type="date"
                          className="input-homejobformdate"
                          name=""
                          id=""
                          // min={disablePastDate()}
                          max={"2025-12-31"}
                          maxlength="4"
                          onChange={(e) => {
                            setyear1(e.target.value);
                          }}
                          // onChange={(e) => {
                          //   setDatestart(e.target.value);
                          // }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="jobpodtedfieldtitile">To</div>
              <div style={{ display: "flex", width: "100%" }}>
                <div
                  style={{
                    left: "0vw",
                    width: "50%",
                    margin: "0.3vw 0",
                  }}
                  className="loginfield"
                  // onClick={handleClickx2}
                >
                  <div className="" style={{ width: "100%" }}>
                    <div style={{ width: "100%" }}>
                      <div className="jobpostfieldinputbox">
                        <input
                          style={{ width: "100%" }}
                          type="date"
                          className="input-homejobformdate"
                          name=""
                          id=""
                          // min={disablePastDate()}
                          max={"2025-12-31"}
                          maxlength="4"
                          onChange={(e) => {
                            setyear2(e.target.value);
                          }}
                          // onChange={(e) => {
                          //   setDatestart(e.target.value);
                          // }}
                        />
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </div>{" "}
              <div
                style={{ left: "0vw", width: "100%" }}
                className="loginfield"
              >
                <TextField
                  id="outlined-basic"
                  label="Description (Optional)"
                  variant="outlined"
                  value={descriptioncallageadd}
                  multiline
                  rows="6"
                  style={{ width: "100%" }}
                  InputLabelProps={{
                    style: {
                      fontSize: width > 700 ? "1vw" : "3vw",
                      fontFamily: "Poppins",
                      fontStyle: "500",
                      fontWeight: "500",
                      color: "black",
                    },
                  }}
                  inputProps={{ className: classes.input }}
                  onChange={(e) => {
                    setdescriptioncallageadd(e.target.value);
                  }}
                />
              </div>
              {/* <hr style={{ color: "#000000" }} /> */}
              <div
                style={{ marginTop: "0.31vw" }}
                className="handlemoreaboutskill"
              >
                <div
                  style={{
                    background: "white",
                    color: "black",
                    cursor: "pointer",
                  }}
                  className="handlecirclieaboutsave"
                  onClick={handleClose}
                >
                  Cancel
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  className="handlecirclieaboutsave"
                  onClick={handleaddcallage}
                >
                  SAVE
                </div>
              </div>
            </Box>
          </Modal>
      
        </div>
        {certificated?.length > 0 &&
          certificated?.map((other) => {
            return (
              <ProfileotherDetails
                other={other}
                width={width}
                setcertificated={setcertificated}
                user={user}
              />
            );
          })}
      </div>
    </div>
  );
}
