import React from "react";
import BlogBannercarosel from "./BlogBannerCaousel";
import "./BlogDetail.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
export default function BlogDetailBanner({ data, width }) {
  const navigate = useNavigate();

  return (
    <div className="Blogdbannercontainer">
      <Helmet>
        <title>{`${data?.metaTitle}`}</title>
        <meta name="description" content={data?.metaDescription} />
        <meta
          property="og:url"
          content={`https://www.44resources.com/blogs/${data?.contentName}`}
        ></meta>
    
        <meta property="og:description" content={data?.metaDescription} />
        <meta property="og:title" content={data?.metaTitle} />
        <meta
          property="og:keywords"
          content={`${data?.metaTitle},${data?.contentName},${data?.slug}`}
        />
        <meta
          property="keywords"
          content={`${data?.metaTitle},${data?.contentName},${data?.slug}`}
        />
      </Helmet>
      <button
        style={{
          marginTop: "1vw",
          cursor: "pointer",
          zIndex: "100",
          padding: "0.5vw 0.7vw",
          backgroundColor: "white",
          color: "#000",
          borderRadius: width > 700 ? "0.3vw" : "1vw",
          border: "1px solid #d7d7d7",
        }}
        onClick={() => navigate(-1)}
      >
        <ArrowBackIosNewIcon />
      </button>
      <div style={{ width: "70vw" }} className="blogdbtitile">
        {data?.contentName}
      </div>{" "}
      <div>
        <div
          style={{ position: "relative", top: width <= 700 && "3vw" }}
          className="ctatlogcarouseltitle"
        >
          <div className="textofcontainercatalgue">{data?.iconTitle}</div>
        </div>
        <div className="bannerblogimagescaurpsel">
          {data && (
            <BlogBannercarosel
              imagealttag={data?.imageAltTag}
              width={width}
              img1={data?.icon[0]}
              img2={data?.icon[1] ? data?.icon[1] : data?.icon[0]}
              img3={
                data?.icon[2]
                  ? data?.icon[2]
                  : data?.icon[0]
                  ? data?.icon[0]
                  : data?.icon[1]
              }
              img4={
                data?.icon[3]
                  ? data?.icon[3]
                  : data?.icon[1]
                  ? data?.icon[1]
                  : data?.icon[0]
                  ? data?.icon[0]
                  : data?.icon[2]
              }
            />
          )}
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            lineHeight: width > 700 ? "3vw" : "7vw",
            paddingLeft: "2.5vw",
            fontSize: width > 700 ? "1.5vw" : "4vw",
            fontWeight: "400",
            width: width > 700 ? "25%" : "23vw",
          }}
        >
          Category
        </div>

        <div
          style={{
            lineHeight: width > 700 ? "3vw" : "7vw",
            paddingLeft: "2.5vw",
            fontSize: width > 700 ? "1.5vw" : "4vw",
            fontWeight: "400",
            width: width > 700 ? "25%" : "fit-content",
          }}
        >
          Publish On
        </div>
        <div
          style={{
            lineHeight: width > 700 ? "3vw" : "7vw",
            paddingLeft: "2.5vw",
            fontSize: width > 700 ? "1.5vw" : "4vw",
            fontWeight: "400",
            width: width > 700 ? "25%" : "fit-content",
          }}
        >
          Views
        </div>
        <div
          style={{
            lineHeight: width > 700 ? "3vw" : "7vw",
            paddingLeft: "2vw",
            fontSize: width > 700 ? "1.5vw" : "4vw",
            fontWeight: "400",
            width: width > 700 ? "25%" : "fit-content",
          }}
        >
          Author
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ width: "25%" }}>
          <button
            style={{
              marginLeft: "2vw",
              cursor: "auto",
              zIndex: "100",
            }}
            className="hb-button"
          >
            {data?.category}
          </button>
        </div>

        <div
          style={{
            lineHeight: "2vw",
            paddingLeft: "2.5vw",
            fontSize: width > 700 ? "1.2vw" : "3.2vw",
            fontWeight: "400",
            width: width > 700 ? "25%" : "fit-content",
          }}
        >
          {/* {data?.created_at?.slice(0, 10)} */}
          {new Date(data?.created_at).getDate()}/
          {new Date(data?.created_at).getMonth() + 1}/
          {new Date(data?.created_at).getFullYear()}
        </div>
        <div
          style={{
            lineHeight: "2vw",
            paddingLeft: "2.5vw",
            fontSize: width > 700 ? "1.2vw" : "3.2vw",
            fontWeight: "400",
            width: width > 700 ? "25%" : "fit-content",
          }}
        >
          {data?.views}
        </div>
        <div
          style={{
            lineHeight: "2vw",
            paddingLeft: "2vw",
            fontSize: width > 700 ? "1.2vw" : "3.2vw",
            fontWeight: "400",
            width: width > 700 ? "25%" : "fit-content",
          }}
        >
          {data?.author}
        </div>
      </div>
    </div>
  );
}
