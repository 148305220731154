import React from "react";

import "../Catalogue/catalogue.css";
import SearchIcon from "@mui/icons-material/Search";
import Catalogfaqquestion from "./Catalogfaqquestion";
export default function SettingFaq({ width }) {
  return (
    <div>
      <div
        style={{ margin: "2vw auto", marginBottom: "5vw" ,width:width>700?'65vw':"90vw" }}
        className="cafaqcontainer"
      >
        <div
          style={{
            border: "none",
            alignItems: "center",
            margin: "auto",
            width: width > 700 ?"20vw":"60vw",
            marginBottom:width > 700 ?"1vw":"5vw"
          }}
          className="catesearchboxfaq"
        >
          <span hidden>
            <SearchIcon style={{ margin: "0vw 0.81vw", fontSize: "1.7vw" }} />
          </span>
          <input
            type="text"
            value="Have questioners? we're here to help"
            disabled
          />
        </div>
        <Catalogfaqquestion
          width={width}
          data={{
            question:
              "What is the pricing structure for Tool44's annual domestic and foreign trips feature?",
            answer:
              "The annual domestic and foreign trip feature is available after the 12-month subscription plan.",
          }}
        />
        <Catalogfaqquestion
          width={width}
          data={{
            question:
              "Where can I find notifications for rewards and contests on the Tool44 platform?",
            answer:
              "Notifications for rewards and contests will be displayed on the dashboard banner",
          }}
        />
        <Catalogfaqquestion
          width={width}
          data={{
            question: " What is the refund policy for Tool44 membership?",
            answer:
              "The refund policy for Tool44 membership allows for refunds within 7 days of the initial purchase. If you are not satisfied with your membership and wish to request a refund, you must submit your refund request within this timeframe. <br /> Please note that the 7-day refund policy is subject to certain conditions. These may include providing proof of purchase, adhering to any additional requirements outlined in the refund policy, and returning any physical products (if applicable) in their original condition. To initiate a refund for your Tool44 membership, please contact our customer support team. They will guide you through the refund process and assist you with any necessary steps or documentation required. <br/>  We recommend reviewing the full refund policy, available on our website or in the terms and conditions, for further details and specific eligibility criteria. If you have any additional questions or need further assistance, our customer support team will be happy to assist you.",
          }}
        />
      </div>
    </div>
  );
}
