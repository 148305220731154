import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import { useNavigate } from 'react-router';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import API_HOST from '../../../env';
import { Popover, TextField, Typography } from '@mui/material';
import { KeyboardArrowDownOutlined } from '@mui/icons-material';
import { makeStyles } from '@material-ui/core';
import coinSymbol from '../../../assets/walletimage/coin_symbol.png';

const useStyles = makeStyles((theme) => ({
  select: {
    height: '2.5vw',
    width: '100%',
    marginTop: '0.2vw',
    padding: '1vw',
    marginLeft: '0vw',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '1vw',
    lineHeight: '120%',
    color: '#FFFFFF',
  },
  select2: {
    height: '1vw',
    width: '100%',
    marginTop: '0.1vw',
    padding: '0.9vw 0.5vw',
    marginLeft: '0vw',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '0.51vw',
    lineHeight: '100%',
    color: '#FFFFFF',
  },
  select3: {
    height: '1vw',
    width: '100%',
    marginTop: '0.1vw',
    padding: '0vw 0vw',
    marginLeft: '0vw',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '0.51vw',
    lineHeight: '100%',
    color: '#FFFFFF',
    position: 'relative',
    top: '0.81vw',
    left: '0.2vw',
  },
  icon: {
    fill: 'white',
  },
}));
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40vw',
  bgcolor: 'background.paper',
  boxShadow: 24,
  maxHeight: '90vh',
  height: 'fit-content',
  overflow: 'scroll',
  padding: '1vw',
};
const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50vw',
  bgcolor: 'background.paper',
  boxShadow: 24,
  maxHeight: '90vh',
  height: 'fit-content',
  overflow: 'scroll',
  padding: '1vw',
};

const style2 = {
  position: 'absolute',
  maxHieght: '90vh',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '94vw',
  bgcolor: 'background.paper',
  boxShadow: 24,
  maxHeight: '90vh',
  height: 'fit-content',
  overflow: 'scroll',

  padding: '2vw',
};
export default function Skillpopupcopy({
  width,
  data,
  checktransaction,
  setChecktransaction,
}) {
  const [openx, setOpenx] = React.useState(false);
  const handleOpenx = () => setOpenx(true);
  const handleClosex = () => setOpenx(false);
  const [name, setName] = useState('');
  const classes = useStyles();
  const [desc, setDesc] = useState('');
  const [titileError, setTitileError] = useState('');
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAddIssue = () => {
    if (!data?.ticketId?.ticketId) {
      if (!name) {
        setTitileError('Please select Issue Type');
      }

      axios
        .post(
          `${API_HOST}/tickets/createTicket`,
          {
            transactionId: data?._id,
            transactionType: name,
            description: desc,
          },
          {
            headers: {
              Authorization:
                'Bearer ' + JSON.parse(localStorage.getItem('token')),
            },
          }
        )
        .then((res) => {
          setChecktransaction(!checktransaction);
          handleClose();
          setDesc('');
        });
    }
  };
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const open1 = Boolean(anchorEl1);
  const id1 = open1 ? 'simple-popover' : undefined;
  const [arrayofgst, setarrayofgst] = useState([
    'Payment issue',
    'Fraud Or scam',
    'Other issues',
  ]);
  return (
    <div
      style={{
        alignItems: 'center',
        padding: '0.75vw 1vw',
        margin: width > 700 ? '1vw 0vw' : '2vw 0vw',
        width: width > 700 ? '82vw' : '224vw',
      }}
      className='navoftableblogsdata'
    >
      <div style={{ width: width > 700 ? '14vw' : '40vw' }}>
        {data?.transactionPurpose}
      </div>
      <div style={{ width: width > 700 ? '25vw' : '65vw' }}>
        {data?.transactionId}
      </div>
      <div style={{ width: width > 700 ? '7vw' : '25vw' }}>
        {data?.transactionType}
      </div>
      <div style={{ width: width > 700 ? '10vw' : '32vw' }}>
        {data?.created_at && (
          <span>
            {new Date(data?.created_at).getHours()}:
            {new Date(data?.created_at).getMinutes()}
            {', '}
            {new Date(data?.created_at).getDate()}/
            {new Date(data?.created_at).getMonth() + 1}/
            {new Date(data?.created_at).getFullYear()}
          </span>
        )}
      </div>
      <div style={{ width: width > 700 ? '9vw' : '27vw' }}>
        <div
          className='valueofcardofexamstype'
          style={{ display: 'flex', alignItems: 'center', gap: '0.3rem' }}
        >
          <div style={{ width: '18px' }}>
            <img
              src={coinSymbol}
              style={{ width: '100%', height: '100%' }}
              alt='coin_symbol'
            />
          </div>
          <span style={{ marginTop: '0.1rem' }}>
            {data?.balance?.$numberDecimal}
          </span>
        </div>
      </div>
      <div
        style={{
          width: width > 700 ? '10vw' : '30vw',
          color:
            data?.status === 'COMPLETED' || data?.status === 'complete'
              ? '#41B541'
              : 'red',
          fontWeight: '500',
        }}
      >
        {data?.status}
      </div>

      <button
        style={{ height: width > 700 ? '2vw' : '4.5vw' }}
        className='hb-buttonx'
        onClick={() => {
          handleOpenx();
        }}
      >
        View Detail
      </button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={width > 700 ? style1 : style2}>
          <div className='profiletitleandmenunav'>
            <div className='profiledetailstitle'>Raise Issue</div>
            <div className='profiledetailnavmanu'>
              <div>
                <CloseIcon
                  onClick={handleClose}
                  style={{
                    fontSize: width > 700 ? '1.5vw' : '4vw',
                    cursor: 'pointer',
                  }}
                />
              </div>
            </div>
          </div>
          <hr style={{ color: '#000000' }} />
          <div
            style={{
              left: '0vw',
              width: width > 700 ? '92%' : '100% ',
              marginLeft: width > 700 ? '1vw' : '0vw',
            }}
            className='loginfield'
            onClick={handleClick1}
          >
            <TextField
              id='outlined-basic'
              label='Issue Type'
              variant='outlined'
              disabled
              value={name}
              style={{ width: '100%' }}
              InputLabelProps={{
                style: {
                  fontSize: width > 700 ? '1vw' : '3vw',
                  fontFamily: 'Poppins',
                  fontStyle: '500',
                  fontWeight: '500',
                  color: 'black',
                },
              }}
              inputProps={{ className: classes.input }}
              onChange={(e) => {
                console.log(e.target.value);
              }}
            />
            <span style={{ width: '0.1vw' }}>
              <KeyboardArrowDownOutlined
                style={{
                  fontSize: width > 700 ? '1.5vw' : '5vw',
                  cursor: 'pointer',
                  position: 'relative',
                  right: width > 700 ? '2vw' : '6vw',
                  top: width > 700 ? '1vw' : '3vw',
                }}
              />
            </span>
          </div>
          <Popover
            id={id1}
            open={open1}
            anchorEl={anchorEl1}
            onClose={handleClose1}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <div
              style={{
                maxHeight: width > 700 ? '18vw' : '40vw',
                overflow: 'scroll',
                width: width > 700 ? '44vw' : '94vw',
              }}
            >
              {arrayofgst?.length > 0 &&
                arrayofgst.map((data, index) => {
                  return (
                    <Typography
                      sx={{
                        p: 0.51,
                        pl: 1,
                        ml: 1,
                        width: '100%',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setName(data);
                        handleClose1();
                      }}
                    >
                      {data}
                    </Typography>
                  );
                })}
            </div>
          </Popover>

          <p style={{ color: 'red', fontSize: '0.91vw' }}>{titileError}</p>
          <div
            style={{ marginLeft: width > 700 ? '1vw' : '0vw' }}
            className='jobpodtedfieldtitile'
          >
            Issue description
          </div>
          <div
            style={{ marginLeft: width > 700 ? '1vw' : '0vw' }}
            className='jobpostfieldinputbox'
          >
            <textarea
              style={{ paddingTop: '0.5vw' }}
              type='text'
              name='desc'
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
            />
          </div>

          {
            <div
              style={{ marginTop: '0.31vw' }}
              className='handlemoreaboutskill'
            >
              <div
                style={{
                  cursor: 'pointer',
                  width: width > 700 ? '10vw' : '30vw',
                }}
                className='handlecirclieaboutsave'
                onClick={handleAddIssue}
              >
                Raise Issue
              </div>
            </div>
          }
        </Box>
      </Modal>

      <Modal
        open={openx}
        onClose={handleClosex}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={width > 700 ? style : style2}>
          <div
            style={{ padding: '1vw' }}
            className='profiletitleandmenunav'
          >
            <div className='profiledetailstitle'> Transaction Detail </div>
            <div className='profiledetailnavmanu'>
              <div>
                <CloseIcon
                  onClick={handleClosex}
                  style={{
                    fontSize: width > 700 ? '1.5vw' : '4vw',
                    cursor: 'pointer',
                  }}
                />
              </div>
            </div>
          </div>
          <hr style={{ color: '#000000' }} />
          <div
            style={{ width: '100%', paddingTop: '0vw' }}
            className='flexofbankdetails'
          >
            <div
              style={{ width: '100%' }}
              className='flexofbankdetails'
            >
              <div
                style={{ width: '50%' }}
                className='titleboxflexofbank'
              >
                Transaction :
              </div>
              <div
                style={{ fontWeight: '400', width: '100%' }}
                className='titleboxflexofbank'
              >
                {data?.transactionPurpose}
              </div>
            </div>
          </div>
          <div
            style={{ width: '100%', paddingTop: '0vw' }}
            className='flexofbankdetails'
          >
            <div
              style={{ width: '100%' }}
              className='flexofbankdetails'
            >
              <div
                style={{ width: '50%' }}
                className='titleboxflexofbank'
              >
                Transaction id :
              </div>
              <div
                style={{ fontWeight: '400', width: '100%' }}
                className='titleboxflexofbank'
              >
                {data?.transactionId}
              </div>
            </div>
          </div>
          <div
            style={{ width: '100%', paddingTop: '0vw' }}
            className='flexofbankdetails'
          >
            <div
              style={{ width: '100%' }}
              className='flexofbankdetails'
            >
              <div
                style={{ width: '50%' }}
                className='titleboxflexofbank'
              >
                Transaction Type :
              </div>
              <div
                style={{ fontWeight: '400', width: '100%' }}
                className='titleboxflexofbank'
              >
                {data?.transactionType}
              </div>
            </div>
          </div>
          <div
            style={{ width: '100%', paddingTop: '0vw' }}
            className='flexofbankdetails'
          >
            <div
              style={{ width: '100%' }}
              className='flexofbankdetails'
            >
              <div
                style={{ width: '50%' }}
                className='titleboxflexofbank'
              >
                Time And Date:
              </div>
              <div
                style={{ fontWeight: '400', width: '100%' }}
                className='titleboxflexofbank'
              >
                {data?.created_at && (
                  <span>
                    {new Date(data?.created_at).getHours()}:
                    {new Date(data?.created_at).getMinutes()}
                    {', '}
                    {new Date(data?.created_at).getDate()}/
                    {new Date(data?.created_at).getMonth() + 1}/
                    {new Date(data?.created_at).getFullYear()}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div
            style={{ width: '100%', paddingTop: '0vw' }}
            className='flexofbankdetails'
          >
            <div
              style={{ width: '100%' }}
              className='flexofbankdetails'
            >
              <div
                style={{ width: '50%' }}
                className='titleboxflexofbank'
              >
                Amount :
              </div>
              <div
                style={{ fontWeight: '400', width: '100%' }}
                className='titleboxflexofbank'
              >
                <div
                  className='valueofcardofexamstype_transaction_amount'
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.3rem',
                  }}
                >
                  <div style={{ width: '18px', height: '18px' }}>
                    <img
                      src={coinSymbol}
                      style={{ width: '100%', height: '100%' }}
                      alt='coin_symbol'
                    />
                  </div>
                  <span style={{ marginTop: '0.1rem' }}>
                    {data?.balance?.$numberDecimal}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{ width: '100%', paddingTop: '0vw' }}
            className='flexofbankdetails'
          >
            <div
              style={{ width: '100%' }}
              className='flexofbankdetails'
            >
              <div
                style={{ width: '50%' }}
                className='titleboxflexofbank'
              >
                Status :
              </div>
              <div
                style={{
                  fontWeight: '400',
                  width: '100%',
                  color:
                    data?.status === 'COMPLETED' || data?.status === 'complete'
                      ? '#41B541'
                      : 'red',
                }}
                className='titleboxflexofbank'
              >
                {data?.status}
              </div>
            </div>
          </div>
          {data?.customer_details?.length > 0 && (
            <div>
              <hr />
              <div className='profiledetailstitle'>Payment Details</div>
              {data?.customer_details[0]?.email && (
                <div
                  style={{ width: '100%', paddingTop: '0vw' }}
                  className='flexofbankdetails'
                >
                  <div
                    style={{ width: '100%' }}
                    className='flexofbankdetails'
                  >
                    <div
                      style={{ width: '50%' }}
                      className='titleboxflexofbank'
                    >
                      Name :
                    </div>
                    <div
                      style={{
                        fontWeight: '400',
                        width: '100%',
                      }}
                      className='titleboxflexofbank'
                    >
                      {data?.customer_details[0]?.name}
                    </div>
                  </div>
                </div>
              )}
              {data?.customer_details[0]?.email && (
                <div
                  style={{ width: '100%', paddingTop: '0vw' }}
                  className='flexofbankdetails'
                >
                  <div
                    style={{ width: '100%' }}
                    className='flexofbankdetails'
                  >
                    <div
                      style={{ width: '50%' }}
                      className='titleboxflexofbank'
                    >
                      email :
                    </div>
                    <div
                      style={{
                        fontWeight: '400',
                        width: '100%',
                      }}
                      className='titleboxflexofbank'
                    >
                      {data?.customer_details[0]?.email}
                    </div>
                  </div>
                </div>
              )}
              {data?.customer_details[0]?.phone && (
                <div
                  style={{ width: '100%', paddingTop: '0vw' }}
                  className='flexofbankdetails'
                >
                  <div
                    style={{ width: '100%' }}
                    className='flexofbankdetails'
                  >
                    <div
                      style={{ width: '50%' }}
                      className='titleboxflexofbank'
                    >
                      phone :
                    </div>
                    <div
                      style={{
                        fontWeight: '400',
                        width: '100%',
                      }}
                      className='titleboxflexofbank'
                    >
                      {data?.customer_details[0]?.phone}
                    </div>
                  </div>
                </div>
              )}
              {data?.customer_details[0]?.upi && (
                <div
                  style={{ width: '100%', paddingTop: '0vw' }}
                  className='flexofbankdetails'
                >
                  <div
                    style={{ width: '100%' }}
                    className='flexofbankdetails'
                  >
                    <div
                      style={{ width: '50%' }}
                      className='titleboxflexofbank'
                    >
                      upi :
                    </div>
                    <div
                      style={{
                        fontWeight: '400',
                        width: '100%',
                      }}
                      className='titleboxflexofbank'
                    >
                      {data?.customer_details[0]?.upi}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {data?.ticketId?.ticketId && (
            <div>
              <hr />
              <div className='profiledetailstitle'>
                {' '}
                Transaction Issue Details{' '}
              </div>
              <div
                style={{ width: '100%', paddingTop: '0vw' }}
                className='flexofbankdetails'
              >
                <div
                  style={{ width: '100%' }}
                  className='flexofbankdetails'
                >
                  <div
                    style={{ width: '50%' }}
                    className='titleboxflexofbank'
                  >
                    ticket id :
                  </div>
                  <div
                    style={{ fontWeight: '400', width: '100%' }}
                    className='titleboxflexofbank'
                  >
                    {data?.ticketId?.ticketId}
                  </div>
                </div>
              </div>
              <div
                style={{ width: '100%', paddingTop: '0vw' }}
                className='flexofbankdetails'
              >
                <div
                  style={{ width: '100%' }}
                  className='flexofbankdetails'
                >
                  <div
                    style={{ width: '50%' }}
                    className='titleboxflexofbank'
                  >
                    Issue Type :
                  </div>
                  <div
                    style={{ fontWeight: '400', width: '100%' }}
                    className='titleboxflexofbank'
                  >
                    {data?.ticketId?.transactionType}
                  </div>
                </div>
              </div>
              <div
                style={{ width: '100%', paddingTop: '0vw' }}
                className='flexofbankdetails'
              >
                <div
                  style={{ width: '100%' }}
                  className='flexofbankdetails'
                >
                  <div
                    style={{ width: '50%' }}
                    className='titleboxflexofbank'
                  >
                    Description :
                  </div>
                  <div
                    style={{ fontWeight: '400', width: '100%' }}
                    className='titleboxflexofbank'
                  >
                    {data?.ticketId?.description}
                  </div>
                </div>
              </div>
              <div
                style={{ width: '100%', paddingTop: '0vw' }}
                className='flexofbankdetails'
              >
                <div
                  style={{ width: '100%' }}
                  className='flexofbankdetails'
                >
                  <div
                    style={{ width: '50%' }}
                    className='titleboxflexofbank'
                  >
                    status :
                  </div>
                  <div
                    style={{
                      fontWeight: '400',
                      width: '100%',
                      color:
                        data?.ticketId?.status !== 'Open' ? '#41B541' : 'red',
                    }}
                    className='titleboxflexofbank'
                  >
                    {data?.ticketId?.status}
                  </div>
                </div>
              </div>
              <div
                style={{ width: '100%', paddingTop: '0vw' }}
                className='flexofbankdetails'
              >
                <div
                  style={{ width: '100%' }}
                  className='flexofbankdetails'
                >
                  <div
                    style={{ width: '50%' }}
                    className='titleboxflexofbank'
                  >
                    check Status :
                  </div>
                  <div
                    style={{
                      fontWeight: '400',
                      width: '100%',
                      color: data?.ticketId?.checked ? '#41B541' : 'red',
                    }}
                    className='titleboxflexofbank'
                  >
                    {data?.ticketId?.checked ? 'checked' : 'not checked'}
                  </div>
                </div>
              </div>
            </div>
          )}
          <hr style={{ color: '#000000' }} />

          <div
            style={{
              float: 'right',
              marginBottom: '1vw',
              marginTop: width > 700 ? '0.3vw' : '1vw',
              width: width > 700 ? '20vw' : '70vw',
              justifyContent: 'right',
            }}
            className='homejobbuttons'
          >
            <button
              onClick={() => {
                handleClosex();
              }}
              style={{ background: 'white', marginRight: '2vw' }}
            >
              Close
            </button>
            {!data?.ticketId?.ticketId && (
              <button
                onClick={() => {
                  handleOpen();
                  handleClosex();
                }}
                style={{ color: 'white' }}
              >
                Raise Issue{' '}
              </button>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
}
