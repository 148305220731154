import React from "react";
import "./homeBenifit.css";

import CenterMode from "./CenterMode";
export default function HomeBenifit({ width }) {
  return (
    <div className="homebenifit-container">
      <div className="home-catetitle" style={{ marginBottom: "0vw" }}>
      Our Services
      </div>
      <div style={{ marginBottom: "2vw" }} className="abouttitileheadingsub">
      Empowering Your Business to Succeed!
      </div>

    
      <CenterMode  width={width}/>
     
    </div>
  );
}
