import React from "react";
import "./Blogs.css";

export default function BlogBanner({ width }) {
  return (
    <div
      style={{
        margin: "0vw 0vw",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        height: width > 700 ? "25vw" : "110vw",
      }}
      className="homebluebox"
    >
      <div
        style={{ width: width > 700 ? "70vw" : "96vw" }}
        className="homeblueboxtitle"
      >
        "Transform Your Business Ideas, Solutions, Concepts, and Experiences
        into Revenue Streams"
      </div>
      <div
        style={{ width: width > 700 ? "86vw" : "96vw" }}
        className="homeblueboxsubtitle"
      >
        {/* Our blog is dedicated to helping entrepreneurs and business owners
        achieve their goals by providing valuable tips, tricks, and advice on
        how to turn their ideas into profitable ventures.  */}
      </div>

      <a href="mailto:hello@44resources.com">
        <button style={{ border: "none" }} className="homeworktop-button">
          Get Started
        </button>
      </a>
    </div>
  );
}
