

import React, { useEffect, useState } from "react";




export default function ProfileSkills({ user, width }) {


  const [skillset, setskillset] = useState([]);


  

  const [arrayofskills, setArrayofskills] = useState([]);

  

  useEffect(() => {
    let arr = [];
    let arr2 = [];
    user?.noOfSkillSet?.map((skill) => {
      arr = [...arr, skill?.skill];
      arr2 = [...arr2, skill?.skill?._id];
    });
    setskillset(arr);
    setArrayofskills(arr2);
  }, [user]);



  return (
    <div style={{ height: "fit-content" }} className="profilebadgecontainer">
      <div
        style={{ padding: "2vw", margin: "0" }}
        className="profiletitleandmenunav"
      >
        <div className="profiledetailstitle">Specialized In (Optional) </div>
      

    </div>

      <div className="pallskillboxes">
        {skillset?.map((skill) => {
          return <div className="pskillbox">{skill}</div>;
        })}
      </div>
    </div>
  );
}
