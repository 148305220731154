import React, { useEffect, useState } from "react";
import "./catalogue.css";
import "../../components/Home/Homebanner/Homebanner.css";
import img4 from "../../assets/My profile – 28/pexels-stefan-stefancik-91227.png";
import Cataloguecarosel from "./CatalogCarosel";
import Cataloguedescription from "./Cataloguedescription";
import CatalogueFaqreview from "./CatalogueFaqreview";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import HomePostjob from "./HomePostjob";
import { useSelector } from "react-redux";
import HomePostjob1 from "./HomePostjob1";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75vw",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1.5,
  pr: 1,
};
export default function Catalogbanner({ data, width }) {
  const navigate = useNavigate();
  const { user, loggedInStatus } = useSelector((state) => state.user);
  const [open3, setOpen3] = React.useState(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);

  const [status, setStatus] = useState(1);

  const radioHandler = (status) => {
    setStatus(status);
    if (status === 1) {
      setBudget(data?.servicePrice1);
      setDuration(data?.serviceDays1);
    }
    if (status === 2) {
      setBudget(data?.servicePrice2);
      setDuration(data?.serviceDays2);
    }
    if (status === 3) {
      setBudget(data?.servicePrice3);
      setDuration(data?.serviceDays3);
    }
    if (status === 4) {
      setBudget(data?.servicePrice4);
      setDuration(data?.serviceDays4);
    }
    if (status === 5) {
      setBudget(data?.servicePrice5);
      setDuration(data?.serviceDays5);
    }
  };

  useEffect(() => {
    if (data) {
      setBudget(data?.servicePrice1);
      setDuration(data?.serviceDays1);
    }
  }, [data]);

  const [budget, setBudget] = useState(data?.servicePrice1);
  const [duration, setDuration] = useState(data?.serviceDays1);

  return (
    <>
      <div
        style={{ flexDirection: width < 700 && "column" }}
        className="catalogbanner-container"
      >
        <div
          style={{ height: "fit-content", overflow: "hidden" }}
          className="catalogdetails"
        >
          <button
            style={{
              cursor: "pointer",
              zIndex: "100",
              padding: "0.5vw 0.7vw",
              backgroundColor: "white",
              color: "#000",
              borderRadius: width > 700 ? "0.3vw" : "1vw",
              border: "1px solid #d7d7d7",
            }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIosNewIcon />
          </button>

          <div
            style={{ paddingTop: "0.5vw", color: "#0052CC", display: "flex" }}
            className="catalogue-titile mt-1"
          >
            {data?.catalougeTitle}
          </div>
          <div
            style={{
              color: "#0052CC",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                color: "white",
                background: "#0052CC",
                height: width > 700 ? "2.5vw" : "8vw",
                lineHeight: width > 700 ? "2.5vw" : "8vw",
                fontSize: width <= 700 && "3vw",
                marginLeft: "0vw",
                width: "fit-content",
              }}
              className={"homeworktop-button"}
            >
              {data?.category?.category}
            </div>

            <div
              style={{ margin: "0", cursor: "pointer" }}
              className="catalogue--basics"
              onClick={() => {
                if (user?.userName) {
                  navigate(`/dashboard/profile/${data?.u_id?.userName}`);
                }
              }}
            >
              <div style={{ width: "fit-content" }}>
                <img
                  style={{ marginRight: "1vw" }}
                  src={data?.u_id?.media ? data?.u_id?.media : img4}
                  alt=""
                />
              </div>
              <div
                style={{ fontSize: width > 700 ? "1.1vw" : "3vw" }}
                className="catalogue--basics-title"
              >
                {data?.u_id?.fullName}
              </div>
            </div>
          </div>

          <div className="ctatlogcarouseltitle">
            <div className="textofcontainercatalgue">{data?.bannerTitle}</div>
          </div>
          <div style={{ position: "relative", bottom: "2.2vw" }}>
            <Cataloguecarosel
              width={width}
              img1={data?.files[0]}
              img2={data?.files[1] ? data?.files[1] : data?.files[0]}
              img3={
                data?.files[2]
                  ? data?.files[2]
                  : data?.files[0]
                  ? data?.files[0]
                  : data?.files[1]
              }
              img4={
                data?.files[3]?.file
                  ? data?.files[3]
                  : data?.files[1]
                  ? data?.files[1]
                  : data?.files[0]
                  ? data?.files[0]
                  : data?.files[2]
              }
            />
          </div>
        </div>
        <div className="catalogdetails-form">
          <div className="catalogdetails-form-box">
            <div className="clfbtitile">Our Services</div>
            <div className="clfbcheckboxes">
              <input
                type="radio"
                name="release"
                checked={status === 1}
                onClick={(e) => radioHandler(1)}
              />
              {data?.serviceName2 && (
                <input
                  type="radio"
                  name="release"
                  checked={status === 2}
                  onClick={(e) => radioHandler(2)}
                />
              )}
              {data?.serviceName3 && (
                <input
                  type="radio"
                  name="release"
                  checked={status === 3}
                  onClick={(e) => radioHandler(3)}
                />
              )}
              {data?.serviceName4 && (
                <input
                  type="radio"
                  name="release"
                  checked={status === 4}
                  onClick={(e) => radioHandler(4)}
                />
              )}
              {data?.serviceName5 && (
                <input
                  type="radio"
                  name="release"
                  checked={status === 5}
                  onClick={(e) => radioHandler(5)}
                />
              )}
            </div>
            <hr style={{ color: "#00000050" }} />
            {status === 1 && (
              <>
                <div className="featuresalfbdiv">
                  <div>Plan Name</div>
                  <div>{data?.serviceName1 ? data?.serviceName1 : "---"}</div>
                </div>
                <div className="featuresalfbdiv">
                  <div>Price (Rs.) </div>
                  <div>{data?.servicePrice1 ? data?.servicePrice1 : "---"}</div>
                </div>
                <div className="featuresalfbdiv">
                  <div>Delivery Time</div>
                  <div>{data?.serviceDays1 ? data?.serviceDays1 : "---"}</div>
                </div>
                <div className="featuresalfbdiv">
                  <div>What Include</div>
                  <div style={{ width: "50%", textAlign: "right" }}>
                    {/* ALL Design Page was Changed to SVG and Provide to as per the
                Flow */}
                    {data?.whatInclude1 ? data?.whatInclude1 : "---"}
                  </div>
                </div>
              </>
            )}
            {status === 2 && (
              <>
                <div className="featuresalfbdiv">
                  <div>Plan Name</div>
                  <div>{data?.serviceName2 ? data?.serviceName2 : "---"}</div>
                </div>
                <div className="featuresalfbdiv">
                  <div>Price (Rs.)</div>
                  <div>{data?.servicePrice2 ? data?.servicePrice1 : "---"}</div>
                </div>
                <div className="featuresalfbdiv">
                  <div>Delivery Time</div>
                  <div>{data?.serviceDays2 ? data?.serviceDays2 : "---"}</div>
                </div>
                <div className="featuresalfbdiv">
                  <div>What Include</div>
                  <div style={{ width: "50%", textAlign: "right" }}>
                    {data?.whatInclude2 ? data?.whatInclude2 : "---"}
                  </div>
                </div>
              </>
            )}
            {status === 3 && (
              <>
                <div className="featuresalfbdiv">
                  <div>Plan Name</div>
                  <div>{data?.serviceName3 ? data?.serviceName3 : "---"}</div>
                </div>
                <div className="featuresalfbdiv">
                  <div>Price (Rs.)</div>
                  <div>{data?.servicePrice3 ? data?.servicePrice3 : "---"}</div>
                </div>
                <div className="featuresalfbdiv">
                  <div>Delivery Time</div>
                  <div>{data?.serviceDays3 ? data?.serviceDays3 : "---"}</div>
                </div>
                <div className="featuresalfbdiv">
                  <div>What Include</div>
                  <div style={{ width: "50%", textAlign: "right" }}>
                    {data?.whatInclude3 ? data?.whatInclude3 : "---"}
                  </div>
                </div>
              </>
            )}
            {status === 4 && (
              <>
                <div className="featuresalfbdiv">
                  <div>Plan Name</div>
                  <div>{data?.serviceName4 ? data?.serviceName4 : "---"}</div>
                </div>
                <div className="featuresalfbdiv">
                  <div>Price (Rs.)</div>
                  <div>{data?.servicePrice4 ? data?.servicePrice4 : "---"}</div>
                </div>
                <div className="featuresalfbdiv">
                  <div>Delivery Time</div>
                  <div>{data?.serviceDays4 ? data?.serviceDays4 : "---"}</div>
                </div>
                <div className="featuresalfbdiv">
                  <div>What Include</div>
                  <div style={{ width: "50%", textAlign: "right" }}>
                    {data?.whatInclude4 ? data?.whatInclude4 : "---"}
                  </div>
                </div>
              </>
            )}
            {status === 5 && (
              <>
                <div className="featuresalfbdiv">
                  <div>Plan Name</div>
                  <div>{data?.serviceName5 ? data?.serviceName5 : "---"}</div>
                </div>
                <div className="featuresalfbdiv">
                  <div>Price (Rs.)</div>
                  <div>{data?.servicePrice5 ? data?.servicePrice5 : "---"}</div>
                </div>
                <div className="featuresalfbdiv">
                  <div>Delivery Time</div>
                  <div>{data?.serviceDays5 ? data?.serviceDays5 : "---"}</div>
                </div>
                <div className="featuresalfbdiv">
                  <div>What Include</div>
                  <div style={{ width: "50%", textAlign: "right" }}>
                    {data?.whatInclude5 ? data?.whatInclude5 : "---"}
                  </div>
                </div>
              </>
            )}

            {user?.userId === data?.userId ? (
              ""
            ) : (
              <button className="clfbbutton" onClick={() => handleOpen3()}>
                BookNow
              </button>
            )}
          </div>

          <Modal
            open={open3}
            onClose={handleClose3}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={width > 700 ? style : style1}>
              {!user._id ? (
                <HomePostjob
                  width={width}
                  handleClose={handleClose3}
                  showBudget={budget}
                  showDuration={duration}
                  catalogueID={data?._id}
                  catalogue={data}
                  SelectedCategory={data?.category?.category}
                  SelectedCategoryId={data?.category?._id}
                  SubSelectedCategory={data?.subCategory?.subCategory}
                  SubSelectedCategoryId={data?.subCategory?._id}
                />
              ) : (
                <HomePostjob1
                  width={width}
                  handleClose={handleClose3}
                  showBudget={budget}
                  showDuration={duration}
                  catalogueID={data?._id}
                  catalogue={data}
                  SelectedCategory={data?.category?.category}
                  SelectedCategoryId={data?.category?._id}
                  SubSelectedCategory={data?.subCategory?.subCategory}
                  SubSelectedCategoryId={data?.subCategory?._id}
                />
              )}
            </Box>
          </Modal>
        </div>
      </div>
      <div
        style={{
          display: "block",
          paddingTop: "0vw",
          position: "relative",
          bottom: "3vw",
        }}
        className="catalogbanner-container"
      >
        <Cataloguedescription data={data} width={width} />
        <CatalogueFaqreview data={data} width={width} />
      </div>
    </>
  );
}
