import React from "react";

import { useNavigate } from "react-router";

export default function Skillpopupcopy({ width, data }) {
  const navigate = useNavigate();
  const ref = React.createRef();

  return (
    <div ref={ref}>
      <div
        style={{
          alignItems: "center",
          margin: "1vw 0vw",
          padding: width > 700 ? "0.5vw  1vw" : "2vw",
          color: "black",
          width: width > 700 ? "82vw" : "220vw",
          cursor: "pointer",
        }}
        className="navoftableblogsdata"
        onClick={() => {
          navigate(`/dashboard/direct-sell-details/${data?.cartId}`);
        }}
      >
        <div style={{ width: width > 700 ? "14.4vw" : "70vw" }}>
          <span>
            <img
              style={{
                height: "fit-content",
                width: width > 700 ? "1.4vw" : "5vw",
                margin: "0vw",
                marginRight: "0.2vw",
                objectFit: "contain",
              }}
              src={data?.website_id[0]?.websiteLogo}
              alt=""
            />
          </span>{" "}
          {data?.website_id[0]?.websiteName}
        </div>
        <div style={{ width: width > 700 ? "10.4vw" : "50vw" }}>
          {data?.fullName}
        </div>
        <div style={{ width: width > 700 ? "9vw" : "30vw" }}>
          {" "}
          {data?.mobile}
        </div>
        <div style={{ width: width > 700 ? "16.7vw" : "70vw" }}>
          {" "}
          {data?.emailId?.length > 25
            ? data?.emailId?.slice(0, 25) + ".."
            : data?.emailId}
        </div>

        <div style={{ width: width > 700 ? "13.3vw" : "50vw" }}>
          {" "}
          Rs.{parseFloat(data?.finalAmount).toFixed(2)}
        </div>
        <div style={{ width: width > 700 ? "7.5vw" : "30vw" }}>
          {new Date(data?.created_at).getDate()}/
          {new Date(data?.created_at).getMonth() + 1}/
          {new Date(data?.created_at).getFullYear()}
        </div>
        <div
          style={{
            width: width > 700 ? "8.5vw" : "37vw",
            display: "flex",
            justifyContent: "space-around",
            color: data?.status === "Order placed" ? "green" : "red",
            fontSize: width > 700 ? "0.85vw" : "2.5vw",
            textAlign: "left",
          }}
        >
          {data?.status}
        </div>
      </div>
    </div>
  );
}
