import React, { useEffect, useState } from "react";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import { TextField } from "@mui/material";
import Popover from "@mui/material/Popover";

import { makeStyles } from "@material-ui/core";
import img from "../../../assets/Web 1280 – 14/Icon.svg";
import img1 from "../../../assets/Web 1280 – 14/Group 9831.svg";
import img21 from "../../../assets/My profile – 28/Component 85 – 16 (1).svg";
import DoneIcon from "@mui/icons-material/Done";
import axios from "axios";
import API_HOST from "../../../env";
import Typography from "@mui/material/Typography";
import imageCompression from "browser-image-compression";
import { useNavigate, useParams } from "react-router";

const useStyles = makeStyles((theme) => ({
  select: {
    height: "2.5vw",
    width: "100%",
    marginTop: "0.2vw",
    padding: "1vw",
    marginLeft: "0vw",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "1vw",
    lineHeight: "120%",
    color: "#FFFFFF",
  },
  select2: {
    height: "1vw",
    width: "100%",
    marginTop: "0.1vw",
    padding: "0.9vw 0.5vw",
    marginLeft: "0vw",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "0.51vw",
    lineHeight: "100%",
    color: "#FFFFFF",
  },
  select3: {
    height: "1vw",
    width: "100%",
    marginTop: "0.1vw",
    padding: "0vw 0vw",
    marginLeft: "0vw",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "0.51vw",
    lineHeight: "100%",
    color: "#FFFFFF",
    position: "relative",
    top: "0.81vw",
    left: "0.2vw",
  },
  icon: {
    fill: "white",
  },
}));

export default function EditExpense({ width }) {
  const { id } = useParams();

  const classes = useStyles();
  const [anchorElx2, setAnchorElx2] = React.useState(null);
  const handleClickx2 = (event) => {
    setAnchorElx2(event.currentTarget);
  };

  const handleClosex2 = () => {
    setAnchorElx2(null);
  };

  const openx2 = Boolean(anchorElx2);
  const idx2 = openx2 ? "simple-popover" : undefined;
  const [checkone, setCheckone] = useState(false);
  const [checkone1, setCheckone1] = useState(false);
  const [arrayoffiles, setArrayoffiles] = useState([]);

  const [ItemName, setItemName] = useState("");
  const [Categoryerror, setCategoryerror] = useState("");
  const [Category, setCategory] = useState("");
  const [Date, setDate] = useState();

  const [totalAmount, setTotalAmount] = useState("");

  const upadteimage = (img) => {
    const formdata = new FormData();
    formdata.append("fileName", img);
    formdata.append("folderName", "expenditiureObjects");
    axios
      ?.post(`${API_HOST}/contentManagement/tableContent`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        setArrayoffiles([
          ...arrayoffiles,
          { file: res.data?.success?.data?.file },
        ]);
      });
  };

  async function handleImageUpload(event) {
    const imageFile = event.target.files[0];
    console.log(imageFile?.type?.includes("image"));

    if (imageFile?.type?.includes("image")) {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(imageFile, options);
        console.log(
          "compressedFile instanceof Blob",
          compressedFile instanceof Blob
        ); // true
        console.log(
          `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
        ); // smaller than maxSizeMB

        //   await uploadToServer(compressedFile); // write your own logic
        await upadteimage(
          new File([compressedFile], `${compressedFile?.name}`)
        );
      } catch (error) {
        console.log(error);
      }
    } else {
      if (imageFile.size < 1024 * 1024) {
        upadteimage(imageFile);
      }
    }
  }

  const [ErrorCheck, setErrorCheck] = useState(false);
  const [ErrorCheck2, setErrorCheck2] = useState(false);

  const handlePostJOb = () => {
    if (!(checkone && checkone1)) {
      if (!checkone) {
        setErrorCheck(true);
      }
      if (!checkone1) {
        setErrorCheck2(true);
      }
      return false;
    }

    const formdata = new FormData();

    formdata.append("files", JSON.stringify(arrayoffiles));
    formdata.append("expenditiureId", id);

    formdata.append("date", Date);
    formdata.append("name", ItemName);
    formdata.append("head", Category);

    formdata.append("totalAmount", totalAmount);

    axios
      .post(`${API_HOST}/expenditure/editExpenses`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        navigate("/dashboard/expenditure");
      })
      .catch((err) => {
        setErroeshow(true);
      });
  };

  const [erroeshow, setErroeshow] = useState(false);

  const navigate = useNavigate();

  const [arrayoflongdegree, setArrayoflongdegree] = useState(
    [
      "Rent",
      "Office supplies",
      "Furniture and Equipment",
      "Salaries, wages, and benefits for employees",
      "Contractor or freelance labour costs",
      "Marketing and advertising expenses",
      "Business insurance",
      "Utilities such as electricity, water, and internet",
      "Travel expenses",
      "Office maintenance and repairs",
      "Taxes such as income tax and sales tax",
      "Legal fees and expenses",
      "Bank fees and charges",
      "Interest payments on loans and credit lines",
      "Depreciation of assets",
      "Cost of goods sold (COGS) such as raw materials, inventory, and shipping expenses",
      "Professional development and training expenses",
      "Research and development expenses",
      "Charitable contributions and donations",
      "Entertainment expenses for clients or employees",
    ].sort()
  );

  useEffect(() => {
    axios
      .get(`${API_HOST}/expenditure/viewById?expenditiureId=${id}`, {
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        setDate(res.data.success.data?.date.slice(0, 10));
        setItemName(res.data.success.data?.name);
        setCategory(res.data.success.data?.head);
        setTotalAmount(res.data.success.data?.totalAmount);
        setArrayoffiles(res?.data?.success?.data?.files);
      });
  }, [id]);

  return (
    <div
      style={{
        justifyContent: "center",
        width: width > 700 ? "80vw" : "100vw",
        paddingLeft: width > 700 ? "3vw" : "0vw",
        height: width > 700 ? "calc(100vh - 4vw)" : "calc(100vh - 14vw)",
      }}
      className="home-postjob-container ScrollTable"
    >
      <div
        style={{
          overflowX: "hidden",
          paddingBottom: "3vw",
          width: width > 700 ? "80vw" : "94vw",
          margin: "2vw",
          paddingTop: width > 700 ? "1vw" : "3vw",

          height: "fit-content",
        }}
        className="homepostjob-right "
      >
        <div className="jobpostedformheading">Edit Expense</div>
        <div style={{ width: "100%" }}>
          <div
            style={{
              left: "0vw",
              width: width > 700 ? "94%" : "100% ",
              marginLeft: "0%",
            }}
            className="loginfield"
          >
            <TextField
              id="outlined-basic"
              label="Enter Expense Name"
              variant="outlined"
              value={ItemName}
              style={{ width: "100%" }}
              InputLabelProps={{
                style: {
                  fontSize: width > 700 ? "1vw" : "3vw",
                  fontFamily: "Poppins",
                  fontStyle: "500",
                  fontWeight: "500",
                  color: "black",
                },
              }}
              inputProps={{ className: classes.input }}
              onChange={(e) => {
                setItemName(e.target.value);
              }}
            />
          </div>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: width > 700 ? "97%" : "100%",
              flexDirection: width > 700 ? "row" : "column",
            }}
          >
            <div style={{ width: width > 700 ? "50%" : "100%" }}>
              <div
                style={{
                  left: "0vw",
                  width: width > 700 ? "94%" : "100% ",
                  marginLeft: "0%",
                }}
                className="loginfield"
                onClick={handleClickx2}
              >
                <TextField
                  id="outlined-basic"
                  label="Select Head "
                  variant="outlined"
                  disabled
                  value={Category}
                  style={{ width: "100%" }}
                  InputLabelProps={{
                    style: {
                      fontSize: width > 700 ? "1vw" : "3vw",
                      fontFamily: "Poppins",
                      fontStyle: "500",
                      fontWeight: "500",
                      color: "black",
                    },
                  }}
                  inputProps={{ className: classes.input }}
                  onChange={(e) => {
                    console.log(e.target.value);
                  }}
                />
                <span style={{ width: "0.1vw" }}>
                  <KeyboardArrowDownOutlined
                    style={{
                      fontSize: width > 700 ? "1.5vw" : "5vw",
                      position: "relative",
                      right: width > 700 ? "2vw" : "6vw",
                      top: width > 700 ? "1vw" : "3vw",
                    }}
                  />
                </span>
              </div>
              <Popover
                id={idx2}
                open={openx2}
                anchorEl={anchorElx2}
                onClose={handleClosex2}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <div
                  style={{
                    maxHeight: width > 700 ? "18vw" : "40vw",
                    overflow: "scroll",
                    width: width > 700 ? "31vw" : "94vw",
                  }}
                >
                  {arrayoflongdegree?.length > 0 &&
                    arrayoflongdegree.map((data, index) => {
                      return (
                        <Typography
                          sx={{
                            p: 0.51,
                            pl: 1,
                            ml: 1,
                            width: "100%",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setCategory(data);
                            handleClosex2();
                            setCategoryerror();
                          }}
                        >
                          {data}
                        </Typography>
                      );
                    })}
                </div>
              </Popover>
              <p
                style={{
                  color: "red",
                  fontSize: width > 700 ? "0.9vw" : "2.7vw",
                }}
              >
                {Categoryerror}
              </p>{" "}
            </div>

            <div style={{ width: width > 700 ? "50%" : "100%" }}>
              <div
                style={{
                  left: "0vw",
                  width: width > 700 ? "94%" : "100% ",
                  marginLeft: "0%",
                }}
                className="loginfield"
              >
                <TextField
                  id="outlined-basic"
                  label="Total Amount (Rs)"
                  variant="outlined"
                  type="number"
                  value={totalAmount}
                  style={{ width: "100%" }}
                  InputLabelProps={{
                    style: {
                      fontSize: width > 700 ? "1vw" : "3vw",
                      fontFamily: "Poppins",
                      fontStyle: "500",
                      fontWeight: "500",
                      color: "black",
                    },
                  }}
                  inputProps={{ className: classes.input }}
                  onChange={(e) => {
                    setTotalAmount(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>{" "}
        <div style={{ display: "flex", alignItems: "center", width: "97%" }}>
          <div style={{ width: "50%" }}>
            <div className="jobpodtedfieldtitile">Date</div>
            <div className="jobpostfieldinputbox">
              <input
                style={{ width: "100%" }}
                type="date"
                className="input-homejobformdate"
                name=""
                id=""
                value={Date}
                maxlength="4"
                onChange={(e) => {
                  setDate(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="jobpodtedfieldtitile"> Uplaod Payment </div>
        <div
          style={{
            background: "white",
            padding: "0.51vw",
            marginTop: "0vw",
            paddingRight: "3vw",
          }}
        >
          <div className="inputfilebox">
            <div>
              <label htmlFor="inputctaelogfile">
                <div className="inputicon">
                  <img src={img} alt="" />
                </div>
                <div className="inputcateaddformfile">
                  Drag and Drop ,Browse to upload
                </div>
                <input
                  type="file"
                  id="inputctaelogfile"
                  onChange={(e) => {
                    handleImageUpload(e);
                  }}
                  hidden
                />
              </label>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            fontSize: width > 700 ? "0.8vw" : "2.7vw",
            paddingRight: "4.5vw",
            textAlign: "right",
          }}
        >
          Please upload file having extensions .jpeg/ .jpg/ .png only.
          <br />
          Image should be less then 512 kb.
        </div>
        <div
          className={arrayoffiles?.length > 0 ? "inputfilesshowncatebox" : ""}
        >
          {arrayoffiles?.length > 0 &&
            arrayoffiles?.map((file, index) => {
              return (
                <div className="inputfilesshowncatboxsingle">
                  <div className="inputfilesshowncatboxsingleimg">
                    <img src={img1} alt="" />
                  </div>
                  <div className="fileselctednamecate">
                    {file?.file?.split(".com/")[1]}
                  </div>
                  <div className="inputfilesshowncatboxsingleimg">
                    <img
                      style={{
                        width: width > 700 ? "1.5vw" : "4.5vw",
                        marginLeft: "1vw",
                        cursor: "pointer",
                      }}
                      src={img21}
                      alt=""
                      onClick={() => {
                        setArrayoffiles([
                          ...arrayoffiles.slice(0, index),
                          ...arrayoffiles.slice(index + 1, arrayoffiles.length),
                        ]);
                      }}
                    />
                  </div>
                </div>
              );
            })}
        </div>
        <div className="checktemandc">
          <div
            className="checkbox"
            onClick={() => {
              setCheckone(!checkone);

              setErrorCheck(false);
            }}
            required="required"
          >
            {checkone ? (
              <DoneIcon
                style={{
                  fontSize: width > 700 ? "0.81vw" : "3.7vw",
                  color: "blueviolet",
                  fontWeight: "600",
                }}
              />
            ) : (
              ""
            )}{" "}
          </div>
          <div className="checktermandconditiontext">
            I agree the{" "}
            <span
              style={{ cursor: "pointer" }}
              onClick={() => window.open("/termsandconditions", "_blank")}
            >
              Terms and Condition *
            </span>
          </div>
        </div>
        <span
          style={{ color: "red", fontSize: width > 700 ? "0.9vw" : "2.7vw" }}
        >
          {ErrorCheck ? <span>Please Click on Terms and Condition</span> : ""}
        </span>
        <div className="checktemandc">
          <div
            className="checkbox"
            onClick={() => {
              setCheckone1(!checkone1);

              setErrorCheck2(false);
            }}
          >
            {checkone1 ? (
              <DoneIcon
                style={{
                  fontSize: width > 700 ? "0.81vw" : "3.7vw",
                  color: "blueviolet",
                  fontWeight: "600",
                }}
              />
            ) : (
              ""
            )}{" "}
          </div>
          <div className="checktermandconditiontext">
            I agree the{" "}
            <span
              onClick={() => window.open("/privacypolicy", "_blank")}
              style={{ cursor: "pointer" }}
            >
              Privacy Policy *
            </span>
          </div>
        </div>
        <span style={{ color: "red", fontSize: width > 700 ? "1vw" : "3vw" }}>
          {ErrorCheck2 ? <span>Please Click on Privacy Policy *</span> : ""}
        </span>
        <span style={{ color: "red", fontSize: width > 700 ? "1vw" : "3vw" }}>
          {erroeshow ? <span>Please Fill all the necessary fields *</span> : ""}
        </span>
        <div className="homejobbuttons">
          <button
            style={{ background: "white" }}
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </button>

          <button
            // disabled={checkone && checkone1 ? false : true}
            style={{ color: "white" }}
            onClick={handlePostJOb}
          >
            Submit
          </button>
        </div>
      </div>{" "}
    </div>
  );
}
