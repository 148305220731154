import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

import edit_logo from "../../../assets/My profile – 28/edit_logo.svg";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router";
import API_HOST from "../../../env";
import img46 from "../../../assets/My profile – 28/Landing page – 19.png";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  input: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "0.91vw",

    color: "#263238",
    border: "yellow !important",
  },
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: "1vw",
};
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
};

export default function ProfileCatelogdetails({
  width,
  catalogue,
  setRecall,
  recall,
  websites,
}) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const deleltecatalogue = () => {
    axios
      .post(
        `${API_HOST}/lender/removelender`,
        {
          financerId: catalogue?.financerId,
        },
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((res) => {
        setRecall(!recall);
        handleClose();
      });
  };

  return (
    <div>
      <div
        style={{ margin: width > 700 ? "1.5vw 0.5vw" : "2.5vw" }}
        className="pcatelogbox"
      >
        <div
          style={{
            background: `url('${
              catalogue?.image
                ? catalogue?.image.trimStart()?.replaceAll(" ", "+")
                : img46
            }') center center / cover no-repeat`,
          }}
          className="pcatelogimg"
        >
          <div hidden className="pcatelogimg2">
            <div hidden className="porfolioprofilemenu">
              <RemoveRedEyeIcon
                onClick={() =>
                  navigate(`/dashboard/lend_finance/${catalogue?.financerId}`)
                }
                style={{
                  margin: "0 0.2vw",
                  width: width > 700 ? "3vw " : "9vw",
                  height: width > 700 ? "3vw " : "9vw",
                  borderRadius: "50%",
                  cursor: "pointer",
                  objectFit: "cover",
                  backgroundColor: "white",
                  padding: "3px",
                }}
              />
            </div>

            <div className="porfolioprofilemenu">
              <Link
                to={`/dashboard/edit_Lend_Finance/${catalogue?.financerId}`}
              >
                <img src={edit_logo} alt="" />
              </Link>
            </div>
            <div onClick={handleOpen} className="porfolioprofilemenu">
              <DeleteOutlineOutlinedIcon
                style={{
                  width: width > 700 ? "3vw " : "9vw",
                  height: width > 700 ? "3vw " : "9vw",
                  borderRadius: "50%",
                  cursor: "pointer",
                  objectFit: "cover",
                  backgroundColor: "white",
                  padding: "3px",
                }}
                alt=""
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            height: width > 700 ? "4.2vw" : "11vw",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              margin: width > 700 ? "0.5vw" : "1.5vw",

              width: "fit-content",
            }}
            className="pcatelogperson"
          >
            <div
              style={{ width: "fit-content" }}
              className="pcatelogpersonname"
            >
              <div className="pcatelogpersonname1">
                <span
                  style={{
                    display: "flex",
                    marginTop: "0.1vw",
                  }}
                >
                  {catalogue?.title?.length > 24
                    ? catalogue?.title?.slice(0, 22) + ".."
                    : catalogue?.title}
                </span>

                <div
                  style={{
                    fontSize: width > 700 ? "0.85vw" : "2.5vw",
                    display: "flex",
                    marginTop: "0.1vw",
                  }}
                >
                  By : {catalogue?.postedByName}
                </div>
              </div>
            </div>
          </div>
          <div style={{ width: "fit-content" }}>
            <div
              style={{
                margin: width > 700 ? "0.4vw 0.5vw" : "1vw 2vw",
                marginLeft: "0vw",
                justifyItems: "right",
                float: "right",
                maxWidth: width > 700 ? "11vw" : "30vw",
                marginBottom: "0.2vw",
                width: "fit-content",
                padding: width > 700 ? "0.3vw 0.6vw" : "0.5vw 1vw",
              }}
              className="tagblue"
            >
              {catalogue?.organizerName_bankName}
            </div>
            <div>
              <div
                style={{
                  fontSize: width > 700 ? "0.85vw" : "2.5vw",
                  display: "flex",
                  justifyItems: "right",
                  float: "right",
                  marginRight: "0.5vw",
                  width: "max-content",
                }}
              >
                ({" "}
                <span style={{ color: "#0052cc" }}>
                  Interest - {catalogue?.intrestRate} %
                </span>
                )
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            height: width > 700 ? "5.3vw" : "12vw",
            margin: "0vw",
            overflow: "hidden",
            padding: "0vw 0.5vw",
          }}
          className="descriptionactibeobbox"
        >
          <div
            className=""
            style={{
              color: "black",
              fontSize: width > 700 ? "0.91vw" : "2.7vw",
            }}
            dangerouslySetInnerHTML={{
              __html: catalogue?.description.slice(0, 250),
            }}
          ></div>
        </div>
        <hr
          style={{ padding: "0vw", margin: width > 700 ? "0.3vw" : "0.5vw" }}
        />
        <div style={{ margin: "0.51vw 0.2vw" }} className="pcatelogdate">
          <div>
            <span>
              Limit :
              <span
                className="tagblue"
                style={{
                  margin: "0.0vw 0.2vw",

                  width: "fit-content",
                  padding: "0.2vw 0.6vw",
                  color: "white",
                }}
              >
                Rs. {catalogue?.finaceLimit ? catalogue?.finaceLimit : "---"}
              </span>{" "}
            </span>
          </div>

          <div>
            <span
              onClick={() =>
                navigate(`/dashboard/add_need_fund/${catalogue?._id}`)
              }
            >
              {" "}
              <span
                className="tagblue"
                style={{
                  margin: "0.0vw 0.2vw",

                  width: "fit-content",
                  padding: "0.2vw 0.6vw",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                Apply for Fund
              </span>
            </span>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={width > 700 ? style : style1}>
          <div className="profiletitleandmenunav">
            <div className="profiledetailstitle"> Delete Offer</div>
            <div className="profiledetailnavmanu">
              <div>
                <CloseIcon
                  onClick={handleClose}
                  style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
                />
              </div>
            </div>
          </div>
          <hr style={{ color: "#000000" }} />
          <div
            style={{
              color: "gray",
              fontSize: width > 700 ? "1vw" : "2.7vw",
              fontWeight: "300",
            }}
            className="profiledetailstitle"
          >
            The action will delete this Business Fund Offer From all of your
            offers.
          </div>
          <div
            style={{
              color: "gray",
              fontSize: width > 700 ? "1vw" : "2.7vw",
              fontWeight: "400",
              marginBottom: "5vw",
            }}
            className="profiledetailstitle"
          >
            Are you sure you want to delete this Business Fund Offer?
          </div>
          <hr style={{ color: "#000000" }} />
          <div style={{ marginTop: "0.31vw" }} className="handlemoreaboutskill">
            <div
              style={{
                background: "white",
                color: "black",
                cursor: "pointer",
              }}
              className="handlecirclieaboutsave"
              onClick={handleClose}
            >
              Cancel
            </div>
            <div
              style={{ cursor: "pointer", background: "red" }}
              className="handlecirclieaboutsave"
              onClick={deleltecatalogue}
            >
              Delete
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
