import React from "react";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router";

import img2 from "../../../assets/Dashboard/Skill center – 2/Iconly-Light-outline-Edit.svg";
import img3 from "../../../assets/Dashboard/Skill center – 2/Iconly-Light-outline-Document.svg";
import img4 from "../../../assets/Dashboard/Skill center – 2/Iconly-Light-outline-Delete.svg";

import Modal from "@mui/material/Modal";

import axios from "axios";
import API_HOST from "../../../env";

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
};
const style3 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
};
export default function Skillpopupcopy({
  index,
  width,
  data,
  setRecall,
  recall,
  page,
}) {
  const navigate = useNavigate();
  const ref = React.createRef();

  const handledelete = () => {
    axios
      .post(
        `${API_HOST}/letterHead/removeLetter`,
        { letterId: data?.letterId },
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then(() => {
        handleClosex3();
        setRecall(!recall);
      });
  };

  const [openx3, setOpenx3] = React.useState(false);
  const handleOpenx3 = () => setOpenx3(true);
  const handleClosex3 = () => setOpenx3(false);
  return (
    <div ref={ref}>
      <div
        style={{
          alignItems: "center",
          margin: "1vw 0vw",
          padding: width > 700 ? "0.5vw  2vw" : "2vw",
          color: "black",
          width: width <= 700 && "145vw",
        }}
        className="navoftableblogsdata"
      >
        <div style={{ width: width > 700 ? "8vw" : "20vw" }}>
          {(page - 1) * 10 + index + 1}
        </div>
        <div style={{ width: width > 700 ? "45.5vw" : "60vw" }}>
          {data?.letterName}
        </div>

        <div style={{ width: width > 700 ? "15vw" : "30vw" }}>
          {data?.created_at && (
            <span>
              {new Date(data?.created_at).getDate()}/
              {new Date(data?.created_at).getMonth() + 1}/
              {new Date(data?.created_at).getFullYear()}
            </span>
          )}
        </div>
        <div
          style={{
            width: width > 700 ? "10vw" : "25vw",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <img
            onClick={() => navigate(`/dashboard/editletter/${data?.letterId}`)}
            style={{
              width: width > 700 ? "1.82vw" : "4vw",
              cursor: "pointer",
              marginRight: "1vw",
            }}
            src={img2}
            alt=""
          />

          <img
            onClick={() => navigate(`/dashboard/letter/${data?.letterId}`)}
            style={{
              width: width > 700 ? "1.82vw" : "4vw",
              cursor: "pointer",
              marginRight: "1vw",
              filter: "brightness(0)",
            }}
            src={img3}
            alt=""
          />

          <img
            onClick={() => handleOpenx3()}
            style={{
              width: width > 700 ? "1.82vw" : "4vw",
              cursor: "pointer",
            }}
            src={img4}
            alt=""
          />
        </div>
      </div>

      <Modal
        open={openx3}
        onClose={handleClosex3}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={width > 700 ? style1 : style3}>
          <div style={{ padding: "1vw" }} className="profiletitleandmenunav">
            <div className="profiledetailstitle">Delete Letter</div>
            <div className="profiledetailnavmanu">
              <div>
                <CloseIcon
                  onClick={handleClosex3}
                  style={{
                    fontSize: width > 700 ? "1.5vw" : "4vw",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          </div>
          <hr style={{ color: "#000000" }} />

          <div
            style={{ left: "0vw", width: "100%", padding: "1vw" }}
            className="loginfield"
          >
            Are you sure Want to Delete this Letter Head ?
          </div>

          <hr style={{ color: "#000000" }} />
          <div
            style={{ marginTop: "0.31vw", padding: "1vw" }}
            className="handlemoreaboutskill"
          >
            <div
              style={{
                background: "white",
                color: "black",
                cursor: "pointer",
              }}
              className="handlecirclieaboutsave"
              onClick={handleClosex3}
            >
              Cancel
            </div>
            <div
              onClick={() => {
                handledelete();
              }}
              style={{ cursor: "pointer", background: "red" }}
              className="handlecirclieaboutsave"
            >
              Delete
            </div>
          </div>
        </Box>
      </Modal>
    
    </div>
  );
}
