import * as React from "react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import PublicTwoToneIcon from "@mui/icons-material/PublicTwoTone";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import "./Letters.css";

const PageTemplate = (props) => {


  return (
    <div>
      <div
        style={{
          position: "absolute",
          top: "0px",
          left: "0px",
          width: "100%",
        }}
      >
        <div
          slot="template"
          style={{
            width: "100%",
            textAlign: "center",
            color: "black",
            padding: "7.5px",
            height: "1.5cm",
            bottom: 0,
            display: "flex",
          }}
        >
          <div className="lettercompanyLogo" slot="template">
            <img src={props?.logo} alt="" crossorigin="anonymous"></img>
          </div>
          <div slot="template">
            <div className="lettercompanyname" slot="template">
              {props?.companyName}
            </div>
            <div className="lettercompanytitle" slot="template">
              {props?.companyTitle}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "0px",
          left: "0px",
          width: "100%",
        }}
      >
        <div
          slot="template"
          style={{
            width: "100%",
            textAlign: "center",
            backgroundColor: "black",
            color: "white",
            padding: "8px",
            height: "1cm",
            display: "flex",
            alignItems: "center",
            bottom: 0,
          }}
        >
          <div className="footeritem">
            <div className="Footeritemimg">
              <PublicTwoToneIcon
                style={{ width: "12px", fontSize: "12px", height: "12px" }}
              />
            </div>

            <span tyle={{ fontSize: "7px" }}>{props?.companyWebsiteLink} </span>
          </div>
          <div className="footeritem">
            <div className="Footeritemimg">
              <PermPhoneMsgIcon
                style={{ width: "12px", fontSize: "12px", height: "12px" }}
              />
            </div>

            <span tyle={{ fontSize: "7px" }}>{props?.mobile} </span>
          </div>
          <div className="footeritem">
            <div className="Footeritemimg">
              <MailOutlineIcon
                style={{ width: "12px", fontSize: "12px", height: "12px" }}
              />
            </div>

            <span tyle={{ fontSize: "7px" }}>{props?.emailId} </span>
          </div>

          <div className="footeritem">
              <div>

            <div
              style={{ width: "15px", fontSize: "15px", height: "15px" }}
              className="Footeritemimg"
            >
              <LocationOnOutlinedIcon
                style={{ width: "12px", fontSize: "12px", height: "12px" }}
              />
            </div>
              </div>
            <span tyle={{ fontSize: "7px" }}>
              {props?.adress} 
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PageTemplate;
