import React from "react";

export default function WebsiteCart2({
  width,
  websitedata,
  arrayofProducts,
  setArrayofProducts,
}) {
  const today = new Date();
  const month = (today.getMonth() + 1).toString().padStart(2, "0");
  const day = today.getDate().toString().padStart(2, "0"); // Note: Months are zero-based, so we add 1
  const year = today.getFullYear();

  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  const month1 = (tomorrow.getMonth() + 1).toString().padStart(2, "0");
  const day1 = tomorrow.getDate().toString().padStart(2, "0"); // Note: Months are zero-based, so we add 1
  const year1 = tomorrow.getFullYear();
  return (
    <div className="websiteoffercontainer">
      <div style={{ color: websitedata?.themeColor }} className="offerheading">
        Booking Summary
      </div>

      <div className="flexofoffers">
        {arrayofProducts?.length > 0 &&
          arrayofProducts?.map((data, index) => {
            return (
              <div className="cartwebsitex">
                <div style={{ display: "flex" }}>
                  <div>
                    <img
                      style={{
                        cursor: "pointer",
                      }}
                      src={data?.web_id?.product_id?.files[0]?.file}
                      alt=""
                    />
                    <div className="cartquantity">
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (arrayofProducts[index]?.qnumbers > 1) {
                            setArrayofProducts([
                              ...arrayofProducts.slice(0, index),
                              {
                                web_id: data?.web_id,
                                type: data?.type,
                                qnumbers: data?.qnumbers - 1,
                                addOn: data?.addOn,
                                totalHours: data?.totalHours,
                                fromHour: data?.fromHour,
                                toDate: data?.toDate,
                                fromDate: data?.fromDate,
                                totalAddonPrice: data?.totalAddonPrice,
                                totalDays: data?.totalDays,
                                totalGST:
                                  (((data?.web_id?.product_id?.costPrice +
                                    data?.totalAddonPrice) *
                                    data?.web_id?.product_id?.gstRate *
                                    (data?.qnumbers - 1)) /
                                    100) *
                                  data?.totalHours *
                                  data?.totalDays,
                                totalPrice:
                                  (data?.web_id?.product_id?.costPrice +
                                    data?.totalAddonPrice) *
                                  (data?.qnumbers - 1) *
                                  data?.totalHours *
                                  data?.totalDays,
                              },
                              ...arrayofProducts.slice(
                                index + 1,
                                arrayofProducts?.length
                              ),
                            ]);
                          } else {
                            setArrayofProducts([
                              ...arrayofProducts.slice(0, index),

                              ...arrayofProducts.slice(
                                index + 1,
                                arrayofProducts?.length
                              ),
                            ]);
                          }
                        }}
                      >
                        -
                      </div>
                      <div>{data?.qnumbers}</div>
                      <div
                        onClick={() => {
                          setArrayofProducts([
                            ...arrayofProducts.slice(0, index),
                            {
                              web_id: data?.web_id,
                              type: data?.type,
                              qnumbers: data?.qnumbers + 1,
                              addOn: data?.addOn,
                              totalHours: data?.totalHours,
                              totalDays: data?.totalDays,
                              fromHour: data?.fromHour,
                              toDate: data?.toDate,
                              fromDate: data?.fromDate,
                              totalAddonPrice: data?.totalAddonPrice,
                              totalGST:
                                (((data?.web_id?.product_id?.costPrice +
                                  data?.totalAddonPrice) *
                                  data?.web_id?.product_id?.gstRate *
                                  (data?.qnumbers + 1)) /
                                  100) *
                                data?.totalHours *
                                data?.totalDays,
                              totalPrice:
                                (data?.web_id?.product_id?.costPrice +
                                  data?.totalAddonPrice) *
                                (data?.qnumbers + 1) *
                                data?.totalHours *
                                data?.totalDays,
                            },
                            ...arrayofProducts.slice(
                              index + 1,
                              arrayofProducts?.length
                            ),
                          ]);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        +
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      overflowY: "scroll",
                      height: width > 700 ? "13vw" : "32vw",
                      width: "100%",
                      marginLeft: width <= 700 && "1vw",
                    }}
                  >
                    {" "}
                    <div
                      style={{
                        cursor: "pointer",
                      }}
                      className="namemenuweb"
                    >
                      {data?.web_id?.product_id?.productName?.slice(0, 23)}
                    </div>
                    <div
                      style={{
                        width: "100%",
                        margin: "0.5vw 0vw",
                        marginBottom: "0vw",
                        paddingBottom: "0vw",
                      }}
                      className="pricemenuweb"
                    >
                      <span>
                        Rs.
                        {parseFloat(data?.totalPrice).toFixed(2)}
                      </span>
                    </div>
                    <div
                      style={{ color: data?.web_id?.qnumbers > 0 ? "" : "red" }}
                      className="stockmenuweb"
                    >
                      Total GST ({data?.web_id?.product_id?.gstRate}%) - Rs{" "}
                      {parseFloat(data?.totalGST).toFixed(2)}{" "}
                    </div>
                    <div style={{ marginTop: "0.5vw" }} className="">
                      {data?.web_id?.product_id?.addOn?.map((data1) => {
                        return (
                          <div style={{ margin: "0vw" }} className="addonbox">
                            <div
                              style={{ alignItems: "center" }}
                              className="addondata"
                            >
                              <div
                                style={{
                                  width: width > 700 ? "10%" : "7vw",
                                  fontSize: width > 700 ? "0.8vw" : "2.4vw",
                                }}
                                className=""
                              >
                                <input
                                  style={{
                                    margin: "0.5vw 0vw",
                                    height: width > 700 ? "1vw" : "2.5vw",
                                  }}
                                  type="checkbox"
                                  name=""
                                  id=""
                                  onChange={(e) => {
                                    if (
                                      arrayofProducts[index]?.addOn?.findIndex(
                                        (x) => x._id === data1?._id
                                      ) === -1
                                    ) {
                                      setArrayofProducts([
                                        ...arrayofProducts.slice(0, index),
                                        {
                                          web_id: data?.web_id,
                                          type: data?.type,
                                          fromHour: data?.fromHour,
                                          qnumbers: data?.qnumbers,
                                          totalHours: data?.totalHours,
                                          toDate: data?.toDate,
                                          fromDate: data?.fromDate,
                                          addOn: [...data?.addOn, data1],
                                          totalDays: data?.totalDays,
                                          totalAddonPrice:
                                            data?.totalAddonPrice +
                                            parseInt(data1?.amount),
                                          totalGST:
                                            (((data?.web_id?.product_id
                                              ?.costPrice +
                                              data?.totalAddonPrice +
                                              parseInt(data1?.amount)) *
                                              data?.web_id?.product_id
                                                ?.gstRate *
                                              data?.qnumbers) /
                                              100) *
                                            data?.totalHours *
                                            data?.totalDays,
                                          totalPrice:
                                            +(
                                              (data?.web_id?.product_id
                                                ?.costPrice +
                                                data?.totalAddonPrice +
                                                parseInt(data1?.amount)) *
                                              data?.qnumbers
                                            ) *
                                            data?.totalHours *
                                            data?.totalDays,
                                        },
                                        ...arrayofProducts.slice(
                                          index + 1,
                                          arrayofProducts?.length
                                        ),
                                      ]);
                                    } else {
                                      console.log();
                                      setArrayofProducts([
                                        ...arrayofProducts.slice(0, index),
                                        {
                                          web_id: data?.web_id,
                                          type: data?.type,
                                          fromHour: data?.fromHour,
                                          qnumbers: data?.qnumbers,
                                          fromDate: data?.fromDate,
                                          totalDays: data?.totalDays,
                                          addOn: [
                                            ...data?.addOn?.slice(
                                              0,
                                              data?.addOn?.findIndex(function (
                                                i
                                              ) {
                                                return i._id === data1?._id;
                                              })
                                            ),
                                            ...data?.addOn?.slice(
                                              data?.addOn?.findIndex(function (
                                                i
                                              ) {
                                                return i._id === data1?._id;
                                              }) + 1,
                                              data?.addOn?.length
                                            ),
                                          ],
                                          totalAddonPrice:
                                            data?.totalAddonPrice -
                                            parseInt(data1?.amount),
                                          totalHours: data?.totalHours,
                                          toDate: data?.toDate,
                                          totalGST:
                                            (((data?.web_id?.product_id
                                              ?.costPrice +
                                              data?.totalAddonPrice -
                                              parseInt(data1?.amount)) *
                                              data?.web_id?.product_id
                                                ?.gstRate *
                                              data?.qnumbers) /
                                              100) *
                                            data?.totalHours *
                                            data?.totalDays,
                                          totalPrice:
                                            (data?.web_id?.product_id
                                              ?.costPrice +
                                              data?.totalAddonPrice -
                                              parseInt(data1?.amount)) *
                                            data?.qnumbers *
                                            data?.totalHours *
                                            data?.totalDays,
                                        },
                                        ...arrayofProducts.slice(
                                          index + 1,
                                          arrayofProducts?.length
                                        ),
                                      ]);
                                    }
                                  }}
                                  checked={
                                    arrayofProducts[index]?.addOn?.findIndex(
                                      (x) => x._id === data1?._id
                                    ) > -1
                                  }
                                />
                              </div>

                              <div
                                style={{
                                  width: "70%",
                                  fontSize: width > 700 ? "0.8vw" : "2.5vw",
                                  margin: width > 700 ? "0.2vw" : "0.5vw",
                                }}
                                className="addonname"
                              >
                                {data1.name}
                              </div>
                              <div
                                style={{
                                  width: "20%",
                                  fontSize: width > 700 ? "0.8vw" : "2.5vw",
                                  margin: "0.2vw",
                                }}
                                className="addonname"
                              >
                                Rs {data1.amount}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>

                {data?.type === "Hourly" ? (
                  <div className="timeanddatecart">
                    <div>
                      <div style={{ marginLeft: "0.5vw" }}>Date</div>
                      <input
                        type="date"
                        value={data?.fromDate}
                        onChange={(e) => {
                          console.log(e.target.value);
                          setArrayofProducts([
                            ...arrayofProducts.slice(0, index),
                            {
                              web_id: data?.web_id,
                              type: data?.type,
                              qnumbers: data?.qnumbers,
                              addOn: data?.addOn,
                              totalAddonPrice: data?.totalAddonPrice,
                              fromDate: e.target.value,
                              toDate: data?.toDate,
                              totalDays: data?.totalDays,
                              fromHour: data?.fromHour,
                              totalHours: data?.totalHours,
                              totalGST:
                                (((data?.web_id?.product_id?.costPrice +
                                  data?.totalAddonPrice) *
                                  data?.web_id?.product_id?.gstRate *
                                  data?.qnumbers) /
                                  100) *
                                data?.totalHours *
                                data?.totalDays,
                              totalPrice:
                                (data?.web_id?.product_id?.costPrice +
                                  data?.totalAddonPrice) *
                                data?.qnumbers *
                                data?.totalHours *
                                data?.totalDays,
                            },
                            ...arrayofProducts.slice(
                              index + 1,
                              arrayofProducts?.length
                            ),
                          ]);
                        }}
                      />
                    </div>
                    <div>
                      <div style={{ marginLeft: "0.5vw" }}>Time</div>
                      <input
                        style={{ width: width > 700 ? "8vw" : "25vw" }}
                        type="time"
                        pattern="[0-9]{2}:[0-9]{2}"
                        min="00:00"
                        max="24:00"
                        value={data?.fromHour}
                        onChange={(e) => {
                          setArrayofProducts([
                            ...arrayofProducts.slice(0, index),
                            {
                              web_id: data?.web_id,
                              type: data?.type,
                              qnumbers: data?.qnumbers,
                              fromDate: data?.fromDate,
                              addOn: data?.addOn,
                              toDate: data?.toDate,
                              totalAddonPrice: data?.totalAddonPrice,
                              fromHour: e.target.value,
                              totalHours: data?.totalHours,
                              totalDays: data?.totalDays,
                              totalGST:
                                (((data?.web_id?.product_id?.costPrice +
                                  data?.totalAddonPrice) *
                                  data?.web_id?.product_id?.gstRate *
                                  data?.qnumbers) /
                                  100) *
                                data?.totalHours *
                                data?.totalDays,
                              totalPrice:
                                (data?.web_id?.product_id?.costPrice +
                                  data?.totalAddonPrice) *
                                data?.qnumbers *
                                data?.totalHours *
                                data?.totalDays,
                            },
                            ...arrayofProducts.slice(
                              index + 1,
                              arrayofProducts?.length
                            ),
                          ]);
                        }}
                      />
                    </div>
                    <div>
                      <div style={{ marginLeft: "0.5vw" }}>No. of Hrs</div>{" "}
                      <input
                        style={{ width: width > 700 ? "6.5vw" : "25vw" }}
                        type="number"
                        value={data?.totalHours}
                        onChange={(e) => {
                          setArrayofProducts([
                            ...arrayofProducts.slice(0, index),
                            {
                              web_id: data?.web_id,
                              type: data?.type,
                              qnumbers: data?.qnumbers,
                              addOn: data?.addOn,
                              fromDate: data?.fromDate,
                              toDate: data?.toDate,
                              totalAddonPrice: data?.totalAddonPrice,
                              fromHour: data?.fromHour,
                              totalDays: data?.totalDays,
                              totalHours: e.target.value,
                              totalGST:
                                (((data?.web_id?.product_id?.costPrice +
                                  data?.totalAddonPrice) *
                                  data?.web_id?.product_id?.gstRate *
                                  data?.qnumbers) /
                                  100) *
                                e.target.value *
                                data?.totalDays,
                              totalPrice:
                                (data?.web_id?.product_id?.costPrice +
                                  data?.totalAddonPrice) *
                                data?.qnumbers *
                                e.target.value *
                                data?.totalDays,
                            },
                            ...arrayofProducts.slice(
                              index + 1,
                              arrayofProducts?.length
                            ),
                          ]);
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="timeanddatecart">
                    <div>
                      <div style={{ marginLeft: "0.5vw" }}>From</div>
                      <input
                        type="date"
                        value={data?.fromDate}
                        min={`${year}-${month}-${day}`}
                        onChange={(e) => {
                          console.log(e.target.value);
                          console.log(e.target.value);
                          console.log(e.target.value);
                          console.log(
                            Math.floor(
                              (new Date(data?.toDate) -
                                new Date(e.target.value)) /
                                (1000 * 60 * 60 * 24)
                            )
                          );
                          setArrayofProducts([
                            ...arrayofProducts.slice(0, index),
                            {
                              web_id: data?.web_id,
                              type: data?.type,
                              qnumbers: data?.qnumbers,
                              addOn: data?.addOn,
                              totalAddonPrice: data?.totalAddonPrice,
                              fromDate: e.target.value,
                              toDate: data?.toDate,
                              fromHour: data?.fromHour,
                              totalHours: data?.totalHours,
                              totalDays: Math.floor(
                                (Date.parse(data?.toDate) -
                                  Date.parse(e.target.value)) /
                                  (1000 * 60 * 60 * 24)
                              ),
                              totalGST:
                                (((data?.web_id?.product_id?.costPrice +
                                  data?.totalAddonPrice) *
                                  data?.web_id?.product_id?.gstRate *
                                  data?.qnumbers) /
                                  100) *
                                data?.totalHours *
                                Math.floor(
                                  (Date.parse(data?.toDate) -
                                    Date.parse(e.target.value)) /
                                    (1000 * 60 * 60 * 24)
                                ),
                              totalPrice:
                                (data?.web_id?.product_id?.costPrice +
                                  data?.totalAddonPrice) *
                                data?.qnumbers *
                                data?.totalHours *
                                Math.floor(
                                  (Date.parse(data?.toDate) -
                                    Date.parse(e.target.value)) /
                                    (1000 * 60 * 60 * 24)
                                ),
                            },
                            ...arrayofProducts.slice(
                              index + 1,
                              arrayofProducts?.length
                            ),
                          ]);
                        }}
                      />
                    </div>

                    <div>
                      <div style={{ marginLeft: "0.5vw" }}>To</div>
                      <input
                        style={{
                          border: data?.totalDays < 1 && "2px solid red",
                        }}
                        type="date"
                        value={data?.toDate}
                        min={`${year1}-${month1}-${day1}`}
                        onChange={(e) => {
                          console.log(e.target.value);
                          console.log(
                            (Date.parse(e?.target?.value) -
                              Date.parse(data?.fromDate)) /
                              (1000 * 60 * 60 * 24)
                          );
                          setArrayofProducts([
                            ...arrayofProducts.slice(0, index),
                            {
                              web_id: data?.web_id,
                              type: data?.type,
                              qnumbers: data?.qnumbers,
                              addOn: data?.addOn,
                              totalAddonPrice: data?.totalAddonPrice,
                              toDate: e?.target?.value,
                              fromDate: data?.fromDate,
                              totalHours: data?.totalHours,
                              totalDays: Math.floor(
                                (Date.parse(e?.target?.value) -
                                  Date.parse(data?.fromDate)) /
                                  (1000 * 60 * 60 * 24)
                              ),
                              totalGST:
                                (((data?.web_id?.product_id?.costPrice +
                                  data?.totalAddonPrice) *
                                  data?.web_id?.product_id?.gstRate *
                                  data?.qnumbers) /
                                  100) *
                                data?.totalHours *
                                Math.floor(
                                  (Date.parse(e?.target?.value) -
                                    Date.parse(data?.fromDate)) /
                                    (1000 * 60 * 60 * 24)
                                ),
                              totalPrice:
                                (data?.web_id?.product_id?.costPrice +
                                  data?.totalAddonPrice) *
                                data?.qnumbers *
                                data?.totalHours *
                                Math.floor(
                                  (Date.parse(e?.target?.value) -
                                    Date.parse(data?.fromDate)) /
                                    (1000 * 60 * 60 * 24)
                                ),
                            },
                            ...arrayofProducts.slice(
                              index + 1,
                              arrayofProducts?.length
                            ),
                          ]);
                        }}
                      />
                    </div>

                    <div>
                      <div style={{ marginLeft: "0vw" }}>No. of Days</div>{" "}
                      <input
                        style={{ width: width > 700 ? "6vw" : "20vw" }}
                        type="number"
                        value={data?.totalDays}
                      />
                    </div>
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
}
