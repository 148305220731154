import React, { useState, useEffect } from "react";
import DoneIcon from "@mui/icons-material/Done";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core";
import API_HOST from "../../env";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { userActions } from "../../store/userSlice";
import "./Emailverify.css";
import CloseIcon from "@mui/icons-material/Close";
const useStyles = makeStyles((theme) => ({
  input: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "0.5vw",
    width: "100%",
    color: "#263238",
  },
  input1: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "2vw",
    width: "100%",
    color: "#263238",
  },
}));

export default function EmailVerify({
  toggleDrawer,
  closedrawer,
  width,
  handleEmailModalclose,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [Emailuser, setEmailuser] = useState('');
  useEffect(() => {
    setEmailuser(user?.emailId);
    setEmail(user?.emailId);
  }, [user]);

  const [OTPShow, setOTPShow] = useState(false);
  const [emailShow, setEmailShow] = useState(true);

  const [labelOTP, setLabelOTP] = useState('');
  const [bacgroundcolor, setBacgroundcolor] = useState('#0052cc');
  const [SignupandOtp, setsignupandOtp] = useState('Get OTP');
  const [showdonetick, setShowdonetick] = useState(false);

  const [bordercolor, setBordercolor] = useState('#E8E8E8');
  const [labelcolor, setlabelcolor] = useState('#6B6B6B');

  const [bordercolor1, setBordercolor1] = useState('#E8E8E8');
  const [labelcolor1, setlabelcolor1] = useState('#6B6B6B');

  const [email, setEmail] = useState(user?.emailId ? user?.emailId : '');
  const [otp, setOtp] = useState('');

  const [seconds, setSeconds] = useState('BOOOOM!');
  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds('BOOOOM!');
    }
  });

  const handleverify = () => {
    const formdata = new FormData();

    formdata.append('userName', user?.userName);
    formdata.append('otp', otp);
    handleToggle();

    axios
      .post(`${API_HOST}/users/otpMatchForEmail`, formdata, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
        },
      })
      .then((res) => {
        const formdata = new FormData();

        formdata.append('userId', user?.userId);
        formdata.append('newEmail', email);

        axios
          .post(`${API_HOST}/users/editEmail`, formdata, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization:
                'Bearer ' + JSON.parse(localStorage.getItem('token')),
            },
          })
          .then((res) => {
            setOTPShow(false);
            setLoginerror('');
            handleClose();
            window.localStorage.setItem(
              'user',
              JSON.stringify({ ...res.data.success.data })
            );

            dispatch(
              userActions.setUser({
                user: { ...res.data.success.data },
              })
            );
            setSuccessbox(
              'Email Successfully  Updated ! , Click Close Icon to Side Tab'
            );
          })
          .catch((err) => {
            setLoginerror(err.response?.data?.fails?.message);
            handleClose();
          });
      })
      .catch((err) => {
        setLoginerror(err.response?.data?.fails?.message);
        handleClose();
      });
  };

  const handleresendotp = () => {
    if (seconds === 'BOOOOM!') {
      const formdata = new FormData();
      handleToggle();
      formdata.append('userEmail', email);
      formdata.append('oldEmail', Emailuser);

      axios
        .post(`${API_HOST}/users/otpForEmail`, formdata, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization:
              'Bearer ' + JSON.parse(localStorage.getItem('token')),
          },
        })
        .then((res) => {
          setOTPShow(true);
          setsignupandOtp('Verify');
          setShowdonetick(false);
          handleClose();
        })
        .catch((err) => {
          setBordercolor('#FF0303');
          setlabelcolor('red');
          handleClose();
        });

      setSeconds(30);
    }
  };

  const handlesendotp = () => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!email) {
      setLoginerror('Email is required!');
      return;
    } else if (!regex.test(email)) {
      setLoginerror('The email address is improperly formatted!');
      return;
    }
    const formdata = new FormData();

    formdata.append('userEmail', email);
    formdata.append('oldEmail', Emailuser);
    handleToggle();
    axios
      .post(`${API_HOST}/users/otpForEmail`, formdata, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
        },
      })
      .then((res) => {
        //   setSuccessbox(
        //     "OTP send to your Emial  , please Confirm to reset your password"
        //   );
        setOTPShow(true);
        setLoginerror('');
        handleClose();
      })
      .catch((err) => {
        setLoginerror(err.response?.data?.fails?.message);
        handleClose();
      });
  };
  const [loginerror, setLoginerror] = useState('');
  const [successbox, setSuccessbox] = useState('');
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    console.log(false);
  };
  const handleToggle = () => {
    setOpen(true);
    console.log(true);
  };

  return (
    <div className=''>
      <div className=''>
        <div style={{ position: 'relative' }}>
          <div
            className='login-box'
            style={{ marginTop: '0.5vmax' }}
          >
            <div
              style={{ display: 'flex', justifyContent: 'space-between' }}
              className='loginheading'
            >
              <div className='profiledetailstitle'>Verify Email And Update</div>
              <div className='profiledetailnavmanu'>
                <div>
                  <CloseIcon
                    onClick={handleEmailModalclose}
                    style={{
                      fontSize: width > 700 ? '1.5vw' : '4vw',
                      cursor: 'pointer',
                    }}
                  />
                </div>
              </div>
            </div>
            <hr style={{ color: '#000000' }} />
            <div className='login-subheading'>
              Verify your email to get alert
            </div>

            {emailShow ? (
              <>
                {' '}
                <div
                  style={{
                    border:
                      bordercolor === '#FF0303'
                        ? '2px solid #FF0303'
                        : '1px solid #E8E8E8',
                  }}
                  className='modal_email-box'
                >
                  <Box
                    component='form'
                    sx={{
                      '& > :not(style)': { width: '28ch' },
                      '& .MuiTextField-root': {
                        mt: '0.75vw',
                        pl: 2,
                      },
                    }}
                    noValidate
                    autoComplete='off'
                  >
                    <TextField
                      InputProps={{ disableUnderline: true }}
                      id='standard-basic'
                      label={'Email'}
                      value={email}
                      disabled={OTPShow ? true : false}
                      placeholder='Email'
                      inputProps={{
                        className: width > 700 ? classes.input : classes.input1,
                      }}
                      InputLabelProps={{
                        style: {
                          color: `${labelcolor}`,
                          fontSize: width > 700 ? '1vw' : '3vw',
                          marginLeft: '15px',
                          fontStyle: 'normal',
                          fontWeight: '500',
                        },
                      }}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setOTPShow(false);
                        setLabelOTP('');
                        setBordercolor('#E8E8E8');
                        setlabelcolor('#6B6B6B');
                        setBordercolor1('#E8E8E8');
                        setlabelcolor1('#6B6B6B');

                        setBacgroundcolor('#0052cc');
                      }}
                      variant='standard'
                    />
                  </Box>
                </div>
              </>
            ) : (
              <>
                <p></p>
              </>
            )}

            {OTPShow && (
              <>
                <div
                  style={{
                    border:
                      bordercolor1 === '#FF0303'
                        ? '2px solid #FF0303'
                        : '1px solid #E8E8E8',
                  }}
                  className='modal_email-box'
                >
                  <Box
                    component='form'
                    sx={{
                      '& > :not(style)': { width: '25ch' },
                      '& .MuiTextField-root': {
                        mt: '0.75vw',
                        pl: 2,
                      },
                    }}
                    noValidate
                    autoComplete='off'
                  >
                    <TextField
                      type='password'
                      InputProps={{ disableUnderline: true }}
                      id='standard-basic'
                      label={'OTP'}
                      value={otp}
                      placeholder='OTP'
                      inputProps={{
                        className: width > 700 ? classes.input : classes.input1,
                      }}
                      InputLabelProps={{
                        style: {
                          fontSize: width > 700 ? '1vw' : '3vw',

                          marginLeft: '15px',

                          fontStyle: 'normal',
                          fontWeight: '500',
                          color: `${labelcolor1}`,
                        },
                      }}
                      variant='standard'
                      onChange={(e) => {
                        setOtp(e.target.value);
                        setBordercolor1('#E8E8E8');
                        setlabelcolor1('#6B6B6B');
                        if (e.target.value) {
                          setLabelOTP('OTP');
                        } else {
                          setLabelOTP('');
                        }
                      }}
                    />
                  </Box>
                </div>

                <div
                  style={{ cursor: 'pointer' }}
                  onClick={handleresendotp}
                  className='forgetpassword'
                >
                  <a style={{ cursor: 'pointer' }}>
                    {' '}
                    {seconds === 'BOOOOM!' ? (
                      'Resend OTP?'
                    ) : (
                      <span style={{ color: '#FB7750', cursor: 'auto' }}>
                        {' '}
                        Resend OTP in 00:{seconds}
                      </span>
                    )}
                  </a>
                </div>
              </>
            )}

            {Emailuser !== email && !OTPShow && (
              <button
                style={{ backgroundColor: `${bacgroundcolor}` }}
                onClick={handlesendotp}
                className='email_loginButton'
              >
                <span>
                  {' '}
                  {showdonetick ? (
                    <>
                      <DoneIcon
                        style={{ fontSize: '19px', marginBottom: '2px' }}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  {SignupandOtp}
                </span>
              </button>
            )}

            {Emailuser !== email && OTPShow && (
              <button
                style={{ backgroundColor: `${bacgroundcolor}` }}
                onClick={handleverify}
                className='loginButton'
              >
                <span>
                  {' '}
                  {showdonetick ? (
                    <>
                      <DoneIcon
                        style={{ fontSize: '19px', marginBottom: '2px' }}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  Verify and Save
                </span>
              </button>
            )}
          </div>
        </div>
        {loginerror && (
          <div
            style={{ width: width > 700 ? '25vw' : '80vw', marginTop: '2vw' }}
            className='Errorbox'
          >
            {loginerror}
          </div>
        )}
        {successbox && (
          <div
            style={{
              border: '1px solid #0085ff',
              background: '#0052cc10',
              color: '#0052cc',

              width: width > 700 ? '25vw' : '80vw',
              marginTop: width > 700 ? '1vw' : '3vw',
              paddingLeft: width > 700 ? '0.51vw' : '1vw',
            }}
            className='Errorbox'
          >
            {successbox}
          </div>
        )}
      </div>
    </div>
  );
}
