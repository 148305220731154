import React, { useEffect, useState } from 'react';
import './Homeabout.css';
import PersonIcon from '@mui/icons-material/Person';
import img1 from '../../../assets/Landing page/laptop banner.png';
import axios from 'axios';
import API_HOST from '../../../env';

import Homecarouselwork from './HomeCarouselWorkit';
export default function Homeaboutwork({ width }) {
  const [allCompany, setAllCompany] = useState(0);
  const [allStories, setAllStories] = useState(0);

  useEffect(() => {
    axios.get(`${API_HOST}/companydetails/allcompany`).then((res) => {
      setAllCompany(res.data.success.totalCompany);
    });
    axios.get(`${API_HOST}/stories/allstories`).then((res) => {

      setAllStories(res.data.success.totalStories);
    });
  }, []);  

  return (
    <>
      <div className='new_homeabout-container'>
        {
          //    <div className="home-catetitle">About us</div>
          // <div className="homeaboutbox">
          //   <div className="logoparthomeabout">
          //     <img src={img2} alt="" />
          //   </div>
          //   <div className="textparthomeabout">
          //     <div className="textabputhome1">
          //     Our vision is to create an innovative marketplace that simplifies business processes and provides affordable solutions to businesses of all sizes.
          //     </div>
          //     <div className="textabputhome2">
          //     One-stop solution for businesses to quickly, professionally, and conveniently sort through all of their problems, tools, and growth strategies. With our easy-to-use tools and expert guidance, we help businesses scale to the next level and expand their boundaries.
          //     </div>
          //   </div>
          // </div>
        }
        <div className='new_homeabout-container_col_1'>
          <h1 className='new_homeabout-container_col_1_first_heading'>OUR</h1>
          <h1 className='new_homeabout-container_col_1_second_heading'>
            Pricing
          </h1>
          <div className='new_homeabout-container_col_1_row_1'>
            <div style={{ display: 'flex', gap: '1rem' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: window.innerWidth > 600 ? '40px' : '35px',
                  height: window.innerWidth > 600 ? '40px' : '35px',
                  borderRadius: '50%',
                  backgroundColor: 'rgb(178 193 214)',
                }}
              >
                <PersonIcon
                  sx={{
                    color: '#0052cc',
                    fontSize: window.innerWidth > 600 ? '28px' : '20px',
                  }}
                />
              </div>
              <div style={{ width: window.innerWidth > 600 ? '70%' : '98%' }}>
                <h1 className='new_homeabout-container_col_1_row_1_fisrt_heading'>
                  Software Usage Cost
                </h1>
                <p className='new_homeabout-container_col_1_row_1_fisrt_description'>
                  Service costs start from an unbeatable Rs. 1 to Rs. 10 per
                  service.
                </p>
              </div>
            </div>
            <div style={{ marginTop: '2.5rem' }}>
              <div style={{ display: 'flex', gap: '1rem' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: window.innerWidth > 600 ? '40px' : '35px',
                    height: window.innerWidth > 600 ? '40px' : '35px',
                    borderRadius: '50%',
                    backgroundColor: 'rgb(178 193 214)',
                  }}
                >
                  <PersonIcon
                    sx={{
                      color: '#0052cc',
                      fontSize: window.innerWidth > 600 ? '28px' : '20px',
                    }}
                  />
                </div>
                <div style={{ width: window.innerWidth > 600 ? '70%' : '98%' }}>
                  <h1 className='new_homeabout-container_col_1_row_1_fisrt_heading'>
                    Assistance Charges
                  </h1>
                  <p className='new_homeabout-container_col_1_row_1_fisrt_description'>
                    Expert advice or workload reduction at just Rs.10 to Rs.20
                    per minute.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='home-about-banner-img'>
          <img
            src={img1}
            alt=''
          />
        </div>
      </div>

      <div className='howitworkhome-container'>
       { /*<div className='home-catetitle'>How it Works</div>*/}
        <Homecarouselwork width={width}
          allCompany={allCompany}
          allStories={allStories}
        />
      </div>
    </>
  );
}
