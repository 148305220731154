import React, { useEffect, useState } from "react";
import "./Website1.css";
import StarIcon from "@mui/icons-material/Star";
export default function WebsiteBanner({ width, websitedata }) {
  const [reversearray, setReversearray] = useState([]);
  useEffect(() => {
    setReversearray(websitedata?.happyCustomers?.reverse());
  }, [websitedata]);
  return (
    <>
      <div className="kok">
        <div
          style={{ background: websitedata?.themeColor }}
          className="kok1x"
        ></div>
      </div>
      {width > 700 ? (
        <div className="Website-bannerx">
          <div className="websitebannerdetailx">
            <div className="navofwebsite">
              <img src={websitedata?.websiteLogo} alt="" />
              <div className="websitelogoname">
                {websitedata?.websiteName}{" "}
                <div
                  style={{
                    fontSize: "1vw",
                    lineHeight: "2.1vw",
                    fontWeight: "500",
                  }}
                >
                  {websitedata?.metaDescription}
                </div>
              </div>
            </div>
            <div
              style={{ color: websitedata?.themeColor }}
              className="webbannertitle"
            >
              {websitedata?.bannerTitle}
            </div>
            <div
              className="webbannertitle1"
              dangerouslySetInnerHTML={{ __html: websitedata?.headline }}
            ></div>
            <div
              className="webbannertitle2"
              dangerouslySetInnerHTML={{ __html: websitedata?.headline2 }}
            ></div>
          </div>
          <div className="websitebannerimgx">
            <img src={websitedata?.bannerImage} alt="" />
          </div>
        </div>
      ) : (
        <>
          <div className="Website-banner">
            <div className="websitebannerdetail">
              <div className="navofwebsite">
                <img src={websitedata?.websiteLogo} alt="" />
                <div className="websitelogoname">
                  {websitedata?.websiteName}{" "}
                  <div
                    style={{
                      fontSize: "2.6vw",
                      lineHeight: "3vw",
                      fontWeight: "500",
                    }}
                  >
                    {websitedata?.metaDescription}
                  </div>
                </div>
              </div>
              <div
                style={{ color: websitedata?.themeColor }}
                className="webbannertitle"
              >
                {websitedata?.bannerTitle}{" "}
                <div
                  style={{
                    fontSize: "1vw",
                    lineHeight: "2.1vw",
                    fontWeight: "500",
                  }}
                >
                  {websitedata?.metaDescription}
                </div>
              </div>
            </div>
            <div className="websitebannerimg">
              <img src={websitedata?.bannerImage} alt="" />
            </div>
          </div>
          <div
            className="webbannertitle1"
            dangerouslySetInnerHTML={{ __html: websitedata?.headline }}
          ></div>
          <div
            className="webbannertitle2"
            dangerouslySetInnerHTML={{ __html: websitedata?.headline2 }}
          ></div>

          <div className="OurHappycustomer">Our Happy Customer</div>

          <div style={{ display: "flex" }}>
            {reversearray?.slice(0, 7)?.map((data, index) => {
              return (
                <div
                  className="personiconwebsite"
                  style={{ right: `${index * (width > 700 ? 0.61 : 3.3)}vw` }}
                >
                  <img
                    src={
                      data?.user_id?.media ||
                      "https://44resourcesimages.s3.amazonaws.com/d2ea44e1-9a8b-484c-85a3-34c98c7adee3-FkjaBcxtfV%24man.png"
                    }
                    alt=""
                  />
                </div>
              );
            })}
            {reversearray?.length === 0 && (
              <div className="personiconwebsite">
                <img
                  src={
                    "https://44resourcesimages.s3.amazonaws.com/d2ea44e1-9a8b-484c-85a3-34c98c7adee3-FkjaBcxtfV%24man.png"
                  }
                  alt=""
                />
              </div>
            )}
          </div>

          <div style={{ display: "flex", paddingLeft: "2vw" }}>
            <StarIcon
              style={{
                width: "3vw",
                margin: "0.51vw",
                height: "3vw",
                color: websitedata?.themeColor,
              }}
            />
            <StarIcon
              style={{
                width: "3vw",
                margin: "0.51vw",
                height: "3vw",
                color: websitedata?.themeColor,
              }}
            />
            <StarIcon
              style={{
                width: "3vw",
                margin: "0.51vw",
                height: "3vw",
                color: websitedata?.themeColor,
              }}
            />
            <StarIcon
              style={{
                width: "3vw",
                margin: "0.51vw",
                height: "3vw",
                color: websitedata?.themeColor,
              }}
            />
            <StarIcon
              style={{
                width: "3vw",
                margin: "0.51vw",
                height: "3vw",
                color: websitedata?.themeColor,
              }}
            />
          </div>
        </>
      )}
    </>
  );
}
