import axios from "axios";
import React, { useEffect, useState } from "react";
// import BlogDetailBanner from "../../components/BlogDetails/BlogDetailBanner";
// import Blogdetailpart from "../../components/BlogDetails/Blogdetailpart";
import { useParams } from "react-router-dom";
import API_HOST from "../../../env";
import { userActions } from "../../../store/userSlice";
import { useDispatch, useSelector } from "react-redux";
import StoryDetailBanner from "./StoryDetailBanner";
import StoryDetailPart from "./StoryDetailPart";

export default function StoryDetail({ width }) {
  const { name, id } = useParams();

  const [data, setData] = useState("");

  useEffect(() => {
    axios
      .get(
        `${API_HOST}/stories/viaId?storyId=${id
          .replace("$10", "/")
          .replace("$11", "?")}&pageSize=8&pageNumber=1`
      )
      .then((res) => {
        setData(res.data?.success?.data);
      });
  }, [id]);

  console.log('data',data);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0, { behavior: "smooth" });
  }, []);

  const [allusers, setAllusers] = useState([
    {
      contentId: "64b319eb-4690-47a3-bde1-4d37ea465e62",
      category: "Platform Tutorials",
      contentName: "Tutorial on How To Use Tool44.com To Build Your Website ",
      author: "Manish Mangal",
      icon: [
        "https://tool44prod.s3.ap-south-1.amazonaws.com/blogObjects/tableContent-mWUR0TBNz8$How To Build Your Website.png",
      ],
      created_at: "2023-07-11T09:33:19.001+00:00",
    },
    {
      contentId: "23d3f486-107e-47e7-ab76-24b4a95e6826",
      category: "Platform Tutorials",
      contentName:
        "How To Add or Edit Your Company or Business Details on Tool44.com",
      author: "Rahul Rajput",
      icon: [
        "https://tool44prod.s3.ap-south-1.amazonaws.com/blogObjects/tableContent-RRRejNgvZS$How To Add or Edit Your Company or Business Details.png",
      ],
      created_at: "2023-07-11T09:33:19.001+00:00",
    },

    {
      contentId: "ac277936-dc52-4c21-a7b2-7fb5bf118cf6",
      category: "Platform Tutorials",
      contentName: "How to Recover Your Password on Tool44.com",
      author: "Rahul Rajput",
      icon: [
        "https://tool44prod.s3.ap-south-1.amazonaws.com/blogObjects/tableContent-mWuS8cY7rj$How to Recover Your Password on Tool44.png",
      ],
      created_at: "2023-07-11T09:33:19.001+00:00",
    },

    {
      contentId: "e1765b9c-8808-4f49-a4cb-68be0898afb3",
      category: "Platform Tutorials",
      contentName:
        "How to Set Your Booking, Orders, and Day tasks on the Order Calendar on Tool44.com",
      author: "Rahul Rajput",
      icon: [
        "https://tool44prod.s3.ap-south-1.amazonaws.com/blogObjects/tableContent-GnDhhcEJGz$How to Set Your Booking Orders and Day tasks on the Order Calendar .png",
      ],
      created_at: "2023-07-11T09:33:19.001+00:00",
    },

    {
      contentId: "97a1f238-d42d-4a87-8942-8d35153eb488",
      category: "Platform Tutorials",
      contentName:
        "How to make your Complete, Comprehensive and Appealing  Profile on Tool44.com",
      author: "Rahul Rajput",
      icon: [
        "https://tool44prod.s3.ap-south-1.amazonaws.com/blogObjects/tableContent-6dQ3irLJQN$Tool Tutorials .png",
      ],
      created_at: "2023-07-11T09:33:19.001+00:00",
    },

    {
      contentId: "b60a361c-2ecb-4c47-9d3e-0cf542423630",
      category: "Platform Tutorials",
      contentName: "How to Connect with Professionals on Tool44.com",
      author: "Rahul Rajput",
      icon: [
        "https://tool44prod.s3.ap-south-1.amazonaws.com/blogObjects/tableContent-Y3YwSAr1nf$How to Connect with Professionals.png",
      ],
      created_at: "2023-07-11T09:33:19.001+00:00",
    },

    {
      contentId: "c91133d4-0122-410c-aeb9-8167e4d9cd29",
      category: "Platform Tutorials",
      contentName: "How To Create Social Media Post",
      author: "Rahul Rajput",
      icon: [
        "https://tool44prod.s3.ap-south-1.amazonaws.com/blogObjects/tableContent-jYjTpyNkvp$How To Create Social Media Post.png",
      ],
      created_at: "2023-07-11T09:33:19.001+00:00",
    },

    {
      contentId: "fbc6b182-1f38-4c88-8d18-f139da795d66",
      category: "Platform Tutorials",
      contentName: "How to Create a Professional Letterhead",
      author: "Rahul Rajput",
      icon: [
        "https://tool44prod.s3.ap-south-1.amazonaws.com/blogObjects/tableContent-Au3ZpXYG61$How to Create a Professional Letterhead.png",
      ],
      created_at: "2023-07-11T09:33:19.001+00:00",
    },

    {
      contentId: "d45077a4-168b-4300-93cb-22e9cb86c783",
      category: "Platform Tutorials",
      contentName: "How to Bid or Apply a Proposal on Tool44.com",
      author: "Rahul Rajput",
      icon: [
        "https://tool44prod.s3.ap-south-1.amazonaws.com/blogObjects/tableContent-jMT1tllJHh$How to Bid or Apply a Proposal.png",
      ],
      created_at: "2023-07-12T09:33:19.001+00:00",
    },

    {
      contentId: "8ee49db5-37e0-4e84-bd01-8699f1994574",
      category: "Platform Tutorials",
      contentName:
        "How to Check, Accept, and Reject a Client Connection request on Tool44.com",
      author: "Rahul Rajput",
      icon: [
        "https://tool44prod.s3.ap-south-1.amazonaws.com/blogObjects/tableContent-QzHZlRSaZD$How to Check Accept and Reject a Client Connection request.png",
      ],
      created_at: "2023-07-12T09:33:19.001+00:00",
    },

    {
      contentId: "dfb6e07a-6420-4d24-af4f-2f5d8e74f5a1",
      category: "Platform Tutorials",
      contentName: "How to Update Your Password On the Tool44.com",
      author: "Rahul Rajput",
      icon: [
        "https://tool44prod.s3.ap-south-1.amazonaws.com/blogObjects/tableContent-325PMRoECl$Update Password.png",
      ],
      created_at: "2023-07-12T09:33:19.001+00:00",
    },

    {
      contentId: "a99bae3e-6c28-4189-b119-36f169377267",
      category: "Platform Tutorials",
      contentName:
        "How to Manage your Expenditure, Add Expenses, and Purchases on the Tool44.com",
      author: "Rahul Rajput",
      icon: [
        "https://tool44prod.s3.ap-south-1.amazonaws.com/blogObjects/tableContent-oxIA6zoUpb$How to Manage your Expenditure Add Expenses and Purchases.png",
      ],
      created_at: "2023-07-12T09:33:19.001+00:00",
    },

    {
      contentId: "c481485f-bf31-4697-9b13-1ef7b45ddcaa",
      category: "Platform Tutorials",
      contentName: "How to Check and Manage your Taxation on the Tool44.com",
      author: "Rahul Rajput",
      icon: [
        "https://tool44prod.s3.ap-south-1.amazonaws.com/blogObjects/tableContent-9lUsEtQdEe$How to Check and Manage your Taxation.png",
      ],
      created_at: "2023-07-12T09:33:19.001+00:00",
    },

    {
      contentId: "3e194ab0-1836-49f9-bc54-17a30ffd8442",
      category: "Platform Tutorials",
      contentName: "How to Create a Custom invoice on the  Tool44.com",
      author: "Rahul Rajput",
      icon: [
        "https://tool44prod.s3.ap-south-1.amazonaws.com/blogObjects/tableContent-bAd4QXrfJq$Professional Invoice.png",
      ],
      created_at: "2023-07-12T09:33:19.001+00:00",
    },

    {
      contentId: "2963a2cc-c2d7-4e9a-a8ad-994ff8163f21",
      category: "Platform Tutorials",
      contentName: "How to Purchase Products and Book Services on Tool44.com",
      author: "Rahul Rajput",
      icon: [
        "https://tool44prod.s3.ap-south-1.amazonaws.com/blogObjects/tableContent-1OlxSz3g6w$How to Purchase Products and Book Services.png",
      ],
      created_at: "2023-07-15T09:33:19.001+00:00",
    },
    {
      contentId: "b8e5c5ca-e73c-4d14-8d19-2db2f9d4f656",
      category: "Platform Tutorials",
      contentName: "How to Add a Portfolio to Your Profile on Tool44.com",
      author: "Rahul Rajput",
      icon: [
        "https://tool44prod.s3.ap-south-1.amazonaws.com/blogObjects/tableContent-jPSGeG0KcW$Tool Tutorials .png",
      ],
      created_at: "2023-07-15T09:33:19.001+00:00",
    },
    {
      contentId: "5fa164b8-2993-4478-8556-ff2ae91653e2",
      category: "Platform Tutorials",
      contentName: "How to Cancel Your Orders and Booking on Tool44.com",
      author: "Rahul Rajput",
      icon: [
        "https://tool44prod.s3.ap-south-1.amazonaws.com/blogObjects/tableContent-kdSFShFVhg$How to Cancel Your Orders and Booking.png",
      ],
      created_at: "2023-07-15T09:33:19.001+00:00",
    },

    {
      contentId: "d3c566bb-3e3c-4c9a-b843-e004e2f2e09f",
      category: "Platform Tutorials",
      contentName:
        "How to Give Ratings and Reviews on your Orders and Bookings on Tool44.com",
      author: "Rahul Rajput",
      icon: [
        "https://tool44prod.s3.ap-south-1.amazonaws.com/blogObjects/tableContent-qx9QOqLHNt$How to Give Ratings and Reviews on Orders and Bookings.png",
      ],
      created_at: "2023-07-15T09:33:19.001+00:00",
    },
    {
      contentId: "ae6cd2c7-c89b-41e8-b54c-094e6d7da5ed",
      category: "Platform Tutorials",
      contentName:
        "How to Deliver new Orders and Confirm Bookings on Tool44.com",
      author: "Rahul Rajput",
      icon: [
        "https://tool44prod.s3.ap-south-1.amazonaws.com/blogObjects/tableContent-TzSfw7pWL8$How To Confirm New Orders and Bookings.png",
      ],
      created_at: "2023-07-15T09:33:19.001+00:00",
    },
    {
      contentId: "936d0d45-9d2d-4c49-ad0e-648710a23c2f",
      category: "Platform Tutorials",
      contentName:
        "How to Automatically Generate Invoices for Orders and Bookings on Tool44.com",
      author: "Rahul Rajput",
      icon: [
        "https://tool44prod.s3.ap-south-1.amazonaws.com/blogObjects/tableContent-x9X61SiBAU$Tool Tutorials .png",
      ],
      created_at: "2023-07-15T09:33:19.001+00:00",
    },
    {
      contentId: "6f163d60-eb19-4e3a-9041-6f069bc6e664",
      category: "Platform Tutorials",
      contentName: "How to Create a New Proposal/Requirement on Tool44.com",
      author: "Rahul Rajput",
      icon: [
        "https://tool44prod.s3.ap-south-1.amazonaws.com/blogObjects/tableContent-ui2MaBcwNa$How to Create a New ProposalRequirement.png",
      ],
      created_at: "2023-07-15T09:33:19.001+00:00",
    },
    {
      contentId: "607f13bf-df31-4210-b2e3-7261c2177a08",
      category: "Platform Tutorials",
      contentName: "How to Accept/Reject Bids on Tool44.com",
      author: "Rahul Rajput",
      icon: [
        "https://tool44prod.s3.ap-south-1.amazonaws.com/blogObjects/tableContent-J4RvNr9L9s$Accept and Reject Bids.png",
      ],
      created_at: "2023-07-15T09:33:19.001+00:00",
    },
    {
      contentId: "a7f7ec99-f47c-4432-81b8-e923da4070c8",
      category: "Platform Tutorials",
      contentName: "How to Create or Add Products/Services from Tool44.com",
      author: "Rahul Rajput",
      icon: [
        "https://tool44prod.s3.ap-south-1.amazonaws.com/blogObjects/tableContent-qLYcF12fYW$Add product or Service from Tool44.png",
      ],
      created_at: "2023-07-15T09:33:19.001+00:00",
    },
    {
      contentId: "75795566-dee9-46ae-add7-57a280ebb1b9",
      category: "Platform Tutorials",
      contentName:
        "How to Add Products/Services on Your Website for Listing in the Marketplace",
      author: "Rahul Rajput",
      icon: [
        "https://tool44prod.s3.ap-south-1.amazonaws.com/blogObjects/tableContent-Z2yq5ynEU9$Add Product or Service.png",
      ],
      created_at: "2023-07-15T09:33:19.001+00:00",
    },
    {
      contentId: "2aea1c74-d971-49aa-8dbe-7aeb051c3b44",
      category: "Platform Tutorials",
      contentName:
        "How to Manage Products/Services on Your Website on Tool44.com",
      author: "Rahul Rajput",
      icon: [
        "https://tool44prod.s3.ap-south-1.amazonaws.com/blogObjects/tableContent-PjcTqySoKA$Product and Service.png",
      ],
      created_at: "2023-07-15T09:33:19.001+00:00",
    },
    {
      contentId: "90877748-7e07-422b-b368-8541f07c1736",
      category: "Platform Tutorials",
      contentName: "How to Add, Edit, and Delete Blogs on Tool44.com",
      author: "Rahul Rajput",
      icon: [
        "https://tool44prod.s3.ap-south-1.amazonaws.com/blogObjects/tableContent-E2T5T3JYj6$Blogs.png",
      ],
      created_at: "2023-07-15T09:33:19.001+00:00",
    },
    {
      contentId: "690b485f-041d-4364-a91d-ce0acc720642",
      category: "Platform Tutorials",
      contentName:
        "How to Add Bank Account Details and Make a Primary Account on Tool44.com",
      author: "Rahul Rajput",
      icon: [
        "https://tool44prod.s3.ap-south-1.amazonaws.com/blogObjects/tableContent-NWtv9d7E7S$Bank Details.png",
      ],
      created_at: "2023-07-15T09:33:19.001+00:00",
    },
    {
      contentId: "f3edd07e-22de-4c78-9ae3-a3ce51c71f23",
      category: "Platform Tutorials",
      contentName:
        "How to Add and Manage Documents for Verification on Tool44.com",
      author: "Rahul Rajput",
      icon: [
        "https://tool44prod.s3.ap-south-1.amazonaws.com/blogObjects/tableContent-9yz9Q5OEBu$Tool Tutorials .png",
      ],
      created_at: "2023-07-15T09:33:19.001+00:00",
    },
  ]);

  useEffect(() => {
    if (
      data?.contentId &&
      user?.userName &&
      !allusers.find((obj) => obj.contentId === id)?.contentId
    ) {
      axios
        .post(
          `${API_HOST}/contentManagement/count`,
          {
            contentId: data?.contentId,
          },
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then(() => {})
        .catch((err) => {
          if (err?.response?.data?.fails?.message === "Insufficient balance") {
            dispatch(userActions.openwalletForm());
          }
        });
    }
  }, [data, user]);

  return (
    <div className="ScrollBarexist" style={{height:"calc(100vh - 4vh)", overflow:"auto"}}>
      <StoryDetailBanner data={data} width={width} />
      <StoryDetailPart data={data} width={width} />
    </div>
  );
}
