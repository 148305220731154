import React from "react";

export default function WebsiteCart({
  width,
  websitedata,
  arrayofProducts,
  setArrayofProducts,
}) {
  return (
    <div className="websiteoffercontainer">
      <div style={{ color: websitedata?.themeColor }} className="offerheading">
        Order Summary
      </div>

      <div className="flexofoffers">
        {arrayofProducts?.length > 0 &&
          arrayofProducts?.map((data, index) => {
            return (
              <div className="cartwebsite">
                <div>
                  <img
                    style={{
                      cursor: "pointer",
                    }}
                    src={data?.web_id?.product_id?.files[0]?.file}
                    alt=""
                  />
                  <div className="cartquantity">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (arrayofProducts[index]?.quantity > 1) {
                          setArrayofProducts([
                            ...arrayofProducts.slice(0, index),
                            {
                              web_id: data?.web_id,
                              quantity: data?.quantity - 1,
                              addOn: data?.addOn,
                              totalAddOnPrices: data?.totalAddOnPrices,
                              calculatedGstPrice:
                                ((data?.web_id?.product_id?.costPrice +
                                  data?.totalAddOnPrices) *
                                  data?.web_id?.product_id?.gstRate *
                                  (data?.quantity - 1)) /
                                100,
                              totalPrice:
                                (data?.web_id?.product_id?.costPrice +
                                  data?.totalAddOnPrices) *
                                (data?.quantity - 1),
                            },
                            ...arrayofProducts.slice(
                              index + 1,
                              arrayofProducts?.length
                            ),
                          ]);
                        } else {
                          setArrayofProducts([
                            ...arrayofProducts.slice(0, index),

                            ...arrayofProducts.slice(
                              index + 1,
                              arrayofProducts?.length
                            ),
                          ]);
                        }
                      }}
                    >
                      -
                    </div>
                    <div>{data?.quantity}</div>
                    <div
                      onClick={() => {
                        if (
                          arrayofProducts[index]?.web_id?.quantity >
                          arrayofProducts[index]?.quantity
                        ) {
                          setArrayofProducts([
                            ...arrayofProducts.slice(0, index),
                            {
                              web_id: data?.web_id,
                              quantity: data?.quantity + 1,
                              addOn: data?.addOn,
                              totalAddOnPrices: data?.totalAddOnPrices,
                              calculatedGstPrice:
                                ((data?.web_id?.product_id?.costPrice +
                                  data?.totalAddOnPrices) *
                                  data?.web_id?.product_id?.gstRate *
                                  (data?.quantity + 1)) /
                                100,
                              totalPrice:
                                (data?.web_id?.product_id?.costPrice +
                                  data?.totalAddOnPrices) *
                                (data?.quantity + 1),
                            },
                            ...arrayofProducts.slice(
                              index + 1,
                              arrayofProducts?.length
                            ),
                          ]);
                        }
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      +
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    overflowY: "scroll",
                    height: width > 700 ? "13vw" : "32vw",
                    width: "100%",
                    marginLeft: width <= 700 && "1vw",
                  }}
                >
                  {" "}
                  <div
                    style={{
                      cursor: "pointer",
                    }}
                    className="namemenuweb"
                  >
                    {data?.web_id?.product_id?.productName?.slice(0, 23)}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      margin: "0.5vw 0vw",
                      marginBottom: "0vw",
                      paddingBottom: "0vw",
                    }}
                    className="pricemenuweb"
                  >
                    <span>
                      Rs.
                      {parseFloat(data?.totalPrice).toFixed(2)}
                    </span>
                  </div>
                  <div
                    style={{ color: data?.web_id?.quantity > 0 ? "" : "red" }}
                    className="stockmenuweb"
                  >
                    Total GST ({data?.web_id?.product_id?.gstRate}%) - Rs{" "}
                    {parseFloat(data?.calculatedGstPrice).toFixed(2)}{" "}
                  </div>
                  <div style={{ marginTop: "0.5vw" }} className="">
                    {data?.web_id?.product_id?.addOn?.map((data1) => {
                      return (
                        <div style={{ margin: "0vw" }} className="addonbox">
                          <div
                            style={{ alignItems: "center" }}
                            className="addondata"
                          >
                            <div
                              style={{
                                width: width > 700 ? "10%" : "7vw",
                                fontSize: width > 700 ? "0.8vw" : "2.4vw",
                              }}
                              className=""
                            >
                              <input
                                style={{
                                  margin: "0.5vw 0vw",
                                  height: width > 700 ? "1vw" : "2.5vw",
                                }}
                                type="checkbox"
                                name=""
                                id=""
                                onChange={(e) => {
                                  if (
                                    arrayofProducts[index]?.addOn?.findIndex(
                                      (x) => x._id === data1?._id
                                    ) === -1
                                  ) {
                                    setArrayofProducts([
                                      ...arrayofProducts.slice(0, index),
                                      {
                                        web_id: data?.web_id,
                                        quantity: data?.quantity,
                                        addOn: [...data?.addOn, data1],
                                        totalAddOnPrices:
                                          data?.totalAddOnPrices +
                                          parseInt(data1?.amount),
                                        calculatedGstPrice:
                                          ((data?.web_id?.product_id
                                            ?.costPrice +
                                            data?.totalAddOnPrices +
                                            parseInt(data1?.amount)) *
                                            data?.web_id?.product_id?.gstRate *
                                            data?.quantity) /
                                          100,
                                        totalPrice:
                                          +(
                                            data?.web_id?.product_id
                                              ?.costPrice +
                                            data?.totalAddOnPrices +
                                            parseInt(data1?.amount)
                                          ) * data?.quantity,
                                      },
                                      ...arrayofProducts.slice(
                                        index + 1,
                                        arrayofProducts?.length
                                      ),
                                    ]);
                                  } else {
                                    console.log();
                                    setArrayofProducts([
                                      ...arrayofProducts.slice(0, index),
                                      {
                                        web_id: data?.web_id,
                                        quantity: data?.quantity,
                                        addOn: [
                                          ...data?.addOn?.slice(
                                            0,
                                            data?.addOn?.findIndex(function (
                                              i
                                            ) {
                                              return i._id === data1?._id;
                                            })
                                          ),
                                          ...data?.addOn?.slice(
                                            data?.addOn?.findIndex(function (
                                              i
                                            ) {
                                              return i._id === data1?._id;
                                            }) + 1,
                                            data?.addOn?.length
                                          ),
                                        ],
                                        totalAddOnPrices:
                                          data?.totalAddOnPrices -
                                          parseInt(data1?.amount),
                                        calculatedGstPrice:
                                          ((data?.web_id?.product_id
                                            ?.costPrice +
                                            data?.totalAddOnPrices -
                                            parseInt(data1?.amount)) *
                                            data?.web_id?.product_id?.gstRate *
                                            data?.quantity) /
                                          100,
                                        totalPrice:
                                          (data?.web_id?.product_id?.costPrice +
                                            data?.totalAddOnPrices -
                                            parseInt(data1?.amount)) *
                                          data?.quantity,
                                      },
                                      ...arrayofProducts.slice(
                                        index + 1,
                                        arrayofProducts?.length
                                      ),
                                    ]);
                                  }
                                }}
                                checked={
                                  arrayofProducts[index]?.addOn?.findIndex(
                                    (x) => x._id === data1?._id
                                  ) > -1
                                }
                              />
                            </div>

                            <div
                              style={{
                                width: "70%",
                                fontSize: width > 700 ? "0.8vw" : "2.5vw",
                                margin: width > 700 ? "0.2vw" : "0.5vw",
                              }}
                              className="addonname"
                            >
                              {data1.name}
                            </div>
                            <div
                              style={{
                                width: "20%",
                                fontSize: width > 700 ? "0.8vw" : "2.5vw",
                                margin: "0.2vw",
                              }}
                              className="addonname"
                            >
                              Rs {data1.amount}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
