import React, { useEffect, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import './BiddingFormDashboard.css';
export default function Texteditor({
  description1,
  setDescription1,
  setDesclength,
}) {
  const editorRef = useRef();

  const handleupdate = () => {
    setDescription1(editorRef.current.getContent());
   
  };

  const handleLetterCount = () => {
     setDesclength(countLetters(editorRef.current.getContent()));
  }



  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.setContent(description1);
    }
  }, [description1]);

  useEffect(() => {
    console.log('Texteditor - description1:', description1);
    console.log('Texteditor - setDescription1:', setDescription1);
  }, [description1, setDescription1]);

  const countLetters = (text) => {
    const plainText = text.replace(/<[^>]+>/g, '');

    return plainText.length;
  };

  return (
    <>
      <Editor
        apiKey='hq7jzwt0yf8k0drn91xhi4c1d2zps3y23blx9qxfvoltoabe'
        toolbar_mode='sliding'
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={description1}
        onBlur={() => handleupdate()}
        init={{
          height: 250,
          menubar: false,
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'preview',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'code',
            'help',
            'wordcount',
          ],
          toolbar:
            'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help' +
            ' link image ' +
            'table' +
            ' media',

          content_style:
            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        }}
        onEditorChange={(content) => handleLetterCount(content)}
      />
    </>
  );
}
