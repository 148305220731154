import React, { useEffect, useState } from "react";
import "./profile.css";
import "./croper.css";
import "./background.css";
import img2 from "../../assets/Success stories Definition/checkmark (1).svg";
import img4 from "../../assets/My profile – 28/pexels-stefan-stefancik-91227.png";
import img from "../../assets/Success stories/alvaro-reyes-qWwpHwip31M-unsplash.png";
import imgedit from "../../assets/My profile – 28/Component 71 – 6.svg";
import { userActions } from "../../store/userSlice";
import axios from "axios";
import Cropper from "react-easy-crop";
import Slider from "@material-ui/core/Slider";
import imglogo from "../../assets/Logo/tools 44 shorten.png";
import API_HOST from "../../env";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import Modal1 from "@mui/material/Modal";
import Button from "@material-ui/core/Button";
import { getCroppedImg } from "../../utils/cropImage";
import ClearIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";
import { Drawer, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@material-ui/core";
import imageCompression from "browser-image-compression";
import facebookIcon from "../../assets/Logo/facebook_icon.png";
import img3 from "../../assets/Dashboard/share.png";
import GoogleIcon from "../../assets/Logo/7611770.png";
import LinkedInIcon from "../../assets/Logo/174857.png";
import GitHubIcon from "../../assets/Logo/Octicons-mark-github.svg.png";
import dob from "../../assets/Logo/Calendar.png";
import location from "../../assets/Logo/Location.png";
import phone from "../../assets/Logo/Call.png";
import email from "../../assets/Logo/Message.png";
import { ShareSocial } from "react-share-social";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Link,
  PDFDownloadLink,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";
import PhoneVerify from "./PhoneVerify";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    color: "black",
    padding: 22.5,
  },
  section: {
    margin: 1,
    padding: 1,
    marginBottom: 2,
  },
  section1: {
    margin: 1,
    padding: 1,
    marginTop: 5,
  },

  sectionleft: {
    margin: 0,
    padding: 0,
    width: "27.5%",
    float: "left",
    height: "100%",
  },
  sectionright: {
    margin: 0,
    padding: 0,
    width: "67.5%",
    float: "right",
    height: "100%",
  },

  viewer: {
    width: "80vw",
    height: "90vh",
  },

  flexBoxforleftandright: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },

  Blueheading: {
    color: "#0052cc",
  },
  simpletext: {
    fontSize: "11px",
    color: "#000000b1",

    paddingTop: "2px",
  },
  simpletextBlod: {
    width: "100%",
    fontFamily: "Helvetica-Bold",
  },
  image: {
    width: "90%",
    height: "130px",
    objectFit: "cover",
  },
  fullName: {
    fontSize: "28px",
    marginBottom: "1px",
    color: "#0052cc",
    paddingTop: "0",
  },
  worktitle: {
    fontSize: "15px",
    marginBottom: "6px",
    marginTop: "5px",
    color: "#0052ccd1",
  },
  hr: {
    width: "100%",
    borderBottom: "1px solid gray",
    height: "0",
    marginTop: "5px",
    marginBottom: "4px",
  },
  flexBoxforhalf: {
    display: "flex",
    flexDirection: "row",
    margin: "5px 0px",
  },
  flexBoxonly: {
    width: "25%",
    display: "flex",
    flexDirection: "row",
    paddingRight: 2,
  },
  imagex: {
    width: "17px",
    height: "17px",
    margin: "0px",
    objectFit: "contain",
  },
  imagexx: {
    width: "15px",
    height: "15px",
    margin: "0.5px",
    objectFit: "contain",
  },
  blueheading: {
    fontSize: "14px",
    fontFamily: "Helvetica-Bold",
    color: "#0052cc",
    margin: "8px 0",
    marginBottom: "5px",
  },
  bluesubheading: {
    fontSize: "12px",
    fontFamily: "Helvetica-Bold",
    color: "#0052cc",
    margin: "2px 0",
  },
  blacksubheading: {
    fontSize: "11px",
    fontFamily: "Helvetica-Bold",
    color: "#000000cc",
    margin: "1px 0",
  },
  profileFlex: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "row",
    margin: "3px 0",
  },
  justifyContent: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "1px 0",
    width: "100%",
  },
  justifyContent2: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "2px 0",
    marginLeft: "3px",
  },

  content: {
    marginBottom: 60, // Adjust the margin to accommodate the logo
  },
  logo: {
    width: "50px",
    height: "15px",
    fontSize: "13px",
    objectFit: "contain",
    marginTop: "3px",
    marginLeft: "2px",
  },

  footer: {
    position: "absolute",
    bottom: 5,
    left: 30,
    right: 10,
    textAlign: "right",
    display: "flex",
    flexDirection: "row",

    alignItems: "center",
    justifyContent: "flex-end",
    color: "#000000b4",
  },
  bluedot: {
    width: "5px",
    height: "5px",
    borderRadius: "50%",
    backgroundColor: "#0052cc",
  },
  bluedotcover: {
    width: "0px",
    position: "relative",
    right: "1.5vw",
    top: "3px",
  },
});

Font.register({
  family: "Roboto",
  fonts: [
    { src: "<path-to-normal-font-variant>" },
    { src: "<path-to-italic-font-variant>", fontStyle: "italic" },
    { src: "<path-to-italic-font-variant>", fontWeight: "600" },
  ],
});

const MyDoc = ({
  user,
  width,
  Refrencesall,
  portfolioall,
  workexp,
  education,
}) => (
  <Document>
    <Page
      size='A4'
      style={styles.page}
    >
      <View style={styles.section}>
        <View style={styles.flexBoxforleftandright}>
          <View style={styles.sectionleft}>
            {user?.media && (
              <Image
                style={styles.image}
                src={{
                  uri: user?.media,
                  method: 'GET',
                  headers: { 'Cache-Control': 'no-cache' },
                  body: '',
                }}
                alt=''
              />
            )}
          </View>
          <View style={styles.sectionright}>
            <View>
              <Text
                style={{
                  ...styles.simpletextBlod,
                  ...styles.fullName,
                }}
              >
                {user?.fullName}
              </Text>
              <Text style={{ ...styles.worktitle }}>{user?.title}</Text>
            </View>

            <Text
              dangerouslySetInnerHTML={{
                __html: user?.fullDescription ? user?.fullDescription : '',
              }}
              style={styles.simpletext}
            >
              //  {user?.fullDescription}
            </Text>

            <View style={styles.hr}></View>
          </View>
        </View>
      </View>
      <View style={styles.section1}>
        <View style={styles.flexBoxforleftandright}>
          <View style={styles.sectionleft}>
            <Text style={styles.blueheading}>Profile</Text>
            <View style={styles?.flexBoxforhalf}>
              <View style={styles?.flexBoxonly}>
                <Text style={styles.simpletext}>
                  <Link
                    style={{ textDecoration: 'none' }}
                    src={user.fLink}
                  >
                    {' '}
                    <Image
                      style={styles.imagex}
                      src={facebookIcon}
                      alt=''
                    />
                  </Link>
                </Text>
              </View>

              <View style={styles?.flexBoxonly}>
                <Text>
                  <Link
                    style={{ textDecoration: 'none' }}
                    src={user.gLink}
                  >
                    {' '}
                    <Image
                      style={styles.imagex}
                      src={GoogleIcon}
                      alt=''
                    />
                  </Link>{' '}
                </Text>
              </View>

              <View style={styles?.flexBoxonly}>
                <Text>
                  <Link
                    style={{ textDecoration: 'none' }}
                    src={user.lLink}
                  >
                    {' '}
                    <Image
                      style={styles.imagex}
                      src={LinkedInIcon}
                      alt=''
                    />
                  </Link>{' '}
                </Text>
              </View>

              <View style={styles?.flexBoxonly}>
                <Text>
                  <Link
                    style={{ textDecoration: 'none' }}
                    src={user.gitLink}
                  >
                    {' '}
                    <Image
                      style={styles.imagex}
                      src={GitHubIcon}
                      alt=''
                    />
                  </Link>{' '}
                </Text>
              </View>
            </View>

            {user?.contactNo && (
              <View style={styles.profileFlex}>
                <Image
                  src={phone}
                  alt=''
                  style={styles.imagexx}
                />{' '}
                <Text
                  style={{
                    ...styles.simpletext,
                    paddingLeft: '3px',
                  }}
                >
                  {user?.contactNo}
                </Text>
              </View>
            )}
            {user?.emailId && (
              <View style={styles.profileFlex}>
                <Image
                  src={email}
                  alt=''
                  style={styles.imagexx}
                />{' '}
                <Text
                  style={{
                    ...styles.simpletext,
                    paddingLeft: '3px',
                  }}
                >
                  {user?.emailId}
                </Text>
              </View>
            )}
            {user?.dob && (
              <View style={styles.profileFlex}>
                <Image
                  src={dob}
                  alt=''
                  style={styles.imagexx}
                />{' '}
                <Text
                  style={{
                    ...styles.simpletext,
                    paddingLeft: '3px',
                  }}
                >
                  {user?.dob && new Date(user?.dob).getDate()}/
                  {user?.dob && new Date(user?.dob).getMonth() + 1}/
                  {user?.dob && new Date(user?.dob).getFullYear()}
                </Text>
              </View>
            )}

            {user?.address && (
              <View style={styles.profileFlex}>
                <Image
                  src={location}
                  alt=''
                  style={styles.imagexx}
                />{' '}
                <Text
                  style={{
                    ...styles.simpletext,
                    paddingLeft: '3px',
                  }}
                >
                  {user?.address}
                </Text>
              </View>
            )}
            {user?.languages?.length > 0 && (
              <Text style={styles.blueheading}>Language</Text>
            )}
            {user?.languages?.length > 0 &&
              user?.languages?.map((lang) => {
                return (
                  <View style={styles.justifyContent2}>
                    <Text style={styles.blacksubheading}>{lang?.language}</Text>
                    <View
                      style={{
                        ...styles.profileFlex,

                        width: '50px',
                        height: '4px',
                        alignItems: 'center',
                      }}
                    >
                      <View
                        style={{
                          width:
                            lang?.proficiencyLevel === 'Highly'
                              ? '100%'
                              : lang?.proficiencyLevel === 'Intermediate'
                              ? '65%'
                              : '36%',
                          marginTop: '3px',
                          height: '4px',
                          backgroundColor: '#0052cc',
                        }}
                      ></View>
                      <View
                        style={{
                          width:
                            lang?.proficiencyLevel === 'Highly'
                              ? '0%'
                              : lang?.proficiencyLevel === 'Intermediate'
                              ? '34%'
                              : '66%',
                          height: '4px',
                          marginTop: '3px',
                          backgroundColor: '#0052cc90',
                        }}
                      ></View>
                    </View>
                  </View>
                );
              })}

            {user?.noOfSkillSet?.length > 0 && (
              <Text style={styles.blueheading}>Skills</Text>
            )}

            {user?.noOfSkillSet?.length > 0 &&
              user?.noOfSkillSet?.map((skill, ind) => {
                return (
                  ind % 2 !== 1 && (
                    <View style={styles.justifyContent2}>
                      <Text
                        style={{
                          ...styles.blacksubheading,
                          width: '50%',
                        }}
                      >
                        {skill?.skill}
                      </Text>
                      {ind < user?.noOfSkillSet?.length && (
                        <Text
                          style={{
                            ...styles.blacksubheading,
                            width: '50%',
                          }}
                        >
                          {user?.noOfSkillSet[ind + 1]?.skill}
                        </Text>
                      )}
                    </View>
                  )
                );
              })}

            {user?.hobbies?.length > 0 && (
              <Text style={styles.blueheading}>Hobbies</Text>
            )}
            {user?.hobbies?.length > 0 &&
              user?.hobbies?.map((skill, ind) => {
                return (
                  ind % 2 !== 1 && (
                    <View style={styles.justifyContent2}>
                      <Text
                        style={{
                          ...styles.blacksubheading,
                          width: '50%',
                        }}
                      >
                        {skill?.hobby}
                      </Text>
                      {ind < user?.noOfSkillSet?.length && (
                        <Text
                          style={{
                            ...styles.blacksubheading,
                            width: '50%',
                          }}
                        >
                          {user?.hobbies[ind + 1]?.hobby}
                        </Text>
                      )}
                    </View>
                  )
                );
              })}

            {Refrencesall?.length > 0 && (
              <Text style={styles.blueheading}>Refrence</Text>
            )}
            {Refrencesall?.length > 0 &&
              Refrencesall?.map((refrence) => {
                return (
                  <View style={{ marginBottom: '5px' }}>
                    <View>
                      <Text
                        style={{
                          ...styles.blacksubheading,
                          width: '100%',
                        }}
                      >
                        {refrence?.name}
                      </Text>
                    </View>

                    <View>
                      <Text
                        style={{
                          ...styles.simpletext,
                          width: '100%',
                        }}
                      >
                        {refrence?.title}
                      </Text>
                    </View>
                    <Text
                      style={{
                        ...styles.simpletext,
                        width: '100%',
                      }}
                    >
                      <Text
                        style={{
                          ...styles.simpletextBlod,
                        }}
                      >
                        P:
                      </Text>
                      {refrence?.phone}
                    </Text>
                    <Text
                      style={{
                        ...styles.simpletext,
                        width: '100%',
                      }}
                    >
                      <Text
                        style={{
                          ...styles.simpletextBlod,
                          width: '100%',
                        }}
                      >
                        E:
                      </Text>
                      {refrence?.emailId}
                    </Text>
                  </View>
                );
              })}
          </View>
          <View style={styles.sectionright}>
            {workexp?.length > 0 && (
              <Text style={styles.blueheading}>Work Experience</Text>
            )}

            {workexp?.length > 0 &&
              workexp?.map((work) => {
                return (
                  <View
                    style={{
                      marginLeft: '5px',
                      marginTop: '3px',
                      marginBottom: '3px',
                      width: '100%',
                    }}
                  >
                    <View style={styles.justifyContent}>
                      <View
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'row',
                          width: '78%',
                        }}
                      >
                        <View style={styles.bluedotcover}>
                          <View style={styles.bluedot}></View>
                        </View>
                        <Text style={styles.bluesubheading}>
                          {work?.companyName}
                        </Text>
                      </View>
                      <View>
                        <Text style={styles.simpletext}>
                          <Text style={styles.simpletext}>
                            {work?.startDate &&
                              new Date(work?.startDate).getDate()}
                            /
                            {work?.startDate &&
                              new Date(work?.startDate).getMonth() + 1}
                            /
                            {work?.startDate &&
                              new Date(work?.startDate).getFullYear()}
                            -{' '}
                            {!work?.workingCurrently
                              ? new Date(work?.endDate).getDate() +
                                '/' +
                                (new Date(work?.endDate).getMonth() + 1) +
                                '/' +
                                new Date(work?.endDate).getFullYear()
                              : 'Present'}
                          </Text>
                        </Text>
                      </View>
                    </View>
                    <Text style={styles.blacksubheading}>{work?.jobTitle}</Text>
                    <Text style={styles.simpletext}>{work?.description}</Text>
                  </View>
                );
              })}

            {education?.length > 0 && (
              <Text style={styles.blueheading}>Education</Text>
            )}

            {education?.length > 0 &&
              education?.map((educa) => {
                return (
                  <View
                    style={{
                      marginLeft: '5px',
                      marginTop: '3px',
                      marginBottom: '3px',
                      width: '100%',
                    }}
                  >
                    <View style={styles.justifyContent}>
                      <View
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'row',
                          width: '78%',
                        }}
                      >
                        <View style={styles.bluedotcover}>
                          <View style={styles.bluedot}></View>
                        </View>

                        <Text style={styles.bluesubheading}>
                          {educa?.collegeName}
                        </Text>
                      </View>
                      <View>
                        <Text style={styles.simpletext}>
                          {educa?.fromDate &&
                            new Date(educa?.fromDate).getFullYear()}
                          -
                          {educa?.toDate &&
                            new Date(educa?.toDate).getFullYear()}
                        </Text>
                      </View>
                    </View>
                    <Text style={styles.blacksubheading}>
                      {educa?.degree}{' '}
                      {educa?.areaOfStudy ? `(${educa?.areaOfStudy})` : ''}{' '}
                    </Text>
                  </View>
                );
              })}

            {portfolioall?.length > 0 && (
              <Text style={styles.blueheading}>Projects</Text>
            )}

            {portfolioall?.length > 0 &&
              portfolioall?.map((portfolio) => {
                return (
                  <View
                    style={{
                      marginLeft: '5px',
                      marginTop: '3px',
                      marginBottom: '3px',
                      width: '100%',
                    }}
                  >
                    <View style={styles.justifyContent}>
                      <View
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'row',
                          width: '78%',
                        }}
                      >
                        <View style={styles.bluedotcover}>
                          <View style={styles.bluedot}></View>
                        </View>
                        <Text style={styles.bluesubheading}>
                          {portfolio?.projectTitle}
                        </Text>
                      </View>
                      <View>
                        <Text style={styles.simpletext}>
                          {portfolio?.startingDate &&
                            new Date(portfolio?.startingDate).getDate()}
                          /
                          {portfolio?.startingDate &&
                            new Date(portfolio?.startingDate).getMonth() + 1}
                          /
                          {portfolio?.startingDate &&
                            new Date(portfolio?.startingDate).getFullYear()}
                          -{' '}
                          {!portfolio?.currentlyWorking
                            ? new Date(portfolio?.completionDate).getDate() +
                              '/' +
                              (new Date(portfolio?.completionDate).getMonth() +
                                1) +
                              '/' +
                              new Date(portfolio?.completionDate).getFullYear()
                            : 'Present'}
                        </Text>
                      </View>
                    </View>
                    <Text style={styles.blacksubheading}>
                      <Link
                        style={{
                          textDecoration: 'none',
                          color: 'black',
                        }}
                        src={portfolio?.projectUrl}
                      >
                        {portfolio?.projectUrl}
                      </Link>
                    </Text>
                    <Text style={styles.simpletext}>
                      {portfolio?.projectDescription}
                    </Text>
                  </View>
                );
              })}
          </View>
        </View>
      </View>
      <View style={styles.footer}>
        <Text style={styles.simpletext}> Resume created on</Text>
        <Image
          style={styles.logo}
          src={imglogo}
        />
      </View>
    </Page>
  </Document>
);

const useStyles = makeStyles((theme) => ({
  input: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "0.91vw",
    color: "#263238",
    border: "yellow !important",
    // padding: "1vw",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  maxHeight: "95vh",
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  maxHeight: "95vh",
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
};
const style3 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  bgcolor: "#00000060",
  boxShadow: 24,
  height: "fit-content",
  overflow: "scroll",
  padding: 0,
};

const style2 = {
  position: "absolute",
  maxHieght: "90vh",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "91vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  height: "fit-content",
};

function MyVerticallyCenteredModal(props) {
  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };
  const inputRef = React.useRef();

  const triggerFileSelectPopup = () => inputRef.current.click();
  const [image, setImage] = React.useState(
    URL.createObjectURL(props?.selectedFile)
  );
  const [croppedArea, setCroppedArea] = React.useState(null);
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);

  const [imgxxxxx, setimgxxxxx] = useState(null);

  const onSelectFilex = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setImage(event.target.files[0]);
      const objectUrl = URL.createObjectURL(event.target.files[0]);

      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.addEventListener("load", () => {
        setImage(reader.result);
      });
    }
  };
  function srcToFile(src, fileName, mimeType) {
    return fetch(src)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], fileName, { type: mimeType });
      });
  }
  const onDownload = async () => {
    const canvas = await getCroppedImg(image, croppedArea);
    var dataURL = canvas.toDataURL();
    srcToFile(dataURL, "hello.png", "image/png").then(function (file) {
      setimgxxxxx(file);
      handleImageUpload1(file);
      props.setSelectedFile(null);
    });
  };
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);

  async function fetchUserData(res) {
    window.localStorage.setItem(
      "user",
      JSON.stringify({ ...res.data.success.data })
    );

    dispatch(
      userActions.setUser({
        user: { ...res.data.success.data },
      })
    );
  }
  async function handleImageUpload1(imageFile) {
    console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log(
        "compressedFile instanceof Blob",
        compressedFile instanceof Blob
      ); // true
      console.log(
        `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
      ); // smaller than maxSizeMB

      //   await uploadToServer(compressedFile); // write your own logic
      await updateProfilePic(
        new File([compressedFile], `${compressedFile?.name}`)
      );
    } catch (error) {
      console.log(error);
    }
  }

  async function updateProfilePic(selectedFile) {
    try {
      const data1 = new FormData();

      console.log(selectedFile);

      data1.append("userId", user?.userId);
      data1.append("fileName", selectedFile);
      const res = await axios.post(`${API_HOST}/users/editUser`, data1, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      });
      await fetchUserData(res);
    } catch (err) {}
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{
        width: "40vw",
        margin: "0 30vw",

        borderRadius: "10px",
      }}
    >
      <Modal.Body style={{ margin: "0 auto" }}>
        <div className="containerppoppop">
          <div className="containerpopouo-heading">
            <div className="containerpopup-heading-hdsbd">
              {" "}
              Update profile picture
            </div>
            <div>
              <h4>
                <ClearIcon
                  onClick={props.onHide}
                  style={{ cursor: "pointer", float: "right" }}
                />
              </h4>
            </div>
          </div>

          <div className="container-cropper">
            {image ? (
              <>
                <div className="cropper">
                  <Cropper
                    image={image}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onCropComplete={onCropComplete}
                  />
                </div>

                <div className="slider1">
                  <Slider
                    min={1}
                    max={3}
                    step={0.01}
                    value={zoom}
                    onChange={(e, zoom) => setZoom(zoom)}
                  />
                </div>
              </>
            ) : (
              <>
                <div
                  style={{ border: "2px dashed #6b6b6b" }}
                  className="cropper"
                >
                  <div className="middlechoodeimagecrops">Choose an Image</div>
                </div>
              </>
            )}
          </div>
          <div
            style={{
              fontSize: "12px",
              width: "72%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            Image should be less then 256 kb.
          </div>
          <div className="container-buttons">
            <input
              type="file"
              accept="image/*"
              ref={inputRef}
              onChange={onSelectFilex}
              style={{ display: "none" }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={triggerFileSelectPopup}
              style={{
                marginRight: "10px",
                backgroundColor: "#0052cc",
                color: "white",
              }}
            >
              Choose
            </Button>
            <Button
              onClick={onDownload}
              variant="contained"
              style={{ backgroundColor: "#0052cc", color: "white" }}
            >
              UpDate
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function MyVerticallyCenteredModalm(props) {
  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };
  const inputRef = React.useRef();

  const triggerFileSelectPopup = () => inputRef.current.click();
  const [image, setImage] = React.useState(
    URL.createObjectURL(props?.selectedFile)
  );
  const [croppedArea, setCroppedArea] = React.useState(null);
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);

  const [imgxxxxx, setimgxxxxx] = useState(null);

  const onSelectFilex = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setImage(event.target.files[0]);
      const objectUrl = URL.createObjectURL(event.target.files[0]);

      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.addEventListener("load", () => {
        setImage(reader.result);
      });
    }
  };
  function srcToFile(src, fileName, mimeType) {
    return fetch(src)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], fileName, { type: mimeType });
      });
  }
  const onDownload = async () => {
    const canvas = await getCroppedImg(image, croppedArea);
    var dataURL = canvas.toDataURL();
    srcToFile(dataURL, "hello.png", "image/png").then(function (file) {
      setimgxxxxx(file);
      handleImageUpload1(file);
      props.setSelectedFile(null);
    });
  };
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);

  async function fetchUserData(res) {
    window.localStorage.setItem(
      "user",
      JSON.stringify({ ...res.data.success.data })
    );

    dispatch(
      userActions.setUser({
        user: { ...res.data.success.data },
      })
    );
  }
  async function handleImageUpload1(imageFile) {
    console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log(
        "compressedFile instanceof Blob",
        compressedFile instanceof Blob
      ); // true
      console.log(
        `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
      ); // smaller than maxSizeMB

      //   await uploadToServer(compressedFile); // write your own logic
      await updateProfilePic(
        new File([compressedFile], `${compressedFile?.name}`)
      );
    } catch (error) {
      console.log(error);
    }
  }

  async function updateProfilePic(selectedFile) {
    try {
      const data1 = new FormData();
      console.log(selectedFile);
      data1.append("userId", user?.userId);
      data1.append("fileName", selectedFile);
      const res = await axios.post(`${API_HOST}/users/editUser`, data1, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      });
      await fetchUserData(res);
    } catch (err) {}
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{
        width: "100vw",

        borderRadius: "10px",
      }}
    >
      <Modal.Body style={{ margin: "0 auto", height: "122vw" }}>
        <div className="containerppoppopxm">
          <div className="containerpopouo-headingm">
            <div className="containerpopup-heading-hdsbdm">
              {" "}
              Update profile picture
            </div>
            <div>
              <h4>
                <ClearIcon
                  onClick={props.onHide}
                  style={{ cursor: "pointer", float: "right" }}
                />
              </h4>
            </div>
          </div>

          <div className="container-cropperxm">
            {image ? (
              <>
                <div className="cropperxm">
                  <Cropper
                    image={image}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onCropComplete={onCropComplete}
                  />
                </div>

                <div className="sliderxm">
                  <Slider
                    min={1}
                    max={3}
                    step={0.01}
                    value={zoom}
                    onChange={(e, zoom) => setZoom(zoom)}
                  />
                </div>
              </>
            ) : (
              <>
                <div
                  style={{ border: "2px dashed #6b6b6b" }}
                  className="cropper"
                >
                  <div className="middlechoodeimagecrops">Choose an Image</div>
                </div>
              </>
            )}
          </div>
          <div
            style={{
              fontSize: "12px",
              width: "100%",
              margin: "1vw 0",
            }}
          >
            Image should be less then 256 kb.
          </div>
          <div className="container-buttonsxm">
            <input
              type="file"
              accept="image/*"
              ref={inputRef}
              onChange={onSelectFilex}
              style={{ display: "none" }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={triggerFileSelectPopup}
              style={{
                marginRight: "10px",
                backgroundColor: "#0052cc",
                color: "white",
              }}
            >
              Choose
            </Button>
            <Button
              onClick={onDownload}
              variant="contained"
              style={{ backgroundColor: "#0052cc", color: "white" }}
            >
              UpDate
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function MyVerticallyCenteredModalx(props) {
  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };
  const inputRef = React.useRef();

  const triggerFileSelectPopup = () => inputRef.current.click();
  const [image, setImage] = React.useState(
    URL.createObjectURL(props?.selectedFilex)
  );
  const [croppedArea, setCroppedArea] = React.useState(null);
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);

  const [imgxxxxx, setimgxxxxx] = useState(null);

  const onSelectFilex = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setImage(event.target.files[0]);
      const objectUrl = URL.createObjectURL(event.target.files[0]);

      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.addEventListener("load", () => {
        setImage(reader.result);
      });
    }
  };
  function srcToFile(src, fileName, mimeType) {
    return fetch(src)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], fileName, { type: mimeType });
      });
  }
  const onDownload = async () => {
    const canvas = await getCroppedImg(image, croppedArea);
    var dataURL = canvas.toDataURL();
    srcToFile(dataURL, "hello.png", "image/png").then(function (file) {
      setimgxxxxx(file);

      handleImageUpload1(file);
      props.setSelectedFile(null);
    });
  };
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);

  async function fetchUserData(res) {
    window.localStorage.setItem(
      "user",
      JSON.stringify({ ...res.data.success.data })
    );

    dispatch(
      userActions.setUser({
        user: { ...res.data.success.data },
      })
    );
  }

  async function handleImageUpload1(imageFile) {
    console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log(
        "compressedFile instanceof Blob",
        compressedFile instanceof Blob
      ); // true
      console.log(
        `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
      ); // smaller than maxSizeMB

      //   await uploadToServer(compressedFile); // write your own logic
      await updateCoverPic(
        new File([compressedFile], `${compressedFile?.name}`)
      );
    } catch (error) {
      console.log(error);
    }
  }

  async function updateCoverPic(selectedFile) {
    try {
      const data = new FormData();
      console.log(selectedFile?.size / (1024 * 1024));
      data.append("userId", user?.userId);
      data.append("fileName", selectedFile);

      const res = await axios.post(`${API_HOST}/users/editCoverPic`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      });
      await fetchUserData(res);
    } catch (err) {}
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{
        width: "100vw",
        borderRadius: "10px",
        height: "100vh",
      }}
    >
      <Modal.Body style={{ margin: "0 auto", height: "50vw" }}>
        <div className="containerppoppopx">
          <div className="containerpopouo-heading">
            <div className="containerpopup-heading-hdsbd">
              {" "}
              Update Cover picture
            </div>
            <div>
              <h4>
                <ClearIcon
                  onClick={props.onHide}
                  style={{ cursor: "pointer", float: "right" }}
                />
              </h4>
            </div>
          </div>
          <div className="container-cropperx">
            {image ? (
              <>
                <div className="cropperx">
                  <Cropper
                    image={image}
                    crop={crop}
                    zoom={zoom}
                    aspect={3.5}
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onCropComplete={onCropComplete}
                  />
                </div>

                <div className="sliderx">
                  <Slider
                    min={1}
                    max={3}
                    step={0.1}
                    value={zoom}
                    onChange={(e, zoom) => setZoom(zoom)}
                  />
                </div>
              </>
            ) : (
              <>
                <div
                  style={{ border: "2px dashed #6b6b6b" }}
                  className="cropperx"
                >
                  <div className="middlechoodeimagecrops">Choose an Image</div>
                </div>
              </>
            )}
          </div>
          <div
            style={{
              fontSize: "12px",
              width: "80%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            Image Should be less then 700 kb.
          </div>
          <div className="container-buttonsx  dvpopupsetcimg">
            <input
              type="file"
              accept="image/*"
              ref={inputRef}
              onChange={onSelectFilex}
              style={{ display: "none" }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={triggerFileSelectPopup}
              style={{
                marginRight: "10px",
                backgroundColor: "#0052cc",
                color: "white",
              }}
            >
              Choose
            </Button>
            <Button
              onClick={onDownload}
              variant="contained"
              style={{ backgroundColor: "#0052cc", color: "white" }}
            >
              UpDate
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function MyVerticallyCenteredModalxm(props) {
  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };
  const inputRef = React.useRef();

  const triggerFileSelectPopup = () => inputRef.current.click();
  const [image, setImage] = React.useState(
    URL.createObjectURL(props?.selectedFilex)
  );
  const [croppedArea, setCroppedArea] = React.useState(null);
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);

  const [imgxxxxx, setimgxxxxx] = useState(null);

  const onSelectFilex = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setImage(event.target.files[0]);
      const objectUrl = URL.createObjectURL(event.target.files[0]);

      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.addEventListener("load", () => {
        setImage(reader.result);
      });
    }
  };
  function srcToFile(src, fileName, mimeType) {
    return fetch(src)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], fileName, { type: mimeType });
      });
  }
  const onDownload = async () => {
    const canvas = await getCroppedImg(image, croppedArea);
    var dataURL = canvas.toDataURL();
    srcToFile(dataURL, "hello.png", "image/png").then(function (file) {
      setimgxxxxx(file);

      handleImageUpload1(file);
      props.setSelectedFile(null);
    });
  };
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);

  async function fetchUserData(res) {
    window.localStorage.setItem(
      "user",
      JSON.stringify({ ...res.data.success.data })
    );

    dispatch(
      userActions.setUser({
        user: { ...res.data.success.data },
      })
    );
  }
  async function handleImageUpload1(imageFile) {
    console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log(
        "compressedFile instanceof Blob",
        compressedFile instanceof Blob
      ); // true
      console.log(
        `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
      ); // smaller than maxSizeMB

      //   await uploadToServer(compressedFile); // write your own logic
      await updateCoverPic(
        new File([compressedFile], `${compressedFile?.name}`)
      );
    } catch (error) {
      console.log(error);
    }
  }

  async function updateCoverPic(selectedFile) {
    try {
      const data = new FormData();
      console.log(selectedFile);
      data.append("userId", user?.userId);
      data.append("fileName", selectedFile);
      const res = await axios.post(`${API_HOST}/users/editCoverPic`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      });
      await fetchUserData(res);
    } catch (err) {}
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{
        width: "100",

        borderRadius: "10px",
      }}
    >
      <Modal.Body style={{ margin: "0 auto", height: "120vw" }}>
        <div className="containerppoppopxm">
          <div className="containerpopouo-headingm">
            <div className="containerpopup-heading-hdsbdm">
              Update Cover picture
            </div>
            <div>
              <h4>
                <ClearIcon
                  onClick={props.onHide}
                  style={{ cursor: "pointer", float: "right" }}
                />
              </h4>
            </div>
          </div>
          <div className="container-cropperxm">
            {image ? (
              <>
                <div className="cropperxm">
                  <Cropper
                    image={image}
                    crop={crop}
                    zoom={zoom}
                    aspect={3.5}
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onCropComplete={onCropComplete}
                  />
                </div>

                <div className="sliderxm">
                  <Slider
                    min={1}
                    max={3}
                    step={0.1}
                    value={zoom}
                    onChange={(e, zoom) => setZoom(zoom)}
                  />
                </div>
              </>
            ) : (
              <>
                <div
                  style={{ border: "2px dashed #6b6b6b" }}
                  className="cropperx"
                >
                  <div className="middlechoodeimagecrops">Choose an Image</div>
                </div>
              </>
            )}
          </div>
          <div
            style={{
              fontSize: "12px",
              width: "100%",
            }}
          >
            Image should be less then 256 kb.
          </div>
          <div className="container-buttonsxm  ">
            <input
              type="file"
              accept="image/*"
              ref={inputRef}
              onChange={onSelectFilex}
              style={{ display: "none" }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={triggerFileSelectPopup}
              style={{
                marginRight: "10px",
                backgroundColor: "#0052cc",
                color: "white",
              }}
            >
              Choose
            </Button>
            <Button
              onClick={onDownload}
              variant="contained"
              style={{ backgroundColor: "#0052cc", color: "white" }}
            >
              UpDate
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const style5 = {
  root: {
    background: "white",
    borderRadius: 3,
    border: 0,
    padding: "0vw",
    color: "black",
    width: "100%",
    maxWidth: "100%",
  },
  copyContainer: {
    border: "1px solid blue",
    background: "rgb(0,0,0,0.4)",
    padding: "1vw 1vw",
  },
  title: {
    color: "#0052cc !important",
    fontStyle: "italic",
    marginBottom: "0vw",
    marginTop: "1vw",
  },
};
export default function ProfileBanner({
  user,
  width,
  Refrencesall,
  portfolioall,
  workexp,
  education,
}) {
  const dispatch = useDispatch();

  const classes = useStyles();

  const [selectedFile, setSelectedFile] = useState();
  const [selectedFile1, setSelectedFile1] = useState();

  async function handleImageUpload(event) {
    setSelectedFile(event.target.files[0]);
  }
  async function handleImageUpload1(event) {
    setSelectedFile1(event.target.files[0]);
  }

  const [state1, setState1] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer1 = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState1({ ...state1, [anchor]: open });
  };

  const list1 = (anchor) => (
    <Box
      sx={{
        width:
          anchor === "top" || anchor === "bottom"
            ? "auto"
            : width > 700
            ? "30vw"
            : "90vw",
      }}
      role="presentation"
    >
      <PhoneVerify
        toggleDrawer={toggleDrawer1}
        closedrawer={toggleDrawer1("right", false)}
        width={width}
      />
    </Box>
  );
  const [modalShow, setModalShow] = useState(true);
  const [modalShowx, setModalShowx] = useState(true);
  const [Name, setName] = useState("");

  useEffect(() => {
    setName(user?.fullName);
  }, [user]);

  // PROFILE NAME UPDATE FUNCT.
  const handleProfileName = () => {
    const formdata = new FormData();

    formdata.append("userId", user?.userId);
    formdata.append("fullName", Name);

    axios
      .post(`${API_HOST}/users/editUser`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        console.log(res.data.success.data);

        window.localStorage.setItem(
          "user",
          JSON.stringify({ ...res.data.success.data })
        );

        dispatch(
          userActions.setUser({
            user: { ...res.data.success.data },
          })
        );
        setOpen3(false);
      });
  };

  const [open3, setOpen3] = useState(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);

  const handleopentowork = () => {
    axios
      .post(
        `${API_HOST}/users/open`,
        { openForWork: !user?.openForWork },
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((res) => {
        console.log(res.data.success.data);

        window.localStorage.setItem(
          "user",
          JSON.stringify({ ...res.data.success.data })
        );

        dispatch(
          userActions.setUser({
            user: { ...res.data.success.data },
          })
        );
        setOpen3(false);
      });
  };

  const [openx, setOpenx] = React.useState(false);
  const handleOpenx = () => setOpenx(true);
  const handleClosex = () => setOpenx(false);

  const [openx1, setOpenx1] = React.useState(false);
  const handleOpenx1 = () => setOpenx1(true);
  const handleClosex1 = () => setOpenx1(false);

  const [referusername, setReferusername] = useState("");

  const [referdata, setReferdata] = useState();

  const addrefferral = () => {
    if (!referusername || referusername === referdata?.referdByUser) {
      handleClosex1();
      return 0;
    }
    if (!referdata) {
      axios
        .post(
          `${API_HOST}/referal/addRefral`,
          { referalName: referusername },
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then(() => {
          axios
            .get(`${API_HOST}/referal/viewByUser`, {
              headers: {
                Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem("token")),
              },
            })
            .then((res) => {
              setReferdata(res?.data?.success?.data?.[0]);
              setReferusername(res?.data?.success?.data[0]?.referdByUser);
              handleClosex1();
            })
            .catch((err) => {
              console.log(err);
            });
          handleClosex1();
        });
    } else {
      axios
        .post(
          `${API_HOST}/referal/editReferal`,
          { referalName: referusername },
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then(() => {
          axios
            .get(`${API_HOST}/referal/viewByUser`, {
              headers: {
                Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem("token")),
              },
            })
            .then((res) => {
              setReferdata(res?.data?.success?.data?.[0]);
              setReferusername(res?.data?.success?.data[0]?.referdByUser);
              handleClosex1();
            })
            .catch((err) => {
              console.log(err);
            });
          handleClosex1();
        });
    }
  };

  const removerefferral = () => {
    axios
      .post(
        `${API_HOST}/referal/removeReferal`,
        {},
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then(() => {
        axios
          .get(`${API_HOST}/referal/viewByUser`, {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("token")),
            },
          })
          .then((res) => {
            setReferdata(res?.data?.success?.data?.[0]);
            setReferusername(res?.data?.success?.data[0]?.referdByUser);
            handleClosex1();
          })
          .catch((err) => {
            console.log(err);
          });
        handleClosex1();
      });
  };

  useEffect(() => {
    axios
      .get(`${API_HOST}/referal/viewByUser`, {
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        setReferdata(res?.data?.success?.data?.[0]);
        setReferusername(res?.data?.success?.data[0]?.referdByUser);
        handleClosex1();
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="Profilebanner-container">
      <div className="coverPiccontainer">
        <div className="coverimgqgeprofile">
          <img src={user?.coverPic ? user?.coverPic : img} alt="" />
        </div>
        <div className="covereditimgqgeprofile">
          <label htmlFor="coverpic">
            <img
              src={imgedit}
              alt=""
              style={{
                fontSize: "1.82vw",
                cursor: "pointer",
                position: "relative",
                width: width > 700 ? "3vw" : "8vw",
                border: "1px solid #0052cc",
                background: "white",
                borderRadius: "50%",
                objectFit: "contain",
              }}
            />
            <input
              type="file"
              name=""
              id="coverpic"
              hidden
              onChange={handleImageUpload}
            />
          </label>
        </div>
      </div>
      {selectedFile ? (
        <>
          {width > 700 ? (
            <div className="dvpopupsetcimg">
              <MyVerticallyCenteredModalx
                show={modalShowx}
                onHide={() => setSelectedFile(null)}
                selectedFilex={selectedFile}
                setSelectedFile={setSelectedFile}
                className="dvpopupsetcimg"
              />
            </div>
          ) : (
            <div className="dvpopupsetcimg">
              <MyVerticallyCenteredModalxm
                show={modalShowx}
                onHide={() => setSelectedFile(null)}
                selectedFilex={selectedFile}
                setSelectedFile={setSelectedFile}
                className="dvpopupsetcimg"
              />
            </div>
          )}
        </>
      ) : (
        ""
      )}
      <div className="profilePiccontainer">
        <div className="image-profilepart">
          <img src={user?.media ? user?.media : img4} alt="" />
          <div className="profileeditimgqgeprofile">
            <label htmlFor="profilepic">
              <img
                src={imgedit}
                alt=""
                style={{
                  fontSize: "1.12vw",
                  cursor: "pointer",
                  width: width > 700 ? "3vw" : "8vw",
                  height: width > 700 ? "3vw" : "8vw",
                  objectFit: "contain",
                  background: "white",
                  borderRadius: "50%",
                  border: "1px solid #0052cc",
                  position: "relative",
                  left: width > 700 ? "10vw" : "18vw",
                  bottom: width > 700 ? "6.5vw" : "10vw",
                }}
              />
              <input
                type="file"
                name=""
                id="profilepic"
                hidden
                onChange={handleImageUpload1}
              />
            </label>
          </div>
        </div>
        {selectedFile1 ? (
          <>
            {width > 700 ? (
              <div className="dvpopupsetcimg">
                <MyVerticallyCenteredModal
                  show={modalShow}
                  onHide={() => setSelectedFile1(null)}
                  selectedFile={selectedFile1}
                  setSelectedFile={setSelectedFile1}
                  className="dvpopupsetcimg"
                />
              </div>
            ) : (
              <div className="dvpopupsetcimg">
                <MyVerticallyCenteredModalm
                  show={modalShow}
                  onHide={() => setSelectedFile1(null)}
                  selectedFile={selectedFile1}
                  setSelectedFile={setSelectedFile1}
                  className="dvpopupsetcimg"
                />
              </div>
            )}
          </>
        ) : (
          ""
        )}
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
          className="profilebannernameandpost"
        >
          <div>
            <div className="profilebannernameandpostname">
              {user?.fullName}
              <img
                src={imgedit}
                alt=""
                onClick={handleOpen3}
                style={{
                  fontSize: "0.5vw",
                  cursor: "pointer",
                  position: "relative",
                  width: width > 700 ? "3vw" : "8vw",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "0.5vw",
              }}
              className="profilebannernameandpostemail"
            >
              <span>
                <img src={img2} alt="" />
              </span>
              {user?.userName}
              <span>
                <img
                  onClick={handleOpenx}
                  style={{
                    width: width > 700 ? "1.5vw" : "4vw",
                    height: width > 700 ? "1.5vw" : "4vw",
                    marginLeft: "0.751vw",
                    cursor: "pointer",
                  }}
                  src={img3}
                  alt=""
                />
              </span>
            </div>
          </div>
          <div>
            <button
              onClick={() => {
                handleOpenx1();
              }}
              style={{
                margin: width > 700 ? "1.2vw 0vw" : "4vw 0vw",
                width: "fit-content",
                paddingLeft: "1vw",
                marginRight: "1vw",
                paddingRight: "1vw",
              }}
              className={referdata ? "hb-buttonx" : "hb-button"}
            >
              {referdata ? "Change Referral" : "Add Referral"}
            </button>
            <PDFDownloadLink
              document={
                <MyDoc
                  user={user}
                  Refrencesall={Refrencesall}
                  portfolioall={portfolioall}
                  workexp={workexp}
                  education={education}
                />
              }
              fileName={`${user?.fullName}.pdf`}
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  ""
                ) : (
                  <button
                    style={{
                      margin: width > 700 ? "1.2vw 0vw" : "4vw 0vw",
                      marginRight: "1vw",
                      width: "fit-content",
                      paddingLeft: "1vw",
                      paddingRight: "1vw",
                    }}
                    className={user?.openForWork ? "hb-buttonx" : "hb-button"}
                  >
                    Resume
                  </button>
                )
              }
            </PDFDownloadLink>

            <button
              onClick={
                user?.contactNo !== (undefined || null)
                  ? handleopentowork
                  : toggleDrawer1("right", true)
              }
              style={{
                margin: width > 700 ? "1.2vw 0vw" : "4vw 0vw",
                width: "fit-content",
                paddingLeft: "1vw",
                paddingRight: "1vw",
              }}
              className={user?.openForWork ? "hb-buttonx" : "hb-button"}
            >
              {user?.openForWork ? "Close to Work" : "Open to Work"}
            </button>
            <Drawer
              anchor={"right"}
              open={state1["right"]}
              onClose={toggleDrawer1("right", false)}
            >
              {list1("right")}
            </Drawer>
          </div>

          <Modal1
            open={open3}
            onClose={handleClose3}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={width > 700 ? style : style1}>
              <div className="profiletitleandmenunav">
                <div className="profiledetailstitle">Edit Your Name Here</div>
                <div className="profiledetailnavmanu">
                  <div>
                    <CloseIcon
                      onClick={handleClose3}
                      style={{
                        fontSize: width > 700 ? "1.5vw" : "4vw",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
              </div>
              <hr style={{ color: "#000000" }} />
              <div
                style={{ left: "0vw", width: "100%" }}
                className="loginfield"
              >
                <div
                  style={{
                    left: "0vw",
                    width: "99%",
                    margin: "0vw 0.5vw 1vw 0vw",
                  }}
                  className="loginfield"
                >
                  <TextField
                    id="outlined-basic"
                    label="Fullname"
                    value={Name}
                    variant="outlined"
                    style={{ width: "100%" }}
                    InputLabelProps={{
                      style: {
                        fontSize: width > 700 ? "1vw" : "3vw",
                        fontFamily: "Poppins",
                        fontStyle: "500",
                        fontWeight: "500",
                        color: "black",
                      },
                    }}
                    inputProps={{ className: classes.input }}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div
                style={{ marginTop: "0.31vw" }}
                className="handlemoreaboutskill"
              >
                <div
                  style={{
                    background: "white",
                    color: "black",
                    cursor: "pointer",
                  }}
                  className="handlecirclieaboutsave"
                  onClick={handleClose3}
                >
                  Cancel
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleProfileName();
                  }}
                  className="handlecirclieaboutsave"
                >
                  SAVE
                </div>
              </div>
            </Box>
          </Modal1>

          <Modal1
            open={openx1}
            onClose={handleClosex1}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={width > 700 ? style : style1}>
              <div className="profiletitleandmenunav">
                <div className="profiledetailstitle">
                  Enter Referral username
                </div>
                <div className="profiledetailnavmanu">
                  <div>
                    <CloseIcon
                      onClick={handleClosex1}
                      style={{
                        fontSize: width > 700 ? "1.5vw" : "4vw",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
              </div>
              <hr style={{ color: "#000000" }} />
              <div
                style={{ left: "0vw", width: "100%" }}
                className="loginfield"
              >
                <div
                  style={{
                    left: "0vw",
                    width: "99%",
                    margin: "0vw 0.5vw 1vw 0vw",
                  }}
                  className="loginfield"
                >
                  <TextField
                    id="outlined-basic"
                    label="Username"
                    value={referusername}
                    variant="outlined"
                    style={{ width: "100%" }}
                    InputLabelProps={{
                      style: {
                        fontSize: width > 700 ? "1vw" : "3vw",
                        fontFamily: "Poppins",
                        fontStyle: "500",
                        fontWeight: "500",
                        color: "black",
                      },
                    }}
                    inputProps={{ className: classes.input }}
                    onChange={(e) => setReferusername(e.target.value)}
                  />
                </div>
              </div>
              <div
                style={{ marginTop: "0.31vw" }}
                className="handlemoreaboutskill"
              >
                <div
                  style={{
                    background: "white",
                    color: "black",
                    cursor: "pointer",
                  }}
                  className="handlecirclieaboutsave"
                  onClick={handleClosex1}
                >
                  Cancel
                </div>
                {referdata && (
                  <div
                    style={{
                      cursor: "pointer",
                      width: "fit-content",
                      padding: width > 700 ? "0.7vw 1vw" : "1.7vw",
                      background: "red",
                      marginRight: "1vw",
                    }}
                    onClick={() => {
                      removerefferral();
                    }}
                    className="handlecirclieaboutsave"
                  >
                    {"Remove"}
                  </div>
                )}
                <div
                  style={{
                    cursor: "pointer",
                    width: "fit-content",
                    padding: width > 700 ? "0.7vw 1vw" : "1.7vw",
                  }}
                  onClick={() => {
                    addrefferral();
                  }}
                  className="handlecirclieaboutsave"
                >
                  {referdata ? "Change" : "Add Referrel"}
                </div>
              </div>
            </Box>
          </Modal1>

          <Modal1
            open={openx}
            onClose={handleClosex}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={width > 700 ? style : style1}>
              <div className="profiletitleandmenunav">
                <div className="profiledetailstitle">Share</div>
                <div className="profiledetailnavmanu">
                  <div>
                    <CloseIcon
                      onClick={handleClosex}
                      style={{
                        fontSize: width > 700 ? "1.5vw" : "4vw",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
              </div>
              <hr style={{ color: "#000000" }} />
              <ShareSocial
                style={style5}
                url={`Hi Sir/Madam,\nI am a Certified Business Captain from www.Tool44.com \nWould you like to grow your business at our B2B Portal?\nUse my Code - “${
                  user.userName
                }” for a referral bonus worth Rs.250! Check it out:: ${"https://www.tool44.com"}.`}
                socialTypes={[
                  "facebook",
                  "whatsapp",
                  "twitter",
                  "linkedin",
                  "telegram",
                  "reddit",
                  "line",
                  "instapaper",
                  "hatena",
                  "email",
                  "livejournal",
                  "mailru",
                ]}
              />
            </Box>
          </Modal1>
        </div>
      </div>
    </div>
  );
}
