import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function ContactBox2({ data, width }) {
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      sx={{
        margin: width > 700 ? "1vw" : "1.5vw",
        border: "none",
        boxShadow: "1px 5px 10px #00000050",
        padding: "0.2vw 0.5vw",
        marginBottom: width > 700 ? "1vw" : "5vw",
        transition: "all 0.5s",
      }}
    >
      <CardActions onClick={handleExpandClick} disableSpacing>
        <div
          className="workhistryboxtitle"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <div>{data?.question}</div>
        </div>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div
          style={{
            fontSize: "0.95vw",
            marginLeft: "0.5vw",
            color: "black",
            marginBottom: "1vw",
          }}
          className="workhistryboxdata"
          dangerouslySetInnerHTML={{ __html: data?.answer }}
        ></div>
      </Collapse>
    </Card>
  );
}
