import React from "react";

import { Link } from "react-router-dom";

import { useNavigate } from "react-router";
import StarIcon from "@mui/icons-material/Star";
import img46 from "../../../assets/My profile – 28/Landing page – 19.png";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

export default function ProfileCatelogdetails({
  width,
  catalogue,
  setRecall,
  recall,
}) {
  const navigate = useNavigate();

  const myStr = catalogue?.productName;
  const contentNameRoute = myStr
    .replaceAll(" ", "-")
    .replaceAll("/", "_")
    .replaceAll("?", "_");

  return (
    <div>
      <div
        style={{ margin: width > 700 ? "1.2vw 0.5vw" : "2.5vw" }}
        className="pcatelogbox"
      >
        <div
          style={{
            background: `url('${
              catalogue?.files[0]?.file
                ? catalogue?.files[0]?.file?.trimStart()?.replaceAll(" ", "+")
                : img46
            }') center center / contain no-repeat`,
          }}
          className="pcatelogimg"
        >
          <div className="pcatelogimg2">
            <div className="porfolioprofilemenu">
              <RemoveRedEyeIcon
                onClick={() =>
                  navigate(
                    `/dashboard/my-product/${contentNameRoute}/${catalogue?.productId}`
                  )
                }
                style={{
                  margin: "0 0.2vw",
                  width: width > 700 ? "3vw " : "9vw",
                  height: width > 700 ? "3vw " : "9vw",
                  borderRadius: "50%",
                  cursor: "pointer",
                  objectFit: "cover",
                  backgroundColor: "white",
                  padding: "3px",
                }}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            height: width > 700 ? "4.2vw" : "11vw",
            overflow: "visible",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <div
            style={{
              margin: width > 700 ? "0.5vw" : "1vw",

              width: "fit-content",
            }}
            className="pcatelogperson"
          >
            <div
              style={{ width: "fit-content", maxWidth: "50vw" }}
              className="pcatelogpersonname"
            >
              <div className="pcatelogpersonname1">
                <span
                  style={{
                    display: "flex",
                    marginTop: "0.2vw",
                  }}
                >
                  {catalogue?.productName?.slice(0, 45)}
                </span>
              </div>
            </div>
          </div>
          <div style={{ width: "fit-content" }}>
            <div
              style={{
                margin: width > 700 ? "0.4vw 0.5vw" : "1vw 2vw",
                marginLeft: "0vw",
                maxWidth: width > 700 ? "10vw" : "30vw",
                marginBottom: "0.2vw",
                width: "fit-content",
                padding: width > 700 ? "0.3vw 0.6vw" : "1vw 1.5vw",
              }}
              className="tagblue"
            >
              {catalogue?.category?.category}
            </div>
            <div>
              <div
                style={{
                  fontSize: width > 700 ? "0.85vw" : "2.5vw",
                  display: "flex",
                  justifyItems: "right",
                  float: "right",
                  marginRight: "0.5vw",
                  width: "max-content",
                }}
              >
                <span style={{ color: "#0052cc" }}>{catalogue?.type}</span>
                <span
                  style={{
                    display: "flex",
                    fontWeight: "500",
                    textAlign: "right",
                  }}
                >
                  <StarIcon
                    style={{
                      width: width > 700 ? "1.1vw" : "3.1vw",
                      height: width > 700 ? "1.1vw" : "3.1vw",
                      color: "#0052cc",
                    }}
                    alt=""
                  />
                  {catalogue?.rating?.$numberDecimal?.slice(0, 1)} ({" "}
                  {catalogue?.completedOrder} )
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            color: "black",
            margin: width > 700 ? "0 0.5vw" : "0 1vw",
            height: width > 700 ? "5.5vw" : "13.5vw",
            lineHeight: width > 700 ? "1.35vw" : "3.4vw",
            width: "90%",
            fontSize: width > 700 ? "1.05vw" : "2.9vw",
            overflow: "hidden",
          }}
          className="pcatelog-title"
        >
          {catalogue?.description?.slice(0, 170)}
        </div>
        <hr style={{ padding: "0vw", margin: "0.3vw" }} />
        <div
          style={{ margin: width > 700 ? "0.51vw 0.2vw" : "1.5vw 0.5vw" }}
          className="pcatelogdate"
        >
          <div>
            <span>
              Price{" "}
              <span
                className="tagblue"
                style={{
                  margin: "0.0vw 0.2vw",

                  width: "fit-content",
                  padding: "0.2vw 0.6vw",
                  color: "white",
                }}
              >
                Rs. {catalogue?.costPrice ? catalogue?.costPrice : "-"}
              </span>{" "}
            </span>
          </div>

          <div>
            <span>
              <Link
                to={`/dashboard/addproductbytool44/${catalogue?.productId}`}
              >
                {" "}
                <span
                  className="tagblue"
                  style={{
                    margin: "0.0vw 0.2vw",

                    width: "fit-content",
                    padding: "0.2vw 0.6vw",
                    color: "white",
                  }}
                >
                  Add This {catalogue?.type}
                </span>{" "}
              </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
