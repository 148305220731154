import React, { useEffect, useState } from "react";
import ProfilePortfoliodetails from "../DashbaordSidebarComponents/Portfilio/ProfilePortfoliodetails";
import img1 from "../../assets/My profile – 28/Component 70 – 6.svg";
import axios from "axios";
import API_HOST from "../../env";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

export default function Profileportfolio({ width, setPortfolioall }) {
  const [allprotfolio, setAllprotfoilio] = useState([]);
  const { user, loggedInStatus } = useSelector((state) => state.user);
  const [totalcount, setTotalcount] = useState(false);
  const [page, setPage] = useState(1);
  const [recall, setRecall] = useState(true);

  useEffect(() => {
    if (user?.userId) {
      axios
        .get(
          `${API_HOST}/portfolio/viewProjectPortfolio?pageSize=2&pageNumber=1&userId=${user?.userId}`
        )
        .then((res) => {
          setAllprotfoilio([...res?.data?.success?.data]);
          setPortfolioall([...res?.data?.success?.data]);
          setTotalcount(res?.data?.success?.data?.length > 1 ? true : false);
        });
    }
  }, [user, recall]);

  useEffect(() => {
    if (page > 1) {
      axios
        .get(
          `${API_HOST}/portfolio/viewProjectPortfolio?pageSize=2&pageNumber=${page}&userId=${user?.userId}`
        )
        .then((res) => {
          setAllprotfoilio([...allprotfolio, ...res?.data?.success?.data]);
          setPortfolioall([...allprotfolio, ...res?.data?.success?.data]);
          setTotalcount(res?.data?.success?.data?.length > 1 ? true : false);
        });
    }
  }, [page]);

  const navigate = useNavigate();

  return (
    <div style={{ height: "fit-content" }} className="profilebadgecontainer">
      <div
        style={{ padding: "2vw", margin: "0 0vw" }}
        className="profiletitleandmenunav"
      >
        <div className="profiledetailstitle">Portfolio</div>
        <div className="profiledetailnavmanu">
          <img
            onClick={() => navigate("/dashboard/addportfolio")}
            src={img1}
            style={{ width: width > 700 ? "2.5vw" : "6vw" }}
            alt=""
          />
        </div>
      </div>

      <div
        style={{ margin: width > 700 ? "0vw" : "1.25vw" }}
        className="pcatalofboxes"
      >
        {allprotfolio.length > 0 &&
          allprotfolio?.map((portfolio, index) => {
            return (
              <ProfilePortfoliodetails
                portfolio={portfolio}
                width={width}
                key={index}
                setAllprotfoilio={setAllprotfoilio}
                recall={recall}
                setRecall={setRecall}
                setPortfolioall={setPortfolioall}
              />
            );
          })}
      </div>
      {totalcount && (
        <div onClick={() => setPage(page + 1)} className="ViewMorebutton">
          View More
        </div>
      )}
    </div>
  );
}
