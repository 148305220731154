import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import "./Homecategories.css";
import API_HOST from "../../../env";

import Homeccaresel from "./Homeccaresel";

import Homeexplorelist from "./Homeexplorelist";

export default function Homecategories({ width }) {
  const [categoryid, setCategoryid] = useState();
  const [viewAllCategories, setViewAllCategories] = useState([]);

  const [poplularCatelogue, setPoplularCatelogue] = useState([]);

  useEffect(() => {
    axios
      .get(
        `${API_HOST}/theCategory/viewCategory?pageSize=6&pageNumber=1&popularCategory=true`
      )
      .then((res) => {
        setViewAllCategories(res?.data?.success?.data);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    axios
      .get(
        `${API_HOST}/webmodel/viewDirectbuy?pageSize=200&pageNumber=1${`&search=`}&costPrice=&location=${
          categoryid ? `&category=${categoryid}` : ""
        }  `
      )
      .then((res) => {
        setPoplularCatelogue(res?.data?.success?.data);
      })
      .catch((err) => {});
  }, [categoryid]);

  return (
    <div hidden className="home-categories-container">
      <div className="home-catetitle">Explore Marketplace</div>
      <Homeexplorelist
        viewAllCategories={viewAllCategories}
        setCategoryid={setCategoryid}
        categoryid={categoryid}
        width={width}
      />
      <div style={{ width: "100vw", overflow: "hidden" }}>
        <Homeccaresel poplularCatelogue={poplularCatelogue} width={width} />
      </div>
    </div>
  );
}
