import React from 'react';
import { useNavigate } from 'react-router';
import img2 from '../../../assets/Success stories Definition/checkmark (1).svg';
import New_quickest_releaser from '../../../assets/Badges/badge crown 2 (1).svg';
import New_quickest_assigner from '../../../assets/Badges/Badge _ 2.2.png';
import New_quickest_performer from '../../../assets/Badges/Badge _ 1.2.png';
import './FindTalent.css';
import PaymentIcon from '@mui/icons-material/Payment';
import DoneIcon from '@mui/icons-material/Done';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import VerifiedIcon from '@mui/icons-material/Verified';

import img1 from '../../../assets/About/Iconly-Light-Location.svg';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';

export default function ProfileCatelogdetails({ width, data }) {
  const navigate = useNavigate();

  console.log('data', data);

  return (
    <div
      style={{
        width: width > 700 ? '25vw' : '',
        height: '100%',
        marginTop: width > 700 ? '1vw' : '2.5vw',
        marginBottom: width > 700 ? '1vw' : '1.8vw',
        marginLeft: width > 700 ? '1vw' : '1.5vw',
        marginRight: width > 700 ? '1vw' : '1.5vw',
        padding: '1rem',
      }}
      className='Menuwebsite'
    >
      <div style={{ textAlign: 'center' }}>
        <img
          style={{
            width: width > 700 ? '10vw' : '30vw',
            height: width > 700 ? '10vw' : '30vw',
            margin: width > 700 ? 'auto 3vw' : '0.5vw 6vw',
            borderRadius: '50%',
            filter:
              data?.product_id?.type !== 'Service' &&
              data?.quantity < 1 &&
              'grayscale(1)',
            cursor: 'pointer',
          }}
          src={data?.media}
          alt=''
        />
        <div style={{ height: '0.3vw' }}>
          {data?.verifiedByAdmin && (
            <img
              style={{
                width: width > 700 ? '1.75vw' : '5vw',
                height: width > 700 ? '1.75vw' : '5vw',
                position: 'relative',
                left: width > 700 ? '4vw' : '10vw',
                bottom: width > 700 ? '2.1vw' : '8.5vw',
              }}
              src={img2}
              alt=''
            />
          )}
        </div>
        <div
          onClick={() => {}}
          style={{
            textAlign: 'center',
            marginTop: '0.3vw',
            height: width > 700 ? '2vw' : '5vw',
          }}
          className='namemenuweb'
        >
          {data?.fullName?.slice(0, 20)}
        </div>
        <div
          onClick={() => {}}
          style={{
            textAlign: 'center',
            paddingTop: '0vw',
            fontSize: width > 700 ? '0.91vw' : '2.7vw',
            color: '#0052cc',
            height: width > 700 ? '1.2vw' : '5vw',
            fontWeight: '700',
          }}
          className='namemenuweb ellipsis'
        >
          {data?.title}
        </div>

        <div
          style={{
            height: width > 700 ? '3.5vw' : '10vw',
            paddingTop: width > 700 ? '1vw' : '2vw',
            fontWeight: '400',
            textAlign: 'left',
            fontSize: width > 700 ? '0.9vw' : '2.7vw',
            lineHeight: width > 700 ? '1.1vw' : '3.5vw',
            color: '#000000bb',
            marginBottom: '1vw',
          }}
          className='pricemenuweb multiline-ellipsis'
          dangerouslySetInnerHTML={{
            __html: data?.fullDescription ? data?.fullDescription : '',
          }}
        />

        <div
          style={{
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            margin: '1vw 0vw',
            marginTop: width > 700 ? '0.35vw' : '1vw',
            alignItems: 'center',
            height: width > 700 ? '2.1vw' : '12vw',
          }}
          className='profileuserdfirst '
        >
          <div
            style={{
              width: '33.33%',
              height: '2vw',
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            className='custom_verified_tooltip verified_hover_12'
          >
            <img
              src={New_quickest_assigner}
              alt=''
              style={{
                width: '65px',
                height: '65px',
                filter: data?.verifiedByAdmin ? '' : 'grayscale(1)',
              }}
            />
            <div
              className='tooltip_verified'
              style={{ color: '#0052cc' }}
            >
              <span>
                <VerifiedUserIcon sx={{ fontSize: '16px' }} />
              </span>
              <span style={{ fontSize: '12px' }}>Verified User</span>
              <span>
                <DoneIcon sx={{ fontSize: '14px' }} />{' '}
              </span>
            </div>
          </div>

          <div
            style={{
              width: '33.33%',
              height: '2vw',
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            className='custom_certified_tooltip certified_hover_12'
          >
            <img
              src={New_quickest_performer}
              alt=''
              style={{
                width: '65px',
                height: '65px',
                filter: data?.certifiedByAdmin ? '' : 'grayscale(1)',
              }}
            />
            <div
              className='tooltip_certified'
              style={{ color: '#0052cc' }}
            >
              <span>
                <VerifiedIcon sx={{ fontSize: '16px' }} />
              </span>
              <span style={{ fontSize: '12px' }}>Certified User</span>
              <span>
                <DoneIcon sx={{ fontSize: '14px' }} />{' '}
              </span>
            </div>
          </div>

          <div
            style={{
              width: '33.33%',
              height: '2vw',
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            className='custom_tooltip hover_12'
          >
            <img
              src={New_quickest_releaser}
              alt=''
              style={{
                width: '65px',
                height: '65px',
                filter: data?.eliteUser ? '' : 'grayscale(1)',
              }}
            />
            <div
              className='tooltip_12'
              style={{ color: '#0052cc' }}
            >
              <span>
                <PaymentIcon sx={{ fontSize: '16px' }} />
              </span>
              <span style={{ fontSize: '12px' }}>Payment Verified</span>
              <span>
                <DoneIcon sx={{ fontSize: '14px' }} />
              </span>
            </div>
          </div>
        </div>

        <hr style={{ padding: '0vw', margin: '0.3vw' }}></hr>
        <div className='d-flex align-items-center justify-content-between mt-2 w-100'>
          <div
            style={{
              height: width > 700 ? '1.6vw' : '4.5vw',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingTop: '0vw',
              paddingBottom: '0',
              width: '70%',
            }}
            className='pricemenuweb ellipsis'
          >
            {data?.address && (
              <div
                style={{ display: 'flex', alignItems: 'center', width: '100%' }}
              >
                <span>
                  <img
                    style={{
                      height: 'fit-content',
                      width: width > 700 ? '1.3vw' : '3.7vw',
                      margin: '0vw',
                      marginRight: '0.1.8vw',
                    }}
                    src={img1}
                    alt=''
                  />
                </span>
                <div className='ellipsis '>{data?.address}</div>
              </div>
            )}
          </div>
          <div
            onClick={() => {
              navigate(`/dashboard/profile/${data?.userName}`);
            }}
            className='visit_btn'
            style={{ whiteSpace: 'nowrap' }}
          >
            View Profile
          </div>
        </div>
      </div>
    </div>
  );
}
