import React, { useState } from "react";
import "./Category.css";
import img from "../../assets/experts/Iconly-Light-Search.svg";
import Box from "@mui/material/Box";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import axios from "axios";
import API_HOST from "../../env";
import { useLocation, useNavigate } from "react-router";

export default function CateGoryBanner({ width }) {
  const [openx, setOpenx] = React.useState(false);
  const [anchorElx, setAnchorElx] = React.useState(null);

  const canBeOpen = openx && Boolean(anchorElx);
  const id = canBeOpen ? "transition-popper" : undefined;

  const [allcategory, setAllcategory] = useState([0]);

  const handleSearchCategory = (e) => {
    axios
      .get(
        `${API_HOST}/theCategory/viewCategory?pageSize=30&pageNumber=1&category=${e.target.value}`
      )
      .then((res) => {
        setAllcategory(res?.data?.success?.data);

        setOpenx(true);
      });
  };

  const [setSelectedCategory, setSetSelectedCategory] = useState("");
  const [setSelectedCategoryid, setSetSelectedCategoryid] = useState("");
  
  const navigate = useNavigate();
  const location = useLocation();
  const url = new URLSearchParams(location.search);

  return (
    <div style={{ height: width > 700 ? "" : "75vw" }} className="baxkground1">
      <div
        style={{ height: width > 700 ? "" : "75vw" }}
        className="expertgbannercontainer1"
      >
        <div style={{ width: width > 700 ? "55%" : "85%", marginTop: "7vw" }}>
          <div style={{ color: "white" }} className="h-banner-accerlate">
            Find The Best
          </div>
          <div style={{ color: "white" }} className="h-banner-carrierland">
            The fastest way to get <br /> things done!
          </div>
          <div
            style={{
              color: "white",
              marginBottom: width > 700 ? "2vw" : "5vw",
            }}
            className="hbsmalltext"
          >
             Quickly, locate the ideal expert to start working on your project.
          </div>
          <div className="searchbuttonexpert">
            <img src={img} alt="" />
            <input
              style={{ width: "100%" }}
              type="text"
              value={setSelectedCategory}
              placeholder="Choose Category and search ...."
              onChange={(e) => {
                if (e.target.value !== "") {
                  handleSearchCategory(e);
                  setAnchorElx(e.currentTarget);
                } else {
                  setOpenx(false);
                }
                setSetSelectedCategory(e.target.value);
              }}
            />
            <button
              style={{ width: width > 700 ? "16vw" : "30vw" }}
              onClick={() => {
                url.set("category", setSelectedCategoryid);
                navigate(location.pathname + "?" + url.toString());
              }}
            >
              Search
            </button>
          </div>
          <Popper id={id} open={openx} anchorEl={anchorElx} transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Box
                  sx={{
                    width: width < 700 ? "96vw" : "49.3vw",
                    border: 1,
                    p: 1,
                    bgcolor: "background.paper",
                    position: "relative",
                    top: "0.81vw",
                    left: width < 700 ? "2vw" : "5.1vw",
                    borderRadius: "0.3vw",
                    maxHeight: width < 700 ? "50vw" : "16vw",
                    overflow: "scroll",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <div className="recentsearch">Recent search</div>
                    {allcategory.length > 0 &&
                      allcategory?.map((cate) => {
                        return (
                          <div
                            className="recentnamesearc"
                            onClick={() => {
                              setSetSelectedCategory(cate?.category);
                              setSetSelectedCategoryid(cate?._id);

                              setOpenx(false);
                            }}
                          >
                            {cate?.category}
                            <span>
                              <ArrowUpwardIcon
                                style={{
                                  fontSize: width < 700 ? "3.5vw" : "1.7vw",
                                  transform: "rotate(-45deg)",
                                }}
                              />
                            </span>
                          </div>
                        );
                      })}
                  </div>
                </Box>
              </Fade>
            )}
          </Popper>
        </div>

        {width > 700 && (
          <div
            style={{
              width: "40%",
              position: "relative",
              bottom: width > 700 ? "5vw" : "1vw",
            }}
          >
            <div
              style={{ padding: "1vw", margin: "5vw", marginLeft: "25vw" }}
              className="expertdetailsbox"
            >
              <div className="hradingcategorybox1">Categories</div>
              <div className="hradingcategorybox">4000+</div>
            </div>
            <div
              style={{ padding: "1vw", margin: "5vw" }}
              className="expertdetailsbox"
            >
              <div className="hradingcategorybox1">Sub categories</div>
              <div className="hradingcategorybox">10000+</div>
            </div>
            <div
              style={{ padding: "1vw", margin: "5vw", marginLeft: "25vw" }}
              className="expertdetailsbox"
            >
              <div className="hradingcategorybox1">Skills</div>
              <div className="hradingcategorybox">20000+</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
