import React from "react";
import img from "../../../assets/Success stories/zan-BKyvWG7D6QA-unsplash.png";
import imgxx from "../../../assets/Success stories Definition/checkmark (1).svg";

import { useNavigate } from "react-router";

export default function StoryCards({ item, width }) {
  const navigate = useNavigate();
  const myStr = item?.heading;
  const contentNameRoute = myStr
    ?.replaceAll(" ", "-")
    ?.replaceAll("/", "-")
    ?.replaceAll("?", "-");
    console.log('item',item);

    const extractFirstH1 = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const firstH1 = doc.querySelector('h1');
        if (firstH1) {
            const strongTag = firstH1.querySelector('strong');
            if (strongTag) {
                while (strongTag.firstChild) {
                    firstH1.insertBefore(strongTag.firstChild, strongTag);
                }
                firstH1.removeChild(strongTag);
            }
            return firstH1.innerHTML;
        }
        return '';
    }

  return (
    <div
      style={{ cursor: "pointer" ,margin: width>700? '1vw 0.7vw':"3vw 0.75vw" ,width: width>700? "25.5vw":"92vw" }}
      onClick={() => navigate(`/dashboard/stories/${contentNameRoute}/${item?.storyId }`)}
      className="boxoffilterbox"
    >
      <div className="imgboxfilteredblog w-100">
        <img src={item?.image ? item?.image : img} alt="" className="w-100" />
      </div>
      <div className="mt-3">
        <div className="d-flex justify-content-between mb-2">
          <div className="tagblogblue mt-2 ellipsis">
            <span className="tagblogblack ">
              {item?.heading}
            </span>
          </div>
           <div className="tagblue " style={{whiteSpace:"nowrap"}}> Business Stories </div>
        </div>
        
        <div className="toc mt-2">
          <div className=" html_content" style={{overflow:"hidden", height:"8vw"}} dangerouslySetInnerHTML = {{__html : item?.content} }/>
        </div>
        <hr style={{ padding: "0vw", margin: "0.3vw" }}></hr>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
              style={{
                fontSize: width > 700 ? "0.85vw" : "2.5vw",
                display: "flex",
                marginTop: "0.1vw",
                alignItems: "center",
                fontWeight:"600"
              }}
            >
              {item?.userName}
            </div>
        </div>
      </div>
    </div>
  );
}
