import React, { useEffect } from "react";
import { useState } from "react";

import imageCompression from "browser-image-compression";
import img from "../../../assets/Web 1280 – 14/Icon.svg";
import img1 from "../../../assets/Web 1280 – 14/Group 9831.svg";
import img21 from "../../../assets/My profile – 28/Component 85 – 16 (1).svg";
import axios from "axios";
import API_HOST from "../../../env";
import { makeStyles } from "@material-ui/core";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { TextField } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  input: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "0.91vw",
    color: "#263238",
    border: "yellow !important",
    // padding: "1vw",
  },
}));
export default function EditCompanydetails({
  width,
  showeditcompany,
  setShoweditcompany,
  setCreate,
  setRecall,
  recall,
}) {
  const classes = useStyles();
  const [companyname, setCompanyname] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [mobile, setMobile] = useState();
  const [email, setEmail] = useState("");
  const [arrayoffiles, setArrayoffiles] = useState();
  const [qrimage1, setQrimage1] = useState();
  const [qrimage, setQrimage] = useState();
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [gstno, setGstno] = useState("");
  const [invoicetag, setInvoicetag] = useState("");
  const [invoicestartyear, setInvoicestartyear] = useState("");
  const [invoicestartno, setInvoicestartno] = useState("");
  const [signature, setSignature] = useState();
  const [websitelink, setWebsitelink] = useState("");
  useEffect(() => {
    setAddress(showeditcompany.adress);
    setCompanyname(showeditcompany.companyName);
    setJobTitle(showeditcompany.companyTitle);
    setMobile(showeditcompany.mobile);
    setEmail(showeditcompany.emailId);
    setGstno(showeditcompany.gstNo);
    setInvoicestartno(showeditcompany.invoiceSeries);
    setInvoicestartyear(showeditcompany.invoiceTimePeriod);
    setInvoicetag(showeditcompany.invoicePrefix);
    setState(showeditcompany.state);
    setPincode(showeditcompany.pinCode);
    setPaymentlink(showeditcompany.paymentLink);
    setBankname(showeditcompany.bankName);
    setBranch(showeditcompany.branch);
    setaccountholder(showeditcompany.accountHolderName);
    setAccountno(showeditcompany.accNo);
    setIfsc(showeditcompany.ifscCode);
    setWebsitelink(showeditcompany.companyWebsiteLink)
    setCategogryid(showeditcompany.category);
  }, [showeditcompany]);

  const [error, setError] = useState("");

  const handleSumbitbank = () => {
    if (!state || !invoicetag || !invoicestartyear || !invoicestartno) {
      setError("Please fill all the field containing (*)");
      return;
    }

    const formdata = new FormData();

    formdata.append("companyId", showeditcompany?.companyId);
    formdata.append("companyName", companyname);
    formdata.append("companyTitle", jobTitle);
    formdata.append("gstNo", gstno);
    formdata.append("mobile", mobile);
    formdata.append("adress", address);
    formdata.append("state", state);
    formdata.append("pinCode", pincode);
    formdata.append("companyWebsiteLink", websitelink);
    formdata.append("invoicePrefix", invoicetag);
    formdata.append("invoiceTimePeriod", invoicestartyear);
    formdata.append("emailId", email);
    formdata.append("invoiceSeries", invoicestartno);
    formdata.append("bankName", bankname);
    formdata.append("accNo", accountno);
    formdata.append("ifscCode", ifsc);
    formdata.append("branch", branch);
    formdata.append("accountHolderName", accountholder);
    formdata.append("paymentLink", paymentlink);
    formdata.append("logo", arrayoffiles);
    formdata.append("qr", qrimage);
    formdata.append("payment", qrimage1);
    formdata.append("stamp", signature);
    formdata.append("category", categogryid);

    axios
      .post(`${API_HOST}/companyDetails/editExisted`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
         setShoweditcompany(false)
         setRecall(!recall)
      });
  };

  async function handleImageUpload(event) {
    const imageFile = event.target.files[0];
    console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log(
        "compressedFile instanceof Blob",
        compressedFile instanceof Blob
      ); // true
      console.log(
        `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
      ); // smaller than maxSizeMB

      //   await uploadToServer(compressedFile); // write your own logic
      await setArrayoffiles(
        new File([compressedFile], `${compressedFile?.name}`)
      );
    } catch (error) {
      console.log(error);
    }
  }

  async function handleImageqr(event) {
    const imageFile = event.target.files[0];
    console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log(
        "compressedFile instanceof Blob",
        compressedFile instanceof Blob
      ); // true
      console.log(
        `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
      ); // smaller than maxSizeMB

      //   await uploadToServer(compressedFile); // write your own logic
      await setQrimage(new File([compressedFile], `${compressedFile?.name}`));
    } catch (error) {
      console.log(error);
    }
  }

  async function handleImageqr1(event) {
    const imageFile = event.target.files[0];
    console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log(
        "compressedFile instanceof Blob",
        compressedFile instanceof Blob
      ); // true
      console.log(
        `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
      ); // smaller than maxSizeMB

      //   await uploadToServer(compressedFile); // write your own logic
      await setQrimage1(new File([compressedFile], `${compressedFile?.name}`));
    } catch (error) {
      console.log(error);
    }
  }

  async function handleImagesign(event) {
    const imageFile = event.target.files[0];
    console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log(
        "compressedFile instanceof Blob",
        compressedFile instanceof Blob
      ); // true
      console.log(
        `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
      ); // smaller than maxSizeMB

      //   await uploadToServer(compressedFile); // write your own logic
      await setSignature(new File([compressedFile], `${compressedFile?.name}`));
    } catch (error) {
      console.log(error);
    }
  }

  const [anchorElx2, setAnchorElx2] = useState(null);
  const openx2 = Boolean(anchorElx2);
  const idx2 = openx2 ? "simple-popover" : undefined;

  const handleClickx2 = (event) => {
    setAnchorElx2(event.currentTarget);
  };

  const handleClosex2 = () => {
    setAnchorElx2(null);
  };

  const [categogryid, setCategogryid] = useState("");
  const [searchCategorysearch, setSearchCategorysearch] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategoryLabel, setSelectedCategoryLabel] = useState('');

  const [anchorElx3, setAnchorElx3] = React.useState(null);

  const handleClickx3 = (event) => {
    setAnchorElx3(event.currentTarget);
  };
  const handleClosex3 = () => {
    setAnchorElx3(null);
  };

  const openx3 = Boolean(anchorElx3);
  const idx3 = openx3 ? "simple-popover" : undefined;

  const [arrayoflongdegree, setArrayoflongdegree] = useState([
    "JAMMU AND KASHMIR (1)",
    "HIMACHAL PRADESH (2)",
    "PUNJAB (3)",
    "CHANDIGARH	(4)",
    "UTTARAKHAND (5)",
    "HARYANA (6)",
    "DELHI (7)",
    "RAJASTHAN (8)",
    "UTTAR PRADESH (9)",
    "BIHAR (10)",
    "SIKKIM	(11)",
    "ARUNACHAL PRADESH (12)",
    "NAGALAND (13)",
    "MANIPUR (14)",
    "MIZORAM (15)",
    "TRIPURA (16)",
    "MEGHALAYA (17)",
    "ASSAM	(18)",
    "WEST BENGAL (19)",
    "JHARKHAND	(20)",
    "ODISHA	(21)",
    "CHATTISGARH (22)",
    "MADHYA PRADESH	(23)",
    "GUJARAT (24)",
    "DADRA AND NAGAR HAVELI AND DAMAN AND DIU (NEWLY MERGED UT) (26*)",
    "MAHARASHTRA (27)",
    "ANDHRA PRADESH(BEFORE DIVISION) (28)",
    "KARNATAKA (29)",
    "GOA (30)",
    "LAKSHADWEEP (31)",
    "KERALA	(32)",
    "TAMIL NADU	(33)",
    "PUDUCHERRY	(34)",
    "ANDAMAN AND NICOBAR ISLANDS (35)",
    "TELANGANA (36)",
    "ANDHRA PRADESH (NEWLY ADDED) (37)",
    "LADAKH (NEWLY ADDED) (38)",
  ]);
  const [anchorElx1, setAnchorElx1] = useState(null);
  const openx1 = Boolean(anchorElx1);
  const idx1 = openx1 ? "simple-popover" : undefined;

  const handleClickx1 = (event) => {
    setAnchorElx1(event.currentTarget);
  };

  const handleClosex1 = () => {
    setAnchorElx1(null);
  };
  const [arrayoflongdegree1, setArrayoflongdegree1] = useState([
    "22-23",
    "23-24",
    "24-25",
    "25-26",
    "26-27",
    "27-28",
    "28-29",
    "29-30",
  ]);

  const [bankname, setBankname] = useState("");
  const [accountno, setAccountno] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [branch, setBranch] = useState("");
  const [accountholder, setaccountholder] = useState("");

  const [paymentlink, setPaymentlink] = useState("");

  useEffect(() => {
    const selectedCategory = categoryList.find(category => category?._id === categogryid);
    if (selectedCategory) {
      setSelectedCategoryLabel(selectedCategory?.category);
    } else {
      setSelectedCategoryLabel('');
    }
  }, [categogryid, categoryList]);

  useEffect(() => {
    axios
      .get(
        `${API_HOST}/theCategory/viewCategory?pageSize=50&pageNumber=1&category=${searchCategorysearch}`
      )
      .then((res) => {
        setCategoryList(res?.data?.success?.data);
      });
  }, [searchCategorysearch]);

  return (
    <div
      style={{
        width: width > 700 ? "60vw" : "90vw",
        margin: "auto",
        height: "fit-content",
        marginTop: "2vw",
      }}
      className="home-postjob-container"
    >
      <div
        style={{
          overflowX: "hidden",
          background: "none",
          paddingLeft: "4vw",
          width: width > 700 ? "60vw" : "90vw",
          paddingTop: "2vw",
        }}
        className="homepostjob-right"
      >
        <div className="jobpostedformheading">Edit Company details</div>

        <div>
          <div className="jobpodtedfieldtitile">company Name</div>
          <div className="jobpostfieldinputbox">
            <input
              type="text"
              value={companyname}
              onChange={(e) => {
                setCompanyname(e.target.value);
                setInvoicetag(e.target.value?.slice(0, 3).toUpperCase());
              }}
            />
          </div>

          <div className="jobpodtedfieldtitile">Company Tagline</div>
          <div className="jobpostfieldinputbox">
            <input
              type="text"
              value={jobTitle}
              onChange={(e) => {
                setJobTitle(e.target.value);
              }}
            />
          </div>


          <div className="jobpodtedfieldtitile">Category </div>
          <div style={{ width: "100%" }}>
            <div
              style={{
                left: "0vw",
                width: width > 700 ? "94%" : "99%",
                marginLeft: "0%",
              }}
              className="loginfield"
              onClick={handleClickx3}
            >
              <TextField
                id="outlined-basic"
                label="Select Category *"
                variant="outlined"
                disabled
                value={selectedCategoryLabel}
                style={{ width: "100%" }}
                InputLabelProps={{
                  style: {
                    fontSize: width > 700 ? "1vw" : "3vw",
                    fontFamily: "Poppins",
                    fontStyle: "500",
                    fontWeight: "500",
                    color: "black",
                  },
                }}
                inputProps={{ className: classes.input }}
                onChange={(e) => {
                  console.log(e.target.value);
                }}
              />
              {width > 700 && (
                <span style={{ width: "0.1vw" }}>
                  <KeyboardArrowDownOutlined
                    style={{
                      fontSize: "1.5vw",
                      position: "relative",
                      right: "2vw",
                      top: "1vw",
                    }}
                  />
                </span>
              )}
            </div>

            <Popover
              id={idx3}
              open={openx3}
              anchorEl={anchorElx3}
              onClose={handleClosex3}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div
                style={{
                  maxHeight: width > 700 ? "18vw" : "40vw",
                  overflow: "scroll",
                  width: width > 700 ? "33vw" : "90vw",
                }}
              >
                <Typography
                  sx={{
                    p: 1,
                    pl: 1,
                    ml: 1,
                    pr: 0,
                    width: width > 700 ? "31vw" : "87vw",
                    position: "fixed",
                    background: "white",
                    zIndex: "10",
                  }}
                >
                  <input
                    placeholder="search here .."
                    onChange={(e) => {
                      setSearchCategorysearch(e.target.value);
                    }}
                    style={{
                      width: "97%",
                      border: "1.5px solid #00000050",
                      outline: "none",
                      height: "2.5",
                      borderRadius: "0.21vw",
                    }}
                  />
                </Typography>
                <Typography
                  sx={{
                    p: 2.5,
                    pl: 1,
                    ml: 1,
                    width: "100%",
                    cursor: "pointer",
                  }}
                ></Typography>

                {categoryList?.length > 0 &&
                  categoryList.map((data, index) => {
                    return (
                      <Typography
                        sx={{
                          p: 0.51,
                          pl: 1,
                          ml: 1,
                          width: "100%",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setCategogryid(data?._id);
                          handleClosex3();
                        }}
                      >
                        {data?.category}
                      </Typography>
                    );
                  })}
              </div>
            </Popover>
          </div>

          <div className="jobpodtedfieldtitile">Website Link</div>
          <div className="jobpostfieldinputbox">
            <input
              type="text"
              value={websitelink}
              onChange={(e) => {
                setWebsitelink(e.target.value);
              }}
            />
          </div>

          <div className="jobpodtedfieldtitile">Mobile </div>
          <div className="jobpostfieldinputbox">
            <input
              type="number"
              value={mobile}
              onChange={(e) => {
                setMobile(e.target.value);
              }}
            />
          </div>

          <div className="jobpodtedfieldtitile">Email</div>
          <div className="jobpostfieldinputbox">
            <input
              type="text"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>

          <div className="jobpodtedfieldtitile">Address</div>
          <div className="jobpostfieldinputbox">
            <input
              type="text"
              value={address}
              onChange={(e) => {
                setAddress(e.target.value);
              }}
            />
          </div>

          <div
            style={{
              left: "0vw",
              width: width > 700 ? "94%" : "99%",
              marginLeft: "0%",
            }}
            className="loginfield"
            onClick={handleClickx2}
          >
            <TextField
              id="outlined-basic"
              label="State *"
              variant="outlined"
              disabled
              value={state}
              style={{ width: "100%" }}
              InputLabelProps={{
                style: {
                  fontSize: width > 700 ? "1vw" : "3vw",
                  fontFamily: "Poppins",
                  fontStyle: "500",
                  fontWeight: "500",
                  color: "black",
                },
              }}
              inputProps={{ className: classes.input }}
              onChange={(e) => {
                console.log(e.target.value);
              }}
            />
            {width > 700 && (
              <span style={{ width: "0.1vw" }}>
                <KeyboardArrowDownOutlined
                  style={{
                    fontSize: "1.5vw",
                    position: "relative",
                    right: "2vw",
                    top: "1vw",
                  }}
                />
              </span>
            )}
          </div>
          <Popover
            id={idx2}
            open={openx2}
            anchorEl={anchorElx2}
            onClose={handleClosex2}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div
              style={{
                maxHeight: width > 700 ? "18vw" : "40vw",
                overflow: "scroll",
                width: width > 700 ? "55vw" : "90vw",
              }}
            >
              {arrayoflongdegree?.length > 0 &&
                arrayoflongdegree.map((data, index) => {
                  return (
                    <Typography
                      sx={{
                        p: 0.51,
                        pl: 1,
                        ml: 1,
                        width: "100%",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setState(data);
                        handleClosex2();
                      }}
                    >
                      {data}
                    </Typography>
                  );
                })}
            </div>
          </Popover>

          <div className="jobpodtedfieldtitile">Pincode</div>
          <div className="jobpostfieldinputbox">
            <input
              type="number"
              value={pincode}
              onChange={(e) => {
                setPincode(e.target.value);
              }}
            />
          </div>

          <div className="jobpodtedfieldtitile">GST No</div>
          <div className="jobpostfieldinputbox">
            <input
              type="text"
              value={gstno}
              onChange={(e) => {
                setGstno(e.target.value);
              }}
            />
          </div>
          <div className="jobpodtedfieldtitile">Invoice Serial Name *</div>
          <div className="jobpostfieldinputbox">
            <input
              type="text"
              value={invoicetag}
              onChange={(e) => {
                setInvoicetag(e.target.value);
              }}
            />
          </div>

          <div
            style={{
              left: "0vw",
              width: width > 700 ? "94%" : "99%",
              marginLeft: "0%",
            }}
            className="loginfield"
            onClick={handleClickx1}
          >
            <TextField
              id="outlined-basic"
              label="Invoiceing Year *"
              variant="outlined"
              disabled
              value={invoicestartyear}
              style={{ width: "100%" }}
              InputLabelProps={{
                style: {
                  fontSize: width > 700 ? "1vw" : "3vw",
                  fontFamily: "Poppins",
                  fontStyle: "500",
                  fontWeight: "500",
                  color: "black",
                },
              }}
              inputProps={{ className: classes.input }}
              onChange={(e) => {
                console.log(e.target.value);
              }}
            />
            {width > 700 && (
              <span style={{ width: "0.1vw" }}>
                <KeyboardArrowDownOutlined
                  style={{
                    fontSize: "1.5vw",
                    position: "relative",
                    right: "2vw",
                    top: "1vw",
                  }}
                />
              </span>
            )}
          </div>
          <Popover
            id={idx1}
            open={openx1}
            anchorEl={anchorElx1}
            onClose={handleClosex2}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div
              style={{
                maxHeight: width > 700 ? "18vw" : "40vw",
                overflow: "scroll",
                width: width > 700 ? "55vw" : "90vw",
              }}
            >
              {arrayoflongdegree1?.length > 0 &&
                arrayoflongdegree1.map((data, index) => {
                  return (
                    <Typography
                      sx={{
                        p: 0.51,
                        pl: 1,
                        ml: 1,
                        width: "100%",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setInvoicestartyear(data);
                        handleClosex1();
                      }}
                    >
                      {data}
                    </Typography>
                  );
                })}
            </div>
          </Popover>

          <div className="jobpodtedfieldtitile">Invoice Start No *</div>
          <div className="jobpostfieldinputbox">
            <input
              type="number"
              value={invoicestartno}
              onChange={(e) => {
                setInvoicestartno(e.target.value);
              }}
            />
          </div>

          <div className="jobpodtedfieldtitile">Bank Name</div>
          <div className="jobpostfieldinputbox">
            <input
              type="text"
              value={bankname}
              onChange={(e) => {
                setBankname(e.target.value);
              }}
            />
          </div>
          <div className="jobpodtedfieldtitile">Account No</div>
          <div className="jobpostfieldinputbox">
            <input
              type="number"
              value={accountno}
              onChange={(e) => {
                setAccountno(e.target.value);
              }}
            />
          </div>
          <div className="jobpodtedfieldtitile">Account Holder Name</div>
          <div className="jobpostfieldinputbox">
            <input
              type="text"
              value={accountholder}
              onChange={(e) => {
                setaccountholder(e.target.value);
              }}
            />
          </div>
          <div className="jobpodtedfieldtitile">Ifsc Code</div>
          <div className="jobpostfieldinputbox">
            <input
              type="text"
              value={ifsc}
              onChange={(e) => {
                setIfsc(e.target.value);
              }}
            />
          </div>
          <div className="jobpodtedfieldtitile">Branch</div>
          <div className="jobpostfieldinputbox">
            <input
              type="text"
              value={branch}
              onChange={(e) => {
                setBranch(e.target.value);
              }}
            />
          </div>

          <div
            style={{
              left: "0vw",
              width: width > 700 ? "94%" : "99%",
              margin: width > 700 ? "1vw 2vw 2vw 0vw" : "4vw 0",
            }}
            className="loginfield"
          >
            <TextField
              id="outlined-basic"
              label="Payment Link"
              value={paymentlink}
              variant="outlined"
              style={{ width: "100%" }}
              InputLabelProps={{
                style: {
                  fontSize: width > 700 ? "1vw" : "3vw",
                  fontFamily: "Poppins",
                  fontStyle: "500",
                  fontWeight: "500",
                  color: "black",
                },
              }}
              inputProps={{ className: classes.input }}
              onChange={(e) => setPaymentlink(e.target.value)}
            />
          </div>

          <div
            style={{
              marginBottom: "0.0vw",
              marginLeft: "1vw",
              marginTop: "2vw",
            }}
            className="jobpodtedfieldtitile"
          >
            Company Logo
          </div>
          <div
            style={{
              background: "white",
              padding: "1vw",
              marginTop: "0vw",
              width: "96%",
            }}
          >
            <div className="inputfilebox">
              <div>
                <label htmlFor="inputctaelogfile">
                  <div className="inputicon">
                    <img src={img} alt="" />
                  </div>
                  <div className="inputcateaddformfile">
                    Drag and Drop ,Browse to upload
                  </div>
                  <input
                    type="file"
                    id="inputctaelogfile"
                    onChange={(e) => {
                      handleImageUpload(e);
                    }}
                    hidden
                  />
                </label>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              fontSize: width > 700 ? "0.9vw" : "2.7vw",
              paddingRight: "2vw",
              textAlign: "right",
            }}
          >
            Please upload file having extensions .jpeg/ .jpg/ .png only.
            <br />
            Image should be less then 512 kb.
          </div>
          {(arrayoffiles || showeditcompany.logo) && (
            <div className="inputfilesshowncatebox">
              <div className="inputfilesshowncatboxsingle">
                <div className="inputfilesshowncatboxsingleimg">
                  <img src={img1} alt="" />
                </div>
                <div className="fileselctednamecate">
                  {arrayoffiles ? arrayoffiles.name : showeditcompany.logo}
                </div>
                {arrayoffiles && (
                  <div className="inputfilesshowncatboxsingleimg">
                    <img
                      style={{
                        width: width > 700 ? "1.5vw" : "4vw",
                        marginLeft: "1vw",
                        cursor: "pointer",
                      }}
                      src={img21}
                      alt=""
                      onClick={() => {
                        setArrayoffiles();
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          <div
            style={{
              marginBottom: "0.0vw",
              marginLeft: "1vw",
              marginTop: "2vw",
            }}
            className="jobpodtedfieldtitile"
          >
            Company QR Image
          </div>
          <div
            style={{
              background: "white",
              padding: "1vw",
              marginTop: "0vw",
              width: "96%",
            }}
          >
            <div className="inputfilebox">
              <div>
                <label htmlFor="inputctaelogfile1">
                  <div className="inputicon">
                    <img src={img} alt="" />
                  </div>
                  <div className="inputcateaddformfile">
                    Drag and Drop ,Browse to upload
                  </div>
                  <input
                    type="file"
                    id="inputctaelogfile1"
                    onChange={(e) => {
                      handleImageqr(e);
                    }}
                    hidden
                  />
                </label>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              fontSize: width > 700 ? "0.9vw" : "2.7vw",
              paddingRight: "2vw",
              textAlign: "right",
            }}
          >
            Please upload file having extensions .jpeg/ .jpg/ .png only.
            <br />
            Image should be less then 512 kb.
          </div>
          {(qrimage || showeditcompany.qr) && (
            <div className="inputfilesshowncatebox">
              <div className="inputfilesshowncatboxsingle">
                <div className="inputfilesshowncatboxsingleimg">
                  <img src={img1} alt="" />
                </div>
                <div className="fileselctednamecate">
                  {qrimage ? qrimage?.name : showeditcompany.qr}
                </div>
                {qrimage && (
                  <div className="inputfilesshowncatboxsingleimg">
                    <img
                      style={{
                        width: width > 700 ? "1.5vw" : "4vw",
                        marginLeft: "1vw",
                        cursor: "pointer",
                      }}
                      src={img21}
                      alt=""
                      onClick={() => {
                        setQrimage();
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          <div
            style={{
              marginBottom: "0.0vw",
              marginLeft: "1vw",
              marginTop: "2vw",
            }}
            className="jobpodtedfieldtitile"
          >
            Signature / Stamp
          </div>
          <div
            style={{
              background: "white",
              padding: "1vw",
              marginTop: "0vw",
              width: "96%",
            }}
          >
            <div className="inputfilebox">
              <div>
                <label htmlFor="inputctaelogfile2">
                  <div className="inputicon">
                    <img src={img} alt="" />
                  </div>
                  <div className="inputcateaddformfile">
                    Drag and Drop ,Browse to upload
                  </div>
                  <input
                    type="file"
                    id="inputctaelogfile2"
                    onChange={(e) => {
                      handleImagesign(e);
                    }}
                    hidden
                  />
                </label>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              fontSize: width > 700 ? "0.9vw" : "2.7vw",
              paddingRight: "2vw",
              textAlign: "right",
            }}
          >
            Please upload file having extensions .jpeg/ .jpg/ .png only.
            <br />
            Image should be less then 512 kb.
          </div>
          {(signature || showeditcompany.signAndStamp) && (
            <div className="inputfilesshowncatebox">
              <div className="inputfilesshowncatboxsingle">
                <div className="inputfilesshowncatboxsingleimg">
                  <img src={img1} alt="" />
                </div>
                <div className="fileselctednamecate">
                  {signature ? signature.name : showeditcompany.signAndStamp}
                </div>
                {signature && (
                  <div className="inputfilesshowncatboxsingleimg">
                    <img
                      style={{
                        width: width > 700 ? "1.5vw" : "4vw",
                        marginLeft: "1vw",
                        cursor: "pointer",
                      }}
                      src={img21}
                      alt=""
                      onClick={() => {
                        setSignature();
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          <div
            style={{
              marginBottom: "0.0vw",
              marginLeft: "1vw",
              marginTop: "2vw",
            }}
            className="jobpodtedfieldtitile"
          >
            Payment QR Image
          </div>
          <div
            style={{
              background: "white",
              padding: "1vw",
              marginTop: "0vw",
              width: "96%",
            }}
          >
            <div className="inputfilebox">
              <div>
                <label htmlFor="inputctaelogfile111">
                  <div className="inputicon">
                    <img src={img} alt="" />
                  </div>
                  <div className="inputcateaddformfile">
                    Drag and Drop ,Browse to upload
                  </div>
                  <input
                    type="file"
                    id="inputctaelogfile111"
                    onChange={(e) => {
                      handleImageqr1(e);
                    }}
                    hidden
                  />
                </label>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              fontSize: width > 700 ? "0.9vw" : "2.7vw",
              paddingRight: "2vw",
              textAlign: "right",
            }}
          >
            Please upload file having extensions .jpeg/ .jpg/ .png only.
            <br />
            Image should be less then 512 kb.
          </div>
          { (qrimage1 || showeditcompany.payment )&& 
            <div className="inputfilesshowncatebox">
              <div className="inputfilesshowncatboxsingle">
                <div className="inputfilesshowncatboxsingleimg">
                  <img src={img1} alt="" />
                </div>
                <div className="fileselctednamecate">
                  {qrimage1 ? qrimage1?.name : showeditcompany.payment}
                </div>
                {qrimage1 && (
                  <div className="inputfilesshowncatboxsingleimg">
                    <img
                      style={{
                        width: width > 700 ? "1.5vw" : "4vw",
                        marginLeft: "1vw",
                        cursor: "pointer",
                      }}
                      src={img21}
                      alt="" 
                      onClick={() => {
                        setQrimage1();
                      }}
                    />
                  </div>  
                )}
              </div>
            </div>
          }

          <div
            style={{
              fontSize: width > 700 ? "0.9vw" : "2.5vw",
              color: "red",
              fontWeight: "400",
              fontFamily: "Poppins",
            }}
            className="rederror"
          >
            {error}
          </div>

          <div style={{ marginBottom: "4vw" }} className="homejobbuttons">
            <button
              style={{ background: "white" }}
              onClick={() => {
                setShoweditcompany(false)
              }}
            >
              Cancel
            </button>

            <button
              style={{ color: "white" }}
              onClick={() => {
                handleSumbitbank();
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
