import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import img2 from "../../assets/Dashboard/Skill center – 2/Iconly-Light-outline-Edit.svg";
import ReactImageMagnify from "react-image-magnify";
import Slider from "react-slick";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import WebsiteCart from "./WebsiteCart";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import img1 from "../../assets/Web 1280 – 14/Group 9831.svg";
import img21 from "../../assets/My profile – 28/Component 85 – 16 (1).svg";
import { useDispatch, useSelector } from "react-redux";
import StarIcon from "@mui/icons-material/Star";
import imageCompression from "browser-image-compression";
import axios from "axios";
import API_HOST from "../../env";
import FormData from "form-data";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useNavigate } from "react-router";
import { userActions } from "../../store/userSlice";
import { Backdrop, CircularProgress, Popover, Typography } from "@mui/material";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import imgxx from "../../../../Tool-prod/src/assets/Success stories Definition/checkmark (1).svg";
import WebsiteCart2 from "./WebsiteCart2";

function SamplePrevArrow(props) {
  return (
    <div
      style={{
        width: "3vw",
        height: "3vw",

        borderRadius: "50%",
        background: "#FFF",
        boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.2)",
        margin: "1vw",
      }}
      className="d-flex justify-content-center align-items-center"
      onClick={props.onClick}
    >
      <ArrowUpwardIcon alt="prev" />
    </div>
  );
}

function SampleNextArrow(props) {
  return (
    <div
      style={{
        width: "3vw",
        height: "3vw",
        borderRadius: "50%",
        background: "#FFF",
        boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.2)",
        margin: "1vw",
      }}
      className="d-flex justify-content-center align-items-center"
      onClick={props.onClick}
    >
      <ArrowDownwardIcon alt="next" />
    </div>
  );
}

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: "0.51vw",
};
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "96vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: "0vw",
};

export default function WebsiteProd({ width, websitedata }) {
  const [proddata, setproddata] = useState();

  const navigate = useNavigate();

  let settings = {
    infinite: false,
    speed: 250,
    arrows: true,
    slidesToShow: 3,
    initialSlide: 0,
    slidesToScroll: 2,
    vertical: true,
    swipeToSlide: true,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
  };
  const [displayImage, setDisplayImage] = useState(0);
  const sliderClick = (value) => {
    setDisplayImage(value);
  };

  const [open121, setOpen121] = React.useState(false);
  const handleClose121 = () => {
    setOpen121(false);
  };
  const handleToggle121 = () => {
    setOpen121(!open121);
  };
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const [Cartsaved, setCartsaved] = useState(false);
  const { user } = useSelector((state) => state.user);

  const [adress, setAdress] = useState(" ");
  const [state, setstate] = useState("");
  const [pincode, setpincode] = useState(" ");
  const [gst, setgst] = useState(" ");

  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [fullname, setfullname] = useState("");

  const [adress1, setAdress1] = useState(" ");
  const [state1, setstate1] = useState(" ");
  const [pincode1, setpincode1] = useState(" ");
  const [gst1, setgst1] = useState(" ");

  const [mobile1, setmobile1] = useState("");
  const [email1, setemail1] = useState("");
  const [fullname1, setfullname1] = useState("");

  const [Sameasabove, setSameasabove] = useState(false);

  const [anchorElx2, setAnchorElx2] = useState(null);
  const openx2 = Boolean(anchorElx2);
  const idx2 = openx2 ? "simple-popover" : undefined;

  const handleClickx2 = (event) => {
    setAnchorElx2(event.currentTarget);
  };

  const handleClosex2 = () => {
    setAnchorElx2(null);
  };
  const [anchorElx, setAnchorElx] = useState(null);
  const openx = Boolean(anchorElx);
  const idx = openx ? "simple-popover" : undefined;

  const handleClickx = (event) => {
    setAnchorElx(event.currentTarget);
  };

  const handleClosex = () => {
    setAnchorElx(null);
  };

  useEffect(() => {
    if (websitedata?.type === "Product") {
      if (Cartsaved) {
        setArrayofProducts(Cartsaved?.product);
        setAdress(Cartsaved?.address);
        setmobile(Cartsaved?.mobile);
        setfullname(Cartsaved?.fullName);
        setemail(Cartsaved?.emailId);
        setstate(Cartsaved?.buyerState);
        setgst(Cartsaved?.buyerGST);
        setpincode(Cartsaved?.buyerPincode);

        setAdress1(Cartsaved?.consignerAddress);
        setmobile1(Cartsaved?.consignerMobile);
        setfullname1(Cartsaved?.consignerName);
        setemail1(Cartsaved?.consignerEmailid);
        setstate1(Cartsaved?.consignerState);
        setgst1(Cartsaved?.consignerGST);
        setpincode1(Cartsaved?.consignerPincode);

        setArrayoffiles(Cartsaved?.files);
      } else {
        setAdress(user?.address ? user?.address : "");
        setmobile(user?.contactNo);
        setemail(user?.emailId);
        setfullname(user?.fullName);
      }
    } else {
      if (Cartsaved) {
        setArrayofservices(Cartsaved?.services);
        setAdress(Cartsaved?.address);
        setmobile(Cartsaved?.mobile);
        setfullname(Cartsaved?.fullName);
        setemail(Cartsaved?.emailId);
        setstate(Cartsaved?.buyerState);
        setgst(Cartsaved?.buyerGST);
        setpincode(Cartsaved?.buyerPincode);

        setAdress1(Cartsaved?.consignerAddress);
        setmobile1(Cartsaved?.consignerMobile);
        setfullname1(Cartsaved?.consignerName);
        setemail1(Cartsaved?.consignerEmailid);
        setstate1(Cartsaved?.consignerState);
        setgst1(Cartsaved?.consignerGST);
        setpincode1(Cartsaved?.consignerPincode);
        setArrayoffiles(Cartsaved?.files);
      } else {
        setAdress(user?.address ? user?.address : "");
        setmobile(user?.contactNo);
        setemail(user?.emailId);
        setfullname(user?.fullName);
      }
    }
  }, [user, Cartsaved]);

  const dispatch = useDispatch();
  const handleStart = () => {
    if (!user?.userName) {
      dispatch(userActions.openloginForm());
    } else {
      navigate("/dashboard");
    }
  };

  const [arrayofProducts, setArrayofProducts] = useState([]);
  const [arrayofservices, setArrayofservices] = useState([]);

  const [arrayoflongdegree, setArrayoflongdegree] = useState([
    "JAMMU AND KASHMIR (1)",
    "HIMACHAL PRADESH (2)",
    "PUNJAB (3)",
    "CHANDIGARH	(4)",
    "UTTARAKHAND (5)",
    "HARYANA (6)",
    "DELHI (7)",
    "RAJASTHAN (8)",
    "UTTAR PRADESH (9)",
    "BIHAR (10)",
    "SIKKIM	(11)",
    "ARUNACHAL PRADESH (12)",
    "NAGALAND (13)",
    "MANIPUR (14)",
    "MIZORAM (15)",
    "TRIPURA (16)",
    "MEGHALAYA (17)",
    "ASSAM	(18)",
    "WEST BENGAL (19)",
    "JHARKHAND	(20)",
    "ODISHA	(21)",
    "CHATTISGARH (22)",
    "MADHYA PRADESH	(23)",
    "GUJARAT (24)",
    "DADRA AND NAGAR HAVELI AND DAMAN AND DIU (NEWLY MERGED UT) (26*)",
    "MAHARASHTRA (27)",
    "ANDHRA PRADESH(BEFORE DIVISION) (28)",
    "KARNATAKA (29)",
    "GOA (30)",
    "LAKSHADWEEP (31)",
    "KERALA	(32)",
    "TAMIL NADU	(33)",
    "PUDUCHERRY	(34)",
    "ANDAMAN AND NICOBAR ISLANDS (35)",
    "TELANGANA (36)",
    "ANDHRA PRADESH (NEWLY ADDED) (37)",
    "LADAKH (NEWLY ADDED) (38)",
  ]);

  const [totalgst, setTotalgst] = useState();

  const [totalparice, settotalparice] = useState();

  const [grandTotal, setGrandTotal] = useState();

  useEffect(() => {
    if (arrayofProducts?.length > 0) {
      let a = 0;
      let b = 0;

      arrayofProducts?.map((item) => {
        a = a + item?.calculatedGstPrice;
        b = b + item?.totalPrice;
      });

      setTotalgst(a);
      settotalparice(b);
      setGrandTotal(a + b + parseInt(websitedata?.amount));
    }
    if (arrayofservices?.length > 0) {
      let a = 0;
      let b = 0;

      arrayofservices?.map((item) => {
        a = a + item?.totalGST;
        b = b + item?.totalPrice;
      });

      setTotalgst(a);
      settotalparice(b);
      setGrandTotal(a + b + parseInt(websitedata?.amount));
    }
  }, [arrayofProducts, arrayofservices]);

  const [arrayoffiles, setArrayoffiles] = useState([]);

  const upadteimage = (img) => {
    const formdata = new FormData();
    formdata.append("fileName", img);
    websitedata?.type === "Service"
      ? formdata.append("folderName", "servicekartObjects")
      : formdata.append("folderName", "kartObjects");

    axios
      ?.post(`${API_HOST}/contentManagement/tableContent`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        setArrayoffiles([
          ...arrayoffiles,
          { file: res.data?.success?.data?.file },
        ]);
      });
  };

  async function handleImageUpload(event) {
    const imageFile = event.target.files[0];

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log(
        "compressedFile instanceof Blob",
        compressedFile instanceof Blob
      ); // true
      console.log(
        `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
      ); // smaller than maxSizeMB

      //   await uploadToServer(compressedFile); // write your own logic
      (await Cartsaved)
        ? upadteimage(new File([compressedFile], `${compressedFile?.name}`))
        : setArrayoffiles([
            ...arrayoffiles,
            new File([compressedFile], `${compressedFile?.name}`),
          ]);
    } catch (error) {
      console.log(error);
    }
  }

  const [errormobile, seterrormobile] = useState();

  const handlesavecart = () => {
    if (!mobile) {
      seterrormobile("Please Enter Your Mobile");
      return;
    }

    if (Cartsaved) {
      handleToggle121();
      const formdata = new FormData();

      formdata.append("products", JSON.stringify(arrayofProducts));
      formdata.append("files", JSON.stringify(arrayoffiles));

      formdata.append("cartId", Cartsaved?.cartId);
      formdata.append("address", adress);
      formdata.append("mobile", mobile);
      formdata.append("fullName", fullname);
      formdata.append("buyerState", state);
      formdata.append("buyerPincode", pincode);
      formdata.append("buyerGST", gst);
      formdata.append("emailId", email);
      formdata.append("consignerAddress", Sameasabove ? adress : adress1);
      formdata.append("consignerMobile", Sameasabove ? mobile : mobile1);
      formdata.append("consignerName", Sameasabove ? fullname : fullname1);
      formdata.append("consignerState", Sameasabove ? state : state1);
      formdata.append("consignerPincode", Sameasabove ? pincode : pincode1);
      formdata.append("consignerGST", Sameasabove ? gst : gst1);
      formdata.append("consignerEmailid", Sameasabove ? email : email1);
      formdata.append("totalPriceValue", totalparice);
      formdata.append("totalGstValue", totalgst);
      formdata.append("finalAmount", grandTotal);
      formdata.append("extraCharge", websitedata?.amount);
      axios
        .post(`${API_HOST}/carts/editCart`, formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((res) => {
          setCartsaved(res?.data?.success?.data);
          setSavedcartdatta(true);
          handleClose121();
        })
        .catch(() => {
          handleClose121();
        });
    } else {
      handleToggle121();
      const formdata = new FormData();
      arrayoffiles?.map((data) => {
        formdata.append("fileName", data);
      });
      formdata.append("products", JSON.stringify(arrayofProducts));
      formdata.append("website_id", websitedata?._id);
      formdata.append("address", adress);
      formdata.append("mobile", mobile);
      formdata.append("fullName", fullname);
      formdata.append("buyerState", state);
      formdata.append("buyerPincode", pincode);
      formdata.append("buyerGST", gst);
      formdata.append("emailId", email);
      formdata.append("consignerAddress", Sameasabove ? adress : adress1);
      formdata.append("consignerMobile", Sameasabove ? mobile : mobile1);
      formdata.append("consignerName", Sameasabove ? fullname : fullname1);
      formdata.append("consignerState", Sameasabove ? state : state1);
      formdata.append("consignerPincode", Sameasabove ? pincode : pincode1);
      formdata.append("consignerGST", Sameasabove ? gst : gst1);
      formdata.append("consignerEmailid", Sameasabove ? email : email1);
      formdata.append("totalPriceValue", totalparice);
      formdata.append("totalGstValue", totalgst);
      formdata.append("finalAmount", grandTotal);
      formdata.append("extraCharge", websitedata?.amount);
      axios
        .post(`${API_HOST}/carts/addCart`, formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((res) => {
          axios
            .get(
              `${API_HOST}/carts/getSavedCart?website_id=${websitedata?._id}`,
              {
                headers: {
                  Authorization:
                    "Bearer " + JSON.parse(localStorage.getItem("token")),
                },
              }
            )
            .then((res) => {
              setCartsaved(res?.data?.success?.data[0]);
              setSavedcartdatta(true);
              handleClose121();
            })
            .catch(() => {
              handleClose121();
            });
        });
    }
  };

  const handlesavecart1 = () => {
    if (!mobile) {
      seterrormobile("Please Enter Your Mobile");
      return;
    }

    if (Cartsaved) {
      handleToggle121();
      const formdata = new FormData();

      formdata.append("services", JSON.stringify(arrayofservices));
      formdata.append("files", JSON.stringify(arrayoffiles));

      formdata.append("sCartId", Cartsaved?.sCartId);
      formdata.append("address", adress);
      formdata.append("mobile", mobile);
      formdata.append("fullName", fullname);
      formdata.append("buyerState", state);
      formdata.append("buyerPincode", pincode);
      formdata.append("buyerGST", gst);
      formdata.append("emailId", email);
      formdata.append("consignerAddress", Sameasabove ? adress : adress1);
      formdata.append("consignerMobile", Sameasabove ? mobile : mobile1);
      formdata.append("consignerName", Sameasabove ? fullname : fullname1);
      formdata.append("consignerState", Sameasabove ? state : state1);
      formdata.append("consignerPincode", Sameasabove ? pincode : pincode1);
      formdata.append("consignerGST", Sameasabove ? gst : gst1);
      formdata.append("consignerEmailid", Sameasabove ? email : email1);
      formdata.append("totalPriceValue", totalparice);
      formdata.append("totalGstValue", totalgst);
      formdata.append("finalAmount", grandTotal);
      formdata.append("extraCharge", websitedata?.amount);
      axios
        .post(`${API_HOST}/services/editService`, formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((res) => {
          setCartsaved(res?.data?.success?.data);
          setSavedcartdatta(true);
          handleClose121();
        })
        .catch(() => {
          handleClose121();
        });
    } else {
      handleToggle121();
      const formdata = new FormData();
      arrayoffiles?.map((data) => {
        formdata.append("fileName", data);
      });
      formdata.append("services", JSON.stringify(arrayofservices));
      formdata.append("website_id", websitedata?._id);
      formdata.append("address", adress);
      formdata.append("mobile", mobile);
      formdata.append("fullName", fullname);
      formdata.append("buyerState", state);
      formdata.append("buyerPincode", pincode);
      formdata.append("buyerGST", gst);
      formdata.append("emailId", email);
      formdata.append("consignerAddress", Sameasabove ? adress : adress1);
      formdata.append("consignerMobile", Sameasabove ? mobile : mobile1);
      formdata.append("consignerName", Sameasabove ? fullname : fullname1);
      formdata.append("consignerState", Sameasabove ? state : state1);
      formdata.append("consignerPincode", Sameasabove ? pincode : pincode1);
      formdata.append("consignerGST", Sameasabove ? gst : gst1);
      formdata.append("consignerEmailid", Sameasabove ? email : email1);
      formdata.append("totalPriceValue", totalparice);
      formdata.append("totalGstValue", totalgst);
      formdata.append("finalAmount", grandTotal);
      formdata.append("extraCharge", websitedata?.amount);
      axios
        .post(`${API_HOST}/services/addService`, formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((res) => {
          axios
            .get(
              `${API_HOST}/services/getSavedCart?website_id=${websitedata?._id}`,
              {
                headers: {
                  Authorization:
                    "Bearer " + JSON.parse(localStorage.getItem("token")),
                },
              }
            )
            .then((res) => {
              setCartsaved(res?.data?.success?.data[0]);
              setSavedcartdatta(true);
              handleClose121();
            })
            .catch(() => {
              handleClose121();
            });
        });
    }
  };

  const handleorder = () => {
    if (!mobile) {
      seterrormobile(" Please Enter Your Mobile");
      return;
    }
    if (!state) {
      seterrormobile(" Please Enter Your state");
      return;
    }
    if (Cartsaved) {
      handleToggle121();
      const formdata = new FormData();
      formdata.append("products", JSON.stringify(arrayofProducts));
      formdata.append("files", JSON.stringify(arrayoffiles));
      formdata.append("cartId", Cartsaved?.cartId);
      formdata.append("address", adress);
      formdata.append("mobile", mobile);
      formdata.append("fullName", fullname);
      formdata.append("buyerState", state);
      formdata.append("buyerPincode", pincode);
      formdata.append("buyerGST", gst);
      formdata.append("emailId", email);
      formdata.append("consignerAddress", Sameasabove ? adress : adress1);
      formdata.append("consignerMobile", Sameasabove ? mobile : mobile1);
      formdata.append("consignerName", Sameasabove ? fullname : fullname1);
      formdata.append("consignerState", Sameasabove ? state : state1);
      formdata.append("consignerPincode", Sameasabove ? pincode : pincode1);
      formdata.append("consignerGST", Sameasabove ? gst : gst1);
      formdata.append("consignerEmailid", Sameasabove ? email : email1);
      formdata.append("totalPriceValue", totalparice);
      formdata.append("totalGstValue", totalgst);
      formdata.append("finalAmount", grandTotal);
      formdata.append("extraCharge", websitedata?.amount);
      axios
        .post(`${API_HOST}/carts/placeOrder`, formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((res) => {
          handleClose121();
          navigate("/dashboard/Buying-List");
        })
        .catch(() => {
          handleClose121();
        });
    } else {
      handleToggle121();
      const formdata = new FormData();
      arrayoffiles?.map((data) => {
        formdata.append("fileName", data);
      });
      formdata.append("products", JSON.stringify(arrayofProducts));
      formdata.append("website_id", websitedata?._id);
      formdata.append("address", adress);
      formdata.append("mobile", mobile);
      formdata.append("fullName", fullname);
      formdata.append("buyerState", state);
      formdata.append("buyerPincode", pincode);
      formdata.append("buyerGST", gst);
      formdata.append("emailId", email);
      formdata.append("consignerAddress", Sameasabove ? adress : adress1);
      formdata.append("consignerMobile", Sameasabove ? mobile : mobile1);
      formdata.append("consignerName", Sameasabove ? fullname : fullname1);
      formdata.append("consignerState", Sameasabove ? state : state1);
      formdata.append("consignerPincode", Sameasabove ? pincode : pincode1);
      formdata.append("consignerGST", Sameasabove ? gst : gst1);
      formdata.append("consignerEmailid", Sameasabove ? email : email1);
      formdata.append("totalPriceValue", totalparice);
      formdata.append("totalGstValue", totalgst);
      formdata.append("finalAmount", grandTotal);
      formdata.append("extraCharge", websitedata?.amount);
      axios
        .post(`${API_HOST}/carts/directOrder`, formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((res) => {
          navigate("/dashboard/Buying-List");
          handleClose121();
        })
        .catch(() => {
          handleClose121();
        });
    }
  };

  const handleorder1 = () => {
    if (!mobile) {
      seterrormobile(" Please Enter Your Mobile");
      return;
    }
    if (!state) {
      seterrormobile(" Please Enter Your state");
      return;
    }
    if (Cartsaved) {
      handleToggle121();
      const formdata = new FormData();
      formdata.append("services", JSON.stringify(arrayofservices));
      formdata.append("files", JSON.stringify(arrayoffiles));
      formdata.append("sCartId", Cartsaved?.sCartId);
      formdata.append("address", adress);
      formdata.append("mobile", mobile);
      formdata.append("fullName", fullname);
      formdata.append("buyerState", state);
      formdata.append("buyerPincode", pincode);
      formdata.append("buyerGST", gst);
      formdata.append("emailId", email);
      formdata.append("consignerAddress", Sameasabove ? adress : adress1);
      formdata.append("consignerMobile", Sameasabove ? mobile : mobile1);
      formdata.append("consignerName", Sameasabove ? fullname : fullname1);
      formdata.append("consignerState", Sameasabove ? state : state1);
      formdata.append("consignerPincode", Sameasabove ? pincode : pincode1);
      formdata.append("consignerGST", Sameasabove ? gst : gst1);
      formdata.append("consignerEmailid", Sameasabove ? email : email1);
      formdata.append("totalPriceValue", totalparice);
      formdata.append("totalGstValue", totalgst);
      formdata.append("finalAmount", grandTotal);
      formdata.append("extraCharge", websitedata?.amount);
      axios
        .post(`${API_HOST}/services/placeOrder`, formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((res) => {
          handleClose121();
          navigate("/dashboard/Service-List");
        })
        .catch(() => {
          handleClose121();
        });
    } else {
      handleToggle121();
      const formdata = new FormData();
      arrayoffiles?.map((data) => {
        formdata.append("fileName", data);
      });
      formdata.append("services", JSON.stringify(arrayofservices));
      formdata.append("website_id", websitedata?._id);
      formdata.append("address", adress);
      formdata.append("mobile", mobile);
      formdata.append("fullName", fullname);
      formdata.append("buyerState", state);
      formdata.append("buyerPincode", pincode);
      formdata.append("buyerGST", gst);
      formdata.append("emailId", email);
      formdata.append("consignerAddress", Sameasabove ? adress : adress1);
      formdata.append("consignerMobile", Sameasabove ? mobile : mobile1);
      formdata.append("consignerName", Sameasabove ? fullname : fullname1);
      formdata.append("consignerState", Sameasabove ? state : state1);
      formdata.append("consignerPincode", Sameasabove ? pincode : pincode1);
      formdata.append("consignerGST", Sameasabove ? gst : gst1);
      formdata.append("consignerEmailid", Sameasabove ? email : email1);
      formdata.append("totalPriceValue", totalparice);
      formdata.append("totalGstValue", totalgst);
      formdata.append("finalAmount", grandTotal);
      formdata.append("extraCharge", websitedata?.amount);
      axios
        .post(`${API_HOST}/services/directOrder`, formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((res) => {
          navigate("/dashboard/Service-List");
          handleClose121();
        })
        .catch(() => {
          handleClose121();
        });
    }
  };

  const [productstoshow, setProductstoshow] = useState("");

  useEffect(() => {
    if (websitedata?.type === "Service") {
      axios
        .get(
          `${API_HOST}/services/getSavedCart?website_id=${websitedata?._id}`,
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then((res) => {
          setCartsaved(res?.data?.success?.data[0]);
        });
    } else {
      axios
        .get(`${API_HOST}/carts/getSavedCart?website_id=${websitedata?._id}`, {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((res) => {
          setCartsaved(res?.data?.success?.data[0]);
        });
    }
    setProductstoshow(websitedata?.webId);
  }, [websitedata]);

  const [savedcartdatta, setSavedcartdatta] = useState(false);

  useEffect(() => {
    if (savedcartdatta) {
      setTimeout(function () {
        setSavedcartdatta(false);
      }, 3000);
    }
  }, [savedcartdatta]);

  const [search, setSearch] = useState("");

  useEffect(() => {
    if (search !== "") {
      setProductstoshow(
        websitedata?.webId?.filter(function (el) {
          return el?.web_id?.product_id?.productName
            .toLowerCase()
            .includes(search?.toLowerCase());
        })
      );
    } else {
      setProductstoshow(websitedata?.webId);
    }
  }, [search]);

  const [showdeleverydetails, setShowdeleverydetails] = useState(false);

  return (
    <>
      <div className="websiteoffercontainer">
        <div
          style={{
            color: websitedata?.themeColor,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "2vw",
            fontWeight: "600",
            fontSize: width > 700 ? "2.5vw" : "6.5vw",
          }}
          className="offerheading"
        >
          <div>{websitedata?.menuHeadlines} </div>
        </div>
        <div>
          <div style={{ marginBottom: "3vw" }} className="searchblog">
            <div>
              <SearchOutlinedIcon
                style={{
                  fontSize: width > 700 ? "2vw" : "5vw",
                  margin: "0vw 1vw",
                  color: websitedata?.themeColor,
                }}
              />
            </div>
            <div style={{ width: "100%" }}>
              <input
                type="text"
                style={{ color: websitedata?.themeColor, fontWeight: "500" }}
                placeholder="Search products "
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </div>
          </div>
        </div>

        <div className="flexofoffers">
          {productstoshow?.length > 0 &&
            productstoshow?.map((data, index) => {
              return (

                <div
                className="Menuwebsite"
                style={{position: "relative",}}
                // style={{ height: "100%", width: width > 700 ? "25vw" : "90vw" }}
              >
                {/* <div style={{ width: "0vw", height: "0vw" }}>
                  <div
                    onClick={() => {
                      setdeleteid(data?.web_id?.webId);
                      handleOpen();
                    }}
                    style={{
                      // position: "relative",
                      // left: width > 700 ? "13.6vw" : "39.5vw",
                      // bottom: width > 700 ? "1.7vw" : "3.5vw",
                      position: "absolute",
                      top: "-20px",
                      right: "-10px"
                    }}
                  >
                    <RemoveCircleOutlineIcon
                      style={{
                        width: width > 700 ? "2vw" : "8vw",
                        height: width > 700 ? "2vw" : "8vw",
                        color: "#0052CC",
                        fontWeight: "600",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div> */}

                <div>
                  <img
                    style={{
                      width: width > 700 ? "25vw" : "100%",
                      height: width > 700 ? "12vw" : "50vw",
                      filter:
                        data?.web_id?.product_id?.type !== "Service" &&
                        data?.quantity < 1 &&
                        "grayscale(1)",
                      cursor: "pointer",
                    }}
                    src={data?.web_id?.product_id?.files[0]?.file}
                    alt=""
                    onClick={() => {
                      handleOpen1();
                      setproddata(data?.web_id);
                    }}
                  />

                  <div className="m-2">
                    <div
                      onClick={() => {
                        handleOpen1();
                        setproddata(data?.web_id);
                      }}
                      style={{ cursor: "pointer" }}
                      className="namemenuweb ellipsis"
                    >
                      {data?.web_id?.product_id?.productName}
                    </div>
                    <div
                      style={{
                        height: width > 700 ? "1.5vw" : "4.5vw",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingTop: "0.2vw",
                      }}
                      className="pricemenuweb mt-2"
                    >
                      <div
                        style={{
                          fontSize: width > 700 ? "0.85vw" : "2.5vw",
                          display: "flex",
                          marginTop: "0.1vw",
                          alignItems: "center",
                        }}
                      >
                        {data?.web_id?.user_id?.verifiedByAdmin === true && (
                          <img
                            style={{
                              width: width > 700 ? "1.1vw" : "3.1vw",
                              marginRight: "0.3vw",
                              height: "3.2vh",
                              objectFit: "contain",
                            }}
                            src={imgxx}
                            alt=""
                          />
                        )}
                        {data?.web_id?.user_id?.fullName}
                        {data?.web_id?.user_id?.certifiedByAdmin === true && (
                          <span style={{ display: "flex" }}>
                            (
                            <WorkspacePremiumIcon
                              style={{
                                width: width > 700 ? "1.1vw" : "3.1vw",
                                height: width > 700 ? "1.1vw" : "3.1vw",
                                margin: "0vw",
                                color: "#0052cc",
                              }}
                              alt=""
                            />
                            )
                          </span>
                        )}
                      </div>
                      <div className="d-flex align-items-center rating_text">
                        <div
                          className="d-flex align-items-center"
                          style={{ marginRight: "5px" }}
                        >
                          <span style={{ color: "#0052cc", marginTop: "2px" }}>
                            {data?.web_id?.product_id?.type
                              ? data?.web_id?.product_id?.type
                              : "Product"}
                          </span>
                          <StarIcon
                            style={{
                              width: width > 700 ? "1vw" : "3.5vw",
                              marginLeft: "5px",
                              color: "#0052cc",
                              height: "14px",
                            }}
                          />{" "}
                        </div>
                        <div style={{ marginTop: "2px" }}>
                          {data?.web_id?.product_id?.rating?.$numberDecimal
                            ? data?.web_id?.product_id?.rating?.$numberDecimal?.slice(0, 1)
                            : 0}{" "}
                          (
                          {data?.web_id?.product_id?.completedOrder
                            ? data?.web_id?.product_id?.completedOrder
                            : 0}
                          )
                        </div>
                      </div>
                    </div>
                    <div
                      className="multiline-ellipsis"
                      style={{
                        fontSize:width > 700 ? "0.95vw": "2.8vw" ,
                        paddingTop: "2px",
                        paddingBottom: "2px",
                        minHeight: "2.5vw",
                      }}
                    >
                      {data?.web_id?.product_id?.description !== "undefined"
                        ? data?.web_id?.product_id?.description || ""
                        : ""}
                    </div>
                    <hr style={{ padding: "0vw", margin: "0.3vw" }}></hr>
                    <div
                      style={{
                        height: width > 700 ? "1.5vw" : "4.5vw",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingTop: "0.2vw",
                      }}
                      className="pricemenuweb mt-2"
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {" "}
                        <span>
                          <img
                            style={{
                              height: width > 700 ? "fit-content" : "6vw",
                              width: width > 700 ? "1.2vw" : "6vw",
                              margin: "0vw",
                              marginRight: "0.2vw",
                              maxHeight: width > 700 ? "1.5vw" : "4vw",
                            }}
                            src={websitedata?.websiteLogo}
                            alt=""
                          />
                        </span>{" "}
                        <div className="ellipsis" style={{fontSize: width > 700 ? "0.8vw" :"2.8vw", marginTop:"2px"}}>{websitedata?.websiteName}</div>
                      </div>{" "}
                      <div
                        style={{
                          color:
                            data?.web_id?.product_id?.type === "Service"
                              ? ""
                              : data?.quantity > 0
                              ? ""
                              : "red",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        className="stockmenuweb"
                      >
                        {data?.web_id?.product_id?.type === "Service"
                          ? data?.web_id?.product_id?.serviceType
                          : data?.quantity > 0
                          ? data?.quantity + " Items Left"
                          : "Out of Stock"}{" "}
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingTop: "0.2vw",
                      }}
                      className="pricemenuweb p-0 w-100"
                    >
                      <div
                        style={{ display: "flex", alignItems: "center" }}
                        className="w-100"
                      >
                        {" "}
                        <span>
                          <img
                            style={{
                              height: "fit-content",
                              width: width > 700 ? "1.3vw" : "3.7vw",
                              margin: "0vw",
                              marginRight: "0.2vw",
                            }}
                            src={img1}
                            alt=""
                          />
                        </span>{" "}
                        <div className="ellipsis">
                          {" "}
                          {websitedata?.companyAddress}
                        </div>
                      </div>{" "}
                    </div>
                    <hr style={{ padding: "0vw", margin: "0.3vw" }}></hr>
                    <div className="d-flex align-items-center justify-content-between mt-2">
                      <div
                        style={{
                          paddingBottom: "0.2vw",
                          height: width > 700 ? "2vw" : "5vw",
                          fontSize: width > 700 ? "1.1vw" : "2.8vw",
                        }}
                        className="pricemenuweb"
                      >
                        <p style={{fontSize:"0.9vw"}}>Price</p>
                        <div className="tagblue d-flex align-items-center" style={{marginLeft:"8px"}}>
                        <span style={{fontSize: width > 700 ? "0.75vw" : "3vw"}}>Rs. {data?.web_id?.product_id?.costPrice}</span>{" "}
                        {data?.web_id?.product_id?.sellingPrice && (
                          <del
                            style={{
                              display: "flex",
                              fontSize: width > 700 ? "0.6vw" : "2vw",
                              height: width > 700 ? "0.8vw" : "2.5vw",
                              margin: "0 0.5vw",
                            }}
                          >
                            ( Rs {data?.web_id?.product_id?.sellingPrice})
                          </del>
                        )}
                        {data?.web_id?.product_id?.sellingPrice && (
                          <span >
                            {parseFloat(
                              ((data?.web_id?.product_id?.sellingPrice -
                                data?.web_id?.product_id?.costPrice) *
                                100) /
                                data?.web_id?.product_id?.sellingPrice
                            ).toFixed(0)}
                            % off{" "}
                          </span>
                        )}
                        </div>
                        
                      </div>
                        {/* <div onClick={() => data?.website_id[0]?.websiteName ? navigate(`/${contentNameRoute}/${data?.website_id[0]?.websiteId}`)
                              : navigate(`/-/${data?.website_id[0]?.websiteId}`) }
                          className="visit_btn"
                        > Visit Website
                        </div> */}
                        {websitedata?.type === "Service" ? (
                      <div
                        onClick={() => {
                          const today = new Date();
                          const month = (today.getMonth() + 1)
                            .toString()
                            .padStart(2, "0");
                          const day = today
                            .getDate()
                            .toString()
                            .padStart(2, "0"); // Note: Months are zero-based, so we add 1
                          const year = today.getFullYear();

                          const tomorrow = new Date(today);
                          tomorrow.setDate(today.getDate() + 1);

                          const month1 = (tomorrow.getMonth() + 1)
                            .toString()
                            .padStart(2, "0");
                          const day1 = tomorrow
                            .getDate()
                            .toString()
                            .padStart(2, "0"); // Note: Months are zero-based, so we add 1
                          const year1 = tomorrow.getFullYear();

                          const currentHour = today.getHours();
                          const currentMinute = today.getMinutes();
                          const formattedHour =
                            currentHour < 10 ? `0${currentHour}` : currentHour;
                          const formattedMinute =
                            currentMinute < 10
                              ? `0${currentMinute}`
                              : currentMinute;

                          setArrayofservices([
                            ...arrayofservices,
                            {
                              web_id: data?.web_id,
                              type: data?.web_id?.product_id?.serviceType,
                              qnumbers: 1,
                              addOn: [],
                              fromDate: `${year}-${month}-${day}`,
                              fromHour: `${formattedHour}:${formattedMinute}`,
                              toDate: `${year1}-${month1}-${day1}`,
                              totalDays: 1,
                              totalHours: 1,
                              totalAddonPrice: 0,
                              totalGST:
                                (data?.web_id?.product_id?.costPrice *
                                  data?.web_id?.product_id?.gstRate *
                                  1) /
                                100,
                              totalPrice:
                                data?.web_id?.product_id?.costPrice * 1,
                            },
                          ]);
                        }}
                        className="tagblue"
                        // style={{ background: `${websitedata?.themeColor}` }}
                      >
                        Add
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          data?.web_id?.quantity > 0 &&
                            setArrayofProducts([
                              ...arrayofProducts,
                              {
                                web_id: data?.web_id,
                                quantity: 1,
                                addOn: [],
                                totalAddOnPrices: 0,
                                calculatedGstPrice:
                                  (data?.web_id?.product_id?.costPrice *
                                    data?.web_id?.product_id?.gstRate *
                                    1) /
                                  100,
                                totalPrice:
                                  data?.web_id?.product_id?.costPrice * 1,
                              },
                            ]);
                        }}
                        className="tagblue"
                        // style={{ background: `${websitedata?.themeColor}` }}
                      >
                        Add to Cart
                      </div>
                    )}
                      </div>
                  </div>
                </div>
              </div>
                // <div
                //   style={{ height: width > 700 ? "19.5vw" : "50vw" }}
                //   className="Menuwebsite"
                // >
                //   <div>
                //     <img
                //       style={{
                //         filter:
                //           data?.web_id?.product_id?.type === "Product" &&
                //           data?.web_id?.quantity < 1 &&
                //           "grayscale(1)",
                //         cursor: "pointer",
                //       }}
                //       src={data?.web_id?.product_id?.files[0]?.file}
                //       alt=""
                //       onClick={() => {
                //         handleOpen1();
                //         setproddata(data?.web_id);
                //       }}
                //     />
                //     <div
                //       onClick={() => {
                //         handleOpen1();
                //         setproddata(data?.web_id);
                //       }}
                //       style={{
                //         cursor: "pointer",
                //       }}
                //       className="namemenuweb"
                //     >
                //       {data?.web_id?.product_id?.productName?.slice(0, 18)}
                //     </div>
                //     <div
                //       style={{
                //         display: "flex",
                //         alignItems: "center",
                //         justifyContent: "space-between",
                //       }}
                //     >
                //       <div
                //         style={{
                //           color:
                //             data?.web_id?.product_id?.type === "Product" &&
                //             data?.web_id?.quantity < 1
                //               ? "red"
                //               : "",
                //         }}
                //         className="stockmenuweb"
                //       >
                //         {data?.web_id?.product_id?.type === "Product"
                //           ? data?.web_id?.quantity > 0
                //             ? data?.web_id?.quantity + " Items Left"
                //             : "Out of Stock"
                //           : data?.web_id?.product_id?.serviceType}
                //       </div>
                //       <div
                //         style={{
                //           color: "#0052cc",
                //           display: "flex",
                //           alignItems: "center",

                //           fontSize: width > 700 ? "0.8vw" : "2.4vw",
                //         }}
                //       >
                //         {data?.web_id?.product_id?.rating?.$numberDecimal
                //           ? data?.web_id?.product_id?.rating?.$numberDecimal?.slice(
                //               0,
                //               1
                //             )
                //           : 0}
                //         <StarIcon
                //           style={{
                //             width: width > 700 ? "0.8vw" : "2.5vw",
                //             margin: "0vw",
                //             height: width > 700 ? "1vw" : "3vw",
                //           }}
                //         />
                //         (
                //         {data?.web_id?.product_id?.completedOrder
                //           ? data?.web_id?.product_id?.completedOrder
                //           : 0}
                //         )
                //       </div>
                //     </div>
                //     <div
                //       style={{ marginBottom: "0vw", paddingBottom: "0.3vw" }}
                //       className="pricemenuweb"
                //     >
                //       <span>Rs. {data?.web_id?.product_id?.costPrice}</span>{" "}
                //       {data?.web_id?.product_id?.sellingPrice && (
                //         <del
                //           style={{
                //             display: "flex",
                //             fontSize: "0.7vw",
                //             margin: "0 0.2vw",
                //           }}
                //         >
                //           ( Rs {data?.web_id?.product_id?.sellingPrice})
                //         </del>
                //       )}
                //       {data?.web_id?.product_id?.sellingPrice &&
                //         parseFloat(
                //           ((data?.web_id?.product_id?.sellingPrice -
                //             data?.web_id?.product_id?.costPrice) *
                //             100) /
                //             data?.web_id?.product_id?.sellingPrice
                //         ).toFixed(0) + "% off"}
                //     </div>
                //     {websitedata?.type === "Service" ? (
                //       <div
                //         onClick={() => {
                //           const today = new Date();
                //           const month = (today.getMonth() + 1)
                //             .toString()
                //             .padStart(2, "0");
                //           const day = today
                //             .getDate()
                //             .toString()
                //             .padStart(2, "0"); // Note: Months are zero-based, so we add 1
                //           const year = today.getFullYear();

                //           const tomorrow = new Date(today);
                //           tomorrow.setDate(today.getDate() + 1);

                //           const month1 = (tomorrow.getMonth() + 1)
                //             .toString()
                //             .padStart(2, "0");
                //           const day1 = tomorrow
                //             .getDate()
                //             .toString()
                //             .padStart(2, "0"); // Note: Months are zero-based, so we add 1
                //           const year1 = tomorrow.getFullYear();

                //           const currentHour = today.getHours();
                //           const currentMinute = today.getMinutes();
                //           const formattedHour =
                //             currentHour < 10 ? `0${currentHour}` : currentHour;
                //           const formattedMinute =
                //             currentMinute < 10
                //               ? `0${currentMinute}`
                //               : currentMinute;

                //           setArrayofservices([
                //             ...arrayofservices,
                //             {
                //               web_id: data?.web_id,
                //               type: data?.web_id?.product_id?.serviceType,
                //               qnumbers: 1,
                //               addOn: [],
                //               fromDate: `${year}-${month}-${day}`,
                //               fromHour: `${formattedHour}:${formattedMinute}`,
                //               toDate: `${year1}-${month1}-${day1}`,
                //               totalDays: 1,
                //               totalHours: 1,
                //               totalAddonPrice: 0,
                //               totalGST:
                //                 (data?.web_id?.product_id?.costPrice *
                //                   data?.web_id?.product_id?.gstRate *
                //                   1) /
                //                 100,
                //               totalPrice:
                //                 data?.web_id?.product_id?.costPrice * 1,
                //             },
                //           ]);
                //         }}
                //         className="Visitwebsitebutton"
                //         style={{ background: `${websitedata?.themeColor}` }}
                //       >
                //         Add
                //       </div>
                //     ) : (
                //       <div
                //         onClick={() => {
                //           data?.web_id?.quantity > 0 &&
                //             setArrayofProducts([
                //               ...arrayofProducts,
                //               {
                //                 web_id: data?.web_id,
                //                 quantity: 1,
                //                 addOn: [],
                //                 totalAddOnPrices: 0,
                //                 calculatedGstPrice:
                //                   (data?.web_id?.product_id?.costPrice *
                //                     data?.web_id?.product_id?.gstRate *
                //                     1) /
                //                   100,
                //                 totalPrice:
                //                   data?.web_id?.product_id?.costPrice * 1,
                //               },
                //             ]);
                //         }}
                //         className="Visitwebsitebutton"
                //         style={{ background: `${websitedata?.themeColor}` }}
                //       >
                //         Add to Cart
                //       </div>
                //     )}
                //   </div>
                // </div>
              );
            })}
        </div>

        <Modal
          open={open1}
          onClose={handleClose1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={width > 700 ? style2 : style1}>
            {width <= 700 && (
              <button
                style={{
                  cursor: "pointer",
                  zIndex: "100",
                  padding: "0.5vw 0.7vw",
                  backgroundColor: "white",
                  color: "#000",
                  fontSize: width > 700 ? "1.2vw" : "3.3vw",
                  borderRadius: "0.3vw",
                  border: "1px solid #d7d7d7",
                  marginBottom: width > 700 ? "1vw" : "3vw",
                  marginTop: "0vw",
                  margin: "1vw",
                }}
                onClick={handleClose1}
              >
                <ArrowBackIosNewIcon />
              </button>
            )}
            <div
              style={{
                width: "100%",
                background: "white",
                display: "flex",
                padding: width > 700 ? "1vw 1vw" : "0vw",
                paddingTop: "0vw",
                height: "85vh",
                flexDirection: width > 700 ? "" : "column",
                overflowY: "scroll",
              }}
            >
              <div className="product-wrapper">
                <div className="productpage">
                  <ul
                    style={{
                      marginTop:
                        proddata?.product_id?.files?.length < 4
                          ? (4 - proddata?.product_id?.files?.length) * 4 + "vw"
                          : "1vw",

                      height: width > 700 ? "" : "20vw",
                      position: width > 700 ? "" : "relative",
                      top: width > 700 ? "" : "2vw",
                      left:
                        width > 700
                          ? ""
                          : proddata?.product_id?.files?.length === 1
                          ? "1vw"
                          : proddata?.product_id?.files?.length === 2
                          ? "6vw"
                          : proddata?.product_id?.files?.length === 3
                          ? "12vw"
                          : "25vw",
                    }}
                    className="preview-thumbnail nav nav-tabs"
                  >
                    {proddata?.product_id?.files.length && (
                      <Slider {...settings}>
                        {proddata?.product_id?.files.map((img, index) => (
                          <li
                            key={index}
                            className={
                              displayImage === index ? "imageActive" : ""
                            }
                          >
                            <span
                              data-target={`#pic-${index}`}
                              data-toggle="tab"
                              onMouseOver={() => sliderClick(index)}
                            >
                              <img
                                src={img?.file}
                                style={{ width: "100%" }}
                                alt="iiiii"
                              />
                            </span>
                          </li>
                        ))}
                      </Slider>
                    )}
                  </ul>

                  <div className="productImg">
                    <div style={{ width: "100%", height: "100%" }}>
                      <ReactImageMagnify
                        style={{ width: "100%", height: "100%" }}
                        imageClassName="smallImage"
                        {...{
                          smallImage: {
                            alt: "Wristwatch by Ted Baker London",
                            isFluidWidth: true,
                            // width: 400,
                            // height: 400,
                            src: proddata?.product_id?.files[displayImage]
                              ?.file,
                            sizes: "(max-width: 1200px) 30vw, 360px",
                          },
                          largeImage: {
                            src: proddata?.product_id?.files[displayImage]
                              ?.file,
                            width: 1400,
                            height: 1500,
                          },
                          shouldUsePositiveSpaceLens: true,
                          lensStyle: {
                            background: "hsla(0, 0%, 100%, .3)",
                            border: "1px solid #ccc",
                          },
                          enlargedImageContainerDimensions: {
                            width: "110%",
                            height: "90%",
                          },
                          enlargedImageContainerStyle: {
                            marginTop: "5vw",
                            zIndex: 10,
                            background: "#fff",
                          },
                          enlargedImageStyle: {
                            objectFit: "contain",
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  height: "fit-content",
                  background: "white",
                  padding: "2vw",
                  width: width <= 700 && "94vw",
                  overflowX: "hidden",
                }}
                className="product-wrapper1 "
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ marginLeft: "0vw" }} className="tagblue">
                      {proddata?.product_id?.category?.category}
                    </div>
                    <span
                      style={{
                        color: "#0052cc",
                        fontSize: width > 700 ? "0.9vw" : "2.7vw",
                        marginLeft: "1vw",
                      }}
                    >
                      {proddata?.product_id?.type}
                    </span>
                  </div>
                  <div className="profiledetailnavmanu">
                    <div>
                      <CloseIcon
                        onClick={handleClose1}
                        style={{
                          fontSize: width > 700 ? "1.5vw" : "4vw",
                          cursor: "pointer",
                          position: "relative",
                          left: "4vw",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="Nameofproduct">
                  {proddata?.product_id?.productName}
                </div>
                <div className="headingofproduct">Description :</div>
                <div className="descriptionofproduct">
                  {proddata?.product_id?.description}
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="headingofproduct">Price :</div>
                    <div
                      style={{ margin: "0 0.5vw" }}
                      className="descriptionofproduct"
                    >
                      <span
                        style={{
                          fontSize: width > 700 ? "1.3vw" : "4vw",
                          color: "#0052cc",
                        }}
                      >
                        Rs {proddata?.product_id?.costPrice}
                      </span>{" "}
                      {proddata?.product_id?.sellingPrice && (
                        <del> Rs {proddata?.product_id?.sellingPrice}</del>
                      )}
                      {proddata?.product_id?.sellingPrice && (
                        <span style={{ color: "#0052cc", margin: "0 1vw" }}>
                          {" "}
                          {parseFloat(
                            ((proddata?.product_id?.sellingPrice -
                              proddata?.product_id?.costPrice) *
                              100) /
                              proddata?.product_id?.sellingPrice
                          ).toFixed(2)}
                          % off{" "}
                        </span>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      color: "#0052cc",
                      display: "flex",
                      alignItems: "center",
                      marginRight: "2vw",
                    }}
                  >
                    {proddata?.product_id?.rating?.$numberDecimal
                      ? proddata?.product_id?.rating?.$numberDecimal?.slice(
                          0,
                          1
                        )
                      : 0}
                    <StarIcon
                      style={{
                        width: width > 700 ? "1.2vw" : "4vw",
                        margin: "0vw",
                      }}
                    />{" "}
                    (
                    {proddata?.product_id?.completedOrder
                      ? proddata?.product_id?.completedOrder
                      : 0}
                    )
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="headingofproduct">
                      {" "}
                      {proddata?.product_id?.type === "Service"
                        ? "Type"
                        : "Stock"}{" "}
                      :{" "}
                    </div>{" "}
                    <div
                      style={{
                        color: proddata?.quantity > 0 ? "" : "red",
                        fontSize: width > 700 ? "1.1vw" : "3vw",
                        margin: "0 1vw",
                      }}
                      className="stockmenuweb"
                    >
                      {proddata?.product_id?.type === "Service" ? (
                        proddata?.product_id.serviceType
                      ) : (
                        <span>
                          {" " + proddata?.quantity > 0
                            ? " " + proddata?.quantity + " Items Left"
                            : "Out of Stock"}
                        </span>
                      )}
                    </div>
                  </div>

                  <div style={{ width: width > 700 ? "16vw" : "fit-content" }}>
                    {websitedata?.type === "Service" ? (
                      <div
                        onClick={() => {
                          const today = new Date();
                          const month = (today.getMonth() + 1)
                            .toString()
                            .padStart(2, "0");
                          const day = today
                            .getDate()
                            .toString()
                            .padStart(2, "0"); // Note: Months are zero-based, so we add 1
                          const year = today.getFullYear();

                          const tomorrow = new Date(today);
                          tomorrow.setDate(today.getDate() + 1);

                          const month1 = (tomorrow.getMonth() + 1)
                            .toString()
                            .padStart(2, "0");
                          const day1 = tomorrow
                            .getDate()
                            .toString()
                            .padStart(2, "0"); // Note: Months are zero-based, so we add 1
                          const year1 = tomorrow.getFullYear();

                          const currentHour = today.getHours();
                          const currentMinute = today.getMinutes();
                          const formattedHour =
                            currentHour < 10 ? `0${currentHour}` : currentHour;
                          const formattedMinute =
                            currentMinute < 10
                              ? `0${currentMinute}`
                              : currentMinute;

                          setArrayofservices([
                            ...arrayofservices,
                            {
                              web_id: proddata,
                              type: proddata?.product_id?.serviceType,
                              qnumbers: 1,
                              addOn: [],
                              fromDate: `${year}-${month}-${day}`,
                              fromHour: `${formattedHour}:${formattedMinute}`,
                              toDate: `${year1}-${month1}-${day1}`,
                              totalDays: 1,
                              totalHours: 1,
                              totalAddonPrice: 0,
                              totalGST:
                                (proddata?.product_id?.costPrice *
                                  proddata?.product_id?.gstRate *
                                  1) /
                                100,
                              totalPrice: proddata?.product_id?.costPrice * 1,
                            },
                          ]);
                        }}
                        className="Visitwebsitebutton"
                      >
                        Add to Cart
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          setArrayofProducts([
                            ...arrayofProducts,
                            {
                              web_id: proddata,
                              quantity: 1,
                              addOn: [],
                              totalAddOnPrices: 0,
                              calculatedGstPrice:
                                (proddata?.product_id?.costPrice *
                                  proddata?.product_id?.gstRate *
                                  1) /
                                100,
                              totalPrice: proddata?.product_id?.costPrice * 1,
                            },
                          ]);
                          handleClose1();
                        }}
                        style={{ width: width > 700 ? "13vw" : "fit-content" }}
                        className="Visitwebsitebutton"
                      >
                        Add to Cart
                      </div>
                    )}
                  </div>
                </div>

                {proddata?.product_id?.addOn?.length > 0 && (
                  <div className="headingofproduct">Add On :</div>
                )}

                {proddata?.product_id?.addOn?.map((data) => {
                  return (
                    <div className="addonbox">
                      <div className="addondata">
                        <div className="addonimg">
                          <img src={data.image} alt="" />
                        </div>
                        <div style={{ width: "70%" }} className="addonname">
                          {data.name}
                        </div>
                        <div style={{ width: "20%" }} className="addonname">
                          Rs {data.amount}
                        </div>
                      </div>
                      <div className="addondesc">{data?.description}</div>
                    </div>
                  );
                })}
                <div className="headingofproduct">Details :</div>
                <div
                  className=""
                  dangerouslySetInnerHTML={{
                    __html: proddata?.product_id?.longDescription,
                  }}
                ></div>
              </div>
            </div>
          </Box>
        </Modal>
      </div>

      {websitedata?.type === "Product" && arrayofProducts?.length > 0 && (
        <WebsiteCart
          width={width}
          websitedata={websitedata}
          arrayofProducts={arrayofProducts}
          setArrayofProducts={setArrayofProducts}
        />
      )}
      {websitedata?.type === "Service" && arrayofservices?.length > 0 && (
        <WebsiteCart2
          width={width}
          websitedata={websitedata}
          arrayofProducts={arrayofservices}
          setArrayofProducts={setArrayofservices}
        />
      )}

      {websitedata?.type === "Product" && arrayofProducts?.length > 0 && (
        <div
          style={{
            padding: width > 700 ? "1vw" : "2vw",
            boxShadow:
              "-7px -7px 15px #e9e9e9, 10px 10px 20px rgba(124, 124, 124, 0.4)",
            borderRadius: "1vw",
            marginBottom: width <= 700 && "5vw",
          }}
          className="websiteoffercontainer "
        >
          <div
            style={{ color: websitedata?.themeColor }}
            className="offerheading"
          >
            Buyer Detail
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: width <= 700 && "column-reverse",
            }}
          >
            <div className="orderdtailsinfoaddress">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    width: width > 700 ? "50%" : "100%",
                    margin: "0.3vw",
                  }}
                  className="offerheading"
                >
                  <label htmlFor="aaa">
                    <div>
                      Fullname
                      <span>
                        <img
                          style={{
                            fontSize: width > 700 ? "1.2vw" : "3.3vw",
                            width: width > 700 ? "1.32vw" : "3.3vw",
                            cursor: "pointer",
                          }}
                          src={img2}
                          alt=""
                        />
                      </span>
                    </div>
                    <input
                      value={fullname}
                      id="aaa"
                      className="websiteaddresdetailinput"
                      style={{
                        fontSize: width > 700 ? "1.05vw" : "3vw",
                        width: width > 700 ? "25vw" : "40vw",
                      }}
                      onChange={(e) => {
                        setfullname(e.target.value);
                      }}
                    ></input>
                  </label>
                </div>
                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    width: "50%",
                    margin: "0.3vw",
                  }}
                  className="offerheading"
                >
                  <label htmlFor="bbb">
                    <div>
                      Mobile{" "}
                      <span>
                        <img
                          style={{
                            fontSize: width > 700 ? "1.2vw" : "3.3vw",
                            width: width > 700 ? "1.32vw" : "3.3vw",
                            cursor: "pointer",
                          }}
                          src={img2}
                          alt=""
                        />
                      </span>
                    </div>
                    <input
                      value={mobile}
                      type="number"
                      id="bbb"
                      className="websiteaddresdetailinput"
                      style={{
                        fontSize: width > 700 ? "1.05vw" : "3vw",
                        width: width > 700 ? "25vw" : "40vw",
                      }}
                      onChange={(e) => {
                        setmobile(e.target.value);
                      }}
                    ></input>
                  </label>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    width: "50%",
                    margin: "0.3vw",
                  }}
                  className="offerheading"
                >
                  <label htmlFor="ccc">
                    <div>
                      Email Id{" "}
                      <span>
                        <img
                          style={{
                            fontSize: width > 700 ? "1.2vw" : "3.3vw",
                            width: width > 700 ? "1.32vw" : "3.3vw",
                            cursor: "pointer",
                          }}
                          src={img2}
                          alt=""
                        />
                      </span>
                    </div>
                    <input
                      className="websiteaddresdetailinput"
                      onChange={(e) => {
                        setemail(e.target.value);
                      }}
                      id="ccc"
                      value={email}
                      style={{
                        fontSize: width > 700 ? "1.05vw" : "3vw",
                        width: width > 700 ? "25vw" : "40vw",
                      }}
                    ></input>
                  </label>
                </div>
                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    width: "50%",
                    margin: "0.3vw",
                  }}
                  className="offerheading"
                >
                  <label htmlFor="ddd">
                    <div onClick={handleClickx2}>
                      State
                      <span>
                        <img
                          style={{
                            fontSize: width > 700 ? "1.2vw" : "3.3vw",
                            width: width > 700 ? "1.32vw" : "3.3vw",
                            cursor: "pointer",
                          }}
                          src={img2}
                          alt=""
                        />
                      </span>
                    </div>
                    <input
                      value={state}
                      id="ddd"
                      className="websiteaddresdetailinput"
                      style={{
                        fontSize: width > 700 ? "1.05vw" : "3vw",
                        width: width > 700 ? "25vw" : "40vw",
                      }}
                      disabled
                      onClick={handleClickx}
                    ></input>
                    <Popover
                      id={idx2}
                      open={openx2}
                      anchorEl={anchorElx2}
                      onClose={handleClosex2}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <div
                        style={{
                          maxHeight: width > 700 ? "15vw" : "40vw",
                          overflow: "scroll",
                          width: width > 700 ? "25.5vw" : "90vw",
                        }}
                        className="ScrollBarexist"
                      >
                        {arrayoflongdegree?.length > 0 &&
                          arrayoflongdegree.map((data, index) => {
                            return (
                              <Typography
                                sx={{
                                  p: 0.51,
                                  pl: 1,
                                  ml: 0,
                                  width: "100%",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setstate(data);
                                  handleClosex2();
                                }}
                              >
                                {data}
                              </Typography>
                            );
                          })}
                      </div>
                    </Popover>
                  </label>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    width: "50%",
                    margin: "0.3vw",
                  }}
                  className="offerheading"
                >
                  <label htmlFor="eee">
                    <div>
                      Pincode
                      <span>
                        <img
                          style={{
                            fontSize: width > 700 ? "1.2vw" : "3.3vw",
                            width: width > 700 ? "1.32vw" : "3.3vw",
                            cursor: "pointer",
                          }}
                          src={img2}
                          alt=""
                        />
                      </span>
                    </div>
                    <input
                      value={pincode}
                      type="number"
                      id="eee"
                      className="websiteaddresdetailinput"
                      style={{
                        fontSize: width > 700 ? "1.05vw" : "3vw",
                        width: width > 700 ? "23vw" : "40vw",
                        marginRight: "2vw",
                      }}
                      onChange={(e) => {
                        setpincode(e.target.value);
                      }}
                    ></input>
                  </label>
                </div>

                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    width: "50%",
                    margin: "0.3vw",
                  }}
                  className="offerheading"
                >
                  <label htmlFor="fff">
                    <div>
                      GST No
                      <span>
                        <img
                          style={{
                            fontSize: width > 700 ? "1.2vw" : "3.3vw",
                            width: width > 700 ? "1.32vw" : "3.3vw",
                            cursor: "pointer",
                          }}
                          src={img2}
                          alt=""
                        />
                      </span>
                    </div>
                    <input
                      className="websiteaddresdetailinput"
                      onChange={(e) => {
                        setgst(e.target.value);
                      }}
                      id="fff"
                      value={gst}
                      style={{
                        fontSize: width > 700 ? "1.05vw" : "3vw",
                        width: width > 700 ? "25vw" : "40vw",
                      }}
                    ></input>
                  </label>
                </div>
              </div>
              <div
                style={{
                  fontSize: width > 700 ? "1.2vw" : "3.3vw",
                  margin: "0.3vw",
                }}
                className="offerheading"
              >
                <label htmlFor="ggg">
                  <div style={{ width: "100%" }}>
                    Address{" "}
                    <span>
                      <img
                        style={{
                          fontSize: width > 700 ? "1.2vw" : "3.3vw",
                          width: width > 700 ? "1.32vw" : "3.3vw",
                          cursor: "pointer",
                        }}
                        src={img2}
                        alt=""
                      />
                    </span>
                  </div>
                  <textarea
                    id="ggg"
                    className="websiteaddresdetailinput"
                    value={adress}
                    onChange={(e) => {
                      setAdress(e.target?.value);
                    }}
                    rows={2}
                    style={{
                      width: width > 700 ? "50vw" : "90vw",
                      fontSize: width > 700 ? "" : "3vw",
                      lineHeight: width > 700 ? "1.3vw" : "3.5vw",
                    }}
                  ></textarea>
                </label>
              </div>

              {websitedata?.wantDocuments && (
                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    margin: "0.3vw",
                  }}
                  className="offerheading"
                >
                  <div>
                    <div>Add Documents</div>

                    <label htmlFor="ff">
                      <span>
                        <div
                          style={{
                            background: "white",
                            border: `2px solid ${websitedata?.themeColor}`,
                            color: websitedata?.themeColor,
                            cursor: "pointer",
                          }}
                          className="Websiteshopingbutton"
                        >
                          Upload
                        </div>

                        <input
                          id="ff"
                          hidden
                          type="file"
                          onChange={(e) => {
                            handleImageUpload(e);
                          }}
                        />
                      </span>
                    </label>
                  </div>
                  <div
                    className={
                      arrayoffiles?.length > 0 ? "inputfilesshowncatebox" : ""
                    }
                    style={{ marginTop: "0.5vw" }}
                  >
                    {arrayoffiles?.length > 0 &&
                      arrayoffiles?.map((file, index) => {
                        return (
                          <div className="inputfilesshowncatboxsingle">
                            <div className="inputfilesshowncatboxsingleimg">
                              <img src={img1} alt="" />
                            </div>
                            <div
                              style={{ lineHeight: "1.3vw" }}
                              className="fileselctednamecate"
                            >
                              {Cartsaved
                                ? file?.file?.split("amazonaws.com/")[1]
                                : file?.name}
                            </div>
                            <div className="inputfilesshowncatboxsingleimg">
                              <img
                                style={{
                                  width: width > 700 ? "1.5vw" : "4.5vw",
                                  marginLeft: "1vw",
                                  cursor: "pointer",
                                }}
                                src={img21}
                                alt=""
                                onClick={() => {
                                  setArrayoffiles([
                                    ...arrayoffiles.slice(0, index),
                                    ...arrayoffiles.slice(
                                      index + 1,
                                      arrayoffiles.length
                                    ),
                                  ]);
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}

              <div
                style={{
                  fontSize: width > 700 ? "1vw" : "3vw",
                  width: "50%",
                  margin: "0.3vw",
                  color: "red",
                }}
              >
                {errormobile}
              </div>
            </div>

            <div className="orderdtailsinfopayment">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    margin: "0.3vw",
                  }}
                  className="offerheading"
                >
                  Total Taxable Price :
                </div>
                <div style={{ fontSize: width > 700 ? "1.1vw" : "3vw" }}>
                  {" "}
                  Rs {parseFloat(totalparice).toFixed(2)}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    margin: "0.3vw",
                  }}
                  className="offerheading"
                >
                  Total Tax ( GST ) :
                </div>
                <div style={{ fontSize: width > 700 ? "1.1vw" : "3vw" }}>
                  {" "}
                  Rs {parseFloat(totalgst).toFixed(2)}
                </div>
              </div>
              {
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      fontSize: width > 700 ? "1.2vw" : "3.3vw",
                      margin: "0.3vw",
                    }}
                    className="offerheading"
                  >
                    {websitedata?.chargeName && websitedata?.chargeName + ":"}
                  </div>
                  <div style={{ fontSize: width > 700 ? "1.1vw" : "3vw" }}>
                    {" "}
                    {websitedata?.amount !== "0" &&
                      "Rs" + parseFloat(websitedata?.amount).toFixed(2)}
                  </div>
                </div>
              }
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    margin: "0.3vw",
                  }}
                  className="offerheading"
                >
                  Grand Total :
                </div>
                <div style={{ fontSize: width > 700 ? "1.1vw" : "3vw" }}>
                  {" "}
                  Rs {parseFloat(grandTotal).toFixed(2)}
                </div>
              </div>
              {user?.userName !== websitedata?.userId?.userName && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    onClick={() => {
                      user?.userName ? handlesavecart() : handleStart();
                    }}
                    style={{ fontSize: width > 700 ? "1.2vw" : "3.3vw" }}
                    className="offerheading"
                  >
                    <button
                      style={{
                        background: !savedcartdatta
                          ? "white"
                          : websitedata?.themeColor,
                        border:
                          !savedcartdatta &&
                          `2px solid ${websitedata?.themeColor}`,
                        color: !savedcartdatta && websitedata?.themeColor,
                      }}
                      className="Websiteshopingbutton"
                    >
                      {!savedcartdatta ? "Save Your Cart" : "saved"}
                    </button>
                  </div>
                  <div
                    style={{ fontSize: width > 700 ? "1.2vw" : "3.3vw" }}
                    className="offerheading"
                  >
                    <button
                      style={{ background: websitedata?.themeColor }}
                      className="Websiteshopingbutton"
                      onClick={() => {
                        user?.userName ? handleorder() : handleStart();
                      }}
                    >
                      {websitedata?.buttonName}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            style={{ marginTop: "0.5vw" }}
            className="addmoreservicecatalog"
            onClick={() => {
              setShowdeleverydetails(!showdeleverydetails);
            }}
          >
            {showdeleverydetails ? "Hide" : "Show"} More Offers
          </div>
          {showdeleverydetails && (
            <div>
              <div
                style={{ color: websitedata?.themeColor }}
                className="offerheading"
              >
                Delivery Detail <span style={{ marginLeft: "2vw" }}></span>
                <label htmlFor="checkbox">
                  <input
                    type="checkbox"
                    style={{ cursor: "pointer" }}
                    onChange={() => {
                      setSameasabove(!Sameasabove);
                    }}
                    name=""
                    checked={Sameasabove}
                    id="checkbox"
                  />

                  <div
                    className=""
                    style={{
                      fontSize: width > 700 ? "1.1vw" : "3vw",
                      fontFamily: "Poppins",
                      fontStyle: "500",
                      fontWeight: "500",
                      color: "#000000",
                      display: "inline",
                      cursor: "pointer",
                      margin: "0 1vw",
                    }}
                  >
                    Delivery details will Same as Upper buyer Details
                  </div>
                </label>
              </div>

              {!Sameasabove && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: width <= 700 && "column-reverse",
                  }}
                >
                  <div className="orderdtailsinfoaddress">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          fontSize: width > 700 ? "1.2vw" : "3.3vw",
                          width: width > 700 ? "50%" : "100%",
                          margin: "0.3vw",
                        }}
                        className="offerheading"
                      >
                        <label htmlFor="aaaa">
                          <div>
                            Fullname
                            <span>
                              <img
                                style={{
                                  fontSize: width > 700 ? "1.2vw" : "3.3vw",
                                  width: width > 700 ? "1.32vw" : "3.3vw",
                                  cursor: "pointer",
                                }}
                                src={img2}
                                alt=""
                              />
                            </span>
                          </div>
                          <input
                            value={fullname1}
                            id="aaaa"
                            className="websiteaddresdetailinput"
                            style={{
                              fontSize: width > 700 ? "1.05vw" : "3vw",
                              width: width > 700 ? "25vw" : "40vw",
                            }}
                            onChange={(e) => {
                              setfullname1(e.target.value);
                            }}
                          ></input>
                        </label>
                      </div>
                      <div
                        style={{
                          fontSize: width > 700 ? "1.2vw" : "3.3vw",
                          width: "50%",
                          margin: "0.3vw",
                        }}
                        className="offerheading"
                      >
                        <label htmlFor="bbba">
                          <div>
                            Mobile{" "}
                            <span>
                              <img
                                style={{
                                  fontSize: width > 700 ? "1.2vw" : "3.3vw",
                                  width: width > 700 ? "1.32vw" : "3.3vw",
                                  cursor: "pointer",
                                }}
                                src={img2}
                                alt=""
                              />
                            </span>
                          </div>
                          <input
                            value={mobile1}
                            type="number"
                            id="bbba"
                            className="websiteaddresdetailinput"
                            style={{
                              fontSize: width > 700 ? "1.05vw" : "3vw",
                              width: width > 700 ? "25vw" : "40vw",
                            }}
                            onChange={(e) => {
                              setmobile1(e.target.value);
                            }}
                          ></input>
                        </label>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          fontSize: width > 700 ? "1.2vw" : "3.3vw",
                          width: "50%",
                          margin: "0.3vw",
                        }}
                        className="offerheading"
                      >
                        <label htmlFor="ccca">
                          <div>
                            Email Id{" "}
                            <span>
                              <img
                                style={{
                                  fontSize: width > 700 ? "1.2vw" : "3.3vw",
                                  width: width > 700 ? "1.32vw" : "3.3vw",
                                  cursor: "pointer",
                                }}
                                src={img2}
                                alt=""
                              />
                            </span>
                          </div>
                          <input
                            className="websiteaddresdetailinput"
                            onChange={(e) => {
                              setemail1(e.target.value);
                            }}
                            id="ccca"
                            value={email1}
                            style={{
                              fontSize: width > 700 ? "1.05vw" : "3vw",
                              width: width > 700 ? "25vw" : "40vw",
                            }}
                          ></input>
                        </label>
                      </div>
                      <div
                        style={{
                          fontSize: width > 700 ? "1.2vw" : "3.3vw",
                          width: "50%",
                          margin: "0.3vw",
                        }}
                        className="offerheading"
                      >
                        <label htmlFor="ddda">
                          <div
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={handleClickx}
                          >
                            State
                            <span>
                              <img
                                style={{
                                  fontSize: width > 700 ? "1.2vw" : "3.3vw",
                                  width: width > 700 ? "1.32vw" : "3.3vw",
                                  cursor: "pointer",
                                }}
                                src={img2}
                                alt=""
                              />
                            </span>
                          </div>
                          <input
                            value={state1}
                            id="ddda"
                            disabled
                            onClick={handleClickx}
                            className="websiteaddresdetailinput"
                            style={{
                              fontSize: width > 700 ? "1.05vw" : "3vw",
                              width: width > 700 ? "25vw" : "40vw",
                            }}
                          ></input>
                          <Popover
                            id={idx}
                            open={openx}
                            anchorEl={anchorElx}
                            onClose={handleClosex}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                          >
                            <div
                              style={{
                                maxHeight: width > 700 ? "15vw" : "40vw",
                                overflow: "scroll",
                                width: width > 700 ? "25.5vw" : "90vw",
                              }}
                              className="ScrollBarexist"
                            >
                              {arrayoflongdegree?.length > 0 &&
                                arrayoflongdegree.map((data, index) => {
                                  return (
                                    <Typography
                                      sx={{
                                        p: 0.51,
                                        pl: 1,
                                        ml: 0,
                                        width: "100%",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setstate1(data);
                                        handleClosex();
                                      }}
                                    >
                                      {data}
                                    </Typography>
                                  );
                                })}
                            </div>
                          </Popover>
                        </label>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          fontSize: width > 700 ? "1.2vw" : "3.3vw",
                          width: "50%",
                          margin: "0.3vw",
                        }}
                        className="offerheading"
                      >
                        <label htmlFor="eeea">
                          <div>
                            Pincode
                            <span>
                              <img
                                style={{
                                  fontSize: width > 700 ? "1.2vw" : "3.3vw",
                                  width: width > 700 ? "1.32vw" : "3.3vw",
                                  cursor: "pointer",
                                }}
                                src={img2}
                                alt=""
                              />
                            </span>
                          </div>
                          <input
                            value={pincode1}
                            type="number"
                            id="eeea"
                            className="websiteaddresdetailinput"
                            style={{
                              fontSize: width > 700 ? "1.05vw" : "3vw",
                              width: width > 700 ? "23vw" : "40vw",
                              marginRight: "2vw",
                            }}
                            onChange={(e) => {
                              setpincode1(e.target.value);
                            }}
                          ></input>
                        </label>
                      </div>

                      <div
                        style={{
                          fontSize: width > 700 ? "1.2vw" : "3.3vw",
                          width: "50%",
                          margin: "0.3vw",
                        }}
                        className="offerheading"
                      >
                        <label htmlFor="fffa">
                          <div>
                            GST No
                            <span>
                              <img
                                style={{
                                  fontSize: width > 700 ? "1.2vw" : "3.3vw",
                                  width: width > 700 ? "1.32vw" : "3.3vw",
                                  cursor: "pointer",
                                }}
                                src={img2}
                                alt=""
                              />
                            </span>
                          </div>
                          <input
                            className="websiteaddresdetailinput"
                            onChange={(e) => {
                              setgst1(e.target.value);
                            }}
                            id="fffa"
                            value={gst1}
                            style={{
                              fontSize: width > 700 ? "1.05vw" : "3vw",
                              width: width > 700 ? "25vw" : "40vw",
                            }}
                          ></input>
                        </label>
                      </div>
                    </div>

                    <div
                      style={{
                        fontSize: width > 700 ? "1.2vw" : "3.3vw",
                        margin: "0.3vw",
                      }}
                      className="offerheading"
                    >
                      <label htmlFor="ggga">
                        <div style={{ width: "100%" }}>
                          Address{" "}
                          <span>
                            <img
                              style={{
                                fontSize: width > 700 ? "1.2vw" : "3.3vw",
                                width: width > 700 ? "1.32vw" : "3.3vw",
                                cursor: "pointer",
                              }}
                              src={img2}
                              alt=""
                            />
                          </span>
                        </div>
                        <textarea
                          id="ggga"
                          className="websiteaddresdetailinput"
                          value={adress1}
                          onChange={(e) => {
                            setAdress1(e.target?.value);
                          }}
                          rows={2}
                          style={{
                            width: width > 700 ? "50vw" : "90vw",
                            fontSize: width > 700 ? "" : "3vw",
                            lineHeight: width > 700 ? "1.3vw" : "3.5vw",
                          }}
                        ></textarea>
                      </label>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open121}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
      {websitedata?.type === "Service" && arrayofservices?.length > 0 && (
        <div
          style={{
            padding: width > 700 ? "1vw" : "2vw",
            boxShadow:
              "-7px -7px 15px #e9e9e9, 10px 10px 20px rgba(124, 124, 124, 0.4)",
            borderRadius: "1vw",
            marginBottom: width <= 700 && "5vw",
          }}
          className="websiteoffercontainer "
        >
          <div
            style={{ color: websitedata?.themeColor }}
            className="offerheading"
          >
            Buyer Detail
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: width <= 700 && "column-reverse",
            }}
          >
            <div className="orderdtailsinfoaddress">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    width: width > 700 ? "50%" : "100%",
                    margin: "0.3vw",
                  }}
                  className="offerheading"
                >
                  <label htmlFor="aaa">
                    <div>
                      Fullname
                      <span>
                        <img
                          style={{
                            fontSize: width > 700 ? "1.2vw" : "3.3vw",
                            width: width > 700 ? "1.32vw" : "3.3vw",
                            cursor: "pointer",
                          }}
                          src={img2}
                          alt=""
                        />
                      </span>
                    </div>
                    <input
                      value={fullname}
                      id="aaa"
                      className="websiteaddresdetailinput"
                      style={{
                        fontSize: width > 700 ? "1.05vw" : "3vw",
                        width: width > 700 ? "25vw" : "40vw",
                      }}
                      onChange={(e) => {
                        setfullname(e.target.value);
                      }}
                    ></input>
                  </label>
                </div>
                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    width: "50%",
                    margin: "0.3vw",
                  }}
                  className="offerheading"
                >
                  <label htmlFor="bbb">
                    <div>
                      Mobile{" "}
                      <span>
                        <img
                          style={{
                            fontSize: width > 700 ? "1.2vw" : "3.3vw",
                            width: width > 700 ? "1.32vw" : "3.3vw",
                            cursor: "pointer",
                          }}
                          src={img2}
                          alt=""
                        />
                      </span>
                    </div>
                    <input
                      value={mobile}
                      type="number"
                      id="bbb"
                      className="websiteaddresdetailinput"
                      style={{
                        fontSize: width > 700 ? "1.05vw" : "3vw",
                        width: width > 700 ? "25vw" : "40vw",
                      }}
                      onChange={(e) => {
                        setmobile(e.target.value);
                      }}
                    ></input>
                  </label>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    width: "50%",
                    margin: "0.3vw",
                  }}
                  className="offerheading"
                >
                  <label htmlFor="ccc">
                    <div>
                      Email Id{" "}
                      <span>
                        <img
                          style={{
                            fontSize: width > 700 ? "1.2vw" : "3.3vw",
                            width: width > 700 ? "1.32vw" : "3.3vw",
                            cursor: "pointer",
                          }}
                          src={img2}
                          alt=""
                        />
                      </span>
                    </div>
                    <input
                      className="websiteaddresdetailinput"
                      onChange={(e) => {
                        setemail(e.target.value);
                      }}
                      id="ccc"
                      value={email}
                      style={{
                        fontSize: width > 700 ? "1.05vw" : "3vw",
                        width: width > 700 ? "25vw" : "40vw",
                      }}
                    ></input>
                  </label>
                </div>
                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    width: "50%",
                    margin: "0.3vw",
                  }}
                  className="offerheading"
                >
                  <label htmlFor="ddd">
                    <div onClick={handleClickx2}>
                      State
                      <span>
                        <img
                          style={{
                            fontSize: width > 700 ? "1.2vw" : "3.3vw",
                            width: width > 700 ? "1.32vw" : "3.3vw",
                            cursor: "pointer",
                          }}
                          src={img2}
                          alt=""
                        />
                      </span>
                    </div>
                    <input
                      value={state}
                      id="ddd"
                      className="websiteaddresdetailinput"
                      style={{
                        fontSize: width > 700 ? "1.05vw" : "3vw",
                        width: width > 700 ? "25vw" : "40vw",
                      }}
                      disabled
                      onClick={handleClickx}
                    ></input>
                    <Popover
                      id={idx2}
                      open={openx2}
                      anchorEl={anchorElx2}
                      onClose={handleClosex2}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <div
                        style={{
                          maxHeight: width > 700 ? "15vw" : "40vw",
                          overflow: "scroll",
                          width: width > 700 ? "25.5vw" : "90vw",
                        }}
                        className="ScrollBarexist"
                      >
                        {arrayoflongdegree?.length > 0 &&
                          arrayoflongdegree.map((data, index) => {
                            return (
                              <Typography
                                sx={{
                                  p: 0.51,
                                  pl: 1,
                                  ml: 0,
                                  width: "100%",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setstate(data);
                                  handleClosex2();
                                }}
                              >
                                {data}
                              </Typography>
                            );
                          })}
                      </div>
                    </Popover>
                  </label>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    width: "50%",
                    margin: "0.3vw",
                  }}
                  className="offerheading"
                >
                  <label htmlFor="eee">
                    <div>
                      Pincode
                      <span>
                        <img
                          style={{
                            fontSize: width > 700 ? "1.2vw" : "3.3vw",
                            width: width > 700 ? "1.32vw" : "3.3vw",
                            cursor: "pointer",
                          }}
                          src={img2}
                          alt=""
                        />
                      </span>
                    </div>
                    <input
                      value={pincode}
                      type="number"
                      id="eee"
                      className="websiteaddresdetailinput"
                      style={{
                        fontSize: width > 700 ? "1.05vw" : "3vw",
                        width: width > 700 ? "23vw" : "40vw",
                        marginRight: "2vw",
                      }}
                      onChange={(e) => {
                        setpincode(e.target.value);
                      }}
                    ></input>
                  </label>
                </div>

                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    width: "50%",
                    margin: "0.3vw",
                  }}
                  className="offerheading"
                >
                  <label htmlFor="fff">
                    <div>
                      GST No
                      <span>
                        <img
                          style={{
                            fontSize: width > 700 ? "1.2vw" : "3.3vw",
                            width: width > 700 ? "1.32vw" : "3.3vw",
                            cursor: "pointer",
                          }}
                          src={img2}
                          alt=""
                        />
                      </span>
                    </div>
                    <input
                      className="websiteaddresdetailinput"
                      onChange={(e) => {
                        setgst(e.target.value);
                      }}
                      id="fff"
                      value={gst}
                      style={{
                        fontSize: width > 700 ? "1.05vw" : "3vw",
                        width: width > 700 ? "25vw" : "40vw",
                      }}
                    ></input>
                  </label>
                </div>
              </div>
              <div
                style={{
                  fontSize: width > 700 ? "1.2vw" : "3.3vw",
                  margin: "0.3vw",
                }}
                className="offerheading"
              >
                <label htmlFor="ggg">
                  <div style={{ width: "100%" }}>
                    Address{" "}
                    <span>
                      <img
                        style={{
                          fontSize: width > 700 ? "1.2vw" : "3.3vw",
                          width: width > 700 ? "1.32vw" : "3.3vw",
                          cursor: "pointer",
                        }}
                        src={img2}
                        alt=""
                      />
                    </span>
                  </div>
                  <textarea
                    id="ggg"
                    className="websiteaddresdetailinput"
                    value={adress}
                    onChange={(e) => {
                      setAdress(e.target?.value);
                    }}
                    rows={2}
                    style={{
                      width: width > 700 ? "50vw" : "90vw",
                      fontSize: width > 700 ? "" : "3vw",
                      lineHeight: width > 700 ? "1.3vw" : "3.5vw",
                    }}
                  ></textarea>
                </label>
              </div>

              {websitedata?.wantDocuments && (
                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    margin: "0.3vw",
                  }}
                  className="offerheading"
                >
                  <div>
                    <div>Add Documents</div>

                    <label htmlFor="ff">
                      <span>
                        <div
                          style={{
                            background: "white",
                            border: `2px solid ${websitedata?.themeColor}`,
                            color: websitedata?.themeColor,
                            cursor: "pointer",
                          }}
                          className="Websiteshopingbutton"
                        >
                          Upload
                        </div>

                        <input
                          id="ff"
                          hidden
                          type="file"
                          onChange={(e) => {
                            handleImageUpload(e);
                          }}
                        />
                      </span>
                    </label>
                  </div>
                  <div
                    className={
                      arrayoffiles?.length > 0 ? "inputfilesshowncatebox" : ""
                    }
                    style={{ marginTop: "0.5vw" }}
                  >
                    {arrayoffiles?.length > 0 &&
                      arrayoffiles?.map((file, index) => {
                        return (
                          <div className="inputfilesshowncatboxsingle">
                            <div className="inputfilesshowncatboxsingleimg">
                              <img src={img1} alt="" />
                            </div>
                            <div
                              style={{ lineHeight: "1.3vw" }}
                              className="fileselctednamecate"
                            >
                              {Cartsaved
                                ? file?.file?.split("amazonaws.com/")[1]
                                : file?.name}
                            </div>
                            <div className="inputfilesshowncatboxsingleimg">
                              <img
                                style={{
                                  width: width > 700 ? "1.5vw" : "4.5vw",
                                  marginLeft: "1vw",
                                  cursor: "pointer",
                                }}
                                src={img21}
                                alt=""
                                onClick={() => {
                                  setArrayoffiles([
                                    ...arrayoffiles.slice(0, index),
                                    ...arrayoffiles.slice(
                                      index + 1,
                                      arrayoffiles.length
                                    ),
                                  ]);
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}

              <div
                style={{
                  fontSize: width > 700 ? "1vw" : "3vw",
                  width: "50%",
                  margin: "0.3vw",
                  color: "red",
                }}
              >
                {errormobile}
              </div>
            </div>

            <div className="orderdtailsinfopayment">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    margin: "0.3vw",
                  }}
                  className="offerheading"
                >
                  Total Taxable Price :
                </div>
                <div style={{ fontSize: width > 700 ? "1.1vw" : "3vw" }}>
                  {" "}
                  Rs {parseFloat(totalparice).toFixed(2)}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    margin: "0.3vw",
                  }}
                  className="offerheading"
                >
                  Total Tax ( GST ) :
                </div>
                <div style={{ fontSize: width > 700 ? "1.1vw" : "3vw" }}>
                  {" "}
                  Rs {parseFloat(totalgst).toFixed(2)}
                </div>
              </div>
              {
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      fontSize: width > 700 ? "1.2vw" : "3.3vw",
                      margin: "0.3vw",
                    }}
                    className="offerheading"
                  >
                    {websitedata?.chargeName && websitedata?.chargeName + ":"}
                  </div>
                  <div style={{ fontSize: width > 700 ? "1.1vw" : "3vw" }}>
                    {" "}
                    {websitedata?.amount !== "0" &&
                      "Rs" + parseFloat(websitedata?.amount).toFixed(2)}
                  </div>
                </div>
              }
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    margin: "0.3vw",
                  }}
                  className="offerheading"
                >
                  Grand Total :
                </div>
                <div style={{ fontSize: width > 700 ? "1.1vw" : "3vw" }}>
                  {" "}
                  Rs {parseFloat(grandTotal).toFixed(2)}
                </div>
              </div>
              {user?.userName !== websitedata?.userId?.userName && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    onClick={() => {
                      user?.userName ? handlesavecart1() : handleStart();
                    }}
                    style={{ fontSize: width > 700 ? "1.2vw" : "3.3vw" }}
                    className="offerheading"
                  >
                    <button
                      style={{
                        background: !savedcartdatta
                          ? "white"
                          : websitedata?.themeColor,
                        border:
                          !savedcartdatta &&
                          `2px solid ${websitedata?.themeColor}`,
                        color: !savedcartdatta && websitedata?.themeColor,
                      }}
                      className="Websiteshopingbutton"
                    >
                      {!savedcartdatta ? "Save Your Cart" : "saved"}
                    </button>
                  </div>
                  <div
                    style={{ fontSize: width > 700 ? "1.2vw" : "3.3vw" }}
                    className="offerheading"
                  >
                    <button
                      style={{ background: websitedata?.themeColor }}
                      className="Websiteshopingbutton"
                      onClick={() => {
                        user?.userName ? handleorder1() : handleStart();
                      }}
                    >
                      {websitedata?.buttonName}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            style={{ marginTop: "0.5vw" }}
            className="addmoreservicecatalog"
            onClick={() => {
              setShowdeleverydetails(!showdeleverydetails);
            }}
          >
            <input
              type="checkbox"
              style={{ cursor: "pointer" }}
              name=""
              checked={showdeleverydetails}
              id="checkbox"
            />{" "}
            {showdeleverydetails ? "Hide" : "Show"} Delivery Detail
          </div>
          {showdeleverydetails && (
            <div>
              <div
                style={{ color: websitedata?.themeColor }}
                className="offerheading"
              >
                Delivery Detail <span style={{ marginLeft: "2vw" }}></span>
                <label htmlFor="checkbox">
                  <input
                    type="checkbox"
                    style={{ cursor: "pointer" }}
                    onChange={() => {
                      setSameasabove(!Sameasabove);
                    }}
                    name=""
                    checked={Sameasabove}
                    id="checkbox"
                  />

                  <div
                    className=""
                    style={{
                      fontSize: width > 700 ? "1.1vw" : "3vw",
                      fontFamily: "Poppins",
                      fontStyle: "500",
                      fontWeight: "500",
                      color: "#000000",
                      display: "inline",
                      cursor: "pointer",
                      margin: "0 1vw",
                    }}
                  >
                    Delivery details will Same as Upper buyer Details
                  </div>
                </label>
              </div>

              {!Sameasabove && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: width <= 700 && "column-reverse",
                  }}
                >
                  <div className="orderdtailsinfoaddress">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          fontSize: width > 700 ? "1.2vw" : "3.3vw",
                          width: width > 700 ? "50%" : "100%",
                          margin: "0.3vw",
                        }}
                        className="offerheading"
                      >
                        <label htmlFor="aaaa">
                          <div>
                            Fullname
                            <span>
                              <img
                                style={{
                                  fontSize: width > 700 ? "1.2vw" : "3.3vw",
                                  width: width > 700 ? "1.32vw" : "3.3vw",
                                  cursor: "pointer",
                                }}
                                src={img2}
                                alt=""
                              />
                            </span>
                          </div>
                          <input
                            value={fullname1}
                            id="aaaa"
                            className="websiteaddresdetailinput"
                            style={{
                              fontSize: width > 700 ? "1.05vw" : "3vw",
                              width: width > 700 ? "25vw" : "40vw",
                            }}
                            onChange={(e) => {
                              setfullname1(e.target.value);
                            }}
                          ></input>
                        </label>
                      </div>
                      <div
                        style={{
                          fontSize: width > 700 ? "1.2vw" : "3.3vw",
                          width: "50%",
                          margin: "0.3vw",
                        }}
                        className="offerheading"
                      >
                        <label htmlFor="bbba">
                          <div>
                            Mobile{" "}
                            <span>
                              <img
                                style={{
                                  fontSize: width > 700 ? "1.2vw" : "3.3vw",
                                  width: width > 700 ? "1.32vw" : "3.3vw",
                                  cursor: "pointer",
                                }}
                                src={img2}
                                alt=""
                              />
                            </span>
                          </div>
                          <input
                            value={mobile1}
                            type="number"
                            id="bbba"
                            className="websiteaddresdetailinput"
                            style={{
                              fontSize: width > 700 ? "1.05vw" : "3vw",
                              width: width > 700 ? "25vw" : "40vw",
                            }}
                            onChange={(e) => {
                              setmobile1(e.target.value);
                            }}
                          ></input>
                        </label>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          fontSize: width > 700 ? "1.2vw" : "3.3vw",
                          width: "50%",
                          margin: "0.3vw",
                        }}
                        className="offerheading"
                      >
                        <label htmlFor="ccca">
                          <div>
                            Email Id{" "}
                            <span>
                              <img
                                style={{
                                  fontSize: width > 700 ? "1.2vw" : "3.3vw",
                                  width: width > 700 ? "1.32vw" : "3.3vw",
                                  cursor: "pointer",
                                }}
                                src={img2}
                                alt=""
                              />
                            </span>
                          </div>
                          <input
                            className="websiteaddresdetailinput"
                            onChange={(e) => {
                              setemail1(e.target.value);
                            }}
                            id="ccca"
                            value={email1}
                            style={{
                              fontSize: width > 700 ? "1.05vw" : "3vw",
                              width: width > 700 ? "25vw" : "40vw",
                            }}
                          ></input>
                        </label>
                      </div>
                      <div
                        style={{
                          fontSize: width > 700 ? "1.2vw" : "3.3vw",
                          width: "50%",
                          margin: "0.3vw",
                        }}
                        className="offerheading"
                      >
                        <label htmlFor="ddda">
                          <div onClick={handleClickx}>
                            State
                            <span>
                              <img
                                style={{
                                  fontSize: width > 700 ? "1.2vw" : "3.3vw",
                                  width: width > 700 ? "1.32vw" : "3.3vw",
                                  cursor: "pointer",
                                }}
                                src={img2}
                                alt=""
                              />
                            </span>
                          </div>
                          <input
                            value={state1}
                            id="ddda"
                            className="websiteaddresdetailinput"
                            style={{
                              fontSize: width > 700 ? "1.05vw" : "3vw",
                              width: width > 700 ? "25vw" : "40vw",
                            }}
                            disabled
                            onClick={handleClickx}
                          ></input>
                          <Popover
                            id={idx}
                            open={openx}
                            anchorEl={anchorElx}
                            onClose={handleClosex}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                          >
                            <div
                              style={{
                                maxHeight: width > 700 ? "15vw" : "40vw",
                                overflow: "scroll",
                                width: width > 700 ? "25.5vw" : "90vw",
                              }}
                              className="ScrollBarexist"
                            >
                              {arrayoflongdegree?.length > 0 &&
                                arrayoflongdegree.map((data, index) => {
                                  return (
                                    <Typography
                                      sx={{
                                        p: 0.51,
                                        pl: 1,
                                        ml: 0,
                                        width: "100%",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setstate1(data);
                                        handleClosex();
                                      }}
                                    >
                                      {data}
                                    </Typography>
                                  );
                                })}
                            </div>
                          </Popover>
                        </label>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          fontSize: width > 700 ? "1.2vw" : "3.3vw",
                          width: "50%",
                          margin: "0.3vw",
                        }}
                        className="offerheading"
                      >
                        <label htmlFor="eeea">
                          <div>
                            Pincode
                            <span>
                              <img
                                style={{
                                  fontSize: width > 700 ? "1.2vw" : "3.3vw",
                                  width: width > 700 ? "1.32vw" : "3.3vw",
                                  cursor: "pointer",
                                }}
                                src={img2}
                                alt=""
                              />
                            </span>
                          </div>
                          <input
                            value={pincode1}
                            type="number"
                            id="eeea"
                            className="websiteaddresdetailinput"
                            style={{
                              fontSize: width > 700 ? "1.05vw" : "3vw",
                              width: width > 700 ? "23vw" : "40vw",
                              marginRight: "2vw",
                            }}
                            onChange={(e) => {
                              setpincode1(e.target.value);
                            }}
                          ></input>
                        </label>
                      </div>

                      <div
                        style={{
                          fontSize: width > 700 ? "1.2vw" : "3.3vw",
                          width: "50%",
                          margin: "0.3vw",
                        }}
                        className="offerheading"
                      >
                        <label htmlFor="fffa">
                          <div>
                            GST No
                            <span>
                              <img
                                style={{
                                  fontSize: width > 700 ? "1.2vw" : "3.3vw",
                                  width: width > 700 ? "1.32vw" : "3.3vw",
                                  cursor: "pointer",
                                }}
                                src={img2}
                                alt=""
                              />
                            </span>
                          </div>
                          <input
                            className="websiteaddresdetailinput"
                            onChange={(e) => {
                              setgst1(e.target.value);
                            }}
                            id="fffa"
                            value={gst1}
                            style={{
                              fontSize: width > 700 ? "1.05vw" : "3vw",
                              width: width > 700 ? "25vw" : "40vw",
                            }}
                          ></input>
                        </label>
                      </div>
                    </div>

                    <div
                      style={{
                        fontSize: width > 700 ? "1.2vw" : "3.3vw",
                        margin: "0.3vw",
                      }}
                      className="offerheading"
                    >
                      <label htmlFor="ggga">
                        <div style={{ width: "100%" }}>
                          Address{" "}
                          <span>
                            <img
                              style={{
                                fontSize: width > 700 ? "1.2vw" : "3.3vw",
                                width: width > 700 ? "1.32vw" : "3.3vw",
                                cursor: "pointer",
                              }}
                              src={img2}
                              alt=""
                            />
                          </span>
                        </div>
                        <textarea
                          id="ggga"
                          className="websiteaddresdetailinput"
                          value={adress1}
                          onChange={(e) => {
                            setAdress1(e.target?.value);
                          }}
                          rows={2}
                          style={{
                            width: width > 700 ? "50vw" : "90vw",
                            fontSize: width > 700 ? "" : "3vw",
                            lineHeight: width > 700 ? "1.3vw" : "3.5vw",
                          }}
                        ></textarea>
                      </label>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open121}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
}
