import React from "react";
import { useNavigate } from "react-router";

import StarIcon from "@mui/icons-material/Star";
import img1 from "../../../assets/About/Iconly-Light-Location.svg";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import imgxx from "../../../assets/Success stories Definition/checkmark (1).svg";

export default function ProfileCatelogdetails({ width, data }) {
  const navigate = useNavigate();

  const myStr = data?.website_id?.websiteName;
  const contentNameRoute = myStr
    .replaceAll(" ", "-")
    .replaceAll("/", "_")
    .replaceAll("?", "_");
  const myStr1 = data?.product_id.productName;
  const contentNameRoute1 = myStr1
    .replaceAll(" ", "-")
    .replaceAll("/", "_")
    .replaceAll("?", "_");
  return (

    <div
      style={{
        width: width > 700 ? "25vw" : "90vw",
        height: "100%",
        marginTop: width > 700 ? "1vw" : "2.5vw",
        marginBottom: width > 700 ? "1vw" : "2.5vw",
        marginLeft: width > 700 ? "1vw" : "2.5vw",
        marginRight: width > 700 ? "1vw" : "2.5vw",
      }}
      className="Menuwebsite"
    >
      <div style={{paddingBottom:"1px"}}>
        <img
          style={{
            width: width > 700 ? "25vw" : "100%",
            height: width > 700 ? "12vw" : "50vw",
            filter:
              data?.product_id?.type !== "Service" &&
              data?.quantity < 1 &&
              "grayscale(1)",
            cursor: "pointer",
          }}
          src={data?.product_id?.files[0]?.file}
          alt=""
          onClick={() => {
            navigate(`/dashboard/product/${contentNameRoute1}/${data?.webId}`);
          }}
        />

        <div className="m-2">
          <div
            onClick={() => {
              navigate(
                `/dashboard/product/${contentNameRoute1}/${data?.webId}`
              );
            }}
            style={{ cursor: "pointer" }}
            className="namemenuweb ellipsis"
          >
            {data?.product_id?.productName}
          </div>
          <div
            style={{
              height: width > 700 ? "1.5vw" : "4.5vw",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: "0.2vw",
            }}
            className="pricemenuweb mt-2"
          >
            <div
              style={{
                fontSize: width > 700 ? "0.85vw" : "2.5vw",
                display: "flex",
                marginTop: "0.1vw",
                alignItems: "center",
              }}
            >
              {data?.user_id?.verifiedByAdmin === true && (
                <img
                  style={{
                    width: width > 700 ? "1.1vw" : "3.1vw",
                    marginRight: "0.3vw",
                    height: "3.2vh",
                    objectFit: "contain",
                  }}
                  src={imgxx}
                  alt=""
                />
              )}
              {data?.user_id?.fullName}
              {data?.user_id?.certifiedByAdmin === true && (
                <span style={{ display: "flex" }}>
                  (
                  <WorkspacePremiumIcon
                    style={{
                      width: width > 700 ? "1.1vw" : "3.1vw",
                      height: width > 700 ? "1.1vw" : "3.1vw",
                      margin: "0vw",
                      color: "#0052cc",
                    }}
                    alt=""
                  />
                  )
                </span>
              )}
            </div>
            <div className="d-flex align-items-center rating_text">
              <div
                className="d-flex align-items-center"
                style={{ marginRight: "5px" }}
              >
                <span style={{ color: "#0052cc", marginTop: "2px" }}>
                  {data?.product_id?.type
                    ? data?.product_id?.type
                    : "Product"}
                </span>
                <StarIcon
                  style={{
                    width: width > 700 ? "1vw" : "3.5vw",
                    marginLeft: "5px",
                    color: "#0052cc",
                    height: "14px",
                  }}
                />{" "}
              </div>
              <div style={{ marginTop: "2px" }}>
                {data?.product_id?.rating?.$numberDecimal
                  ? data?.product_id?.rating?.$numberDecimal?.slice(0, 1)
                  : 0}{" "}
                (
                {data?.product_id?.completedOrder
                  ? data?.product_id?.completedOrder
                  : 0}
                )
              </div>
            </div>
          </div>
          <div
            className="multiline-ellipsis"
            style={{
              fontSize:width > 700 ? "0.95vw": "2.8vw" ,
              paddingTop: "2px",
              paddingBottom: "2px",
              height: width > 700 ? "2.5vw" : "7.5vw",
            }}
          >
            {data?.product_id?.description !== "undefined"
              ? data?.product_id?.description || ""
              : ""}
          </div>
          <hr style={{ padding: "0vw", margin: "0.3vw" }}></hr>
          <div
            style={{
              height: width > 700 ? "1.5vw" : "4.5vw",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: "0.2vw",
            }}
            className="pricemenuweb mt-2"
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {" "}
              <span>
                <img
                  style={{
                    height: width > 700 ? "fit-content" : "6vw",
                    width: width > 700 ? "1.2vw" : "6vw",
                    margin: "0vw",
                    marginRight: "0.2vw",
                    maxHeight: width > 700 ? "1.5vw" : "4vw",
                  }}
                  src={data?.website_id?.websiteLogo}
                  alt=""
                />
              </span>{" "}
              <div className="ellipsis" style={{fontSize: width > 700 ? "0.8vw" :"2.8vw", marginTop:"2px"}}>{data?.website_id?.websiteName}</div>
            </div>{" "}
            <div
              style={{
                color:
                  data?.product_id?.type === "Service"
                    ? ""
                    : data?.quantity > 0
                    ? ""
                    : "red",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              className="stockmenuweb"
            >
              {data?.product_id?.type === "Service"
                ? data?.product_id?.serviceType
                : data?.quantity > 0
                ? data?.quantity + " Items Left"
                : "Out of Stock"}{" "}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: "0.2vw",
            }}
            className="pricemenuweb p-0 w-100"
          >
            <div
              style={{ display: "flex", alignItems: "center" }}
              className="w-100"
            >
              {" "}
              <span>
                <img
                  style={{
                    height: "fit-content",
                    width: width > 700 ? "1.3vw" : "3.7vw",
                    margin: "0vw",
                    marginRight: "0.2vw",
                  }}
                  src={img1}
                  alt=""
                />
              </span>{" "}
              <div className="ellipsis">
                {" "}
                {data?.website_id?.companyAddress}
              </div>
            </div>{" "}
          </div>
          <hr style={{ padding: "0vw", margin: "0.3vw" }}></hr>
          <div className="d-flex align-items-center justify-content-between mt-2">
            <div
              style={{
                paddingBottom: "0.2vw",
                height: width > 700 ? "2vw" : "5vw",
                fontSize: width > 700 ? "1.1vw" : "2.8vw",
              }}
              className="pricemenuweb"
            >
              <p style={{fontSize:"0.9vw"}}>Price</p>
              <div className="tagblue d-flex align-items-center" style={{marginLeft:"8px", whiteSpace:"nowrap"}}>
              <span style={{fontSize: width > 700 ? "0.75vw" : "3vw"}}>Rs. {data?.product_id?.costPrice}</span>{" "}
              {data?.product_id?.sellingPrice && (
                <del
                  style={{
                    display: "flex",
                    fontSize: width > 700 ? "0.6vw" : "2vw",
                    height: width > 700 ? "0.8vw" : "2.5vw",
                    margin: "0 0.5vw",
                  }}
                >
                  ( Rs {data?.product_id?.sellingPrice})
                </del>
              )}
              {data?.product_id?.sellingPrice && (
                <span >
                  {parseFloat(
                    ((data?.product_id?.sellingPrice -
                      data?.product_id?.costPrice) *
                      100) /
                      data?.product_id?.sellingPrice
                  ).toFixed(0)}
                  % off{" "}
                </span>
              )}
              </div>
              
            </div>
              <div onClick={() => data?.website_id?.websiteName ? navigate(`/${contentNameRoute}/${data?.website_id?.websiteId}`)
                    : navigate(`/-/${data?.website_id?.websiteId}`) }
                className="visit_btn"
              > Visit Website
              </div>
            </div>
          </div>
        </div>
    </div>
    
    // <div
    //   style={{
    //     width: width > 700 ? "18vw" : "44.2vw",
    //     height: width > 700 ? "23.8vw" : "65vw",
    //     marginTop: width > 700 ? "1vw" : "2vw",
    //     marginBottom: width > 700 ? "1vw" : "2vw",
    //     marginLeft: width > 700 ? "1vw" : "1.5vw",
    //     marginRight: width > 700 ? "1vw" : "1.2vw",
    //   }}
    //   className="Menuwebsite"
    // >

    //   <div>
    //     <img
    //       style={{
    //         width: width > 700 ? "16vw" : "41vw",
    //         height: width > 700 ? "12vw" : "30vw",
    //         filter:
    //           data?.product_id?.type !== "Service" &&
    //           data?.quantity < 1 &&
    //           "grayscale(1)",
    //         cursor: "pointer",
    //       }}
    //       src={data?.product_id?.files[0]?.file}
    //       alt=""
    //       onClick={() => {
    //         navigate(`/dashboard/product/${contentNameRoute1}/${data?.webId}`);
    //       }}
    //     />
    //     <div
    //       onClick={() => {
    //         navigate(`/dashboard/product/${contentNameRoute1}/${data?.webId}`);
    //       }}
    //       style={{
    //         cursor: "pointer",
    //       }}
    //       className="namemenuweb"
    //     >
    //       {width > 700
    //         ? data?.product_id?.productName?.length > 23
    //           ? data?.product_id?.productName?.slice(0, 20) + ".."
    //           : data?.product_id?.productName?.slice(0, 23)
    //         : data?.product_id?.productName?.length > 20
    //         ? data?.product_id?.productName?.slice(0, 17) + ".."
    //         : data?.product_id?.productName?.slice(0, 20)}
    //     </div>
    //     <div
    //       style={{
    //         color:
    //           data?.product_id?.type === "Service"
    //             ? ""
    //             : data?.quantity > 0
    //             ? ""
    //             : "red",
    //         display: "flex",
    //         alignItems: "center",
    //         justifyContent: "space-between",
    //       }}
    //       className="stockmenuweb"
    //     >
    //       {data?.product_id?.type === "Service"
    //         ? data?.product_id?.serviceType
    //         : data?.quantity > 0
    //         ? data?.quantity + " Items Left"
    //         : "Out of Stock"}{" "}
    //       <span>
    //         {data?.product_id?.type ? data?.product_id?.type : "Product"}
    //       </span>
    //     </div>
    //     <div
    //       style={{
    //         paddingBottom: "0.2vw",
    //         height: width > 700 ? "2vw" : "5vw",
    //         fontSize: width > 700 ? "1.1vw" : "2.8vw",
    //       }}
    //       className="pricemenuweb"
    //     >
    //       <span>Rs. {data?.product_id?.costPrice}</span>{" "}
    //       {data?.product_id?.sellingPrice && (
    //         <del
    //           style={{
    //             display: "flex",
    //             fontSize: width > 700 ? "0.75vw" : "2vw",
    //             height: width > 700 ? "0.8vw" : "2.5vw",
    //             margin: "0 0.5vw",
    //           }}
    //         >
    //           ( Rs {data?.product_id?.sellingPrice})
    //         </del>
    //       )}
    //       {data?.product_id?.sellingPrice && (
    //         <span style={{ color: "#0052cc" }}>
    //           {parseFloat(
    //             ((data?.product_id?.sellingPrice -
    //               data?.product_id?.costPrice) *
    //               100) /
    //               data?.product_id?.sellingPrice
    //           ).toFixed(0)}
    //           % off{" "}
    //         </span>
    //       )}
    //     </div>

    //     <div
    //       style={{
    //         height: width > 700 ? "1.5vw" : "4.5vw",
    //         display: "flex",
    //         alignItems: "center",
    //         justifyContent: "space-between",
    //         paddingTop: "0.2vw",
    //       }}
    //       className="pricemenuweb"
    //     >
    //       <div style={{ display: "flex", alignItems: "center" }}>
    //         {" "}
    //         <span>
    //           <img
    //             style={{
    //               height: width > 700 ? "fit-content" : "4vw",
    //               width: width > 700 ? "1.4vw" : "4vw",
    //               margin: "0vw",
    //               marginRight: "0.2vw",
    //               maxHeight: width > 700 ? "1.5vw" : "4vw",
    //             }}
    //             src={data?.website_id?.websiteLogo}
    //             alt=""
    //           />
    //         </span>{" "}
    //         {data?.website_id?.websiteName?.slice(0, 18)}
    //       </div>{" "}
    //       <div
    //         style={{ color: "#0052cc", display: "flex", alignItems: "center" }}
    //       >
    //         {data?.product_id?.rating?.$numberDecimal
    //           ? data?.product_id?.rating?.$numberDecimal?.slice(0, 1)
    //           : 0}
    //         <StarIcon
    //           style={{ width: width > 700 ? "1.2vw" : "3.5vw", margin: "0vw" }}
    //         />{" "}
    //         (
    //         {data?.product_id?.completedOrder
    //           ? data?.product_id?.completedOrder
    //           : 0}
    //         )
    //       </div>
    //     </div>
    //     <div
    //       style={{
    //         height: width > 700 ? "1.5vw" : "4.5vw",
    //         display: "flex",
    //         alignItems: "center",
    //         justifyContent: "space-between",
    //         paddingTop: "0.2vw",
    //       }}
    //       className="pricemenuweb"
    //     >
    //       <div style={{ display: "flex", alignItems: "center" }}>
    //         {" "}
    //         <span>
    //           <img
    //             style={{
    //               height: "fit-content",
    //               width: width > 700 ? "1.3vw" : "3.7vw",
    //               margin: "0vw",
    //               marginRight: "0.2vw",
    //             }}
    //             src={img1}
    //             alt=""
    //           />
    //         </span>{" "}
    //         {data?.website_id?.companyAddress?.slice(0, width > 700 ? 28 : 24)}
    //       </div>{" "}
    //     </div>

    //     <div
    //       onClick={() =>
    //         data?.website_id?.websiteName
    //           ? navigate(`/${contentNameRoute}/${data?.website_id?.websiteId}`)
    //           : navigate(`/-/${data?.website_id?.websiteId}`)
    //       }
    //       className="Visitwebsitebutton"
    //     >
    //       Visit Website
    //     </div>
    //   </div>
    // </div>
  );
}
