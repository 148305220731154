import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { makeStyles } from "@material-ui/core";
import Box from "@mui/material/Box";
import TextEditor from "../BiddingForm/Texteditor";

const useStyles = makeStyles((theme) => ({
  select: {
    height: "2.5vw",
    width: "100%",
    marginTop: "0.2vw",
    padding: "1vw",
    marginLeft: "0vw",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "1vw",
    lineHeight: "120%",

    color: "#FFFFFF",
  },
  select2: {
    height: "1vw",
    width: "11vw",
    marginTop: "0.1vw",
    padding: "0.9vw 0.5vw",
    marginLeft: "0vw",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "0.51vw",
    lineHeight: "100%",
    color: "#FFFFFF",
  },
  select3: {
    height: "1vw",
    width: "9vw",
    marginTop: "0.1vw",
    padding: "0vw 0vw",
    marginLeft: "0vw",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "0.51vw",
    lineHeight: "100%",
    color: "#FFFFFF",
    position: "relative",
    top: "0.81vw",
    left: "0.2vw",
  },
  select4: {
    height: "8.1vw",
    width: "100%",
    marginTop: "0.3vw",
    padding: "1vw",
    marginLeft: "0vw",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "1vw",
    lineHeight: "120%",

    color: "#FFFFFF",
  },
  select5: {
    height: "8vw",
    width: "30vw",
    marginTop: "0.1vw",
    padding: "0.9vw 0.5vw",
    marginLeft: "0vw",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "0.51vw",
    lineHeight: "100%",
    color: "#FFFFFF",
  },
  select6: {
    height: "7vw",
    width: "25vw",
    marginTop: "0.1vw",
    padding: "0vw 0vw",
    marginLeft: "0vw",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "0.51vw",
    lineHeight: "100%",
    color: "#FFFFFF",
    position: "relative",
    top: "0.81vw",
    left: "0.2vw",
  },
  icon: {
    fill: "white",
  },
}));
export default function AddTableofContent({
  width,
  setArrayofblogs,
  arrayofblogs,
  data,
  index,
  setErroraddblog,
  erroraddblog,
  title,
  scate,
  arrayoffiles,
  imagetitle,
  allCbutton,
  allCtitle,
  imageTagAlt,
}) {
  const [description1, setDescription1] = useState("");

  const classes = useStyles();
  const [age4, setAge4] = React.useState(10);
  const [age5, setAge5] = React.useState(10);
  const [heading1, setHeading1] = useState("");
  const [buttonDesc1, setButtonDesc1] = useState("");
  const [buttonlink, setButtonlink] = useState("");

  const [checktocalltoaction, setChecktocalltoaction] = useState(false);

  const handleChange4x = (event) => {
    setAge4(event.target.value);
  };
  const handleChange5x = (event) => {
    setAge5(event.target.value);
  };
  const [wrongsec, setwrongsec] = useState(false);

  useEffect(() => {
    setArrayofblogs([
      ...arrayofblogs.slice(0, index),
      {
        heading: heading1,
        toc: description1,

        desc: buttonDesc1,
        desc: buttonDesc1,
        title: data?.title,
        button: data?.button,
        buttonlink: buttonlink,
      },
      ...arrayofblogs.slice(index + 1, arrayofblogs.length),
    ]);
  }, [description1, heading1, buttonDesc1, buttonlink]);

  useEffect(() => {
    setwrongsec(false);
    if (
      title &&
 
      scate &&
      arrayoffiles?.length > 0 &&
      imageTagAlt
    ) {
      setErroraddblog(false);
    } else {
      setErroraddblog(true);
    }
  }, [data, title, scate, arrayoffiles, arrayofblogs, imageTagAlt]);

  return (
    <>
      {
        <div>
          <div
            style={{
              marginBottom: "1vw",
              display: "flex",
              justifyContent: "space-between",
            }}
            className="jobpodtedfieldtitile"
          >
            <div style={{ fontSize: width > 700 ? "1.2vw" : "3.5vw" }}>
              {" "}
              Paragraph {index + 1}{" "}
            </div>
            {arrayofblogs?.length > 1 && (
              <div>
                <CloseIcon
                  style={{
                    position: "relative",
                    right: "1vw",
                    fontSize: width > 700 ? "2vw" : "5vw",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setArrayofblogs([
                      ...arrayofblogs.slice(0, index),
                      ...arrayofblogs.slice(index + 1, arrayofblogs.length),
                    ]);
                  }}
                />
              </div>
            )}
          </div>
          <div className="jobpodtedfieldtitile"> Paragraph Heading </div>
          <div className="jobpostfieldinputbox">
            <input
              type="text"
              value={heading1}
              onChange={(e) => {
                setHeading1(e.target.value);
              }}
            />
          </div>
          <div className="jobpodtedfieldtitile"> Paragraph Description </div>
          <div
            style={{ marginBottom: "2vw", width: width > 700 ? "96%" : "100%" }}
          >
            <TextEditor
              width={"65vw"}
              setDescription1={setDescription1}
              description1={description1}
            />
          </div>
          <label style={{ display: "flex" }} htmlFor="checkbox">
            <input
              type="checkbox"
              checked={checktocalltoaction}
              onChange={() => {
                if (checktocalltoaction === true) {
                  setChecktocalltoaction(!checktocalltoaction);
                } else {
                  setChecktocalltoaction(!checktocalltoaction);
                }
              }}
              name=""
              id="checkbox"
            />

            <div
              className=""
              style={{
                fontSize: width > 700 ? "1vw" : "3vw",
                fontFamily: "Poppins",
                fontStyle: "500",
                fontWeight: "500",
                color: "#000000",
                display: "inline",
                margin: "0 1vw",
              }}
            >
              <div className="jobpodtedfieldtitile">Show Call to Action</div>
            </div>
          </label>{" "}
          {checktocalltoaction && (
            <div>
              <div
                style={{ justifyContent: "space-between", width: "90%" }}
                className="homjobpost-popbudegt"
              >
                <div>
                  <div
                    style={{ width: "fit-content" }}
                    className="jobpodtedfieldtitile"
                  >
                    Title Name{" "}
                  </div>
                  <div className="">
                    <Box
                      sx={{
                        background: "white",
                        border: "1px solid #7070705b",
                        height: width > 700 ? "2.6vw" : "9vw",
                        width: width > 700 ? "64vw" : "92vw",
                        borderRadius: "5px",
                        margin: "0.5vw 1.5vw",
                        padding: "0.2vw 0.2vw",
                        marginLeft: "0vw",
                      }}
                      className="setting-toggler"
                    >
                      <FormControl variant="standard" fullWidth>
                        <Select
                          className={
                            width > 700 ? classes.select1 : classes.select4
                          }
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={age4}
                          disableUnderline
                          inputProps={{
                            classes: {
                              icon: classes.icon,
                            },
                          }}
                          onChange={handleChange4x}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                bgcolor: "white",

                                "& .MuiMenuItem-root": {
                                  padding: "0.1vw 2vw",
                                  width: "100%",
                                  height: "2vw",
                                  fontFamily: "Poppins",
                                  fontStyle: "normal",
                                  fontWeight: "500",
                                  fontSize: width > 700 ? "1vw" : "3vw",
                                  lineHeight: width > 700 ? "1.5vw" : "4vw",
                                  color: "#6b6b6b",
                                },
                              },
                            },
                          }}
                        >
                          <MenuItem
                            onClick={() => {
                              setArrayofblogs([
                                ...arrayofblogs.slice(0, index),
                                {
                                  heading: data?.heading,
                                  toc: data?.toc,
                                  file: data?.file,
                                  desc: data?.desc,
                                  title: "",
                                  button: data?.button,
                                },
                                ...arrayofblogs.slice(
                                  index + 1,
                                  arrayofblogs.length
                                ),
                              ]);
                            }}
                            value={10}
                          >
                            None
                          </MenuItem>
                          {allCtitle?.length > 0 &&
                            allCtitle?.map((data1) => {
                              return (
                                <MenuItem
                                  onClick={() => {
                                    setArrayofblogs([
                                      ...arrayofblogs.slice(0, index),
                                      {
                                        heading: data?.heading,
                                        toc: data?.toc,
                                        file: data?.file,
                                        desc: data?.desc,
                                        title: data1?.callToActionTitle,
                                        button: data?.button,
                                      },
                                      ...arrayofblogs.slice(
                                        index + 1,
                                        arrayofblogs.length
                                      ),
                                    ]);
                                  }}
                                  value={data1?.callToActionTitle}
                                >
                                  {data1?.callToActionTitle}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                </div>
              </div>
              <div className="jobpodtedfieldtitile">Button Description </div>
              <div
                style={{
                  marginBottom: "2vw",
                  width: width > 700 ? "96%" : "100%",
                }}
              >
                <TextEditor
                  width={"64vw"}
                  setDescription1={setButtonDesc1}
                  description1={buttonDesc1}
                />
              </div>
              <div>
                <div
                  style={{ marginLeft: "0.2vw", width: "fit-content" }}
                  className="jobpodtedfieldtitile"
                >
                  Button Name
                </div>
                <div className="">
                  <Box
                    sx={{
                      background: "white",
                      border: "1px solid #7070705b",
                      height: width > 700 ? "2.6vw" : "9vw",
                      width: width > 700 ? "64vw" : "92vw",
                      borderRadius: "5px",
                      margin: "0.5vw 1.5vw",
                      padding: "0.2vw 0.2vw",
                      marginLeft: "0vw",
                    }}
                    className="setting-toggler"
                  >
                    <FormControl variant="standard" fullWidth>
                      <Select
                        className={
                          width > 700 ? classes.select1 : classes.select4
                        }
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={age5}
                        disableUnderline
                        inputProps={{
                          classes: {
                            icon: classes.icon,
                          },
                        }}
                        onChange={handleChange5x}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              bgcolor: "white",
                              "& .MuiMenuItem-root": {
                                padding: "0.1vw 2vw",
                                width: "100%",
                                height: "2vw",
                                fontFamily: "Poppins",
                                fontStyle: "normal",
                                fontWeight: "500",
                                fontSize: width > 700 ? "1vw" : "3vw",
                                lineHeight: width > 700 ? "1.5vw" : "4vw",
                                color: "#6b6b6b",
                              },
                            },
                          },
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            setArrayofblogs([
                              ...arrayofblogs.slice(0, index),
                              {
                                toc: data?.toc,
                                file: data?.file,
                                desc: data?.desc,
                                title: data?.title,
                                button: "",
                              },
                              ...arrayofblogs.slice(
                                index + 1,
                                arrayofblogs.length
                              ),
                            ]);
                          }}
                          value={10}
                        >
                          None
                        </MenuItem>
                        {allCbutton?.length &&
                          allCbutton?.map((data1) => {
                            return (
                              <MenuItem
                                onClick={() => {
                                  setArrayofblogs([
                                    ...arrayofblogs.slice(0, index),
                                    {
                                      heading: data?.heading,
                                      toc: data?.toc,
                                      file: data?.file,
                                      desc: data?.desc,
                                      title: data?.title,
                                      button: data1?.callToActionButton,
                                    },
                                    ...arrayofblogs.slice(
                                      index + 1,
                                      arrayofblogs.length
                                    ),
                                  ]);
                                }}
                                value={data1?.callToActionButton}
                              >
                                {data1?.callToActionButton}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Box>
                </div>
              </div>
              <div className="jobpodtedfieldtitile">Button Link </div>
              <div className="jobpostfieldinputbox">
                <input
                  type="text"
                  value={buttonlink}
                  onChange={(e) => {
                    setButtonlink(e.target.value);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      }
    </>
  );
}
