import React, { useEffect, useState } from 'react';
import './profile.css';
import img2 from '../../assets/My profile – 28/Component 71 – 6.svg';
import img1 from '../../assets/My profile – 28/Component 70 – 6.svg';
import Box from '@mui/material/Box';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import Modal from '@mui/material/Modal';
import { Popover, TextField, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import Profileuserdetaillanguage from './Profilepopup/Profileuserdetaillanguage';
import { KeyboardArrowDownOutlined } from '@mui/icons-material';
import API_HOST from '../../env';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../store/userSlice';
import Drawer from '@mui/material/Drawer';
import EmailVerify from './EmailVerify';
import PhoneVerify from './PhoneVerify';
import Texteditor from '../DashbaordSidebarComponents/BiddingForm/Texteditor';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50vw',
  maxHeight: '95vh',
  overflow: 'scroll',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: '2vw',
};
const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxHeight: '95vh',
  overflow: 'scroll',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 1,
};
const contact_modal_style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40vw',
  maxHeight: '95vh',
  overflow: 'scroll',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: '2vw',
};
const contact_modal_style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxHeight: '95vh',
  overflow: 'scroll',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 1,
};

const email_modal_style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40vw',
  maxHeight: '95vh',
  overflow: 'scroll',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: '2vw',
};
const email_modal_style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxHeight: '95vh',
  overflow: 'scroll',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 1,
};

const useStyles = makeStyles((theme) => ({
  input: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '0.91vw',
    color: '#263238',
    border: 'yellow !important',
  },
}));
export default function Profileuserdetails({ width }) {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [month1x1, setMonth1x1] = useState('');
  const [year1x1, setyear1x1] = useState('');
  const classes = useStyles();

  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const [open3, setOpen3] = React.useState(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);

  const [open3x, setOpen3x] = React.useState(false);
  const handleOpen3x = () => setOpen3x(true);
  const handleClose3x = () => setOpen3x(false);

  const [open4, setOpen4] = React.useState(false);
  const handleOpen4 = () => setOpen4(true);
  const handleClose4 = () => setOpen4(false);

  const [open4x, setOpen4x] = React.useState(false);
  const handleOpen4x = () => setOpen4x(true);
  const handleClose4x = () => setOpen4x(false);

  const [openContactModal, setOpenContactModal] = React.useState(false);
  const handleContactModalOpen = () => {
    setOpenContactModal(true);
  };

  const handleContactModalclose = () => {
    setOpenContactModal(false);
  };

  const [openEmailModal, setOpenEmailModal] = React.useState(false);
  const handleEmailModalOpen = () => {
    setOpenEmailModal(true);
  };

  const handleEmailModalclose = () => {
    setOpenEmailModal(false);
  };

  const [anchorElx1p1, setAnchorElx1p1] = React.useState(null);
  const handleClickx1p1 = (event) => {
    setAnchorElx1p1(event.currentTarget);
  };
  const handleClosex1p1 = () => {
    setAnchorElx1p1(null);
  };
  const openx1p1 = Boolean(anchorElx1p1);
  const idx1p1 = openx1p1 ? 'simple-popover' : undefined;
  const [desc, setDesc] = useState('');
  const [desclength, setDesclength] = useState(0);

  const [address, setAddress] = useState('');
  const [description1, setDescription1] = useState('');

  useEffect(() => {
    setDescription1(user?.fullDescription ? user?.fullDescription : '');
  }, [user.fullDescription]);

  const handleupdatelang = () => {
    if (month1x1 !== '' && year1x1 !== '') {
      const formdata = {
        userId: user?.userId,
        language: month1x1,
        proficiencyLevel: year1x1,
      };
      axios
        .post(`${API_HOST}/users/updateUserLanguage`, formdata, {
          headers: {
            // "Content-Type": "multipart/form-data",
            Authorization:
              'Bearer ' + JSON.parse(localStorage.getItem('token')),
          },
        })
        .then((res) => {
          window.localStorage.setItem(
            'user',
            JSON.stringify({ ...res.data.success.data })
          );

          dispatch(
            userActions.setUser({
              user: { ...res.data.success.data },
            })
          );
          handleClose1();
          setMonth1x1('');
          setyear1x1('');
        });
    } else {
      if (month1x1 === '' && year1x1 === '') {
        setLangugaeaddwrong(true);
        setLangugaeaddwrong1(true);
      }
      if (month1x1 === '' && year1x1 !== '') {
        setLangugaeaddwrong(true);
        setLangugaeaddwrong1(false);
      }
      if (month1x1 !== '' && year1x1 === '') {
        setLangugaeaddwrong(false);
        setLangugaeaddwrong1(true);
      }
    }
  };

  const [langugaeaddwrong, setLangugaeaddwrong] = useState(false);
  const [langugaeaddwrong1, setLangugaeaddwrong1] = useState(false);

  const handledescriptionupdate = () => {
    axios
      .post(
        `${API_HOST}/users/editUser`,
        {
          fullDescription: description1,
          userId: user?.userId,
        },
        {
          headers: {
            // "Content-Type": "multipart/form-data",
            Authorization:
              'Bearer ' + JSON.parse(localStorage.getItem('token')),
          },
        }
      )
      .then((res) => {
        window.localStorage.setItem(
          'user',
          JSON.stringify({ ...res.data.success.data })
        );

        dispatch(
          userActions.setUser({
            user: { ...res.data.success.data },
          })
        );
      });
    handleClose3();
  };

  console.log(description1);
  const handletitleupdate = () => {
    axios
      .post(
        `${API_HOST}/users/editUser`,
        {
          title: titleuser,
          userId: user?.userId,
        },
        {
          headers: {
            // "Content-Type": "multipart/form-data",
            Authorization:
              'Bearer ' + JSON.parse(localStorage.getItem('token')),
          },
        }
      )
      .then((res) => {
        window.localStorage.setItem(
          'user',
          JSON.stringify({ ...res.data.success.data })
        );

        dispatch(
          userActions.setUser({
            user: { ...res.data.success.data },
          })
        );
      });
    handleClose2();
  };
  const [titleuser, setTitleuser] = useState('');

  const handleupdateaddress = () => {
    axios
      .post(
        `${API_HOST}/users/editUser`,
        {
          address: address,
          userId: user?.userId,
        },
        {
          headers: {
            // "Content-Type": "multipart/form-data",
            Authorization:
              'Bearer ' + JSON.parse(localStorage.getItem('token')),
          },
        }
      )
      .then((res) => {
        window.localStorage.setItem(
          'user',
          JSON.stringify({ ...res.data.success.data })
        );

        dispatch(
          userActions.setUser({
            user: { ...res.data.success.data },
          })
        );
      });
    handleClose3x();
  };
  const [dob, setDob] = useState('');

  const handleupdateDob = () => {
    axios
      .post(
        `${API_HOST}/users/editUser`,
        {
          dob: dob,
          userId: user?.userId,
        },
        {
          headers: {
            // "Content-Type": "multipart/form-data",
            Authorization:
              'Bearer ' + JSON.parse(localStorage.getItem('token')),
          },
        }
      )
      .then((res) => {
        window.localStorage.setItem(
          'user',
          JSON.stringify({ ...res.data.success.data })
        );

        dispatch(
          userActions.setUser({
            user: { ...res.data.success.data },
          })
        );
      });
    handleClose4x();
  };

  const [instaLink, setInstaLink] = useState('');
  const [lLink, setllink] = useState('');
  const [fLink, setflink] = useState('');
  const [gLink, setglink] = useState('');

  const handleupdatelink = () => {
    axios
      .post(
        `${API_HOST}/users/socialLink`,
        {
          gLink: gLink,
          fLink: fLink,
          lLink: lLink,
          gitLink: instaLink,
        },
        {
          headers: {
            // "Content-Type": "multipart/form-data",
            Authorization:
              'Bearer ' + JSON.parse(localStorage.getItem('token')),
          },
        }
      )
      .then((res) => {
        window.localStorage.setItem(
          'user',
          JSON.stringify({ ...res.data.success.data })
        );

        dispatch(
          userActions.setUser({
            user: { ...res.data.success.data },
          })
        );
      });
    handleClose4();
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width:
          anchor === 'top' || anchor === 'bottom'
            ? 'auto'
            : width > 700
            ? '30vw'
            : '90vw',
      }}
      role='presentation'
    >
      <EmailVerify
        toggleDrawer={toggleDrawer}
        closedrawer={toggleDrawer('right', false)}
        width={width}
      />
    </Box>
  );
  const [state1, setState1] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer1 = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState1({ ...state1, [anchor]: open });
  };

  useEffect(() => {
    if (user?.userName) {
      setllink(user?.lLink);
      setglink(user?.gLink);
      setInstaLink(user?.gitLink);
      setflink(user?.fLink);
      setAddress(user?.address);
    }
  }, [user]);

  return (
    <div className='Profileuserdetails-container'>
      <div className='profileuserdetailsone'>
        <div className='profiletitleandmenunav'>
          <div className='profiledetailstitle'>User profile</div>
          <div className='profiledetailnavmanu'></div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '0.5vw',
          }}
          className='profileuserfirstonetitle'
        >
          Social Link{' '}
          <div style={{ width: '13%', textAlign: 'center' }}>
            {' '}
            <img
              style={{ width: width > 700 ? '2vw' : '8vw', cursor: 'pointer' }}
              onClick={handleOpen4}
              src={img2}
              alt=''
            />
          </div>{' '}
        </div>
        <div
          style={{ flexWrap: 'wrap', justifyContent: 'flex-start' }}
          className='profileuserdfirst'
        >
          {fLink && (
            <div style={{ width: '25%', textAlign: 'center' }}>
              <a
                href={fLink}
                target='_blank'
              >
                <FacebookIcon
                  style={{
                    width: width > 700 ? '2vw' : '5vw',
                    cursor: 'pointer',
                    color: '#0052cc',
                  }}
                />
              </a>
            </div>
          )}
          {gLink && (
            <div style={{ width: '25%', textAlign: 'center' }}>
              {' '}
              <a
                href={gLink}
                target='_blank'
              >
                <GoogleIcon
                  style={{
                    width: width > 700 ? '2vw' : '5vw',
                    cursor: 'pointer',
                    color: '#0052cc',
                  }}
                />
              </a>
            </div>
          )}
          {lLink && (
            <div style={{ width: '25%', textAlign: 'center' }}>
              {' '}
              <a
                href={lLink}
                target='_blank'
              >
                <LinkedInIcon
                  style={{
                    width: width > 700 ? '2vw' : '5vw',
                    cursor: 'pointer',
                    color: '#0052cc',
                  }}
                />
              </a>
            </div>
          )}
          {instaLink && (
            <div style={{ width: '25%', textAlign: 'center' }}>
              {' '}
              <a
                href={instaLink}
                target='_blank'
              >
                <InstagramIcon
                  style={{
                    width: width > 700 ? '2vw' : '5vw',
                    cursor: 'pointer',
                    color: '#0052cc',
                  }}
                />
              </a>
            </div>
          )}
        </div>

        <Modal
          open={open4}
          onClose={handleClose4}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={width > 700 ? style : style1}>
            <div className='profiletitleandmenunav'>
              <div className='profiledetailstitle'>Social Links</div>
              <div className='profiledetailnavmanu'>
                <div>
                  <CloseIcon
                    onClick={handleClose4}
                    style={{
                      fontSize: width > 700 ? '1.5vw' : '4vw',
                      cursor: 'pointer',
                    }}
                  />
                </div>
              </div>
            </div>
            <hr style={{ color: '#000000' }} />

            <div
              style={{ left: '0vw', width: '100%' }}
              className='loginfield'
            >
              <TextField
                id='outlined-basic'
                label='Facebook Link'
                variant='outlined'
                value={fLink}
                rows='7'
                style={{ width: '100%' }}
                InputLabelProps={{
                  style: {
                    fontSize: width > 700 ? '1vw' : '3vw',
                    fontFamily: 'Poppins',
                    fontStyle: '500',
                    fontWeight: '500',
                    color: 'black',
                  },
                }}
                inputProps={{ className: classes.input }}
                onChange={(e) => {
                  setflink(e.target.value);
                }}
              />
            </div>
            <div
              style={{ left: '0vw', width: '100%' }}
              className='loginfield'
            >
              <TextField
                id='outlined-basic'
                label='Google Link'
                variant='outlined'
                value={gLink}
                rows='7'
                style={{ width: '100%' }}
                InputLabelProps={{
                  style: {
                    fontSize: width > 700 ? '1vw' : '3vw',
                    fontFamily: 'Poppins',
                    fontStyle: '500',
                    fontWeight: '500',
                    color: 'black',
                  },
                }}
                inputProps={{ className: classes.input }}
                onChange={(e) => {
                  setglink(e.target.value);
                }}
              />
            </div>
            <div
              style={{ left: '0vw', width: '100%' }}
              className='loginfield'
            >
              <TextField
                id='outlined-basic'
                label='Linkedin Link'
                variant='outlined'
                value={lLink}
                rows='7'
                style={{ width: '100%' }}
                InputLabelProps={{
                  style: {
                    fontSize: width > 700 ? '1vw' : '3vw',
                    fontFamily: 'Poppins',
                    fontStyle: '500',
                    fontWeight: '500',
                    color: 'black',
                  },
                }}
                inputProps={{ className: classes.input }}
                onChange={(e) => {
                  setllink(e.target.value);
                }}
              />
            </div>
            <div
              style={{ left: '0vw', width: '100%' }}
              className='loginfield'
            >
              <TextField
                id='outlined-basic'
                label='Instagram Link'
                variant='outlined'
                value={instaLink}
                rows='7'
                style={{ width: '100%' }}
                InputLabelProps={{
                  style: {
                    fontSize: width > 700 ? '1vw' : '3vw',
                    fontFamily: 'Poppins',
                    fontStyle: '500',
                    fontWeight: '500',
                    color: 'black',
                  },
                }}
                inputProps={{ className: classes.input }}
                onChange={(e) => {
                  setInstaLink(e.target.value);
                }}
              />
            </div>

            <div
              style={{ marginTop: '0.31vw' }}
              className='handlemoreaboutskill'
            >
              <div
                style={{
                  background: 'white',
                  color: 'black',
                  cursor: 'pointer',
                }}
                className='handlecirclieaboutsave'
                onClick={handleClose4}
              >
                Cancel
              </div>
              <div
                style={{ cursor: 'pointer' }}
                onClick={handleupdatelink}
                className='handlecirclieaboutsave'
              >
                SAVE
              </div>
            </div>
          </Box>
        </Modal>

        <div className='profileuserdfirst'>
          <div className='profileuserfirstone'>
            <div
              style={{
                display: 'flex',

                alignItems: 'center',
              }}
              className='profileuserfirstonetitle'
            >
              <div>Language</div>

              <div onClick={handleOpen1}>
                <img
                  style={{
                    width: width > 700 ? '2.4vw' : '6.5vw',
                    cursor: 'pointer',
                  }}
                  src={img1}
                  alt=''
                />
              </div>
              <Modal
                open={open1}
                onClose={handleClose1}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
              >
                <Box sx={width > 700 ? style : style1}>
                  <div className='profiletitleandmenunav'>
                    <div
                      style={{ width: '200%' }}
                      className='profiledetailstitle'
                    >
                      Add Language
                    </div>
                    <div className='profiledetailnavmanu'>
                      <div>
                        <CloseIcon
                          onClick={handleClose1}
                          style={{
                            fontSize: width > 700 ? '1.5vw' : '4vw',
                            cursor: 'pointer',
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <hr style={{ color: '#000000' }} />

                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      flexDirection: width > 700 ? 'row' : 'column',
                    }}
                  >
                    <div
                      style={{
                        left: width > 700 ? '0vw' : '',
                        width: width > 700 ? '49%' : '98%',
                        margin: width > 700 ? '0.3vw 0' : '2vw 0vw',
                      }}
                      className='loginfield'
                    >
                      <TextField
                        error={!langugaeaddwrong ? false : true}
                        id='outlined-basic'
                        label='Language'
                        variant='outlined'
                        placeholder='English'
                        value={month1x1}
                        style={{ width: width > 700 ? '95%' : '100%' }}
                        InputLabelProps={{
                          style: {
                            fontSize: width > 700 ? '1vw' : '3vw',
                            fontFamily: 'Poppins',
                            fontStyle: '500',
                            fontWeight: '500',
                            color: !langugaeaddwrong ? 'black' : '#dc3545',
                          },
                        }}
                        onChange={(e) => setMonth1x1(e.target.value)}
                        inputProps={{ className: classes.input }}
                      />
                    </div>

                    <div
                      style={{
                        width: width > 700 ? '49%' : '98%',
                        margin: width > 700 ? '0.3vw 0' : '2vw 0vw',
                      }}
                      className='loginfield'
                      onClick={handleClickx1p1}
                    >
                      <TextField
                        error={!langugaeaddwrong1 ? false : true}
                        id='outlined-basic'
                        label='Proficiency Level'
                        variant='outlined'
                        placeholder='Intermediate'
                        value={year1x1}
                        style={{ width: width > 700 ? '95%' : '100%' }}
                        InputLabelProps={{
                          style: {
                            fontSize: width > 700 ? '1vw' : '3vw',
                            fontFamily: 'Poppins',
                            fontStyle: '500',
                            fontWeight: '500',
                            color: !langugaeaddwrong1 ? 'black' : '#dc3545',
                          },
                        }}
                        onChange={handleClickx1p1}
                        inputProps={{ className: classes.input }}
                      />
                      {width > 700 && (
                        <span>
                          <KeyboardArrowDownOutlined
                            style={{
                              fontSize: '1.5vw',
                              position: 'relative',
                              right: '2vw',
                              top: '1vw',
                            }}
                          />
                        </span>
                      )}
                    </div>
                    <Popover
                      id={idx1p1}
                      open={openx1p1}
                      anchorEl={anchorElx1p1}
                      onClose={handleClosex1p1}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      style={{}}
                    >
                      <div
                        style={{
                          maxHeight: width > 700 ? '20vw' : '50vw',
                          overflow: 'scroll',
                        }}
                      >
                        <Typography
                          sx={{
                            p: 1,
                            pl: 1,
                            ml: 1,
                            width: width > 700 ? '20vw' : '80vw',
                          }}
                          onClick={() => {
                            setyear1x1('Learning');
                            handleClosex1p1();
                            setLangugaeaddwrong1(false);
                          }}
                        >
                          Learning
                        </Typography>
                        <Typography
                          sx={{
                            p: 1,
                            pl: 1,
                            ml: 1,
                            width: width > 700 ? '20vw' : '80vw',
                          }}
                          onClick={() => {
                            setyear1x1('Intermediate');
                            handleClosex1p1();
                            setLangugaeaddwrong1(false);
                          }}
                        >
                          Intermediate
                        </Typography>
                        <Typography
                          sx={{
                            p: 1,
                            ml: 1,
                            width: width > 700 ? '20vw' : '80vw',
                          }}
                          onClick={() => {
                            setyear1x1('Highly');
                            handleClosex1p1();
                            setLangugaeaddwrong1(false);
                          }}
                        >
                          Highly
                        </Typography>
                      </div>
                    </Popover>
                  </div>
                  <hr style={{ color: '#000000' }} />
                  <div
                    style={{ marginTop: '0.31vw' }}
                    className='handlemoreaboutskill'
                  >
                    <div
                      style={{
                        background: 'white',
                        color: 'black',
                        cursor: 'pointer',
                      }}
                      className='handlecirclieaboutsave'
                      onClick={handleClose1}
                    >
                      Cancel
                    </div>
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={handleupdatelang}
                      className='handlecirclieaboutsave'
                    >
                      SAVE
                    </div>
                  </div>
                </Box>
              </Modal>
            </div>

            {user?.languages?.map((language) => {
              return (
                <Profileuserdetaillanguage
                  language={language}
                  width={width}
                />
              );
            })}
          </div>
        </div>

        <div className='profileuserfirstonetitle'>
          DOB -
          {user?.dob && (
            <span>
              {new Date(user?.dob).getDate()}/
              {new Date(user?.dob).getMonth() + 1}/
              {new Date(user?.dob).getFullYear()}
            </span>
          )}
          <span>
            <span
              style={{
                width: width > 700 ? '2vw' : '8vw',
                cursor: 'pointer',
                cursor: 'pointer',
              }}
            >
              <img
                style={{
                  width: width > 700 ? '2vw' : '8vw',
                  cursor: 'pointer',
                }}
                onClick={handleOpen4x}
                src={img2}
                alt=''
              />
            </span>
          </span>
          <Modal
            open={open4x}
            onClose={handleClose4x}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box sx={width > 700 ? style : style1}>
              <div className='profiletitleandmenunav'>
                <div className='profiledetailstitle'>Date of birth</div>
                <div className='profiledetailnavmanu'>
                  <div>
                    <CloseIcon
                      onClick={handleClose4x}
                      style={{
                        fontSize: width > 700 ? '1.5vw' : '4vw',
                        cursor: 'pointer',
                      }}
                    />
                  </div>
                </div>
              </div>
              <hr style={{ color: '#000000' }} />

              <div
                style={{ left: '0vw', width: '100%' }}
                className='loginfield'
              >
                <TextField
                  id='outlined-basic'
                  label='DOB'
                  type='date'
                  variant='outlined'
                  value={dob}
                  rows='7'
                  style={{ width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                    style: {
                      fontSize: width > 700 ? '1vw' : '3vw',
                      fontFamily: 'Poppins',
                      fontStyle: '500',
                      fontWeight: '500',
                      color: 'black',
                    },
                  }}
                  inputProps={{ className: classes.input }}
                  onChange={(e) => {
                    setDob(e.target.value);
                  }}
                />
              </div>

              <div
                style={{ marginTop: '0.31vw' }}
                className='handlemoreaboutskill'
              >
                <div
                  style={{
                    background: 'white',
                    color: 'black',
                    cursor: 'pointer',
                  }}
                  className='handlecirclieaboutsave'
                  onClick={handleClose4x}
                >
                  Cancel
                </div>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={handleupdateDob}
                  className='handlecirclieaboutsave'
                >
                  SAVE
                </div>
              </div>
            </Box>
          </Modal>
        </div>

        <div className='profileuserfirstonetitle'>
          Email - {user?.emailId}{' '}
          <span>
            <span
              style={{
                width: width > 700 ? '2vw' : '8vw',
                cursor: 'pointer',
                cursor: 'pointer',
              }}
            >
              <img
                style={{
                  width: width > 700 ? '2vw' : '8vw',
                  cursor: 'pointer',
                }}
                onClick={handleEmailModalOpen}
                src={img2}
                alt=''
              />
            </span>
          </span>
          <Modal
            open={openEmailModal}
            onClose={handleEmailModalclose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box sx={width > 700 ? email_modal_style : email_modal_style1}>
              <EmailVerify
                toggleDrawer={toggleDrawer1}
                closedrawer={toggleDrawer1('right', false)}
                width={width}
                handleEmailModalclose={handleEmailModalclose}
              />
            </Box>
          </Modal>
        </div>
        <div
          style={{ lineHeight: width > 700 ? '2vw' : '8vw', cursor: 'pointer' }}
          className='profileuserfirstonetitle'
        >
          Mobile - {user?.contactNo}
          <span>
            <span
              style={{
                width: width > 700 ? '2vw' : '8vw',
                cursor: 'pointer',
                cursor: 'pointer',
              }}
            >
              <img
                style={{
                  width: width > 700 ? '2vw' : '8vw',
                  cursor: 'pointer',
                }}
                onClick={handleContactModalOpen}
                src={img2}
                alt=''
              />
            </span>
          </span>
          <Modal
            open={openContactModal}
            onClose={handleContactModalclose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box sx={width > 700 ? contact_modal_style : contact_modal_style1}>
              <PhoneVerify
                toggleDrawer={toggleDrawer1}
                closedrawer={toggleDrawer1('right', false)}
                width={width}
                handleContactModalclose={handleContactModalclose}
              />
            </Box>
          </Modal>
        </div>
        <div className='profileuserfirstonetitle'>
          Address - {user?.address}{' '}
          <span>
            <span
              style={{
                width: width > 700 ? '2vw' : '8vw',
                cursor: 'pointer',
                cursor: 'pointer',
              }}
            >
              <img
                style={{
                  width: width > 700 ? '2vw' : '8vw',
                  cursor: 'pointer',
                }}
                onClick={handleOpen3x}
                src={img2}
                alt=''
              />
            </span>
          </span>
          <Modal
            open={open3x}
            onClose={handleClose3x}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box sx={width > 700 ? style : style1}>
              <div className='profiletitleandmenunav'>
                <div className='profiledetailstitle'>Address</div>
                <div className='profiledetailnavmanu'>
                  <div>
                    <CloseIcon
                      onClick={handleClose3x}
                      style={{
                        fontSize: width > 700 ? '1.5vw' : '4vw',
                        cursor: 'pointer',
                      }}
                    />
                  </div>
                </div>
              </div>
              <hr style={{ color: '#000000' }} />

              <div
                style={{ left: '0vw', width: '100%' }}
                className='loginfield'
              >
                <TextField
                  id='outlined-basic'
                  label='Address'
                  variant='outlined'
                  value={address}
                  rows='7'
                  style={{ width: '100%' }}
                  InputLabelProps={{
                    style: {
                      fontSize: width > 700 ? '1vw' : '3vw',
                      fontFamily: 'Poppins',
                      fontStyle: '500',
                      fontWeight: '500',
                      color: 'black',
                    },
                  }}
                  inputProps={{ className: classes.input }}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                />
              </div>

              <div
                style={{ marginTop: '0.31vw' }}
                className='handlemoreaboutskill'
              >
                <div
                  style={{
                    background: 'white',
                    color: 'black',
                    cursor: 'pointer',
                  }}
                  className='handlecirclieaboutsave'
                  onClick={handleClose3x}
                >
                  Cancel
                </div>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={handleupdateaddress}
                  className='handlecirclieaboutsave'
                >
                  SAVE
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
      <div className='profileuserdetailstwo'></div>
      <div
        style={{ marginTop: '1vw' }}
        className='profileuserdetailsthree'
      >
        <div
          style={{}}
          className='profiletitleandmenunav'
        >
          <div className='profiledetailstitle'>Work title</div>
          <div className='profiledetailnavmanu'></div>
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              fontSize: width > 700 ? '1.1vw' : '3.1vw',
              margin: '0.5vw 1vw',
            }}
            className='profiledetailstitle'
          >
            {user?.title ? user?.title : 'Update Your Title'}
          </div>
          <div
            style={{ width: width > 700 ? '2.5vw' : '8vw', cursor: 'pointer' }}
          >
            <img
              style={{ width: width > 700 ? '2.5vw' : '8vw' }}
              onClick={handleOpen2}
              src={img2}
              alt=''
            />
          </div>
          <Modal
            open={open2}
            onClose={handleClose2}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box sx={width > 700 ? style : style1}>
              <div className='profiletitleandmenunav'>
                <div className='profiledetailstitle'>Edit your title</div>
                <div className='profiledetailnavmanu'>
                  <div>
                    <CloseIcon
                      onClick={handleClose2}
                      style={{
                        fontSize: width > 700 ? '1.5vw' : '4vw',
                        cursor: 'pointer',
                      }}
                    />
                  </div>
                </div>
              </div>
              <hr style={{ color: '#000000' }} />
              <div className='jobpodtedfieldtitile'>Your Title</div>

              <div
                style={{
                  fontWeight: '400',
                  fontSize: width > 700 ? '1vw' : '2.5vw',
                  lineHeight: width > 700 ? '1.3vw' : '3.8vw',
                }}
                className='jobpodtedfieldtitile'
              >
                Enter a Description for your title in a single sentence (e.g.,
                Expert Web Designer with Back End Development)
              </div>

              <div
                style={{ left: '0vw', width: '100%' }}
                className='loginfield'
              >
                <TextField
                  id='outlined-basic'
                  label='Title'
                  value={titleuser}
                  variant='outlined'
                  style={{ width: '100%' }}
                  InputLabelProps={{
                    style: {
                      fontSize: width > 700 ? '1vw' : '3vw',
                      fontFamily: 'Poppins',
                      fontStyle: '500',
                      fontWeight: '500',
                      color: 'black',
                    },
                  }}
                  inputProps={{ className: classes.input }}
                  onChange={(e) => {
                    setTitleuser(e.target.value);
                  }}
                />
              </div>

              <hr style={{ color: '#000000' }} />
              <div
                style={{ marginTop: '0.31vw' }}
                className='handlemoreaboutskill'
              >
                <div
                  style={{
                    background: 'white',
                    color: 'black',
                    cursor: 'pointer',
                  }}
                  className='handlecirclieaboutsave'
                  onClick={handleClose2}
                >
                  Cancel
                </div>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={handletitleupdate}
                  className='handlecirclieaboutsave'
                >
                  SAVE
                </div>
              </div>
            </Box>
          </Modal>
        </div>

        <div className='profiledetailstitle'>About Me </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              fontSize: width > 700 ? '1.1vw' : '3.1vw',
              margin: '0.5vw 1vw',
            }}
            className='profiledetailstitle'
          >
            <div
              className='aboutprofileuser'
              dangerouslySetInnerHTML={{
                __html: user?.fullDescription ? user?.fullDescription : '',
              }}
            ></div>
          </div>
          <div
            style={{ width: width > 700 ? '2.5vw' : '8vw', cursor: 'pointer' }}
          >
            <img
              style={{ width: width > 700 ? '2.5vw' : '8vw' }}
              onClick={handleOpen3}
              src={img2}
              alt=''
            />
          </div>
        </div>
        <Modal
          open={open3}
          onClose={handleClose3}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={width > 700 ? style : style1}>
            <div className='profiletitleandmenunav'>
              <div className='profiledetailstitle'>Overview</div>
              <div className='profiledetailnavmanu'>
                <div>
                  <CloseIcon
                    onClick={handleClose3}
                    style={{
                      fontSize: width > 700 ? '1.5vw' : '4vw',
                      cursor: 'pointer',
                    }}
                  />
                </div>
              </div>
            </div>
            <hr style={{ color: '#000000' }} />
            <div
              style={{
                color: '#000000g6',
                fontSize: width > 700 ? '1vw' : '2.5vw',
              }}
              className='profiledetailstitle'
            >
              Use this space to show clients you have the skills and experience
              they're looking for .
            </div>

            <Texteditor
              description1={description1}
              setDescription1={setDescription1}
              setDesclength={setDesclength}
            ></Texteditor>
            <div
              style={{
                textAlign: 'right',
                fontSize: width > 700 ? '0.81vw' : '2.2vw',
                color: '#00000099',
                marginTop: '1.3vw',
                position: 'relative',
                bottom: '0vw',
              }}
            >
              {5000 - desclength} Character Left{' '}
            </div>
            <hr style={{ color: '#000000' }} />
            <div
              style={{ marginTop: '0.31vw' }}
              className='handlemoreaboutskill'
            >
              <div
                style={{
                  background: 'white',
                  color: 'black',
                  cursor: 'pointer',
                }}
                className='handlecirclieaboutsave'
                onClick={handleClose3}
              >
                Cancel
              </div>
              <div
                style={{ cursor: 'pointer' }}
                onClick={handledescriptionupdate}
                className='handlecirclieaboutsave'
              >
                SAVE
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
}
