import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Metadata } from "../../components/Metadata/Metadata";
import WebsiteBanner from "../../components/Website/WebsiteBanner";
import WebsiteFooter from "../../components/Website/WebsiteFooter";
import WebsiteOffers from "../../components/Website/WebsiteOffers";
import WebsiteProd from "../../components/Website/WebsiteProd";
import API_HOST from "../../env";

export default function Website({ width }) {
  const { website, id } = useParams();
  const [websitedata, setwebsitedata] = useState();

  useEffect(() => {
    axios
      .get(`${API_HOST}/websites/forAll?websiteId=${id}`, {
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        setwebsitedata(res.data?.success?.data);
        axios
        .get(`${API_HOST}/websites/forClientCount?websiteId=${id}`, {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("token")),
            },
          })
          .then((res) => {});
      });
  }, []);

  return (
    websitedata && (
      <div style={{ width: "100vw" }}>
        <Metadata
          title={`${websitedata?.websiteName}`}
          description={websitedata?.metaDescription}
          keyword={`${websitedata?.websiteName} ,${websitedata?.metaDescription}, ${websitedata?.bannerTitle},${websitedata?.headline} ,${websitedata?.headline2} `}
        ></Metadata>
        <WebsiteBanner width={width} websitedata={websitedata} />
        <WebsiteOffers width={width} websitedata={websitedata} />
        <WebsiteProd width={width} websitedata={websitedata} />
        <WebsiteFooter width={width} websitedata={websitedata} />
      </div>
    )
  );
}
