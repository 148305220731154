import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import React, { useState } from "react";
import Popover from "@mui/material/Popover";

import Typography from "@mui/material/Typography";
import { makeStyles } from "@material-ui/core";
import { TextField } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  input: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "0.91vw",
    color: "#263238",
    border: "yellow !important",
  },
}));
export default function Productdata({
  width,
  data,
  index,
  setArrayofservices,
  arrayofservices,
  products,
  setSelectedCategory,
  setsetSelectedCategory,
}) {
  const [anchorElx2, setAnchorElx2] = React.useState(null);
  const handleClickx2 = (event) => {
    setAnchorElx2(event.currentTarget);
  };
  const classes = useStyles();
  const handleClosex2 = () => {
    setAnchorElx2(null);
  };

  const openx2 = Boolean(anchorElx2);
  const idx2 = openx2 ? "simple-popover" : undefined;

  const [anchorEl1, setAnchorEl1] = useState(null);
  const open1 = Boolean(anchorEl1);
  const id1 = open1 ? "simple-popover" : undefined;
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const [arrayoflongdegree1, setArrayoflongdegree1] = useState([
    "0",
    5,
    12,
    18,
    28,
  ]);

  return (
    <>
      <div
        style={{ marginTop: "0.5vw", flexWrap: "wrap", height: "fit-content" }}
        className="servicesmenudiv"
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              left: "0vw",
              width: width > 700 ? "99%" : "99%",
              marginLeft: "0%",
            }}
            className="loginfield"
            onClick={handleClickx2}
          >
            <TextField
              id="outlined-basic"
              label="Name"
              variant="outlined"
              value={data?.productName}
              style={{ width: "99%" }}
              InputLabelProps={{
                style: {
                  fontSize: width > 700 ? "1vw" : "3vw",
                  fontFamily: "Poppins",
                  fontStyle: "500",
                  fontWeight: "500",
                  color: "black",
                  paddingRight: "2vw",
                },
              }}
              inputProps={{ className: classes.input }}
              onChange={(e) => {
                setArrayofservices([
                  ...arrayofservices?.slice(0, index),
                  { ...data, productName: e.target?.value },
                  ...arrayofservices?.slice(index + 1, arrayofservices?.length),
                ]);
              }}
            />
            {width > 700 && (
              <span style={{ width: "0.1vw" }}>
                <KeyboardArrowDownOutlined
                  style={{
                    fontSize: "1.5vw",
                    position: "relative",
                    right: "2vw",
                    top: "1vw",
                  }}
                />
              </span>
            )}
          </div>

          <Popover
            id={idx2}
            open={openx2}
            anchorEl={anchorElx2}
            onClose={handleClosex2}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div
              style={{
                maxHeight: width > 700 ? "18vw" : "40vw",
                overflow: "scroll",
                width: width > 700 ? "33vw" : "90vw",
              }}
            >
              <Typography
                sx={{
                  p: 1,
                  pl: width > 700 ? 1 : 0,
                  ml: 1,
                  pr: 0,
                  width: width > 700 ? "30vw" : "89vw",
                  position: "fixed",
                  background: "white",
                  zIndex: "10",
                }}
              >
                <input
                  placeholder="search here .."
                  onChange={(e) => {
                    setsetSelectedCategory(e.target.value);
                  }}
                  style={{
                    width: "96%",
                    border: "1.5px solid #00000050",
                    outline: "none",
                    height: "2.5",
                    borderRadius: "0.21vw",
                  }}
                />
              </Typography>
              <Typography
                sx={{
                  p: 2.5,
                  pl: 1,
                  ml: 1,
                  width: "100%",
                  cursor: "pointer",
                }}
              ></Typography>

              {products?.length > 0 &&
                products.map((data1) => {
                  return (
                    <Typography
                      sx={{
                        p: 0.51,
                        pl: 1,
                        ml: 1,
                        width: "100%",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        setArrayofservices([
                          ...arrayofservices?.slice(0, index),
                          {
                            productName: data1?.productName,
                            productGst: data1?.gstRate,
                            hsnCode: data1?.hsnCode,
                            rate: data1?.costPrice,
                            qty: data?.qty,
                            value:
                              data1?.costPrice * (data?.qty ? data?.qty : 0),
                          },
                          ...arrayofservices?.slice(
                            index + 1,
                            arrayofservices?.length
                          ),
                        ]);
                        handleClosex2();
                      }}
                    >
                      {data1?.productName}
                    </Typography>
                  );
                })}
            </div>
          </Popover>
          <div
            style={{
              left: "0vw",
              width: width > 700 ? "99%" : "99%",
              marginLeft: "0%",
            }}
            className="loginfield"
            onClick={handleClick1}
          >
            <TextField
              id="outlined-basic"
              label="GST *"
              variant="outlined"
              disabled
              value={data.productGst ? data.productGst + " %" : ""}
              style={{ width: "100%" }}
              InputLabelProps={{
                style: {
                  fontSize: width > 700 ? "1vw" : "3vw",
                  fontFamily: "Poppins",
                  fontStyle: "500",
                  fontWeight: "500",
                  color: "black",
                },
              }}
              inputProps={{ className: classes.input }}
              onChange={(e) => {
                console.log(e.target.value);
              }}
            />
            {width > 700 && (
              <span style={{ width: "0.1vw" }}>
                <KeyboardArrowDownOutlined
                  style={{
                    fontSize: "1.5vw",
                    position: "relative",
                    right: "2vw",
                    top: "1vw",
                  }}
                />
              </span>
            )}
          </div>

          <Popover
            id={id1}
            open={open1}
            anchorEl={anchorEl1}
            onClose={handleClose1}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div
              style={{
                maxHeight: width > 700 ? "18vw" : "40vw",
                overflow: "scroll",
                width: width > 700 ? "33vw" : "90vw",
              }}
            >
              {arrayoflongdegree1?.length > 0 &&
                arrayoflongdegree1.map((data11) => {
                  return (
                    <Typography
                      sx={{
                        p: 0.51,
                        pl: 1,
                        ml: 1,
                        width: "100%",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setArrayofservices([
                          ...arrayofservices?.slice(0, index),
                          {
                            ...data,
                            productGst: data11,
                            productGstValue:
                              data?.qty *
                              (data.rate ? data.rate : 0) *
                              (data11 / 100),

                            totalValue:
                              data?.qty * (data.rate ? data.rate : 0) +
                              data?.qty *
                                (data.rate ? data.rate : 0) *
                                (data11 / 100),
                          },
                          ...arrayofservices?.slice(
                            index + 1,
                            arrayofservices?.length
                          ),
                        ]);

                        handleClose1();
                      }}
                    >
                      {data11} %
                    </Typography>
                  );
                })}
            </div>
          </Popover>
        </div>
        <div className="servicesmenuname1boxWInc mb-2">
          <input
            value={data?.extraDescription}
            type="text"
            placeholder="Sell Description"
            onChange={(e) => {
              setArrayofservices([
                ...arrayofservices?.slice(0, index),
                { ...data, extraDescription: e.target?.value },
                ...arrayofservices?.slice(index + 1, arrayofservices?.length),
              ]);
            }}
          />
        </div>
        <div style={{ width: "30%", marginBottom: "0.25vw" }}>
          <div
            className=""
            style={{
              fontSize: width > 700 ? "1vw" : "3vw",
              fontFamily: "Poppins",
              fontStyle: "500",
              fontWeight: "500",
              color: "#000000",
              display: "inline",
              margin: "0 0.2vw",
            }}
          >
            HSN Code
          </div>
        </div>
        <div style={{ width: "30%", marginBottom: "0.25vw" }}>
          <div
            className=""
            style={{
              fontSize: width > 700 ? "1vw" : "3vw",
              fontFamily: "Poppins",
              fontStyle: "500",
              fontWeight: "500",
              color: "#000000",
              display: "inline",
              margin: "0 0.2vw",
            }}
          >
            Price
          </div>
        </div>
        <div style={{ width: "30%", marginBottom: "0.25vw" }}>
          <div
            className=""
            style={{
              fontSize: width > 700 ? "1vw" : "3vw",
              fontFamily: "Poppins",
              fontStyle: "500",
              fontWeight: "500",
              color: "#000000",
              display: "inline",
              margin: "0 0.2vw",
            }}
          >
            Quantity/ No’s
          </div>
        </div>

        <div
          style={{ width: "30%", marginBottom: "0.5vw" }}
          className="servicesmenuname3box"
        >
          <input
            value={data?.hsnCode}
            type="number"
            placeholder="HSN Code"
            onChange={(e) => {
              setArrayofservices([
                ...arrayofservices?.slice(0, index),
                { ...data, hsnCode: e.target?.value },
                ...arrayofservices?.slice(index + 1, arrayofservices?.length),
              ]);
            }}
          />
        </div>

        <div
          style={{ width: "30%", marginBottom: "0.5vw" }}
          className="servicesmenuname2box"
        >
          Rs.
          <input
            value={data?.rate}
            type="number"
            placeholder="Price / Rate"
            onChange={(e) => {
              setArrayofservices([
                ...arrayofservices?.slice(0, index),
                {
                  ...data,
                  productGstValue:
                    data?.qty *
                    (e.target?.value ? e.target?.value : 0) *
                    (data.productGst / 100),

                  totalValue:
                    data?.qty * (e.target?.value ? e.target?.value : 0) +
                    data?.qty *
                      (e.target?.value ? e.target?.value : 0) *
                      (data.productGst / 100),
                  rate: e.target?.value,
                  value: e.target?.value * (data?.qty ? data?.qty : 0),
                },
                ...arrayofservices?.slice(index + 1, arrayofservices?.length),
              ]);
            }}
          />
        </div>
        <div
          style={{ width: "30%", marginBottom: "0.5vw" }}
          className="servicesmenuname2box"
        >
          <input
            value={data?.qty}
            type="number"
            placeholder="Quantity/ Units"
            onChange={(e) => {
              setArrayofservices([
                ...arrayofservices?.slice(0, index),
                {
                  ...data,
                  qty: e.target?.value,
                  productGstValue:
                    e.target?.value *
                    (data?.rate ? data?.rate : 0) *
                    (data.productGst / 100),

                  totalValue:
                    e.target?.value * (data?.rate ? data?.rate : 0) +
                    e.target?.value *
                      (data?.rate ? data?.rate : 0) *
                      (data.productGst / 100),
                  value: e.target?.value * (data?.rate ? data?.rate : 0),
                },
                ...arrayofservices?.slice(index + 1, arrayofservices?.length),
              ]);
            }}
          />
        </div>
        <div style={{ width: "30%", marginBottom: "0.25vw" }}>
          <div
            className=""
            style={{
              fontSize: width > 700 ? "1vw" : "3vw",
              fontFamily: "Poppins",
              fontStyle: "500",
              fontWeight: "500",
              color: "#000000",
              display: "inline",
              margin: "0 0.2vw",
            }}
          >
            Total Value
          </div>
        </div>
        <div style={{ width: "30%", marginBottom: "0.25vw" }}>
          <div
            className=""
            style={{
              fontSize: width > 700 ? "1vw" : "3vw",
              fontFamily: "Poppins",
              fontStyle: "500",
              fontWeight: "500",
              color: "#000000",
              display: "inline",
              margin: "0 0.2vw",
            }}
          >
            Total tax
          </div>
        </div>
        <div style={{ width: "30%", marginBottom: "0.25vw" }}>
          <div
            className=""
            style={{
              fontSize: width > 700 ? "1vw" : "3vw",
              fontFamily: "Poppins",
              fontStyle: "500",
              fontWeight: "500",
              color: "#000000",
              display: "inline",
              margin: "0 0.2vw",
            }}
          >
            Final Value
          </div>
        </div>

        <div
          style={{ width: "30%", marginBottom: "0.5vw" }}
          className="servicesmenuname2box"
        >
          Rs.
          <input
            value={data?.value}
            type="number"
            placeholder="Total value"
            disabled
          />
        </div>

        <div
          style={{ width: "30%", marginBottom: "0.5vw" }}
          className="servicesmenuname2box"
        >
          Rs.
          <input
            value={data?.productGstValue}
            type="number"
            placeholder="Total tax"
            disabled
          />
        </div>

        <div
          style={{ width: "30%", marginBottom: "0.5vw" }}
          className="servicesmenuname2box"
        >
          Rs.
          <input
            value={data?.totalValue}
            type="number"
            placeholder="Final Value"
            disabled
          />
        </div>
      </div>
    </>
  );
}
