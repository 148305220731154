import React from "react";
import Catelogfaqquestion from "../Catalogue/Catelogfaqquestion";
import "../Catalogue/catalogue.css";
import SearchIcon from "@mui/icons-material/Search";
export default function SettingFaq() {
  return (
    <div>
      <div
        style={{ margin: "auto", marginBottom: "5vw" }}
        className="cafaqcontainer"
      >
        <div className="catesearchboxfaq">
          <span>
            <SearchIcon style={{ margin: "0vw 0.81vw", fontSize: "1.7vw" }} />
          </span>
          <input
            type="text"
            placeholder="Have questioners? we're here to help"
          />
        </div>
        <Catelogfaqquestion />
        <Catelogfaqquestion />
        <Catelogfaqquestion />
        <Catelogfaqquestion />
        <Catelogfaqquestion />
        <Catelogfaqquestion />
      </div>
    </div>
  );
}
