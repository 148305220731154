import React, { useState } from "react";

import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import { makeStyles } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { DeleteOutlineOutlined } from "@mui/icons-material";

import { TextField } from "@mui/material";
import axios from "axios";

import API_HOST from "../../../env";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  maxHeight: "95vh",
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  maxHeight: "95vh",
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
};
const useStyles = makeStyles((theme) => ({
  input: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "0.91vw",
    color: "#263238",
    border: "yellow !important",
  },
}));
export default function Profilerefrencedetail({
  education,
  width,
  setcertificated,
  user,setRefrencesall
}) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);


  const [callagename, setCallagename] = useState(education?.name);
  const [degreeset, setDegreeset] = useState(education?.title);
  const [studyset, setstudyset] = useState(education?.phone);
  const [descriptioncallageadd, setdescriptioncallageadd] = useState(
    education?.emailId
  );
  const handleeducationdelete = () => {
    const data = {
      refrenceId: education?.refrenceId,
    };
    axios
      .post(`${API_HOST}/users/removeRefrence`, data, {
        headers: {
          // "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        if (user?.userName) {
          axios
            .get(
              `${API_HOST}/users/viewRefrence??pageSize=50&pageNumber=1&userName=${user?.userName}`
            )
            .then((res) => {
              setcertificated(res?.data?.success?.data);
   
              setRefrencesall(res?.data?.success?.data);
            });
        }
        handleClose1();
      });
  };

  const handleeducationedit = () => {
    const formdata = {
      refrenceId: education?.refrenceId,
      emailId: descriptioncallageadd,
      name: callagename,
      title: degreeset,
      phone: studyset,
    };
    axios
      .post(`${API_HOST}/users/editRefrence`, formdata, {
        headers: {
          // "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        if (user?.userName) {
          axios
            .get(
              `${API_HOST}/users/viewRefrence??pageSize=50&pageNumber=1&userName=${user?.userName}`
            )
            .then((res) => {
              setcertificated(res?.data?.success?.data);
            });
        }
        handleClose();
      });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "1vw 2vw",
        }}
      >
        <div style={{ padding: "0vw" }} className="pworkexperinxecontainer">
          <div className="pworkexperincebox">
            <div className="pworkexperinceboxtext">
              <div className="pworkexperincebox-name">
                {education?.name}
              </div>
              <div
                style={{ margin: width > 700 ? "0.1vw" : "0.5vw" }}
                className="pworkexperincebox-name1"
              >
                {education?.title} 
              </div>
              <div
                style={{
                  fontSize: width > 700 ? "0.851vw" : "2vw",
                  color: "black",
                  opacity: "0.7",
                }}
                className="profileuserfirstonedata"
              >
                {education?.phone}  |  {education?.emailId}
              </div>
             
            </div>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <EditIcon
            onClick={handleOpen}
            style={{
              fontSize: width > 700 ? "1.6vw" : "4vw",
              color: "gray",
              margin: "0 0.61vw",
              cursor: "pointer",
            }}
          />{" "}
          <DeleteOutlineOutlined
            onClick={handleOpen1}
            style={{
              fontSize: width > 700 ? "1.6vw" : "4vw",
              color: "gray",
              margin: "0 0.61vw",
              cursor: "pointer",
            }}
          />
        </div>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={width > 700 ? style : style1}>
            <div className="profiletitleandmenunav">
              <div className="profiledetailstitle">Edit Refrence</div>
              <div className="profiledetailnavmanu">
                <div>
                  <CloseIcon
                    onClick={handleClose}
                    style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
                  />
                </div>
              </div>
            </div>
            <hr style={{ color: "#000000" }} />
            <div style={{ left: "0vw", width: "100%" }} className="loginfield">
              <TextField
                id="outlined-basic"
                label="Full Name"
                variant="outlined"
                value={callagename}
                multiline
                style={{ width: "100%" }}
                InputLabelProps={{
                  style: {
                    fontSize: width > 700 ? "1vw" : "3vw",
                    fontFamily: "Poppins",
                    fontStyle: "500",
                    fontWeight: "500",
                    color: "black",
                  },
                }}
                inputProps={{ className: classes.input }}
                onChange={(e) => {
                  setCallagename(e.target.value);
                }}
              />
            </div>

            <div style={{ left: "0vw", width: "100%" }} className="loginfield">
              <TextField
                id="outlined-basic"
                label="Title"
                variant="outlined"
                value={degreeset}
                style={{ width: "100%" }}
                InputLabelProps={{
                  style: {
                    fontSize: width > 700 ? "1vw" : "3vw",

                    fontFamily: "Poppins",
                    fontStyle: "500",
                    fontWeight: "500",
                    color: "black",
                  },
                }}
                inputProps={{ className: classes.input }}
                onChange={(e) => {
                  setDegreeset(e.target.value);
                }}
              />
            </div>
            <div style={{ left: "0vw", width: "100%" }} className="loginfield">
              <TextField
                id="outlined-basic"
                label="Phone number"
                variant="outlined"
                value={studyset}
                type="number"
                style={{ width: "100%" }}
                InputLabelProps={{
                  style: {
                    fontSize: width > 700 ? "1vw" : "3vw",
                    fontFamily: "Poppins",
                    fontStyle: "500",
                    fontWeight: "500",
                    color: "black",
                  },
                }}
                inputProps={{ className: classes.input }}
                onChange={(e) => {
                  setstudyset(e.target.value);
                }}
              />
            </div>

            <div style={{ left: "0vw", width: "100%" }} className="loginfield">
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                value={descriptioncallageadd}
                style={{ width: "100%" }}
                InputLabelProps={{
                  style: {
                    fontSize: width > 700 ? "1vw" : "3vw",
                    fontFamily: "Poppins",
                    fontStyle: "500",
                    fontWeight: "500",
                    color: "black",
                  },
                }}
                inputProps={{ className: classes.input }}
                onChange={(e) => {
                  setdescriptioncallageadd(e.target.value);
                }}
              />
            </div>

            <hr style={{ color: "#000000" }} />
            <div
              style={{ marginTop: "0.31vw" }}
              className="handlemoreaboutskill"
            >
              <div
                style={{
                  background: "white",
                  color: "black",
                  cursor: "pointer",
                }}
                className="handlecirclieaboutsave"
                onClick={handleClose}
              >
                Cancel
              </div>
              <div
                style={{ cursor: "pointer" }}
                className="handlecirclieaboutsave"
                onClick={handleeducationedit}
              >
                SAVE
              </div>
            </div>
          </Box>
        </Modal>
        <Modal
          open={open1}
          onClose={handleClose1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={width > 700 ? style : style1}>
            <div className="profiletitleandmenunav">
              <div className="profiledetailstitle">Delete Refrence</div>
              <div className="profiledetailnavmanu">
                <div>
                  <CloseIcon
                    onClick={handleClose1}
                    style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
                  />
                </div>
              </div>
            </div>
            <hr style={{ color: "#000000" }} />
            <div
              style={{
                color: "gray",
                fontSize: width > 700 ? "1vw" : "2.6vw",
                fontWeight: "300",
              }}
              className="profiledetailstitle"
            >
              The action will delete "{education?.name}" From all of your
              profiles.
            </div>
            <div
              style={{
                color: "gray",
                fontSize: width > 700 ? "1vw" : "2.5vw",
                fontWeight: "400",
                marginBottom: "5vw",
              }}
              className="profiledetailstitle"
            >
              Are you sure you want to delete this Refrence?
            </div>
            <hr style={{ color: "#000000" }} />
            <div
              style={{ marginTop: "0.31vw" }}
              className="handlemoreaboutskill"
            >
              <div
                style={{
                  background: "white",
                  color: "black",
                  cursor: "pointer",
                }}
                className="handlecirclieaboutsave"
                onClick={handleClose1}
              >
                Cancel
              </div>
              <div
                style={{ cursor: "pointer", background: "red" }}
                onClick={handleeducationdelete}
                className="handlecirclieaboutsave"
              >
                Delete
              </div>
            </div>
          </Box>
        </Modal>
      </div>
      <hr
        style={{
          width: "70vw",
          margin: "0 4vw",
          marginBottom: "1vw",
          color: "#000000",
        }}
      />
    </>
  );
}
