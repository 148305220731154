import React from "react";

export default function StoryDetailPart({ data, width }) {
    console.log("ok", data);
  return (
    <>
      <div style={{ padding: "2vw", }}>
        <div  dangerouslySetInnerHTML={{ __html: data?.content}} className=""></div>
      </div>
    </>
  );
}
