import React from "react";

export default function Blogdetailpart({ data, width }) {
    console.log("ok");
  return (
    <>
      <div
        style={{
          paddingTop: "0vw",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
        className="Blogdbannercontainer"
      >
        {width > 700 && (
          <div
            style={{
              width: width > 700 ? "20vw" : "96vw",
              position: "sticky",
              top: width > 700 ? "5vw" : "20vw",
            }}
          >
            <div
              style={{
                position: "relative",
                top: width > 700 ? "1vw" : "-3vw",
              }}
            >
              <div className="FirstBlogcontnetbox">
                <div className="titileofblogcontnet">Table of Content</div>
                {data?.toC?.length > 0 &&
                  data?.toC?.map((data1, index) => {
                    return (
                      <a
                        style={{ height: width > 700 ? "2vw" : "4vw" }}
                        href={`#titileofblogcontnet${index + 1}`}
                      >
                        <div className="dataeofblogcontnet">
                          {data?.type1 ? (
                            <div
                              style={{
                                fontSize: "1vw !important",
                                fontWeight: "500",
                              }}
                            >
                              <li>{data1?.heading}</li>
                            </div>
                          ) : (
                            <div
                              style={{
                                fontSize: "1vw !important",
                                fontWeight: "500",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: data1?.heading,
                              }}
                            ></div>
                          )}
                        </div>
                      </a>
                    );
                  })}
              </div>
            </div>
          </div>
        )}

        <div className="blogdetaildata">
          <div style={{ width: width > 700 ? "70vw" : "92vw" }}>
            {data &&
              data?.toC?.map((data1, index) => {
                return (
                  <div
                    style={{
                      position: "relative",
                      bottom: width > 700 ? "5vw" : "8vw",
                    }}
                    id={`titileofblogcontnet${index + 1}`}
                  >
                    <div
                      style={{
                        position: "relative",
                        top: width > 700 ? "6vw" : "15vw",
                      }}
                    >
                      {data?.type1 && (
                        <div className="titileofblogcontnet1">
                          {" "}
                          {data1?.heading}
                        </div>
                      )}
                      <div
                        className="dataeofblogcontnet1"
                        style={{
                          color: "black",
                          fontSize: width > 700 ? "1vw" : "2.5vw",
                          overflow: "scroll",
                        }}
                        dangerouslySetInnerHTML={{ __html:data1?.toc}}
                      ></div>

                      {console.log(data1?.toc?.replaceAll('<br>', '').replaceAll('&nbsp;', '').replaceAll('</h4><h4>', '\n• ')) }

                      {data1?.title && (
                        <div className="Joinwithusblogbox">
                          <div
                            style={{
                              width: width > 700 ? "70%" : "70%",
                              textAlign: "center",
                              background: "#",
                            }}
                          >
                            <div className="Joinwithusblogboxtitle">
                              {data1?.title}
                            </div>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: data1?.desc?.replaceAll('\n' , '\n '),
                              }}
                              className="Joinwithusblogboxdetail"
                            ></div>
                          </div>
                          <div
                            style={{
                              width: width > 700 ? "30%" : "fit-content",
                              textAlign: "center",
                            }}
                          >
                            {data1?.button && (
                              <a  target="_blank" href={data1?.buttonlink} rel="noopener noreferrer">
                                <button>{data1?.button} </button>
                              </a>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
}
