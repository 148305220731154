import React, { useState } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import img from "../../../assets/Dashboard/Upload Image.svg";
import API_HOST from "../../../env";
import { Link } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { TextField } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import StarIcon from "@mui/icons-material/Star";
import ReactImageMagnify from "react-image-magnify";
import Slider from "react-slick";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import imgxx from "../../../assets/Success stories Definition/checkmark (1).svg";

import img1 from "../../../assets/About/Iconly-Light-Location.svg";

function SamplePrevArrow(props) {
  return (
    <div
      style={{
        width: "3vw",
        height: "3vw",

        borderRadius: "50%",
        background: "#FFF",
        boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.2)",
        margin: "1vw",
      }}
      className="d-flex justify-content-center align-items-center"
      onClick={props.onClick}
    >
      <ArrowUpwardIcon alt="prev" />
    </div>
  );
}

function SampleNextArrow(props) {
  return (
    <div
      style={{
        width: "3vw",
        height: "3vw",
        borderRadius: "50%",
        background: "#FFF",
        boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.2)",
        margin: "1vw",
      }}
      className="d-flex justify-content-center align-items-center"
      onClick={props.onClick}
    >
      <ArrowDownwardIcon alt="next" />
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  input: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "0.91vw",

    color: "#263238",
    border: "yellow !important",
  },
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "55vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: "1vw",
};
const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: "0.51vw",
};
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "96vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: "0vw",
};

export default function WebsiteProd({ width, websitedata, setwebsitedata }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const classes = useStyles();
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [qty, setQty] = useState();
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  const deleltecatalogue = () => {
    console.log(deleteid);
    axios
      .post(
        `${API_HOST}/webmodel/remove`,
        {
          webId: deleteid,
        },
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((res) => {
        axios
          .get(
            `${API_HOST}/websites/forAll?websiteId=${websitedata?.websiteId}`,
            {
              headers: {
                Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem("token")),
              },
            }
          )
          .then((res) => {
            setwebsitedata(res.data?.success?.data);
            handleClose();
          });
      });
  };
  const editcatalogue = () => {
    axios
      .post(
        `${API_HOST}/webmodel/edit`,
        {
          webId: edit?.webId,
          quantity: qty,
        },
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((res) => {
        axios
          .get(
            `${API_HOST}/websites/forAll?websiteId=${websitedata?.websiteId}`,
            {
              headers: {
                Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem("token")),
              },
            }
          )
          .then((res) => {
            setwebsitedata(res.data?.success?.data);
            handleClose2();
          });
      });
  };

  const [deleteid, setdeleteid] = useState();

  const [edit, setEdit] = useState();

  const [proddata, setproddata] = useState();

  let settings = {
    infinite: false,
    speed: 250,
    arrows: true,
    slidesToShow: 3,
    initialSlide: 0,
    slidesToScroll: 2,
    vertical: true,
    swipeToSlide: true,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
  };
  const [displayImage, setDisplayImage] = useState(0);
  const sliderClick = (value) => {
    setDisplayImage(value);
  };

  return (
    <div className="websiteoffercontainerx">
      <div style={{ color: websitedata?.themeColor }} className="offerheading">
        {websitedata?.menuHeadlines}
      </div>
      <div className="flexofoffers">
        {websitedata?.webId?.length > 0 &&
          websitedata?.webId?.map((data) => {
            return (
              <div
                className="Menuwebsite"
                style={{position: "relative",}}
                // style={{ height: "100%", width: width > 700 ? "25vw" : "90vw" }}
              >
                <div style={{ width: "0vw", height: "0vw" }}>
                  <div
                    onClick={() => {
                      setdeleteid(data?.web_id?.webId);
                      handleOpen();
                    }}
                    style={{
                      // position: "relative",
                      // left: width > 700 ? "13.6vw" : "39.5vw",
                      // bottom: width > 700 ? "1.7vw" : "3.5vw",
                      position: "absolute",
                      top: "-20px",
                      right: "-10px"
                    }}
                  >
                    <RemoveCircleOutlineIcon
                      style={{
                        width: width > 700 ? "2vw" : "8vw",
                        height: width > 700 ? "2vw" : "8vw",
                        color: "#0052CC",
                        fontWeight: "600",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>

                <div>
                  <img
                    style={{
                      width: width > 700 ? "25vw" : "100%",
                      height: width > 700 ? "12vw" : "50vw",
                      filter:
                        data?.web_id?.product_id?.type !== "Service" &&
                        data?.quantity < 1 &&
                        "grayscale(1)",
                      cursor: "pointer",
                    }}
                    src={data?.web_id?.product_id?.files[0]?.file}
                    alt=""
                    onClick={() => {
                      handleOpen1();
                      setproddata(data?.web_id);
                    }}
                  />

                  <div className="m-2">
                    <div
                      onClick={() => {
                        handleOpen1();
                        setproddata(data?.web_id);
                      }}
                      style={{ cursor: "pointer" }}
                      className="namemenuweb ellipsis"
                    >
                      {data?.web_id?.product_id?.productName}
                    </div>
                    <div
                      style={{
                        height: width > 700 ? "1.5vw" : "4.5vw",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingTop: "0.2vw",
                      }}
                      className="pricemenuweb mt-2"
                    >
                      <div
                        style={{
                          fontSize: width > 700 ? "0.85vw" : "2.5vw",
                          display: "flex",
                          marginTop: "0.1vw",
                          alignItems: "center",
                        }}
                      >
                        {websitedata?.userId?.verifiedByAdmin === true && (
                          <img
                            style={{
                              width: width > 700 ? "1.1vw" : "3.1vw",
                              marginRight: "0.3vw",
                              height: "3.2vh",
                              objectFit: "contain",
                            }}
                            src={imgxx}
                            alt=""
                          />
                        )}
                        {websitedata?.userId?.fullName}
                        {websitedata?.userId?.certifiedByAdmin === true && (
                          <span style={{ display: "flex" }}>
                            (
                            <WorkspacePremiumIcon
                              style={{
                                width: width > 700 ? "1.1vw" : "3.1vw",
                                height: width > 700 ? "1.1vw" : "3.1vw",
                                margin: "0vw",
                                color: "#0052cc",
                              }}
                              alt=""
                            />
                            )
                          </span>
                        )}
                      </div>
                      <div className="d-flex align-items-center rating_text">
                        <div
                          className="d-flex align-items-center"
                          style={{ marginRight: "5px" }}
                        >
                          <span style={{ color: "#0052cc", marginTop: "2px" }}>
                            {data?.web_id?.product_id?.type
                              ? data?.web_id?.product_id?.type
                              : "Product"}
                          </span>
                          <StarIcon
                            style={{
                              width: width > 700 ? "1vw" : "3.5vw",
                              marginLeft: "5px",
                              color: "#0052cc",
                              height: "14px",
                            }}
                          />{" "}
                        </div>
                        <div style={{ marginTop: "2px" }}>
                          {data?.web_id?.product_id?.rating?.$numberDecimal
                            ? data?.web_id?.product_id?.rating?.$numberDecimal?.slice(0, 1)
                            : 0}{" "}
                          (
                          {data?.web_id?.product_id?.completedOrder
                            ? data?.web_id?.product_id?.completedOrder
                            : 0}
                          )
                        </div>
                      </div>
                    </div>
                    <div
                      className="multiline-ellipsis"
                      style={{
                        fontSize:width > 700 ? "0.95vw": "2.8vw" ,
                        paddingTop: "2px",
                        paddingBottom: "2px",
                        height: "2.5vw",
                      }}
                    >
                      {data?.web_id?.product_id?.description !== "undefined"
                        ? data?.web_id?.product_id?.description || ""
                        : ""}
                    </div>
                    <hr style={{ padding: "0vw", margin: "0.3vw" }}></hr>
                    <div
                      style={{
                        height: width > 700 ? "1.5vw" : "4.5vw",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingTop: "0.2vw",
                      }}
                      className="pricemenuweb mt-2"
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {" "}
                        <span>
                          <img
                            style={{
                              height: width > 700 ? "fit-content" : "6vw",
                              width: width > 700 ? "1.2vw" : "6vw",
                              margin: "0vw",
                              marginRight: "0.2vw",
                              maxHeight: width > 700 ? "1.5vw" : "4vw",
                            }}
                            src={websitedata?.websiteLogo}
                            alt=""
                          />
                        </span>{" "}
                        <div className="ellipsis" style={{fontSize: width > 700 ? "0.8vw" :"2.8vw", marginTop:"2px"}}>{websitedata?.websiteName}</div>
                      </div>{" "}
                      <div
                        style={{
                          color:
                            data?.web_id?.product_id?.type === "Service"
                              ? ""
                              : data?.quantity > 0
                              ? ""
                              : "red",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        className="stockmenuweb"
                      >
                        {data?.web_id?.product_id?.type === "Service"
                          ? data?.web_id?.product_id?.serviceType
                          : data?.quantity > 0
                          ? data?.quantity + " Items Left"
                          : "Out of Stock"}{" "}
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingTop: "0.2vw",
                      }}
                      className="pricemenuweb p-0 w-100"
                    >
                      <div
                        style={{ display: "flex", alignItems: "center" }}
                        className="w-100"
                      >
                        {" "}
                        <span>
                          <img
                            style={{
                              height: "fit-content",
                              width: width > 700 ? "1.3vw" : "3.7vw",
                              margin: "0vw",
                              marginRight: "0.2vw",
                            }}
                            src={img1}
                            alt=""
                          />
                        </span>{" "}
                        <div className="ellipsis">
                          {" "}
                          {websitedata?.companyAddress}
                        </div>
                      </div>{" "}
                    </div>
                    <hr style={{ padding: "0vw", margin: "0.3vw" }}></hr>
                    <div className="d-flex align-items-center justify-content-between mt-2">
                      <div
                        style={{
                          paddingBottom: "0.2vw",
                          height: width > 700 ? "2vw" : "5vw",
                          fontSize: width > 700 ? "1.1vw" : "2.8vw",
                        }}
                        className="pricemenuweb"
                      >
                        <p style={{fontSize:"0.9vw"}}>Price</p>
                        <div className="tagblue d-flex align-items-center" style={{marginLeft:"8px"}}>
                        <span style={{fontSize: width > 700 ? "0.75vw" : "3vw"}}>Rs. {data?.web_id?.product_id?.costPrice}</span>{" "}
                        {data?.web_id?.product_id?.sellingPrice && (
                          <del
                            style={{
                              display: "flex",
                              fontSize: width > 700 ? "0.6vw" : "2vw",
                              height: width > 700 ? "0.8vw" : "2.5vw",
                              margin: "0 0.5vw",
                            }}
                          >
                            ( Rs {data?.web_id?.product_id?.sellingPrice})
                          </del>
                        )}
                        {data?.web_id?.product_id?.sellingPrice && (
                          <span >
                            {parseFloat(
                              ((data?.web_id?.product_id?.sellingPrice -
                                data?.web_id?.product_id?.costPrice) *
                                100) /
                                data?.web_id?.product_id?.sellingPrice
                            ).toFixed(0)}
                            % off{" "}
                          </span>
                        )}
                        </div>
                        
                      </div>
                        {/* <div onClick={() => data?.website_id[0]?.websiteName ? navigate(`/${contentNameRoute}/${data?.website_id[0]?.websiteId}`)
                              : navigate(`/-/${data?.website_id[0]?.websiteId}`) }
                          className="visit_btn"
                        > Visit Website
                        </div> */}
                      </div>
                  </div>
                </div>
                {/* <div>
                  <img
                    style={{
                      filter:
                        data?.web_id?.product_id?.type === "Product" &&
                        data?.web_id?.quantity < 1 &&
                        "grayscale(1)",
                      cursor: "pointer",
                    }}
                    src={data?.web_id?.product_id?.files[0]?.file}
                    alt=""
                    onClick={() => {
                      handleOpen1();
                      setproddata(data?.web_id);
                    }}
                  />
                  <div
                    onClick={() => {
                      handleOpen1();
                      setproddata(data?.web_id);
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                    className="namemenuweb"
                  >
                    {data?.web_id?.product_id?.productName?.slice(0, 18)}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      margin: width <= 700 && "0.51vw",
                    }}
                  >
                    <div
                      style={{
                        color:
                          data?.web_id?.product_id?.type === "Product" &&
                          data?.web_id?.quantity < 1
                            ? "red"
                            : "",
                      }}
                      className="stockmenuweb"
                    >
                      {data?.web_id?.product_id?.type === "Product"
                        ? data?.web_id?.quantity > 0
                          ? data?.web_id?.quantity + " Items Left"
                          : "Out of Stock"
                        : data?.web_id?.product_id?.serviceType}
                      {data?.web_id?.product_id?.type === "Product" && (
                        <span
                          onClick={() => {
                            setEdit(data?.web_id);
                            setQty(data?.web_id?.quantity);
                            handleOpen2();
                          }}
                        >
                          <AddCircleOutlineIcon
                            style={{
                              width: width > 700 ? "1vw" : "3.5vw",
                              height: width > 700 ? "1vw" : "3.5vw",
                              color: "#0052CC",
                              fontWeight: "600",
                              cursor: "pointer",
                            }}
                          />
                        </span>
                      )}
                    </div>
                    <div
                      style={{
                        color: "#0052cc",
                        display: "flex",
                        alignItems: "center",

                        fontSize: width > 700 ? "0.8vw" : "2.5vw",
                      }}
                    >
                      {data?.web_id?.product_id?.rating?.$numberDecimal
                        ? data?.web_id?.product_id?.rating?.$numberDecimal?.slice(
                            0,
                            1
                          )
                        : 0}
                      <StarIcon
                        style={{
                          width: width > 700 ? "0.8vw" : "2.5vw",
                          margin: "0vw",
                          height: width > 700 ? "1vw" : "2.5vw",
                        }}
                      />{" "}
                      (
                      {data?.web_id?.product_id?.completedOrder
                        ? data?.web_id?.product_id?.completedOrder
                        : 0}
                      )
                    </div>
                  </div>
                  <div className="pricemenuweb">
                    <span>Rs. {data?.web_id?.product_id?.costPrice}</span>{" "}
                    {data?.web_id?.product_id?.sellingPrice && (
                      <del
                        style={{
                          display: "flex",
                          fontSize: width > 700 ? "0.75vw" : "2vw",
                          margin: "0 0.2vw",
                        }}
                      >
                        ( Rs {data?.web_id?.product_id?.sellingPrice})
                      </del>
                    )}
                    {data?.web_id?.product_id?.sellingPrice &&
                      parseFloat(
                        ((data?.web_id?.product_id?.sellingPrice -
                          data?.web_id?.product_id?.costPrice) *
                          100) /
                          data?.web_id?.product_id?.sellingPrice
                      ).toFixed(0) + "% off"}
                  </div>
                </div> */}
              </div>
            );
          })}
        <div
          style={{
            padding: "0vw",
            cursor: "pointer",
            height: "",
          }}
          className="Menuwebsite"
        >
          <Link to="/dashboard/products">
            <img
              style={{
                objectFit: "cover",
                height: "100%",
                width: "100%",
                borderRadius: "1vw",
              }}
              src={img}
              alt=""
            />
          </Link>
        </div>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={width > 700 ? style : style1}>
            <div className="profiletitleandmenunav">
              <div className="profiledetailstitle"> Remove Product</div>
              <div className="profiledetailnavmanu">
                <div>
                  <CloseIcon
                    onClick={handleClose}
                    style={{
                      fontSize: width > 700 ? "1.5vw" : "4vw",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
            </div>
            <hr style={{ color: "#000000" }} />
            <div
              style={{
                color: "gray",
                fontSize: width > 700 ? "1vw" : "2.7vw",
                fontWeight: "300",
              }}
              className="profiledetailstitle"
            >
              The action will Remove this Product From Your Website.
            </div>
            <div
              style={{
                color: "gray",
                fontSize: width > 700 ? "1vw" : "2.7vw",
                fontWeight: "400",
                marginBottom: "5vw",
              }}
              className="profiledetailstitle"
            >
              Are you sure you want to Remove this Product?
            </div>
            <hr style={{ color: "#000000" }} />
            <div
              style={{ marginTop: "0.31vw" }}
              className="handlemoreaboutskill"
            >
              <div
                style={{
                  background: "white",
                  color: "black",
                  cursor: "pointer",
                }}
                className="handlecirclieaboutsave"
                onClick={handleClose}
              >
                Cancel
              </div>
              <div
                style={{ cursor: "pointer" }}
                className="handlecirclieaboutsave"
                onClick={deleltecatalogue}
              >
                Remove
              </div>
            </div>
          </Box>
        </Modal>

        <Modal
          open={open2}
          onClose={handleClose2}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={width > 700 ? style : style1}>
            <div className="profiletitleandmenunav">
              <div className="profiledetailstitle"> Increase Quantity </div>
              <div className="profiledetailnavmanu">
                <div>
                  <CloseIcon
                    onClick={handleClose2}
                    style={{
                      fontSize: width > 700 ? "1.5vw" : "4vw",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
            </div>
            <hr style={{ color: "#000000" }} />
            <div
              style={{
                color: "gray",
                fontSize: width > 700 ? "1vw" : "2.7vw",
                fontWeight: "300",
              }}
              className="profiledetailstitle"
            >
              The action will change Quantity of this Product '{" "}
              {edit?.product_id?.productName} '
            </div>
            <div>
              <div
                style={{ left: "0vw", width: "98%", marginLeft: "0%" }}
                className="loginfield"
              >
                <TextField
                  id="outlined-basic"
                  label="Quantity"
                  variant="outlined"
                  type="number"
                  value={qty}
                  style={{ width: "100%" }}
                  InputLabelProps={{
                    style: {
                      fontSize: width > 700 ? "1vw" : "3vw",
                      fontFamily: "Poppins",
                      fontStyle: "500",
                      fontWeight: "500",
                      color: "black",
                    },
                  }}
                  inputProps={{ className: classes.input }}
                  onChange={(e) => {
                    setQty(e.target.value);
                  }}
                />
              </div>
            </div>

            <hr style={{ color: "#000000" }} />
            <div
              style={{ marginTop: "0.31vw" }}
              className="handlemoreaboutskill"
            >
              <div
                style={{
                  background: "white",
                  color: "black",
                  cursor: "pointer",
                }}
                className="handlecirclieaboutsave"
                onClick={handleClose2}
              >
                Cancel
              </div>
              <div
                style={{ cursor: "pointer" }}
                className="handlecirclieaboutsave"
                onClick={editcatalogue}
              >
                Update
              </div>
            </div>
          </Box>
        </Modal>

        <Modal
          open={open1}
          onClose={handleClose1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={width > 700 ? style2 : style1}>
            {width <= 700 && (
              <button
                style={{
                  cursor: "pointer",
                  zIndex: "100",
                  padding: "0.5vw 0.7vw",
                  backgroundColor: "white",
                  color: "#000",
                  fontSize: width > 700 ? "1.2vw" : "3.3vw",
                  borderRadius: "0.3vw",
                  border: "1px solid #d7d7d7",
                  marginBottom: width > 700 ? "1vw" : "3vw",
                  marginTop: "0vw",
                  margin: "1vw",
                }}
                onClick={handleClose1}
              >
                <ArrowBackIosNewIcon />
              </button>
            )}
            <div
              style={{
                width: "100%",
                background: "white",
                display: "flex",
                padding: width > 700 ? "1vw 1vw" : "0vw",
                paddingTop: "0vw",
                height: "85vh",
                flexDirection: width > 700 ? "" : "column",
                overflowY: "scroll",
              }}
            >
              <div className="product-wrapper">
                <div className="productpage">
                  <ul
                    style={{
                      marginTop:
                        proddata?.product_id?.files?.length < 4
                          ? (4 - proddata?.product_id?.files?.length) * 4 + "vw"
                          : "1vw",

                      height: width > 700 ? "" : "20vw",
                      position: width > 700 ? "" : "relative",
                      top: width > 700 ? "" : "2vw",
                      left:
                        width > 700
                          ? ""
                          : proddata?.product_id?.files?.length === 1
                          ? "1vw"
                          : proddata?.product_id?.files?.length === 2
                          ? "6vw"
                          : proddata?.product_id?.files?.length === 3
                          ? "12vw"
                          : "25vw",
                    }}
                    className="preview-thumbnail nav nav-tabs"
                  >
                    {proddata?.product_id?.files.length && (
                      <Slider {...settings}>
                        {proddata?.product_id?.files.map((img, index) => (
                          <li
                            key={index}
                            className={
                              displayImage === index ? "imageActive" : ""
                            }
                          >
                            <span
                              data-target={`#pic-${index}`}
                              data-toggle="tab"
                              onMouseOver={() => sliderClick(index)}
                            >
                              <img
                                src={img?.file}
                                style={{ width: "100%" }}
                                alt="iiiii"
                              />
                            </span>
                          </li>
                        ))}
                      </Slider>
                    )}
                  </ul>

                  <div className="productImg">
                    <div style={{ width: "100%", height: "100%" }}>
                      <ReactImageMagnify
                        style={{ width: "100%", height: "100%" }}
                        imageClassName="smallImage"
                        {...{
                          smallImage: {
                            alt: "Wristwatch by Ted Baker London",
                            isFluidWidth: true,
                            // width: 400,
                            // height: 400,
                            src: proddata?.product_id?.files[displayImage]
                              ?.file,
                            sizes: "(max-width: 1200px) 30vw, 360px",
                          },
                          largeImage: {
                            src: proddata?.product_id?.files[displayImage]
                              ?.file,
                            width: 1400,
                            height: 1500,
                          },
                          shouldUsePositiveSpaceLens: true,
                          lensStyle: {
                            background: "hsla(0, 0%, 100%, .3)",
                            border: "1px solid #ccc",
                          },
                          enlargedImageContainerDimensions: {
                            width: "110%",
                            height: "90%",
                          },
                          enlargedImageContainerStyle: {
                            marginTop: "5vw",
                            zIndex: 10,
                            background: "#fff",
                          },
                          enlargedImageStyle: {
                            objectFit: "contain",
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  height: "fit-content",
                  background: "white",
                  padding: "2vw",
                  width: width <= 700 && "94vw",
                  overflowX: "hidden",
                }}
                className="product-wrapper1 "
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ marginLeft: "0vw" }} className="tagblue">
                      {proddata?.product_id?.category?.category}
                    </div>
                    <span
                      style={{
                        color: "#0052cc",
                        fontSize: width > 700 ? "0.9vw" : "2.5vw",
                        marginLeft: "1vw",
                      }}
                    >
                      {proddata?.product_id?.type}
                    </span>
                  </div>
                  <div className="profiledetailnavmanu">
                    <div>
                      <CloseIcon
                        onClick={handleClose1}
                        style={{
                          fontSize: width > 700 ? "1.5vw" : "4vw",
                          cursor: "pointer",
                          position: "relative",
                          left: "3vw",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="Nameofproduct">
                  {proddata?.product_id?.productName}
                </div>
                <div className="headingofproduct">Description :</div>
                <div className="descriptionofproduct">
                  {proddata?.product_id?.description}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="headingofproduct">Price :</div>
                    <div
                      style={{ margin: "0 0.5vw" }}
                      className="descriptionofproduct"
                    >
                      <span style={{ fontSize: "1.3vw", color: "#0052cc" }}>
                        Rs {proddata?.product_id?.costPrice}
                      </span>{" "}
                      {proddata?.product_id?.sellingPrice && (
                        <del> Rs {proddata?.product_id?.sellingPrice}</del>
                      )}
                      {proddata?.product_id?.sellingPrice && (
                        <span style={{ color: "#0052cc", margin: "0 1vw" }}>
                          {" "}
                          {parseFloat(
                            ((proddata?.product_id?.sellingPrice -
                              proddata?.product_id?.costPrice) *
                              100) /
                              proddata?.product_id?.sellingPrice
                          ).toFixed(2)}
                          % off{" "}
                        </span>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      color: "#0052cc",
                      display: "flex",
                      alignItems: "center",
                      marginRight: "2vw",
                    }}
                  >
                    {proddata?.product_id?.rating?.$numberDecimal
                      ? proddata?.product_id?.rating?.$numberDecimal?.slice(
                          0,
                          1
                        )
                      : 0}
                    <StarIcon style={{ width: "1.2vw", margin: "0vw" }} /> (
                    {proddata?.product_id?.completedOrder
                      ? proddata?.product_id?.completedOrder
                      : 0}
                    )
                  </div>
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className="headingofproduct">
                    {" "}
                    {proddata?.product_id?.type === "Service"
                      ? "Type"
                      : "Stock"}{" "}
                    :{" "}
                  </div>{" "}
                  <div
                    style={{
                      color: proddata?.quantity > 0 ? "" : "red",
                      fontSize: width > 700 ? "1.1vw" : "3vw",
                      margin: "0 1vw",
                    }}
                    className="stockmenuweb"
                  >
                    {proddata?.product_id?.type === "Service" ? (
                      proddata?.product_id.serviceType
                    ) : (
                      <span>
                        {" " + proddata?.quantity > 0
                          ? " " + proddata?.quantity + " Items Left"
                          : "Out of Stock"}
                      </span>
                    )}
                  </div>
                </div>

                {proddata?.product_id?.addOn?.length > 0 && (
                  <div className="headingofproduct">Add On :</div>
                )}

                {proddata?.product_id?.addOn?.map((data) => {
                  return (
                    <div className="addonbox">
                      <div className="addondata">
                        <div className="addonimg">
                          <img src={data.image} alt="" />
                        </div>
                        <div style={{ width: "70%" }} className="addonname">
                          {data.name}
                        </div>
                        <div style={{ width: "20%" }} className="addonname">
                          Rs {data.amount}
                        </div>
                      </div>
                      <div className="addondesc">{data?.description}</div>
                    </div>
                  );
                })}
                <div className="headingofproduct">Details :</div>
                <div
                  className=""
                  dangerouslySetInnerHTML={{
                    __html: proddata?.product_id?.longDescription,
                  }}
                ></div>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
}
